module.exports={
"8000": 8000,
"11025": 11025,
"16000": 16000,
"22050": 22050,
"44100": 44100,
"48000": 48000,
"88200": 88200,
"96000": 96000,
"176400": 176400,
"192000": 192000,
"352800": 352800,
"384000": 384000
}
