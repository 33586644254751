import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment"
import './stamp';
import { RNSAPI } from "../../api";

export class Stamp {
    Id: string | null;
    Text: string;
    Type: string;

    constructor(Text: string, Type: string, Id?: string) {
        this.Text = Text;
        this.Type = Type;
        if (Id) this.Id = Id;
    }
}

//the DTO has german names, so we have to use them here too
class PredefinedStamp {
    AnnoTyp: string
    Bezeichnung: string;
    Text: string
    Workflow: string
    Farbe: string
}

export const stampContainerWidth = "250px";
export const stampClosedContainerWidth = "35px";

export class StampsViewModel {
    currentId: ko.Observable<string>;
    currentStamps: ko.ObservableArray<Stamp> = ko.observableArray([])
    stampsVisible = ko.observable(true);
    predefinedStamps = ko.observableArray([]);
    stampContainerSize: ko.Observable<string>;
    public readonly stampContainerWidth = stampContainerWidth;
    public readonly stampClosedContainerWidth = stampClosedContainerWidth;

    openStampDialog = () => {
        $("#createStamp").modal();
    };

    public toggleStamps = () => {
        this.stampsVisible(!this.stampsVisible());
        this.stampContainerSize(this.stampsVisible() ? this.stampContainerWidth : this.stampClosedContainerWidth);
    };

    public getVM = () => this;

    private async loadPredefinedStamps() {
        let templates = (await RNSAPI.getStampTemplates()).Payload.Templates;
        this.predefinedStamps(templates);
    }

    private async loadStamps(recordId: string) {
        if (recordId) {
            let stamps = (await RNSAPI.getStampsByECaseId(recordId)).Payload.Stamps;
            let stampsWithEditedDate = stamps.map(stamp => {
                stamp.CreationDate = (new Date(stamp.CreationDate)).toLocaleDateString();
                return stamp;
            });
            this.currentStamps(stampsWithEditedDate);
        }
    }

    public async updateStamps() {
        this.loadStamps(this.currentId());
    }

    createStamp = async (stamp: Stamp) => {
        let result = await RNSAPI.addStamp(stamp.Text, stamp.Type, this.currentId());
        if (result.Type === "AddSuccessful") {
            this.updateStamps();
        } else {
            alert("Fehler beim Erstellen des Stempels.");
        }
    }

    createStampFromTemplate = async (stamp: PredefinedStamp) => {
        let result = await RNSAPI.addStamp(stamp.Text, "Regular", this.currentId());
        if (result.Type === "AddSuccessful") {
            this.updateStamps();
        } else {
            alert("Fehler beim Erstellen des Stempels.");
        }
    }

    deleteStamp = async (stampId: string) => {
        let result = await RNSAPI.deleteStampById(stampId);
        if (result.Type === "DeleteStampSuccessful") {
            this.updateStamps();
        } else {
            alert("Fehler beim Löschen des Stempels.");
        }
    }

    constructor(params: any) {
        this.stampContainerSize = params.stampContainerSize;
        this.loadPredefinedStamps();
        this.currentId = params.eCaseRecordId;
        this.currentId.subscribe(newId => this.loadStamps(newId));
        this.loadStamps(this.currentId());
    }
}

let html = fs.readFileSync(__dirname + '/stamps.html', 'utf8');

ko.components.register("stamps-view", {
    viewModel: StampsViewModel,
    template: html
});