import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { MainViewModel } from "../../main";
import { Utils } from "../../utils";

class InboxViewModel {
    IsEditMode = ko.observable(false);
    inbox = this.newBasicInbox()

    protocols = [
        {
            name: "POP3",
            value: 0
        },
        {
            name: "IMAP",
            value: 1
        }
    ];

    newBasicInbox() {
        let obj = {
            "EMailAccount_ID": ko.observable(""),
            "EMail_Address": ko.observable(""),
            "IN_ProtokollType": ko.observable(1),
            "IN_UserLogin": ko.observable(""),
            "IN_UserPassword": ko.observable(""),
            "IN_Host": ko.observable(""),
            "IN_Port": ko.observable(993),
            "IN_ImapFolder": ko.observable("Inbox"),
            "IN_Authentification": ko.observable(1),
            "OUT_ProtokollType": ko.observable(0),
            "OUT_UserLogin": ko.observable(""),
            "OUT_UserPassword": ko.observable(""),
            "OUT_Host": ko.observable(""),
            "OUT_Port": ko.observable(465),
            "OUT_Authentification": ko.observable(0),
            "checkCertificate": ko.observable(true),
            "DeleteAfterDownload": ko.observable(false),
            "UnseenOnly": ko.observable(false),
            "GrabMails": ko.observable(true),
            "IsAutoInsertAddress": ko.observable(true)
        };

        return ko.observable(obj);
    };

    postData = async () => {
        if (this.IsEditMode()) {
            let result = await RNSAPI.updateInbox(this.inbox());
            if (result.Type !== "InboxCreationSuccessful") {
                alert("Fehler beim Speichern!");
            } else {
                MainViewModel.RoutingTable.showInboxOverview();
            }
        } else {
            let result = await RNSAPI.createInbox(this.inbox());
            if (result.Type !== "InboxCreationSuccessful") {
                alert("Fehler beim Anlegen!");
            } else {
                MainViewModel.RoutingTable.showInboxOverview();
            }
        }
    }

    loadInbox = async (id: string) => {
        let result = (await RNSAPI.getInboxById(id));
        let inbox = result.Payload;
        let tempInbox = this.inbox();
        for (let key in tempInbox) {
            if (tempInbox[key] === undefined) {
                tempInbox[key] = inbox[key];
            } else {
                tempInbox[key](inbox[key]);
            }
        }

        this.inbox(tempInbox);
    };

    constructor(params: any) {
        if (params && params.id) {
            this.IsEditMode(true);
            this.loadInbox(params.id);
        }

        this.inbox().EMail_Address.subscribe((newValue) => {
            if (this.inbox().IN_UserLogin() === "") this.inbox().IN_UserLogin(newValue);
            if (this.inbox().OUT_UserLogin() === "") this.inbox().OUT_UserLogin(newValue);
        });
    }
}

let html = fs.readFileSync(__dirname + '/inbox.html', 'utf8');

ko.components.register("inbox-view", {
    viewModel: InboxViewModel,
    template: html
});
