import { Utils } from "../../utils";
import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { MainViewModel } from "../../main";
import { DataTableViewModel, IntermediateListItem, Color } from '../dataTable/dataTable';
import * as moment from "moment"
import "../../../node_modules/moment/locale/de.js";

class DataManagementViewModel {
    units = ko.observableArray([]);
    legalforms = ko.observableArray([]);

    referatName = ko.observable("");
    referatId = ko.observable("");
    referatResult = ko.observable("");

    currentView: ko.Observable<String>;




    public showUnits() {
        MainViewModel.RoutingTable.showDatamanagementView({ view: "units" }, true);
        this.currentView('units');
    }

    public showLegalForms() {
        MainViewModel.RoutingTable.showDatamanagementView({ view: "legalforms" }, true);
        this.currentView('legalforms');
    }

    async update() {
        this.updateUnits();
        this.updateLegalForms();
    }

    async updateUnits() {
        let units = await RNSAPI.getReferate();
        this.units(units.Payload.Units.map((c) => {

            c.actionHandlers = [{
                icon: "pencil-alt",
                name: "Edit",
                action: () => MainViewModel.RoutingTable.showDatamanagementOverview(),
            }];
            return c;
        }));
    }

    async updateLegalForms() {
        let legalforms = await RNSAPI.getRechtsformen();
        this.legalforms(legalforms.Payload.LegalForms.map((c) => {
            c.actionHandlers = [{
                icon: "pencil-alt",
                name: "Edit",
                action: () => MainViewModel.RoutingTable.showDatamanagementOverview(),
            }];
            return c;
        }));
    }

    async saveReferat() {
        if (this.referatName() !== "" && this.referatId() !== "") {
            try {
                let result = await RNSAPI.addReferat(this.referatId(), this.referatName());
                if (result !== null) {
                    $('#AddReferatDialog').modal('hide');
                    this.updateUnits();
                }
            } catch (e) {
                this.referatResult("<i class='fas fa-exclamation-circle '></i> Das Referat konnte nicht hinzugef&uuml;gt werden.");
            }
        } else { 
            this.referatResult("<i class='fas fa-exclamation-circle '></i> Es wurden nicht alle Felder ausgef&uuml;llt.");
        }
        
    }

    addReferat() {
        this.referatName("");
        this.referatId("");
        this.referatResult("");
        $('#AddReferatDialog').modal('show');
    }

    constructor(params: any) {
        moment.locale("de");
     //   moment.locale("de");
        this.currentView = ko.observable(params.view);
        this.update();
        //this.updateUnits();
    }
}


let nameTable: { [key: string]: string; } = {
    "units": "Referate",
};


let html = fs.readFileSync(__dirname + '/datamanagement.html', 'utf8');

ko.components.register("datamanagement-view", {
    viewModel: DataManagementViewModel,
    template: html
});
