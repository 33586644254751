import { Utils } from "../../utils";
import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { MainViewModel } from "../../main";

export class Note {
    Id: string
    NoteText: string
    User: string
    CreationDate: string


    constructor(id: string, text: string, user: string, date: string) {
        this.Id = id;
        this.NoteText = text;
        this.User = user;
        this.CreationDate = date;

        
    }
}

class NotesViewModel {
    notes = ko.observableArray<Note>([]);
    text = ko.observable("");
    IsEditMode = ko.observable(false);
    modalTitle = ko.observable("");
    id = "";

    deleteNote = async (id: string) => {
        await RNSAPI.deleteNote(id);
        this.loadNotes();
    }

    editNote = async (id: string) => {
        let note = await RNSAPI.getNote(id);
        this.id=id;
        this.text(note.Payload.NoteText);
        this.IsEditMode(true);
        $('#NoteDialog').modal('show');
        //this.IsEditMode(false);
    }

    newNote = async () => {
        this.text("");        
        $('#NoteDialog').modal('show');
    }

    async loadNotes() {
        let notes = await RNSAPI.getNotes();
        for (let i = 0; i < notes.Payload.Notes.length; i++) {
            let OneNoteDate = notes.Payload.Notes[i].CreationDate;
            let GermanDate = OneNoteDate[8] + OneNoteDate[9] + "." + OneNoteDate[5] + OneNoteDate[6] + "." + OneNoteDate[0] + OneNoteDate[1] + OneNoteDate[2] + OneNoteDate[3];
            notes.Payload.Notes[i].CreationDate = GermanDate;
        }
        this.notes(notes.Payload.Notes);
    }

    async postData() {
        $(".form-group").each(function () { $(this).removeClass("has-error"); });
        if (!Utils.checkErrors(["text"], this, "", [Utils.checkString])) return;
        if (this.IsEditMode()) {
            await RNSAPI.updateNote(new Note(this.id, this.text(), RNSAPI.User().username, null));
            this.text("");
            $('#NoteDialog').modal('hide');
            this.IsEditMode(false);
            this.loadNotes();
        } else {
            await RNSAPI.createNote(new Note(this.id, this.text(), RNSAPI.User().username, null));
            this.text("");
            $('#NoteDialog').modal('hide');
            this.loadNotes();
        }
    };

    constructor(params) {
        this.loadNotes();

        //let id = params.id;
        //this.IsEditMode(id !== undefined);
        //if (this.IsEditMode()) {
        //    this.id = id;
        //    //this.loadNotes(id);
        //}
    }
}

let html = fs.readFileSync(__dirname + '/notes.html', 'utf8');

ko.components.register("notes-view", {
    viewModel: NotesViewModel,
    template: html
});