import * as fs from 'fs';
import * as ko from "knockout";
import { RNSAPI } from "../../api";

class FastCasePersonViewModel {
    constructor(params) {
        this.client = params.client;
        this.index = params.index;
        this.prefix = params.prefix;
    }

    client;
    index;
    prefix
    showRF = ko.observable(false);
    rachts = ko.observableArray([]);
    rachtsPerson = ko.observableArray([]);
    briefkopf = ko.observableArray([]);
    natrualPerson = ko.observable(true);

    toggleShown() {
        this.client.isShown(!this.client.isShown());
    }

    toggleMoreData() {
        this.client.isMoreShown(!this.client.isMoreShown());
    }

    async pickPerson(parent) {
        let addresses = (await RNSAPI.getPersonsForScope(this.client.personType())).Payload.Addresses;
        await parent.pickGeneric(
            this.client.personType() === "gegner" ? "Gegnerauswahl" : "Mandantauswahl",
            ["FirstName", "Name1", "CityName"],
            ["Vorname", "Nachname", "Ort"],
            addresses,
            (a) => {
                let o = a();
                for (let key in o) {
                    if (key === "Keyword") {
                        this.client.data["Keyword"] = ko.observable(o["Keyword"]);
                    } else if (typeof(this.client.data[key]) === 'function') {
                        this.client.data[key](o[key]);
                    } else {
                        this.client.data[key] = o[key];
                    }
                }
            }
        );
        $('#modal').modal('show');
    }

    async pickRechtsform2(parent) {
        let rechtsformen = (await RNSAPI.getRechtsformen()).Payload.LegalForms;
        await parent.pickGeneric(
            "Rechtsform",
            ["NameID", "Briefanrede"],
            ["Name", "Anrede"],
            rechtsformen,
            (a) => this.client.data.Rechtsform(a().NameID)
        );
        $('#modal').modal('show');
    };
    pickRechtsform = async () => {
        let rechtsformen = (await RNSAPI.getRechtsformen()).Payload.LegalForms;
        let x = [];
        let y = [];
        let z = [];
        let a = [];
        let b = [];
        let c = [];
        for (let i = 0; i < rechtsformen.length; i++) {
            if (rechtsformen[i].NameID != "") {
                if (rechtsformen[i].NameID === "Herr" || rechtsformen[i].NameID === "Frau" || rechtsformen[i].NameID === "Divers") {
                    a.push(rechtsformen[i].NameID);
                    b.push(rechtsformen[i].Briefanrede);
                    c.push(rechtsformen[i].Anrede);
                }
                else {
                    x.push(rechtsformen[i].NameID);
                    y.push(rechtsformen[i].Briefanrede)
                    z.push(rechtsformen[i].Anrede)
                }
            }

        }
        this.rachts(x);
        this.rachtsPerson(a);
        this.briefkopf(rechtsformen);
    }

    showExtendedData = (id) => {
        let el = document.getElementById(id);
        if (el.classList.contains("collapse"))
            el.classList.remove("collapse");
        else
            el.classList.add("collapse");        
    }

    selectOption = (data) => {
        let a = ko.toJS(this.briefkopf);
        for (let i = 0; i < a.length; i++) {
            if (data === a[i].NameID) {
                this.client.data.Rechtsform(a[i].NameID)
                this.client.data.AnredeBriefkopf(a[i].Briefanrede)
                this.client.data.AnredeDirekt(a[i].Anrede)
                if (data === "Herr" || data === "Frau" || data === "Divers") {
                    this.natrualPerson(true);
                }
                else {
                    this.client.data.FirstName("");
                    this.natrualPerson(false);
                }
                this.showRF(false);
                break;
            }
        }
    }

    showSuggestionsRF = async () => {
        this.showRF(true);
        this.pickRechtsform();
    }

    async getCity() {
        let result = await RNSAPI.getPostcode(this.client.data.ZipCode());
        if (result.Type=="GetPostcodeResult" && result.Payload.Cities.length > 0) {
            this.client.data.CityName(result.Payload.Cities[0])
        }
        else {
            this.client.data.CityName('');
        }
    }
}

let html = fs.readFileSync(__dirname + '/fastcaseperson.html', 'utf8');

ko.components.register("fastcaseperson-view", {
    viewModel: FastCasePersonViewModel,
    template: html
});
