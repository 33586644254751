import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { MainViewModel } from "../../main";
import '../dataTable/dataTable';

class AddUnitsViewModel {

    ReferatNummer = ko.observable("");
    ReferatName = ko.observable("");
    Result = ko.observable("");

    //referat = ko.observable({
    //    ReferatNummer: ko.observable(null),
    //    ReferatName: ko.observable(null)
    //});

    //Result = ko.observable("");

    async createReferat() {
        
        let Id = this.ReferatNummer();
        let Name = this.ReferatName();
        
   

        let result = await RNSAPI.addReferat(Id, Name);
        if (result !== null) {

            this.Result("Das Referat " + Id + " mit dem Namen " + Name + " wurde erfolgreich angelegt.");
            this.ReferatNummer("");
            this.ReferatName("");

        }


        //if (failed === true) {
        //    alert("Das Referat mit dieser Nummer ist bereits vergeben.");
        //    this.Result("Das Referat mit der Nummer " + Id + " existiert bereits oder konnte nicht angelegt werden. Bitte w&aumln;hlen Sie eine andere Nummer aus.");
        //    return false;
        //}
        //var result = await RNSAPI.addReferat(Id, Name);
        //console.log("Result:" + result);
        //if (result.Type === "Sucessfull") {
        //    console.log("Success");
        //    this.Result("Das Referat " + Id + " mit dem Namen " + Name + " wurde erfolgreich angelegt.");
        //    //this.ReferatNummer("");
        //    //this.ReferatName("");
        //}
        //else if (result.Type === "rejected") {
        //    console.log("Rejected");
        //    this.Result("Das Referat mit der Nummer " + Id + " existiert bereits. Bitte w&aumln;hlen Sie eine andere Nummer aus.");
        //}
        //else {
        //    console.log("failed");
        //    this.Result("Das Referat konnte nicht angelegt werden.");
        //}
        
        //MainViewModel.RoutingTable.showCaseOverview();
    };

    //createReferat() {
    //    let result = this.postData().then(ret_val => {
    //        if (!ret_val) {
    //            alert("failed");
    //        }
    //    });
    //};

}

let html = fs.readFileSync(__dirname + '/addunit.html', 'utf8');

ko.components.register("addunit-view", {
    viewModel: AddUnitsViewModel,
    template: html
});