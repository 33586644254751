import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI, Routing } from "../../api";
import { MainViewModel } from "../../main";
import '../dataTable/dataTable';
import { TreeType, treeTypeText } from './tree';

export class TreeOverviewViewModel {
    trees = ko.observableArray([]);
    selectedType = ko.observable(TreeType.PROCESS);
    types = ko.observableArray([TreeType.PROCESS, TreeType.CHECKLIST]);
    treeTypeText = treeTypeText;

    createTree() {
        MainViewModel.RoutingTable.showTreeView();
    }

    async loadTrees() {
        let trees = (await RNSAPI.getTreesByType(this.selectedType())).Payload.Trees;
        let mappedTrees = trees.map(t => {
            t.actionHandlers = [{
                icon: "pencil",
                name: "Edit",
                action: () => MainViewModel.RoutingTable.showTreeView({ type: this.selectedType(), id: t.Id })
            },
            {
                icon: "trash-o",
                name: "Löschen",
                action: async () => {
                    await RNSAPI.deleteTreeByTypeAndId(t.Type, t.Id);
                    this.loadTrees();
                }
            }];

            return t;
        });

        this.trees(mappedTrees);
    }

    constructor() {
        this.loadTrees();
        this.selectedType.subscribe(() => this.loadTrees());
    }
}

let html = fs.readFileSync(__dirname + '/overview.html', 'utf8');

ko.components.register("treeoverview-view", {
    viewModel: TreeOverviewViewModel,
    template: html
});