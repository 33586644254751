import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment"
import { RNSAPI } from "../../api";
import { Utils } from "../../utils";
import { MainViewModel } from "../../main";
import '../dialog/dialog';
import { DialogViewModel } from '../dialog/dialog';

class DeadlineViewModel {
    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalHandleSelection = ko.observable();

    newBasicDeadline() {
        let obj = {
            CaseId: ko.observable(null),
            DeadlineNumber: ko.observable(null),
            StartDate: ko.observable(moment().format("DD.MM.YYYY")),
            Deadline: ko.observable(null),
            PreDeadline: ko.observable(null),
            Rubrum: ko.observable("-/-"),
            Sachbearbeiter: ko.observable(null)
        };

        return ko.observable(obj);
    };

    basicDeadline = this.newBasicDeadline();

    async pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    };

    pickSachbearbeiter = async () => {
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric("Sachbearbeiter", ["Sachbearbeiter"], ["Sachbearbeiter"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => this.basicDeadline().Sachbearbeiter(selectedObject()["Sachbearbeiter_ID"]));
        $('#modal').modal('show');
    };

    async pickCase() {
        let cases = (await RNSAPI.getCases()).Payload.Cases;
        this.pickGeneric("Akte", ["Registernummer", "Rubrum", "Wegen"], ["Akte", "Rubrum", "Wegen"], cases);
        this.modalHandleSelection((selectedObject) => {
            this.basicDeadline().CaseId(selectedObject()["Registernummer"]);
            this.basicDeadline().Rubrum(selectedObject()["Rubrum"])
        });
        $('#modal').modal('show');
    };

    //why the separation?
    //because async/await does not work easily in a computed context, see
    //https://github.com/knockout/knockout/wiki/asynchronous-dependent-observables
    computedDeadlineText = ko.observable("Bitte einen Grund und ein Startdatum wählen.");

    computedDeadLine = ko.computed(async () => {
        let startDate = this.basicDeadline().StartDate();
        let deadlineNumber = this.basicDeadline().DeadlineNumber();

        if (startDate && deadlineNumber) {
            let result = (await RNSAPI.calcDeadline({
                StartDate: this.parseDate(startDate).format("YYYY-MM-DD"),
                DeadlineNumber: deadlineNumber
            })).Payload.Deadline.Deadline;

            this.computedDeadlineText(moment.utc(result).format("DD.MM.YYYY"));
        }
    });

    async pickDeadlineNumber() {
        let reasons = (await RNSAPI.getDeadlineReasons()).Payload.Reasons;
        this.pickGeneric("Grund", ["DeadlineDescription"], ["Beschreibung"], reasons);
        this.modalHandleSelection((selectedObject) => {
            this.basicDeadline().DeadlineNumber(selectedObject()["DeadlineNumber"]);
            if (Utils.checkString(selectedObject().DeadlineDuration)) this.IsDeadlineNecessary(false);
            else this.IsDeadlineNecessary(true);
        });
        $('#modal').modal('show');
    };

    IsDeadlineNecessary = ko.observable(true);

    parseDate = (dateStr: string) => moment(dateStr, "DD.MM.YYYY", true);

    resetObject(obj) {
        for (let key in obj) {
            obj[key](null);
        }
    }


    checkDate = (str: string) => this.parseDate(str).isValid();

    async postData() {
        //console.log("PostData_log")
        $(".form-group").each(function () { $(this).removeClass("has-error"); });
        if (!Utils.checkErrors(["CaseId", "DeadlineNumber"], this.basicDeadline(), "deadline", [Utils.checkString])) return;
        if (!Utils.checkErrors(["StartDate"], this.basicDeadline(), "deadline", [Utils.checkString, this.checkDate])) return;
        if (this.IsDeadlineNecessary() && !Utils.checkErrors(["Deadline"], this.basicDeadline(), "deadline", [Utils.checkString, this.checkDate])) return;


        let deadlineObj = ko.toJS(this.basicDeadline());
        deadlineObj.StartDate = this.parseDate(deadlineObj.StartDate).format("YYYY-MM-DD");
        if (deadlineObj.Deadline) deadlineObj.Deadline = this.parseDate(deadlineObj.Deadline).format("YYYY-MM-DD");
        if (deadlineObj.PreDeadline) deadlineObj.PreDeadline = this.parseDate(deadlineObj.PreDeadline).format("YYYY-MM-DD");

     
        try {
            let result = await RNSAPI.createDeadline(deadlineObj);
            if (result.Type === "CreationSuccessful") {
                MainViewModel.RoutingTable.showDashboardView({ view: "appointments" });
                RNSAPI.createHistoryEntry(this.basicDeadline().CaseId, "Frist angelegt"); 
            } else {
                alert("Fehler beim Anlegen der Frist.");
            }
        } catch (e) {
            console.log(e);
            alert("Fehler beim Anlegen: " + e.responseText);
        }
    };

    constructor(params: any) {
        params = params || {};
        let id = params.id;
    }
}

let html = fs.readFileSync(__dirname + '/deadline.html', 'utf8');

ko.components.register("deadline-view", {
    viewModel: DeadlineViewModel,
    template: html
});