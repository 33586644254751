import * as fs from "fs";
import * as ko from "knockout";
//import * as PDFJS from "pdfjs-dist"
import "pdfjs-dist";
import '../../../node_modules/print-js/dist/print.js';
import '../../../node_modules/pdfjs-dist/web/pdf_viewer.js';
import { RNSAPI, Routing } from "../../api";
import { Utils } from "../../utils";

class PDFJSViewModel {
    currentPageNumber = ko.observable(1);
    maximumPageNumber = ko.observable(1);
    pdfDocument = null;
    subscriptions = []
    //save blob for printing
    blob: Blob
    showPrintButton = ko.observable(false);

    async loadDocumentFromECase(id: string) {
        let result = await RNSAPI.getDocumentData(id);
        this.blob = Utils.base64ToBlob(result.Payload.Document.DocumentData, "application/pdf");
        let pdfData = Utils.base64ToUint8Array(result.Payload.Document.DocumentData);
        this.loadPdf(pdfData);
    }

    async loadDocumentFromScanPool(fileName: string) {
        let result = await RNSAPI.getScanPoolEntryByName(fileName);
        this.blob = Utils.base64ToBlob(result.Payload.Document.DocumentData, "application/pdf");
        let pdfData = Utils.base64ToUint8Array(result.Payload.Document.DocumentData);
        this.loadPdf(pdfData);
    }

    print = () => {
        let fileURL = URL.createObjectURL(this.blob);
        (window as any).printJS(fileURL);
        // printJS(fileURL);
    }

    constructor(params) {
        if (params.id || params.fileName) {
            if (params.id) {
                this.subscriptions.push(params.id.subscribe(newId => {
                    if (newId) this.loadDocumentFromECase(newId);
                }));
                if (params.id()) this.loadDocumentFromECase(params.id());
            } else if (params.fileName) {
                this.loadDocumentFromScanPool(params.fileName);
            } else {
                return;
            }

            this.currentPageNumber.subscribe(newPageNumber => this.loadPage(this.pdfDocument, +newPageNumber));

            const pdfjsWorker = fs.readFileSync('node_modules/pdfjs-dist/build/pdf.worker.js', 'utf8');

            const pdfjsWorkerBlob = new Blob([pdfjsWorker]);
            const pdfjsWorkerBlobURL = URL.createObjectURL(pdfjsWorkerBlob);

            PDFJS.workerSrc = pdfjsWorkerBlobURL;
        }
    }

    dispose() {
        for (var s of this.subscriptions) s.dispose();
    }


    private loadPdf(pdfData) {
        let pdf = PDFJS.getDocument(pdfData);
        pdf.then((document) => this.loadPage(document, 1));

        this.showPrintButton(true);
    }

    private loadPage(pdfDocument, pageNumber: number) {
        let SCALE = 0.75;

        this.pdfDocument = pdfDocument;
        this.maximumPageNumber(pdfDocument.numPages);
        $("#pdfContainer").empty();
        var container = document.getElementById('pdfContainer');
        // Document loaded, retrieving the page.
        return pdfDocument.getPage(pageNumber).then(function (pdfPage) {
            // Creating the page view with default parameters.
            var pdfPageView = new (PDFJS as any).PDFPageView({
                container: container,
                id: pageNumber,
                scale: SCALE,
                defaultViewport: pdfPage.getViewport(SCALE),
                // We can enable text/annotations layers, if needed
                textLayerFactory: new (PDFJS as any).DefaultTextLayerFactory(),
                //annotationLayerFactory: new (PDFJS as any).DefaultAnnotationLayerFactory()
            });
            // Associates the actual page with the view, and drawing it
            pdfPageView.setPdfPage(pdfPage);
            return pdfPageView.draw();
        });
    }

    public pageForward() {
        let page = +this.currentPageNumber();
        let max = this.maximumPageNumber();
        if (page < max) {
            this.currentPageNumber(page + 1);
        }
    }

    public pageBackward = () => {
        let page = +this.currentPageNumber();
        if (page > 1) {
            this.currentPageNumber(page - 1);
        }
    }
}


let html = fs.readFileSync(__dirname + '/pdfjs.html', 'utf8');

ko.components.register("pdfjs-view", {
    viewModel: PDFJSViewModel,
    template: html
});
