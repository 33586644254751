import * as fs from "fs";
import * as ko from "knockout";
import moment = require("moment");
import { RNSAPI } from "../../api";
import { RolesAndRights } from "../../helpers/RolesAndRights";
import { RVGList } from "../../types/RVGList";
import { Utils } from "../../utils";
import { Postbox } from "../dashboard/postbox";
import { Konto } from "./Konto";

export class RVGViewModel {

    isViewerRole = ko.observable<boolean>(false);

    caseId = ko.observable<string>('');
    billNumber = ko.observable<string>(); //Rechnungsnummer
    selectedRVGTable = ko.observable<string>('2021');
    selectedAbrechnung = ko.observable<string>('0');
    selectedAmount = ko.observable<string>('');
    isGeneratingInvoice = ko.observable<boolean>(false);
    rvgList = ko.observableArray<RVGList>([]);
    selectedRVGMandant = ko.observable<string>('');
    allRVGMandanten = ko.observableArray([]);
    selectedAmountValue = ko.observable<boolean>(false);
    rvgErrorText = ko.observable<string>('');
    wasAbrechnungSelected = ko.observable<boolean>(false);
    wasRVGTableSelected = ko.observable<boolean>(false);


    // RVG Modal Cache 
    rvgValueDispute = ko.observable<string>('0,00');
    rvgNumber = ko.observable<string>('');
    rvgInstance = ko.observable<string>('');
    rvgSelectedTax = ko.observable<string>('19,00'); //Wird ebenfalls für Zuzug benutzt
    rvgFeeRate = ko.observable<string>('');
    rvgAmount = ko.observable<string>('0,00 €');
    rvgAmountUnformated = ko.observable<string>('0,00');
    rvgDescription = ko.observable<string>('');
    rvgMinAmount = ko.observable<string>('');
    rvgMiddleAmount = ko.observable<string>('');
    rvgMaxAmount = ko.observable<string>('');

    // Zuzug Modal Cache
    influxAmount = ko.observable<string>('0,00');
    influxDescription = ko.observable<string>('Zuzüglich');

    // Abzug Modal Cache
    deductionDescription = ko.observable<string>('Vorschuss');
    deductionAmount = ko.observable<string>('0,00');

    // Steuerfrei Modal Cache
    taxFreeDescription = ko.observable<string>('Steuerfreie Auslagen');
    taxFreeAmount = ko.observable<string>('0,00');

    // Steuerpflichtige Modal Cache
    taxDescription = ko.observable<string>('Steuerpflichtige Auslagen');
    taxAmount = ko.observable<string>('0,00');


    filter = ko.observable('');
    allItems = ko.observableArray([]);
    entries: ko.Computed<Array<any>>;

    allCases = ko.observableArray([]);



    // Modal
    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalHandleSelection: any = ko.observable();
    modalSelectedObject = ko.observable(null);

    filteredItems = ko.computed({
        owner: this,
        read: () => {
            let filter = this.filter().toLowerCase();
            let items = this.allItems();
            return items.filter(item => {
                for (let value of ko.toJS(this.modalKeys).map(column => item[column]) as Array<string>) {
                    if (value && value.toLowerCase().indexOf(filter) !== -1) return true;
                }
                return false;
            })
        }
    });

    constructor() {
        this.loadCases();
        this.isViewerRole(RolesAndRights.isViewerRole());

        this.suggestBillNumber();
        this.setLastCase();

        this.allItems = ko.observableArray([]);
        this.entries = ko.computed({
            owner: this,
            read: () => {
                return this.filteredItems();
            }
        });
    }


    async openRVGModal(): Promise<void> {
        if(!ko.toJS(this.allCases()).find(x => x.caseid === this.caseId())) {
            $("#openRVGErrorModal").modal('show');
            this.rvgErrorText('Die eingegebene Registernummer existiert nicht.');
            return;
        }

        if(this.caseId() !== '') {
            this.clearRVGModal();
            await this.setCourtValues();
            $("#openRVGModal").modal('show');
        } else {
            $("#openRVGErrorModal").modal('show');
            this.rvgErrorText('Bitte wählen Sie eine Akte aus.');
        }
    }

    async openRVGLibrary(): Promise<void> {
        const rvgLibrary = (await RNSAPI.rvgSearch({
            Query: "",
            Version: this.selectedRVGTable(),
            Department: this.selectedAbrechnung()
        })).Payload.RVGGeb;

        this.modalTitle("RVG Gebühren");
        this.modalKeys(["ParagraphNummer", "ParagraphBezeichnung"]);
        this.modalColumns(["Gebühr", "Bezeichnung"]);
        this.modalData(rvgLibrary);
        this.allItems(rvgLibrary);

        this.filter(' ');
        this.filter('');

        $("#collapseExampleAbr").addClass('show');
    }


    addRVGList(): void {
        if(this.rvgNumber() !== '' && this.rvgDescription() !== '' && this.rvgAmount() !== '') {
            if(this.rvgValueDispute() === '') return;
            if(this.rvgAmountUnformated() !== this.rvgAmount()) {
                if(this.rvgAmountUnformated() === '0,00') {
                    if(ko.toJS(this.rvgAmount()).includes('€')) {
                        this.rvgAmountUnformated(ko.toJS(this.rvgAmount()).replace('€', ''));
                    } else {
                        this.rvgAmountUnformated(this.rvgAmount());
                    }
                }
            }
        
            let checkFormattedAmount;

            if(this.rvgAmount().toString().includes('€')) {
                checkFormattedAmount = this.rvgAmount().toString();
            } else {
                checkFormattedAmount = this.formatCurrencyInput(parseFloat(this.rvgAmount().toString()));
            }

            this.rvgList.push({
                type: 'G',
                number: this.rvgNumber(),
                description: this.rvgDescription(),
                valueDispute: this.rvgValueDispute(),
                formattedValueDispute: this.formatCurrencyInput(parseFloat(this.rvgValueDispute().toString().replace(',','.'))),
                feeRate: this.rvgFeeRate(),
                formattedAmount: checkFormattedAmount,
                amount: this.rvgAmountUnformated(),
                tax: this.rvgSelectedTax(),
                rvgType: this.selectedAbrechnung(),
                minAmount: this.rvgMinAmount(),
                middleAmount: this.rvgMiddleAmount(),
                maxAmount: this.rvgMaxAmount(),
                formattedMinAmount: this.formatCurrencyInput(parseFloat(this.rvgMinAmount())),
                formattedMiddleAmount: this.formatCurrencyInput(parseFloat(this.rvgMiddleAmount())),
                formattedMaxAmount: this.formatCurrencyInput(parseFloat(this.rvgMaxAmount())),
                instance: this.rvgInstance()
            });
            $('#openRVGModal').modal('hide');
            this.wasAbrechnungSelected(true);
            this.wasRVGTableSelected(true);
        }
    }

    SumWithoutUSt(): void {
        const sum = this.calculateSum();
        this.selectedAmountValue(true);

        this.rvgList.push({
            type: '=',
            number: '',
            description: 'SUMME',
            valueDispute: '',
            formattedValueDispute: '',
            feeRate: '',
            amount: sum.toString(), //calculated
            formattedAmount: this.formatCurrencyInput(sum),
            tax: '',
            rvgType: '0',
            minAmount: '',
            middleAmount: '',
            maxAmount: '',
            formattedMinAmount: this.formatCurrencyInput(0),
            formattedMiddleAmount: this.formatCurrencyInput(0),
            formattedMaxAmount: this.formatCurrencyInput(0),
            instance: this.rvgInstance()
        });
        $('#openRVGSumQuestionModal').modal('hide');
        this.wasAbrechnungSelected(true);
        this.wasRVGTableSelected(true);
    }

    SumWithUSt(): void {
        this.selectedAmountValue(true);
        let [calculatedUSt, calculatedAmount] = this.calculateSumWithUSt();
        this.rvgList.push({
            type: 'U19',
            number: '7008',
            description: `19% Umsatzsteuer aus ${this.formatCurrencyInput(calculatedAmount)}`,
            valueDispute: '',
            formattedValueDispute: '',
            feeRate: '',
            amount: calculatedUSt.toString(),
            formattedAmount: this.formatCurrencyInput(calculatedUSt),
            tax: '19,00',
            rvgType: '0',
            minAmount: '',
            middleAmount: '',
            maxAmount: '',
            formattedMinAmount: this.formatCurrencyInput(0),
            formattedMiddleAmount: this.formatCurrencyInput(0),
            formattedMaxAmount: this.formatCurrencyInput(0),
            instance: this.rvgInstance()
        });
        $("#openRVGSumQuestionModal").modal('hide');
        this.wasAbrechnungSelected(true);
        this.wasRVGTableSelected(true);
    }
    
    openRVGSumQuestionModal(): void {
        if(this.caseId() !== '') {
            $("#openRVGSumQuestionModal").modal('show');
        } else {
            $("#openRVGErrorModal").modal('show');
            this.rvgErrorText('Bitte wählen Sie eine Akte aus.');
        }
    }

    openRVGZuzug(): void {
        if(this.caseId() !== '') {
            this.clearZuzugModal();
            $("#openZuzugModal").modal('show');
        } else {
            $("#openRVGErrorModal").modal('show');
            this.rvgErrorText('Bitte wählen Sie eine Akte aus.');
        }
    }

    addRVGZuzug(): void {
        if(this.influxDescription() !== '' && this.influxAmount() !== '') {
            if(this.influxAmount() !== '0,00' || this.influxAmount() !== '0') {
                this.rvgList.push({
                    type: '+',
                    number: '',
                    description: this.influxDescription(),
                    valueDispute: '',
                    formattedValueDispute: '',
                    feeRate: '',
                    amount: this.influxAmount(),
                    formattedAmount: this.formatCurrencyInput(parseFloat(this.influxAmount().toString().replace(',','.'))),
                    tax: this.rvgSelectedTax(),
                    rvgType: '0',
                    minAmount: '',
                    middleAmount: '',
                    maxAmount: '',
                    formattedMinAmount: this.formatCurrencyInput(0),
                    formattedMiddleAmount: this.formatCurrencyInput(0),
                    formattedMaxAmount: this.formatCurrencyInput(0),
                    instance: this.rvgInstance()
                });
                $('#openZuzugModal').modal('hide');
                this.wasAbrechnungSelected(true);
                this.wasRVGTableSelected(true);
            }
        }
    }

    addRVGAbzug(): void {
        if(this.deductionDescription() !== '' && this.deductionAmount() !== '') {
            if(this.deductionAmount() !== '0,00' || this.deductionAmount() !== '0') {
                this.rvgList.push({
                    type: '-',
                    number: '',
                    description: this.deductionDescription(),
                    valueDispute: '',
                    formattedValueDispute: '',
                    feeRate: '',
                    amount: `-${this.deductionAmount()}`,
                    formattedAmount: `-${this.formatCurrencyInput(parseFloat(this.deductionAmount().toString().replace(',','.')))}`,
                    tax: this.rvgSelectedTax(),
                    rvgType: '0',
                    minAmount: '',
                    middleAmount: '',
                    maxAmount: '',
                    formattedMinAmount: this.formatCurrencyInput(0),
                    formattedMiddleAmount: this.formatCurrencyInput(0),
                    formattedMaxAmount: this.formatCurrencyInput(0),
                    instance: this.rvgInstance()
                });
                $('#openAbzugModal').modal('hide');
                this.wasAbrechnungSelected(true);
                this.wasRVGTableSelected(true);
            }
        }
    }

    openRVGAbzug(): void {
        if(this.caseId() !== '') {
            this.clearAbzugModal();
            $("#openAbzugModal").modal('show');
        } else {
            $("#openRVGErrorModal").modal('show');
            this.rvgErrorText('Bitte wählen Sie eine Akte aus.');
        }
    }

    openRVGSteuerpflichtige(): void {
        if(this.caseId() !== '') {
            this.clearTaxModal();
            $("#openRVGSteuerpflichtige").modal('show');
        } else {
            $("#openRVGErrorModal").modal('show');
            this.rvgErrorText('Bitte wählen Sie eine Akte aus.');
        }
    }

    addRVGSteuerpflichtige(): void {
        if(this.taxDescription() !== '' && this.taxAmount() !== '') {
            if(this.taxAmount() !== '0,00' || this.taxAmount() !== '0') {
                this.rvgList.push({
                    type: 'P',
                    number: '',
                    description: this.taxDescription(),
                    valueDispute: '',
                    formattedValueDispute: '',
                    feeRate: '',
                    amount: this.taxAmount(),
                    formattedAmount: this.formatCurrencyInput(parseFloat(this.taxAmount().toString().replace(',','.'))),
                    tax: this.rvgSelectedTax(),
                    rvgType: '0',
                    minAmount: '',
                    middleAmount: '',
                    maxAmount: '',
                    formattedMinAmount: this.formatCurrencyInput(0),
                    formattedMiddleAmount: this.formatCurrencyInput(0),
                    formattedMaxAmount: this.formatCurrencyInput(0),
                    instance: this.rvgInstance()
                });
                $('#openRVGSteuerpflichtige').modal('hide');
                this.wasAbrechnungSelected(true);
                this.wasRVGTableSelected(true);
            }
        }
    }

    addRVGSteuerfreie(): void {
        if(this.taxFreeDescription() !== '' && this.taxFreeAmount() !== '') {
            if(this.taxFreeAmount() !== '0,00' || this.taxFreeAmount() !== '0') {
                this.rvgList.push({
                    type: '*',
                    number: '',
                    description: this.taxFreeDescription(),
                    valueDispute: '',
                    formattedValueDispute: '',
                    feeRate: '',
                    amount: this.taxFreeAmount(),
                    formattedAmount: this.formatCurrencyInput(parseFloat(this.taxFreeAmount().toString().replace(',','.'))),
                    tax: this.rvgSelectedTax(),
                    rvgType: '0',
                    minAmount: '',
                    middleAmount: '',
                    maxAmount: '',
                    formattedMinAmount: this.formatCurrencyInput(0),
                    formattedMiddleAmount: this.formatCurrencyInput(0),
                    formattedMaxAmount: this.formatCurrencyInput(0),
                    instance: this.rvgInstance()
                });
                $('#openRVGSteuerfreie').modal('hide');
                this.wasAbrechnungSelected(true);
                this.wasRVGTableSelected(true);
            }
        }
    }

    openRVGSteuerfreie(): void {
        if(this.caseId() !== '') {
            this.clearTaxFreeModal();
            $("#openRVGSteuerfreie").modal('show');
        } else {
            $("#openRVGErrorModal").modal('show');
            this.rvgErrorText('Bitte wählen Sie eine Akte aus.');
        }
    }

    async selectMandant(): Promise<void> {
        $("#openRVGMandanten").modal('hide');
        await this.generateRVGInvoice();
    }

    async chooseReciepiant(): Promise<void> {
        if(this.caseId() !== '') {
            let address = (await RNSAPI.getPersonsForCaseId(this.caseId())).Payload.Addresses[0];
            if (address.length > 0) {
                if (address.length === 1) {
                    this.selectedRVGMandant(address[0]['Keyword']);
                    await this.generateRVGInvoice();
                }
                else {
                    this.allRVGMandanten(address);
                    this.selectedRVGMandant(address[0]['Keyword']);
                    $("#openRVGMandanten").modal('show');
                }
            } else {
                $("#openRVGErrorModal").modal('show');
                this.rvgErrorText('Es existiert kein Mandant in der Akte um eine Rechnung zu erstellen.');
            }
        }  else {
            $("#openRVGErrorModal").modal('show');
            this.rvgErrorText('Bitte wählen Sie eine Akte aus.');
        }
    }

    selectRVGTable(data, event): void {
        this.selectedRVGTable(event.target.value);
    }

    selectAbrechnungTypes(data, event): void {
        this.selectedAbrechnung(event.target.value);
    }

    selectTax(data, event): void {
        this.rvgSelectedTax(event.target.value);
    }

    selectRVGMandant(data, event): void {
        this.selectedRVGMandant(event.target.value);
    }

    selectAmount = (rvgObject,event) => {
        const filtered = this.rvgList().find(x => x.type === '=' || x.type === 'U19');
        if(!filtered) {
            rvgObject.amount = event.target.value;
        } else {
            $("#openRVGErrorModal").modal('show');
            this.rvgErrorText('Die Mindest/Mittel und Höchstgebühr kann nach einer Summe nicht mehr verändert werden.');
        }
        
    }

    deleteRVG = (rvgObject, event) => {
        this.rvgList.remove(rvgObject);
        const filtered = this.rvgList().find(x => x.type === '=' || x.type === 'U19');
        if(!filtered) {
            this.selectedAmountValue(false);
        }
        if(this.rvgList().length === 0) {
            this.wasAbrechnungSelected(false);
            this.wasRVGTableSelected(false);
        }
    }

    modalSelect = async (obj, event) => {
        $("#modalTable tr").removeClass("active");
        $(event.target.parentElement).addClass("active");
        this.modalSelectedObject(obj);
        this.rvgNumber(obj["ParagraphNummer"]);
        await this.updateRVGModal();
        $("#collapseExampleAbr").removeClass('show');
    }

    searchRVGNumber(data: any, event: any): void {
        if(event.target.value.length > 2) {
            this.rvgNumber(event.target.value);
            this.updateRVGModal();
        }
    } 

    modalPick() {
        if (this.modalHandleSelection !== null) this.modalHandleSelection()(this.modalSelectedObject);
    };

    async updateFeeRate(data: any, event: any): Promise<void> {
        this.rvgFeeRate(event.target.value);
        await this.calculateAmount();
    }

    async updatedValueDispute(data: any, event: any): Promise<void> {
        this.rvgValueDispute(event.target.value);
        await this.calculateAmount();
        await this.updateRVGModal();
    }

    private async updateRVGModal(): Promise<void> {
        const rvgResponse = await RNSAPI.rvgSearch({
            Query: `${this.rvgNumber()} BY NUMBER`,
            Version: this.selectedRVGTable(),
            Department: this.selectedAbrechnung()
        });

        const firstEntry = rvgResponse.Payload.RVGGeb[0];
        if(firstEntry !== undefined || firstEntry !== null) {
            if(firstEntry.Verfahren.trim() !== 'Auslagen') {
                this.rvgDescription(`${firstEntry.ParagraphBezeichnung.trim()} aus ${this.formatCurrencyInput(parseFloat(this.rvgValueDispute().toString().replace(',','.')))}`);
            } else {
                this.rvgDescription(`${firstEntry.ParagraphBezeichnung.trim()}`);
            }
            this.rvgInstance(firstEntry.Verfahren.trim());
            this.rvgFeeRate(firstEntry.GebuehrenSatz.trim());
            if(this.selectedAbrechnung() !== '0') {
                this.rvgMinAmount(parseFloat(firstEntry.MindestGebuehren.trim()).toString());
                this.rvgMiddleAmount(parseFloat(firstEntry.MittelGebuehren.trim()).toString());
                this.rvgMaxAmount(parseFloat(firstEntry.HoechstGebuehren.trim()).toString());
            }
            await this.calculateAmount();
        }
    }

    private async calculateAmount(): Promise<void> {
        const calculatedResponse = await RNSAPI.rvgCalculate({
            Streitwert: this.rvgValueDispute(),
            Paragraph: this.rvgNumber(),
            Version: this.selectedRVGTable(),
            Gebuehrensatz: this.rvgFeeRate(),
            Department: this.selectedAbrechnung()
        });
        var calculateData = calculatedResponse.Payload;
        if (Number(this.rvgNumber()) !== 7002) {
            this.rvgAmountUnformated(calculateData.Gebuehr.trim());
            this.rvgAmount(this.formatCurrencyInput(parseFloat(calculateData.Gebuehr.trim().toString().replace(',','.'))));
        } else {
            this.rvgAmountUnformated(calculateData.Gebuehr.trim());
            this.rvgAmount(this.formatCurrencyInput(parseFloat(await (await this.calculateSpecialAmount(this.rvgValueDispute().toString())).toString().replace(',','.'))));
        }
    }

    private async calculateSpecialAmount(valueDispute: string): Promise<string> {
        let berechnung: any = parseFloat(valueDispute.toString().replace(',', '.'));
        berechnung = ((Math.round(berechnung * 100) / 100) * 20) / 100;
        if (berechnung > 20) {
            berechnung = 20;
        }
        berechnung = berechnung.toFixed(2);
        berechnung = berechnung.toString();
        berechnung = berechnung.replace('.', ',');
        return berechnung;
    }

    private async generateRVGInvoice(): Promise<void> {
        if(!this.isGeneratingInvoice()) {
            if(this.rvgList().length === 0) {
                $("#openRVGErrorModal").modal('show');
                this.rvgErrorText('Es sind keine Gebühren erfasst worden.');
                return;
            }
            this.isGeneratingInvoice(true);

            const response = await RNSAPI.rvgAddEntry(
                this.caseId(), 
                this.billNumber(), 
                this.selectedRVGMandant(), 
                '2',
                this.generateRVGListObject(),
                this.selectedRVGTable(),
                this.selectedAbrechnung()
            );

            if(response.Type === 'NewEntryResultUnsuccessful') {
                $("#openRVGErrorModal").modal('show');
                this.rvgErrorText('Die Rechnung konnte nicht erstellt werden.');
                this.isGeneratingInvoice(false);
                return;
            }

            this.billNumber(response.Payload.rechNr);
            RNSAPI.createHistoryEntry(this.caseId(), "Rechnung erstellt / Rechnungsnummer: " + this.billNumber());

            if(response.Payload.DocumentId !== "") {
                RNSAPI.createHistoryEntry(this.caseId(), "Texterzeugung / Gebührenerfassung RVG (" + this.billNumber() + ")");
                await this.downloadInvoice('Gebührenerfassung RVG', response.Payload.DocumentId);
            }

            let caseOne = (await RNSAPI.getCaseByCaseId(this.caseId())).Payload.Case;

            await this.addAccounting();
            await this.addReminder(caseOne['SachbearbeiterId']);

            this.addAktenkonto(this.billNumber(), this.selectedRVGMandant(), caseOne['SachbearbeiterId'], caseOne['Rubrum'], caseOne['Referat']);

            $("#openRVGErrorModal").modal('show');
            this.rvgErrorText('Die Rechnung ('+this.billNumber()+') wurde erfolgreich erstellt.');

            this.suggestBillNumber();
            this.rvgList([]);
            this.selectedAmountValue(false);
            this.isGeneratingInvoice(false);
            this.wasAbrechnungSelected(false);
            this.wasRVGTableSelected(false);
          
            Postbox.publisher().publish('refresh', 'refreshReminders');
        }
    }

    private async addAktenkonto(rechnungsnummer, keyword, sachbearbeiterId, rubrum, referat): Promise<void> {
        const hasPartials = this.rvgList().filter(item => item.type === '*' || item.type === 'P').length > 0 ? true : false;

        if (hasPartials) {
            let amount = 0;
            for (let i = 0; i < this.rvgList().length; i++) {
                if (this.rvgList()[i].type === '=') {
                    amount = parseFloat(this.rvgList()[i].amount.toString().replace(',', '.'))
                }
            }

            amount = Math.round(amount * 100) / 100;
            amount = parseFloat(amount.toFixed(2));

            var netamount: any = 0;
            for (let i = 0; i < this.rvgList().length; i++) {
                if (this.rvgList()[i].type === '=' || this.rvgList()[i].type === 'U19') continue;
                netamount += parseFloat(this.rvgList()[i].amount.toString().replace(',', '.'));
            }

            netamount = Math.round(netamount * 100) / 100;
            netamount = this.formatCurrencyInput(netamount);


            let arts = ['G', '+', '-', 'A'];
 
            let sum: any = 0;
            let honorar: any = 0;
            let steuerfreieAuslagen: any = 0;
            let steuerpflichtigeAuslagen: any = 0;

            steuerfreieAuslagen = this.rvgList().filter(item => item.type === '*')
                .reduce((a: number, b) => a + parseFloat(b.amount.toString().replace(',', '.')), 0);

            steuerpflichtigeAuslagen = this.rvgList().filter(item => item.type === 'P')
                .reduce((a: number, b) => a + parseFloat(b.amount.toString().replace(',', '.')) * (1 + parseFloat(b.tax.toString().replace(',', '.')) / 100), 0);

            honorar = this.rvgList().filter(item => arts.indexOf(item.type) !== -1)
                .reduce((a, b) => a + parseFloat(b.amount.toString().replace(',', '.')) * (1 + parseFloat(b.tax.toString().replace(',', '.')) / 100), 0);

            honorar = +honorar.toFixed(2);
            steuerpflichtigeAuslagen = +steuerpflichtigeAuslagen.toFixed(2);
            steuerfreieAuslagen = +steuerfreieAuslagen.toFixed(2);


            sum = honorar + steuerfreieAuslagen + steuerpflichtigeAuslagen;

            if (amount !== +sum.toFixed(2)) return;

            let aktenkonto = {
                ...new Konto(
                    keyword, rubrum,
                    amount.toFixed(2).toString().replace('.', ','),
                    netamount,
                    rechnungsnummer,
                    this.caseId(),
                    sachbearbeiterId,
                    steuerfreieAuslagen.toFixed(2).toString().replace('.', ','),
                    steuerpflichtigeAuslagen.toFixed(2).toString().replace('.', ','),
                    '0,00',
                    referat,
                    honorar.toFixed(2).toString().replace('.', ',')
                )
            };

            let responseType = (await RNSAPI.addAktenkonto(aktenkonto)).Type;
            if (responseType !== 'AddSuccessful') {
                $("#openRVGErrorModal").modal('show');
                this.rvgErrorText('Der Eintrag konnte dem Aktenkonto nicht zugeordnet werden.');
            }
      

        } else {

            let amount = '0,00';
            for (let i = 0; i < this.rvgList().length; i++) {
                if (this.rvgList()[i].type === '=') {
                    amount = this.rvgList()[i].amount; 
                }
            }

            var netamount: any = 0;
            for (let i = 0; i <this.rvgList().length; i++) {
                if (this.rvgList()[i].type === '=' || this.rvgList()[i].type === 'U19') continue;
                netamount += parseFloat(this.rvgList()[i].amount.toString().replace(',', '.'));
            }

            netamount = Math.round(netamount * 100) / 100;
            netamount = this.formatCurrencyInput(netamount);

            let aktenkonto = {
                ...new Konto(
                    keyword,
                    rubrum,
                    amount,
                    netamount,
                    rechnungsnummer,
                    this.caseId(),
                    sachbearbeiterId,
                    '0,00',
                    '0,00',
                    '0,00',
                    referat,
                    amount
                )
            };


            let responseType = (await RNSAPI.addAktenkonto(aktenkonto)).Type;
            if (responseType !== 'AddSuccessful') {
                $("#openRVGErrorModal").modal('show');
                this.rvgErrorText('Der Eintrag konnte dem Aktenkonto nicht zugeordnet werden.');
            }
            
        }
    }

    private nextMonth(date): string {
        let currentDate = date ? moment(date) : moment();
        let futureMonth = moment(currentDate).add(1, 'M');
        let futureMonthEnd = moment(futureMonth).endOf('month');

        if (currentDate.date() != futureMonth.date() && futureMonth.isSame(futureMonthEnd.format('YYYY-MM-DD'))) {
            futureMonth = futureMonth.add(1, 'd');
        }

        return moment.parseZone(futureMonth).utc().format();
    }

    private async addReminder(sachbearbeiter: string): Promise<void> {
        let calculatedAmount = 0;
        for (let i = 0; i < this.rvgList().length; i++) {
            if (this.rvgList()[i].type === '=') {
                calculatedAmount = parseFloat(this.rvgList()[i].amount.toString().replace(',','.'));
            }
        }

        let reminder = {
            AdressatSuchbegriff: this.selectedRVGMandant(), //Address search term
            AdressatTyp: '2', //Address type
            Aktion: null,
            DatUhr: '', //Date
            Geldbetrag: this.formatCurrencyInput(calculatedAmount).toString().replace('€', '').trim(), //Amount of money
            GezahltAm: null,
            MahnDatum: this.nextMonth(null), //Reminder Date = +4weeks from today. '2019-06-12T00:00:00'
            MahnNr: 'HV', 
            Mahnung1: null,
            Mahnung2: null,
            Mahnung3: null,
            MwSt: '19,00', //VAT
            Rechnungsnummer: this.billNumber(), //Bill number
            Registernummer: this.caseId(), //CaseID/Register number
            Restbetrag: '', //Balance
            Sachbearbeiter: sachbearbeiter, //Clerk
            SchreibenVom: moment.utc().format(),// '2019-05-12T00:00:00', //Writing From
            Status: 'O',
            Wegen: 'Gebührenerfassung RVG' //Reason: Gebührenrefassung RVG
        }

        let resultType = (await RNSAPI.addReminder(reminder)).Type;
        if (resultType !== 'AddSuccessful') {
            $("#openRVGErrorModal").modal('show');
            this.rvgErrorText('Der Eintrag konnte der Mahnliste nicht zugeordnet werden.');
        }
    }

    private async setLastCase(): Promise<void> {
        let data = (await RNSAPI.receiveUserData()).lastVisitedCaseId;
        this.caseId(data);
    }

    private async addAccounting(): Promise<void> {
        let calculatedAmount = 0;
        for (let i = 0; i < this.rvgList().length; i++) {
            if (this.rvgList()[i].type === '=') {
                calculatedAmount = parseFloat(this.rvgList()[i].amount.toString().replace(',','.'));
            }
        }

        let betrag;
        for (let i = 0; i < this.rvgList().length; i++) {
            if (this.rvgList()[i].type === 'U19') {
                betrag = this.rvgList()[i - 1].amount;
            }
        }

    
        let accounting = {
            AdressatSuchbegriff: this.selectedRVGMandant(), 
            AdressatTyp: '2', 
            Betrag: betrag ? this.formatCurrencyInput(betrag).toString().replace('€', '').trim() : calculatedAmount, 
            Currency: '€', 
            Endsumme: this.formatCurrencyInput(calculatedAmount).toString().replace('€', '').trim(), 
            IsLawOrNotaryOffice: 'A',
            Rechnungsdatum: moment().format(), //Invoice date.
            Rechnungsnummer: this.billNumber(), //Bill number
            Registernummer: this.caseId(), //CaseID/Register number
            Status: 'R',
        }

    
        let resultType = (await RNSAPI.addAccounting(accounting)).Type;

        if (resultType !== 'AddSuccessful') {
            $("#openRVGErrorModal").modal('show');
            this.rvgErrorText('Der Eintrag konnte dem Rechnungsausgangsbuch nicht zugeordnet werden.');
        }

    }

    private async downloadInvoice(docName: string, docId: string): Promise<void> {
        let element = document.createElement('a');
        let doc;
        await RNSAPI.getDocumentData(docId).then((response) => {
            doc = response.Payload.Document;
            let IsRTF = atob(doc.DocumentData.substr(0, 50)).indexOf("rtf") !== -1;
            let type = IsRTF ? "rtf" : doc.OLE2Type.trim().toLowerCase();
    
            let blob = Utils.base64ToBlob(doc.DocumentData, 'application/octet-stream');
    
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob);
            } else {
                element.setAttribute('href', 'data:application/octet-stream;charset=utf-16le;base64,' + doc.DocumentData);
                element.setAttribute('download', `${docName.trim()}.${type}`);
    
                element.style.display = 'none';
                document.body.appendChild(element);
    
                element.click();
    
                document.body.removeChild(element);
            }
        });
    }

    private generateRVGListObject(): any {
        return this.rvgList().map(item => {
            return {
                Art: item.type,
                Nummer: item.number,
                Bezeichnung: item.description,
                Gebuehrensatz: item.feeRate,
                Streitwert: item.valueDispute.toString().replace('€', '').trim(),
                Betrag: parseFloat(item.amount.toString().replace(',','.')).toFixed(2).toString(),
                USt: item.tax
            }
        });
    }

    private async suggestBillNumber(): Promise<void> {
        let billNumber = (await RNSAPI.rvgSuggestId()).Payload.Rechnungsnummer;
        this.billNumber(billNumber);
    }

    private async setCourtValues(): Promise<void> {
        const caseData = (await RNSAPI.getCaseByCaseId(this.caseId())).Payload.Case;
        if(caseData.WertGericht1 !== 0) {
            this.rvgValueDispute(caseData.WertGericht1);
        }

        if(caseData.WertGericht2 !== 0) {
            this.rvgValueDispute(caseData.WertGericht2);
        }

        if(caseData.WertGericht3 !== 0) {
            this.rvgValueDispute(caseData.WertGericht3);
        }
    }

    
    private pickGeneric(title, keys, columns, data): void {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    };

    private formatCurrencyInput(input: number): string {
        if (isNaN(input)) return "0,00 €";
        const options = { style: 'currency', currency: 'EUR' };
        const numberFormat = new Intl.NumberFormat('de-DE', options);
        return numberFormat.format(input);
    }

    private calculateSum(): number {
        let calculatedSum: number = 0;
        for(let i=0; i<this.rvgList().length; i++) {
            if(this.rvgList()[i].type === '=') continue;
            calculatedSum += parseFloat(this.rvgList()[i].amount.toString().replace(',','.'));
        }
        calculatedSum = Math.round(calculatedSum * 100) / 100;
        return calculatedSum;
    }

    private calculateSumWithUSt(): [number, number] {
        let calculatedUStSum: number = 0;
        let calculatedAmount: number = 0;
        for(let i=0; i<this.rvgList().length; i++) {
            if(this.rvgList()[i].type === '=') continue;
            if(this.rvgList()[i].tax.includes('19,00') || this.rvgList()[i].tax.includes('16,00')) {
                calculatedAmount += parseFloat(this.rvgList()[i].amount.toString().replace(',', '.'));
            }
            calculatedUStSum += parseFloat(this.rvgList()[i].amount.toString().replace(',', '.')) * (parseInt(this.rvgList()[i].tax.toString().replace(',','.')) / 100);

        }
        return [calculatedUStSum, calculatedAmount];
    }

    private clearRVGModal(): void {
        this.rvgValueDispute('0,00');
        this.rvgSelectedTax('19,00');
        this.rvgNumber('');
        this.rvgInstance('');
        this.rvgFeeRate('');
        this.rvgDescription('');
        this.rvgAmount('0,00 €');
        this.rvgMinAmount('');
        this.rvgMiddleAmount('');
        this.rvgMaxAmount('');
        this.rvgAmountUnformated('0,00');

        this.allItems([]);

        $("#collapseExampleAbr").removeClass('show');
    }

    private clearZuzugModal(): void {
        this.influxAmount('0,00');
        this.influxDescription('Zuzüglich');
        this.rvgSelectedTax('0,00');
    }

    private clearAbzugModal(): void {
        this.deductionAmount('0,00');
        this.deductionDescription('Vorschuss');
        this.rvgSelectedTax('0,00');
    }

    private clearTaxFreeModal(): void {
        this.taxFreeDescription('Steuerfreie Auslagen');
        this.taxFreeAmount('0,00');
        this.rvgSelectedTax('0,00');
    }

    private clearTaxModal(): void {
        this.taxDescription('Steuerpflichtige Auslagen');
        this.taxAmount('0,00');
        this.rvgSelectedTax('19,00');
    }

    private async loadCases(): Promise<void> {
        let cases = [];
        await RNSAPI.getCasesOverview(0, ko.toJS(20)).then((data) => {
            cases = data as any;
        }).catch((error) => {
            cases = [];
        });

        this.allCases(cases); //(await RNSAPI.getCases()).Payload.Cases
    }
    

    async selectCase(): Promise<void> {
        await this.loadCases();
        let cases = ko.toJS(this.allCases());
        this.pickGeneric("Akte", ["caseid", "rubrum"], ["Akte", "Rubrum"], cases);
        this.modalHandleSelection((selectedObject) => {
            this.caseId(selectedObject().caseid);
        });
        $('#modal').modal('show');
    }
}


let html = fs.readFileSync(__dirname + '/rvg.html', 'utf8');

ko.components.register("rvg-view", {
    viewModel: RVGViewModel,
    template: html
});
