import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { User } from "../../UserData";
import { MainViewModel } from "../../main";

export class LoginViewModel {
    token = ko.observable("");

    public login() {
        RNSAPI.login(this.token());
        MainViewModel.RoutingTable.showDashboardView();
    }

    constructor() {
        if (RNSAPI.Token) this.token(RNSAPI.Token);
    }
}

var html = fs.readFileSync(__dirname + '/loginToken.html', 'utf8');

ko.components.register("loginToken-view", {
    viewModel: LoginViewModel,
    template: html
});