﻿import * as fs from "fs";
import * as ko from "knockout";
import { stringify } from "querystring";
import { RNSAPI } from "../../api";
import { Postbox } from "../dashboard/postbox";

export class DropdownsearchView {
    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalSelectedObject = ko.observable(null);
    modalHandleSelection = ko.observable();
    givenId = "searchdrop";
    AllCases = ko.observableArray([]);
    ShownData = ko.observableArray();
    AllSB = ko.observableArray();
    AllMailAddresses = ko.observableArray();
    //ShownSB = ko.observableArray();
    AllAddress = ko.observableArray();
    EntLength = ko.observable(0);
    ShownDataCount = ko.observable(0);
    SearchMode = ko.observable(0);
    Rubrum = ko.observable("");
    allDeadlineExtensionReasons = ko.observableArray([]);
    placeholderText = ko.observable("Geben Sie ein Suchwort zur Akte ein");
    maxLength = ko.observable(13);
    isKanzleipostfach = ko.observable(false);

    isRequired = ko.observable(true);


    attachmentMap = new Map<string, string>();

    changedEntryUnfocus() {
        this.ShownDataCount(0);
        this.EntLength(0);
    }

    changedEntry() {
        let Entry: any = (<HTMLInputElement>document.getElementById(this.givenId)).value;
        let Counter = 0;
        this.ShownData([]);
      

        this.EntLength(1);
        if (ko.toJS(this.SearchMode) === 1) {
            for (let i = 0; i < this.AllSB().length; i++) {
                if (ko.toJS(this.AllSB()[i]).includes(Entry)) {
                    this.ShownData.push(this.AllSB()[i]);
                    Counter++;
                }

            }
        }
        else if (ko.toJS(this.SearchMode) === 2) {
            for (let i = 0; i < this.AllAddress().length; i++) {
                if (ko.toJS(this.AllAddress()[i]).includes(Entry)) {
                    this.ShownData.push(this.AllAddress()[i]);
                    Counter++;
                }

            }
        }
        else if (ko.toJS(this.SearchMode) === 3) {
            for (let i = 0; i < this.allDeadlineExtensionReasons().length; i++) {
                if (ko.toJS(this.allDeadlineExtensionReasons()[i]).includes(Entry)) {
                    this.ShownData.push(this.allDeadlineExtensionReasons()[i]);
                    Counter++;
                }

            }
        }
        else if(ko.toJS(this.SearchMode) === 4) {
            for(let [key, value] of this.attachmentMap) {
                if(value.includes(Entry)) {
                    this.ShownData.push({ "Key": key, value });
                    Counter++;
                }
            }
        }
        else if(ko.toJS(this.SearchMode) === 5 || ko.toJS(this.SearchMode) === 6) {
            for(let i=0; i< this.AllMailAddresses().length; i++) {
                if(ko.toJS(this.AllMailAddresses()[i]).includes(Entry)) {
                    this.ShownData.push(this.AllMailAddresses()[i]);
                    Counter++;
                }
            }
        }
        else {
            for (let i = 0; i < this.AllCases().length; i++) {
                if (!isNaN(Entry) && ko.toJS(this.AllCases()[i].Registernummer).includes(Entry)) {
                    this.ShownData.push(this.AllCases()[i].Registernummer + " " + this.AllCases()[i].Rubrum);
                    Counter++;
                } else {
                    if (ko.toJS(this.AllCases()[i].Rubrum.toLowerCase()).includes(Entry.toLowerCase())) {
                        this.ShownData.push(this.AllCases()[i].Registernummer + " " + this.AllCases()[i].Rubrum);
                        Counter++;
                    }
                }

            }
        }
        this.ShownDataCount(Counter);
    }

    async getCases() {
        let ExtractCaseNumber = (await RNSAPI.getCases()).Payload.Cases;
        for (let i = 0; i < ExtractCaseNumber.length; i++) {
            this.AllCases.push({
                Registernummer: ExtractCaseNumber[i].Registernummer,
                Rubrum: ExtractCaseNumber[i].Rubrum
            })
        }
    }

    async getSB() {
        let ExtractSB = (await RNSAPI.getSachbearbeiter()).Payload.Clerks;
        for (let i = 0; i < ExtractSB.length; i++) {
            this.AllSB.push(ExtractSB[i].Sachbearbeiter);
        }
    }
    async getClients() {
        let ExtractAddress = (await RNSAPI.getPersonsForScope("Mandant")).Payload.Addresses;
        console.log(ExtractAddress);
        for (let i = 0; i < ExtractAddress.length; i++) {
            this.AllAddress.push(ExtractAddress[i].Keyword);
        }
    }



    async getAllMailAddresses() {
        await RNSAPI.getAllMailAddresses().then((response) => {
            for(const data of response["AddressMails"]) {
                if(data.Mail === "") continue;
                this.AllMailAddresses.push(data.Name + " <" + data.Mail + ">");
            }
        }).catch((error) => {

        });
    }

    async getFristDeadlineReasons() {
        let res = (await RNSAPI.getFristDeadlineReasons());
        if (res.Type === "GetFristExtensionReasonsSuccessful") {
            let result = res.Payload.DeadlineExtendReasonTypes;
            for (let i = 0; i < result.length; i++) {
                this.allDeadlineExtensionReasons.push(result[i].Reason);
            }
        }
    }

    async getAllFileAttachments(caseId: string) {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' } as const;

        let records = (await RNSAPI.getCaseEntriesForId(caseId)).Payload.Records;
        let sortedRecords = records.sort((a, b) => {
            let dateA = new Date(a.LastChange || a.DocumentDate || a.RecordDate);
            let dateB = new Date(b.LastChange || b.DocumentDate || b.RecordDate);
            if (dateA < dateB) return 1;
            else if (dateA > dateB) return -1;
            else return 0
        });
        let mappedRecords = sortedRecords.map(record => {
            record.DocumentDate = (new Date(record.LastChange || record.DocumentDate || record.RecordDate)).toLocaleDateString('de-DE', options);
            return record;
        });

        for(const data in mappedRecords) {
            if(mappedRecords[data]["CaseId"] !== caseId) continue;
            const text = mappedRecords[data]["DocumentDate"] + " - " + mappedRecords[data]["Subject"];
            this.attachmentMap.set(mappedRecords[data]["Id"], mappedRecords[data]["Subject"]);
        }
    }

    public setInputEntry(entry: any) {
        if (ko.toJS(this.SearchMode) !== 1 && ko.toJS(this.SearchMode) !== 2 && ko.toJS(this.SearchMode) !== 3 && ko.toJS(this.SearchMode) !== 4 && ko.toJS(this.SearchMode) !== 5 && ko.toJS(this.SearchMode) !== 6) {
            for (let i = 0; i < this.AllCases().length; i++) {
                let data = this.AllCases()[i].Registernummer + " " + this.AllCases()[i].Rubrum;
                if (data === entry) {
                    (<HTMLInputElement>document.getElementById(this.givenId)).value = data;
                }
            }
        }
        else {
            (<HTMLInputElement>document.getElementById(this.givenId)).value = entry;
            if(ko.toJS(this.SearchMode) === 4) {
                Postbox.publisher().publish(entry, "DropDownSearchEntry");
                $("#"+this.givenId+"").val("");
            }
        }
        if (ko.toJS(this.isKanzleipostfach))
            Postbox.publisher().publish(entry, "adressesForCase");

        this.ShownDataCount(0);
    }

    private handleCaseId() {
        this.getAllFileAttachments($("#CaseSearchsearchdrop").val().toString());
    }

    constructor(params: any) {
        if (params.prefix) {
            this.givenId = params.prefix + this.givenId;
            if (params.prefix === "CaseSearchKanzleipostfach")
                this.isKanzleipostfach(true);
        }

        if (params.dataparam === "SB") {
            this.getSB();
            this.SearchMode(1);
            //Change Placeholder Text
        }
        else if (params.dataparam === "Clie") {
            this.getClients();
            this.SearchMode(2);
        }
        else if (params.dataparam === "FVG") {
            this.getFristDeadlineReasons();
            this.SearchMode(3);
            this.placeholderText("Geben Sie ein Suchwort für den Fristenverlängerungsgrund an");
            this.maxLength(250);
        }
        else if(params.dataparam === "ECaseFiles") {
            this.placeholderText("Geben Sie ein Suchwort für einen Dateianhang ein");

            if(params.caseId) {
                this.getAllFileAttachments(params.caseId);
            } else {
                setTimeout(() => { this.handleCaseId() }, 1000);
            }
            this.SearchMode(4);
            this.isRequired(false);
        }
        else if(params.dataparam === "SearchMail") {
            this.getAllMailAddresses();
            this.SearchMode(5);
            this.placeholderText("Geben Sie ein Suchwort für einen Vor- und Nachnamen oder E-Mail Adresse ein");
        }
        else if(params.dataparam === "SearchMailCC") {
            this.getAllMailAddresses();
            this.SearchMode(6);
            this.isRequired(false);
            this.placeholderText("Geben Sie ein Suchwort für einen Vor- und Nachnamen oder E-Mail Adresse ein");
        }
        else
            this.getCases();
    }
}

let html = fs.readFileSync(__dirname + '/dropdownsearch.html', 'utf8');

ko.components.register("dropdownsearch-view", {
    viewModel: DropdownsearchView,
    template: html
});