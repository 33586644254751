import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI, Routing } from "../../api";
import { TIMEOUT } from "dns";

class WopiViewModel {
    actionUrl: ko.Computed<String>
    wopiToken = {}
    subscriptions = []
    observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.type === "attributes") {
                this.showOfficeFrame();
            }
        });
    });

    showOfficeFrame() {
        let frameholder = document.getElementById('frameholder');
        let office_frame = document.createElement('iframe');

        office_frame.name = 'office_frame';
        office_frame.id = 'office_frame';
        // The title should be set for accessibility
        office_frame.title = 'Office Online Frame';
        // This attribute allows true fullscreen mode in slideshow view
        // when using PowerPoint Online's 'view' action.
        office_frame.setAttribute('allowfullscreen', 'true');
        if (frameholder.firstChild) frameholder.replaceChild(office_frame, frameholder.firstChild);
        else frameholder.appendChild(office_frame);
        (document.getElementById('office_form') as any).submit();
    }

    constructor(params) {
        //remember that we need to send the form AFTER the rendering takes place, not when the url is updated
        let config = { attributes: true, childList: true, characterData: true };
        this.observer.observe(document.getElementById("office_form"), config);
        //console.log('wopi params: ', params)

        this.wopiToken = RNSAPI.Token;

        this.subscriptions.push(this.actionUrl = ko.computed(() => {
            let id = params.id();
            let mimeType = params.mimeType();
            let wopiSrc = encodeURIComponent(`${RNSAPI.origin}/wopi/files/${id}`);
            //console.log('wopi src: ', wopiSrc)

            return `${RNSAPI.config.office}/loleaflet/8a1761a/loleaflet.html?WOPISrc=${wopiSrc}&title=foo&lang=de-DE&revisionhistory=1^`;
        }));


    }

    dispose() {
        for (var s of this.subscriptions) s.dispose();
        this.observer.disconnect();
    }
}

let html = fs.readFileSync(__dirname + '/wopi.html', 'utf8');

ko.components.register("wopi-view", {
    viewModel: WopiViewModel,
    template: html
});