export let layoutMietrecht = JSON.parse(`{
  "Identifikationsdaten": [
    [
      {
        "Label": "Identifikationsdaten",
        "Type": "HEADLINE",
        "Size": 12,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Registernummer*",
        "Database": "",
        "DbText": "LRegnr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "Registernummer",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 13,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Referat*",
        "Database": "",
        "DbText": "lbReferat",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "Referat",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 2,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Referat",
        "SelectionProperty": "Id"
      },
      {
        "Label": "",
        "Database": "Nein/Undefiniert",
        "DbText": "Zuo_refnr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Referat",
        "SelectionProperty": "Name"
      }
    ],
    [
      {
        "Label": "Sachbearbeiter*",
        "Database": "",
        "DbText": "LblSachbearbeiter",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "SachbearbeiterId",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 2,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sachbearbeiter",
        "SelectionProperty": "Sachbearbeiter_ID"
      },
      {
        "Label": "",
        "Database": "Nein/Undefiniert",
        "DbText": "Zuo_sachnr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Sachbearbeiter",
        "SelectionProperty": "Sachbearbeiter"
      }
    ],
    [
      {
        "Label": "Anlagedatum",
        "Database": "",
        "DbText": "lblAnlagedatum",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "AnlageDatum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 10,
        "Alignment": null,
        "WebInfo": "Datum",
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Rubrum*",
        "Database": "",
        "DbText": "LblRubrum",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "Rubrum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 64,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Wegen*",
        "Database": "",
        "DbText": "LblWegen",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "Wegen",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 64,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Kennz. Ausl Mandant",
        "Database": "",
        "DbText": "LKausmd",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "IsAuslaendischesMandat",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 1,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Kennz. Beitr. Eigene",
        "Database": "",
        "DbText": "Lkbeiei",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "IsEigeneBeitreibung",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 1,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "VorSt. Abzugsber. Mdt.",
        "Database": "",
        "DbText": "LKvstab",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": null,
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 1,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Mandant ist Kläger",
        "Database": "",
        "DbText": "LMdtKläger",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": null,
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 1,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "AZ Mandant",
        "Database": "",
        "DbText": "LAzmdt",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": null,
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Organisationsdaten": [
    [
      {
        "Label": "Organisationsdaten",
        "Type": "HEADLINE",
        "Size": 12,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "WV Grund",
        "Database": "",
        "DbText": "LblWVGrund",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "FristNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Nein/Undefiniert",
        "DbText": "Zuo_frinr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "WV Ablauf",
        "Database": "",
        "DbText": "LblWVAblauf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "FristDatum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 10,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Sachstand",
        "Database": "",
        "DbText": "LblSachbestand",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": null,
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Nein/Undefiniert",
        "DbText": "Zuo_Sachstand",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Mahnkennung",
        "Database": "",
        "DbText": "Lmahnnr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "MahnNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Nein/Undefiniert",
        "DbText": "Zuo_Mahnnr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Gegenstandswert ",
        "Database": "",
        "DbText": "LWert1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": null,
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 12,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "MEK",
        "Database": "",
        "DbText": "LMEK",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Akte",
        "DbText": "MEK",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 12,
        "Alignment": null,
        "WebInfo": "Datum",
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Mandatsherkunft",
        "Database": "",
        "DbText": "LblMandat",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Values": [
          "",
          "Empfehlung",
          "Homepage/Internet",
          "Telefonbuch",
          "Regionale Anzeige",
          "Rechtsberater Kontaktformular",
          "Rechtsberater Hilfehotline",
          "Rechtsberater Anwaltshotline",
          "ADAC",
          "Sonstiges"
        ],
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Mandat",
        "Type": "COMBOBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Sachverhalt": [
    {
      "Name": "Allgemein",
      "Elements": [
        [
          {
            "Label": "Mandantenbegehren",
            "Database": "",
            "DbText": "LblMandantenbegehren",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Notiz_Mandantenbegehren",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Notiz",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sachverhalt",
            "Database": "",
            "DbText": "LblSachverhalt",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Notiz_Sachverhalt",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Notiz",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fallkonstellation",
            "Database": "",
            "DbText": "LblFallkonstellation",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Fallkonstellation",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Notiz",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Abrechnungsdaten",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zahlungsart",
            "Database": "",
            "DbText": "LblZahlungsart",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Selbstzahler",
              "Rechtsschutzversicherung",
              "Prozesskostenhilfe",
              "RECHT-nah Card"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Zahlungsart",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ertragsverteilung",
            "Database": "",
            "DbText": "LblErtragsverteilung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "100%",
              "50% : 50%",
              "30% : 70%"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Ertragsverteilung",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Nur bei Rechtsschutzversicherung:",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Versicherung",
            "Database": "",
            "DbText": "LblVersicherung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Versicherung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 20,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Versicherungsnummer",
            "Database": "",
            "DbText": "LblVersicherungsnr",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Versicherungsnr",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 30,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Selbstbeteiligung",
            "Database": "",
            "DbText": "LblSelbstbeteiligung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Selbstbeteiligung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 30,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schadensnummer",
            "Database": "",
            "DbText": "LblSchadensnummer",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Schadensnummer",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 30,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Deckungszusage erhalten",
            "Database": "",
            "DbText": "LblBereits_Deckungszusage",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja ": "Ja_Deckungszusage_erhalten",
              "Nein ": "Nein_Deckungszusage_erhalten"
            },
            "Default": "True",
            "Label": "Deckungszusage_erhalten",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Nur bei RECHT-nah Card",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kartennummer",
            "Database": "",
            "DbText": "LblRechtNahCard_Kartennummer",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "RechtNahCard_Kartennummer",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Belege / Dokumente:",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kopie Personalausweis",
            "Database": "XML/Default",
            "DbText": "ChkDok_Personalausweiskopie",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schreiben Übernahme VKH-Kosten",
            "Database": "XML/Default",
            "DbText": "ChkDok_SchreibenZuProzesskostenuebernahme",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Deckungszusage",
            "Database": "XML/Default",
            "DbText": "ChkDok_Deckungszusage",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Berechtigungsschein",
            "Database": "XML/Default",
            "DbText": "ChkDok_Berechtigungsschein",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "1. Daten Mietvertrag",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mietvertrag Datum Abschluss",
            "Database": "",
            "DbText": "LblMietvertrag_Datum_Abschluss",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Mietvertrag_Datum_Abschluss",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mietvertrag wirksam ab",
            "Database": "",
            "DbText": "LblMietvertrag_wirksam_ab",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Mietvertrag_wirksam_ab",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mietvertrag Netto-Mietzins",
            "Database": "",
            "DbText": "LblMietvertrag_NettoMietzins",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Mietvertrag_NettoMietzins",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mietvertrag Betriebskosten",
            "Database": "",
            "DbText": "LMietvertrag_Betriebskosten",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Mietvertrag_Betriebskosten",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mietzins Fälligkeit",
            "Database": "",
            "DbText": "LblMietzins_Faelligkeit",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Mietzins_Faelligkeit",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Netto-Mietzins aktuell",
            "Database": "",
            "DbText": "LblNettoMietzins_aktuell",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "NettoMietzins_aktuell",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Betriebskosten aktuell",
            "Database": "",
            "DbText": "LblBetriebskosten_aktuell",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Betriebskosten_aktuell",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Brutto-Mietzins aktuell",
            "Database": "",
            "DbText": "LblBrutto_Mietzins_aktuell",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Brutto_Mietzins_aktuell",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Einzugsdatum",
            "Database": "",
            "DbText": "LEinzugsdatum",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Einzugsdatum",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Anzahl Personen in Wohnung",
            "Database": "",
            "DbText": "LblAnzahl_Personen_in_Wohnung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Anzahl_Personen_in_Wohnung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "2. Daten Mietobjekt",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wohnung Adresse",
            "Database": "",
            "DbText": "LblWohnung_Adresse",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Wohnung_Adresse",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wohnung Lage",
            "Database": "",
            "DbText": "LWohnung_Lage",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Wohnung_Lage",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wohnung Baujahr",
            "Database": "",
            "DbText": "LblWohnung_Baujahr",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Wohnung_Baujahr",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wohnung Beschreibung",
            "Database": "",
            "DbText": "LWohnung_Beschreibung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Wohnung_Beschreibung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wohnung Qm-Zahl",
            "Database": "",
            "DbText": "LblWohnung_Qm_Zahl",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Wohnung_Qm_Zahl",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wohnung Wohnlage",
            "Database": "",
            "DbText": "LblWohnung_Wohnlage",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              " ",
              "in gehobener Wohnlage",
              "in städtischer Wohnlage",
              "in ländlicher Wohnlage"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Wohnung_Wohnlage",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wohnung Ausstattung",
            "Database": "",
            "DbText": "LblWohnung_Ausstattung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              " ",
              "mit einfacher Ausstattung",
              "mit mittlerer Ausstattung",
              "mit gehobener Ausstattung"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Wohnung_Ausstattung",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "3. Zugehörige Dokumente",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mietvertrag (mit Übergabeprotokoll)",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Mietvertrag",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Rechte wegen Mängel",
      "Elements": [
        [
          {
            "Label": "1. Allgemeine Daten Mietmangel",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "1a. Mängelbeschreibung",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Headers": [
              "Konkrete Beschreibung"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Maengelbeschreibung",
            "Type": "TABLE",
            "Size": 9,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "1b. Mängelanzeige",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Headers": [
              "Datum Feststellung",
              "Datum erste Anzeige",
              "Datum 1. Frist Maengelbeseitigung",
              "Erste Anzeigeform",
              "Reaktion des Vermieters",
              "Datum zweite Anzeige",
              "Datum 2. Frist Maengelbeseitigung",
              "Zweite Anzeigeform"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Maengelanzeige",
            "Type": "TABLE",
            "Size": 9,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Angaben zum Mängel",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Heizungsausfall",
            "Database": "XML/Default",
            "DbText": "Mietmaengel_Heizungsausfall",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schimmel in der Wohnung",
            "Database": "XML/Default",
            "DbText": "Mietmaengel_SchimmelWohnung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Feuchtigkeit in der Wohnung",
            "Database": "XML/Default",
            "DbText": "Mietmaengel_FeuchtigkeitWohnung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Toilette funktioniert nicht",
            "Database": "XML/Default",
            "DbText": "Mietmaengel_ToiletteFunktioniertNicht",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Die Wohnungstür ist defekt",
            "Database": "XML/Default",
            "DbText": "Mietmaengel_WohnungstuerDefekt",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Thermostatventile fehlen oder sind defekt",
            "Database": "XML/Default",
            "DbText": "Mietmaengel_ThermostatventileDefekt",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Lärmbelästigung durch Baulärm",
            "Database": "XML/Default",
            "DbText": "Mietmaengel_LarmbelaestigungBaulaerm",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Lärmbelästigung allgemein",
            "Database": "XML/Default",
            "DbText": "Mietmaengel_LarmbelaestigungAllgemein",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kabelanschluss / TV-Empfang defekt",
            "Database": "XML/Default",
            "DbText": "Mietmaengel_KabelanschlussTVDefekt",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Probleme mit der Warmwasserversorgung",
            "Database": "XML/Default",
            "DbText": "Mietmaengel_ProblemWarmwasserversorgung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fenster sind undicht",
            "Database": "XML/Default",
            "DbText": "Mietmaengel_FensterUndicht",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Türschloss ist defekt",
            "Database": "XML/Default",
            "DbText": "Mietmaengel_TuerschlossDefekt",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Dusche ist defekt",
            "Database": "XML/Default",
            "DbText": "Mietmaengel_DuscheDefekt",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Problem mit dem Fahrstuhl",
            "Database": "XML/Default",
            "DbText": "Mietmaengel_FahrstuhlProblem",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fussboden mangelhaft",
            "Database": "XML/Default",
            "DbText": "Mietmaengel_FussbodenMangelhaft",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schädlinge in der Wohnung",
            "Database": "XML/Default",
            "DbText": "Mietmaengel_SchaedlingeWohnung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Belästigungen durch Tiere in der Nachbarschaft",
            "Database": "XML/Default",
            "DbText": "Mietmaengel_BelaestigungTierNachbarschaft",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wohnhaus (Treppenhaus, Hof, Garten, ...) dauerhaft stark verschmutzt",
            "Database": "XML/Default",
            "DbText": "Mietmaengel_WohnhausVerschmutzt",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Tatsächliche Größe der Wohnung kleiner als im Mietvertrag angegeben",
            "Database": "XML/Default",
            "DbText": "Mietmaengel_WohnungKleinerMietvertrag",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "2. Schadensersatz wegen Mangel",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "2a. Allgemeine Daten Schadensersatz",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Aufforderung Schadensregulierung",
            "Database": "",
            "DbText": "LblAufforderung_Schadensregulierung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Aufforderung_Schadensregulierung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Frist Schadensregulierung",
            "Database": "",
            "DbText": "LblFrist_Schadensregulierung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Frist_Schadensregulierung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Geforderte Gesamtsumme ",
            "Database": "",
            "DbText": "LblGeforderte_Gesamtsumme_Schadensersatz",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Geforderte_Gesamtsumme_Schadensersatz",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "2b. Sachschaden wegen Mangel",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Headers": [
              "Schadensbeschreibung 1",
              "Anschaffungskosten",
              "Schadenshöhe",
              "Erworben am",
              "Verursacht durch Mangel 1"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Sachschaden_wegen_Mangel",
            "Type": "TABLE",
            "Size": 9,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "2c. Personenschaden wegen Mangel",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Headers": [
              "Vorname, Name",
              "Schadensbeschreibung 2",
              "Verursacht durch Mangel 2",
              "Ärztl. Attest",
              "Krankschreibung von",
              "Krankschreibung bis",
              "Ärztl. Behandlung von",
              "Ärztl. Behandlung bis",
              "Kosten Medikament 1",
              "Kosten Medikament 2",
              "Verdienstausfall",
              "Bezeichnung sonstige Schadensposition",
              "Summe sonstige Schadensposition"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Personenschaden_wegen_Mangel",
            "Type": "TABLE",
            "Size": 9,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "3. Ersatzunterkunft",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Beschreibung Ersatzunterkunft",
            "Database": "",
            "DbText": "LblBeschreibung_Ersatzunterkunft",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Beschreibung_Ersatzunterkunft",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Einzug Ersatzunterkunft",
            "Database": "",
            "DbText": "LblDatum_Einzug_Ersatzunterkunft",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Einzug_Ersatzunterkunft",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Auszug Ersatzunterkunft",
            "Database": "",
            "DbText": "LblDatum_Auszug_Ersatzunterkunft",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Auszug_Ersatzunterkunft",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gesamtkosten Ersatzunterkunft",
            "Database": "",
            "DbText": "LblGesamtkosten_Ersatzunterkunft",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Gesamtkosten_Ersatzunterkunft",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Umzugskosten Ersatzunterkunft",
            "Database": "",
            "DbText": "LblUmzugskosten_Ersatzunterkunft",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Umzugskosten_Ersatzunterkunft",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Aufforderung Zahlung Kosten Ersatzunterkunft",
            "Database": "",
            "DbText": "LblDatum_Aufforderung_Zahlung_Kosten_Ersatzunterkunft",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Aufforderung_Zahlung_Kosten_Ersatzunterkunft",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Frist Aufforderung Zahlung Kosten Ersatzunterkunft",
            "Database": "",
            "DbText": "LblFrist_Aufforderung_Zahlung_Kosten_Ersatzunterkunft",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Frist_Aufforderung_Zahlung_Kosten_Ersatzunterkunft",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "4. Selbstvornahmekosten",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Aufforderung Zahlung SV-Kosten",
            "Database": "",
            "DbText": "LDatum_Aufforderung_Zahlung_SVKosten",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Aufforderung_Zahlung_SVKosten",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Frist zur Zahlung der Selbstvornahmekosten",
            "Database": "",
            "DbText": "LFrist_zur_Zahlung_Selbstvornahmekosten",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Frist_zur_Zahlung_Selbstvornahmekosten",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Beauftragung Firma ",
            "Database": "",
            "DbText": "LDatum_Beauftragung_Firma",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Beauftragung_Firma",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Selbstvornahme durch Mieter",
            "Database": "",
            "DbText": "LDatum_Selbstvornahme_durch_Mieter",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Selbstvornahme_durch_Mieter",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Durch Selbstvornahme des Mieters beseitigte Mängel",
            "Database": "",
            "DbText": "LDurch_Selbstvornahme_des_Mieters_beseitigte_Maengel",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Durch_Selbstvornahme_des_Mieters_beseitigte_Maengel",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Anzahl Stunden",
            "Database": "",
            "DbText": "LAnzahl_Stunden",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Anzahl_Stunden",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Tätigkeit des Mieters zu Grunde gelegter Stundenlohn",
            "Database": "",
            "DbText": "LTaetigkeit_Mieter_zu_Grunde_gelegter_Stundenlohn",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Taetigkeit_Mieter_zu_Grunde_gelegter_Stundenlohn",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Aufzählung der von Hand beseitigten Mängel",
            "Database": "",
            "DbText": "LAufzaehlung_der_von_Hand_beseitigten_Maengel",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Aufzaehlung_der_von_Hand_beseitigten_Maengel",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Höhe der gesamten Selbstvornahmekosten durch Mieter ",
            "Database": "",
            "DbText": "LHoehe_der_gesamten_Selbstvornahmekosten_durch_Mieter",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Hoehe_der_gesamten_Selbstvornahmekosten_durch_Mieter",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Höhe Gesamtkosten Selbstvornahme (Firma und Mieter) ",
            "Database": "",
            "DbText": "LHoehe_Gesamtkosten_Selbstvornahme",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Hoehe_Gesamtkosten_Selbstvornahme",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Selbstvornahmekosten",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Headers": [
              "Name Betrieb 1",
              "Höhe Rechnungsbetrag Betrieb 1",
              "Name Betrieb 2",
              "Datum Rechnung Betrieb 2",
              "Höhe Rechnungsbetrag Betrieb 2"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Tabelle_Selbstvornahmekosten",
            "Type": "TABLE",
            "Size": 9,
            "Skip": 3,
            "ReadOnly": true,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "5. Mietminderung",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Höhe Mietminderung",
            "Database": "",
            "DbText": "LHoehe_Mietminderung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Hoehe_Mietminderung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gesamte Mietminderungshöhe in %",
            "Database": "",
            "DbText": "LGesamte_Mietminderungshoehe_Prozent",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Gesamte_Mietminderungshoehe_Prozent",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mietminderungshöhe Mangel 1",
            "Database": "",
            "DbText": "LblMietminderungshoehe_Mangel_1",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Mietminderungshoehe_Mangel_1",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mietminderungshöhe in % zum Mangel 1",
            "Database": "",
            "DbText": "LMietminderungshoehe_in_Prozent_zum_Mangel1",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Mietminderungshoehe_in_Prozent_zum_Mangel1",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mietminderungshöhe Mangel 2",
            "Database": "",
            "DbText": "LblMietminderungshoehe_Mangel_2",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Mietminderungshoehe_Mangel_2",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mietminderungshöhe in % zum Mangel 2",
            "Database": "",
            "DbText": "LMietminderungshoehe_in_Prozent_zum_Mangel2",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Mietminderungshoehe_in_Prozent_zum_Mangel2",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mietminderungshöhe Mangel 3",
            "Database": "",
            "DbText": "LblMietminderungshoehe_Mangel_3",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Mietminderungshoehe_Mangel_3",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mietminderungshöhe in % zum Mangel 3",
            "Database": "",
            "DbText": "LMietminderungshoehe_in_Prozent_zum_Mangel3",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Mietminderungshoehe_in_Prozent_zum_Mangel3",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mietminderungshöhe Mangel 4",
            "Database": "",
            "DbText": "LblMietminderungshoehe_Mangel_4",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Mietminderungshoehe_Mangel_4",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mietminderungshöhe in % zum Mangel 4",
            "Database": "",
            "DbText": "LblMietminderungshoehe_in_zum_Mangel_4",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Mietminderungshoehe_in_zum_Mangel_4",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mietminderungshöhe Mangel 5",
            "Database": "",
            "DbText": "LblMietminderungshoehe_Mangel_5",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Mietminderungshoehe_Mangel_5",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mietminderungshöhe in % zum Mangel 5",
            "Database": "",
            "DbText": "LblMietminderungshoehe_in_zum_Mangel_5",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Mietminderungshoehe_in_zum_Mangel_5",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Aufgelaufene Mietminderungshöhe",
            "Database": "",
            "DbText": "LblAufgelaufene_Mietminderungshoehe",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Aufgelaufene_Mietminderungshoehe",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Anfang Mietzahlung unter Vorbehalt",
            "Database": "",
            "DbText": "LblDatum_Anfang_Mietzahlung_unter_Vorbehalt",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Mietzahlung_unter_Vorbehalt",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Ende Mietzahlung unter Vorbehalt",
            "Database": "",
            "DbText": "LblDatum_Ende_Mietzahlung_unter_Vorbehalt",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Ende_Mietzahlung_unter_Vorbehalt",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "6. Höhe Rechtsanwaltskosten",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Höhe Rechtsanwaltskosten",
            "Database": "",
            "DbText": "LblHoehe_Rechtsanwaltskosten",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Hoehe_Rechtsanwaltskosten",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "7. Zugehörige Dokumente",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fotos",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Fotos_Mind",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sonstige Beweismittel",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Sonstige_Beweismittel_Mind",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mängelanzeige",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Maengelanzeige",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mietminderungsschreiben",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Mietminderungsschreiben",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Rechnungen",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Rechnungen",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gutachten",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Gutachten",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Klage",
            "Database": "",
            "DbText": "ChkDok_Klage_Rechte_wegen_Maengel",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Nebenkosten",
      "Elements": [
        [
          {
            "Label": "1. Nebenkostenabrechnung",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zeitraum Nebenkostenabrechnung",
            "Database": "",
            "DbText": "LZeitraum_Nebenkostenabrechnung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Zeitraum_Nebenkostenabrechnung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Nebenkostenabrechnung",
            "Database": "",
            "DbText": "LDatum_Nebenkostenabrechnung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Nebenkostenabrechnung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Zugang Nebenkostenabrechnung",
            "Database": "",
            "DbText": "LDatum_Zugang_Nebenkostenabrechnung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Zugang_Nebenkostenabrechnung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zeitraum Nebenkostenabrechnung (Vorjahr)",
            "Database": "",
            "DbText": "LZeitraum_Nebenkostenabrechnung_Vorjahr",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Zeitraum_Nebenkostenabrechnung_Vorjahr",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Nebenkostenabrechnung (Vorjahr)",
            "Database": "",
            "DbText": "LDatum_Nebenkostenabrechnung_Vorjahr",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Nebenkostenabrechnung_Vorjahr",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "2.  Aufforderung Erstellung Nebenkostenabrechnung",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Frist zur Erstellung NKA",
            "Database": "",
            "DbText": "LFrist_zur_Erstellung_NKA",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Frist_zur_Erstellung_NKA",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum 1. Aufforderung Erstellung NKA",
            "Database": "",
            "DbText": "LDatum_1Aufforderung_Erstellung_NKA",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_1Aufforderung_Erstellung_NKA",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zweite Frist zur Erstellung NKA",
            "Database": "",
            "DbText": "LZweite_Frist_zur_Erstellung_NKA",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Zweite_Frist_zur_Erstellung_NKA",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "3. Einsichtnahme Unterlagen Nebenkostenabrechnung",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Termins Vorschlag 1 zur Einsicht Unterlagen",
            "Database": "",
            "DbText": "LTermins_Vorschlag1_zur_Einsicht_Unterlagen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Termins_Vorschlag1_zur_Einsicht_Unterlagen",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Termins Vorschlag 2 zur Einsicht Unterlagen",
            "Database": "",
            "DbText": "LTermins_Vorschlag2_zur_Einsicht_Unterlagen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Termins_Vorschlag2_zur_Einsicht_Unterlagen",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Termins Vorschlag 3 zur Einsicht Unterlagen",
            "Database": "",
            "DbText": "LTermins_Vorschlag3_zur_Einsicht_Unterlagen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Termins_Vorschlag3_zur_Einsicht_Unterlagen",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Anschreiben Einsicht Unterlagen",
            "Database": "",
            "DbText": "LDatum_Anschreiben_Einsicht_Unterlagen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Anschreiben_Einsicht_Unterlagen",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Frist Einsicht Unterlagen",
            "Database": "",
            "DbText": "lFrist_Einsicht_Unterlagen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Frist_Einsicht_Unterlagen",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Anschreiben Zusendung Unterlagen",
            "Database": "",
            "DbText": "LDatum_Anschreiben_Zusendung_Unterlagen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Anschreiben_Zusendung_Unterlagen",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Frist Zusendung Unterlagen",
            "Database": "",
            "DbText": "LFrist_Zusendung_Unterlagen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Frist_Zusendung_Unterlagen",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "4. Überprüfung Nebenkostenabrechnung",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Headers": [
              "Beanstandete",
              "Exakte Bezeichnung",
              "Rechnungssumme",
              "Eigene Rechnungssumme",
              "Rechnungssumme in Vorjahr",
              "Berechnungsgrundlage eigene Rechnungssumme"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Ueberpruefung_Nebenkostenabrechnung",
            "Type": "TABLE",
            "Size": 9,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Differenzbetrag zu Gunsten des Mieters",
            "Database": "",
            "DbText": "LDifferenzbetrag_zu_Gunsten_Mieter",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Differenzbetrag_zu_Gunsten_Mieter",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Differenzbetrag zu Gunsten des Vermieters",
            "Database": "",
            "DbText": "LDifferenzbetrag_zu_Gunsten_Vermieter",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Differenzbetrag_zu_Gunsten_Vermieter",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "5. Abänderung der Nebenkostenabrechnung",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum 1. Beanstandung Nebenkostenabrechnung",
            "Database": "",
            "DbText": "LDatum_1_Beanstandung_Nebenkostenabrechnung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_1_Beanstandung_Nebenkostenabrechnung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Frist zur Abänderung",
            "Database": "",
            "DbText": "LFrist_zur_Abaenderung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Frist_zur_Abaenderung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum 2. Beanstandung Nebenkostenabrechnung",
            "Database": "",
            "DbText": "LDatum_2_Beanstandung_Nebenkostenabrechnung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_2_Beanstandung_Nebenkostenabrechnung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zweite Frist zur Abänderung",
            "Database": "",
            "DbText": "LZweite_Frist_zur_Abaenderung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Zweite_Frist_zur_Abaenderung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Höhe unter Vorbehalt gezahlte Nebenkosten",
            "Database": "",
            "DbText": "LHoehe_unter_Vorbehalt_gezahlte_Nebenkosten",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Hoehe_unter_Vorbehalt_gezahlte_Nebenkosten",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Zugang berichtigte Nebenkostenabrechnung",
            "Database": "",
            "DbText": "LDatum_Zugang_berichtigte_Nebenkostenabrechnung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Zugang_berichtigte_Nebenkostenabrechnung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "6. Rückforderung zu viel gezahlter Nebenkosten",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum 1. Rückforderung",
            "Database": "",
            "DbText": "LDatum_1Rueckforderung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_1Rueckforderung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Frist Rückzahlung Guthaben",
            "Database": "",
            "DbText": "LFrist_Rueckzahlung_Guthaben",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Frist_Rueckzahlung_Guthaben",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum 2. Rückforderung ",
            "Database": "",
            "DbText": "LDatum_2Rueckforderung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_2Rueckforderung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zweite Frist Rückzahlung Guthaben",
            "Database": "",
            "DbText": "LZweite_Frist_Rueckzahlung_Guthaben",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Zweite_Frist_Rueckzahlung_Guthaben",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "7. Zugehörige Dokumente",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Aktuelle Nebenkostenabrechnung",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Aktuelle_Nebenkostenabrechnung_NKA",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Nebenkostenabrechnung Vorjahr",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Nebenkostenabrechnung_Vorjahr_NKA",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kontoauszüge",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Kontoauszuege_NKA",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mandantenanschreiben",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Mandantenanschreiben",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sonstoge Dokumente",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Sonstoge_Dokumente",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Mieterhöhung",
      "Elements": [
        [
          {
            "Label": "1. Allgemeine Daten Mieterhöhung",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Netto-Mietzins neu",
            "Database": "",
            "DbText": "LblNettoMietzins_neu",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "NettoMietzins_neu",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Brutto-Mietzins neu",
            "Database": "",
            "DbText": "LblBruttoMietzins_neu",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "BruttoMietzins_neu",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Betriebskosten neu",
            "Database": "",
            "DbText": "LblBetriebskosten_neu",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Betriebskosten_neu",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Netto-Einkommen Mieter",
            "Database": "",
            "DbText": "LNettoEinkommen_Mieter",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "NettoEinkommen_Mieter",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Ende Mietverhältnis Kündigung durch Mieter",
            "Database": "",
            "DbText": "LblLDatum_Ende_Mietverhaeltnis_Kuendigung_Mieter",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Ende_Mietverhaeltnis_Kuendigung_Mieter",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Mieterhöhungsverlangen",
            "Database": "",
            "DbText": "LblLDatum_Mieterhoehungsverlangen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Mieterhoehungsverlangen",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Zugang Mieterhöhungsverlangen",
            "Database": "",
            "DbText": "LblLDatum_Zugang_Mieterhoehungsverlangen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Zugang_Mieterhoehungsverlangen",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mieterhöhung neu Wirksamkeitsdatum",
            "Database": "",
            "DbText": "LblMieterhoehung_neu_Wirksamkeitsdatum",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Mieterhoehung_neu_Wirksamkeitsdatum",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ergebnis Prüfung MEV",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Tatbestand Rechtmäßigkeit MEV",
            "Database": "",
            "DbText": "LblLTatbestand_Rechtmaessigkeit_MEV",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              " ",
              "rechtmäßig",
              "rechtswidrig"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Tatbestand_Rechtmaessigkeit_MEV",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Beratungsergebnis zum MEV",
            "Database": "",
            "DbText": "LblLBeratungsergebnis_zum_MEV",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "das Mieterhöhungsverlangen des Vermieters abzulehnen.",
              "dem Mieterhöhungsverlangen des Vermieters zuzustimmen.",
              "dem Mieterhöhungsverlangen des Vermieters eingeschränkt wie folgt zuzustimmen.",
              "Sie sollten dem Mieterhöhungsverlangen des Vermieters zustimmen.",
              "Sie sollten das Mieterhöhungsverlangen des Vermieters bis zur Höhe des Mietspiegels bzw. der ortüblichen Miete zustimmen."
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Beratungsergebnis_zum_MEV",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "2. Modernisierung 559 BGB",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "2a. Durchführung Modernisierungsarbeiten",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wohnung Datum Tag 1 Modernisierung",
            "Database": "",
            "DbText": "LblWohnung_Datum_Tag1_Modernisierung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Wohnung_Datum_Tag1_Modernisierung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wohnung Datum Tag Modernisierungsende",
            "Database": "",
            "DbText": "LblWohnung_Datum_TagL_Modernisierung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Wohnung_Datum_TagL_Modernisierung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gebäude Datum Tag 1 Modernisierung",
            "Database": "",
            "DbText": "LblGebaeude_Datum_Tag1_Modernisierung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Gebaeude_Datum_Tag1_Modernisierung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gebäude Datum Tag Modernisierungsende",
            "Database": "",
            "DbText": "LblGebaeude_Datum_TagL_Modernisierung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Gebaeude_Datum_TagL_Modernisierung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Adresse Empfangsberechtigter",
            "Database": "",
            "DbText": "LblAdresse_Empfangsberechtigter",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Adresse_Empfangsberechtigter",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Name Empfangsberechtigter Schlüssel",
            "Database": "",
            "DbText": "LblName_Empfangsberechtigter_Schluessel",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Name_Empfangsberechtigter_Schluessel",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Ankündigungsschreiben Modernisierung",
            "Database": "",
            "DbText": "LblDatum_Ankuendigungsschreiben_Modernisierung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Ankuendigungsschreiben_Modernisierung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zugang Ankündigungsschreiben Modernisierung",
            "Database": "",
            "DbText": "LZugang_Ankuendigungsschreiben_Modernisierung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Zugang_Ankuendigungsschreiben_Modernisierung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Erwiderung Modernisierung",
            "Database": "",
            "DbText": "LDatum_Erwiderung_Modernisierung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Erwiderung_Modernisierung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Umfang Modernisierung",
            "Database": "",
            "DbText": "LblUmfang_Modernisierung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Notiz_Umfang_Modernisierung",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "2b. Kosten Modernisierungsarbeiten",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Voraussichtliche Kosten Modernisierung Wohnung",
            "Database": "",
            "DbText": "LVoraussichtliche_Kosten_Modernisierung_Wohnung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Voraussichtliche_Kosten_Modernisierung_Wohnung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Voraussichtliche Gesamtkosten Modernisierung",
            "Database": "",
            "DbText": "Lblvoraussichtliche_Gesamtkosten_Modernisierung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "voraussichtliche_Gesamtkosten_Modernisierung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Voraussichtliche monatliche Mieterhöhung",
            "Database": "",
            "DbText": "Lblvoraussichtliche_mtl_Mieterhoehung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "voraussichtliche_mtl_Mieterhoehung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Tatsächliche Gesamtkosten Wohnung 11 %",
            "Database": "",
            "DbText": "Lbltatsaechliche_Gesamtkosten_Wohnung_11Prozent",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "tatsaechliche_Gesamtkosten_Wohnung_11Prozent",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Tatsächliche Gesamtkosten Modernisierung",
            "Database": "",
            "DbText": "Lbltatsaechliche_Gesamtkosten_Modernisierung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "tatsaechliche_Gesamtkosten_Modernisierung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Tatsächliche monatliche Mieterhöhung",
            "Database": "",
            "DbText": "Lbltatsaechliche_mtl_Mieterhoehung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "tatsaechliche_mtl_Mieterhoehung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "3. Mieterhöhun § 558 BGB",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Anzahl Monate zwischen Mieterhöhungen",
            "Database": "",
            "DbText": "LAnzahl_Monate_Zwischen_Mieterhoehungen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Anzahl_Monate_Zwischen_Mieterhoehungen",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Anzahl Monate Sperrfrist",
            "Database": "",
            "DbText": "LblAnzahlMonate_Sperrfrist",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "AnzahlMonate_Sperrfrist",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Mieterhöhungsverlangen alt",
            "Database": "",
            "DbText": "LblDatum_Mieterhoehungsverlangen_alt",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Mieterhoehungsverlangen_alt",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mieterhöhung alt Wirksamkeitsdatum",
            "Database": "",
            "DbText": "LMieterhoehung_alt_Wirksamkeitsdatum",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Mieterhoehung_alt_Wirksamkeitsdatum",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Frist Zustimmung ME",
            "Database": "",
            "DbText": "LblDatum_Frist_ZustimmungME",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Frist_ZustimmungME",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Zustimmungsverweigerung",
            "Database": "",
            "DbText": "LblDatum_Zustimmungsverweigerung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Zustimmungsverweigerung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Netto-Mietzins neu Mietervorschlag",
            "Database": "",
            "DbText": "LNetto_Mietzins_neu_Mietervorschlag",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Netto_Mietzins_neu_Mietervorschlag",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Netto-Mietzins alt",
            "Database": "",
            "DbText": "LblNettoMietzins_alt",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "NettoMietzins_alt",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Netto-Mietzins aktuell plus 20 Prozent",
            "Database": "",
            "DbText": "LblNettoMietzins_aktuellplus20Prozent",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "NettoMietzins_aktuellplus20Prozent",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mietzins der letzten 3 Jahre",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "4. Zugehörige Dokumente",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Headers": [
              "Zeitraum",
              "Netto-Mietzins",
              "Betriebskosten",
              "Brutto-Mietzins"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Mietzins_der_letzten_3_Jahre",
            "Type": "TABLE",
            "Size": 9,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Auflistung Modernisierungsmaßnahmen",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Auflistung_Modernisierungsmassnahmen_559",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ankündigung Modernisierungsmaßnahmen",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Ankuendigung_Modernisierungsmassnahmen_559",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kostenvoranschläge, Rechnungen",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Kostenvoranschlaege_Rechnungen_559",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Nebenkostenabrechnung",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Nebenkostenabrechnung_559",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mieterhöhungsverlangen",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Mieterhoehungsverlangen_559",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Kündigung/Räumung",
      "Elements": [
        [
          {
            "Label": "1. Allgemeine Daten Kündigung",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Kündigungsschreiben Vermieter",
            "Database": "",
            "DbText": "LblDatum_Kuendigungsschreiben_Vermieter",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Kuendigungsschreiben_Vermieter",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Zugang Kündigungsschreiben Vermieter",
            "Database": "",
            "DbText": "LblDatum_Zugang_Kuendigungsschreiben_Vermieter",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Zugang_Kuendigungsschreiben_Vermieter",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Kündigungsschreiben Mieter",
            "Database": "",
            "DbText": "LblDatum_Kuendigungsschreiben_Mieter",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Kuendigungsschreiben_Mieter",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Ende Mietverhältnis",
            "Database": "",
            "DbText": "LblDatum_Ende_Mietverhaeltnis",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Ende_Mietverhaeltnis",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Abmahnungsschreiben Vermieter",
            "Database": "",
            "DbText": "LblDatum_Abmahnungsschreiben_Vermieter",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Abmahnungsschreiben_Vermieter",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Frist in der Abmahnung",
            "Database": "",
            "DbText": "LblFrist_in_der_Abmahnung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Frist_in_der_Abmahnung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "2. Kündigung",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Widerspruchsfrist Mieter gegen Kündigung",
            "Database": "",
            "DbText": "LblWiderspruchsfrist_Mieter_gegen_Kuendigung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Widerspruchsfrist_Mieter_gegen_Kuendigung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum des Widerspruchs",
            "Database": "",
            "DbText": "LblDatum_des_Widerspruchs",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_des_Widerspruchs",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Räumungsfrist des Vermieters",
            "Database": "",
            "DbText": "LblDatum_Raeumungsfrist_des_Vermieters",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Raeumungsfrist_des_Vermieters",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "2a. Zahlungsverzug Mieter",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gesamthöhe ausstehende Mietzahlung",
            "Database": "",
            "DbText": "LblGesamthoehe_ausstehende_Mietzahlung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Gesamthoehe_ausstehende_Mietzahlung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum letzte Mietzahlung",
            "Database": "",
            "DbText": "LblDatum_letzte_Mietzahlung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_letzte_Mietzahlung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Mahnung(en)",
            "Database": "",
            "DbText": "LblDatum_Mahnung_en",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Mahnung_en",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mietübernahmebereiter Dritter",
            "Database": "",
            "DbText": "LblMietuebernahmebereiter_Dritter",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Mietuebernahmebereiter_Dritter",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Tabelle Zahlungsrückstand",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Headers": [
              "Monat/Jahr",
              "geschuldeter Betrag",
              "gezahlter Betrag",
              "Rückstand in €"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Tabelle_Zahlungsrueckstand",
            "Type": "TABLE",
            "Size": 9,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "2b. Eigenbedarfskündigung",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Schreiben Eigenbedarfskündigung",
            "Database": "",
            "DbText": "LDatum_Schreiben_Eigenbedarfskuendigung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Schreiben_Eigenbedarfskuendigung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Widerspruchsschr. Eigenbedarfskdg.",
            "Database": "",
            "DbText": "LDatum_Widerspruchsschreiben_Eigenbedarfskuendigung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Widerspruchsschreiben_Eigenbedarfskuendigung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Ende Kündigungsfrist",
            "Database": "",
            "DbText": "LblDatum_Ende_Kuendigungsfrist",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Ende_Kuendigungsfrist",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Beweggrund Eigenbedarfskündigung",
            "Database": "",
            "DbText": "LblBeweggrund_Eigenbedarfskuendigung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Beweggrund_Eigenbedarfskuendigung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Einzugsperson Eigenbedarfskündigung",
            "Database": "",
            "DbText": "LblEinzugsperson_Eigenbedarfskuendigung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Einzugsperson_Eigenbedarfskuendigung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Angebot angemessener Ersatzwohnraum",
            "Database": "",
            "DbText": "LblAngebot_angemessener_Ersatzwohnraum",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Angebot_angemessener_Ersatzwohnraum",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "2c. Sonstige Kündigungsgründe",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Nachhaltige Störung Hausfrieden",
            "Database": "",
            "DbText": "LblStoerung_Hausfrieden",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Nachhaltige_Stoerung_Hausfrieden",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gebrauchsüberlassung an Dritte",
            "Database": "",
            "DbText": "LblGebrauchsueberlassung_an_Dritte",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Gebrauchsueberlassung_an_Dritte",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Vertragswidriger Gebrauch",
            "Database": "",
            "DbText": "LblVertragswidriger_Gebrauch",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Vertragswidriger_Gebrauch",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Weiterer Kündigungsgrund",
            "Database": "",
            "DbText": "LblWeiterer_Kuendigungsgrund",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Weiterer_Kuendigungsgrund",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "2d. Mieterkündigung",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum der vom Vermieter behaupteten Kündigungsfrist",
            "Database": "",
            "DbText": "LblDatum_der_vom_Vermieter_behaupteten_Kuendigungsfrist",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_der_vom_Vermieter_behaupteten_Kuendigungsfrist",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fristloser Kündigungsgrund Mieter",
            "Database": "",
            "DbText": "LblFristloser_Kuendigungsgrund_Mieter",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Fristloser_Kuendigungsgrund_Mieter",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Vorbringen des Vermieters",
            "Database": "",
            "DbText": "LblVorbringen_des_Vermieters",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Vorbringen_des_Vermieters",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "2e. Widerspruchsbegründung § 574 BGB",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Notiz_Widerspruchsbegruendung",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "2f. Widerspruchsbegründung § 574a BGB",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Notiz_Widerspruchsbegruendung_574a",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "1. Räumungsklage",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Zustellung der Räumungsklage",
            "Database": "",
            "DbText": "LblDatum_Zustellung_der_Klage",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Zustellung_der_Klage",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Anzeige der Verteidigungsbereitschaft",
            "Database": "",
            "DbText": "LblDatum_Anzeige_der_Verteidigungsbereitschaft",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Anzeige_der_Verteidigungsbereitschaft",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Inhalt Räumungsklage",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Inhalt_Raeumungsklage",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "2. Verlängerung Räumungsfrist",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Gericht Verhandlungstermin",
            "Database": "",
            "DbText": "LDatum_Gericht_Verhandlungstermin",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Gericht_Verhandlungstermin",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gericht Räumungsurteil",
            "Database": "",
            "DbText": "LGericht_Raeumungsurteil",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Gericht_Raeumungsurteil",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Aktenzeichen Räumungsurteil",
            "Database": "",
            "DbText": "LblAktenzeichen_Raeumungsurteil",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Aktenzeichen_Raeumungsurteil",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Räumungsurteil",
            "Database": "",
            "DbText": "LblDatum_Raeumungsurteil",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Raeumungsurteil",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum beantragte Räumungsfrist",
            "Database": "",
            "DbText": "LblDatum_beantragte_Raeumungsfrist",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_beantragte_Raeumungsfrist",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum gewährte Räumungsfrist",
            "Database": "",
            "DbText": "LblDatum_gewaehrte_Raeumungsfrist",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_gewaehrte_Raeumungsfrist",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum gewünschte Räumungsfrist",
            "Database": "",
            "DbText": "LDatum_gewuenschte_Raeumungsfrist",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_gewuenschte_Raeumungsfrist",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Schriftsatz gewünschte Räumungsfrist",
            "Database": "",
            "DbText": "lDatum_Schriftsatz_gewuenschte_Raeumungsfrist",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Schriftsatz_gewuenschte_Raeumungsfrist",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Schriftsatz angemessene Räumungsfrist",
            "Database": "",
            "DbText": "LDatum_Schriftsatz_angemessene_Raeumungsfrist",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Schriftsatz_angemessene_Raeumungsfrist",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "3. Vermieterpfandrecht",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Vermieterpfandrecht",
            "Database": "",
            "DbText": "LblDatum_Vermieterpfandrecht",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Vermieterpfandrecht",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Termin 1 Begehung Wohnung",
            "Database": "",
            "DbText": "LTermin1_Begehung_Wohnung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Termin1_Begehung_Wohnung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Termin 2 Begehung Wohnung",
            "Database": "",
            "DbText": "LTermin2_Begehung_Wohnung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Termin2_Begehung_Wohnung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Termin 3 Begehung Wohnung",
            "Database": "",
            "DbText": "LTermin3_Begehung_Wohnung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Termin3_Begehung_Wohnung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Terminbestaetigung Begehung Whg.",
            "Database": "",
            "DbText": "LDatum_Terminbestaetigung_Begehung_Wohnung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Terminbestaetigung_Begehung_Wohnung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "4. Vollstreckung/Vollstreckungsschutz",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Aussetzung Vollstreckung",
            "Database": "",
            "DbText": "LDatum_Aussetzung_Vollstreckung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Aussetzung_Vollstreckung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Frist Antwort gewünschte Räumungsfrist",
            "Database": "",
            "DbText": "LFrist_Antwort_gewuenschte_Raeumungsfrist",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Frist_Antwort_gewuenschte_Raeumungsfrist",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gericht Zwangsvollstreckung",
            "Database": "",
            "DbText": "LGericht_Zwangsvollstreckung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Gericht_Zwangsvollstreckung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Aktenzeichen Urteil Zwangsvollstreckung",
            "Database": "",
            "DbText": "LAktenzeichen_Urteil_Zwangsvollstreckung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Aktenzeichen_Urteil_Zwangsvollstreckung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Urteil Zwangsvollstreckung",
            "Database": "",
            "DbText": "LDatum_Urteil_Zwangsvollstreckung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Urteil_Zwangsvollstreckung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "GV Aktenzeichen",
            "Database": "",
            "DbText": "LblGV_Aktenzeichen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "GV_Aktenzeichen",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "GV Datum Räumungsmitteilung",
            "Database": "",
            "DbText": "LblGV_Datum_Raeumungsmitteilung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "GV_Datum_Raeumungsmitteilung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "GV Zugang Räumungsmitteilung",
            "Database": "",
            "DbText": "LblGV_Zugang_Raeumungsmitteilung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "GV_Zugang_Raeumungsmitteilung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "GV Datum Zwangsräumung",
            "Database": "",
            "DbText": "LblGV_Datum_Zwangsraeumung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "GV_Datum_Zwangsraeumung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Adresse Einlagerungsort Pfandsachen",
            "Database": "",
            "DbText": "LblAdresse_Einlagerungsort_Pfandsachen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Adresse_Einlagerungsort_Pfandsachen",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "5. Zugehörige Dokumente",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kündigungsschreiben",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Kuendigungsschreiben_Raeum",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mahnschreiben",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Mahnschreiben_Raeum",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Räumungstitel",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Raeumungstitel_Raeum",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Räumungsmitteilung",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Raeumungsmitteilung_Raeum",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Räumungsklage",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Raeumungsklage",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zahlungsklage",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Zahlungsklage",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Außergerichtliche Zahlungsaufforderung",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Außergerichtliche_Zahlungsaufforderung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sonstige Zwangsvollstreckungsunterlagen",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Sonstige_Zwangsvollstreckungsunterlagen",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Widerspruchsschreiben Kündigung",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Widerspruchsschreiben_Kuendigung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zahlungstitel ",
            "Database": "",
            "DbText": "ChkDok_Zahlungstitel_Mietrueckstand",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Kaution",
      "Elements": [
        [
          {
            "Label": "1. Allgemeine Angaben Kaution",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Ende Mietvertrag alte Wohnung",
            "Database": "",
            "DbText": "LblDatum_Ende_Mietvertrag_alte_Wohnung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Ende_Mietvertrag_alte_Wohnung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Höhe vereinbarte Mietkaution",
            "Database": "",
            "DbText": "LblHoehe_vereinbarte_Mietkaution",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Hoehe_vereinbarte_Mietkaution",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Rückgabe Mietobjekt",
            "Database": "",
            "DbText": "LblDatum_Rueckgabe_Mietobjekt",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Rueckgabe_Mietobjekt",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "2. Kautionbegehren Mieter",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Art Kautionszahlung",
            "Database": "",
            "DbText": "LblArt_Kautionszahlung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Art_Kautionszahlung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Höhe Kaution zzgl. Zinsen",
            "Database": "",
            "DbText": "LblHoehe_Kaution_zzgl_Zinsen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Hoehe_Kaution_zzgl_Zinsen",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ablehnungsgrund Rückgewähr Sicherheitsl.",
            "Database": "",
            "DbText": "LblAblehnungsgrund_Rueckgewaehr",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Ablehnungsgrund_Rueckgewaehr",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Reaktion Vermieter Sicherheitsleistung",
            "Database": "",
            "DbText": "LblDatum_Reaktion_Vermieter_Sicherheitsleistung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Reaktion_Vermieter_Sicherheitsleistung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "2a. Barzahlung Kaution",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Betrag Teilrückzahlung Kaution",
            "Database": "",
            "DbText": "LblBetrag_Teilrueckzahlung_Kaution",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Betrag_Teilrueckzahlung_Kaution",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Barrückzahlungsaufforderung",
            "Database": "",
            "DbText": "LblDatum_Barrueckzahlungsaufforderung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Barrueckzahlungsaufforderung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Frist Kautionsbarrückzahlung",
            "Database": "",
            "DbText": "LblFrist_Kautionsbarrueckzahlung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Frist_Kautionsbarrueckzahlung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "2b.Sparbuch mit Verpfändungserkl.",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Herausgabeaufforderung Sparbuch",
            "Database": "",
            "DbText": "LblDatum_Herausgabeaufforderung_Sparbuch",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Herausgabeaufforderung_Sparbuch",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Frist Sparbuchherausgabe",
            "Database": "",
            "DbText": "LblFrist_Sparbuchherausgabe",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Frist_Sparbuchherausgabe",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kautionssparbuchnummer",
            "Database": "",
            "DbText": "LblKautionssparbuchnummer",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Kautionssparbuchnummer",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "2c.Bankbürgschaft",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Herausgabe Bankbürgschaft",
            "Database": "",
            "DbText": "LblDatum_Herausgabe_Bankbuergschaft",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Herausgabe_Bankbuergschaft",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Frist Herausgabe Bankbürgschaft",
            "Database": "",
            "DbText": "LblFrist_Herausgabe_Bankbuergschaft",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Frist_Herausgabe_Bankbuergschaft",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kautionsbürgschaftsbezeichnung",
            "Database": "",
            "DbText": "LblKautionsbuergschaftsbezeichnung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Kautionsbuergschaftsbezeichnung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "3. Kautionsbegehren Vermieter",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Gesamtfälligkeit der Kaution",
            "Database": "",
            "DbText": "LblDatum_Gesamtfaelligkeit_der_Kaution",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Gesamtfaelligkeit_der_Kaution",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Einzelfälligkeit Teilkaution 1",
            "Database": "",
            "DbText": "LblDatum_Einzelfaelligkeit_Teilkaution_1",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Einzelfaelligkeit_Teilkaution_1",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Einzelfälligkeit Teilkaution 2",
            "Database": "",
            "DbText": "LblDatum_Einzelfaelligkeit_Teilkaution_2",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Einzelfaelligkeit_Teilkaution_2",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Einzelfälligkeit Teilkaution 3",
            "Database": "",
            "DbText": "LblDatum_Einzelfaelligkeit_Teilkaution_3",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Einzelfaelligkeit_Teilkaution_3",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sicherungsbedürfnis nach Kündigung",
            "Database": "",
            "DbText": "LblSicherungsbeduerfnis_nach_Kuendigung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Sicherungsbeduerfnis_nach_Kuendigung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Benötigte Dokumente",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Aufforderungsschreiben Kautionsrückzahlung",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Aufforderungsschreiben_Kautionsrueckzahlung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Aufforderungsschreiben Kautinszahlung",
            "Database": "",
            "DbText": "ChkDok_Aufforderungsschreiben_Kautinszahlung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Reaktionsschreiben Vermieter",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Reaktionsschreiben_Vermieter",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Reaktionsschreiben Mieter",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Reaktionsschreiben_Mieter",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zahlungsnachweis Kaution (z. B. Kontoauszug, Quittung)",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Zahlungsnachweis_Kaution",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kündigungbestätigung",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Kündigungbestaetigung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kündigungsschreiben altes Mietverhältnis",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Kuendigungsschreiben_altes_Mietverhaeltnis",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Klage",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Klage_Rueckgewaehr",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Sonstige Angelegenheiten",
      "Elements": [
        [
          {
            "Label": "Sonstige Angelegenheiten",
            "Database": "",
            "DbText": "LblSonstige_Angelegenheiten",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Sonstige_Angelegenheiten",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schönheitsreparaturen",
            "Database": "",
            "DbText": "LblSchoenheitsreparaturen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Schoenheitsreparaturen",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Instandhaltungen",
            "Database": "",
            "DbText": "LblInstandhaltungen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Instandhaltungen",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Schreiben Vermieter ",
            "Database": "",
            "DbText": "LblDatum_Schreiben_Vermieter_S",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Schreiben_Vermieter_S",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Zugang des Schreibens ",
            "Database": "",
            "DbText": "LblDatum_Zugang_des_Schreibens_S",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Zugang_des_Schreibens_S",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zugehörige Dokumente",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Bilder ",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Bilder_S",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sachverständigengutachten",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Sachverstaendigengutachten",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kostenvoranschlag ",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Kostenvoranschlag_S",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schreiben des Vermieters",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Schreiben_des_Vermieters_S",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Verträge",
      "Elements": [
        [
          {
            "Label": "1. Mietvertrag",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Um was für einen Mietvertrag handelt es sich?",
            "Database": "",
            "DbText": "LblVertrag_Mietobjekt_WasVertrag",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Wohnung": "Wohnung_Vertrag_Mietobjekt_WasVertrag",
              "Haus": "Haus_Vertrag_Mietobjekt_WasVertrag",
              "Garage": "Garage_Vertrag_Mietobjekt_WasVertrag",
              "Gewerbe": "Gewerbe_Vertrag_Mietobjekt_WasVertrag"
            },
            "Default": "True",
            "Label": "Vertrag_Mietobjekt_WasVertrag",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wann soll der Mietvertrag geschlossen werden?",
            "Database": "",
            "DbText": "LblVertrag_Mietobjekt_Vertragsdatum",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Vertrag_Mietobjekt_Vertragsdatum",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wann soll das Mietverhältnis beginnen?",
            "Database": "",
            "DbText": "LblVertrag_Mietobjekt_Beginn",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Vertrag_Mietobjekt_Beginn",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "2. Mietobjekt",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wohnungsadresse",
            "Database": "",
            "DbText": "LblVertrag_Mietobjekt_Adresse",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Vertrag_Mietobjekt_Adresse",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Stockwerk",
            "Database": "",
            "DbText": "LblVertrag_Mietobjekt_Stockwerk",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Vertrag_Mietobjekt_Stockwerk",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wohnungsnummer",
            "Database": "",
            "DbText": "LblVertrag_Mietobjekt_Nummer",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Vertrag_Mietobjekt_Nummer",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Baujahr",
            "Database": "",
            "DbText": "LblVertrag_Mietobjekt_Baujahr",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Vertrag_Mietobjekt_Baujahr",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wohngröße",
            "Database": "",
            "DbText": "LblVertrag_Mietobjekt_qm",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Vertrag_Mietobjekt_qm",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zimmeranzahl",
            "Database": "",
            "DbText": "LblVertrag_Mietobjekt_Zimmer",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Vertrag_Mietobjekt_Zimmer",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Welche Räume sind vorhanden?",
            "Database": "",
            "DbText": "LblVertrag_Mietobjekt_Raeumlichkeiten",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Vertrag_Mietobjekt_Raeumlichkeiten",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wie viele Personen ziehen ein?",
            "Database": "",
            "DbText": "LblVertrag_Mietobjekt_Personen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Vertrag_Mietobjekt_Personen",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Bitte ankreuzen, ob noch sonstige Bestandsteile zum Mietobjekt dazu gehören?",
            "Database": "",
            "DbText": "LblVertrag_Mietobjekt_Bestaende",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Tiefgarage": "Tiefgarage_Vertrag_Mietobjekt_Bestaende",
              "Gartennutzung": "Gartennutzung_Vertrag_Mietobjekt_Bestaende"
            },
            "Default": "True",
            "Label": "Vertrag_Mietobjekt_Bestaende",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Soll die Untervermietung für die gesamte Wohnung gestattet sein?",
            "Database": "",
            "DbText": "LblVertrag_Mietobjekt_UntervermietungGesamt",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertrag_Mietobjekt_UntervermietungGesamt",
              "Nein": "Nein_Vertrag_Mietobjekt_UntervermietungGesamt"
            },
            "Default": "True",
            "Label": "Vertrag_Mietobjekt_UntervermietungGesamt",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Soll die Untervermietung nur für bestimmte Räume gestattet sein?",
            "Database": "",
            "DbText": "LblVertrag_Mietobjekt_UntervermietungBestimmte",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "3. Mietdauer",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "unbestimmte Dauer?",
            "Database": "",
            "DbText": "LblVertrag_Mietobjekt_UnbestimmteDauer",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertrag_Mietobjekt_UnbestimmteDauer",
              "Nein": "Nein_Vertrag_Mietobjekt_UnbestimmteDauer"
            },
            "Default": "True",
            "Label": "Vertrag_Mietobjekt_UnbestimmteDauer",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Bestimmte Dauer bis",
            "Database": "",
            "DbText": "LblVertrag_MietdauerBis",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "",
            "DbText": "Vertrag_MietdauerBis",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "4. Miete",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Nettomiete Wohnung",
            "Database": "",
            "DbText": "LblVertrag_Mietnetto",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Vertrag_Mietnetto",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Nettomiete weitere Gegenstände",
            "Database": "",
            "DbText": "LblVertrag_MietnettoGegenstaende",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Vertrag_MietnettoGegenstaende",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Summe der Miete",
            "Database": "",
            "DbText": "LblVertrag_Mietsumme",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Vertrag_Mietsumme",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fälligkeit der Miete",
            "Database": "",
            "DbText": "LblVertrag_MieteFaelligkeit",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Vertrag_MieteFaelligkeit",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "5. Betriebskosten",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "laufende öffentliche Lasten",
            "Database": "",
            "DbText": "LblVertrag_BetriebskosteninhaltLasten",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertrag_BetriebskosteninhaltLasten",
              "Nein": "Nein_Vertrag_BetriebskosteninhaltLasten"
            },
            "Default": "True",
            "Label": "Vertrag_BetriebskosteninhaltLasten",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wasserversorgung",
            "Database": "",
            "DbText": "LblVertrag_BetriebskostenWasserversorgung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertrag_BetriebskostenWasserversorgung",
              "Nein": "Nein_Vertrag_BetriebskostenWasserversorgung"
            },
            "Default": "True",
            "Label": "Vertrag_BetriebskostenWasserversorgung",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Müllabfuhr",
            "Database": "",
            "DbText": "LblVertrag_BetriebskostenMuellabfuhr",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertrag_BetriebskostenMuellabfuhr",
              "Nein": "Nein_Vertrag_BetriebskostenMuellabfuhr"
            },
            "Default": "True",
            "Label": "Vertrag_BetriebskostenMuellabfuhr",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Entwässerung",
            "Database": "",
            "DbText": "LblVertrag_BetriebskosteninhaltEntwaesserung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertrag_BetriebskosteninhaltEntwaesserung",
              "Nein": "Nein_Vertrag_BetriebskosteninhaltEntwaesserung"
            },
            "Default": "True",
            "Label": "Vertrag_BetriebskosteninhaltEntwaesserung",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schnee- und Eisbeseitigung",
            "Database": "",
            "DbText": "LblVertrag_BetriebskosteninhaltEisbeseitigung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertrag_BetriebskosteninhaltEisbeseitigung",
              "Nein": "Nein_Vertrag_BetriebskosteninhaltEisbeseitigung"
            },
            "Default": "True",
            "Label": "Vertrag_BetriebskosteninhaltEisbeseitigung",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Straßenreinigung",
            "Database": "",
            "DbText": "LblVertrag_BetriebskosteninhaltStrassenreinigung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertrag_BetriebskosteninhaltStrassenreinigung",
              "Nein": "Nein_Vertrag_BetriebskosteninhaltStrassenreinigung"
            },
            "Default": "True",
            "Label": "Vertrag_BetriebskosteninhaltStrassenreinigung",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fernwärme",
            "Database": "",
            "DbText": "LblVertrag_BetriebskosteninhaltFernwaerme",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertrag_BetriebskosteninhaltFernwaerme",
              "Nein": "Nein_Vertrag_BetriebskosteninhaltFernwaerme"
            },
            "Default": "True",
            "Label": "Vertrag_BetriebskosteninhaltFernwaerme",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Heizanlage",
            "Database": "",
            "DbText": "LblVertrag_BetriebskosteninhaltHeizanlage",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertrag_BetriebskosteninhaltHeizanlage",
              "Nein": "Nein_Vertrag_BetriebskosteninhaltHeizanlage"
            },
            "Default": "True",
            "Label": "Vertrag_BetriebskosteninhaltHeizanlage",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "allgemeine Stromkosten",
            "Database": "",
            "DbText": "LblVertrag_BetriebskosteninhaltAllgemeineStromkosten",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertrag_BetriebskosteninhaltAllgemeineStromkosten",
              "Nein": "Nein_Vertrag_BetriebskosteninhaltAllgemeineStromkosten"
            },
            "Default": "True",
            "Label": "Vertrag_BetriebskosteninhaltAllgemeineStromkosten",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gartenpflege",
            "Database": "",
            "DbText": "LblVertrag_BetriebskosteninhaltGartenpflege",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertrag_BetriebskosteninhaltGartenpflege",
              "Nein": "Nein_Vertrag_BetriebskosteninhaltGartenpflege"
            },
            "Default": "True",
            "Label": "Vertrag_BetriebskosteninhaltGartenpflege",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sach- und Haftpflichtversicherung",
            "Database": "",
            "DbText": "LblVertrag_BetriebskosteninhaltSachHaftpflichtversicherung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertrag_BetriebskosteninhaltSachHaftpflichtversicherung",
              "Nein": "Nein_Vertrag_BetriebskosteninhaltSachHaftpflichtversicherung"
            },
            "Default": "True",
            "Label": "Vertrag_BetriebskosteninhaltSachHaftpflichtversicherung",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wartung Aufzug",
            "Database": "",
            "DbText": "LblVertrag_BetriebskosteninhaltWartungAufzug",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertrag_BetriebskosteninhaltWartungAufzug",
              "Nein": "Nein_Vertrag_BetriebskosteninhaltWartungAufzug"
            },
            "Default": "True",
            "Label": "Vertrag_BetriebskosteninhaltWartungAufzug",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wartung Warmwasseraufbereitung",
            "Database": "",
            "DbText": "LblWartung_Warmwasseraufbereitung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertrag_BetriebskosteninhaltWartungWarmwasseraufbereitung",
              "Nein": "Nein_Vertrag_BetriebskosteninhaltWartungWarmwasseraufbereitung"
            },
            "Default": "True",
            "Label": "Vertrag_BetriebskosteninhaltWartungWarmwasseraufbereitung",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "sonstige weitere Betriebskosteninhalte",
            "Database": "",
            "DbText": "LblVertrag_BetriebskosteninhaltSonstigeBetriebskosteninhalte",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Vertrag_BetriebskosteninhaltSonstigeBetriebskosteninhalte",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Umlageart",
            "Database": "",
            "DbText": "LblVertrag_BetriebskostenUmlage",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Personenanzahl",
              "Wohnungsgröße"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Vertrag_BetriebskostenUmlage",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "6. Schönheitsreparaturen",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schönheitsreparaturklausel",
            "Database": "",
            "DbText": "LblVertraege_Schoenheitsreparaturen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertraege_Schoenheitsreparaturen",
              "Nein": "Nein_Vertraege_Schoenheitsreparaturen"
            },
            "Default": "True",
            "Label": "Vertraege_Schoenheitsreparaturen",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "7. Instandhaltung",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mängelbeseitigungklausel",
            "Database": "",
            "DbText": "LblVertraege_InstandhaltungMaengelbeseitigungklausel",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertraege_InstandhaltungMaengelbeseitigungklausel",
              "Nein": "Nein_Vertraege_InstandhaltungMaengelbeseitigungklausel"
            },
            "Default": "True",
            "Label": "Vertraege_InstandhaltungMaengelbeseitigungklausel",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Beschädigungsklausel",
            "Database": "",
            "DbText": "LblVertraege_InstandhaltungBeschaedigungsklausel",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertraege_InstandhaltungBeschaedigungsklausel",
              "Nein": "Nein_Vertraege_InstandhaltungBeschaedigungsklausel"
            },
            "Default": "True",
            "Label": "Vertraege_InstandhaltungBeschaedigungsklausel",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kleinreparaturenklausel",
            "Database": "",
            "DbText": "LblVertraege_InstandhaltungKleinreparaturenklausel",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertraege_InstandhaltungKleinreparaturenklausel",
              "Nein": "Nein_Vertraege_InstandhaltungKleinreparaturenklausel"
            },
            "Default": "True",
            "Label": "Vertraege_InstandhaltungKleinreparaturenklausel",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Eigenleistung",
            "Database": "",
            "DbText": "LblVertraege_InstandhaltungEigenleistung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Vertraege_InstandhaltungEigenleistungMaxJahr",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sorgfaltspflichtsklausel",
            "Database": "",
            "DbText": "LblVertraege_InstandhaltungSorgfaltspflichtsklausel",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertraege_InstandhaltungSorgfaltspflichtsklausel",
              "Nein": "Nein_Vertraege_InstandhaltungSorgfaltspflichtsklausel"
            },
            "Default": "True",
            "Label": "Vertraege_InstandhaltungSorgfaltspflichtsklausel",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "max. jährliche Eigenleistung",
            "Database": "",
            "DbText": "LblVertraege_InstandhaltungEigenleistungMaxJahr",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Vertraege_InstandhaltungEigenleistung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "8. Tierhaltung",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Tierhaltungsklausel",
            "Database": "",
            "DbText": "LblVertraege_Tierhaltungsklausel",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertraege_Tierhaltungsklausel",
              "Nein": "Nein_Vertraege_Tierhaltungsklausel"
            },
            "Default": "True",
            "Label": "Vertraege_Tierhaltungsklausel",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zustimmung",
            "Database": "",
            "DbText": "LblVertraege_TierhaltungZustimmung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertraege_TierhaltungZustimmung",
              "Nein": "Nein_Vertraege_TierhaltungZustimmung"
            },
            "Default": "True",
            "Label": "Vertraege_TierhaltungZustimmung",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kleintierklausel",
            "Database": "",
            "DbText": "LblVertraege_TierhaltungKleintierklausel",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertraege_TierhaltungKleintierklausel",
              "Nein": "Nein_Vertraege_TierhaltungKleintierklausel"
            },
            "Default": "True",
            "Label": "Vertraege_TierhaltungKleintierklausel",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "9. bauliche Veränderung Mieter",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "bauliche Veränderungsklausel",
            "Database": "",
            "DbText": "LblVertraege_BaulicheVeraenderungVermieterKlausel",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertraege_BaulicheVeraenderungVermieterKlausel",
              "Nein": "Nein_Vertraege_BaulicheVeraenderungVermieterKlausel"
            },
            "Default": "True",
            "Label": "Vertraege_BaulicheVeraenderungVermieterKlausel",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "10. Veränderung Mieträume Mieter",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Veränderungklausel Mieträume",
            "Database": "",
            "DbText": "LblVertraege_VeraenderungMietraeumeKlausel",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertraege_VeraenderungMietraeumeKlausel",
              "Nein": "Nein_Vertraege_VeraenderungMietraeumeKlausel"
            },
            "Default": "True",
            "Label": "Vertraege_VeraenderungMietraeumeKlausel",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Erlaubniserfordernis Vermieter",
            "Database": "",
            "DbText": "LblVertraege_VeraenderungMietraeumeErlaubnis",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertraege_VeraenderungMietraeumeErlaubnis",
              "Nein": "Nein_Vertraege_VeraenderungMietraeumeErlaubnis"
            },
            "Default": "True",
            "Label": "Vertraege_VeraenderungMietraeumeErlaubnis",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "11. Betreten der Mieträume",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Erlaubnis Betreten",
            "Database": "",
            "DbText": "LblVertraege_BetretenMietraeumeErlaubnis",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertraege_BetretenMietraeumeErlaubnis",
              "Nein": "Nein_Vertraege_BetretenMietraeumeErlaubnis"
            },
            "Default": "True",
            "Label": "Vertraege_BetretenMietraeumeErlaubnis",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mitwirkungspflicht Mieter",
            "Database": "",
            "DbText": "LblVertraege_BetretenMietraeumeMitwirkungspflicht",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertraege_BetretenMietraeumeMitwirkungspflicht",
              "Nein": "Nein_Vertraege_BetretenMietraeumeMitwirkungspflicht"
            },
            "Default": "True",
            "Label": "Vertraege_BetretenMietraeumeMitwirkungspflicht",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "12. Beendigung des Mietverhältnisses",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Klausel für Beendigung",
            "Database": "",
            "DbText": "LblVertraege_BeendigungMietverhaeltnisKlausel",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Vertraege_BeendigungMietverhaeltnisKlausel",
              "Nein": "Nein_Vertraege_BeendigungMietverhaeltnisKlausel"
            },
            "Default": "True",
            "Label": "Vertraege_BeendigungMietverhaeltnisKlausel",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "13. Kaution",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Nettokaltmiete",
            "Database": "",
            "DbText": "LblVertraege_KautionNettokaltmiete",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "",
            "DbText": "Vertraege_KautionNettokaltmiete",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "14. Sonstige Vereinbarungen",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Vereinbart wurde zusätzlich",
            "Database": "",
            "DbText": "LblVertraege_SonstigeVereinbarung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Vertraege_SonstigeVereinbarung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    }
  ],
  "Mandant": [
    [
      {
        "Label": "Suchbegriff*",
        "Database": "",
        "DbText": "LSuchbegriff",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Keyword",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Keyword"
      }
    ],
    [
      {
        "Label": "Vorname",
        "Database": "",
        "DbText": "LVorname",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "FirstName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Name*",
        "Database": "",
        "DbText": "LName1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Name1"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "LblName2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Name2"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "LblName3",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name3",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Name3"
      }
    ],
    [
      {
        "Label": "Straße*",
        "Database": "",
        "DbText": "LStrasse",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Address",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Address"
      }
    ],
    [
      {
        "Label": "Postleitzahl* / Ort*",
        "Database": "",
        "DbText": "LPLZ_Ort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ZipCode",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "ZipCode"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "CityName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "CityName"
      }
    ],
    [
      {
        "Label": "Telefonnummer 1",
        "Database": "",
        "DbText": "LTelefonnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Phone1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Phone1"
      }
    ],
    [
      {
        "Label": "Telefonnummer 2",
        "Database": "",
        "DbText": "Lbltel2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "tel2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Phone2"
      }
    ],
    [
      {
        "Label": "Faxnummer",
        "Database": "",
        "DbText": "LFaxnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/DefaultZuord",
        "DbText": "Fax",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Fax"
      }
    ],
    [
      {
        "Label": "Handynummer",
        "Database": "",
        "DbText": "LHandynummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "HandyNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "MobilePhone"
      }
    ],
    [
      {
        "Label": "E-Mail",
        "Database": "",
        "DbText": "Llongmail",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "EmailAddress",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": "Email",
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "EmailAddress"
      }
    ],
    [
      {
        "Label": "Homepage",
        "Database": "",
        "DbText": "LblHomepage",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "homepage",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": "Browser",
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "URL"
      }
    ],
    [
      {
        "Label": "Geburtsdatum",
        "Database": "",
        "DbText": "Lblgebdat",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "BirthDate",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": "Datum",
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Beruf",
        "Database": "",
        "DbText": "LblBeruf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Beruf",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Vorsteuerberechtigt",
        "Database": "",
        "DbText": "LblVorsteuerberechtigt",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Values": {
          "Ja": "Ja_vorsteuerberechtigt",
          "Nein": "Nein_vorsteuerberechtigt"
        },
        "Default": "True",
        "Label": "vorsteuerberechtigt",
        "Database": "XML/Default",
        "DbText": "",
        "Type": "RADIOGROUP",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Rechtsform*",
        "Database": "",
        "DbText": "LRechtsform",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Rechtsform",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Anrede Briefkopf",
        "Database": "",
        "DbText": "LAnredeBriefkopf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeBriefkopf",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Anrede"
      }
    ],
    [
      {
        "Label": "Anrede direkt",
        "Database": "",
        "DbText": "LAnrededirekt",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeDirekt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Briefanrede"
      }
    ],
    [
      {
        "Label": "Kontoinhaber",
        "Database": "",
        "DbText": "LblKontoinhaber",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Kontoinhaber",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Bankverbindung",
        "Database": "",
        "DbText": "LBankverbindung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "kurzbez",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 57,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "bank",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      }
    ],
    [
      {
        "Label": "BLZ / BIC",
        "Database": "",
        "DbText": "LBLZ_BIC",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "blz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BLZ"
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "BIC",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Konto / IBAN",
        "Database": "",
        "DbText": "LKonto_IBAN",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ktonr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "IBAN",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "SEPA Lastschr.",
        "Database": "",
        "DbText": "LSEPALastschr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "MandatSEPA",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 35,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Datum SEPA",
        "Database": "",
        "DbText": "LDatumSEPA",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "MandatSEPAdatum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 10,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Abrechnungsdaten",
        "Type": "HEADLINE",
        "Size": 12,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Zahlungsart",
        "Database": "",
        "DbText": "LblZahlungsart",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Values": [
          "",
          "Selbstzahler",
          "Rechtsschutzversicherung",
          "Prozesskostenhilfe",
          "RECHT-nah Card"
        ],
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Zahlungsart",
        "Type": "COMBOBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Ertragsverteilung",
        "Database": "",
        "DbText": "LblErtragsverteilung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Values": [
          "100%",
          "50% : 50%",
          "30% : 70%"
        ],
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Ertragsverteilung",
        "Type": "COMBOBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Nur bei Rechtsschutzversicherung:",
        "Type": "HEADLINE",
        "Size": 12,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Versicherung",
        "Database": "",
        "DbText": "LblVersicherung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "RsVers",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Versicherungsnummer",
        "Database": "",
        "DbText": "LblVersicherungsnr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "RsverNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Selbstbeteiligung",
        "Database": "",
        "DbText": "LblSelbstbeteiligung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "RsSelbst",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": "Geld",
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Schadensnummer",
        "Database": "",
        "DbText": "LblSchadensnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "SchadNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Deckungszusage erhalten",
        "Database": "",
        "DbText": "LblBereits_Deckungszusage",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Values": {
          "Ja ": "Ja_Deckungszusage_erhalten",
          "Nein ": "Nein_Deckungszusage_erhalten"
        },
        "Default": "True",
        "Label": "Deckungszusage_erhalten",
        "Database": "XML/Default",
        "DbText": "",
        "Type": "RADIOGROUP",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Nur bei RECHT-nah Card",
        "Type": "HEADLINE",
        "Size": 12,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Kartennummer",
        "Database": "",
        "DbText": "LblRechtNahCard_Kartennummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "RechtNahCard_Kartennummer",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Belege / Dokumente:",
        "Type": "HEADLINE",
        "Size": 12,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Kopie Personalausweis",
        "Database": "XML/Default",
        "DbText": "ChkDok_Personalausweiskopie",
        "Type": "CHECKBOX",
        "Size": 3,
        "Skip": 3,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Schreiben Übernahme VKH-Kosten",
        "Database": "XML/Default",
        "DbText": "ChkDok_SchreibenZuProzesskostenuebernahme",
        "Type": "CHECKBOX",
        "Size": 3,
        "Skip": 3,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Deckungszusage",
        "Database": "XML/Default",
        "DbText": "ChkDok_Deckungszusage",
        "Type": "CHECKBOX",
        "Size": 3,
        "Skip": 3,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Berechtigungsschein",
        "Database": "XML/Default",
        "DbText": "ChkDok_Berechtigungsschein",
        "Type": "CHECKBOX",
        "Size": 3,
        "Skip": 3,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Gegner": [
    [
      {
        "Label": "Suchbegriff*",
        "Database": "",
        "DbText": "LSuchbegriff",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Keyword",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Keyword"
      }
    ],
    [
      {
        "Label": "Vorname",
        "Database": "",
        "DbText": "LVorname",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "FirstName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Name*",
        "Database": "",
        "DbText": "LName",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Name1"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lname2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Name2"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lblname3",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name3",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Name3"
      }
    ],
    [
      {
        "Label": "Straße*",
        "Database": "",
        "DbText": "LStrasse",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Address",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Address"
      }
    ],
    [
      {
        "Label": "Postleitzahl* / Ort*",
        "Database": "",
        "DbText": "LPLZ_Ort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ZipCode",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "ZipCode"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "CityName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "CityName"
      }
    ],
    [
      {
        "Label": "Telefonnummer 1",
        "Database": "",
        "DbText": "LTelefonnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Phone1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Phone1"
      }
    ],
    [
      {
        "Label": "Telefonnummer 2",
        "Database": "",
        "DbText": "Lbltel2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "tel2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Phone2"
      }
    ],
    [
      {
        "Label": "Faxnummer",
        "Database": "",
        "DbText": "LFaxnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Fax",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Fax"
      }
    ],
    [
      {
        "Label": "Handynummer",
        "Database": "",
        "DbText": "LHandynummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "HandyNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "MobilePhone"
      }
    ],
    [
      {
        "Label": "E-Mail",
        "Database": "",
        "DbText": "Llongmail",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "EmailAddress",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": "Email",
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "EmailAddress"
      }
    ],
    [
      {
        "Label": "Homepage",
        "Database": "",
        "DbText": "LblHomepage",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Homepage",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": "Browser",
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Geburtsdatum",
        "Database": "",
        "DbText": "Lblgebgeg",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "BirthDate",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": "Datum",
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Beruf",
        "Database": "",
        "DbText": "LblBeruf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Beruf",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Rechtsform*",
        "Database": "",
        "DbText": "LRechtsform",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Rechtsform",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Anrede Briefkopf",
        "Database": "",
        "DbText": "LAnredeBriefkopf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeBriefkopf",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Anrede"
      }
    ],
    [
      {
        "Label": "Anrede direkt",
        "Database": "",
        "DbText": "LAnrededirekt",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeDirekt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Briefanrede"
      }
    ],
    [
      {
        "Label": "Gegn. Anwalt",
        "Database": "",
        "DbText": "Lgeganwalt",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/DefaultZuord",
        "DbText": "geganwalt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "AZ gegn. Anwalt",
        "Database": "",
        "DbText": "Lazgeganw",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/DefaultZuord",
        "DbText": "azgeganw",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Bankverbindung",
        "Database": "",
        "DbText": "LBankverbindung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "kurzbez",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 57,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "bank",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      }
    ],
    [
      {
        "Label": "BLZ/BIC",
        "Database": "",
        "DbText": "LBLZ_BIC",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "blz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BLZ"
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "BIC",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 11,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Konto / IBAN",
        "Database": "",
        "DbText": "LKonto_IBAN",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ktonr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "IBAN",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 34,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "SEPA Lastschr.",
        "Database": "",
        "DbText": "LSEPALastschr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "MandatSEPA",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 35,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Datum SEPA",
        "Database": "",
        "DbText": "LDatumSEPA",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "MandatSEPAdatum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 10,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Behorde": [
    [
      {
        "Label": "Suchbegriff*",
        "Database": "",
        "DbText": "LSuchbegriff",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Keyword",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Keyword"
      }
    ],
    [
      {
        "Label": "Name*",
        "Database": "",
        "DbText": "LName1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Name1"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lname2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Name2"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lname3",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name3",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Name3"
      }
    ],
    [
      {
        "Label": "Straße",
        "Database": "",
        "DbText": "lstr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Address",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Address"
      }
    ],
    [
      {
        "Label": "Länderkennzeichen",
        "Database": "",
        "DbText": "llkz",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "lkz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 3,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "CountryID"
      }
    ],
    [
      {
        "Label": "PLZ",
        "Database": "",
        "DbText": "lplz",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ZipCode",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "ZipCode"
      }
    ],
    [
      {
        "Label": "Wohnort",
        "Database": "",
        "DbText": "lort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "CityName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "CityName"
      }
    ],
    [
      {
        "Label": "Telefon1",
        "Database": "",
        "DbText": "ltel1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Phone1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Phone1"
      }
    ],
    [
      {
        "Label": "Telefon2",
        "Database": "",
        "DbText": "ltel2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "tel2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Phone2"
      }
    ],
    [
      {
        "Label": "Zentralrufnummer",
        "Database": "",
        "DbText": "lzentralrufnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Zentralrufnummer",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Fax",
        "Database": "",
        "DbText": "lfax",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Fax",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Fax"
      }
    ],
    [
      {
        "Label": "E-Mail",
        "Database": "",
        "DbText": "llongmail",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "EmailAddress",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "EmailAddress"
      }
    ],
    [
      {
        "Label": "Telekommunikation",
        "Database": "",
        "DbText": "ltelkom",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "telkom",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Homepage",
        "Database": "",
        "DbText": "lhomepage",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Homepage",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Anrede Briefkopf",
        "Database": "",
        "DbText": "lan1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeBriefkopf",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Anrede"
      }
    ],
    [
      {
        "Label": "Anrede direkt",
        "Database": "",
        "DbText": "lan2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeDirekt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Briefanrede"
      }
    ],
    [
      {
        "Label": "Bankverbindung",
        "Database": "",
        "DbText": "LBankverbindung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "kurzbez",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 57,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "bank",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      }
    ],
    [
      {
        "Label": "BLZ/BIC",
        "Database": "",
        "DbText": "LBLZ_BIC",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "blz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BLZ"
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "BIC",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 11,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Konto / IBAN",
        "Database": "",
        "DbText": "LKonto_IBAN",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ktonr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "IBAN",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 34,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Amtsbezeichnung",
        "Database": "",
        "DbText": "LblAmtsbezeichnung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Amtsbezeichnung",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Aktenzeichen",
        "Database": "",
        "DbText": "LblAktenzeichen",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Aktenzeichen",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Gericht": [
    [
      {
        "Label": "Suchbegriff*",
        "Database": "",
        "DbText": "LSuchbegriff",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Keyword",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Keyword"
      }
    ],
    [
      {
        "Label": "Name*",
        "Database": "",
        "DbText": "LName1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Name1"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lname2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Name2"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lname3",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name3",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Name3"
      }
    ],
    [
      {
        "Label": "Straße",
        "Database": "",
        "DbText": "LStrasse",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Address",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Address"
      }
    ],
    [
      {
        "Label": "Postleitzahl",
        "Database": "",
        "DbText": "LPLZ_Ort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ZipCode",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "ZipCode"
      }
    ],
    [
      {
        "Label": "Gerichtsort",
        "Database": "",
        "DbText": "Lort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "CityName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "CityName"
      }
    ],
    [
      {
        "Label": "Gerichtskennzahl",
        "Database": "",
        "DbText": "LRechtsform",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "GerichtsKennzahl",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Telefonnummer 1",
        "Database": "",
        "DbText": "LTelefonnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Phone1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Phone1"
      }
    ],
    [
      {
        "Label": "Telefonnummer 2",
        "Database": "",
        "DbText": "Lbltel2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "tel2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Phone2"
      }
    ],
    [
      {
        "Label": "Faxnummer",
        "Database": "",
        "DbText": "LFaxnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Fax",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Fax"
      }
    ],
    [
      {
        "Label": "Handynummer",
        "Database": "",
        "DbText": "LHandynummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "HandyNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "MobilePhone"
      }
    ],
    [
      {
        "Label": "E-Mail",
        "Database": "",
        "DbText": "Llongmail",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "EmailAddress",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "EmailAddress"
      }
    ],
    [
      {
        "Label": "Homepage",
        "Database": "",
        "DbText": "LblHomepage",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Homepage",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Anrede Briefkopf",
        "Database": "",
        "DbText": "lan1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeBriefkopf",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Anrede"
      }
    ],
    [
      {
        "Label": "Anrede direkt",
        "Database": "",
        "DbText": "lan2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeDirekt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Briefanrede"
      }
    ],
    [
      {
        "Label": "Bankverbindung",
        "Database": "",
        "DbText": "LBankverbindung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "kurzbez",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 57,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "bank",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      }
    ],
    [
      {
        "Label": "BLZ/BIC",
        "Database": "",
        "DbText": "LBLZ_BIC",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "blz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BLZ"
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "BIC",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 11,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Konto / IBAN",
        "Database": "",
        "DbText": "LKonto_IBAN",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ktonr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "IBAN",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 34,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Weitere": [
    [
      {
        "Label": "Suchbegriff*",
        "Database": "",
        "DbText": "LSuchbegriff",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Keyword",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Keyword"
      }
    ],
    [
      {
        "Label": "Vorname",
        "Database": "",
        "DbText": "LVorname",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "FirstName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Name*",
        "Database": "",
        "DbText": "LName",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Name1"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "MyLabel1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Name2"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lblname3",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name3",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Name3"
      }
    ],
    [
      {
        "Label": "Straße",
        "Database": "",
        "DbText": "LStrasse",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Address",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Address"
      }
    ],
    [
      {
        "Label": "Postleitzahl / Ort",
        "Database": "",
        "DbText": "LPLZ_Ort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ZipCode",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "ZipCode"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "CityName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "CityName"
      }
    ],
    [
      {
        "Label": "Telefonnummer 1",
        "Database": "",
        "DbText": "LTelefonnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Phone1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Phone1"
      }
    ],
    [
      {
        "Label": "Telefonnummer 2",
        "Database": "",
        "DbText": "Lbltel2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "tel2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Phone2"
      }
    ],
    [
      {
        "Label": "Faxnummer",
        "Database": "",
        "DbText": "LFaxnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Fax",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Fax"
      }
    ],
    [
      {
        "Label": "Handynummer",
        "Database": "",
        "DbText": "LHandynummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "HandyNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "MobilePhone"
      }
    ],
    [
      {
        "Label": "E-Mail",
        "Database": "",
        "DbText": "Llongmail",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "EmailAddress",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "EmailAddress"
      }
    ],
    [
      {
        "Label": "Homepage",
        "Database": "",
        "DbText": "LblHomepage",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Homepage",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Kommunikationsdaten",
        "Database": "",
        "DbText": "LblKommunikationsdaten",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Headers": [
          "Medium",
          "Nummer"
        ],
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Kommunikationsdaten",
        "Type": "TABLE",
        "Size": 9,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Rechtsform*",
        "Database": "",
        "DbText": "LRechtsform",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": null,
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "NameID"
      }
    ],
    [
      {
        "Label": "Anrede Briefkopf",
        "Database": "",
        "DbText": "LAnredeBriefkopf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeBriefkopf",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Anrede"
      }
    ],
    [
      {
        "Label": "Anrede direkt",
        "Database": "",
        "DbText": "LAnrededirekt",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeDirekt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Briefanrede"
      }
    ],
    [
      {
        "Label": "Beteiligungsart",
        "Database": "",
        "DbText": "Llbetart",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/DefaultZuord",
        "DbText": "Beteiligungsart",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 3,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "",
        "DbText": "Zuo_Beteiligungsart",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Bankverbindung",
        "Database": "",
        "DbText": "LBankverbindung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "kurzbez",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 57,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "bank",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      }
    ],
    [
      {
        "Label": "BLZ/BIC",
        "Database": "",
        "DbText": "LBLZ_BIC",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "blz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BLZ"
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "bic",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 11,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BIC"
      }
    ],
    [
      {
        "Label": "Konto / IBAN",
        "Database": "",
        "DbText": "LKonto_IBAN",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ktonr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "iban",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 34,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "SEPA Lastschr.",
        "Database": "",
        "DbText": "LSEPALastschr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "MandatSEPA",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 35,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Datum SEPA",
        "Database": "",
        "DbText": "LDatumSEPA",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "MandatSEPAdatum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 10,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ]
}`);
