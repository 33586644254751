import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment"
import { RNSAPI } from "../../api";
import { RolesAndRights } from "../../helpers/RolesAndRights";
import { Utils } from "../../utils";

class ExtentDeadlineViewModel {
    newDeadlineDate = ko.observable("");
    oldDeadline = ko.observable(null);
    parentVM: any;
    parseDate = (dateStr: string) => moment(dateStr, "DD.MM.YYYY", true);
    checkDate = (str: string) => this.parseDate(str).isValid();

    hasDeadlineManagementRights = ko.observable<boolean>(false);

    extendDeadline = async () => {
        $(".form-group").each(function () { $(this).removeClass("has-error"); });
        if (!Utils.checkErrors(["newDeadlineDate"], this, "", [Utils.checkString, this.checkDate])) return;

        try {
            let result = await RNSAPI.extendDeadline(this.oldDeadline(), this.parseDate(this.newDeadlineDate()).format("YYYY-MM-DD"));
            if (result.Type === "ExtendedSuccessful") {
                $('#extendDeadline').modal('hide');
                this.parentVM.update();
            } else {
                alert("Fehler beim Verlängern der Frist.");
            }
        } catch (e) {
            console.log(e);
            alert("error occured: " + e.responseText);
        }
    };
    /* extendResubmission = async () => {
        $(".form-group").each(function () { $(this).removeClass("has-error"); });
        if (!Utils.checkErrors(["newDeadlineDate"], this, "", [Utils.checkString, this.checkDate])) return;
        try {
            let result = await RNSAPI.extendDeadline(this.oldDeadline(), this.parseDate(this.newDeadlineDate()).format("YYYY-MM-DD"));
            
            if (result.Type === "ExtendedSuccessful") {
                $('#extendResubmission').modal('hide');
                this.parentVM.update();
            } else {
                alert("Fehler beim Verlängern der Frist.");
            }
        } catch (e) {
            console.log(e);
            alert("Fehler beim Verlängern: " + e.responseText);
        }
    }; */

    constructor(params: any) {
        this.hasDeadlineManagementRights(RolesAndRights.hasDeadlineManagementRights());
        if (params && params.deadline) {
            console.log(params.deadline);
            this.oldDeadline = params.deadline;
            this.parentVM = params.vm;
        }
    }
}

let html = fs.readFileSync(__dirname + '/extend.html', 'utf8');

ko.components.register("extend-deadline-view", {
    viewModel: ExtentDeadlineViewModel,
    template: html
});