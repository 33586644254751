import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { MainViewModel } from "../../main";
import '../extended/new';
import '../appointment/daily';
import '../appointment/weekly';
import '../appointment/monthly';
import '../dialog/appointmentdialog'
import '../deadline/extend';
import '../deadline/delegate';
import '../calendar/calendar';
import '../note/notes';
import '../dialog/dialogYN';
import { DataTableViewModel, IntermediateListItem, Color } from '../dataTable/dataTable';
import { Utils } from '../../utils';
import * as moment from "moment"
import "../../../node_modules/moment/locale/de.js";
import * as fs from 'fs';
import { Interval } from '../workflow/zpe';
import { Transform } from "stream";
import { Filter } from "../case/caseentryoverview";
import { Postbox } from "../dashboard/postbox";
import { RolesAndRights } from "../../helpers/RolesAndRights";
import "../dialog/ConfirmFTWDialog";

enum Status {
    Done = 'E',
    New = 'N',
    Running = 'L',
    FurtherTerminating = 'W',
}
export class TfwViewModel {
    addressTypes = ko.observableArray(["Mandant", "Gegner", "Sonstige", "Gericht", "Versicherung", "Behörde", "Anwalt"]); //, "Gericht", "Versicherung", "Behörde", "Anwalt"
    appointments = ko.observableArray([]);
    deadlines = ko.observableArray([]);
    resubmissions = ko.observableArray([]);
    todos = ko.observableArray([]);
    lastWorkedOn = ko.observableArray([]);
    isempty = ko.observable("-/-");
    isemptyDate = ko.observable("TT.MM.JJJJ")
    selectedAddress = ko.observable(this.addressTypes()[0]);
    defaultSachbearbeiter = ko.observable();
    Rubrum = ko.observable("Kein Rubrum vorhanden.");
    AllSB = ko.observableArray();
    newSB = ko.observable("");
    newSB1 = ko.observable("");
    openDashboardAppointment = ko.observable();
    appointmentId = ko.observable();
    filter = ko.observable("");
    sachbearbeiter = ko.observable("");
    range = ko.observable(false);
    rStartDate = ko.observable("");
    rEndDate = ko.observable("");
    filters = ko.observableArray([]);
    values = ko.observable({
        complete: ko.observable(true),
        green: ko.observable(true),
        yellow: ko.observable(true),
        red: ko.observable(true),
    })
    types = ko.observable({
        Frist: ko.observable(true),
        Termin: ko.observable(true),
        Wiedervorlage: ko.observable(true),
    })
    currentID = ko.observable('');
    hasButton = ko.observable(true);
    registerNumber = ko.observable('')
    oldDeadline = ko.observable(null);
    extendDeadlineDate = ko.observable();
    checkDeadline = ko.observable(true);
    checkApp = ko.observable(true);
    checkResub = ko.observable(true);
    tooltips = ko.observableArray([{ name: 'PreDeadline', col: 'entryTypeName' }, { name: 'TypeId', col: 'subject' }])
    showTermine = ko.observable(false);
    showFristen = ko.observable(false);
    showWiedervorlagen = ko.observable(false);
    calledfirst = ko.observable(true);
    allStandorte = ko.observableArray([]);
    hasRightsForStandorte = ko.observable(false);
    hasStandortCount = ko.observable(0);
    allStandortSelected = ko.observable(false);
    showFTWFilter = ko.observable(true);
    allCasesWithRubrum = ko.observable();

    allDeadlineExtensionReasons = ko.observableArray([]);
    allExtendsionReasonsLength = ko.observable(0);
    extendDeadlineReason = ko.observable('');

    fKAId = ko.observable('');
    showOutlookLoader = ko.observable(false);

    allDifferentLawfirmDeadlines = ko.observableArray([]);

    hasDeadlineManagementRights = ko.observable<boolean>(false);
    isViewerRole = ko.observable<boolean>(false);

    ReportFilter = ko.observable({
        CaseID: ko.observable(""),
        DoneOnly: ko.observable(false),
        Locations: ko.observableArray(null),
        ClerkID: ko.observable("")
    })

    readonly DANGER_COLOR: Color = {
        color: "white",
        "background-color": "#e21b38" //be4259
    }

    wholeday = ko.observable(false);

    currentView = ko.observable("ftw");
    filteredTodos: ko.Computed<any>;

    pickedWeekDate = ko.observable(moment.utc().startOf("day"));

    today = () => this.pickedWeekDate(moment.utc().startOf("day"));

    filterByRange() {
        this.range(true)
    }

    filterType(type: string) {
        if (type == 'frist') {
            this.types().Frist(this.checkDeadline())
        }
        else if (type == 'resubmission') {
            this.types().Wiedervorlage(this.checkResub())
        }
        else if (type == 'termin') {
            this.types().Termin(this.checkApp())
        }
        this.filter(this.filter());
    }

    locationChecked(obj: any) {
        console.log(obj);
    }

    startOfDay = ko.computed(() => {
        let weekDate = this.pickedWeekDate().clone();
        return weekDate;
    });

    endOfDay = ko.computed(() => {
        let weekDate = this.pickedWeekDate().clone();
        return weekDate.endOf("day");
    });

    startOfThisMonth: moment.Moment;

    startOfNextMonth: moment.Moment;

    deadlineToBeModified = ko.observable(null);

    totalInactive = ko.observable();

    ErledigtFr = ko.observable(false);
    UnerledigtFr = ko.observable(true);

    filterWithRange(item) {
        let eDate = this.rEndDate() ? this.rEndDate() : '9999-01-01';
        if (ko.toJS(this.rEndDate) !== "" && ko.toJS(this.rStartDate) === "") {
            this.rStartDate(ko.toJS(moment().format("YYYY-MM-DD")))
        }
        if (ko.toJS(this.rEndDate) === "" && ko.toJS(this.rStartDate) !== "") {
            this.rEndDate(ko.toJS(moment().format("YYYY-MM-DD")))
        }
        if (item['date'] && this.rStartDate() && moment(item['date'], 'DD.MM.YYYY').isSameOrAfter(this.rStartDate()) && moment(item['date'], 'DD.MM.YYYY').isSameOrBefore(eDate)) {
            return true
        }
        return false
    }

    filterWithStars(item, filters) {
        //Add or complete here. 
        if (item['stars'] && filters.indexOf(item['stars']) !== -1) return true
        return false
    }

    filterWithType(item, filters) {
        if (item['entryTypeName'] && filters.indexOf(item['entryTypeName']) !== -1) return true
        return false
    }

    getNextWV(items) {
        if (items.length > 0) {
            let found = false;
            let intervall = 1;
            let notify = false;
            for (let i = items.length - 1; i >= 0; i--) {
                if (items[i].stars === "red")
                    notify = true;
                if (items[i].entryType === "resubmission") {
                    if (Number(moment(ko.toJS(items[i].date), "DD.MM.YYYY").format("YYYYMMDD")) >= Number(moment().format("YYYYMMDD"))) {
                        found = true;
                    }
                    else {
                        if (found) {
                            $("#NextWVCase").removeClass("hide");
                            $("#NextWVCaseLbl").removeClass("hide");
                            $("#grundcaseWV").val(items[i + intervall].subject);
                            $("#deadlineDateCaseWV").val(moment(items[i + intervall].date, "DD.MM.YYYY").format("YYYY-MM-DD"));
                            $("#SBcaseWV").val(items[i + intervall].SB);
                            break;
                        }
                    }
                    intervall = 1;
                }
                else {
                    intervall++;
                }
            }

            if (notify) {
                $("#redNotificationCircle").attr("style", "font-size: 0.75rem; vertical-align: middle !important; margin-bottom: 0.4rem; display: inline-block");
                $("#redNotificationCircleTFW").attr("style", "font-size: 0.5rem; vertical-align: middle !important; margin-bottom: 0.1rem; display: inline-block")
            }
        }
    }

    async getDefaultSachbearbeiter() {
        let sach = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(d => d.Sachbearbeiter_ID === RNSAPI.User().username);
        this.akteFrist().SachbearbeiterId(sach[0].Sachbearbeiter_ID);
        this.akteFrist().Sachbearbeiter(sach[0].Sachbearbeiter);
    }

    public getVM = () => this;

    async updateTodos(allAppointments: any, allDeadlines: any) {
        try {
            let appointments;
            let deadlines;
            let resubmissions;

            let res = (await RNSAPI.getCasesInfo()).Payload.CaseInfo;
            let rubrums = new Map();
            res.map((b) => { rubrums.set(b.CaseID, b.Rubrum); return b });
            this.allCasesWithRubrum(rubrums);

            if (ko.toJS(this.currentID) !== '')
                appointments = allAppointments.Payload.Appointments
            else
                appointments = allAppointments.Payload.Appointments.filter((a: any) => a.AppointmentType !== "F" && !a.Completed)

            deadlines = allDeadlines.Payload.Deadlines;

            let transformedAppointments = appointments.map(obj => this.transformAppointmentData(obj, "appointment"));

            let transformedDeadlines = deadlines.map(obj => this.transformDeadlineData(obj, "deadline"));

            if (ko.toJS(this.currentID) !== '')
                resubmissions = (await RNSAPI.getResubmissionByCaseId(ko.toJS(this.currentID))).Payload.Resubmissions.map(r => ({ ...r }));
            else
                resubmissions = (await RNSAPI.getResubmissionsByRange({ StartDate: moment(ko.toJS(this.rStartDate)).toDate(), EndDate: moment(ko.toJS(this.rEndDate)).add("1", "days").toDate() })).Payload.Resubmissions.map(r => ({ ...r }));

            let transformedResubmissions = resubmissions.map(obj => this.transformResubmissionData(obj, "resubmission"));

            this.showTermine(transformedAppointments.length > 0 ? true : false);
            this.showFristen(transformedDeadlines.length > 0 ? true : false);
            this.showWiedervorlagen(transformedResubmissions.length > 0 ? true : false);
            this.showFTWFilter((ko.toJS(this.showFristen) && ko.toJS(this.showTermine)) || (ko.toJS(this.showFristen) && ko.toJS(this.showWiedervorlagen)) || (ko.toJS(this.showTermine) && ko.toJS(this.showWiedervorlagen)));

            let sortedTodos = (transformedAppointments.concat(transformedDeadlines).concat(transformedResubmissions)).sort((t1: IntermediateListItem, t2: IntermediateListItem) => {
                if (t1.dateObj.isAfter(t2.dateObj))
                    return 1;
                else
                    return -1;                    
            });

            let transformed = sortedTodos.map(obj => { 
                if(!this.isViewerRole()) {
                    obj.columnActions = { "number": MainViewModel.RoutingTable.generateLink(`/new/${encodeURIComponent(obj.number)}`) };
                }
                
                obj.SB = obj.originalObj.Clerk; return obj; 
            });
            this.todos(transformed);
        }
        catch (e) {
            console.log(e);
        }
    }

    updateTooltip() {
        setTimeout(() => {
            $("[data-toggle='tooltip']").tooltip();
        }, 10000)
    }

    public showFtw() {
        MainViewModel.RoutingTable.showTfwView({ view: "ftw" }, true);
        this.currentView('ftw');
    }

    transformAppointmentData(obj: any, type: string) {
        let actionList = [];
        if(!this.isViewerRole()) {
            actionList = [
                {
                    icon: "pencil-alt", name: "Bearbeiten", action: () => {
                        // this.IsEditMode(true);
                        // this.appointmentId(obj.Id);
                        // this.openDashboardAppointment(true);
                        Postbox.publisher().publish({ EditAppointment: true, AppointmentId: obj.Id }, "EditAppointmentData");
                        $("#AppointmentDashboardmodal").modal("show");
                    }
                },
                {
                    icon: "trash-alt", name: "Löschen", action: async () => {
                        Postbox.publisher().publish({ ConfirmType: "APPOINTMENT", Reason: obj.AppointmentName, CaseId: "", Id: obj.Id, IsDeleteItem: true }, "ConfirmFTWModalData");
                    }
                },
            ];

            if (!obj.Completed) {
                actionList.push({
                    icon: "check", name: "Abhaken", action: async () => {
                        Postbox.publisher().publish({ ConfirmType: "APPOINTMENT", Reason: obj.AppointmentName, CaseId: "", Id: obj.Id, IsDeleteItem: false }, "ConfirmFTWModalData");
                    }
                });
            }
    }

        obj.Rubrum = (this.allCasesWithRubrum()).get(obj.CaseId);

        let stars = "";
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        let d = new Date(obj.EndDate);
        d.setHours(0, 0, 0, 0);
        if (obj.Completed)
            stars = "complete"
        else {
            if (d < today) {
                stars = "red";
            }
            else {
                stars = "yellow";
            }
        }
        obj.Clerk = obj.LawyerId;

        return new IntermediateListItem(stars, type, nameTable[type], obj.StartDate, obj.AppointmentSubject, obj.CaseId, obj, actionList, undefined, obj.Rubrum);
    }

    transformDeadlineData(obj: any, type: string) {
        let check = [
            {
                icon: "check", name: "Abschließen", action: async () => {
                    obj.CompletionDate = "";
                    obj.CompletionDate2 = "";
                    Postbox.publisher().publish({ ConfirmType: "DEADLINE", Reason: obj.TypeDescription, CaseId: obj.CaseId, Id: obj, IsDeleteItem: false }, "ConfirmFTWModalData");
                }
            }

        ];

        let delegate = [
            {
                icon: "user-circle", name: "Delegieren", action: async () => {
                    this.deadlineToBeModified(obj);
                    $('#delegateDeadlineModal').modal('show');
                    $('#newSB').val(ko.toJS(obj.Clerk));
                }
            }
        ];

        let extendable = [
            {
                icon: "pencil-alt", name: "Verlängern", action: async () => {
                    this.deadlineToBeModified(obj);
                    $('#extendDeadlineModal').modal('show');
                    this.extendDeadlineDate(ko.toJS(moment(this.deadlineToBeModified().Deadline).format("YYYY-MM-DD")));
                }
            }
        ];

        let actionList = [];
        if(!this.isViewerRole()) {
            actionList = [
                {
                    icon: "history", name: "Verlauf", action: async () => {
                        this.calledfirst(true);

                        this.getDeadlineLength(obj.TypeId)
                        if (!obj.EditedObj)
                            obj.EditedObj = false;

                        obj.Deadline = ko.toJS(moment(ko.toJS(obj.Deadline)).format("YYYY-MM-DD"));

                        this.startRepDeadline(obj.Deadline);
                        this.endRepDeadline(obj.Deadline);
                        this.caseRepDeadline(obj.CaseId);

                        obj.PreDeadline = ko.toJS(moment(ko.toJS(obj.PreDeadline)).format("YYYY-MM-DD"));


                        if (!obj.EditedObj) {
                            obj.CreatedBy = await this.getUserForShorthand(obj.CreatedBy);
                            if (obj.CreationDate !== null && obj.CreationDate !== "")
                                obj.CreationDate = ko.toJS(moment(ko.toJS(obj.CreationDate)).format("DD.MM.YYYY"));
                            else
                                obj.CreationDate === "";

                            obj.EditedFromId = await this.getUserForShorthand(obj.EditedFromId);
                            if (obj.EditedAt !== null && obj.EditedAt !== "")
                                obj.EditedAt = ko.toJS(moment(ko.toJS(obj.EditedAt)).format("DD.MM.YYYY"));
                            else
                                obj.EditedAt = "";

                            obj.CompletedBy = await this.getUserForShorthand(obj.CompletedBy);
                            if (obj.CompletionDate !== null && obj.CompletionDate !== "")
                                obj.CompletionDate = ko.toJS(moment(ko.toJS(obj.CompletionDate)).format("DD.MM.YYYY"));
                            else
                                obj.CompletionDate = "";
                            obj.EditedObj = true;

                            obj.Note = obj.Note + obj.Note1 + obj.Note2 + obj.Note3 + obj.Note4 + obj.Note5;
                        }
                        this.deadlineToBeModified(obj);
                        $('#historyDeadlineModal').modal('show');
                    }
                }
            ];
        }

        if (obj.locationID === undefined) {
            obj.locationID = '';
            if(!this.isViewerRole()) {
                if (obj.CompletionDate === null && this.hasDeadlineManagementRights()) {
                    actionList.push(delegate[0]);
                    if (!ko.toJS(this.isNotfrist(obj.TypeId)))
                        actionList.push(extendable[0]);
                    actionList.push(check[0]);
                }
            }
        }
        else {
            actionList = [
                {
                    icon: "building", name: obj.location, action: async () => {
                        
                    }
                }
            ];
        }

        let stars = "";
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        let d = new Date(obj.Deadline);
        d.setHours(0, 0, 0, 0);

        if (obj.CompletionDate !== "" && obj.CompletionDate !== null) {
            stars = "complete";
        }
        else {

            if (d < today) {
                if (obj.CompletionDate == "" || obj.CompletionDate == null) {
                    stars = "red";
                } else {
                    stars = "complete";
                }
            }
            else {
                stars = "yellow";
            }
        }

        return new IntermediateListItem(stars, type, nameTable[type], obj.Deadline, obj.TypeDescription, obj.CaseId, obj, actionList, undefined, obj.Rubrum);
    }

    transformResubmissionData(rObj: any, type: string) {
        let checkAction = {
            icon: "check",
            name: "Abschließen",
            action: async () => {
                Postbox.publisher().publish({ ConfirmType: "RESUBMISSION", Reason: rObj.DeadLineReason, CaseId: rObj.Case_ID, Id: rObj.ID, IsDeleteItem: false }, "ConfirmFTWModalData");
            }
        };

        rObj.Rubrum = (this.allCasesWithRubrum()).get(rObj.Case_ID);

        let stars = "";
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        let d = new Date(rObj.DeadLineDate);
        d.setHours(0, 0, 0, 0);
        if (d < today) {
            if (rObj.ErledigtDatum == "" || rObj.ErledigtDatum == null) {
                stars = "red";
            } else {
                stars = "complete";
            }
        }
        else if (d >= today) {
            if (rObj.ErledigtDatum == "" || rObj.ErledigtDatum == null) {
                stars = "yellow";
            } else {
                stars = "complete";
            }
        }

        let actionList = [];
        if(!this.isViewerRole()) {
            actionList = [
                {
                    icon: "pencil-alt", name: "Edit", action: async () => {
                        $('#extendResubmissionModal').modal('show');
                        this.akteExtendResubmission(ko.toJS(rObj));
                        rObj.DeadLineDate = moment(rObj.DeadLineDate).format('YYYY-MM-DD');
                        $('#wiedervorlage').val(rObj.DeadLineDate);

                    }
                },
                {
                    icon: "user-circle", name: "Delegieren", action: async () => {
                        this.akteExtendResubmission(ko.toJS(rObj));
                        $('#delegateResubmissionModal').modal('show');
                        $('#delegate').val(rObj.Sachbearbeiter);
                    }
                },
            ];
        }

        if (rObj.ErledigtDatum === null && !this.isViewerRole())
            actionList.push(checkAction);

        rObj.Clerk = rObj.Sachbearbeiter;
        return new IntermediateListItem(stars, type, nameTable[type], rObj.DeadLineDate, rObj.DeadLineReason, rObj.Case_ID || rObj.Sachbearbeiter, rObj, actionList, undefined, rObj.Rubrum);
    }

    allUsers = ko.observableArray();

    startRepDeadline = ko.observable('');
    endRepDeadline = ko.observable('');
    caseRepDeadline = ko.observable('');
    deadlineLength = ko.observable('');

    isNotfrist(TypeId: string) {
        switch (TypeId) {
            case "AV":
                return true;
            case "B":
                return true;
            case "ER":
                return true;
            case "ES":
                return true;
            case "NZ":
                return true;
            case "R":
                return true;
            case "SB":
                return true;
            case "RV":
                return true;
            case "We":
                return true;
            case "EP":
                return true;
            case "WK":
                return true;
            case "GE":
                return true;
            case "EA":
                return true;
            case "Er":
                return true;
            case "WA":
                return true;
            default:
                return false;
        }
    }

    async getUserForShorthand(shorthand: string) {
        for (let i = 0; i < this.allUsers().length; i++) {
            if (ko.toJS(this.allUsers())[i].Shorthandsymbol === shorthand)
                return ko.toJS(this.allUsers())[i].Name;
        }
        return "";
    }

    async getAllUsers() {
        let res = (await RNSAPI.getLawFirmUsers()).Payload.Users
        this.allUsers(res);
    }

    async getDeadlineLength(TypeId: string) {
        let res = (await RNSAPI.getDeadlineReasons()).Payload.Reasons
        for (let i = 0; i < res.length; i++) {
            if (TypeId === res[i].DeadlineNumber) {
                if (res[i].DeadlineDuration !== "") {
                    this.deadlineLength(this.formatDeadlineDuration(res[i].DeadlineDuration, res[i].PreDeadlineDuration));
                }
                break;
            }
        }
    }

    formatDeadlineDuration(deadduration: string, preduration = "") {
        let duration_names = { s: { d: 'Tag', w: 'Woche', m: 'Monat', y: 'Jahr' }, p: { d: 'Tage', w: 'Wochen', m: 'Monate', y: 'Jahre' } }
        let oldDuration = ko.toJS(deadduration).slice(1);
        let durationtext = "";
        if (oldDuration.startsWith("0"))
            oldDuration = oldDuration.slice(1);
        if (/^\w\d+$/.test(deadduration)) {
            let duration = Number(deadduration.slice(1));
            let dtype = deadduration[0];
            durationtext = duration + ' ' + duration_names[duration == 1 ? 's' : 'p'][dtype];
        }
        if (ko.toJS(preduration) !== "") {
            try {
                let days = parseInt(preduration)
                if (days > 1)
                    preduration = preduration + " Tage";
                else
                    preduration = preduration + " Tag";

                durationtext = durationtext + " | Vorfrist: " + preduration;
            } catch{ }
        }
        return durationtext;
    }

    async printDeadline(exact) {
        if (!ko.toJS(this.calledfirst)) {
            if (exact) {
                this.startRepDeadline((<HTMLInputElement>document.getElementById("startRepDeadline")).value);
                this.endRepDeadline((<HTMLInputElement>document.getElementById("endRepDeadline")).value);
            }

            this.ReportFilter().CaseID(ko.toJS(this.caseRepDeadline));

            this.ReportFilter().ClerkID(ko.toJS(this.sachbearbeiter));

            if (ko.toJS((<HTMLInputElement>document.getElementById("inlineRadio2")).checked))
                this.ReportFilter().DoneOnly(true);


            if (this.allStandorte().length > 0) {
                for (let i = 0; i < this.allStandorte().length; i++) {
                    let Print = (<HTMLInputElement>document.getElementById(this.allStandorte()[i].LawfirmID + '_checkbox')).checked;
                    if (Print)
                        this.ReportFilter().Locations.push(this.allStandorte()[i].LawfirmID);
                }
            }

            //Locations of ReportFIlter

            let res = (await RNSAPI.reportDeadline(moment(ko.toJS(this.startRepDeadline), "YYYY-MM-DD"), moment(ko.toJS(this.endRepDeadline), "YYYY-MM-DD"), ko.toJS(this.ReportFilter)));
            if (res.Type === "ReportSuccessful") {
                setTimeout(() => { }, 1000)
                let DocumentData = res.Payload;
                let from = moment(ko.toJS(this.startRepDeadline), "YYYY-MM-DD").format("DD.MM.YYYY");
                let to = moment(ko.toJS(this.endRepDeadline), "YYYY-MM-DD").format("DD.MM.YYYY");
                await this.download('Fristenübersicht von ' + from + ' bis ' + to, DocumentData);
                $("#reportDeadlineModal").modal("hide");
                $("#historyDeadlineModal").modal("hide");
                this.startRepDeadline('');
                this.endRepDeadline('');
                this.caseRepDeadline('');
                this.ReportFilter().DoneOnly(false);
                this.ReportFilter().CaseID("")
                this.ReportFilter().Locations([]);
            }
            else {
                //Error info modal
            }
        }
        else
            this.calledfirst(false);
    }

    async download(name: string, DocumentData: any) {

        let element = document.createElement('a');
        let doc = DocumentData;
        let IsRTF = atob(doc.DocumentData.substr(0, 50)).indexOf("rtf") !== -1;
        let type = IsRTF ? "rtf" : doc.OLE2Type.trim().toLowerCase();

        let blob = Utils.base64ToBlob(doc.DocumentData, 'application/octet-stream');

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob);
        } else {
            element.setAttribute('href', 'data:application/octet-stream;charset=utf-16le;base64,' + doc.DocumentData);
            element.setAttribute('download', `${name.trim()}.${type}`);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }
    }

    async updateDeadlines(allDeadlines: any) {
        try {
            let deadlines = allDeadlines.Payload.Deadlines.map(d => ({ ...d }));
            let deadlinesSorted = deadlines.sort((d1, d2) => {
                if (moment.utc(d1.Deadline).isBefore(moment.utc(d2.Deadline))) {
                    return 1;
                } else {
                    return -1;
                }
            });
            var DeletedItems = 0;
            let transformed = deadlinesSorted.map(obj => {
                let copy = $.extend(true, {}, obj);
                if(!this.isViewerRole()) {
                    obj.columnActions = {
                        "CaseId": MainViewModel.RoutingTable.generateLink(`/new/${encodeURIComponent(obj.CaseId)}`)
                    };
                }
                obj.actionHandlers = [];
                if(!this.isViewerRole()) {
                    obj.actionHandlers = [
                        {
                            icon: "pencil-alt", name: "Verlängern", action: async () => {
                                this.deadlineToBeModified(obj);
                                $('#extendDeadlineModal').modal('show');
                                this.extendDeadlineDate(ko.toJS(moment(this.deadlineToBeModified().Deadline).format("YYYY-MM-DD")));
                            }
                        },
                        {

                            icon: "user-circle",
                            name: "Delegieren",
                            action: async () => {
                                $('#delegateDeadlineModal').modal('show');
                                $('#newSB').val(ko.toJS(obj.Clerk));
                                var obj1 = {};
                                for (let key in obj1) {
                                    obj1[key] = ko.observable(obj[key]);
                                }
                                this.basicDeadline(obj);
                            }
                        }
                    ];
                }

                if(this.hasDeadlineManagementRights()) {
                    obj.actionHandlers.push({
                        icon: "check",
                        name: "Abschließen",
                        action: async () => {
                            if (obj.PreDeadline === "Keine Vorfrist") {
                                obj.PreDeadline = "";
                            }
                            obj.CompletionDate = "";
                            obj.CompletionDate2 = "";
                            await RNSAPI.completeDeadline(obj);
                            this.update();
                        }
                    });
                }

                obj.Deadline = moment(obj.Deadline).format("DD.MM.YYYY");

                obj.PreDeadline = obj.PreDeadline ? moment(obj.PreDeadline).format("DD.MM.YYYY") : "Keine Vorfrist";

                obj.TypeDescription = obj.TypeId + obj.TypeDescription;

                var Today = Number(moment().format("YYYYMMDD"));
                var FristTimestamp = Number(moment(obj.Deadline, "DD.MM.YYYY").format("YYYYMMDD"));

                if (Today < FristTimestamp) {
                    if (obj.PreDeadline !== "Keine Vorfrist") {
                        var PreFrTimestamp = Number(moment(obj.PreDeadline, "DD.MM.YYYY").format("YYYYMMDD"));
                        if (Today < PreFrTimestamp) {
                            DeletedItems++;
                            return 0;
                        }
                        else {
                            obj.color = PreFrTimestamp < Today ? this.DANGER_COLOR : undefined;
                        }
                    }
                    else {
                        DeletedItems++;
                        return 0;
                    }
                }
                else {
                    obj.color = FristTimestamp < Today ? this.DANGER_COLOR : undefined;
                }
                obj.color = undefined;
                return obj;
            });

            this.TermiCount(Number(transformed.length - DeletedItems));
            this.deadlines(transformed);

        } catch (e) {
            console.log("Could not retrieve deadlines.");
            console.log(e);
        }
    }

    intervalLength = ko.observable(30);

    interval = ko.pureComputed(() => {
        let length = this.intervalLength();
        let startDate = moment.utc().startOf('day');
        let endDate = moment.utc().endOf('day').add(length, 'days');
        return new Interval(startDate, endDate);
    })

    async updateResubmissions() {
        let range = {
            StartDate: this.interval().startDate.toISOString(),
            EndDate: this.interval().endDate.toISOString()
        };
        let resub = (await RNSAPI.getResubmissionsByRange(range)).Payload.Resubmissions.map(r => ({ ...r })).filter((r: any) => r.Status !== "E");

        let UserData = RNSAPI.User();
        let UserRole = UserData.role;

        if (UserRole.toString() === "0") {
            for (let i = 0; i < resub.length; i++) {
                if (resub[i].Sachbearbeiter !== UserData.username) {
                    resub.splice(i, 1);
                }
            }
        }

        this.totalInactive = resub.lenght;

        let resubmissions = resub.sort((d1, d2) => {
            if (moment.utc(d1.DeadLineDate).isBefore(moment.utc(d2.DeadLineDate))) {
                return 1;
            } else {
                return -1;
            }
        });

        this.resubmissions(resubmissions.map((r) => {
            let copy = $.extend(true, {}, r);
            r.DeadLineDate = moment.utc(r.DeadLineDate).format("DD.MM.YYYY");
            var Today = Number((moment().add(5, 'days')).format("YYYYMMDD"));
            var FristTimestamp = Number(moment(r.DeadLineDate, "MM/DD/YYYY").format("YYYYMMDD"));
            r.color = undefined;
            if(!this.isViewerRole()) {
                r.columnActions = {
                    "Case_ID": MainViewModel.RoutingTable.generateLink(`/new/${encodeURIComponent(r.Case_ID)}`)
                };
            }

            if (r.Status !== Status.Done) {
                if(!this.isViewerRole()) {
                    r.actionHandlers = [{

                        icon: "pencil-alt",
                        name: "Edit",
                        action: () => 
                        {
                            $('#extendResubmissionModal').modal('show');
                            var obj = {};
                            for (let key in r) {
                                obj[key] = ko.observable(r[key]);
                            }
                            this.akteExtendResubmission(obj);
                            $('#wiedervorlage').val(this.dateConverter(this.akteExtendResubmission().DeadLineDate()));
                        }
                    },
                    {
                        icon: "user-circle",
                        name: "Delegieren",
                        action: async () => {
                            $('#delegateResubmissionModal').modal('show');
                            var obj = {};
                            for (let key in r) {
                                obj[key] = ko.observable(r[key]);
                            }
                            this.akteExtendResubmission(obj);
                            $('#delegate').val(this.akteExtendResubmission().Sachbearbeiter());
                        }

                    }
                    ];
                }
            }
            let checkAction = { icon: "check", name: "Abschließen", action: async () => { await RNSAPI.completeResubmission(r.ID); this.updateResubmissions(); } };
            if (r.Status !== Status.Done) { 
                if(!this.isViewerRole()) {
                    r.actionHandlers.push(checkAction); 
                }
            }
            r.Status = (() => {
                switch (r.Status) {
                    case Status.New:
                        return 'N';
                    case Status.Running:
                    case Status.FurtherTerminating:
                        return 'L';
                    case Status.Done:
                        return 'E';
                    default:
                        return r.Status;
                }
            })();

            return r;
        }));
    }

    dateConverter(date: string) {

        let d = date.split('.');

        return `${d[2]}-${d[1]}-${d[0]}`;
    }

    public async update() {
        let allAppointments;
        let allDeadlines;

        if (ko.toJS(this.rStartDate) === '') {
            this.rStartDate(ko.toJS(moment("2000-01-01", "YYYY-MM-DD").toDate().toString()));
        }

        if (ko.toJS(this.rEndDate) === '') {
            this.rEndDate(ko.toJS(moment("2099-12-31", "YYYY-MM-DD").toDate().toString()));
        }

        if (ko.toJS(this.currentID) !== '')
            allAppointments = await RNSAPI.getAppointmentsForCase(ko.toJS(this.currentID), moment("2000-01-01", "YYYY-MM-DD").toDate(), moment("2099-12-31", "YYYY-MM-DD").toDate());
        else
            allAppointments = await RNSAPI.getAppointments(moment(ko.toJS(this.rStartDate)).toDate(), moment(ko.toJS(this.rEndDate)).toDate());


        for (let appointment of allAppointments.Payload.Appointments) {
            if (appointment["AppointmentType"] === "B")
                appointment["AppointmentType"] = "Besprechung";
            else if (appointment["AppointmentType"] === "G")
                appointment["AppointmentType"] = "Gerichtstermin";
            else if (appointment["AppointmentType"] === "A")
                appointment["AppointmentType"] = "Ausser Haus";
            else if (appointment["AppointmentType"] === "V")
                appointment["AppointmentType"] = "Verkündungstermin";
            else if (appointment["AppointmentType"] === "P")
                appointment["AppointmentType"] = "Privat";
            else if (appointment["AppointmentType"] === "D")
                appointment["AppointmentType"] = "Dauertermin";
            else if (appointment["AppointmentType"] === "N")
                appointment["AppointmentType"] = "Notar";
        }

        if (ko.toJS(this.currentID) !== '') {
            allDeadlines = await RNSAPI.getDeadlinesForCase(ko.toJS(this.currentID));
        }
        else if (this.hasStandortCount() > 0) {
            allDeadlines = await this.deadlinesForLawfirms();
        }
        else
            allDeadlines = await RNSAPI.getDeadlinesByRange(moment(ko.toJS(this.rStartDate)), moment(ko.toJS(this.rEndDate)));

        this.updateTodos(allAppointments, allDeadlines);
    }

    async deadlinesForLawfirms() {
        let lawfirms = [];
        for (let i = 0; i < this.allStandorte().length; i++) {
            let el = (<HTMLInputElement>document.getElementById(ko.toJS(this.allStandorte()[i].LawfirmID) + "_filter_checkbox")).checked;
            
            if (el)
                lawfirms.push(this.allStandorte()[i].LawfirmID)
        }
        let res = await RNSAPI.getDedlinesForLawfirms(moment(ko.toJS(this.rStartDate)), moment(ko.toJS(this.rEndDate)), lawfirms)
        return res;
    }

    changedLawfirms(caller: string) {
        for (let i = 0; i < this.allStandorte().length; i++) {
            if (ko.toJS(this.allStandorte()[i].LawfirmID) === caller)
                this.allStandorte()[i].Checked = !(<HTMLInputElement>document.getElementById(caller + "_filter_checkbox")).checked;
        }
        this.update();
    }

    newAkteFrist() {
        let obj = {
            SachbearbeiterId: ko.observable(RNSAPI.User().username),
            Sachbearbeiter: ko.observable(null),
            Clerk: ko.observable(null),
            CaseId: ko.observable(null),
            Rubrum: ko.observable("Kein Rubrum vorhanden."),
            DeadlineNumber: ko.observable("-/-"),
            DeadlineText: ko.observable(null),
            CompletedBy: ko.observable(""),
            CompletionDate: ko.observable(""),
            CreatedBy: ko.observable(""),
            StartDate: ko.observable(moment().format("YYYY-MM-DD")),
            PreDeadline: ko.computed(() => {
                if (this.predeadlineNr().startsWith("w")) {
                    let weeks = this.predeadlineNr()[1] + this.predeadlineNr()[2];
                    let today = moment().format("YYYY-MM-DD");
                    let FristDay = moment(this.akteFrist().StartDate(), "YYYY-MM-DD").add(weeks, "w");
                    return FristDay.format("YYYY-MM-DD");
                }
                else if (this.predeadlineNr().startsWith("m")) {
                    let months = this.predeadlineNr()[1] + this.predeadlineNr()[2];
                    let today = moment().format("YYYY-MM-DD");
                    let FristDay = moment(this.akteFrist().StartDate(), "YYYY-MM-DD").add(months, "M");
                    return FristDay.format("YYYY-MM-DD");
                }
                else if (this.predeadlineNr().startsWith("j")) {
                    let year = this.predeadlineNr()[1] + this.predeadlineNr()[2];
                    let today = moment().format("YYYY-MM-DD");
                    let FristDay = moment(this.akteFrist().StartDate(), "YYYY-MM-DD").add(year, "y");
                    return FristDay.format("YYYY-MM-DD");
                }
                else {
                    return moment().format("YYYY-MM-DD");
                }
            }),
            TheDeadline: ko.computed(() => {
                if (this.deadlineNr().startsWith("w")) {
                    let weeks = this.deadlineNr()[1] + this.deadlineNr()[2];
                    let today = moment().format("YYYY-MM-DD");
                    let FristDay = moment(this.akteFrist().StartDate(), "YYYY-MM-DD").add(weeks, "w");
                    if (FristDay.day() == 0) {
                        FristDay.add(1, 'day');
                    }
                    else if (FristDay.day() == 6) {
                        FristDay.add(2, 'day');
                    }
                    return FristDay.format("YYYY-MM-DD");
                }
                else if (this.deadlineNr().startsWith("m")) {
                    let months = this.deadlineNr()[1] + this.deadlineNr()[2];
                    let today = moment().format("YYYY-MM-DD");
                    let FristDay = moment(this.akteFrist().StartDate(), "YYYY-MM-DD").add(months, "M");
                    if (FristDay.day() == 0) {
                        FristDay.add(1, 'day');
                    }
                    else if (FristDay.day() == 6) {
                        FristDay.add(2, 'day');
                    }
                    return FristDay.format("YYYY-MM-DD");
                }
                else if (this.deadlineNr().startsWith("j")) {
                    let year = this.deadlineNr()[1] + this.deadlineNr()[2];
                    let today = moment().format("YYYY-MM-DD");
                    let FristDay = moment(this.akteFrist().StartDate(), "YYYY-MM-DD").add(year, "y");
                    if (FristDay.day() == 0) {
                        FristDay.add(1, 'day');
                    }
                    else if (FristDay.day() == 6) {
                        FristDay.add(2, 'day');
                    }
                    return FristDay.format("YYYY-MM-DD");
                }
                else {
                    //Kein Präfix = Wochen
                }
            })
        };
        return ko.observable(obj);
    };

    deadlineNr = ko.observable("");
    predeadlineNr = ko.observable("");
    IsEditMode = ko.observable(false)

    akteFrist = this.newAkteFrist();
    basicDeadline = this.akteFrist;
    IsDeadlineNecessary = ko.observable(true);

    DeadLineNr = ko.observable("");
    deadlineCreationDate = ko.observable(moment().format("YYYY-MM-DD"));
    wiedervorlage = ko.observable(moment().format("YYYY-MM-DD"));
    deadlineDeadlineDate = ko.computed(() => {
        if (this.deadlineNr().startsWith("w")) {
            let weeks = this.deadlineNr()[1] + this.deadlineNr()[2];
            let today = moment().format("YYYY-MM-DD");
            let FristDay = moment(this.deadlineCreationDate(), "YYYY-MM-DD").add(weeks, "w");
            if (FristDay.day() == 0) {
                FristDay.add(1, 'day');
            }
            else if (FristDay.day() == 6) {
                FristDay.add(2, 'day');
            }
            return FristDay.format("YYYY-MM-DD");
        }
        else if (this.deadlineNr().startsWith("m")) {
            let months = this.deadlineNr()[1] + this.deadlineNr()[2];
            let today = moment().format("YYYY-MM-DD");
            let FristDay = moment(this.deadlineCreationDate(), "YYYY-MM-DD").add(months, "M");
            if (FristDay.day() == 0) {
                FristDay.add(1, 'day');
            }
            else if (FristDay.day() == 6) {
                FristDay.add(2, 'day');
            }
            return FristDay.format("YYYY-MM-DD");
            // this.akteResubmission().DeadLineDate(FristDay.format("DD.MM.YYYY"));
        }
        else if (this.deadlineNr().startsWith("j")) {
            let year = this.deadlineNr()[1] + this.deadlineNr()[2];
            let today = moment().format("YYYY-MM-DD");
            let FristDay = moment(this.deadlineCreationDate(), "YYYY-MM-DD").add(year, "y");
            if (FristDay.day() == 0) {
                FristDay.add(1, 'day');
            }
            else if (FristDay.day() == 6) {
                FristDay.add(2, 'day');
            }
            //console.log('Date is: ');
            //console.log(FristDay.format("YYYY-MM-DD"));
            return FristDay.format("YYYY-MM-DD");
            // this.akteResubmission().DeadLineDate(FristDay.format("DD.MM.YYYY"));
        }
        else {
            //console.log("DeadlineNr is wrong");
            //console.log(this.deadlineNr());
            //Kein Präfix = Wochen
        }
    });
    deadlinePrelineDate = ko.computed(() => {
        if (this.predeadlineNr().startsWith("w")) {
            let weeks = this.predeadlineNr()[1] + this.predeadlineNr()[2];
            let FristDay = moment(this.deadlineCreationDate(), "YYYY-MM-DD").add(weeks, "w");
            return FristDay.format("YYYY-MM-DD");
        }
        else if (this.predeadlineNr().startsWith("m")) {
            let months = this.predeadlineNr()[1] + this.predeadlineNr()[2];
            let FristDay = moment(this.deadlineCreationDate(), "YYYY-MM-DD").add(months, "M");
            return FristDay.format("YYYY-MM-DD");
        }
        else if (this.predeadlineNr().startsWith("j")) {
            let year = this.predeadlineNr()[1] + this.predeadlineNr()[2];
            let FristDay = moment(this.deadlineCreationDate(), "YYYY-MM-DD").add(year, "y");
            return FristDay.format("YYYY-MM-DD");
        }
        else {
            return moment(this.deadlineCreationDate(), "YYYY-MM-DD").format("YYYY-MM-DD");
        }
    });
    creationDate = ko.observable(moment().format("YYYY-MM-DD"));
    deadlineDate = ko.computed(() => {
        if (ko.toJS(this.DeadLineNr()) == "") {
            return moment().format("YYYY-MM-DD");
        }
        else {
            let total_days = Number(ko.toJS(this.DeadLineNr())) * 7;
            if (ko.toJS(this.akteResubmission().DeadLineNr) == 'm1') {
                total_days = 30;
            }
            // console.log('DeadlineNr');
            // console.log(ko.toJS(this.DeadLineNr));
            if (!isNaN(total_days)) {
                console.log('Creation date: ');
                console.log(total_days);
                // alert(total_days);gul
                let potential_date = moment(ko.toJS(this.creationDate()), "YYYY-MM-DD").add(total_days, 'days');
                console.log('Date');
                console.log(potential_date.format("DD.MM.YYYY"));
                if (potential_date.day() == 0) {
                    potential_date.subtract(2, 'day');
                }
                else if (potential_date.day() == 6) {
                    potential_date.subtract(1, 'day');
                }
                return (potential_date.format("YYYY-MM-DD"));
            }
        }
    });

    parseDate = (dateStr: string) => moment.utc(dateStr, "DD.MM.YYYY", true);

    newAkteResubmission() {
        let rObj = {
            AdressTyp: ko.observable(""),
            AngelegtVon: ko.observable(""),
            AnlageDatum: ko.computed(() => this.parseDate((moment(this.creationDate(), "YYYY-MM-DD").format("DD.MM.YYYY"))).toISOString()),
            Case_ID: ko.observable(""),
            Comment: ko.observable(""),
            Copies: ko.observable(""),
            DeadLineDate: ko.computed(() => this.parseDate((moment(this.deadlineDate(), "YYYY-MM-DD").format("DD.MM.YYYY"))).toISOString()),
            DeadLineNr: ko.observable(""),
            DeadLineReason: ko.observable(""),
            EDAVerfahren: ko.observable(false),
            ErledigtDatum: ko.observable(null),
            ErledigtVon: ko.observable(""),
            ForderungskontoDrucken: ko.observable(false),
            Formulardruk: ko.observable(false),
            HonorarForderung: ko.observable(false),
            Keyword: ko.observable(""),
            PapierArtID: ko.observable(""),
            Quotelung: ko.observable(0),
            Referat: ko.observable(""),
            //Sachbearbeiter: ko.observable(""),
            Sachbearbeiter: ko.observable(RNSAPI.User() ? RNSAPI.User().username : "GR"),
            Standardtext: ko.observable(""),
            Status: ko.observable("N"),
            //SachbearbeiterId: ko.observable(RNSAPI.User().username),
        };

        return ko.observable(rObj);
    };

    akteResubmission = this.newAkteResubmission();
    akteExtendResubmission: ko.Observable<any> = ko.observable();

    async extendResubmission() {
        $(".form-group").each(function () { $(this).removeClass("has-error"); });
        this.akteExtendResubmission()['DeadLineDate'] = ko.observable($('#wiedervorlage').val());
        this.akteExtendResubmission()['Status'] = ko.observable('L');
        try {
            let result = await RNSAPI.updateResubmission(ko.toJS(this.akteExtendResubmission()));
            if (result.Type === "EditSuccess") {
                $('#extendResubmissionModal').modal('hide');
                this.update();
            } else {
                alert("Fehler beim Speichern.");
            }
        } catch (e) {
            console.log(e);
            alert("Fehler beim Speichern: " + e.responseText);
        }
    }

    async extendDeadline() {
        let deadlineObj = ko.toJS(this.deadlineToBeModified);
        let res = "";
        try {
            if (ko.toJS(this.allExtendsionReasonsLength) !== 0) {
                res = (<HTMLSelectElement>document.getElementById("DeadlineExtensionSelect")).value
            }
            //deadlineObj.RecordId = "";

            let result = await RNSAPI.extendDeadline(deadlineObj, ko.toJS(this.extendDeadlineDate()), res);
            if (result.Type === "ExtendedSuccessful") {
                RNSAPI.createHistoryEntry(deadlineObj.CaseId, "Frist angelegt");
                this.update();
            } else {
                alert("Fehler beim Speichern..");
            }
        }
        catch (e) {
            alert("Fehler beim ..");
            console.log(e);
        }
        $('#extendDeadlineModal').modal('hide');
    }

    async delegateResubmission() {
        $(".form-group").each(function () { $(this).removeClass("has-error"); });
        this.akteExtendResubmission()['Sachbearbeiter'] = ko.observable($('#delegate').val());
        try {
            let result = await RNSAPI.updateResubmission(ko.toJS(this.akteExtendResubmission()));
            if (result.Type == "EditSuccess") {
                $('#delegateResubmissionModal').modal('hide');
                this.update();
            } else {
                alert("Fehler beim Speichern.");
            }
        } catch (e) {
            $('#delegateResubmissionModal').modal('hide');
        }
    }

    async delegateDeadline() {
        $(".form-group").each(function () { $(this).removeClass("has-error"); });
        let delegateobj = ko.toJS(this.deadlineToBeModified());
        let keys = ['AddressTypeId', 'CaseId', 'Clerk', 'CompletedBy', 'CompletionDate', 'CompletionDate2', 'CreatedBy', 'CreationDate', 'Deadline', 'EditedAt', 'EditedFromId', 'ExportToOutlook', 'IMEX', 'IsDoubleCheck', 'IsPasswordProtected', 'NotaryOrLawyer', 'Note', 'Note1', 'Note2', 'Note3', 'Note4', 'OutlookMessageId', 'PreDeadline', 'PrDeadline2', 'ProcessTime', 'RecordId', 'ReplacedWith', 'ReviewAt', 'Rubrum', 'StartDate', 'TypeDescription', 'TypeId'];
        let obj = {};
        for (let key in keys) {
            obj[keys[key]] = delegateobj[keys[key]];
        }
        try {
            (<HTMLButtonElement>document.getElementById("postDelegation")).disabled = true;
            let result = await RNSAPI.delegateDeadline(ko.toJS(obj), ko.toJS(this.newSB));
            if (result.Type == "DelegationSuccessful") {
                if (ko.toJS(this.fKAId) !== '') {
                    let Notify = (<HTMLInputElement>document.getElementById('notifyClerkDelegate')).checked;
                    if (Notify) {
                        this.showOutlookLoader(true);
                        console.log(delegateobj);
                        delegateobj.Clerk = ko.toJS(this.newSB);
                        delegateobj.AddressKeyword = "";
                        let res = await RNSAPI.notifyDeadline(ko.toJS(this.fKAId), ko.toJS(delegateobj), Notify);
                        if (res.Type === "ExportAppointmentSuccessful") {
                            (<HTMLButtonElement>document.getElementById("postDelegation")).disabled = false;
                            this.showOutlookLoader(false);
                        }
                        else {
                            //Fehlermeldung einfügen
                        }
                    }
                }
                $('#delegateDeadlineModal').modal('hide');
                this.update();

            } else {
                alert("Fehler beim Speichern.");
            }
        } catch (e) {
            console.log(e);
            console.log("here is the problem: " + e.responseText);
        }

    }
    async getFKA() {
        let res = (await RNSAPI.getOutlook()).Payload.OutlookAccounts;
        if (res.length > 0) {
            //Eventuelle Änderung für die Auswahl der Adressen (oder hinterlegen der Adressen im Userprofil)
            this.fKAId(res[0].OutlookAccountID);
        }
    }

    times = [];

    startDate = ko.observable(moment().format("YYYY-MM-DD"));
    startTime = ko.observable("00:00");

    endDate = ko.observable(moment().format("YYYY-MM-DD"));
    endTime = ko.observable("00:00");

    async showextExtensionReasons() {
        this.pickGeneric("Verlängerungsgrund", ["Reason"], ['Verlängerungsgrund'], ko.toJS(this.allDeadlineExtensionReasons));
        this.modalHandleSelection((selectedObject) => {
            this.extendDeadlineReason(selectedObject()["Reason"])
        });
        $('#modal').modal('show');
    }

    async pickCase5() {
        // let cases = (await RNSAPI.getCases()).Payload.Cases;
        let cases = [];
        await RNSAPI.getCasesOverview(0, ko.toJS(20)).then((data) => {
            cases = data as any;
        }).catch((error) => {
            cases = [];
        });
        this.pickGeneric("Akte", ["Registernummer", "Rubrum", "Wegen"], ["Akte", "Rubrum", "Wegen"], cases);
        this.modalHandleSelection((selectedObject) => {
            this.filter(selectedObject()["Registernummer"])
        });
        $('#modal').modal('show');
    };

    async pickClerk5() {
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric("Sachbearbeiter", ["Sachbearbeiter"], ["Sachbearbeiter"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => {
            this.sachbearbeiter(selectedObject()["Sachbearbeiter_ID"])
        });
        $('#modal').modal('show');
    };

    pickSachbearbeiter1 = async () => {
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric("Sachbearbeiter", ["Sachbearbeiter"], ["Sachbearbeiter"], sachbearbeiter);
        console.log(sachbearbeiter);
        this.modalHandleSelection((selectedObject) => this.newSB1(selectedObject()["Sachbearbeiter_ID"]));
        // $('#delegatemodal').modal('show');
        $('#modal').modal('show');
    };
    pickclerk = async () => {
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric("Sachbearbeiter", ["Sachbearbeiter"], ["Sachbearbeiter"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => this.newSB(selectedObject()["Sachbearbeiter_ID"]));
        // $('#delegatemodal').modal('show');
        $('#modal').modal('show');
    };
    pickSachbearbeiter = async () => {
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric("Sachbearbeiter", ["Sachbearbeiter"], ["Sachbearbeiter"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => {
            this.akteFrist().SachbearbeiterId(selectedObject()["Sachbearbeiter_ID"]); //selectedObject()["Sachbearbeiter_ID"]
            this.akteFrist().Sachbearbeiter(selectedObject()["Sachbearbeiter_ID"]);
            //this.akteResubmission().Sachbearbeiter(selectedObject()["Sachbearbeiter_ID"]); //selectedObject()["Sachbearbeiter_ID"]
            this.akteResubmission().Sachbearbeiter(selectedObject()["Sachbearbeiter_ID"])
        });
        $('#modal').modal('show');
    };

    async pickCase() {
        let cases = [];
        await RNSAPI.getCasesOverview(0, ko.toJS(20)).then((data) => {
            cases = data as any;
        }).catch((error) => {
            cases = [];
        });

        this.pickGeneric("Akte", ["Registernummer", "Rubrum", "Wegen"], ["Akte", "Rubrum", "Wegen"], cases);
        this.modalHandleSelection((selectedObject) => {
            this.akteFrist().CaseId(selectedObject()["Registernummer"]);
            this.akteFrist().Rubrum(selectedObject()["Rubrum"])
            this.akteResubmission().Case_ID(selectedObject()["Registernummer"]);
            this.Rubrum(selectedObject()["Rubrum"])
            this.caseRepDeadline(selectedObject()["Registernummer"])
        });
        $('#modal').modal('show');
    };

    async pickAddress() {
        let address = (await RNSAPI.getPersonsForScope(this.selectedAddress())).Payload.Addresses;
        this.pickGeneric("Gesetzlicher Vertreter", ["FirstName", "Name1", "CityName"], ["Vorname", "Nachname", "Ort"], address);
        this.modalHandleSelection((selectedObject) => {

        });
        $('#modal').modal('show');
    }

    async pickDeadlineNumberResubmission() {
        let reasons = (await RNSAPI.getResubmissionReasons()).Payload.Reasons;
        console.log('Reasons');
        console.log(reasons);
        this.pickGeneric("Grund", ["DeadLineName"], ["Beschreibung"], reasons);
        this.modalHandleSelection((selectedObject) => {
            this.akteResubmission().DeadLineNr(selectedObject().DeadLineNr);
            this.DeadLineNr(selectedObject().DeadLineElapseTime);
            this.akteResubmission().DeadLineReason(selectedObject().DeadLineName);
        });
        $('#modal').modal('show');
    };

    async pickDeadlineNumber() {
        let reasons = (await RNSAPI.getDeadlineReasons()).Payload.Reasons;
        this.pickGeneric("Grund", ["DeadlineDescription"], ["Beschreibung"], reasons);
        this.modalHandleSelection((selectedObject) => {
            this.akteFrist().DeadlineNumber(selectedObject()["DeadlineNumber"]);
            this.deadlineNr(selectedObject()["DeadlineDuration"]);
            this.predeadlineNr(selectedObject()["PreDeadlineDuration"]);
            this.akteFrist().DeadlineText(selectedObject()["DeadlineDescription"]);
            this.akteResubmission().DeadLineReason(selectedObject()["DeadlineDescription"]);
        });
        $('#modal').modal('show');
    };

    async pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    };

    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalHandleSelection = ko.observable();
    TermiCount = ko.observable(0);

    akte = ko.observable({
        Registernummer: ko.observable(null),
        Referat: ko.observable(null),
        SachbearbeiterId: ko.observable(null),
        Rubrum: ko.observable(null),
        Wegen: ko.observable(null),
        Sachstand: ko.observable(null),
        AnlageDatum: ko.observable(moment().format("DD.MM.YYYY")),
        Note1: ko.observable(null),
        Wiedervorlage: ko.observable(""),
        WertGericht1: ko.observable(0),
        IsSelected: ko.observable(true),
        delegate: ko.observable(""),
        delegateapp: ko.observable("")
    });

    async getFristDeadlineReasons() {
        let res = (await RNSAPI.getFristDeadlineReasons());
        if (res.Type === "GetFristExtensionReasonsSuccessful") {
            this.allDeadlineExtensionReasons([]);
            this.allDeadlineExtensionReasons(res.Payload.DeadlineExtendReasonTypes.map((deadlineExtenstionRea) => {
                return deadlineExtenstionRea;
            }));
            this.allExtendsionReasonsLength(this.allDeadlineExtensionReasons().length)
        }
    }

    initTooltip() {
        $("[data-toggle='tooltip']").tooltip();
    }

    constructor(params: any) {
        this.isViewerRole(RolesAndRights.isViewerRole());
        this.hasDeadlineManagementRights(RolesAndRights.hasDeadlineManagementRights());

        moment.locale("de");
        this.hasButton(true);
        if (params.id !== undefined) {
            this.currentID(ko.toJS(params.id));
            this.filter(ko.toJS(params.id));
            this.hasButton(false);
        }
        else {
            this.rStartDate(ko.toJS(moment().subtract(1, 'weeks').format("YYYY-MM-DD")));
            this.rEndDate(ko.toJS(moment().add(2, 'months').format("YYYY-MM-DD")));
        }

        this.getFristDeadlineReasons();

        this.getAllUsers();

        this.getFKA();

        this.update();
        this.endOfDay.subscribe(() => this.update());

        this.startOfThisMonth = moment.utc().startOf("month");
        this.startOfNextMonth = moment.utc().startOf("month").add(1, "month");

        Postbox.publisher().subscribe(() => {
            this.update();
        }, 'updateTFW')

        this.filteredTodos = ko.computed({
            owner: this,
            read: () => {
                let filter = this.filter()
                let sachbearbeiter = this.sachbearbeiter()
                let range = this.range()
                let filters = []

                if (this.values().complete()) {
                    filters.push('complete')
                } else {
                    filters.indexOf('complete') !== -1 ? filters.splice(filters.indexOf('complete'), 1) : null
                }

                if (this.values().green()) {
                    filters.push('green')
                } else {
                    filters.indexOf('green') !== -1 ? filters.splice(filters.indexOf('green'), 1) : null
                }

                if (this.values().yellow()) {
                    filters.push('yellow')
                } else {
                    filters.indexOf('yellow') !== -1 ? filters.splice(filters.indexOf('yellow'), 1) : null
                }
                if (this.values().red()) {
                    filters.push('red')
                } else {
                    filters.indexOf('red') !== -1 ? filters.splice(filters.indexOf('red'), 1) : null
                }

                const filterTypes = Object.keys(this.types()).filter(item => this.types()[item]())

                let items = this.todos();
                if (filters.length) {
                    items = items.filter(item => this.filterWithStars(item, filters))
                }
                if (filterTypes.length) {
                    items = items.filter(item => this.filterWithType(item, filterTypes))
                }
                //if (this.rEndDate() || this.rStartDate()) {
                //    items = items.filter(item => this.filterWithRange(item))
                //}
                if (filter) {
                    items = items.filter(item => item['number'] && item['number'].indexOf(filter) !== -1)
                }
                if (sachbearbeiter) {
                    items = items.filter(item => item['SB'] && item['SB'].includes(sachbearbeiter))
                }

                if (ko.toJS(this.currentID) !== '' && ko.toJS(this.currentID) !== "undefined") {
                    this.getNextWV(items);

                }
                return items
            }
        });

        this.rStartDate.subscribe(() => this.update());
        this.rEndDate.subscribe(() => this.update());

        Postbox.publisher().subscribe((start: string, end: string) => {
            console.log("Here");
            this.startRepDeadline(start);
            this.endRepDeadline(end);
        }, 'UpdateRepDeadlines');

        this.getDefaultSachbearbeiter();
        this.oldDeadline = params;
        this.updateTooltip()
    }
}


let nameTable: { [key: string]: string; } = {
    "appointment": "Termin",
    "deadline": "Frist",
    "predeadline": "Vorfrist",
    "inbox": "Posteingang",
    "resubmission": "Wiedervorlage",
    "ecase": "EAkte"
};

var html = fs.readFileSync(__dirname + '/tfw.html', 'utf8');

ko.components.register("tfw-view", {
    viewModel: TfwViewModel,
    template: html
});
