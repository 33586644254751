import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { MainViewModel } from "../../main";
import '../dataTable/dataTable';
import { LayoutViewModel } from "../layout/layout";
import "../dialog/resubmissionDialog";
import { RolesAndRights } from "../../helpers/RolesAndRights";
export class CaseOverviewViewModel {
    public static action = {
        edit: ""
    }
    cases = ko.observableArray([]);
    referate = ko.observableArray([]);
    CurrentUser = ko.observable();
    caseOwn = ko.observable(false)
    ak = ko.observable();

    isViewerRole = ko.observable<boolean>(false);

    async Statisitc() {
        console.log("Al");
        let test = await RNSAPI.GetStatistic("PS", "05.06.2020", "12.06.2020", "AWH");
        console.log(test.Type);
    }

    async loadCases() {
        var id = localStorage.getItem('id');
        let user = (await RNSAPI.getLawFirmUser(RNSAPI.User().username)).Payload.User;
        //console.log("the user profile is ",id, user.Rolle);
        let referate = await RNSAPI.getReferate();
        this.CurrentUser(RNSAPI.User().username);
        let cases = [];

        await RNSAPI.getCasesOverview(1, ko.toJS(20)).then((data) => {
            cases = data as any;
        }).catch((error) => {
            cases = [];
        });

        // let cases = (await RNSAPI.getCases()).Payload.Cases
        if (this.caseOwn()) {
             cases = cases.map(r => ({ ...r })).filter((r: any) => r.leadpartner === ko.toJS(this.CurrentUser()));
        }

        let registerNrFormat = (await RNSAPI.suggestCaseId()).Payload.CaseId;
        let counterFirst = false;
        if (registerNrFormat[4] === "/") {
            counterFirst = true;
        }

        cases.reverse();

        this.cases(cases.map((c) => {
            this.referate(referate.Payload.Units.map((x) => {
                if (x.Id === c.refId) {
                    c.Referat = x.Name;
                }
            }));

            c.actionHandlers = [];

            if(!this.isViewerRole()) {
                c.columnActions = {
                    caseid: MainViewModel.RoutingTable.generateLink(
                        `/new/${encodeURIComponent(c.caseid)}`
                    ),
                };

                c.actionHandlers = [{
                    icon: "pencil-alt",
                    name: "Bearbeiten",
                    action: () => {
                        MainViewModel.RoutingTable.showNewView({ caseId: c.caseid });
                    }
                }, {
                    icon: "fas fa-file",
                    name: "Dokument erstellen",
                    action: () => {
                        //MainViewModel.RoutingTable.showDocumentEditorView({ caseId: c.Registernummer });
                        MainViewModel.RoutingTable.showNewView({ caseId: c.caseid });
                        localStorage.setItem('caseEditView', 'TextModalOpen');
                    }
                }, {
                    icon: "fas fa-list-alt", 
                    name: "Aktenvita",
                    action: () => {
                        MainViewModel.RoutingTable.showNewView({ caseId: c.caseid });
                        localStorage.setItem('caseEditView', 'FolderOpen');
                    }
    
                }, {
                    icon: "fas fa-flag", 
                    name: "Neue Wiedervorlage",
                    action: () => {
                        const elementId = document.getElementById('NewResubmissionDialogExt');
                        elementId.setAttribute('caseId', c.caseid);
                        $('#NewResubmissionDialogExt').modal('show');
                    }
                }];
            }
            return c;
        }).sort((cId1, cId2) => {
            if (counterFirst) {
                try {
                    if (Number(ko.toJS(cId1.caseid.split('/')[0])) > Number(ko.toJS(cId2.caseid.split('/')[0])))
                        return -1;
                    else
                        return 1;
                }
                catch {
                    return 0;
                }
            }
            else
                return 0;
        }));
    }

    constructor(params: any) {
        this.isViewerRole(RolesAndRights.isViewerRole());
        if (params && params.all === 'own') {
            this.caseOwn(true)
        }
        this.loadCases();

    }
}

let html = fs.readFileSync(__dirname + '/overview.html', 'utf8');

ko.components.register("caseoverview-view", {
    viewModel: CaseOverviewViewModel,
    template: html
});