import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment"
import { Filter } from '../case/caseentryoverview';
import { Interval } from './zpe';
import { RNSAPI } from "../../api";
import { UserRole } from '../../UserData';
import { MainViewModel } from "../../main";

class Button {
    Name: string;
    Wfk: string;
    Action: () => void;

    constructor(name: string, wfk: string, action: () => void) {
        this.Name = name;
        this.Wfk = wfk;
        this.Action = action;
    }
}

export class WfkHeaderViewModel {
    currentFilter: ko.Observable<Filter>;
    currentWorkflow: ko.Computed<string>;
    currentButtons = ko.computed(() => {
        let user = RNSAPI.User();
        switch (user.role) {
            case UserRole.Lawyer:
                return [
                    new Button("Posteingang", "PE", () => MainViewModel.RoutingTable.showWorkflowView("PE")),
                    new Button("Unterschriftenmappe", "ZU", () => MainViewModel.RoutingTable.showWorkflowView("ZU")),
                    new Button("Postausgang", "PA", () => MainViewModel.RoutingTable.showWorkflowView("PA"))
                ];
            case UserRole.Paralegal:
                return [new Button("Posteingang", "PE", () => MainViewModel.RoutingTable.showWorkflowView("PE"))];
        }
    });

    intervalLength = ko.observable(30);
    interval = ko.pureComputed(() => {
        let length = this.intervalLength();
        let endDate = moment.utc().endOf("day");
        let startDate = endDate.clone().subtract(length, "days").startOf("day");
        return new Interval(startDate, endDate);
    })

    public static wfkToDescription(wfk: string) {
        switch (wfk) {
            case "RA":
                return "Rechtsanwalt";
            case "B":
                return "In Bearbeitung";
            case "PE":
                return "Mein Postfach";
            case "PA":
                return "Postausgang";
            case "V":
                return "Versand";
            case "ZU":
                return "Unterschriftenmappe";
            default:
                return "Unbekanntes Workflowkennzeichen";
        }
    }

    constructor(params: any) {
        this.currentFilter = params.currentFilter;
        this.currentWorkflow = ko.computed(() => {
            let workflow = this.currentFilter().Workflow;
            return WfkHeaderViewModel.wfkToDescription(workflow);
        });
        this.interval.subscribe(newInterval => {
            let f = this.currentFilter()
            let startDate = newInterval.startDate.format("YYYY-MM-DD");
            let endDate = newInterval.endDate.format("YYYY-MM-DD");
            this.currentFilter(new Filter(f.CaseId, startDate, endDate, f.ShorthandSymbol, f.Workflow, f.Dictation, f.View, f.Limit));
        });
    }
}

let html = fs.readFileSync(__dirname + '/header.html', 'utf8');

ko.components.register("wfkheader-view", {
    viewModel: WfkHeaderViewModel,
    template: html
});