export let layoutArbeitsrecht = JSON.parse(`{
  "Identifikationsdaten": [
    [
      {
        "Label": "Identifikationsdaten",
        "Type": "HEADLINE",
        "Size": 12,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Registernummer*",
        "Database": "",
        "DbText": "LRegnr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "Registernummer",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 13,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Referat*",
        "Database": "",
        "DbText": "LblReferat",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "Referatnummer",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 2,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Nein/Undefiniert",
        "DbText": "Zuo_refnr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Sachbearbeiter*",
        "Database": "",
        "DbText": "LblSachbearbeiter",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "SachbearbeiterId",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 2,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sachbearbeiter",
        "SelectionProperty": "Sachbearbeiter_ID"
      },
      {
        "Label": "",
        "Database": "Nein/Undefiniert",
        "DbText": "Zuo_sachnr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Sachbearbeiter",
        "SelectionProperty": "Sachbearbeiter"
      }
    ],
    [
      {
        "Label": "Anlagedatum",
        "Database": "",
        "DbText": "lblAnlagedatum",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "AnlageDatum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 10,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Rubrum*",
        "Database": "",
        "DbText": "LblRubrum",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "Rubrum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 64,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Wegen*",
        "Database": "",
        "DbText": "LblWegen",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "Wegen",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 64,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Kennz. Ausl Mandant",
        "Database": "",
        "DbText": "LKausmd",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "IsAuslaendischesMandat",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 1,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Kennz. Beitr. Eigene",
        "Database": "",
        "DbText": "Lkbeiei",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "IsEigeneBeitreibung",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 1,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "VorSt. Abzugsber. Mdt.",
        "Database": "",
        "DbText": "LKvstab",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": null,
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 1,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Mandant ist Kläger",
        "Database": "",
        "DbText": "LMdtKläger",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": null,
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 1,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "AZ Mandant",
        "Database": "",
        "DbText": "LAzmdt",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": null,
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Organisationsdaten": [
    [
      {
        "Label": "Organisationsdaten",
        "Type": "HEADLINE",
        "Size": 12,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "WV Grund",
        "Database": "",
        "DbText": "LblWVGrund",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "FristNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Nein/Undefiniert",
        "DbText": "Zuo_frinr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "WV Ablauf",
        "Database": "",
        "DbText": "LblWVAblauf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "FristDatum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 10,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Sachstand",
        "Database": "",
        "DbText": "LblSachbestand",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": null,
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Nein/Undefiniert",
        "DbText": "Zuo_Sachstand",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Mahnkennung",
        "Database": "",
        "DbText": "Lmahnnr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "MahnNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Nein/Undefiniert",
        "DbText": "Zuo_Mahnnr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Gegenstandswert ",
        "Database": "",
        "DbText": "LWert1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": null,
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 12,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "MEK",
        "Database": "",
        "DbText": "LMEK",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Akte",
        "DbText": "MEK",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 12,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Sachverhalt": [
    {
      "Name": "Allgemein",
      "Elements": [
        [
          {
            "Label": "1. Daten Arbeitsvertrag",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Arbeitsvertrag Datum Abschluss",
            "Database": "",
            "DbText": "LblMietvertrag_Datum_Abschluss",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Mietvertrag_Datum_Abschluss",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Arbeitsbeginn",
            "Database": "",
            "DbText": "LblMietvertrag_wirksam_ab",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Mietvertrag_wirksam_ab",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Arbeitszeit",
            "Database": "",
            "DbText": "LblMietvertrag_NettoMietzins",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Arbeitszeit",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gleitzeit",
            "Database": "",
            "DbText": "LEinzugsdatum",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Ja",
            "Database": "",
            "DbText": "Ja",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Nein",
            "Database": "",
            "DbText": "Nein",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gehalt",
            "Database": "",
            "DbText": "LMietvertrag_Betriebskosten",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Mietvertrag_Betriebskosten",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Urlaubsanspruch",
            "Database": "",
            "DbText": "LblMietzins_Faelligkeit",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Mietzins_Faelligkeit",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Tätigkeit",
            "Database": "",
            "DbText": "LblNettoMietzins_aktuell",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "NettoMietzins_aktuell",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Arbeitgeber",
            "Database": "",
            "DbText": "LblBetriebskosten_aktuell",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Betriebskosten_aktuell",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "2. Daten Arbeitgeber",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Name Arbeitsgeber",
            "Database": "",
            "DbText": "LblName_Arbeitsgeber",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "",
            "DbText": "Name_Arbeitsgeber",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Straße Arbeitgeber",
            "Database": "",
            "DbText": "LblWohnung_Adresse",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Wohnung_Adresse",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "PLZ / Ort Arbeitstelle",
            "Database": "",
            "DbText": "LWohnung_Lage",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "PLZ_Arbeitsstelle",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Telefon Arbeitgeber",
            "Database": "",
            "DbText": "LblWohnung_Baujahr",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Wohnung_Baujahr",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "3. Zugehörige Dokumente",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Vollmacht",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Vollmacht",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Angaben zur Rechtsschutzversicherung",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Angaben_zur_Rechtsschutzversicherung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Berechtigungsschein",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Berechtigungsschein",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Bereits erfolgte Korrespondenz",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Bereits_erfolgte_Korrespondenz",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kontodaten Mandantschaft",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Kontodaten_Mandantschaft",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Arbeitsvertrag",
            "Database": "",
            "DbText": "ChkDok_Arbeitsvertrag",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Kündigung",
      "Elements": [
        [
          {
            "Label": "Ist die Kündigung schriftlich erteilt worden ?",
            "Database": "",
            "DbText": "LblNettoMietzins_alt",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Ja",
            "Database": "",
            "DbText": "Ja_schriftlich",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Nein",
            "Database": "",
            "DbText": "Nein_nicht_schriftlich",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zugang Kündigung",
            "Database": "",
            "DbText": "LblNettoMietzins_neu",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Zugang_Kündigung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Beendigung des Arbeitsverhältnisses",
            "Database": "",
            "DbText": "LblNettoMietzins_aktuellplus20Prozent",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Beendigung_Arbeitsverhältnis",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Dauer des Arbeitsverhältnisses",
            "Database": "",
            "DbText": "LblDauer_des_Arbeitsverhältnisses",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "befristet",
              "unbefristet"
            ],
            "Label": "",
            "Database": "",
            "DbText": "Dauer_des_Arbeitsverhältnisses",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Anzahl Arbeitnehmer im Betrieb",
            "Database": "",
            "DbText": "LblBruttoMietzins_neu",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "BruttoMietzins_neu",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ist der Arbeitgeber tarifgebunden ?",
            "Database": "",
            "DbText": "Lbl2",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Ja",
            "Database": "",
            "DbText": "tarifgebunden",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Nein",
            "Database": "",
            "DbText": "nicht_tarifgebunden",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gehören Sie zum folgenden Personenkreis :",
            "Database": "",
            "DbText": "LblGehören_Sie_zum_folgenden_Personenkreis",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Betriebsrat",
              "Schwanger",
              "Behindert",
              "Datenschutzbeauftragter"
            ],
            "Label": "Schwanger",
            "Database": "",
            "DbText": "Gehören_Sie_zum_folgenden_Personenkreis",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zugehörige Dokumente",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kündigungsschreiben",
            "Database": "",
            "DbText": "ChkDok_Kündigungsschreiben",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Entgeltfortzahlung im Krankheitsfall",
      "Elements": [
        [
          {
            "Label": "Beginn der Krankheit",
            "Database": "",
            "DbText": "LblBeginn_der_Krankheit",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Mieterhoehungsverlangen_559",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ende der Krankheit",
            "Database": "",
            "DbText": "LblEnde_der_Krankheit",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Zugang_Mieterhoehungsverlangen_559",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Bereits an derselben Krankheit erkrankt gewesen?",
            "Database": "",
            "DbText": "LblMieterhoehung_neu_Wirksamkeitsdatum_559",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Dieselbe_Krankheit",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kündigung erhalten ?",
            "Database": "",
            "DbText": "LNettoEinkommen_Mieter",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Kündigung_Krankheit",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wenn ja, Datum:",
            "Database": "",
            "DbText": "LDatum_Ende_Mietverhaeltnis_Kuendigung_Mieter_559",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Ende_Mietverhaeltnis_Kuendigung_Mieter_559",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zugehörige Dokumente",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Arbeitsunfähigkeitsbescheinigung",
            "Database": "",
            "DbText": "ChkDok_Arbeitsunfähigkeitsbescheinigung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Elternzeit",
      "Elements": [
        [
          {
            "Label": "Lebt das Kind in Ihrem Haushalt?",
            "Database": "",
            "DbText": "LblLebt_das_Kind_in_Ihrem_Haushalt",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "",
            "DbText": "Lebt_das_Kind_in_Ihrem_Haushalt",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Betreuen Sie das Kind persönlich?",
            "Database": "",
            "DbText": "LblBetreuen_Sie_das_Kind_persönlich",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "",
            "DbText": "Betreuen_Sie_das_Kind_persönlich",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ist das Kind keine 3 Jahre alt?",
            "Database": "",
            "DbText": "LblIst_das_Kind_keine_3_Jahre_alt",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "",
            "DbText": "Ist_das_Kind_keine_3_Jahre_alt",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wurde ein Teil der Elternzeit nicht in Anspruch genommen?",
            "Database": "",
            "DbText": "LblWurde_ein_Teil_der_Elternzeit_nicht_in_Anspruch_genommen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Ja",
            "Database": "",
            "DbText": "Ja_Elternzeit",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Nein",
            "Database": "",
            "DbText": "Nein_Elternzeit",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wenn ja, liegt eine Zustimmung des Arbeitgebers vor?",
            "Database": "",
            "DbText": "LblWenn_ja_lieht_eine_Zustimmung_des_Arbeitgebers_vor",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Ja",
            "Database": "",
            "DbText": "Ja_ZuAG",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Nein",
            "Database": "",
            "DbText": "Nein_Zu_AG",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sind Sie in der Elternzeit schwanger geworden?",
            "Database": "",
            "DbText": "LblSind_Sie_in_der_Elternzeit_schwanger_geworden",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Ja",
            "Database": "",
            "DbText": "Ja_Schwanger",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Nein ",
            "Database": "",
            "DbText": "Nein_Schwanger",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Zeugnis",
      "Elements": [
        [
          {
            "Label": "Berichtigungsanspruch_einfaches_und qualifiziertes Arbeitszeugnis",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Das Arbeitszeugnis hat folgende formelle Mängel:",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kein schriftliches Arbeitszeugnis",
            "Database": "",
            "DbText": "Kein_schriftliches_Arbeitszeugnis",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Rechtschreibfehler",
            "Database": "",
            "DbText": "Rechtschreibfehler",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Nicht auf Firmenpapier verfasst",
            "Database": "",
            "DbText": "Nicht_auf_Firmenpapier_verfasst",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fehlen des Unterschriftes des Ausstellers",
            "Database": "",
            "DbText": "Fehlen_des_Unterschriftes_des_Ausstellers",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Die äußere Form wurde gewahrt",
            "Database": "",
            "DbText": "Die_äußere_Form_wurde_gewahrt",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Nicht in der dritten Person verfasst?",
            "Database": "",
            "DbText": "In_der_dritten_Person_verfasst",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Berichtigungsanspruch qualifiziertes Arbeitszeugnis",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fehlen folgende inhaltliche Angaben:",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Art und Dauer der Beschäftigung",
            "Database": "",
            "DbText": "Art_und_Dauer_der_Beschäftigung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Angaben zur Leistung",
            "Database": "",
            "DbText": "Angaben_zur_Leistung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Angaben zum Verhalten",
            "Database": "",
            "DbText": "Angaben_zum_Verhalten",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Beinhaltet das qualifizierte Arbeistzeugnis folgende unzulässige Inhalte:",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fehlzeiten aufgrund von Kranheit oder Elternzeit",
            "Database": "",
            "DbText": "Fehlzeiten_aufgrund_von_Kranheit_oder_Elternzeit",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Das Erwähnen einer Schwangerschaft oder der Elternzeit",
            "Database": "",
            "DbText": "Das_Erwähnen_einer_Schwangerschaft_oder_der_Elternzeit",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schwerbehinderteneigenschaft",
            "Database": "",
            "DbText": "Schwerbehinderteneigenschaft",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gesundheitszustand",
            "Database": "",
            "DbText": "Gesundheitszustand",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Parteimitgliedschaft",
            "Database": "",
            "DbText": "Parteimitgliedschaft",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gewerkschaftszugehörigkeit",
            "Database": "",
            "DbText": "Gewerkschaftszugehörigkeit",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Erwähnung der Teilnahme an Streiks",
            "Database": "",
            "DbText": "Erwähnung_der_Teilnahme_an_Streiks",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Erwähnen einer Betriebsratstätigkeit",
            "Database": "",
            "DbText": "Erwähnen_einer_Betriebsratstätigkeit",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Erwähnen von Nebentätigkeiten",
            "Database": "",
            "DbText": "Erwähnen_von_Nebentätigkeiten",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Erwähnen des negativen Kündigungsgrundes",
            "Database": "",
            "DbText": "Erwähnen_des_negativen_Kündigungsgrundes",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Erwähnen des Verdachs einer strafbaren Handlung",
            "Database": "",
            "DbText": "Erwähnen_des_Verdachs_einer_strafbaren_Handlung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Diffarmierende Inhalte",
            "Database": "",
            "DbText": "Diffarmierende_Inhalte",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Erwähnen von Straftaten die das Arbeitsverhältnis nicht unmittelbar berühren",
            "Database": "",
            "DbText": "Erwähnen_von_Straftaten_die_das_Arbeitsverhältnis_nicht_unmittelbar_berühren",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Dem Gesamteindruck widersprechende Schlussformel",
            "Database": "",
            "DbText": "Dem_Gesamteindruck_widersprechende_Schlussformel",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    }
  ],
  "Mandant": [
    [
      {
        "Label": "Suchbegriff*",
        "Database": "",
        "DbText": "LblSuchbegriff",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Keyword",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Keyword"
      }
    ],
    [
      {
        "Label": "Vorname",
        "Database": "",
        "DbText": "LblVorname",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "FirstName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Name*",
        "Database": "",
        "DbText": "LblName1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Name1"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lblname2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Name2"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lblname3",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name3",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Name3"
      }
    ],
    [
      {
        "Label": "Straße*",
        "Database": "",
        "DbText": "LblStrasse",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Address",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Address"
      }
    ],
    [
      {
        "Label": "Postleitzahl* / Ort*",
        "Database": "",
        "DbText": "LblPLZ_Ort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ZipCode",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "ZipCode"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "CityName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "CityName"
      }
    ],
    [
      {
        "Label": "Telefonnummer 1",
        "Database": "",
        "DbText": "LblTelefonnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Phone1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Phone1"
      }
    ],
    [
      {
        "Label": "Telefonnummer 2",
        "Database": "",
        "DbText": "Lbltel2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "tel2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Phone2"
      }
    ],
    [
      {
        "Label": "Faxnummer",
        "Database": "",
        "DbText": "LblFaxnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/DefaultZuord",
        "DbText": "Fax",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Fax"
      }
    ],
    [
      {
        "Label": "Handynummer",
        "Database": "",
        "DbText": "LblHandynummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "HandyNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "MobilePhone"
      }
    ],
    [
      {
        "Label": "E-Mail",
        "Database": "",
        "DbText": "Lbllongmail",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "EmailAddress",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "EmailAddress"
      }
    ],
    [
      {
        "Label": "Homepage",
        "Database": "",
        "DbText": "LblHomepage",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Homepage",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Kommunikationsdaten",
        "Database": "",
        "DbText": "LblKommunikationsdaten",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Headers": [
          "Medium",
          "Nummer"
        ],
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Kommunikationsdaten",
        "Type": "TABLE",
        "Size": 9,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Rechtsform*",
        "Database": "",
        "DbText": "LblRechtsform",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/DefaultZuord",
        "DbText": null,
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "NameID"
      }
    ],
    [
      {
        "Label": "Anrede Briefkopf",
        "Database": "",
        "DbText": "LblAnredeBriefkopf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeBriefkopf",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Anrede"
      }
    ],
    [
      {
        "Label": "Anrede direkt",
        "Database": "",
        "DbText": "LblAnrededirekt",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeDirekt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Briefanrede"
      }
    ],
    [
      {
        "Label": "Bankverbindung",
        "Database": "",
        "DbText": "LblBankverbindung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Nein/Bank",
        "DbText": "kurzbez",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 57,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "bank",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      }
    ],
    [
      {
        "Label": "BLZ/BIC",
        "Database": "",
        "DbText": "LblBLZ_BIC",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "blz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BLZ"
      },
      {
        "Label": "",
        "Database": "Nein/Bank",
        "DbText": "bic",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 11,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BIC"
      }
    ],
    [
      {
        "Label": "Konto / IBAN",
        "Database": "",
        "DbText": "LblKonto_IBAN",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ktonr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "iban",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 34,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "SEPA Lastschr.",
        "Database": "",
        "DbText": "LblSEPALastschr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "MandatSEPA",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 35,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Datum SEPA",
        "Database": "",
        "DbText": "LblDatumSEPA",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "MandatSEPAdatum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 10,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Geburtsname",
        "Database": "",
        "DbText": "LblGebname",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Gebnam",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Geburtsdatum",
        "Database": "",
        "DbText": "Lblgebdat",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "BirthDate",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 10,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Beruf/Erwerbstätigkeit",
        "Database": "",
        "DbText": "LblBeruf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/DefaultZuord",
        "DbText": "Beruf",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Familienstand",
        "Database": "",
        "DbText": "LblFamilienstand",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Values": [
          "",
          "Ledig",
          "Verheiratet seit",
          "Geschieden seit",
          "Getrennt lebend seit",
          "Verwitwet seit ",
          "Eingetragene Lebenspartnerschaft seit"
        ],
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Familienstand",
        "Type": "COMBOBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Beginn_Aktueller_Familienstand",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Gesetzl. Vertreter",
        "Database": "",
        "DbText": "LblGeve1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "geve1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Abrechnungsdaten",
        "Type": "HEADLINE",
        "Size": 12,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Zahlungsart",
        "Database": "",
        "DbText": "LblZahlungsart",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Values": [
          "",
          "Selbstzahler",
          "Rechtsschutzversicherung",
          "Prozesskostenhilfe",
          "RECHT-nah Card"
        ],
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Zahlungsart",
        "Type": "COMBOBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Versicherung",
        "Database": "",
        "DbText": "LblVersicherung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Versicherung",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Versicherungsnummer",
        "Database": "",
        "DbText": "LblVersicherungsnr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Versicherungsnr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Selbstbeteiligung",
        "Database": "",
        "DbText": "LblSelbstbeteiligung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Selbstbeteiligung",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Schadensnummer",
        "Database": "",
        "DbText": "LblSchadensnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Schadensnummer",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Nur bei RECHT-nah Card",
        "Type": "HEADLINE",
        "Size": 12,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Kartennummer",
        "Database": "",
        "DbText": "LblRechtNahCard_Kartennummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "RechtNahCard_Kartennummer",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Belege / Dokumente:",
        "Database": "",
        "DbText": "LblBelege",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Kopie Personalausweis",
        "Database": "XML/Default",
        "DbText": "Personalausweiskopie",
        "Type": "CHECKBOX",
        "Size": 3,
        "Skip": 3,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Schreiben Übernahme PKH-Kosten",
        "Database": "XML/Default",
        "DbText": "SchreibenZuProzesskostenübernahme",
        "Type": "CHECKBOX",
        "Size": 3,
        "Skip": 3,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "1. Unterhaltsanspruch gegenüber anderen Personen (jeweils BELEGE)",
        "Type": "HEADLINE",
        "Size": 12,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Gibt es Angehörige, die dem Mandanten ggü. gesetzlich zur Leistung von Unterhalt verpflichtet sind",
        "Database": "XML/Default",
        "DbText": "Unterhaltsanspruch_gegeben",
        "Type": "CHECKBOX",
        "Size": 3,
        "Skip": 3,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Name, Vorname",
        "Database": "",
        "DbText": "LblUVP1_Name_Vorname",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_Name_Vorname",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Verhältnis",
        "Database": "",
        "DbText": "LblUBP1_Verhältnis",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_Verhältnis",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Hat die 1. zu Unterhalt verplflichtete Person Einnahmen aus  (bitte die  monatlichen Bruttobeträge in EUR angeben) – jeweils BELEGE",
        "Database": "XML/Default",
        "DbText": "UVP1_HatEinnahmenAus",
        "Type": "CHECKBOX",
        "Size": 3,
        "Skip": 3,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Nichtselbständiger Arbeit?",
        "Database": "",
        "DbText": "LblUVP1_Nichtselbständige_Arbeit",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_Nichtselbständige_Arbeit",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_EinnahmenNichtselbständiger_Arbeit",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "mtl. EUR brutto",
        "Database": "",
        "DbText": "Lblbrutto2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Selbständiger Arbeit/Gewerbebetrieb/Land- u. Forstwirtschaft",
        "Database": "",
        "DbText": "LblUVP1_SelbständigerArbeit",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_SelbständigerArbeit",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_EinnahmenSelbständigerArbeit",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "mtl. EUR brutto",
        "Database": "",
        "DbText": "Lblbrutto3",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Vermietung?",
        "Database": "",
        "DbText": "LblUVP1_Vermietung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_Vermietung",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_EinnahmenVermietung",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "mtl. EUR brutto",
        "Database": "",
        "DbText": "Lblbrutto5",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Verpachtung?",
        "Database": "",
        "DbText": "LblUVP1_Verpachtung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_Verpachtung",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_EinnahmenVerpachtung",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "mtl. EUR brutto",
        "Database": "",
        "DbText": "Lblbrutto4",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Kapitalvermögen?",
        "Database": "",
        "DbText": "LblUVP1_Kapitalvermögen",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_Kapitalvermögen",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_EinnahmenKapitalvermögen",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "mtl. EUR brutto",
        "Database": "",
        "DbText": "Lblbrutto8",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Kindergeld?",
        "Database": "",
        "DbText": "LblUVP1_Kindergeld",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_Kindergeld",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_EinnahmenKindergeld",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "mtl. EUR brutto",
        "Database": "",
        "DbText": "Lblbrutto9",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Kinderzuschlag?",
        "Database": "",
        "DbText": "LblUVP1_Kinderzuschlag",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_Kinderzuschlag",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_EinnahmenKinderzuschlag",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "mtl. EUR brutto",
        "Database": "",
        "DbText": "Lblbrutto7",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Wohngeld?",
        "Database": "",
        "DbText": "LblUVP1_Wohngeld",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_Wohngeld",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_EinnahmenWohngeld",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "mtl. EUR brutto",
        "Database": "",
        "DbText": "Lblbrutto6",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Unterhalt?",
        "Database": "",
        "DbText": "LblUVP1_Unterhalt",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_Unterhalt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_EinnahmenUnterhalt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "mtl. EUR brutto",
        "Database": "",
        "DbText": "Lblbrutto11",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Rente/Pension?",
        "Database": "",
        "DbText": "LblUVP1_Rente_Pension",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_Rente_Pension",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_EinnahmenRente_Pension",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "mtl. EUR brutto",
        "Database": "",
        "DbText": "Lblbrutto13",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Arbeitslosengeld?",
        "Database": "",
        "DbText": "LblUVP1_Arbeitslosengeld",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_Arbeitslosengeld",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_EinnahmenArbeitslosengeld",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "mtl. EUR brutto",
        "Database": "",
        "DbText": "Lblbrutto15",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Arbeitslosengeld II?",
        "Database": "",
        "DbText": "LblUVP1_Arbeitslosengeld_II",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_Arbeitslosengeld_II",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_EinnahmenArbeitslosengeld_II",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "mtl. EUR brutto",
        "Database": "",
        "DbText": "Lblbrutto14",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Krankengeld?",
        "Database": "",
        "DbText": "LblUVP1_Krankengeld",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_Krankengeld",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_EinnahmenKrankengeld",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "mtl. EUR brutto",
        "Database": "",
        "DbText": "Lblbrutto10",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Elterngeld?",
        "Database": "",
        "DbText": "LblUVP1_Elterngeld",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_Elterngeld",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_EinnahmenElterngeld",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "mtl. EUR brutto",
        "Database": "",
        "DbText": "Lblbrutto1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Andere Einnahmen?",
        "Database": "",
        "DbText": "LblUVP1_Andere_Einnahmen",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_Andere_Einnahmen",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "EUR brutto:",
        "Database": "",
        "DbText": "Lblbrutto16",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_EinnahmenAndere_Einnahmen",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Art der Einnahme",
        "Database": "",
        "DbText": "LblUVP1_Art_der_Einnahme",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_Art_der_Einnahme",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Bezugszeitraum (einmalig, monatlich, jährlich o.ä.)",
        "Database": "",
        "DbText": "LblUVP1_Bezugszeitraum",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_Bezugszeitraum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Höhe in EUR brutto",
        "Database": "",
        "DbText": "LblUVP1_Höhe_in_EUR_brutto",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "UVP1_Höhe_in_EUR_brutto",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Gegner": [
    [
      {
        "Label": "Suchbegriff*",
        "Database": "",
        "DbText": "LSuchbegriff",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Keyword",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Keyword"
      }
    ],
    [
      {
        "Label": "Vorname",
        "Database": "",
        "DbText": "LVorname",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "FirstName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "FirstName"
      }
    ],
    [
      {
        "Label": "Name*",
        "Database": "",
        "DbText": "LName",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Name1"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lname2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Name2"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lblname3",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name3",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Name3"
      }
    ],
    [
      {
        "Label": "Straße*",
        "Database": "",
        "DbText": "LStrasse",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Address",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Address"
      }
    ],
    [
      {
        "Label": "Postleitzahl* / Ort*",
        "Database": "",
        "DbText": "LPLZ_Ort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ZipCode",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "ZipCode"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "CityName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "CityName"
      }
    ],
    [
      {
        "Label": "Telefonnummer 1",
        "Database": "",
        "DbText": "LTelefonnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Phone1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Phone1"
      }
    ],
    [
      {
        "Label": "Telefonnummer 2",
        "Database": "",
        "DbText": "Lbltel2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "tel2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Phone2"
      }
    ],
    [
      {
        "Label": "Faxnummer",
        "Database": "",
        "DbText": "LFaxnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Fax",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Fax"
      }
    ],
    [
      {
        "Label": "Handynummer",
        "Database": "",
        "DbText": "LHandynummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "HandyNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "MobilePhone"
      }
    ],
    [
      {
        "Label": "E-Mail",
        "Database": "",
        "DbText": "Llongmail",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "EmailAddress",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "EmailAddress"
      }
    ],
    [
      {
        "Label": "Homepage",
        "Database": "Fats/Default",
        "DbText": "LblHomepage",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "URL"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Homepage",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Kommunikationsdaten",
        "Database": "",
        "DbText": "LblKommunikationsdaten",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Headers": [
          "Medium",
          "Nummer"
        ],
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Kommunikationsdaten",
        "Type": "TABLE",
        "Size": 9,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Rechtsform*",
        "Database": "",
        "DbText": "LRechtsform",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": null,
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "NameID"
      }
    ],
    [
      {
        "Label": "Anrede Briefkopf",
        "Database": "",
        "DbText": "LAnredeBriefkopf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeBriefkopf",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Anrede"
      }
    ],
    [
      {
        "Label": "Anrede direkt",
        "Database": "",
        "DbText": "LAnrededirekt",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeDirekt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Briefanrede"
      }
    ],
    [
      {
        "Label": "Gegn. Anwalt",
        "Database": "",
        "DbText": "Lgeganwalt",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/DefaultZuord",
        "DbText": "geganwalt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "AZ gegn. Anwalt",
        "Database": "",
        "DbText": "Lazgeganw",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/DefaultZuord",
        "DbText": "azgeganw",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Bankverbindung",
        "Database": "",
        "DbText": "LBankverbindung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "kurzbez",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 57,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "bank",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      }
    ],
    [
      {
        "Label": "BLZ/BIC",
        "Database": "",
        "DbText": "LBLZ_BIC",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "blz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BLZ"
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "bic",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 11,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BIC"
      }
    ],
    [
      {
        "Label": "Konto / IBAN",
        "Database": "",
        "DbText": "LKonto_IBAN",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ktonr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "iban",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 34,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "SEPA Lastschr.",
        "Database": "",
        "DbText": "LSEPALastschr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "MandatSEPA",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 35,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Datum SEPA",
        "Database": "",
        "DbText": "LDatumSEPA",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "MandatSEPAdatum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 10,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Behorde": [
    [
      {
        "Label": "Suchbegriff*",
        "Database": "",
        "DbText": "LSuchbegriff",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Keyword",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Keyword"
      }
    ],
    [
      {
        "Label": "Name*",
        "Database": "",
        "DbText": "LName1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Name1"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lname2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Name2"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lname3",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name3",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Name3"
      }
    ],
    [
      {
        "Label": "Straße",
        "Database": "",
        "DbText": "lstr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Address",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Address"
      }
    ],
    [
      {
        "Label": "Länderkennzeichen",
        "Database": "",
        "DbText": "llkz",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "lkz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 3,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "CountryID"
      }
    ],
    [
      {
        "Label": "PLZ",
        "Database": "",
        "DbText": "lplz",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ZipCode",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "ZipCode"
      }
    ],
    [
      {
        "Label": "Wohnort",
        "Database": "",
        "DbText": "lort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "CityName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "CityName"
      }
    ],
    [
      {
        "Label": "Telefon1",
        "Database": "",
        "DbText": "ltel1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Phone1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Phone1"
      }
    ],
    [
      {
        "Label": "Telefon2",
        "Database": "",
        "DbText": "ltel2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "tel2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Phone2"
      }
    ],
    [
      {
        "Label": "Zentralrufnummer",
        "Database": "",
        "DbText": "lzentralrufnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "zentralrufnummer",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "CentralPhone"
      }
    ],
    [
      {
        "Label": "Fax",
        "Database": "",
        "DbText": "lfax",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Fax",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Fax"
      }
    ],
    [
      {
        "Label": "E-Mail",
        "Database": "",
        "DbText": "llongmail",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "EmailAddress",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "EmailAddress"
      }
    ],
    [
      {
        "Label": "Telekommunikation",
        "Database": "",
        "DbText": "ltelkom",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "telkom",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Homepage",
        "Database": "",
        "DbText": "lhomepage",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "homepage",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "URL"
      }
    ],
    [
      {
        "Label": "Kommunikationsdaten",
        "Database": "",
        "DbText": "LblKommunikationsdaten",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Headers": [
          "Medium",
          "Nummer"
        ],
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Kommunikationsdaten",
        "Type": "TABLE",
        "Size": 9,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Anrede Briefkopf",
        "Database": "",
        "DbText": "lan1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeBriefkopf",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Anrede"
      }
    ],
    [
      {
        "Label": "Anrede direkt",
        "Database": "",
        "DbText": "lan2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeDirekt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Briefanrede"
      }
    ],
    [
      {
        "Label": "Bankverbindung",
        "Database": "",
        "DbText": "LBankverbindung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "kurzbez",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 57,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "bank",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      }
    ],
    [
      {
        "Label": "BLZ/BIC",
        "Database": "",
        "DbText": "LBLZ_BIC",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "blz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BLZ"
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "bic",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 11,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BIC"
      }
    ],
    [
      {
        "Label": "Konto / IBAN",
        "Database": "",
        "DbText": "LKonto_IBAN",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ktonr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "iban",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 34,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Gericht": [
    [
      {
        "Label": "Suchbegriff*",
        "Database": "",
        "DbText": "LSuchbegriff",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Keyword",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Keyword"
      }
    ],
    [
      {
        "Label": "Name*",
        "Database": "",
        "DbText": "LName1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Name1"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lname2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Name2"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lname3",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name3",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Name3"
      }
    ],
    [
      {
        "Label": "Straße",
        "Database": "",
        "DbText": "LStrasse",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Address",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Address"
      }
    ],
    [
      {
        "Label": "Postleitzahl",
        "Database": "",
        "DbText": "LPLZ_Ort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ZipCode",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "ZipCode"
      }
    ],
    [
      {
        "Label": "Gerichtsort",
        "Database": "",
        "DbText": "Lort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "CityName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "CityName"
      }
    ],
    [
      {
        "Label": "Gerichtskennzahl",
        "Database": "",
        "DbText": "LRechtsform",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "GerichtsKennzahl",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Telefonnummer 1",
        "Database": "",
        "DbText": "LTelefonnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Phone1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Phone1"
      }
    ],
    [
      {
        "Label": "Telefonnummer 2",
        "Database": "",
        "DbText": "Lbltel2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "tel2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Phone2"
      }
    ],
    [
      {
        "Label": "Faxnummer",
        "Database": "",
        "DbText": "LFaxnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Fax",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Fax"
      }
    ],
    [
      {
        "Label": "Handynummer",
        "Database": "",
        "DbText": "LHandynummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "HandyNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "MobilePhone"
      }
    ],
    [
      {
        "Label": "E-Mail",
        "Database": "",
        "DbText": "Llongmail",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "EmailAddress",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "EmailAddress"
      }
    ],
    [
      {
        "Label": "Homepage",
        "Database": "",
        "DbText": "LblHomepage",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Homepage",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Kommunikationsdaten",
        "Database": "",
        "DbText": "LblKommunikationsdaten",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Headers": [
          "Medium",
          "Nummer"
        ],
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Kommunikationsdaten",
        "Type": "TABLE",
        "Size": 9,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Anrede Briefkopf",
        "Database": "",
        "DbText": "lan1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeBriefkopf",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Anrede"
      }
    ],
    [
      {
        "Label": "Anrede direkt",
        "Database": "",
        "DbText": "lan2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeDirekt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Briefanrede"
      }
    ],
    [
      {
        "Label": "Bankverbindung",
        "Database": "",
        "DbText": "LBankverbindung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "kurzbez",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 57,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "bank",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      }
    ],
    [
      {
        "Label": "BLZ/BIC",
        "Database": "",
        "DbText": "LBLZ_BIC",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "blz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BLZ"
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "bic",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 11,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BIC"
      }
    ],
    [
      {
        "Label": "Konto / IBAN",
        "Database": "",
        "DbText": "LKonto_IBAN",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ktonr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "iban",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 34,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Weitere": [
    [
      {
        "Label": "Suchbegriff*",
        "Database": "",
        "DbText": "LSuchbegriff",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Keyword",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Keyword"
      }
    ],
    [
      {
        "Label": "Vorname",
        "Database": "",
        "DbText": "LVorname",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "FirstName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Name*",
        "Database": "",
        "DbText": "LName",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Name1"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "MyLabel1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Name2"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lblname3",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name3",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Name3"
      }
    ],
    [
      {
        "Label": "Straße",
        "Database": "",
        "DbText": "LStrasse",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Address",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Address"
      }
    ],
    [
      {
        "Label": "Postleitzahl / Ort",
        "Database": "",
        "DbText": "LPLZ_Ort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ZipCode",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "ZipCode"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "CityName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "CityName"
      }
    ],
    [
      {
        "Label": "Telefonnummer 1",
        "Database": "",
        "DbText": "LTelefonnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Phone1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Phone1"
      }
    ],
    [
      {
        "Label": "Telefonnummer 2",
        "Database": "",
        "DbText": "Lbltel2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "tel2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Phone2"
      }
    ],
    [
      {
        "Label": "Faxnummer",
        "Database": "",
        "DbText": "LFaxnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Fax",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Fax"
      }
    ],
    [
      {
        "Label": "Handynummer",
        "Database": "",
        "DbText": "LHandynummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "HandyNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "MobilePhone"
      }
    ],
    [
      {
        "Label": "E-Mail",
        "Database": "",
        "DbText": "Llongmail",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "EmailAddress",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "EmailAddress"
      }
    ],
    [
      {
        "Label": "Homepage",
        "Database": "",
        "DbText": "LblHomepage",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Homepage",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Kommunikationsdaten",
        "Database": "",
        "DbText": "LblKommunikationsdaten",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Headers": [
          "Medium",
          "Nummer"
        ],
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Kommunikationsdaten",
        "Type": "TABLE",
        "Size": 9,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Rechtsform*",
        "Database": "",
        "DbText": "LRechtsform",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": null,
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "NameID"
      }
    ],
    [
      {
        "Label": "Anrede Briefkopf",
        "Database": "",
        "DbText": "LAnredeBriefkopf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeBriefkopf",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Anrede"
      }
    ],
    [
      {
        "Label": "Anrede direkt",
        "Database": "",
        "DbText": "LAnrededirekt",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeDirekt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Briefanrede"
      }
    ],
    [
      {
        "Label": "Beteiligungsart",
        "Database": "",
        "DbText": "Llbetart",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/DefaultZuord",
        "DbText": "Beteiligungsart",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 3,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "",
        "DbText": "Zuo_Beteiligungsart",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Bankverbindung",
        "Database": "",
        "DbText": "LBankverbindung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "kurzbez",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 57,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "bank",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      }
    ],
    [
      {
        "Label": "BLZ/BIC",
        "Database": "",
        "DbText": "LBLZ_BIC",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "blz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BLZ"
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "bic",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 11,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BIC"
      }
    ],
    [
      {
        "Label": "Konto / IBAN",
        "Database": "",
        "DbText": "LKonto_IBAN",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ktonr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "iban",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 34,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "SEPA Lastschr.",
        "Database": "",
        "DbText": "LSEPALastschr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "MandatSEPA",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 35,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Datum SEPA",
        "Database": "",
        "DbText": "LDatumSEPA",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "MandatSEPAdatum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 10,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ]
}`);
