import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { RolesAndRights } from "../../helpers/RolesAndRights";
import { MainViewModel } from "../../main";
import '../dataTable/dataTable';

export class InboxOverviewViewModel {
    inboxes = ko.observableArray([]);
    hasAdminRights = ko.observable<boolean>(false);
    isViewerRole = ko.observable<boolean>(false);

    async loadInboxes() {
        let inboxes = await RNSAPI.getAllInboxes();
        this.inboxes(inboxes.Payload.Accounts.map((inbox) => {
            inbox.actionHandlers = [];
            if(this.hasAdminRights() || !this.isViewerRole()) {
                inbox.actionHandlers = [{
                    icon: "pencil-alt",
                    name: "Edit",
                    action: () => MainViewModel.RoutingTable.showInboxView({ id: inbox.EMailAccount_ID })
                }, {
                    icon: "trash-alt",
                    name: "Löschen",
                    action: async () => {
                        await RNSAPI.deleteInboxById(inbox.EMailAccount_ID);
                        this.loadInboxes();
                    }
                }];
            }
            return inbox;
        }));
    }

    constructor() {
        this.hasAdminRights(RolesAndRights.hasAdminRights());
        this.isViewerRole(RolesAndRights.isViewerRole());
        this.loadInboxes();
    }
}

let html = fs.readFileSync(__dirname + '/inboxoverview.html', 'utf8');

ko.components.register("inboxoverview-view", {
    viewModel: InboxOverviewViewModel,
    template: html
});