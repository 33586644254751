import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI, Routing } from "../../api";
import { MainViewModel } from "../../main";
import { StrategyDto } from "./strategy";
import '../dataTable/dataTable';

export class StrategyOverviewViewModel {
    strategies: ko.ObservableArray<StrategyDto> = ko.observableArray([]);

    createStrategy() {
        MainViewModel.RoutingTable.showStrategyView();
    }

    async loadStrategies() {
        let strategies = (await RNSAPI.getStrategies()).Payload.Strategies.map(s => {
            s.actionHandlers = [
                {
                    icon: "trash-o",
                    name: "Löschen",
                    action: async () => {
                        await RNSAPI.unjoinTaxonomie(s.StrategyId);
                        this.loadStrategies();
                    }
                }
            ];

            return s;
        });

        this.strategies(strategies);
    }

    constructor() {
        this.loadStrategies();
    }
}

let html = fs.readFileSync(__dirname + '/overview.html', 'utf8');

ko.components.register("strategyoverview-view", {
    viewModel: StrategyOverviewViewModel,
    template: html
});