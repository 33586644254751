import { RNSAPI } from '../../api';
import { MainViewModel } from "../../main";
import * as fs from "fs";
import * as ko from "knockout";

class CaseEntryViewModel {
    IsEditMode = ko.observable(false);
    basicEntry = this.newBasicEntry();

    //replace with ajax request at some time
    workflowTable = {
        "": "<Kein Worfklow>",
        "Ak": "Autorenkorrektur",
        "B": "Zur Bearbeitung",
        "BDS": "Löschung wg. BDSG",
        "Di": "Diktat",
        "E": "Erledigt",
        "EGP": "an EGVP übergeben",
        "EIN": "Empfangene Nachrichten",
        "ERV": "Elektronischer Rechtsverkehr",
        "F": "Fertigstellung",
        "PA": "Postausgang",
        "PE": "Posteingang",
        "PR": "Prüfung",
        "SG": "Signatur geprüft",
        "SI": "Signiert",
        "St": "Standardtext",
        "U": "Zur Unterschrift",
        "V": "Versand",
        "VF": "Versandfertig machen",
        "VPA": "Archiv VP",
        "ZS": "Zur Signatur",
        "ZU": "zur Unterschrift",
        "b": "Bearbeitung Büro",
        "eCD": "Download eConsult",
        "eCU": "Upload eConsult",
        "eDD": "Download Drebis",
        "eDU": "Upload Drebis",
        "ra": "Bearbeitung Anwalt"
    }

    types = ko.observableArray(Object.keys(this.workflowTable).map(key => this.workflowTable[key]));
    selectedWorkflow = ko.observable(this.types()[0]);

    newBasicEntry() {
        let obj = {
            Id: ko.observable(null),
            CaseId: ko.observable(""),
            CurrentCRTVersion: ko.observable(" "),
            DocType: ko.observable(0),
            Documents: ko.observableArray([]),
            LawyerId: ko.observable(RNSAPI.User() ? RNSAPI.User().username : "GR"),
            MimeType: ko.observable("application/pdf"),
            Note1: ko.observable(""),
            Recipient: ko.observable(""),
            Subject: ko.observable(""),
            imex: ko.observable("A"),
            isScanned: ko.observable("0"),
            isVerschluss: ko.observable(false),
            WorkflowId: ko.observable("")
        };

        return ko.observable(obj);
    };

    async createEntry(document: any, ending: string) {
        let documentObj = {
            CRTVersion: " ",
            DocumentData: btoa(document.result),
            OleType: ending.toUpperCase(),
            OLE2Type: ending.toUpperCase(),
            useOCR: false
        };

        this.basicEntry().Documents([documentObj]);

        try {
            let result = await RNSAPI.createCaseEntry(this.basicEntry());
            if (result.Type === "InsertionSuccessful") {
                MainViewModel.RoutingTable.showCaseEntriesView({ caseId: this.basicEntry().CaseId() });
            } else {
                alert("Fehler beim Anlegen.");
            }
        } catch (e) {
            console.log(e);
            alert("Fehler beim Anlegen: " + e.responseText);
        }
    }

    async updateDocument(document: any) {
        try {
            let result = await RNSAPI.updateCaseEntryDocument(this.basicEntry().Id(), btoa(document.result));
            if (result.Type === "UpdateSuccessful") {
                MainViewModel.RoutingTable.showCaseEntriesView({ caseId: this.basicEntry().CaseId() });
            } else {
                alert("Fehler beim Speichern des Dokumentes.");
            }
        } catch (e) {
            console.log(e);
            alert("Fehler beim Speichern des Dokumentes: " + e.responseText);
        }
    }

    async updateEntry() {
        try {
            let result = await RNSAPI.updateCaseEntry(this.basicEntry());
            if (result.Type === "SetSuccessful") {
                MainViewModel.RoutingTable.showCaseEntriesView({ caseId: this.basicEntry().CaseId() });
            } else {
                alert("Fehler beim Speichern.");
            }
        } catch (e) {
            console.log(e);
            alert("Fehler beim Speichern: " + e.responseText);
        }
    }

    async postData() {
        let selectedFile = (document.getElementById('file') as any).files[0];

        if (selectedFile === undefined) {
            if (!this.IsEditMode()) {
                alert("Bitte geben Sie eine Datei an.");
                return;
            } else {
                await this.updateEntry();
            }
        } else {
            this.basicEntry().MimeType(selectedFile.type);
            if (!this.IsEditMode()) {
                let reader = new FileReader();
                reader.onload = async (evt) => await this.createEntry(evt.target, selectedFile.name.split('.').pop());
                reader.readAsBinaryString(selectedFile);
            } else {
                let reader = new FileReader();
                reader.onload = async (evt) => {
                    await this.updateEntry();
                    await this.updateDocument(evt.target);
                }
                reader.readAsBinaryString(selectedFile);
            }
        }

    }

    async loadEntry(eCaseRecordId: string) {
        try {
            let result = await RNSAPI.getCaseEntry(eCaseRecordId);

            if (result.Type === "IdFound") {
                let eCase = result.Payload.Record;
                console.log(eCase);
                let newECase = this.newBasicEntry();
                let obj = newECase();

                let excluding = [];

                for (let key in eCase) {
                    if (obj[key] === undefined) {
                        obj[key] = eCase[key];
                    } else if (excluding.indexOf(key) === -1) {
                        obj[key](eCase[key]);
                    }
                }

                this.basicEntry(obj);
                let workflowId = this.basicEntry().WorkflowId().trim();
                if (workflowId) {
                    this.selectedWorkflow(this.workflowTable[workflowId]);
                }
                console.log(ko.toJS(this.basicEntry()));
            } else {
                alert("Akteneintrag nicht gefunden.");
            }
        } catch (e) {
            alert("Verbindung zum Server abgebrochen.");
        }
    }


    constructor(params: any) {
        let eCaseRecordId = params.eCaseRecordId;
        if (eCaseRecordId) {
            this.IsEditMode(true);
            this.loadEntry(eCaseRecordId);
        } else {
            this.basicEntry().CaseId(params.caseId);
        }

        this.selectedWorkflow.subscribe((workflow) => {
            for (let id of Object.keys(this.workflowTable)) {
                if (this.workflowTable[id] === workflow) this.basicEntry().WorkflowId(id);
            }
        });
    }
}

let html = fs.readFileSync(__dirname + '/caseentry.html', 'utf8');

ko.components.register("caseentry-view", {
    viewModel: CaseEntryViewModel,
    template: html
});