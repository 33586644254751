import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { MainViewModel } from "../../main";
import '../dataTable/dataTable';
import { LayoutViewModel } from "../layout/layout";
export class CaseOverviewViewModel {
    public static action = {
        edit: ""
    }
    cases = ko.observableArray([]);
    referate = ko.observableArray([]);
    CurrentUser = ko.observable();
    ak = ko.observable();

    constructor(params: any) {

    }
}

let html = fs.readFileSync(__dirname + '/overview.html', 'utf8');

ko.components.register("timemonitoringov-view", {
    viewModel: CaseOverviewViewModel,
    template: html
});