import * as fs from "fs";
import * as ko from "knockout";
import Navigo = require("navigo");
import { RNSAPI } from "../../api";
import { User } from "../../UserData";
import { MainViewModel } from "../../main";
import { Utils } from "../../utils";

//var jwt = require('jwt-simple');

export class unlockViewModel {
    router = new Navigo(null, true);
    hasMobile = ko.observable(false);
    codeCorrect = ko.observable(false);
    yesReset = ko.observable(true);
    hasNoPhone = ko.observable(false);
    password = ko.observable('');
    confirmPassword = ko.observable('');
    email = ko.observable('');
    username = ko.observable('');
    courtId = ko.observable('');
    codes = ko.observable({
        code1: ko.observable(''),
        code2: ko.observable(''),
        code3: ko.observable(''),
        code4: ko.observable('')
    })
    userCode = ko.observable({ LawFirmID: "", UserID: "" });
    userCodeRec = ko.observable({ LawFirmID: "", UserID: "", SecurityCode: "" });
    randomQuestions = ko.observable({ randomQ1: {}, randomQ2: {}, randomQ3: {} });
    answers = ko.observable({ answer1: "", answer2: "", answer3: "" });
    currentUser: any = ko.observable({});
    verifyUserCode = ko.observable({ LawFirmID: "", UserID: "", SecurityQuestions: [{ SecurityQuestionID: "", QuestionAnswer: "" }] });
    defaultLocale: string = 'de';
    recaptchResponse = ko.observable('');
    recaptchaLoading: boolean = true;
    hasRecaptchaLoaded: boolean = false;
    hasRecapthaExpired = ko.observable(false);
    //verifyUserCode=ko.observable({LawFirmID:"",UserID:"",SecurityQuestions:[{SecurityQuestionID1:"",QuestionAnswer1:""},{SecurityQuestionID2:"",QuestionAnswer2:""},{SecurityQuestionID3:"",QuestionAnswer3:""}]});
    async reset() {
        if (!Utils.checkMultipleErrors(["code1", "code2", "code3", "code4"], this.codes(), "", [Utils.checkString])) {
            return;
        }

        this.userCodeRec().UserID = this.username();
        this.userCodeRec().LawFirmID = this.courtId()
        this.userCodeRec().SecurityCode = ko.toJS(this.codes().code1) + ko.toJS(this.codes().code2) + ko.toJS(this.codes().code3) + ko.toJS(this.codes().code4);

        //let result="SMSSecurityCodeCorrect";
        //let { Type } = await RNSAPI.verifyActivationCode(this.userCodeRec())
        let result = await RNSAPI.verifyActivationCode(this.userCodeRec());
        //Type = 'SMSActivationCodeCorrect'
        console.log(result);
        if (result.Type === "ActivationCodeCorrect") {
            //SuccessNotification
            this.gotoLogin();
        }
    }
    verifyCallback(response) {
        this.recaptchResponse(response);
        this.hasRecapthaExpired(false);
        //console.log('response: ', this.recaptchResponse())
    }

    recaptchaExpiredCallback() {
        this.hasRecapthaExpired(true);
    }
    onloadCallback() {

        this.recaptchaLoading = false;
        this.hasRecaptchaLoaded = true;
        const gRecaptchaRef = $('.g-recaptcha').get(0);
        //console.log(gRecaptchaRef);
        (<any>window).grecaptcha.render(gRecaptchaRef, {
            'sitekey': "6Lf1kjEUAAAAAAZySv-idV45OzAtwFvZs4AV8MfO",
            'callback': <any>((response: any) => this.verifyCallback(response)),
            'expired-callback': <any>(() => this.recaptchaExpiredCallback())
        });

    }
    displayRecaptcha() {

        var doc = <HTMLDivElement>document.getElementById('form');
        //console.log(doc);
        var script = document.createElement('script');
        script.innerHTML = '';
        //TODO: - Add locale if needed `this.defaultLocale`
        script.src = `https://www.google.com/recaptcha/api.js?onload=reCaptchaOnloadCallback&render=explicit&hl=${this.defaultLocale}`;
        script.async = true;
        script.defer = true;
        doc.appendChild(script);

    }

    gotoLogin() {
        this.router.navigate('/login');
    }
    constructor() {
        (<any>window).skip = function (o) {
            for (let i = 0; i < o.form.elements.length; ++i) {
                if (o == o.form.elements[i]) {
                    //MARK: - Focus and select only for existing elements.
                    if (o.form.elements[i].value.length > 0 && i + 1 < o.form.elements.length) {
                        o.form.elements[i].value = o.form.elements[i].value.substr(0, 1)
                        o.form.elements[i + 1].focus();
                        o.form.elements[i + 1].select();
                    }
                }
            }
        }
        if (document.location.href.endsWith('unlock')) {
            window[<any>"reCaptchaOnloadCallback"] = <any>(() => this.onloadCallback());
            this.displayRecaptcha();
        }
    }
}

var html = fs.readFileSync(__dirname + '/unlock.html', 'utf8');

ko.components.register("unlock-view", {
    viewModel: unlockViewModel,
    template: html
});