﻿import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI } from "../../api";
import '../dropdownsearch/dropdownsearch';

export class YNDialogView {
    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalSelectedObject = ko.observable(null);
    modalHandleSelection = ko.observable();
    givenId = "modal";
    AllCases = ko.observableArray();
    EntLength = ko.observable(0);
    ShownCasesCount = ko.observable(0);

    changedRegNr() {
        var sugg = '';
        document.getElementById('suggestions').innerHTML = "";
        let Entry = (<HTMLInputElement>document.getElementById('caseNumberInput')).value;
        let Counter = 0;
        this.EntLength(Entry.length);
        //console.log(this.EntLength);
        if (Entry.length > 2) {
            for (let i = 0; i < this.AllCases().length; i++) {
                if (ko.toJS(this.AllCases()[i]).includes(Entry)) {
                    console.log(this.AllCases()[i]);
                    //sugg += '<option value="' + this.AllCases()[i] + '"/>';
                    sugg += '<li><a class="dropdown-item" style="color: #3cb7d6 !important; padding-left:0.75rem" data-bind="click: () => setInputEntry()"/>' + this.AllCases()[i] + '</a></li>';
                    Counter++;
                }

            }
            this.ShownCasesCount(Counter);
            document.getElementById('suggestions').innerHTML = sugg;
        }
    }

    async getCases() {
        let ExtractCaseNumber = (await RNSAPI.getCases()).Payload.Cases;
        for (let i = 0; i < ExtractCaseNumber.length; i++) {
            //console.log(ExtractCaseNumber[i]);
            this.AllCases.push(ExtractCaseNumber[i].Registernummer) + " " + ExtractCaseNumber[i].Rubrum + " " + ExtractCaseNumber[i].Wegen;
        }
        //console.log(this.AllCases);
    }

    TestShow() {
        document.getElementById('suggestion').setAttribute('show', "true");
    }

    public setInputEntry() {
        console.log("adsfasdfas");
    }

    constructor(params: any) {
        if (params.prefix) {
            this.givenId = params.prefix + this.givenId;
        }
        this.getCases();
    }
}

let html = fs.readFileSync(__dirname + '/dialogYN.html', 'utf8');

ko.components.register("diayn-view", {
    viewModel: YNDialogView,
    template: html
});