import * as fs from "fs";
import * as ko from "knockout";
import Navigo = require("navigo");
import { RNSAPI } from "../../api";
import { User } from "../../UserData";
import { MainViewModel } from "../../main";
import { Utils } from "../../utils";

//var jwt = require('jwt-simple');

export class resetViewModel {
    router = new Navigo(null, true);
    hasMobile = ko.observable(false);
    codeCorrect = ko.observable(false);
    yesReset = ko.observable(true);
    hasNoPhone = ko.observable(false);
    password = ko.observable('');
    confirmPassword = ko.observable('');
    email = ko.observable('');
    username = ko.observable('');
    courtId = ko.observable('');
    codes = ko.observable({
        code1: ko.observable(''),
        code2: ko.observable(''),
        code3: ko.observable(''),
        code4: ko.observable('')
    })
    userCode = ko.observable({ LawFirmID: "", UserID: "", SendMethod:"", g_recaptcha: ''});
    userCodeRec = ko.observable({ LawFirmID: "", UserID: "", SecurityCode: "" });
    randomQuestions = ko.observable({ randomQ1: {}, randomQ2: {}, randomQ3: {} });
    answers = ko.observable({ answer1: "", answer2: "", answer3: "" });
    currentUser: any = ko.observable({});
    verifyUserCode = ko.observable({ LawFirmID: "", UserID: "", SecurityQuestions: [{ SecurityQuestionID: "", QuestionAnswer: "" }] });
    defaultLocale: string = 'de';
    recaptchResponse = ko.observable('');
    recaptchaLoading: boolean = true;
    hasRecaptchaLoaded: boolean = false;
    hasRecapthaExpired = ko.observable(false);
    isValidDTD = ko.computed({
        owner: this,
        read: () => this.recaptchResponse() && !this.hasRecapthaExpired()
    })

    //verifyUserCode=ko.observable({LawFirmID:"",UserID:"",SecurityQuestions:[{SecurityQuestionID1:"",QuestionAnswer1:""},{SecurityQuestionID2:"",QuestionAnswer2:""},{SecurityQuestionID3:"",QuestionAnswer3:""}]});
    async reset() {
        if (!Utils.checkMultipleErrors(["code1", "code2", "code3", "code4"], this.codes(), "", [Utils.checkString])) {
            return;
        }

        this.userCodeRec().UserID = this.username();
        this.userCodeRec().LawFirmID = this.courtId()
        this.userCodeRec().SecurityCode = ko.toJS(this.codes().code1) + ko.toJS(this.codes().code2) + ko.toJS(this.codes().code3) + ko.toJS(this.codes().code4);

        console.log(ko.toJS(this.userCodeRec()));
        //let result="SMSSecurityCodeCorrect";
        let { Type } = await RNSAPI.verifySMSRecoverCode(this.userCodeRec())
        // Type = 'SMSSecurityCodeCorrect'
        if (Type == "SMSSecurityCodeCorrect") {
            let token: any = await this.getToken();
            if (token) {
                RNSAPI.Token = token;
            }
            RNSAPI.sessionLogin();
            this.codeCorrect(true);
            this.hasMobile(false);
            this.yesReset(false);
            this.delay(2000).then(any => {
                this.getLawFirmUser();
            });  
        }
    }

    delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async getLawFirmUser() {
        let { Type, Payload } = await RNSAPI.getLawFirmUser(this.userCode().UserID);
        console.log(Payload);
        if (Type === "GetUserSuccessful") {
            this.currentUser(Payload.User);
        }
    }

    async changePassword() {
        //console.log("i am here ");
        try {
            if (this.password() !== this.confirmPassword()) {
                $('#error-msg').text('Password must match.')
                return;
            }
            else {
                console.log(ko.toJS(this.password()), /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/.test(ko.toJS(this.password())))
                if (ko.toJS(this.password()) === ko.toJS(this.confirmPassword()) && /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/.test(ko.toJS(this.password()).toString())) {
                    $("#pc_validation_message").hide();
                    //return true;
                }
                else {
                    if (this.password() !== this.confirmPassword()) {
                        $("#pc_message").html("Die Passwörter stimmen nicht überein.")

                    } else {
                        $('#pc_message').html('Bitte mindestens 8 Zeichen und eine Kombination aus Kleinbuchstaben, Großbuchstaben, Ziffern und Sonderzeichen verwenden.')
                    }
                    $("#pc_validation_message").show();
                    return false;
                }
            }

            this.currentUser().passwort = this.password()
            this.currentUser().PasswortRetry = this.confirmPassword()
            let result = await RNSAPI.updateLawFirmUser(ko.toJS(this.currentUser()));

            if (result.Type === "UpdateUserSuccessful") {
                this.gotoLogin();
            }
        }
        catch (error) {
            console.log("there is some error here");
        }
    }

    gotoLogin() {
        this.router.navigate('/login');
    }
    async noPhone() {
        //let token: any = await this.getToken();
        //if (token) {
        //    RNSAPI.Token = token;
        //}
        this.userCode().UserID = this.username();
        this.userCode().LawFirmID = this.courtId();
        //let result = await RNSAPI.randomSecurity(this.userCode())
        //if (result.Type !== "RandomSecurityQuestionsForUser") {
        //    return;
        //}
        //this.randomQuestions().randomQ1 = result.Payload.Question1;
        //this.verifyUserCode().SecurityQuestions[0].SecurityQuestionID = result.Payload.Question1.QuestionID;
        //this.verifyUserCode().SecurityQuestions[1].SecurityQuestionID = result.Payload.Question2.QuestionID;
        //this.verifyUserCode().SecurityQuestions[2].SecurityQuestionID = result.Payload.Question3.QuestionID;
        //this.randomQuestions().randomQ2 = result.Payload.Question2;
        //this.randomQuestions().randomQ3 = result.Payload.Question3;
        this.hasNoPhone(true);

        this.codeCorrect(false);
        this.hasMobile(false);
        this.yesReset(false);
    }

    async sendEMail() {
        //API Call will be implemented.
        try {

            this.userCode().UserID = this.username();
            this.userCode().LawFirmID = this.courtId();
            this.userCode().SendMethod = "";
            let result = await RNSAPI.sendSmsRecoverCode(this.userCode())

            if (result.Type === "SMSCodeSentSuccessfully") {
                this.hasMobile(true);
                this.codeCorrect(false);
                this.yesReset(false);
                this.reset();
            }
            else {
                this.codeCorrect(false);
                this.yesReset(false);
                this.hasMobile(true);
            }
        } catch (error) {
            console.log(error);
            this.codeCorrect(false);
            this.yesReset(false);
            this.hasMobile(true);
        }

    }

    async answerForSecurityQuestion() {
        if (this.answers().answer1 == "" || this.answers().answer2 == "" || this.answers().answer3 == "") {
            alert("answer all the questions");
            return
        }
        this.verifyUserCode().LawFirmID = ko.toJS(this.userCode().UserID);
        this.verifyUserCode().UserID = ko.toJS(this.userCode().LawFirmID);
        this.verifyUserCode().SecurityQuestions[0].QuestionAnswer = ko.toJS(this.answers().answer1);
        this.verifyUserCode().SecurityQuestions[1].QuestionAnswer = ko.toJS(this.answers().answer2);
        this.verifyUserCode().SecurityQuestions[2].QuestionAnswer = ko.toJS(this.answers().answer3);
        let result = await RNSAPI.verifySecurityQuestions(this.verifyUserCode());
        if (result.Type == "AllQuestionsVerifiedSuccessfully") {


        }

    }

    async sendSms() {
        try {

            this.userCode().UserID = this.username();
            this.userCode().LawFirmID = this.courtId();
            // this.userCode().SendMethod = "SMS";
            this.userCode().g_recaptcha = this.recaptchResponse();
            let result = await RNSAPI.sendSmsRecoverCode(this.userCode())
            //let result = 'SMSCodeSentSuccessfully';
            // if (result.Type = "SMSCodeSentSuccessfully") {
            if (result.Type === "SMSCodeSentSuccessfully") {
                console.log("we are now safe")
                //this.reset();
                this.hasMobile(true);
                this.codeCorrect(false);
                this.yesReset(false);
                this.reset();
                //let token: any = await this.getToken();
                //if (token) {
                //    RNSAPI.Token = token;
                //}
                //RNSAPI.sessionLogin();
            }
            else {
                // console.log(result.Type);
                //this.reset();
                this.codeCorrect(false);
                this.yesReset(false);
                this.hasMobile(true);
            }
        } catch (error) {
            console.log(error);
            this.codeCorrect(false);
            this.yesReset(false);
            this.hasMobile(true);
        }
    }
    async getToken() {

        let api = 'https://test.rainmaker.de/resetToken.php';
        let formData = new FormData()
        try {
            if (localStorage.getItem('LawFirmInfo')) {
                let lawFirmInfo = JSON.parse(localStorage.getItem('LawFirmInfo'))
                formData.append('reset_password', 'JA')
                formData.append('LawfirmID', lawFirmInfo['LawFirmId'])
                formData.append('UserID', lawFirmInfo['ShorthandSymbol'])
            }
        } catch (e) { }
        let token = await RNSAPI.loginRegisterApi(api, formData);
        return token;

    }

    verifyCallback(response) {
        this.recaptchResponse(response);
        this.hasRecapthaExpired(false);
        //console.log('response: ', this.recaptchResponse())
    }

    recaptchaExpiredCallback() {
        this.hasRecapthaExpired(true);
    }
    onloadCallback() {

        this.recaptchaLoading = false;
        this.hasRecaptchaLoaded = true;
        const gRecaptchaRef = $('.g-recaptcha').get(0);
        //console.log(gRecaptchaRef);
        (<any>window).grecaptcha.render(gRecaptchaRef, {
            'sitekey': "6Lft7N0UAAAAALcGZXBlulSleV6iUrpIGesLHMWl",
            'callback': <any>((response: any) => this.verifyCallback(response)),
            'expired-callback': <any>(() => this.recaptchaExpiredCallback())
        });

    }
    displayRecaptcha() {

        var doc = <HTMLDivElement>document.getElementById('form');
        //console.log(doc);
        var script = document.createElement('script');
        script.innerHTML = '';
        //TODO: - Add locale if needed `this.defaultLocale`
        script.src = `https://www.google.com/recaptcha/api.js?onload=reCaptchaOnloadCallback&render=explicit&hl=${this.defaultLocale}`;
        script.async = true;
        script.defer = true;
        doc.appendChild(script);

    }

    constructor() {
        (<any>window).skip = function (o) {
            for (let i = 0; i < o.form.elements.length; ++i) {
                if (o == o.form.elements[i]) {
                    //MARK: - Focus and select only for existing elements.
                    if (o.form.elements[i].value.length > 0 && i + 1 < o.form.elements.length) {
                        o.form.elements[i].value = o.form.elements[i].value.substr(0, 1)
                        o.form.elements[i + 1].focus();
                        o.form.elements[i + 1].select();
                    }
                }
            }
        }

        if (document.location.href.endsWith('reset')) {
            window[<any>"reCaptchaOnloadCallback"] = <any>(() => this.onloadCallback());
            // this.displayRecaptcha();
        }

        $(document).ready(function () {
            $("[data-toggle='tooltip']").tooltip();
        })
        try {
            if (localStorage.getItem('LawFirmInfo')) {
                let lawFirmInfo = JSON.parse(localStorage.getItem('LawFirmInfo'))
                this.username(lawFirmInfo['ShorthandSymbol'])
                this.courtId(lawFirmInfo['LawFirmId'])
            }
        } catch (e) { }
    }
}

var html = fs.readFileSync(__dirname + '/reset.html', 'utf8');

ko.components.register("reset-view", {
    viewModel: resetViewModel,
    template: html
});