import * as moment from "moment";
import { Utils } from "../../utils";

export class Konto {
    Abgerechnet: string // ''
    AdresseSuchbegriff: string // Keyword
    AdresseTyp: string // '1'
    Belegdatum: string
    Betreff: string // Rubrum
    Bezahlt: string // '0,00'
    Buchungsdatum: string
    Buchungstext1: string // "Honorarabrechnung RVG",
    Buchungstext2: string // ''
    Buchungstyp: string // "R"
    DatUhr: string
    EinOderAusgang: string // E or A
    Fremdgeld: string // "0,00"
    Gebuehrenerhoehung: string // ""
    Gesamtumsatz: string // '0,00' Total amount oft he invoice
    Honorar: string // '0,00' Total amount oft he invoice
    ImportOrExport: string // ''
    Jahrgang: string // this year's full date
    Konto: string // "",
    KorrespondierendeDatUhr: string // ""
    Menupunkt: string // 'HR-2'
    MindestGebuehr: string // ''
    Mwst: string // '19,00'
    NettoauslagenNachP26: string // "0,00",
    NettoauslagenNachP27: string // "0,00",
    NettoauslagenNachP28: string // "0,00",
    Nettogebuehr: string // netto amount of invoice
    Paragraph: string // ""
    Rechnungsnummer: string // *
    Referat: string //
    Registernummer: string // * Caseid
    Root: string // 'H'
    Sachbearbeiter: string // * User id
    SteuerfreieAuslagen: string // "0,00"
    SteuerpflichtigeAuslagen: string // "0,00"
    Streitwert: string // '0,00'
    Umsatz: string // '0,00'
    Unterkonto: string // ''
    Zehntel: string // ''

    constructor(
        keyword: string,
        rubrum: string,
        amount: string,
        netamount: string,
        rechNo: string,
        caseId: string,
        sachbearbeiterId: string,
        fr: string,
        fl: string,
        streitwert: string,
        referat: string,
        honorar: string,
        bookType: string = 'R',
        eInAus: string = 'A',
        mwst: string = '19,00',
        menupunkt: string = 'HR-2',
        bookText: string = 'Honorarabrechnung RVG',
        root: string = 'H'
    ) {
        this.Abgerechnet = '';
        this.AdresseSuchbegriff = keyword;
        this.AdresseTyp = '1';
        this.Belegdatum = moment.utc().format();
        this.Betreff = rubrum;
        this.Bezahlt = '0,00';
        this.Buchungsdatum = moment.utc().format();
        this.Buchungstext1 = bookText;
        this.Buchungstext2 = '';
        this.Buchungstyp = bookType;
        this.DatUhr = moment.utc().format('YYYYMMDD') + moment.utc().format('hhmmss') + Utils.uuidv4().substring(0, 6);
        this.EinOderAusgang = eInAus;
        this.Fremdgeld = '0,00';
        this.Gebuehrenerhoehung = '';
        this.Gesamtumsatz = amount;
        this.Honorar = honorar;
        this.ImportOrExport = '';
        this.Jahrgang = moment.utc().startOf('year').format();
        this.Konto = '';
        this.KorrespondierendeDatUhr = '';
        this.Menupunkt = menupunkt;
        this.MindestGebuehr = '';
        this.Mwst = mwst;
        this.NettoauslagenNachP26 = '0,00';
        this.NettoauslagenNachP27 = '0,00';
        this.NettoauslagenNachP28 = '0,00';
        this.Nettogebuehr = netamount;
        this.Paragraph = '';
        this.Rechnungsnummer = rechNo;
        this.Referat = referat;
        this.Registernummer = caseId;
        this.Root = root;
        this.Sachbearbeiter = sachbearbeiterId;
        this.SteuerfreieAuslagen = fr;
        this.SteuerpflichtigeAuslagen = fl;
        this.Streitwert = streitwert;
        this.Umsatz = amount;
        this.Unterkonto = '';
        this.Zehntel = '';
    }
};