import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI, Routing } from "../../api";
import { MainViewModel } from "../../main";
import { TaxonDto } from './taxonomie';
import '../dataTable/dataTable';

export class TaxonomieOverviewViewModel {
    taxonomies: ko.ObservableArray<TaxonDto> = ko.observableArray([]);

    createTaxonomie() {
        MainViewModel.RoutingTable.showTaxonomieView();
    }

    async loadTaxonomies() {
        let taxonomies = (await RNSAPI.getTaxonomies()).Payload.Taxonomies.map(t => {
            t.actionHandlers = [{
                icon: "pencil",
                name: "Bearbeiten",
                action: () => MainViewModel.RoutingTable.showTaxonomieView({ id: t.Id })
            },
            {
                icon: "trash-o",
                name: "Löschen",
                action: async () => {
                    await RNSAPI.deleteTaxonomieById(t.Id);
                    this.loadTaxonomies();
                }
            }];

            t.AllFilters = [t.Filter1, t.Filter2, t.Filter3, t.Filter4, t.Filter5, t.Filter6, t.Filter7].filter(f => f).join(", ");

            return t;
        }).sort((a, b) => {
            if (a.AllFilters < b.AllFilters) {
                return -1;
            } else if (a.AllFilters > b.AllFilters) {
                return 1;
            } else {
                return 0;
            }
        });

        this.taxonomies(taxonomies);
    }

    constructor() {
        this.loadTaxonomies();
    }
}

let html = fs.readFileSync(__dirname + '/overview.html', 'utf8');

ko.components.register("taxonomieoverview-view", {
    viewModel: TaxonomieOverviewViewModel,
    template: html
});