import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment";
import { Utils } from "../../utils";
import { RNSAPI } from "../../api";
import { Postbox } from '../dashboard/postbox'
import "../dropdownsearch/dropdownsearch";
import { MainViewModel } from "../../main";
import './dialog';
import { DialogViewModel } from './dialog';
import './message';
import { DashboardViewModel } from "../dashboard/dashboard";
import { join } from "path";

export class resubmissionDialogViewModel {
    givenId = "NewDeadlineDialogExt";
    PregiID = "fristDeadlinePrelineDate";

    deadlineNr = ko.observable("");
    predeadlineNr = ko.observable("");
    deadlineCreationDate = ko.observable(moment().format("YYYY-MM-DD"));
    deadlinePrelineDate = ko.computed(() => {
        //console.log(ko.toJS(this.predeadlineNr));
        let predead = moment(ko.toJS(this.FristTemp), "YYYY-MM-DD").subtract(7, "day");
        if (ko.toJS(this.predeadlineNr) !== '') {
            predead = moment(ko.toJS(this.FristTemp), "YYYY-MM-DD").subtract(parseInt(ko.toJS(this.predeadlineNr)), "day");
        }
        return predead.format("YYYY-MM-DD");

    });
    akteFrist = this.newAkteFrist();
    infoMessage = ko.observable('');
    infoTitle = ko.observable('');
    modalHandleSelection = ko.observable();
    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    fKAId = ko.observable('');
    isAfterFrist = ko.observable(false);
    isBeforeStart = ko.observable(false);
    savedSuccessfull = ko.observable(false);
    showOutlookLoader = ko.observable(false);

    allCases = ko.observableArray([]);
    allClerks = ko.observableArray([]);
    allDeadlineReasons = ko.observableArray([]);



    FristTemp = ko.observable(moment().format("YYYY-MM-DD"));

    gerCase = ko.observable('');

    newAkteFrist() {
        let obj = {
            SachbearbeiterId: ko.observable(RNSAPI.User().username),
            Sachbearbeiter: ko.observable(null),
            Clerk: ko.observable(null),
            CaseId: ko.observable(null),
            Rubrum: ko.observable("Kein Rubrum vorhanden."),
            DeadlineNumber: ko.observable("-/-"),
            DeadlineText: ko.observable(null),
            CompletedBy: ko.observable(""),
            CompletionDate: ko.observable(""),
            CreatedBy: ko.observable(""),
            StartDate: ko.observable(moment().format("YYYY-MM-DD")),
            TheDeadline: ko.observable("YYYY-MM-DD"),
            PreDeadline: ko.observable('YYYY-MM-DD'),
            State: ko.observable(""),
            TypeId: ko.observable(""),
            TypeDescription: ko.observable(""),
            AddressKeyword: ko.observable(""),
            // Deadline: ko.observable("")
        };
        return ko.observable(obj);
    }

    newNotifyObj() {
        let obj = {
            CaseId: ko.observable(""),
            TypeId: ko.observable(""),
            TypeDescription: ko.observable(""),
            Clerk: ko.observable(""),
            SachbearbeiterId: ko.observable(RNSAPI.User().username),
            Sachbearbeiter: ko.observable(null),
            Rubrum: ko.observable("Kein Rubrum vorhanden."),
            DeadlineNumber: ko.observable("-/-"),
            DeadlineText: ko.observable(null),
            CompletedBy: ko.observable(""),
            CompletionDate: ko.observable(""),
            CreatedBy: ko.observable(""),
            StartDate: ko.observable(moment().format("YYYY-MM-DD")),
            TheDeadline: ko.observable("YYYY-MM-DD"),
            PreDeadline: ko.observable('YYYY-MM-DD'),
            State: ko.observable(""),
            AddressKeyword: ko.observable(""),
            Deadline: ko.observable("")
        }
        return ko.observable(obj)
    }

    async pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    }

    async postFrist() {
        this.postFristData();
    }

    formatDeadlineDuration(deadduration: string, preduration = "") {
        let duration_names = { s: { d: 'Tag', w: 'Woche', m: 'Monat', y: 'Jahr' }, p: { d: 'Tage', w: 'Wochen', m: 'Monate', y: 'Jahre' } }
        let oldDuration = ko.toJS(deadduration).slice(1);
        let durationtext = "";
        if (oldDuration.startsWith("0"))
            oldDuration = oldDuration.slice(1);
        if (/^\w\d+$/.test(deadduration)) {
            let duration = Number(deadduration.slice(1));
            let dtype = deadduration[0];
            durationtext = duration + ' ' + duration_names[duration == 1 ? 's' : 'p'][dtype];
        }
        if (ko.toJS(preduration) !== "") {
            try {
                let days = parseInt(preduration)
                if (days > 1)
                    preduration = preduration + " Tage";
                else
                    preduration = preduration + " Tag";

                durationtext = durationtext + " | Vorfrist: " + preduration;
            } catch { }
        }
        return durationtext;
    }

    newCalculationCall() {
        let obj = {
            StartDate: ko.observable(ko.toJS(this.deadlineCreationDate)),
            DeadlineNumber: ko.observable(ko.toJS(this.akteFrist().DeadlineNumber)),
            State: ko.observable(ko.toJS(this.akteFrist().State))
        };
        return ko.observable(obj);
    }

    async calulateFristWithParameters() {
        if (ko.toJS(this.deadlineCreationDate) !== '' && ko.toJS(this.akteFrist().DeadlineNumber) !== "-/-" && ko.toJS(this.akteFrist().State) !== '') {
            let res = (await RNSAPI.calcDeadline(ko.toJS(this.newCalculationCall())));
            if (res.Type === "CalculationResult") {
                let form = res.Payload.Deadline.Deadline.split('T')[0];
                let pred = res.Payload.Deadline.PreDeadline.split('T')[0];
                this.akteFrist().TheDeadline(form);
                this.akteFrist().PreDeadline(pred);
            }
        }
    }

    deadlineErrorMessage = ko.observable<string>('');

    async postFristData() {
        $(".form-group").each(function () {
            $(this).removeClass("has-error");
        });
        if (!Utils.checkErrors(["CaseId", "Sachbearbeiter", 'DeadlineText'], this.akteFrist(), "deadline", [Utils.checkString])) return;

        let deadlineObj = ko.toJS(this.akteFrist());
        deadlineObj.StartDate = moment(ko.toJS(this.deadlineCreationDate), "YYYY-MM-DD").format("YYYY-MM-DD");
        deadlineObj.TheDeadline = moment(deadlineObj.TheDeadline, "YYYY-MM-DD").format("YYYY-MM-DD");
        deadlineObj.PreDeadline = moment($("#" + this.PregiID).val(), "YYYY-MM-DD").format("YYYY-MM-DD");

        if (Number(moment(deadlineObj.PreDeadline).format("YYYYMMDD")) > Number(moment(deadlineObj.TheDeadline).format("YYYYMMDD"))) {
            $("#" + this.PregiID).addClass("has-error");
            this.isAfterFrist(true)
            return;
        }
        else {
            this.isAfterFrist(false);
            $("#" + this.PregiID).removeClass("has-error");
        }

        if (Number(moment(deadlineObj.PreDeadline).format("YYYYMMDD")) < Number(moment(deadlineObj.StartDate).format("YYYYMMDD"))) {
            $("#" + this.PregiID).addClass("has-error");
            this.isBeforeStart(true)
            return;
        }
        else {
            this.isBeforeStart(false);
            $("#" + this.PregiID).removeClass("has-error");
        }

        if(!ko.toJS(this.allCases()).find(x => x.caseid === this.akteFrist().CaseId())) {
            this.deadlineErrorMessage("Die eingegebene Registernummer existiert nicht!");
            return;
        }

        if(!ko.toJS(this.allClerks()).find(x => x.Sachbearbeiter_ID === this.akteFrist().Sachbearbeiter())) {
            this.deadlineErrorMessage("Der eingegebene Sachbearbeiter existiert nicht!");
            return;
        }

        if(!ko.toJS(this.allDeadlineReasons()).find(x => x.DeadlineDescription === this.akteFrist().DeadlineText())) {
            this.deadlineErrorMessage("Der eingegebene Fristgrund existiert nicht!");
            return;
        }

        

        try {
            (<HTMLButtonElement>document.getElementById("postFristBtn")).disabled = true;
            if (ko.toJS(this.fKAId) !== '') {
                (<HTMLButtonElement>document.getElementById("exportToFKA")).disabled = true;
                (<HTMLButtonElement>document.getElementById("notifyClerk")).disabled = true;
            }
            let result = await RNSAPI.createDeadline(ko.toJS(deadlineObj));
            if (result.Type === "CreationSuccessful") {
                this.savedSuccessfull(true);
                if (ko.toJS(this.fKAId) !== '') {
                    let Export = (<HTMLInputElement>document.getElementById('exportToFKA')).checked;
                    let Notify = (<HTMLInputElement>document.getElementById('notifyClerk')).checked;
                    if (Export) {
                        this.showOutlookLoader(true);
                        deadlineObj.Clerk = deadlineObj.SachbearbeiterId;
                        deadlineObj.TypeId = deadlineObj.DeadlineNumber;
                        deadlineObj.TypeDescription = deadlineObj.DeadlineText;
                        deadlineObj.AddressKeyword = "";
                        // deadlineObj.Deadline = deadlineObj.TheDeadline.toString();
                        let notifyObject = this.newNotifyObj();
                        notifyObject().Clerk(deadlineObj.SachbearbeiterId.toString());
                        notifyObject().CaseId(deadlineObj.CaseId.toString());
                        notifyObject().Deadline(deadlineObj.TheDeadline.toString());
                        notifyObject().TypeDescription(deadlineObj.DeadlineText.toString());
                        notifyObject().TypeId(deadlineObj.DeadlineNumber.toString());
                        notifyObject().SachbearbeiterId(deadlineObj.SachbearbeiterId.toString());
                        notifyObject().Sachbearbeiter(deadlineObj.SachbearbeiterId.toString());
                        notifyObject().Rubrum(deadlineObj.Rubrum.toString());
                        notifyObject().DeadlineNumber(deadlineObj.DeadlineNumber.toString());
                        notifyObject().DeadlineText(deadlineObj.DeadlineText.toString());
                        // notifyObject().CompletedBy(deadlineObj.CompletedBy.toString());
                        notifyObject().StartDate(deadlineObj.StartDate.toString());
                        notifyObject().TheDeadline(deadlineObj.TheDeadline.toString());
                        notifyObject().PreDeadline(deadlineObj.PreDeadline.toString());
                        notifyObject().State(deadlineObj.State.toString());
                        notifyObject().AddressKeyword(deadlineObj.AddressKeyword.toString());
                        


                        let res = await RNSAPI.notifyDeadline(ko.toJS(this.fKAId), ko.toJS(notifyObject), Notify);
                        if (res.Type === "ExportAppointmentSuccessful") {
                            (<HTMLButtonElement>document.getElementById("postFristBtn")).disabled = false;
                            (<HTMLButtonElement>document.getElementById("exportToFKA")).disabled = false;
                            (<HTMLButtonElement>document.getElementById("notifyClerk")).disabled = false;
                            this.showOutlookLoader(false);
                        }
                        else {
                            //Fehlermeldung einf�gen
                        }
                    }
                }

                this.akteFrist().CaseId("");
                //this.akteFrist().Sachbearbeiter("");
                this.akteFrist().Rubrum("");
                this.akteFrist().DeadlineText("");
                RNSAPI.createHistoryEntry(deadlineObj.CaseId, "Frist angelegt");
                let idname = "#" + this.givenId;
                $(idname).modal("hide");
                (<HTMLButtonElement>document.getElementById("postFristBtn")).disabled = false;
                //(<HTMLButtonElement>document.getElementById("exportToFKA")).disabled = false;
                //(<HTMLButtonElement>document.getElementById("notifyClerk")).disabled = false;
                this.savedSuccessfull(false);
                //$('#messageModal').modal('show');

                if (window.location.href.endsWith('tfw') || window.location.href.includes('new'))
                    Postbox.publisher().publish("", "updateTFW")

                this.infoTitle('Erledigt')
                this.infoMessage('Daten erfolgreich erfasst.')

                setTimeout(() => {
                    $('#messageModal').modal('hide');
                }, 3000);

                if (ko.toJS(this.givenId) === 'EasyCaseNewDeadlineDialogExt') {
                    $("html, body").animate({ scrollTop: 0 }, "fast");
                    window.location.reload();
                }

            } else {
                (<HTMLButtonElement>document.getElementById("postFristBtn")).disabled = false;
                //(<HTMLButtonElement>document.getElementById("exportToFKA")).disabled = false;
                //(<HTMLButtonElement>document.getElementById("notifyClerk")).disabled = false;
                this.infoTitle('')
                this.infoTitle('Fehler')
                this.infoMessage('');
                this.infoMessage('Fehler beim Anlegen der Frist.');
                $('#messageModal').modal('show');
            }
        } catch (e) {
            (<HTMLButtonElement>document.getElementById("postFristBtn")).disabled = false;
            (<HTMLButtonElement>document.getElementById("exportToFKA")).disabled = false;
            (<HTMLButtonElement>document.getElementById("notifyClerk")).disabled = false;
            console.log(e);
            this.infoTitle('')
            this.infoTitle('Fehler')
            this.infoMessage('');
            this.infoMessage('Fehler beim Anlegen der Frist.');
            $('#messageModal').modal('show');
        }
    }

    workDayOffset(startDate, endDate) {
        let startWeekDay = startDate.clone();
        if (startDate.day() != 0) {
            startWeekDay = startWeekDay.subtract(startDate.day(), 'day');
        }
        else {
            startWeekDay = startWeekDay.subtract(1, 'w');
        }
        let endWeekDay = endDate.clone();
        if (endDate.day() != 0) {
            endWeekDay = endWeekDay.add(7 - endDate.day(), 'day');
        }
        let diff = endWeekDay.diff(startWeekDay, 'days');
        let weekends = 2 * (Math.floor(diff / 7) - 1);
        return weekends;
    };

    async pickCase() {
        this.deadlineErrorMessage('');
        await this.loadCases();
        let cases = ko.toJS(this.allCases());
        this.pickGeneric(
            "Akte",
            ["caseid", "rubrum"],
            ["Akte", "Rubrum"],
            cases
        );
        this.modalHandleSelection((selectedObject) => {
            this.akteFrist().CaseId(selectedObject()["caseid"]);
            this.akteFrist().Rubrum(selectedObject()["rubrum"]);
            this.gerCase('');
            if (selectedObject()["Gericht1Id"] !== null && selectedObject()["Gericht1Id"] !== '') {
                this.gerCase(selectedObject()["Gericht1Id"]);
            }
            if (selectedObject()["Gericht2Id"] !== null && selectedObject()["Gericht2Id"] !== '') {
                this.gerCase(selectedObject()["Gericht2Id"]);
            }
            if (selectedObject()["Gericht3Id"] !== null && selectedObject()["Gericht3Id"] !== '') {
                this.gerCase(selectedObject()["Gericht3Id"]);
            }

            if (ko.toJS(this.gerCase) !== '') {
                this.setBL();
            }
        });
        $("#Deadmodal").modal("show");
    }

    async setBL() {
        var state = (await RNSAPI.GetStateByGerId(ko.toJS(this.gerCase))).Payload.state;
        this.akteFrist().State(state);
        //(<HTMLSelectElement>document.getElementById("Bundesland")).value = state;
    }

    pickSachbearbeiter = async () => {
        this.deadlineErrorMessage('');
        await this.loadClerks();
        let sachbearbeiter = ko.toJS(this.allClerks());

        this.pickGeneric(
            "Sachbearbeiter",
            ["Sachbearbeiter"],
            ["Sachbearbeiter"],
            sachbearbeiter
        );
        this.modalHandleSelection((selectedObject) => {
            this.akteFrist().SachbearbeiterId(selectedObject()["Sachbearbeiter_ID"]);
            this.akteFrist().Sachbearbeiter(selectedObject()["Sachbearbeiter_ID"]);
        });
        $("#Deadmodal").modal("show");
    };

    async pickDeadlineNumber() {
        this.deadlineErrorMessage('');
        await this.loadDeadlineReasons();
        let reasons = ko.toJS(this.allDeadlineReasons());

        for (let i = 0; i < reasons.length; i++) {
            reasons[i].DeadlineDuration = this.formatDeadlineDuration(reasons[i].DeadlineDuration, "");
        }
        this.pickGeneric(
            "Grund",
            ["DeadlineDescription", "DeadlineDuration"],
            ["Beschreibung", "Fristdauer"],
            reasons
        );
        this.modalHandleSelection((selectedObject) => {
            this.akteFrist().DeadlineNumber(selectedObject()["DeadlineNumber"]);
            this.deadlineNr(selectedObject()["DeadlineDuration"] + this.formatDeadlineDuration("", selectedObject()["PreDeadlineDuration"]))
            this.akteFrist().DeadlineText(selectedObject()["DeadlineDescription"]);
            if (ko.toJS(this.deadlineNr) === '')
                document.getElementById('deadlineDeadlineDate').removeAttribute('disabled');
            else
                document.getElementById('deadlineDeadlineDate').setAttribute('disabled', "");

        });
        $("#Deadmodal").modal("show");
    }

    async getDefaultSachbearbeiter() {
        let sach = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(
            (d) => d.Sachbearbeiter_ID === RNSAPI.User().username
        );
        this.akteFrist().Sachbearbeiter(sach[0].Sachbearbeiter_ID);
    }

    async getRubrumFromCase() {
        let res = (await RNSAPI.getCaseByCaseId(ko.toJS(this.akteFrist().CaseId))).Payload.Case;
        this.akteFrist().Rubrum(res.Rubrum)

        this.gerCase('');
        if (res.Gericht1Id !== null && res.Gericht1Id !== '') {
            this.gerCase(res.Gericht1Id);
        }
        if (res.Gericht2Id !== null && res.Gericht2Id !== '') {
            this.gerCase(res.Gericht2Id);
        }
        if (res.Gericht3Id !== null && res.Gericht3Id !== '') {
            this.gerCase(res.Gericht3Id);
        }

        if (ko.toJS(this.gerCase) !== '') {
            this.setBL();
        }
    }

    async getFKA() {
        let res = (await RNSAPI.getOutlook()).Payload.OutlookAccounts;
        if (res.length > 0) {
            //Eventuelle �nderung f�r die Auswahl der Adressen (oder hinterlegen der Adressen im Userprofil)
            this.fKAId(res[0].OutlookAccountID);
        }
    }

    private async loadCases() {
        let cases = [];
        await RNSAPI.getCasesOverview(0, ko.toJS(20)).then((data) => {
            cases = data as any;
        }).catch((error) => {
            cases = [];
        });

        this.allCases(cases); //(await RNSAPI.getCases()).Payload.Cases

        // this.allCases((await RNSAPI.getCases()).Payload.Cases);
    }


    private async loadClerks() {
        this.allClerks((
            await RNSAPI.getSachbearbeiter()
        ).Payload.Clerks.filter((s) => s.Sachbearbeiter_ID.trim() !== ""));
    }


    private async loadDeadlineReasons() {
        this.allDeadlineReasons((await RNSAPI.getDeadlineReasons()).Payload.Reasons);
    }

    constructor(params: any) {
        this.loadCases();
        this.loadClerks();
        this.loadDeadlineReasons();


        if (params.Case) {
            this.akteFrist().CaseId(params.Case);
            this.getRubrumFromCase();
            //document.getElementById("deadlineCaseId").setAttribute("disabled", "true");
        }
        if (params.prefix) {
            this.givenId = params.prefix + this.givenId;
            this.PregiID = params.prefix + this.PregiID;
        }

        Postbox.publisher().subscribe((CaseID) => {
            this.akteFrist().CaseId(CaseID);
            this.getRubrumFromCase();
        }, "SetCaseID");

        this.akteFrist().State.subscribe(() => this.calulateFristWithParameters())
        this.akteFrist().DeadlineText.subscribe(() => this.calulateFristWithParameters())
        this.deadlineCreationDate.subscribe(() => this.calulateFristWithParameters())

        this.getDefaultSachbearbeiter();

        this.getFKA();
    }
}

let html = fs.readFileSync(__dirname + "/deadlineDialog.html", "utf8");

ko.components.register("deadline-dialog-view", {
    viewModel: resubmissionDialogViewModel,
    template: html,
});
