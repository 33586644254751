import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment"
import { RNSAPI } from "../../api";
import { Utils } from "../../utils";
import { MainViewModel } from "../../main";

class TagViewModel {
    IsEditMode = ko.observable(false)

    basicTag = this.newBasicTagType();

    newBasicTagType() {
        let obj = {
            TagName: ko.observable(""),
            WFK_ID: ko.observable(""),
            Prio: ko.observable(""),
            OnlyWFK: ko.observable(false)
        };

        return ko.observable(obj);
    };


    resetObject(obj) {
        for (let key in obj) {
            obj[key](null);
        }
    }

    async postData() {
        $(".form-group").each(function () { $(this).removeClass("has-error"); });
        if (!Utils.checkErrors(["TagName"], this.basicTag(), "tag", [Utils.checkString])) return;

        if (this.IsEditMode()) {
            try {
                let result = await RNSAPI.updateTagType(this.basicTag());
                if (result.Type === "UpdateSuccessful") {
                    MainViewModel.RoutingTable.showTagOverview();
                } else {
                    alert("Fehler beim Speichern.");
                }
            } catch (e) {
                console.log(e);
                alert("Fehler beim Speichern: " + e.responseText);
            }
        } else {
            try {
                let result = await RNSAPI.createTagType(this.basicTag());
                if (result.Type === "AddSuccessful") {
                    MainViewModel.RoutingTable.showTagOverview();
                }
                else if (result.Type === "TagAlreadyExist") {
                    alert("Einen Tag mit dem Namen gibt es bereits.");
                } else {
                    alert("Fehler beim Anlegen des Tags.");
                }
            } catch (e) {
                console.log(e);
                alert("Fehler beim Anlegen: " + e.responseText);
            }
        }
    };

    async loadTag(name: string) {
        try {
            let result = await RNSAPI.getTagTypeByName(name);

            if (result.Type !== "TagNotFound") {
                //why is an array used here?
                //FATS key management is (very) broken and SearchRecord needs to be
                //replaced with SearchRecords and filtered
                let tag = result.Payload.Tags[0];
                let newTag = this.newBasicTagType();
                let obj = newTag();

                let excluding = [];

                for (let key in tag) {
                    if (obj[key] === undefined) {
                        obj[key] = tag[key];
                    } else if (excluding.indexOf(key) === -1) {
                        obj[key](tag[key]);
                    }
                }

                this.basicTag(obj);
            } else {
                alert("Tag nicht gefunden.");
            }
        } catch (e) {
            alert("Verbindung zum Server abgebrochen.");
        }
    }

    constructor(params: any) {
        params = params || {};
        let name = params.name;
        this.IsEditMode(name !== undefined);

        if (this.IsEditMode()) {
            this.loadTag(name);
        }
    }
}

let html = fs.readFileSync(__dirname + '/tag.html', 'utf8');

ko.components.register("tag-view", {
    viewModel: TagViewModel,
    template: html
});
