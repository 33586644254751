import * as fs from "fs";
import * as ko from "knockout";
import { enableRipple } from '@syncfusion/ej2-base';
enableRipple(true);
import { DocumentEditor, DocumentEditorContainer, Toolbar } from "@syncfusion/ej2-documenteditor";
import { TitleBar } from "./title-bar";
import { Postbox } from "../dashboard/postbox";
import { L10n } from '@syncfusion/ej2-base';
import * as de from './editorLanguage.json'; 
import { RNSAPI } from "../../api";
import { MainViewModel } from "../../main";
import { SaveDocument } from "../../types/SaveDocument";


class DocEditorViewModel {
    private hostUrl: string = RNSAPI.base;
    container: DocumentEditorContainer;

    documentId: string = '';
    caseId: string = '';

    private async loadDocumentFromId(docId: string): Promise<void> {
        await RNSAPI.loadDocumentEditorDocumentById(docId).then((response) => {
            this.container.refresh();
            // this.container.documentEditor.refresh();
            this.container.documentEditor.open(response.toString());
        }, (error) => {
            alert("Das Dokument konnte nicht geladen werden.");
        })
    }

    backToCase(): void {
        MainViewModel.RoutingTable.showNewView({ caseId: this.caseId });
    }

    constructor(params) {
        //L10n.load(de);
       

        if(params) {
            if(params.docId && params.caseId) {
                this.documentId = params.docId;
                this.caseId = params.caseId;
                this.loadDocumentFromId(this.documentId);
            } else if(params.caseId) {
                this.caseId = params.caseId;
            } else {
                this.caseId = "";
                this.documentId = "";
            }
        } else {
            this.caseId = "";
            this.documentId = "";
        }
        
        

        this.container = new DocumentEditorContainer({ enableToolbar: true, height: (window.innerHeight-200)+'px', locale: "de" });
        DocumentEditor.Inject(Toolbar);

        this.container.enableRtl = false;
        this.container.enableLockAndEdit = false;
        
        DocumentEditorContainer.Inject(Toolbar);
        this.container.serviceUrl = this.hostUrl + '/DocumentEditor/';
        this.container.appendTo('#container');
        
 
    
        let titleBar: TitleBar = new TitleBar(document.getElementById('documenteditor_titlebar'), this.container.documentEditor, true);
        this.container.documentEditor.documentName = 'Neues Dokument';
        titleBar.updateDocumentTitle();
    
        this.container.documentChange = (): void => {
            titleBar.updateDocumentTitle();
            this.container.documentEditor.focusIn();
        };
      

        this.container.documentEditor.keyDown = async (args) => {
            let keyCode = args.event.which || args.event.keyCode;
            let isCtrlKey = (args.event.ctrlKey || args.event.metaKey) ? true : ((keyCode === 17) ? true : false);
            let isAltKey = args.event.altKey ? args.event.altKey : ((keyCode === 18) ? true : false);
            if (isCtrlKey && !isAltKey && keyCode === 83) {
                alert("2");
                args.isHandled = true;
                args.event.preventDefault();

                if(this.caseId) {
                    const saveDoc: SaveDocument = {
                        CaseId: this.caseId, 
                        DocumentId: this.documentId,
                        Documentname: this.container.documentEditor.documentName,
                        Extension: "docx",
                        Content: this.container.documentEditor.serialize()   
                    };

                    await RNSAPI.saveDocument(saveDoc).then((response) => {
                        alert("Das Dokument wurde gespeichert.")
                    }).catch((error) => {
                        alert("Das Dokument konnte nicht gespeichert werden.")
                    });
                } else {
                    this.container.documentEditor.save(this.container.documentEditor.documentName, 'Docx');
                }


                
            }
        };

        Postbox.publisher().subscribe((response) => {
            const currentWidth = document.getElementById('container').clientWidth;
            if(response) {
                this.container.resize((currentWidth+225),window.innerHeight-200);
            } else {
                this.container.resize((currentWidth-225),window.innerHeight-200);
            }
        }, "CollapsedSidebar");
    }
}

let html = fs.readFileSync(__dirname + '/editor.html', 'utf8');

ko.components.register("document-editor-view", {
    viewModel: DocEditorViewModel,
    template: html
});
