import * as fs from "fs";
import * as ko from "knockout";

export class MessageViewModel {
    message = ko.observable('');
    title = ko.observable('')

    constructor(params: any) {
        if (params.message()) {
            this.message(params.message())
            $('#MessageModal').modal('show')
        }
        if (params.autoHide()) {
            setTimeout(() => {
                $('#MessageModal').modal('hide')
            }, 3000)
        }
        this.title(params.title() ? params.title(): 'Ein Fehler ist aufgetreten')
    }
}


let html = fs.readFileSync(__dirname + '/message.html', 'utf8');

ko.components.register("message-view", {
    viewModel: MessageViewModel,
    template: html
});