import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI, Routing } from "../../api";

class TextTreeViewModel {
    name: string;
    dirs = ko.observableArray([]);
    files = ko.observableArray([]);
    open: ko.Observable<boolean>;
    callback: (string) => void;

    private compare(a: any, b: any) {
        if (a.name < b.name)
            return -1;
        if (a.name > b.name)
            return 1;
        return 0;
	}
	
    constructor(params: any) {
        this.dirs(params.data.filter(node => node.type === "DIRECTORY").sort(this.compare));
        this.files(params.data.filter(node => node.type === "FILE").sort(this.compare));
        this.name = params.name;
        this.open = ko.observable(!!params.open);
        this.callback = params.callback;
        this.toggleOpen = function () { this.open(!this.open()) };
    }

    toggleOpen() {
        this.open(!this.open());
    }
}

let html = fs.readFileSync(__dirname + '/textTree.html', 'utf8');

ko.components.register("text-tree-view", {
    viewModel: TextTreeViewModel,
    template: html
});