import * as fs from "fs";
import * as ko from "knockout";
import "./textTree";
import { RNSAPI, Routing } from "../../api";
import { MainViewModel } from "../../main";
import { Utils } from "../../utils";
import "../dialog/dialog";

class Person {
    type: string
    keyword: string

    constructor(type: string, keyword: string) {
        this.type = type;
        this.keyword = keyword;
    }

    getText() {
        return `${this.type.charAt(0).toUpperCase() + this.type.slice(1)}: ${this.keyword}`;
    }
}

class GenerationViewModel {
    //replace with ajax request at some time
    workflowTable = {
        "": "<Kein Worfklow>",
        "Ak": "Autorenkorrektur",
        "B": "Zur Bearbeitung",
        "BDS": "Löschung wg. BDSG",
        "Di": "Diktat",
        "E": "Erledigt",
        "EGP": "an EGVP übergeben",
        "EIN": "Empfangene Nachrichten",
        "ERV": "Elektronischer Rechtsverkehr",
        "F": "Fertigstellung",
        "PA": "Postausgang",
        "PE": "Posteingang",
        "PR": "Prüfung",
        "SG": "Signatur geprüft",
        "SI": "Signiert",
        "St": "Standardtext",
        "U": "Zur Unterschrift",
        "V": "Versand",
        "VF": "Versandfertig machen",
        "VPA": "Archiv VP",
        "ZS": "Zur Signatur",
        "ZU": "zur Unterschrift",
        "b": "Bearbeitung Büro",
        "eCD": "Download eConsult",
        "eCU": "Upload eConsult",
        "eDD": "Download Drebis",
        "eDU": "Upload Drebis",
        "ra": "Bearbeitung Anwalt"
    }

    types = ko.observableArray(Object.keys(this.workflowTable).map(key => this.workflowTable[key]));
    selectedWorkflow = ko.observable(this.types()[0]);
    caseId = ko.observable("");
    rubrum = ko.observable("");
    name = ko.observable("empty-view");
    params = ko.observable({});
    persons = ko.observableArray();
    person: ko.Observable<Person> = ko.observable(null);
    dictationMark = ko.observable("");

    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalHandleSelection = ko.observable();

    async pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    };

    pickCase = async () => {
        let cases = (await RNSAPI.getCases()).Payload.Cases;
        this.pickGeneric("Akten", ['Registernummer', 'Rubrum'], ['Akte', 'Rubrum'], cases);
        this.modalHandleSelection((selectedObject) => this.caseId(selectedObject().Registernummer));
        $('#modal').modal('show');
    };

    async download(name: string, id: string) {
        let element = document.createElement('a');
        let doc = (await RNSAPI.getDocumentData(id)).Payload.Document;

        //server side bug in the ActiveX Component => OleType is doc instead of rtf
        let IsRTF = atob(doc.DocumentData.substr(0, 50)).indexOf("rtf") !== -1;
        let type = IsRTF ? "rtf" : doc.OLE2Type.trim().toLowerCase();

        let blob = Utils.base64ToBlob(doc.DocumentData, 'application/octet-stream');

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob);
        } else {
            element.setAttribute('href', 'data:application/octet-stream;charset=utf-16le;base64,' + doc.DocumentData);
            element.setAttribute('download', `${name.trim()}.${type}`);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }
    }

    async loadTemplates() {
        let data = (await RNSAPI.getTemplates()).Payload.Tree;
        let caseObject = (await RNSAPI.getCaseByCaseId(this.caseId())).Payload.Case;
        this.rubrum(caseObject.Rubrum);
        let textTreeParams = {
            open: true,
            data: data,
            name: "Vorlagen",
            callback: async (name) => {
                if (!this.person()) {
                    alert("Bitte eine Person auswählen!");
                    return;
                }

                let workflow = Object.keys(this.workflowTable).filter(w => this.workflowTable[w] == this.selectedWorkflow())[0];
                let result = await RNSAPI.generateCaseEntryText(this.caseId(), name, this.person().type, this.person().keyword, workflow, this.dictationMark());
                if (result.Type === "GenerationSuccessful") {
                    //TODO typo on the server fix later!! should of course be record id
                    let documentId = result.Payload.CaseId;
                    let caseEntries = (await RNSAPI.getCaseEntriesForId(this.caseId())).Payload.Records;
                    let recordId = caseEntries.filter(entry => {
                        return entry.DocDBIds.indexOf(documentId) !== -1;
                    })[0].Id;
                    RNSAPI.createHistoryEntry(this.caseId(), "Texterzeugung / "+ name); 

                    await this.download(name, recordId);
                    MainViewModel.RoutingTable.showCaseEntriesView({ caseId: this.caseId() });
                }
            }
        }

        let persons = (await RNSAPI.getPersonsForCaseId(this.caseId())).Payload.Addresses;
        let mandanten = persons[0].map(p => new Person("mandant", p.Keyword));
        let gegner = persons[1].map(p => new Person("gegner", p.Keyword));
        let sonstige = persons[7].map(p => new Person("sonstige", p.Keyword));

        this.persons(mandanten.concat(gegner).concat(sonstige));

        this.params(textTreeParams);
        this.name("text-tree-view");
    }

    postData = async () => {

    }

    constructor(params: any) {
        if (params && params.caseId) {
            this.caseId(params.caseId);
            this.loadTemplates();
        }

        this.caseId.subscribe((newCaseId) => {
            if (newCaseId) {
                this.loadTemplates();
            }
        });
    }
}

let html = fs.readFileSync(__dirname + '/generation.html', 'utf8');

ko.components.register("generation-view", {
    viewModel: GenerationViewModel,
    template: html
});
