import { RNSAPI } from '../../api';
import { UserData } from '../../UserData';
import { Utils } from '../../utils';
import { MainViewModel } from "../../main";
import * as fs from "fs";
import * as ko from "knockout";
import * as moment from 'moment';
import '../document/pdfjs';
import '../document/wopi';
import '../zpe/mail';
import './caseentry';
import '../workflow/stamps';
import '../workflow/header';
import '../view/select';
import '../tag/assign';
import { CaseOverviewViewModel } from './overview';
import { StampsViewModel, stampContainerWidth } from '../workflow/stamps';
import { start } from 'repl';

//Server Side filter
export class Filter {
    CaseId: string;
    StartDate: string;
    EndDate: string;
    ShorthandSymbol: string;
    Workflow: string;
    Dictation: string;
    View: string;
    Limit: number;

    constructor(caseId: string, startDate: string, endDate: string, shorthandSymbol: string, workflow: string, dictation: string, view: string, limit: number) {
        this.CaseId = caseId;
        this.StartDate = startDate;
        this.EndDate = endDate;
        this.ShorthandSymbol = shorthandSymbol;
        this.Workflow = workflow;
        this.Dictation = dictation;
        this.View = view;
        this.Limit = limit;
    }
}

export class Stamp {
    Id: string | null;
    Text: string;
    Type: string;

    constructor(Text: string, Type: string, Id?: string) {
        this.Text = Text;
        this.Type = Type;
        if (Id) this.Id = Id;
    }
}

class PredefinedStamp {
    AnnoTyp: string
    Bezeichnung: string;
    Text: string
    Workflow: string
    Farbe: string
}

enum DisplayMode {
    Overview = 1,
    Wfk = 2
}

class CaseEntryOverviewViewModel {
    private readonly limit = 100;
    currentFilter = ko.observable(new Filter(null, null, null, null, null, null, null, this.limit));

    filterCaseId = ko.observable("");
    filterStartDate = ko.observable("");
    filterEndDate = ko.observable("");
    filterShorthandSymbol = ko.observable("");
    filterWorkflow = ko.observable("");
    filterDictation = ko.observable("");
    filterInputs = [this.filterCaseId, this.filterStartDate, this.filterEndDate, this.filterShorthandSymbol, this.filterWorkflow, this.filterDictation];


    wopiToken = {
        "AccessToken": "",
        "AccessTokenTtl": 0
    }
    caseEntries: ko.ObservableArray<any>;
    currentId = ko.observable(null);
    currentMode = ko.observable(DisplayMode.Overview);
    //why is currentMargin necessary? This is necessary because the flexbox css model is not powerful enough
    //CSS Grid would be powerful enough, but is not supported in older browsers
    currentMargin = ko.computed(() => {
        switch (this.currentMode()) {
            case DisplayMode.Overview:
                return "191px";
            case DisplayMode.Wfk:
                return "135px";
            default:
                return "0";
        }
    });
    currentViewer = ko.observable("pdf");
    currentMimeType = ko.observable(null);
    wfk = ko.observable("");

    //expose to knockout
    trunc = Utils.trunc
    DisplayMode = DisplayMode

    stampContainerSize = ko.observable(stampContainerWidth);
    containerWidth = ko.computed(() => `calc(100% - ${this.stampContainerSize()}`);

    modalTitle = ko.observable("Workflow Mark");
    modalKeys = ko.observableArray(["WFK"]);
    modalColumns = ko.observableArray(["WFK"]);
    modalData = ko.observableArray([]);
    modalHandleSelection = ko.observable();

    async remove(id: string) {
        await RNSAPI.deleteRecord(id);
        await this.loadEntries(this.currentFilter());
    }

    async download(name: string, id: string) {
        let element = document.createElement('a');
        let doc= (await RNSAPI.getDocumentData(id)).Payload.Document;
        //server side bug in the ActiveX Component => OleType is doc instead of rtf
        let IsRTF = atob(doc.DocumentData.substr(0, 50)).indexOf("rtf") !== -1;
        let type = IsRTF ? "rtf" : doc.OLE2Type.trim().toLowerCase();

        let blob = Utils.base64ToBlob(doc.DocumentData, 'application/octet-stream');

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob);
        } else {
            element.setAttribute('href', 'data:application/octet-stream;charset=utf-16le;base64,' + doc.DocumentData);
            element.setAttribute('download', `${name.trim()}.${type}`);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }
    }

    async loadEntries(filter: Filter) {
        try {
            let entries = (await RNSAPI.getCaseEntriesForFilter(filter)).Payload.Records
                .filter((entry) => {
                    return entry.MimeType.indexOf("application/vnd.renostar.xmp") === -1;
                })
                .sort((a, b) => {
                    let dateA = new Date(a.LastChange || a.DocumentDate || a.RecordDate);
                    let dateB = new Date(b.LastChange || b.DocumentDate || b.RecordDate);
                    if (dateA < dateB) return 1;
                    else if (dateA > dateB) return -1;
                    else return 0
                })
                .map((entry) => {
                    const options = { day: '2-digit', month: '2-digit', year: 'numeric' } as const;
                    if (entry.Subject === "")
                        entry.Subject = entry.DocDBIds[0];
                    entry.DocumentDate = new Date(entry.LastChange || entry.DocumentDate || entry.RecordDate).toLocaleDateString('de-DE', options);
                    entry.columnActions = {
                        "CaseId": MainViewModel.RoutingTable.generateLink(`/case/${encodeURIComponent(entry.CaseId)}`)
                    };
                    entry.actionHandlers = [{
                        'icon': 'pencil-alt',
                        'name': 'Edit',
                        'action': () => {
                            MainViewModel.RoutingTable.showCaseEntryView({ caseId: entry.CaseId, eCaseRecordId: entry.Id });
                        }
                    }, {
                        'icon': 'tags',
                        'name': 'Tags',
                        'action': () => {
                            this.currentId(entry.Id);
                            this.currentId.valueHasMutated();
                            $("#assignTags").modal('show');
                        }
                    }, {
                        'icon': 'paperclip',
                        'name': 'Workflow',
                        'action': () => {
                            this.mark(entry.Id, entry.CaseId);
                        }
                    }, {
                        'icon': 'download',
                        'name': 'Download',
                        'action': () => {
                            if (entry.Id === '') {
                                entry.Id = entry.DocDBIds[0];
                            }
                            this.download(entry.Subject, entry.Id);
                        }
                    }, {
                        'icon': 'trash',
                        'name': 'Löschen',
                        'action': () => {
                            this.remove(entry.Id);
                        }
                    }];

                    if (Utils.matchAny(entry.MimeType.toLowerCase(), Utils.AcceptedMimeTypes)) {
                        entry.actionHandlers.unshift({
                            'icon': 'eye',
                            'selectable': true,
                            'name': 'Vorschau',
                            'action': () => {
                                //set an non existent viewer to trigger the dispose methods
                                //before the id is set, otherwise, the old subscriptions will be called again
                                this.currentViewer("");
                                this.currentMimeType(entry.MimeType.toLowerCase());
                                this.currentId(entry.Id);
                                if (entry.MimeType.toLowerCase().indexOf("pdf") !== -1) {
                                    this.currentViewer("pdf");
                                    this.showPDF(entry.Id);
                                } else if (entry.MimeType.toLowerCase().indexOf("rfc822") !== -1) {
                                    this.currentViewer("mail");
                                } else if (entry.MimeType.toLowerCase().indexOf("audio/ogg") !== -1) {
                                    this.currentViewer("play");
                                } else {
                                    this.currentViewer("wopi");
                                }
                                $('#DocumentViewerModal').modal('show');
                            }
                        });
                    }

                    if (entry.WorkflowId === "V") {
                        entry.actionHandlers.push({
                            'icon': 'envelope',
                            'name': 'Versenden',
                            'action': () => {
                                MainViewModel.RoutingTable.showComposeView({
                                    caseId: entry.CaseId,
                                    eCaseRecordId: entry.Id
                                });
                            }
                        });
                    }

                    //add a "mark dictation as done button" when necessary
                    if (entry.MimeType.toLowerCase() === "audio/ogg" && entry.WorkflowId !== 'E' && !entry.TypistId) {
                        entry.actionHandlers.push({
                            'icon': 'check',
                            'name': 'Erledigen',
                            'action': async () => {
                                await RNSAPI.markDictationAsDone(entry.Id);
                                this.loadEntries(this.currentFilter());
                            }
                        });
                    }

                    return entry;
                });

            this.caseEntries(entries);
        } catch (e) {
            //alert("Laden der Akteneinträge fehlgeschlagen.");
        }
    }

    deleteStamp = async (stampId: string) => {
        let result = await RNSAPI.deleteStampById(stampId);
        if (result.Type === "DeleteStampSuccessful") {
            this.updateStamps();
        } else {
            alert("Fehler beim Löschen des Stempels.");
        }
    }
    public async updateStamps() {
        this.loadStamps(this.currentId());
    }
    private async loadStamps(recordId: string) {
        if (recordId) {
            let stamps = (await RNSAPI.getStampsByECaseId(recordId)).Payload.Stamps;
            let stampsWithEditedDate = stamps.map(stamp => {
                stamp.CreationDate = (new Date(stamp.CreationDate)).toLocaleDateString();
                return stamp;
            });
            this.currentStamps(stampsWithEditedDate);
        }
    }

    private async loadPredefinedStamps() {
        let templates = (await RNSAPI.getStampTemplates()).Payload.Templates;
        this.predefinedStamps(templates);
    }

    createStampFromTemplate = async (stamp: PredefinedStamp) => {
        let result = await RNSAPI.addStamp(stamp.Text, "Regular", this.currentId());
        if (result.Type === "AddSuccessful") {
            this.updateStamps();
        } else {
            alert("Fehler beim Erstellen des Stempels.");
        }
    }

    predefinedStamps = ko.observableArray([]);
    currentStamps: ko.ObservableArray<Stamp> = ko.observableArray([])

    openaktenoverview = async () => {
        window.open("/#/cases")
    }

    async showPDF(recordId) {
        console.log("caseentryoverview")
        let { DocumentData } = (await RNSAPI.getDocumentData(recordId)).Payload.Document
        let blob = Utils.base64ToBlob(DocumentData, "application/pdf");
        var file = window.URL.createObjectURL(blob);
        $('#pdf-view').attr('src', file)
    }

    mark(entryId: string, caseId: string): void {
        if (this.modalData().length == 1) {
            this.setMark(entryId, this.modalData()[0].WFK);
        } else {
            this.modalHandleSelection((selectedObject) => {
                this.setMark(entryId, selectedObject().WFK);
            });
            $('#modal').modal('show');
        }
    }

    async setMark(entryId: string, mark: string) {
        await RNSAPI.setCaseEntryWorkflowMark(entryId, mark);
        this.loadEntries(this.currentFilter());
    }

    public getVM = () => this;

    private readonly wfkOutMap = {
        "PE": ["B", "RA"],
        "ZU": ["B", "V"],
        "PA": ["E"],
        "RA": ["B", "V"],
        "B": ["ZU", "RA"],
        "E": [],
        "V": ["PA"]
    }

    constructor(params: any) {
        this.caseEntries = ko.observableArray([]);
        let n = (s: string) => s ? s : null;
        this.currentFilter.subscribe(newFilter => this.loadEntries(newFilter));
        for (let filterInput of this.filterInputs) {
            filterInput.subscribe(newValue => {
                let startDateInput = n(this.filterStartDate());
                let startDate = null;
                let endDateInput = n(this.filterEndDate());
                let endDate = null;

                if (startDateInput) {
                    let startDateMoment = moment(startDateInput, "DD.MM.YYYY", true);
                    if (startDateMoment.isValid()) {
                        startDate = startDateMoment.format("YYYY-MM-DD");
                    } else {
                        alert("Startdatum ist ungültig.");
                    }
                }

                if (endDateInput) {
                    let endDateMoment = moment(endDateInput, "DD.MM.YYYY", true);
                    if (endDateMoment.isValid()) {
                        endDate = endDateMoment.format("YYYY-MM-DD");
                    } else {
                        alert("Enddatum ist ungültig.");
                    }
                }
                let postaus = localStorage.getItem('id');
                let user = "";
                if (postaus == "4" || postaus == "6" || postaus == "7") {
                    user = RNSAPI.User().username;
                } else {
                    user = n(this.filterShorthandSymbol());
                }
                this.currentFilter(new Filter(n(this.filterCaseId()), startDate, endDate, user, n(this.filterWorkflow()), n(this.filterDictation()), this.currentFilter().View, this.limit));
            });
        }

        if (params && params.workflow) {
            this.currentMode(DisplayMode.Wfk);
            this.filterWorkflow(params.workflow);

            this.modalData(this.wfkOutMap[params.workflow].map(wfk => { return { WFK: wfk }; }));
        } else {
            this.modalData(Object.keys(this.wfkOutMap).map(wfk => { return { WFK: wfk }; }));
        }

        this.loadEntries(this.currentFilter());

        this.loadPredefinedStamps();
    }
}

let html = fs.readFileSync(__dirname + '/caseentryoverview.html', 'utf8');

ko.components.register("caseentryoverview-view", {
    viewModel: CaseEntryOverviewViewModel,
    template: html
});
