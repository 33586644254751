import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI, Routing } from "../../api";
import { MainViewModel } from "../../main";
import '../dataTable/dataTable';

export class TaxonDto {
    Filter1: ko.Observable<string>;
    Filter2: ko.Observable<string>;
    Filter3: ko.Observable<string>;
    Filter4: ko.Observable<string>;
    Filter5: ko.Observable<string>;
    Filter6: ko.Observable<string>;
    Filter7: ko.Observable<string>;
    Id: ko.Observable<string>;
}

export class TaxonomieOverviewViewModel {
    newBasicTaxonomie = () => {
        return {
            Filter1: ko.observable(""),
            Filter2: ko.observable(""),
            Filter3: ko.observable(""),
            Filter4: ko.observable(""),
            Filter5: ko.observable(""),
            Filter6: ko.observable(""),
            Filter7: ko.observable(""),
            Id: ko.observable(null)
        }
    }

    IsEditMode = ko.observable(false);

    taxonomie: ko.Observable<TaxonDto> = ko.observable(this.newBasicTaxonomie());

    async loadTaxonomie(id: string) {
        let taxonomie = (await RNSAPI.getTaxonomieById(id)).Payload;
        let newTaxonomie = this.newBasicTaxonomie();

        for (let key in newTaxonomie) {
            newTaxonomie[key](taxonomie[key]);
        }

        this.taxonomie(newTaxonomie);
    }

    async postData() {
        if (!this.IsEditMode()) {
            let result = await RNSAPI.addTaxonomie(this.taxonomie());

            if (result.Type === "AddTaxonSuccessful") {
                MainViewModel.RoutingTable.showTaxonomieOverview();
            } else {
                alert("Fehler beim Hinzufügen des Taxons.")
            }
        } else {
            let result = await RNSAPI.updateTaxonomie(this.taxonomie());

            if (result.Type === "UpdateTaxonSuccessful") {
                MainViewModel.RoutingTable.showTaxonomieOverview();
            } else {
                alert("Fehler beim Updaten des Taxons.")
            }

        }
    }

    constructor(params) {
        if (params && params.id) {
            this.IsEditMode(true);
            this.loadTaxonomie(params.id);
        }
    }
}

let html = fs.readFileSync(__dirname + '/taxonomie.html', 'utf8');

ko.components.register("taxonomie-view", {
    viewModel: TaxonomieOverviewViewModel,
    template: html
});