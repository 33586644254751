import * as fs from "fs";
import * as ko from "knockout";
import "../dialog/ConfirmFTWDialog";
import { RNSAPI } from "../../api";
import moment = require("moment");
import { ELEMENTS } from "@syncfusion/ej2/inplace-editor";
import { Utils } from "../../utils";
declare var $: any;

export class ForeclosureOverviewViewModel {
    caseId = ko.observable<string>('');
    defendants = ko.observableArray([]);
    overviewCalculationDate = ko.observable(moment().format("YYYY-MM-DD"));
    receivableAccountEntries = ko.observableArray([]);
    receivableAccountPrincipals = ko.observableArray([]);
    catalogNumbers = ko.observableArray([]);
    typesOfIntererests = ko.observableArray([]);
    withBasiszinssatz = ko.observable(true);
    withBasiszinssatzValue = ko.observable("Ja");
    suggestetdAmount = ko.observable(0);
    newReceivablesAccountObject = ko.observable(
        {
            Id: ko.observable<number>(),
            Uid: ko.observable("YYYY-MM-DD"),
            CaseId: ko.observable(""),
            ReceiptDate: ko.observable("YYYY-MM-DD"),
            TransactionNumber: ko.observable(""),
            TransactionNumberId: ko.observable(0),
            BookingText: ko.observable(""),
            Amount: ko.observable(0),
            InterestRate: ko.observable(0),
            TargetDate: ko.observable("YYYY-MM-DD"),
            InterestDate: ko.observable("YYYY-MM-DD"),
            State: ko.observable(""),
            StateEnumId: ko.observable(0),
            DebtSettlementPlan: ko.observable(false),
            Claimant_CaseID: ko.observable(""),
            Charged: ko.observable(false),
            Defendant: ko.observable(""),
            BookingOrder: ko.observable(""),
            PositionPrincipal: ko.observable(0),
            Action: ko.observable(""),
            CatalogNumber: ko.observable(1),
            InvoiceNumber: ko.observable(""),
            BookingText1: ko.observable(""),
            BookingText2: ko.observable(""),
            SubAccount: ko.observable(""),
            InvoiceDate: ko.observable("YYYY-MM-DD"),
            MinInterestRate: ko.observable(0),
            MaxInterestRate: ko.observable(0),
            BookingNumber: ko.observable(0),
            Periodic: ko.observable(false),
            BasicInterestRate: ko.observable(true),
            InterestType: ko.observable(""),
            CountryId: ko.observable(""),
            Postcode: ko.observable(""),
            City: ko.observable(""),
            Contract: ko.observable(""),
            Street: ko.observable(""),
            Matter: ko.observable(0),
            FeeRate: ko.observable(0),
            FeeIncrease: ko.observable(0),
            CostType: ko.observable("")
        }
    );
    positionToDeleteId = ko.observable(0);
    isPrincipal = ko.observable(false);
    processNumbers = ko.observableArray(['Kommentar', 'Hauptforderung', 'Zinsen auf einer spezifischen HF', 'Ausgerechnete Zinsen (nur Betrag)', 'Reine Zinsen(ohne HF-Bezug)', 'Vorgerichtliche Kosten Anwalt', 'Vorgerichtliche Kosten Behörde', 'Vorgerichtliche Kosten Gläubiger', 'Verzinsliche Kosten Anwalt', 'Verzinsliche Kosten Gericht', 'Zinsen auf verzinsliche Kosten', 'Vollstreckungsverfahren: Unverzinsliche Kosten Anwalt', 'Vollstreckungsverfahren: Unverzinsliche Kosten Gericht', 'Zahlung vom Gegner (Standard)', 'Zahlung mit Spezifizierung der Buchungsreihenfolge', 'Zahlung auf eine bestimmte HF', 'Zahlung auf HF direkt an den Mandanten']);
    onCosts = ko.observable("0,00 €");
    onDemand = ko.observable("0,00 €");
    resetAccountObject(){
        this.receivablesAccountObject.Id(undefined);
        this.receivablesAccountObject.Uid("YYYY-MM-DD");
        this.receivablesAccountObject.CaseId(ko.toJS(this.caseId));
        this.receivablesAccountObject.ReceiptDate("YYYY-MM-DD");
        this.receivablesAccountObject.TransactionNumber("");
        this.receivablesAccountObject.TransactionNumberId(0);
        this.receivablesAccountObject.BookingText("");
        this.receivablesAccountObject.Amount(0);
        this.receivablesAccountObject.InterestRate(0);
        this.receivablesAccountObject.TargetDate("YYYY-MM-DD");
        this.receivablesAccountObject.InterestDate("YYYY-MM-DD");
        this.receivablesAccountObject.State(""),
        this.receivablesAccountObject.StateEnumId(0);
        this.receivablesAccountObject.DebtSettlementPlan(false);
        this.receivablesAccountObject.Claimant_CaseID("");
        this.receivablesAccountObject.Charged(false);
        this.receivablesAccountObject.Defendant("");
        this.receivablesAccountObject.BookingOrder("");
        this.receivablesAccountObject.PositionPrincipal(0);
        this.receivablesAccountObject.Action("");
        this.receivablesAccountObject.CatalogNumber(1);
        this.receivablesAccountObject.InvoiceNumber("");
        this.receivablesAccountObject.BookingText1("");
        this.receivablesAccountObject.BookingText2("");
        this.receivablesAccountObject.SubAccount("");
        this.receivablesAccountObject.InvoiceDate("YYYY-MM-DD");
        this.receivablesAccountObject.MinInterestRate(0);
        this.receivablesAccountObject.MaxInterestRate(0);
        this.receivablesAccountObject.BookingNumber(0);
        this.receivablesAccountObject.Periodic(false);
        this.receivablesAccountObject.BasicInterestRate(true);
        this.receivablesAccountObject.InterestType("");
        this.receivablesAccountObject.CountryId("");
        this.receivablesAccountObject.Postcode("");
        this.receivablesAccountObject.City("");
        this.receivablesAccountObject.Contract("");
        this.receivablesAccountObject.Street("");
        this.receivablesAccountObject.Matter(0);
        this.receivablesAccountObject.FeeRate(0);
        this.receivablesAccountObject.FeeIncrease(0);
        this.receivablesAccountObject.CostType("");
    }

    newReceivablesAccount = ko.observable(
        {
            ID : ko.observable<number>(),
            LawfirmID: ko.observable(""),
            regnr: ko.observable(""),
            Schuldner: ko.observable(""),
            datum: ko.observable("YYYY-MM-DD"),
            hf: ko.observable(0),
            hf_rest: ko.observable(0),
            hf_zinsen: ko.observable(0),
            hf_zinsen_rest: ko.observable(0),
            vga: ko.observable(0),
            vga_rest: ko.observable(0),
            vgm: ko.observable(0),
            vgm_rest: ko.observable(0),
            vgb: ko.observable(0),
            vgb_rest: ko.observable(0),
            vka: ko.observable(0),
            vka_rest: ko.observable(0),
            vkg: ko.observable(0),
            vkg_rest: ko.observable(0),
            vkz: ko.observable(0),
            vkz_rest: ko.observable(0),
            uka: ko.observable(0),
            uka_rest: ko.observable(0),
            ukg: ko.observable(0),
            ukg_rest: ko.observable(0),
            UID: ko.observable(""),
            Typ: ko.observable(0),
            zahlung: ko.observable(0),
            zins_hf: ko.observable(0),
            zins_kosten: ko.observable(0),
            tz_hf: ko.observable(0),
            tz_kosten: ko.observable(0),
            restforderung: ko.observable(0),
            unterkonto: ko.observable(0),
            fokodruck: ko.observable(false),
        }
    );
    
    editPositionModal = ko.observable(false);

    CatalogNumberName = ko.observable("");
    receivablesAccountObject = this.newReceivablesAccountObject();
    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalHandleSelection = ko.observable();
    hasRates = ko.observable(true);
    showAmount = ko.observable(true);
    showCatalog = ko.observable(true);
    showBookingNr = ko.observable(true);
    showInvoiceFields = ko.observable(true);
    showBookingOrder = ko.observable(false);
    showDefendant = ko.observable(true);
    showHFSelection = ko.observable(false);
    disableBookingOrder = ko.observable(true);
    showRatesEnd = ko.observable(false);
    showCostKind = ko.observable(false);

    async pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    };

    editPosition = (positionObject, event) => {
        RNSAPI.getReceivablePositionById(positionObject.Id).then((res) => {
            let result = res as any;
            result.InterestDate = moment(result.InterestDate).format("YYYY-MM-DD");
            result.InvoiceDate = moment(result.InvoiceDate).format("YYYY-MM-DD");
            if(result.InvoiceDate === "0001-01-01")
                result.InvoiceDate = "YYYY-MM-DD";
            result.ReceiptDate = moment(result.ReceiptDate).format("YYYY-MM-DD");

            for (var attribut in this.receivablesAccountObject) {
                if(result[attribut] !== undefined)
                    this.receivablesAccountObject[attribut](result[attribut]);
            }
            this.receivablesAccountObject.CaseId(ko.toJS(this.caseId));
            console.log(ko.toJS(this.receivablesAccountObject));
            this.withBasiszinssatzValue(this.receivablesAccountObject.BasicInterestRate() ? "Ja": "Nein");
            this.editPositionModal(true);
            this.setProcessNumber(result.TransactionNumberId);
            
            $("#BookingModal").modal('show'); 

        }).catch((error) => {
            console.log(error)
            //Eintrag konnte nicht gefunden werden
        });
    }

    async download(name: string, documentData) {
        let element = document.createElement('a');

        let blob = Utils.base64ToBlob(documentData, 'application/octet-stream');

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob);
        } else {
            element.setAttribute('href', 'data:application/octet-stream;charset=utf-16le;base64,' + documentData);
            element.setAttribute('download', `${name.trim()}.pdf`);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }
    }

    async newBooking(): Promise<void> {
        this.editPositionModal(false);
        this.resetAccountObject();
        this.selectProcessNumber();
        this.receivablesAccountObject.Amount(ko.toJS(this.suggestetdAmount));
        this.receivablesAccountObject.CaseId(ko.toJS(this.caseId));
        this.receivablesAccountObject.ReceiptDate(moment().format("YYYY-MM-DD"));
        this.receivablesAccountObject.InterestDate(moment().format("YYYY-MM-DD"));
        this.receivablesAccountObject.BookingNumber(ko.toJS(this.receivableAccountEntries().length + 1))
        let princi = 0;
        for(let ent of ko.toJS(this.receivableAccountEntries()))
        {
            if(ent.PositionPrincipal > princi)
                princi = ent.PositionPrincipal;
        }
        this.receivablesAccountObject.PositionPrincipal(princi + 1);
        //this.receivablesAccountObject.InvoiceDate(moment().format("YYYY-MM-DD"));
        //this.receivablesAccountObject.InvoiceNumber(ko.toJS(await this.getInvoiceNumber()));
        $("#BookingModal").modal('show'); 
    }

    closeBookingModal(): void {
        $("#BookingModal").modal('hide');
    }

    async addBooking() {
        this.receivablesAccountObject.Amount(this.formatToDouble(ko.toJS(this.receivablesAccountObject.Amount).toString())); 
        this.receivablesAccountObject.InterestRate(ko.toJS(this.receivablesAccountObject.InterestRate).toString()); 
        this.receivablesAccountObject.MinInterestRate(ko.toJS(this.receivablesAccountObject.MinInterestRate).toString()); 
        this.receivablesAccountObject.MaxInterestRate(ko.toJS(this.receivablesAccountObject.MaxInterestRate).toString()); 

        var el = (<HTMLSelectElement> document.getElementById("selectedProcessNumber")).value;
        // if(el !== "0")
        //     this.receivablesAccountObject.PositionPrincipal(0);

        await RNSAPI.addReceivableAccountData(ko.toJS(this.receivablesAccountObject)).then((res) => {
            this.resetAccountObject();
            this.selectProcessNumber();
            this.getReceivableAccount();
            this.getReceivableAccountEntries();
            $("#BookingModal").modal('hide');

        }).catch((error) => {
            console.log(error);
        });
    }

    async editBooking(){
        await RNSAPI.editReceivableAccountData(ko.toJS(this.receivablesAccountObject)).then((res) => {
            this.resetAccountObject();
            this.selectProcessNumber();
            this.getReceivableAccount();
            this.getReceivableAccountEntries();
            $("#BookingModal").modal('hide');

        }).catch((error) => {
            
        });
    }

    deletePosition = (positionObject, event) =>{
        this.positionToDeleteId(positionObject.Id);
        $("#DeletePositionModal").modal('show');     
    }

    confirmDeletePosition() {        
        RNSAPI.deleteReceivablePositionById(ko.toJS(this.positionToDeleteId)).then((res) => {
            this.getReceivableAccountEntries();
            $("#DeletePositionModal").modal('hide');
            this.positionToDeleteId(0);
        }).catch((error) => {
            console.log(error);
        });
    }

    selectBasiszinssatz() {
        if (ko.toJS(this.withBasiszinssatzValue) === "Nein")
            this.receivablesAccountObject.BasicInterestRate(true);
        else
            this.receivablesAccountObject.BasicInterestRate(false);
    }

    setProcessNumber(id){
        (<HTMLSelectElement> document.getElementById("selectedProcessNumber")).value = id;
        this.selectProcessNumber();
    }

    selectProcessNumber() {
        var el = (<HTMLSelectElement> document.getElementById("selectedProcessNumber")).value;
        if(el === "1") { //Hauptforderung
            this.isPrincipal(true);
        }
        else {
            this.isPrincipal(false);
        }

        //Anzeige Zinsen
        if(el === "0" || el === "11" || el === "12" || el === "13" || el === "14" || el === "15" || el === "16")
            this.hasRates(false);
        else
            this.hasRates(true);

        if(el === "3")
            this.showRatesEnd(true);
        else
            this.showRatesEnd(false);

        //Anzeige Betrag
        if(el === "2" || el === "10")
            this.showAmount(false);
        else 
            this.showAmount(true);

        //Anzeige Katalognummer
        if(el === "2" || el === "3"|| el === "4"|| el === "5"||el === "6"||el === "7"|| el === "8"|| el === "9" || el === "10"|| el === "11" || el === "12" || el === "13" || el === "14" || el === "15" || el === "16")
            this.showCatalog(false);
        else 
            this.showCatalog(true);

        //Anziege Buchungsreihenfolge
        // if(el === "2"|| el === "3"  || el === "10")
        //     this.showBookingOrder(false);
        // else 
        //     this.showBookingOrder(true);
            
        //Anzeige Rechnungsfelder
        if(el === "2" || el === "3" || el === "4" || el === "8"|| el === "9"|| el === "10")
            this.showInvoiceFields(false);
        else 
            this.showInvoiceFields(true);

        //Anzeige Hauptforderungsauswahl
        if(el ==="2" || el === "15" || el === "16")
            this.showHFSelection(true);
        else
            this.showHFSelection(false);

        //Anziege Schuldner
        if(el === "3")
            this.showDefendant(false);
        else 
            this.showDefendant(true);

        //Anziege Buchungsnummer
        if(el === "3")
            this.showBookingNr(false);
        else 
            this.showBookingNr(true);

        if(el === "5" || el === "6" || el === "7" || el === "11" || el === "12")
            this.showCostKind(true);
        else
            this.showCostKind(false);

        if(el === "13" || el === "14")
        {
            this.receivablesAccountObject.BookingOrder("8975623410");
            this.showBookingOrder(true);
        }
        else{
            this.receivablesAccountObject.BookingOrder("");
            this.showBookingOrder(false);
        }

        if(el === "13")
            this.disableBookingOrder(true);
        else
            this.disableBookingOrder(false);
        
        this.receivablesAccountObject.TransactionNumberId(el);
        this.receivablesAccountObject.TransactionNumber(ko.toJS(this.processNumbers()[el]));
    }

    changeOverviewParameters(){
        this.getReceivableAccount();
    }

    isPrincipalBubble= (parent: any, data: any) => {
        if(data.Periodic && data.TransactionNumberId === 0)
            return 'Periodisch';        
    }

    async getReceivableAccountEntries(){
        await RNSAPI.getReceivableAccountData(ko.toJS(this.caseId)).then((response) => {
            if (response === null) {
                this.receivableAccountEntries([]);
                return;
            }
            let entries = response as any;
            entries = entries.map((a) => {
                a.DocumentDateFormated = this.convertDate(a.ReceiptDate);
                a.PeriodicState = this.convertBoolean(a.Periodic);
                a.AmountFormated = this.formatCurrencyInput(a.Amount);
                a.InterestRateFormated = this.formatPercent(a.InterestRate);
                a.BasicInterestRateFormated = this.convertBoolean(a.BasicInterestRate);
                a.MinInterestRateFormated = this.formatPercent(a.MinInterestRate);
                a.MaxInterestRateFormated = this.formatPercent(a.MaxInterestRate);
                a.InterestDateFormated = this.convertDate(a.InterestDate);
                a.InvoiceDateFormated = this.convertDate(a.InvoiceDate);
                return a;
            });

            this.receivableAccountPrincipals(entries.filter(a => a.TransactionNumber === "Hauptforderung"));

            this.receivableAccountEntries(entries);

        }).catch((ex) => {
            this.receivableAccountEntries([]);
        });
    }

    async getReceivableAccount() {
        var defendant = (<HTMLSelectElement>document.getElementById("defendantSelect_sel")).value;

        await RNSAPI.getReceivableAccountForCase(ko.toJS(this.caseId), defendant, moment(ko.toJS(this.overviewCalculationDate)).add("days", 1).toDate()).then((response) => {
            if (response === null) {
                return;
            }

            for(let res in response)
            {
                let a = response[res];
                if(res !== "datum" && res !== "Schuldner" && res !== "LawfirmID" && res !== "ID" && res !== "fokodruck" && res !== "unterkonto" && res !== "regnr")
                    a = this.formatCurrencyInput(a);               
                
                this.newReceivablesAccount()[res](a);
            }

            this.onCosts(this.formatCurrencyInput((response["vka"]-response["vka_rest"])+(response["vgb"]-response["vgb_rest"])+(response["vkg"]-response["vkg_rest"])+(response["vga"]-response["vga_rest"])+(response["vgm"]-response["vgm_rest"])))
            this.onDemand(this.formatCurrencyInput(response["hf"]-response["hf_rest"]));

        }).catch((ex) => {
            console.log(ex)
        });
    }

    async printPositions() {
        var defendant = (<HTMLSelectElement>document.getElementById("defendantSelect_sel")).value;

        await RNSAPI.printReceivableAccountForCase(ko.toJS(this.caseId), defendant, moment(ko.toJS(this.overviewCalculationDate)).add("days", 1).toDate()).then((response) => {
            if (response === null) {
                return;
            }
            let blob = Utils.base64ToBlob(response.toString(), "application/pdf");
            var file = window.URL.createObjectURL(blob);
            $('#pdf-view-foko').attr('src', file);
            $('#FoKoViewerModal').modal('show');
            //this.download("Forderungskonto", response);
            
        }).catch((ex) => {
            console.log(ex)
        });
    }

    async getCatalogNumbers(){
        await RNSAPI.getCatalogNumbers().then((response) => {
            if (response === null) {
                this.catalogNumbers([]);
                return;
            }
            let entries = response as any;
            this.catalogNumbers(entries);
            this.CatalogNumberName(entries[0].Designation);

        }).catch((ex) => {
            this.catalogNumbers([]);
        });
    }

    async getTypesOfInterest(){
        await RNSAPI.getTypesOfInterest().then((response) => {
            if (response === null) {
                this.catalogNumbers([]);
                return;
            }
            let entries = response as any;
            this.typesOfIntererests(entries);

        }).catch((ex) => {
            this.typesOfIntererests([]);
        });
    }

    pickCatalogNumber()
    {
        this.pickGeneric("Katalog", ["Designation", "CatalogNumber"], ["Katalog", "Katalognummer"], ko.toJS(this.catalogNumbers));
        this.modalHandleSelection((selectedObject) => {
        this.receivablesAccountObject.CatalogNumber(selectedObject()["CatalogNumber"]);
        this.CatalogNumberName(selectedObject()["Designation"]);
    
    });
        $('#receivableAccountmodal').modal('show');
    }

    private convertDate(date) {
        if(date !== "0001-01-01T00:00:00")
        {
            let formatedDate = moment(date).format('DD.MM.YYYY');
            return formatedDate;
        }
        return "";
    }

    private convertBoolean(input) {
        let statement = input ? "Ja" : "Nein";
        return statement;
    }

    private formatCurrencyInput(input: number): string {
        if (isNaN(input)) return "0,00 €";
        const options = { style: 'currency', currency: 'EUR' };
        const numberFormat = new Intl.NumberFormat('de-DE', options);
        return numberFormat.format(input);
    }

    private formatPercent(input: number): string {
        let percent = "";
        let inp = input.toString();
        if (inp.includes('.')) {
            let inpu = inp.split(".");
            if (inpu[1].length < 2) {
                inpu[1] = inpu[1] + "0";
            }
            percent = inpu[0] + "," + inpu[1] + " %";
        }
        else {
            percent = inp + ",00 %";
        }
        return percent;
    }

    private formatToDouble(input: string): string{
        if(input.includes("."))
            input = input.replace(".", "");
        if(input.includes(","))
            input = input.replace(",", ".");

        return input;
    }

    constructor(params: any) {
        if (params) {
            if (params.caseId) {
                this.caseId(params.caseId);
                this.getCatalogNumbers();
                this.getTypesOfInterest();
                this.getReceivableAccount();
                this.getReceivableAccountEntries();
            }

            if(params.defendants())
            {
                this.defendants(params.defendants());
            }

            if(params.CourtCaseValue1())
            {
                if(params.CourtCaseValue1() !== "0,00")
                    this.suggestetdAmount(params.CourtCaseValue1());
            }

            this.overviewCalculationDate.subscribe(() => this.getReceivableAccount());
            //if(params.vsab) this.vorsteuer(params.vsab);
        }
    }
}

let html = fs.readFileSync(__dirname + '/forcase.html', 'utf8');

ko.components.register("receivables-view", {
    viewModel: ForeclosureOverviewViewModel,
    template: html
});