import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment";
import { Utils } from "../../utils";
import { RNSAPI } from "../../api";
import { Postbox } from '../dashboard/postbox'
import "../dropdownsearch/dropdownsearch";
import { MainViewModel } from "../../main";
import './dialog';
import { DialogViewModel } from './dialog';
import './message';
// import '../dashboard/dashboard';
import { DashboardViewModel } from "../dashboard/dashboard";
import { join } from "path";
import { RolesAndRights } from "../../helpers/RolesAndRights";
declare var $:any;


export class appointmentDialogViewModel {
    givenId = "modal";

    times = [];

    IsEditMode = ko.observable(false);

    startDate = ko.observable(moment().format("YYYY-MM-DD"));
    startTime = ko.observable("00:00");

    endDate = ko.observable(moment().format("YYYY-MM-DD"));
    endTime = ko.observable("00:00");

    hideoncreate = ko.observable();

    wholeday = ko.observable(false);

    parseDate = (dateStr: string) => moment.utc(dateStr, "DD.MM.YYYY", true);

    viewTypes = [
        { name: "Tagesansicht", view: "daily-view" },
        { name: "Wochenansicht", view: "weekly-view" },
    ];

    dateWarnningError = ko.observable(null);
    //dateWarnningErrorMessage
    selectedType = ko.observable(this.viewTypes[0].view);

    showOutlookLoader = ko.observable(false);

    parentVM: any;

    public Appoint: any;

    async pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    }

    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);

    fKAId = ko.observable('');

    modalHandleSelection = ko.observable();

    processingAppointment = ko.observable<boolean>(false);
    processingAppointmentErrorMessage = ko.observable<string>("");
    outlookExport = ko.observable<boolean>(false);

    changeDateFormat = (date: string) => {
        var d = date.split("-");
        return `${d[2]}.${d[1]}.${d[0]}`;
    };

    parseTime = (timeStr: string) => moment(timeStr, "HH:mm");

    constructDate = (date: string, time: string, timeType?: string) => {
        let parsedDate = this.parseDate(date);

        if (this.selectedType() === "Frist") {
            return parsedDate.toISOString();
        } else {
            if (this.wholeday()) {
                let parsedTime = this.parseTime(timeType === 'start' ? '00:00' : '23:59');
                parsedDate.add(parsedTime.hours(), "hours");
                parsedDate.add(parsedTime.minutes(), "minutes");
                return parsedDate.toISOString();
            }

            let parsedTime = this.parseTime(time);
            let hour = parsedTime.hour();
            parsedDate.add(parsedTime.hours(), "hours");
            parsedDate.add(parsedTime.minutes(), "minutes");
            return parsedDate.toISOString();
        }
    };

    // checkDate = (str: string) => this.parseDate(moment(str).format("DD.MM.YYYY")).isValid();
    checkDate = (str: string) => { return { validation: moment.utc(str).isValid(), message: ' ist Pflichtfeld' } };
    matchDate = (start: string, end: string) => moment(start).isSame(end)

    checkTime = (str: string) => this.parseTime(str).isValid();

    newBasicAppointment() {
        let obj = {
            AppointmentSubject: ko.observable(null),
            AppointmentDate: ko.computed(() =>
                this.constructDate(this.startDate().toString(), this.startTime(), 'start')
            ),
            StartDate: ko.computed(() =>
                this.constructDate(this.startDate().toString(), this.startTime(), 'start')
            ),
            EndDate: ko.computed(() =>
                this.constructDate(this.endDate().toString(), this.endTime(), 'end')
            ),
            AppointmentType: ko.observable(""),
            Addressee: ko.observable(null),
            AddresseeId: ko.observable(""),
            AddresseeTypeId: ko.observable(""),
            AppointmentNote: ko.observable(""),
            SachbearbeiterId: ko.observable(RNSAPI.User().username),
            Sachbearbeiter: ko.observable(null),
            CaseId: ko.observable(""),
            Completed: ko.observable(false),
            CreationDate: ko.observable(null),
            CreatorId: ko.observable(""),
            EditedAtDate: ko.observable(null),
            EditorId: ko.observable(""),
            Gericht: ko.observable(null),
            GerichtsId: ko.observable(""),
            Id: ko.observable(""),
            IsDeleted: ko.observable(false),
            IsRecurringAppointment: ko.observable(false),
            Lawyer: ko.observable(null),
            LawyerId: ko.observable(RNSAPI.User() ? RNSAPI.User().username : "GR"),
            MeetingPlace: ko.observable(""),
            MeetingPlaceStreet: ko.observable(""),
            Moved: ko.observable(""),
            RecurringAppointmentDto: ko.observable(null),
            RoomID: ko.observable(""),
        };
        obj["AppointmentName"] = ko.computed(() => obj.AppointmentSubject());

        return ko.observable(obj);
    }

    basicAppointment = this.newBasicAppointment();
    fillNewAppointmentData(data) {
        if (data) {
            this.startDate(moment(data().startDate).format("YYYY-MM-DD"));
            this.endDate(moment(data().startDate).format("YYYY-MM-DD"));
            this.startTime(data().time || "00:00");
            setTimeout(() => {
                this.changedStartTime()
            }, 10)
        }
    }
    async fillAppointmentData(id) {
        try {
            //id Encoding wrong!

            let result = await RNSAPI.getAppointment(id);
            if (result.Type === "AppointmentFound") {
                let appointment = result.Payload.Appointment;
                let obj = this.basicAppointment();

                let excluding = [
                    "AppointmentDate",
                    "AppointmentName",
                    "StartDate",
                    "EndDate",
                    "isRecurringAppointment",
                ]; //"AppointmentType",
                for (let key in appointment) {
                    if (obj[key] === undefined) {
                        obj[key] = appointment[key];
                    } else if (excluding.indexOf(key) === -1) {
                        obj[key](appointment[key]);
                    }
                }

                this.basicAppointment(obj);

                let Rubrum = "";

                if (ko.toJS(this.basicAppointment().CaseId) !== "") {
                    Rubrum = (await RNSAPI.getCaseByCaseId(ko.toJS(this.basicAppointment().CaseId))).Payload.Case.Rubrum
                }

                //console.log(obj);
                (<HTMLSelectElement>(
                    document.getElementById("terminar")
                )).value = ko.toJS(this.basicAppointment().AppointmentType);
                (<HTMLSelectElement>(
                    document.getElementById("AppointmentDialog_Akte")
                )).value = ko.toJS(this.basicAppointment().CaseId) + (Rubrum !== "" ? " " + Rubrum : "");

                let momentStart = moment
                    .utc(appointment.AppointmentDate)
                // .subtract(1, "hours")
                // .local();
                let momentStartTime = moment
                    .utc(appointment.StartDate)
                // .subtract(1, "hours")
                // .local();
                let momentEndTime = moment
                    .utc(appointment.EndDate)
                // .subtract(1, "hours")
                // .local();
                this.startDate(momentStart.format("YYYY-MM-DD"));
                this.endDate(momentEndTime.format("YYYY-MM-DD"));
                this.startTime(momentStartTime.format("HH:mm"));
                this.endTime(momentEndTime.format("HH:mm"));

                if(this.startDate() === this.endDate() && this.endTime() === this.startTime()) {
                    this.wholeday(true);
                }
            } else {
                alert("Termin nicht gefunden.");
            }
        } catch (e) {
            alert("Verbindung zum Server abgebrochen.");
            console.log(e);
        }
    }


    clearAppointmentModal(): void {
        $(`#${this.givenId}`).modal("hide");

        this.processingAppointment(false);
        this.processingAppointmentErrorMessage("");
        this.IsEditMode(false);
        this.wholeday(false);
        this.outlookExport(false);
        this.basicAppointment().AppointmentSubject("");
        this.basicAppointment().CaseId("");
        this.basicAppointment().AddresseeId("");
        this.basicAppointment().AddresseeTypeId("");
        this.basicAppointment().Addressee("");
        this.basicAppointment().AppointmentNote("");
        this.basicAppointment().MeetingPlace("");
    }

    async createAppointment(): Promise<void> {
        if(!this.processingAppointment()) {
            this.processingAppointment(true);
            if(this.hasDeadlineManagementRights()) {
                this.basicAppointment().LawyerId(this.basicAppointment().SachbearbeiterId());
            }
            await RNSAPI.createAppointment(this.basicAppointment()).then(async (result) => {
                if (result.Type === "CreationSuccessful") {
                    RNSAPI.createHistoryEntry(
                        this.basicAppointment().CaseId(),
                        "Termin angelegt"
                    );
                    if (ko.toJS(this.fKAId) !== '') {
                        if (this.outlookExport()) {
                            this.showOutlookLoader(true);
                            let res = await RNSAPI.notifyAppointment(ko.toJS(this.fKAId), ko.toJS(this.basicAppointment));
                            if (res.Type === "ExportRealAppointmentSuccessful") {
                                this.showOutlookLoader(false);
                            }
                        }
                    }

                    Postbox.publisher().publish(true, "AppointmentCreatedSuccessful");
                    this.clearAppointmentModal();
                 
                    Postbox.publisher().publish('Hello', 'updateDashboard');
                    Postbox.publisher().publish("", "updateTFW");    
                    $("#EndDateWarning").modal("hide");
                }
                else {
                    this.processingAppointmentErrorMessage("Der Termin konnte nicht angelegt werden.");
                    this.processingAppointment(false);
                }
            }).catch((error) => {
                this.processingAppointmentErrorMessage("Der Termin konnte nicht angelegt werden.");
                this.processingAppointment(false);
            });            
        }
        
    }
    hideContinueWithWarnningModal() {

        $("#EndDateWarning").modal("hide");
        $("#" + this.givenId).modal("show");
    }
    async continueWithWarnning() {

        await this.createAppointment();
    }
    async appointmentModalPostData() {
        $(".form-group").each(function () {
            $(this).removeClass("has-error");
        });

        if (
            !Utils.checkErrors(
                ["AppointmentSubject", "SachbearbeiterId"],
                this.basicAppointment(),
                "appointment",
                [Utils.checkString]
            )
        ) {
            return false;
        }
        if (
            !Utils.checkErrors(["startDate", "endDate"], this, "", [
                Utils.checkString,
                this.checkDate,
            ])
        ) {
            this.processingAppointmentErrorMessage("Ungültiges Datum");
            return;
        }

        if (
            this.selectedType() === "Termin" &&
            !Utils.checkErrors(["startTime", "endTime"], this, "", [
                Utils.checkString,
                this.checkTime,
            ])
        ) {
            this.processingAppointmentErrorMessage("Ungültige Zeit");
            return;
        }

        if(this.basicAppointment().CaseId() !== ''){
            if(!ko.toJS(this.allCases()).find(x => x.caseid === this.basicAppointment().CaseId())) {
                this.processingAppointmentErrorMessage("Die eingegebene Registernummer existiert nicht.");
                return;
            }
        }

        this.startDate(this.changeDateFormat(this.startDate()));
        this.endDate(this.changeDateFormat(this.endDate()));
        if (
            new Date(this.basicAppointment().StartDate()) >
            new Date(this.basicAppointment().EndDate())
        ) {
            this.startDate(this.parseDate(this.startDate()).format("YYYY-MM-DD"));
            this.endDate(this.parseDate(this.endDate()).format("YYYY-MM-DD"));
            alert("Das Enddatum liegt vor dem Startdatum");
            return;
        }

        if (this.IsEditMode()) {
            if(!this.processingAppointment()) {
                this.processingAppointment(true);
                await RNSAPI.updateAppointment(this.basicAppointment()).then(async (result) => {
                    if (result.Type === "UpdateSuccessful") {
                        RNSAPI.createHistoryEntry(
                            this.basicAppointment().CaseId(),
                            "Termin bearbeitet"
                        );
                        if (ko.toJS(this.fKAId) !== '') {
                            if (this.outlookExport()) {
                                this.showOutlookLoader(true);
                                let res = await RNSAPI.notifyAppointment(ko.toJS(this.fKAId), ko.toJS(this.basicAppointment));
                                if (res.Type === "ExportRealAppointmentSuccessful") {
                                    this.showOutlookLoader(false);
                                }
                            }
                        }

                        Postbox.publisher().publish(true, "AppointmentCreatedSuccessful");
                        this.clearAppointmentModal();
                        setTimeout(() => {
                            Postbox.publisher().publish('edited', 'updatedAppointment');
                        }, 1000);
                        Postbox.publisher().publish('edit', 'updateDashboard');
                    } else {
                        this.processingAppointmentErrorMessage("Der Termin konnte nicht bearbeitet werden.");
                        this.processingAppointment(false);
                    }
                    this.IsEditMode(false);
                }).catch((error) => {
                    this.processingAppointmentErrorMessage("Der Termin konnte nicht bearbeitet werden.");
                    this.processingAppointment(false);
                });
            }
        } else {
            try {

                let endDate = this.wholeday() ? moment(this.basicAppointment().EndDate()).subtract(1, 'day').format('YYYY-MM-DD') :
                    moment(this.basicAppointment().EndDate()).format('YYYY-MM-DD');

                let currentDate = moment().format('YYYY-MM-DD');

                if (moment(endDate).isBefore(currentDate)) {
                    this.clearAppointmentModal();
                    $("#" + 'EndDateWarning').modal("show");
                    return;
                }

                await this.createAppointment();
            } catch (e) {
                console.log(e);
                alert(
                    "Fehler beim Anlegen" + e.responseText ? ": " + e.responseText : ""
                );
            }
        }
    }

    adjustDigits(val: number, places: number) {
        var str = String(val);
        for (var i = 0; i < places - String(val).length; i++) {
            str = "0" + str;
        }
        return str;
    }


    async pickSachbearbeiter() {
        if(this.hasDeadlineManagementRights()) {
            this.processingAppointmentErrorMessage('');
            await this.loadClerks();
    
            let sachbearbeiter = ko.toJS(this.allClerks());
            this.pickGeneric("Sachbearbeiter", ["Sachbearbeiter"], ["Sachbearbeiter"], sachbearbeiter);
            this.modalHandleSelection((selectedObject) => this. basicAppointment().SachbearbeiterId(selectedObject()["Sachbearbeiter_ID"]));
            $("#appointModalmodal").modal("show");
        } else {
            this.processingAppointmentErrorMessage('Sie können den Termin keinem anderen Benutzer eintragen, da Ihnen die Fristenverwalter-Rechte fehlen.');
        }
    };

    allCases = ko.observableArray([]);
    allClerks = ko.observableArray([]);
    hasDeadlineManagementRights = ko.observable<boolean>(false);

    selectedCase = ko.observable();

    selectCase(event, data): void {
        this.basicAppointment().CaseId(this.selectedCase());
    }

    async pickCase() {
        this.processingAppointmentErrorMessage('');
        await this.loadCases();
        let cases = ko.toJS(this.allCases());

        this.pickGeneric(
            "Akte",
            ["caseid", "rubrum"],
            ["Akte", "Rubrum"],
            cases
        );
        this.modalHandleSelection((selectedObject) => {
            this.basicAppointment().CaseId(selectedObject()["caseid"])
        });
        $("#appointModalmodal").modal("show");
    }


    changedStartTime() {
        let start = ko.toJS(this.startTime);
        for (let i = 0; i < this.times.length; i++) {
            if (start == this.times[i]) {
                this.endTime(this.times[i + 1]);
                // document.getElementById("endTimeID")[i + 1].selected = true;
            }
        }
    }

    changedStartDate() {
        if (moment(this.startDate(), 'YYYY-MM-DD').isAfter(moment(this.endDate(), 'YYYY-MM-DD'))) {
            this.endDate(this.startDate());
            this.changedStartTime()
        }
    }

    changeWholeDay(data: any, event: any): void {
        this.wholeday(event.target.checked);
    }

    changeOutlookExport(data: any, event: any): void {
        this.outlookExport(event.target.checked);
    }

   

    async getFKA() {
        let res = (await RNSAPI.getOutlook()).Payload.OutlookAccounts;
        if (res.length > 0) {
            this.fKAId(res[0].OutlookAccountID);
        }
    }

    private async loadCases() {
        let cases = [];
        await RNSAPI.getCasesOverview(0, ko.toJS(20)).then((data) => {
            cases = data as any;
        }).catch((error) => {
            cases = [];
        });

        this.allCases(cases); //(await RNSAPI.getCases()).Payload.Cases
        $(".selectpicker").selectpicker("refresh");
    }

    private async loadClerks() {
        this.allClerks((
            await RNSAPI.getSachbearbeiter()
        ).Payload.Clerks.filter((s) => s.Sachbearbeiter_ID.trim() !== ""));
    }


    constructor(params: any) {

       

        this.hasDeadlineManagementRights(RolesAndRights.hasDeadlineManagementRights());
        this.loadCases();
        this.loadClerks();

        $(document).ready(function() {
            $(".selectpicker").selectpicker({
                noneSelectedText: "Auswahl",
                noneResultsText: "Keine Einträge gefunden",
                iconBase: "fal",
                tickIcon: "fa-check",
                actionsBox: true,
                deselectAllText: "Auswahl aufheben",
                selectAllText: "Alle auswählen"
            });
            $(".selectpicker").selectpicker("refresh");
        });

        if (params && params.hideoncreate) {
            if (typeof params.hideoncreate == "boolean")
                this.hideoncreate(params.hideoncreate);
            else this.hideoncreate = params.hideoncreate;
        } else this.hideoncreate(false);

        if (params.prefix) {
            if (params.mode && params.mode() !== 'edit') {
                this.fillNewAppointmentData(params.data)
            }
            this.givenId = params.prefix + this.givenId;
        }
        this.parentVM = params.vm;

        for (var i = 0; i < 24; i++) {
            this.times.push(this.adjustDigits(i, 2) + ":" + "00");
            this.times.push(this.adjustDigits(i, 2) + ":" + "30");
        }

        Postbox.publisher().subscribe(async (response) => {
            if(response) {
                this.IsEditMode(response.EditAppointment);
                if(response.AppointmentId) {
                    this.fillAppointmentData(response.AppointmentId);
                }
                $(`#${this.givenId}`).modal("show");
            }
        }, "EditAppointmentData");


        this.getFKA();
    }
}

let html = fs.readFileSync(__dirname + "/appointmentDialog.html", "utf8");

ko.components.register("appointment-dialog-view", {
    viewModel: appointmentDialogViewModel,
    template: html,
});
