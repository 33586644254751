import * as fs from 'fs';
import * as ko from "knockout";
import { RNSAPI } from "../../api";

class FastCasePersonViewModel {
    constructor(params) {
        this.client = params.client;
        this.index = params.index;
        this.prefix = params.prefix;
    }

    client;
    index;
    prefix

    toggleShown() {
        this.client.isShown(!this.client.isShown());
    }

    toggleMoreData() {
        this.client.isMoreShown(!this.client.isMoreShown());
    }

    async pickPerson(parent) {
        let addresses = (await RNSAPI.getPersonsForScope(this.client.personType())).Payload.Addresses;
        console.log(addresses);
        await parent.pickGeneric(
            this.client.personType() === "gegner" ? "Gegnerauswahl" : "Mandantauswahl",
            ["FirstName", "Name1", "CityName"],
            ["Vorname", "Nachname", "Ort"],
            addresses,
            (a) => {
                let o = a();
                for (let key in o) {
                    if (key === "Keyword") {
                        this.client.data["Keyword"] = ko.observable(o["Keyword"]);
                    } else if (typeof(this.client.data[key]) === 'function') {
                        this.client.data[key](o[key]);
                    } else {
                        this.client.data[key] = o[key];
                    }
                }
            }
        );
        $('#modal').modal('show');
    }

    async pickRechtsform(parent) {
        let rechtsformen = (await RNSAPI.getRechtsformen()).Payload.LegalForms;
        await parent.pickGeneric(
            "Rechtsform",
            ["NameID", "Briefanrede"],
            ["Name", "Anrede"],
            rechtsformen,
            (a) => this.client.data.Rechtsform(a().NameID)
        );
        $('#modal').modal('show');
    };

}

let html = fs.readFileSync(__dirname + '/fastcaseperson1.html', 'utf8');

ko.components.register("fastcaseperson1-view", {
    viewModel: FastCasePersonViewModel,
    template: html
});
