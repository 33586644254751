import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { Utils } from "../../utils";
import { MainViewModel } from "../../main";
import '../dialog/dialog';
import { TreeType } from "../tree/tree";

export class StrategyDto {
    StrategyId: string
    TaxonId: string
    StrategyProcessId: string
    ChecklistProcessId: string
    Description: string
}

class StrategyViewModel {
    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalHandleSelection = ko.observable();

    selectedTaxon = ko.observable(null);
    selectedProcess = ko.observable(null);
    selectedChecklist = ko.observable(null);
    description = ko.observable("");

    pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    };

    async pickTaxon() {
        let taxonomies = (await RNSAPI.getTaxonomies()).Payload.Taxonomies;
        let mappedTaxonomies = taxonomies.map(t => {
            t.AllFilters = [t.Filter1, t.Filter2, t.Filter3, t.Filter4, t.Filter5, t.Filter6, t.Filter7].filter(f => f).join(", ");
        });
        this.pickGeneric("Taxonomien", ["AllFilters"], ["Filter"], taxonomies);
        this.modalHandleSelection((selectedObject) => this.selectedTaxon(selectedObject()));
        $('#modal').modal('show');
    };

    async pickChecklist() {
        let checklists = (await RNSAPI.getTreesByType(TreeType.CHECKLIST)).Payload.Trees;
        this.pickGeneric("Checklisten", ["Title"], ["Titel"], checklists);
        this.modalHandleSelection((selectedObject) => this.selectedChecklist(selectedObject()));
        $('#modal').modal('show');
    };

    async pickProcess() {
        let checklists = (await RNSAPI.getTreesByType(TreeType.PROCESS)).Payload.Trees;
        this.pickGeneric("Prozesse", ["Title"], ["Titel"], checklists);
        this.modalHandleSelection((selectedObject) => this.selectedProcess(selectedObject()));
        $('#modal').modal('show');
    };

    async postData() {
        let strategy: StrategyDto = {
            StrategyId: null,
            TaxonId: this.selectedTaxon().Id,
            StrategyProcessId: this.selectedProcess().Id,
            ChecklistProcessId: this.selectedChecklist().Id,
            Description: this.description()
        }

        let result = await RNSAPI.joinTaxonomie(strategy);

        if (result.Type === "JoinSuccessful") {
            MainViewModel.RoutingTable.showStrategyOverview();
        } else {
            alert("Fehler beim Speichern der Strategie.");
        }
    };

    constructor(params: any) {
    }
}

let html = fs.readFileSync(__dirname + '/strategy.html', 'utf8');

ko.components.register("strategy-view", {
    viewModel: StrategyViewModel,
    template: html
});
