import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { MainViewModel } from "../../main";
import { Utils } from "../../utils";

//special handler to update iframe content
ko.bindingHandlers.iframeContent = {
    update: function (element, valueAccessor) {
        var value = ko.unwrap(valueAccessor());
        element.contentWindow.document.close(); // Clear the content
        element.contentWindow.document.write(value);
    }
};

enum Source {
    ECase = "ECase",
    ID = "ID"
}

class MailViewModel {
    mail = ko.observable(null);
    isLoadedEmailByEcase = ko.observable(false);
    //if true disables the offset
    fullscreen = false;
    source = Source.ID;
    subscriptions = []

    async loadEmailById(accountId: string, mailId: string) {
        let mail = (await RNSAPI.getMailById(accountId, mailId)).Payload;
        this.mail(mail);
    }

    async loadEmailByECase(eCaseRecordId: string) {
        let mail = (await RNSAPI.getMailByECase(eCaseRecordId)).Payload;
        this.mail(mail);
    }

    newMail() {
        return ko.observable({
            Subject: ko.observable(''),
            From: ko.observable(''),
            To: ko.observable(''),
            BodyHtml: ko.observable(''),
            Attachments: ko.observableArray([]),
        })
    }
    downloadAttachment = async (id, name) => {
        if (this.source === Source.ECase) {
            //in Case of ECase
            //Attachment is already contained in the DTO
            //with big attachments this could be problematic
            let blob = Utils.base64ToBlob(this.mail().Attachments.filter(attachment => attachment.AttachmentId === id)[0].AttachmentData);
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            document.body.appendChild(link);
            link.download = name;
            link.click();
            document.body.removeChild(link);
        } else {
            let accountId = this.mail().AccountID;
            let rnsMailId = this.mail().RnsMailID;
            let attachmentId = id;
            let req = new XMLHttpRequest();
            req.open("POST", `${RNSAPI.base}/ZPE/GetAttachment`, true);
            req.setRequestHeader("Content-Type", "application/json");
            req.setRequestHeader('access_token', RNSAPI.Token);
            req.responseType = "blob";

            req.onload = function (event) {
                let blob = req.response;
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                document.body.appendChild(link);
                link.download = name;
                link.click();
                document.body.removeChild(link);
            };

            let obj = {
                AccountID: accountId,
                RnsMailID: rnsMailId,
                AttachmentID: attachmentId
            }

            req.send(JSON.stringify(obj));

        }
    };

    constructor(params: any) {

        params = params || {};

        if (params.accountId && params.mailId) {
            this.source = Source.ID;
            let accountId = params.accountId;
            let mailId = params.mailId;
            this.loadEmailById(accountId, mailId);
        } else if (params.id) {

            this.source = Source.ECase;
            this.subscriptions.push(params.id.subscribe(newId => {
                this.loadEmailByECase(newId);
            }));

            this.mail(this.newMail())
            this.loadEmailByECase(params.id());
        }

        this.fullscreen = params.fullscreen || false;
    }

    dispose() {
        for (var s of this.subscriptions) s.dispose();
    }
}

let html = fs.readFileSync(__dirname + '/mail.html', 'utf8');

ko.components.register("mail-view", {
    viewModel: MailViewModel,
    template: html
});
