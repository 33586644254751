import { Utils } from '../../utils';
import * as moment from 'moment';
import * as ko from "knockout";
import * as fs from 'fs';
export class ExtendedDataTableViewModel {
    numberOfItems: ko.Observable<number>;
    columns: Array<string>;
    columnHeaders: Array<string>;
    entries: ko.ObservableArray<any>;
    allItems: ko.ObservableArray<any>;
    emptyMessage: string;
    hasActions: boolean;
    isEmpty: ko.Computed<boolean>;
     constructor(params: any) {
        this.columns = params.columns;
        this.columnHeaders = params.columnHeaders;
        this.emptyMessage = params.emptyMessage;
        this.hasActions = params.hasActions;
        this.entries = params.entries;
        this.isEmpty = ko.computed(
            () => {
                return this.entries.length === 0;
            }
        );
    }

    newElement(): void {
      this.entries.push(Utils.replicate(this.columnHeaders.length, ()=>ko.observable("")));
    }
}

var html = fs.readFileSync(__dirname + '/extendedDataTable.html', 'utf8');

ko.components.register("extended-data-table", {
    viewModel: ExtendedDataTableViewModel,
    template: html
});
