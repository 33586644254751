import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { Utils } from "../../utils";
import { MainViewModel } from "../../main";
import '../dialog/dialog';
import { DialogViewModel } from '../dialog/dialog';
import * as $ from 'jquery';
import * as moment from 'moment';
window["$"] = $;
window["jQuery"] = $;
import * as fs from 'fs';

class CaseViewModel {
    IsEditMode = ko.observable(false);
    caseId: string = undefined;
    buttonText = ko.computed(() => this.IsEditMode() ? "Akte speichern" : "Akte anlegen");




    buildName(firstName, lastName) {
        return this.defaultString((firstName === null ? '' : firstName) + ' ' + (lastName === null ? '' : lastName));
    };

    defaultString(str) {
        if (!Utils.checkString(str)) {
            return '<keine Angabe>';
        }

        return str;
    };

    async pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    };

    pickSachbearbeiter = async () => {
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric("Sachbearbeiter", ["Sachbearbeiter"], ["Sachbearbeiter"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => this.akte().SachbearbeiterId(selectedObject()["Sachbearbeiter_ID"]));
        $('#modal').modal('show');
    };

    pickReferat = async () => {
        let referate = (await RNSAPI.getReferate()).Payload.Units;
        this.pickGeneric("Referat", ["Name", "Id"], ["Name", "Id"], referate);
        this.modalHandleSelection((selectedObject) => this.akte().Referat(selectedObject()["Id"]));
        $('#modal').modal('show');
    };

    async pickRechtsform(person) {
        let rechtsformen = (await RNSAPI.getRechtsformen()).Payload.LegalForms;
        console.log(this);
        this.pickGeneric("Rechtsform", ["NameID", "Briefanrede"], ["Name", "Anrede"], rechtsformen);
        this.modalHandleSelection((selectedObject) => person.Rechtsform(selectedObject()["NameID"]));
        $('#modal').modal('show');
    };

    async pickPerson(type) {
        let addresses = (await RNSAPI.getPersonsForScope(type)).Payload.Addresses;
        this.pickGeneric("Mandantenauswahl", ["FirstName", "Name1", "CityName"], ["Vorname", "Nachname", "Ort"], addresses);
        this.modalHandleSelection((selectedObject) => {
            let personFromDb = selectedObject();
            let obj = this[type]();
            for (let key in personFromDb) {
                if (obj[key] === undefined) {
                    obj[key] = personFromDb[key];
                } else if (key !== "Keyword") {
                    obj[key](personFromDb[key]);
                } else {
                    obj["Keyword"] = ko.observable(personFromDb["Keyword"]);
                }
            }
            obj["edit"](false);
        });
        $('#modal').modal('show');
    };

    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalHandleSelection = ko.observable();

    akte = ko.observable({
        Registernummer: ko.observable(null),
        Referat: ko.observable(null),
        SachbearbeiterId: ko.observable(null),
        Rubrum: ko.observable(null),
        Wegen: ko.observable(null),
        Sachstand: ko.observable(null),
        AnlageDatum: ko.observable(moment().format("DD.MM.YYYY")),
        Note1: ko.observable(null),
        Wiedervorlage: ko.observable(""),
        WertGericht1: ko.observable(0),
        IsSelected: ko.observable(true),

    });

    oldElement = this.akte();
    highlight(elem) {
        if (this.oldElement) this.oldElement.IsSelected(false);
        elem.IsSelected(true);
        this.oldElement = elem;
    };

    newBasicPerson() {
        let obj = {
            FirstName: ko.observable(""),
            Name1: ko.observable(""),
            Rechtsform: ko.observable(null),
            Address: ko.observable(null),
            ZipCode: ko.observable(null),
            CityName: ko.observable(null),
            EmailAddress: ko.observable(null),
            Phone1: ko.observable(null),
            edit: ko.observable(true),
            IsSelected: ko.observable(false)
        };

        obj["Keyword"] = ko.computed(() => (obj.Name1() + obj.FirstName()).substring(0, 20));

        return ko.observable(obj);
    };

    resetObject(obj) {
        for (let key in obj) {
            obj[key](null);
        }
    }

    originalPersons = [];

    mandant = this.newBasicPerson();
    gegner = this.newBasicPerson();
    sonstige = this.newBasicPerson();

    allSonstige = ko.observableArray([]);

    addSonstige = () => {
        let person = this.newBasicPerson();
        this.allSonstige.push(person);
        this.sonstige(person());
        this.goto("sonstige");
        this.highlight(person());
    };

    selectSonstige = (sonstige) => {
        this.sonstige(sonstige);
        this.highlight(sonstige);
        this.goto("sonstige");
    };

    removeSonstige = (index) => {
        this.allSonstige.splice(index, 1);
        this.goto("akte");
        this.highlight(this.akte());
    };


    allMandanten = ko.observableArray([]);

    addMandant = () => {
        let person = this.newBasicPerson();
        this.allMandanten.push(person);
        this.mandant(person());
        this.goto("mandant");
        this.highlight(person());
    };

    selectMandant = mandant => {
        this.mandant(mandant);
        this.highlight(mandant);
        this.goto("mandant");
    };

    removeMandant = (index) => {
        this.allMandanten.splice(index, 1);
        this.goto("akte");
        this.highlight(this.akte());
    };

    allGegner = ko.observableArray([]);

    addGegner = () => {
        let person = this.newBasicPerson();
        this.allGegner.push(person);
        this.gegner(person());
        this.goto("gegner");
        this.highlight(person());
    };

    selectGegner = (gegner) => {
        this.gegner(gegner);
        this.highlight(gegner);
        this.goto("gegner");
    };

    removeGegner = (index) => {
        this.allGegner.splice(index, 1);
        this.goto("akte");
        this.highlight(this.akte());
    };

    nextAppointment = ko.observable(null);
    nextDeadline = ko.observable(null);
    nextResubmission = ko.observable(null);

    panelName = ko.observable('case-template');
    panelData = ko.observable(null);


    currentPanelData = ko.observable({
        panel: 'case-template',
        data: this.akte
    });

    mappedName = ko.observable("akte");

    mapping = {
        "akte": {
            "panel": "case-template",
            "data": this.akte
        },
        "mandant": {
            "panel": "client-template",
            "data": this.mandant
        },
        "gegner": {
            "panel": "client-template",
            "data": this.gegner
        },
        "sonstige": {
            "panel": "client-template",
            "data": this.sonstige
        }
    };

    goto(name) {
        this.mappedName(name);
        this.currentPanelData(this.mapping[name]);
        this.currentPanelData.valueHasMutated();
    };

    noErrors() {
        $(".form-group").each(function () { $(this).removeClass("has-error"); });
        if (!Utils.checkErrors(["Registernummer"], this.akte(), "akte", [Utils.checkString])) return false;

        if (!Utils.checkErrors(["AnlageDatum"], this.akte(), "akte", [(s: string) => moment(s, "DD.MM.YYYY", true).isValid()])) return false;

        for (let i = 0; i < this.allMandanten().length; i++) {
            let mandant = this.allMandanten()[i]();
            if (mandant.edit() === true && !Utils.checkErrors(["Name1"], mandant, "mandant", [Utils.checkString])) return false;
        }

        for (let i = 0; i < this.allGegner().length; i++) {
            let gegner = this.allGegner()[i]();
            if (gegner.edit() === true && !Utils.checkErrors(["Name1"], gegner, "mandant", [Utils.checkString])) return false;
        }

        for (let i = 0; i < this.allSonstige().length; i++) {
            let sonstige = this.allSonstige()[i]();
            if (sonstige.edit() === true && !Utils.checkErrors(["Name1"], sonstige, "mandant", [Utils.checkString])) return false;
        }
        return true;
    };

    async readData() {
        let caseId = this.caseId;

        let akte = (await RNSAPI.getCaseByCaseId(caseId)).Payload.Case;
        let obj = this.akte();
        for (let key in akte) {
            if (obj[key] === undefined) {
                obj[key] = akte[key];
            } else {
                obj[key](akte[key]);
            }
        }

        let persons = (await RNSAPI.getPersonsForCaseId(caseId)).Payload.Addresses;
        let mandanten = persons[0];
        let gegner = persons[1];
        let sonstige = persons[7];

        this.originalPersons = mandanten.map(function (data) { return { "data": data, url: "Mandant" }; });
        this.originalPersons = this.originalPersons.concat(gegner.map(function (data) { return { "data": data, url: "Gegner" }; }));
        this.originalPersons = this.originalPersons.concat(sonstige.map(function (data) { return { "data": data, url: "Sonstige" }; }));

        let parsePerson = personFromDb => {
            let newPerson = this.newBasicPerson();
            let obj = newPerson();
            for (let key in personFromDb) {
                if (obj[key] === undefined) {
                    obj[key] = personFromDb[key];
                } else if (key !== "Keyword") {
                    obj[key](personFromDb[key]);
                } else {
                    obj["Keyword"] = ko.observable(personFromDb["Keyword"]);
                }
            }
            obj["edit"](false);

            return newPerson;
        };

        for (let i = 0; i < mandanten.length; i++) {
            this.allMandanten.push(parsePerson(mandanten[i]));
        }

        for (let i = 0; i < gegner.length; i++) {
            this.allGegner.push(parsePerson(gegner[i]));
        }

        for (let i = 0; i < sonstige.length; i++) {
            this.allSonstige.push(parsePerson(sonstige[i]));
        }

        let startOfDay = moment().startOf("day").toDate();
        let endOfDay = moment().endOf("day").toDate();
        let allAppointmentsResult = await RNSAPI.getAppointmentsForCase(caseId, startOfDay, endOfDay);
        let allDeadlines = (await RNSAPI.getDeadlinesForCase(caseId)).Payload.Deadlines;
        if (allAppointmentsResult.Type === "ForCasesFound") {
            let appointments = allAppointmentsResult.Payload.Appointments.filter(a => a.AppointmentType !== "F");
            let deadlines = allDeadlines.filter(d => d.CompletionDate === null);
            let resubmissions = (await RNSAPI.getResubmissionByCaseId(caseId)).Payload.Resubmissions.filter(r => (new Date(r.DeadLineDate)).getTime() >= (moment().startOf("day").toDate()).getTime());
            if (appointments.length > 0) this.nextAppointment(appointments[0]);
            if (deadlines.length > 0) this.nextDeadline(deadlines[0]);
            if (resubmissions.length > 0) this.nextResubmission(resubmissions[0]);
        }
    };

    async postData() {
        if (!this.noErrors()) return;

        try {
            if (this.IsEditMode()) {
                let result = await RNSAPI.updateCase(this.akte());
            } else {
                let result = await RNSAPI.createCase(this.akte());
                if (result.Type === "CaseAlreadyExists" ) {
                  alert("Warnung: Es exestiert bereits eine Akte mit dieser Registernummer\nEs wurde eine neue Vorgeschlagen");
                  this.suggestRegisterNumber();
                  return
                }
            }

            let mandanten = this.allMandanten().map(function (data) {
                 return { "data": data(), url: "Mandant" }; 
                 });
            let withGegner = mandanten.concat(this.allGegner().map(function (data) {
                 return { "data": data(), url: "Gegner" };
                  }));
            let withSonstige = withGegner.concat(this.allSonstige().map(function (data) {
                 return { "data": data(), url: "Sonstige" };
                  }));

            let personsToBeCreated = withSonstige.filter(function (obj) { 
                return obj.data.edit() === true;
                 });

            let toBeJoined = [];
            let toBeUnjoined = [];

            for (let i = 0; i < withSonstige.length; i++) {
                let person = withSonstige[i].data;
                let join = true;

                for (let j = 0; j < this.originalPersons.length; j++) {
                    let originalPerson = this.originalPersons[j].data;

                    if (originalPerson.Keyword === person.Keyword()) {
                        join = false;
                    }
                }

                if (join) {
                    toBeJoined.push(withSonstige[i]);
                }
            }

            for (let i = 0; i < this.originalPersons.length; i++) {
                let originalPerson = this.originalPersons[i].data;
                let unjoin = true;

                for (let j = 0; j < withSonstige.length; j++) {
                    let person = withSonstige[j].data;

                    if (originalPerson.Keyword === person.Keyword()) {
                        unjoin = false;
                    }
                }

                if (unjoin) {
                    toBeUnjoined.push(this.originalPersons[i]);
                }
            }

            console.log(toBeJoined);
            console.log(toBeUnjoined);

            for (let obj of personsToBeCreated) {
                console.log(ko.toJS(obj.data)," ",obj.url);
                let result = await RNSAPI.createPerson(obj.data, obj.url);
                if (result.Type !== "AdditionSuccessful") {
                    alert(`Fehler beim Anlegen: Person mit diesem Keyword existiert bereits: ${obj.data.Name1}`);
                    return;
                }
            }

            for (let obj of toBeJoined) {
                let urlRegister = this.akte().Registernummer();
                let urlKeyword = obj.data.Keyword();
                console.log("joined ",urlKeyword,urlRegister,obj.url);
                await RNSAPI.joinPerson(urlKeyword, urlRegister, obj.url);
            }

            for (let obj of toBeUnjoined) {
                let urlRegister = this.akte().Registernummer();
                let urlKeyword = obj.data.Keyword;
                console.log("un joined ",urlKeyword,urlRegister,obj.url);
                await RNSAPI.unjoinPerson(urlKeyword, urlRegister, obj.url);
            }

            //MainViewModel.RoutingTable.showCaseOverview();
        } catch (e) {
            console.log(e);
            alert("Fehler beim Speichern der Akte: " + e.responseText);
        }
    };

    async suggestRegisterNumber() {
        let registernummer = (await RNSAPI.suggestCaseId()).Payload.CaseId;
        this.akte().Registernummer(registernummer);
    }

    constructor(params) {
        params = params || {};
        this.caseId = params.caseId;
        this.IsEditMode(this.caseId !== undefined);
        if (!this.IsEditMode()) {
            this.suggestRegisterNumber();
        } else {
            this.readData();
        }

        var a = moment().add(14, 'day');
        this.akte().Wiedervorlage(a.format("DD.MM.YYYY").toString());

        this.akte().SachbearbeiterId(RNSAPI.User().username);

        $('#modalTable').on('click', 'tr', function (event) {
            $(this).addClass('bg-info').siblings().removeClass('bg-info');
        });
    }
}

let html = fs.readFileSync(__dirname + '/case.html', 'utf8');

ko.components.register("case-view", {
    viewModel: CaseViewModel,
    template: html
});
