import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment";
import { RNSAPI } from "../../api";
import { MainViewModel } from "../../main";
import '../zpe/mail';
import '../dataTable/dataTable';
import '../empty/empty';
import '../dropdownsearch/dropdownsearch';
import { Utils } from "../../utils";
import { DataTableViewModel, IntermediateListItem, Color } from '../dataTable/dataTable';
import { Postbox } from "../dashboard/postbox";
import { RolesAndRights } from "../../helpers/RolesAndRights";
enum ZPECategories {
    Emails = "Emails",
    ScanPool = "ScanPool",
    EConsult = "EConsult",
    Debris = "Debris",
    BeA = "BeA",
    Alle = "Alle"
}
enum Source {
    ECase = "ECase",
    ID = "ID"
}

export class Interval {
    public readonly startDate: moment.Moment
    public readonly endDate: moment.Moment

    constructor(startDate: moment.Moment, endDate: moment.Moment) {
        this.startDate = startDate;
        this.endDate = endDate;
    }
}

export class ZPEViewModel {
    workflowTable = {
        "PE": "Posteingang",
        "RA": "Bearbeitung Rechtsanwalt",
        "B": "Bearbeitung Büro",
        "ZU": "Unterschriftsmappe",
        "V": "Postausgang",
        "PA": "Gesendet",
        "E": "Erledigt",
    }

    tableWorkflow = {
        "Posteingang": "PE",
        "Autorenkorrektur": "Ak",
        "Zur Bearbeitung": "B",
        "Löschung wg. BDSG": "BDS",
        "Diktat": "Di",
        "Erledigt": "E",
        "an EGVP übergeben": "EGP",
        "Empfangene Nachrichten": "EIN",
        "Elektronischer Rechtsverkehr": "ERV",
        "Fertigstellung": "F",
        "Postausgang": "PA",
        "Prüfung": "PR",
        "Signatur geprüft": "SG",
        "Signiert": "SI",
        "Standardtext": "St",
        "Zur Unterschrift": "U",
        "Versand": "V",
        "Versandfertig machen": "VF",
        "Archiv VP": "VPA",
        "Zur Signatur": "ZS",
        "zur Unterschrift": "ZU",
        "Bearbeitung Büro": "b",
        "Download eConsult": "eCD",
        "Upload eConsult": "eCU",
        "Download Drebis": "eDD",
        "Upload Drebis": "eDU",
        "Bearbeitung Anwalt": "ra"
    }

    types = ko.observableArray(Object.keys(this.workflowTable).map(key => this.workflowTable[key]));
    selectedWorkflow = ko.observable(this.types()[0]);

    isViewerRole = ko.observable<boolean>(false);

    currentViewer = ko.observable("empty-view");
    newCaseOption = ko.observable(false);
    currentViewerParams = ko.observable({});
    BeAMessage = ko.observable(null);
    beABool = ko.observable(false);
    emailBool = ko.observable(false);
    msg = ko.observable("");
    alleScan = ko.observable("");
    alleMail = ko.observable("");
    intervalLength = ko.observable(30);
    adressesForCase = ko.observableArray([]);
    interval = ko.pureComputed(() => {
        let length = this.intervalLength();
        let endDate = moment.utc().endOf("day");
        let startDate = endDate.clone().subtract(length, "days").startOf("day");
        return new Interval(startDate, endDate);
    })
    currentCategory = ko.observable(ZPECategories.Emails);
    categories = ZPECategories;
    selectedObject = ko.observable(null);
    source = Source.ID;
    blob: Blob;
    mails = ko.observableArray([]);
    scanPool = ko.observableArray([]);
    scanPoolT = ko.observableArray([]);
    beAMessages = ko.observableArray([]);
    alles = ko.observableArray([]);
    beAAccounts = ko.observableArray([]);
    allAccounts;
    allMessages = ko.observableArray([]);
    emptyArray = ko.observableArray([]);
    caseId = ko.observable("");
    clerkId = ko.observable("");
    entryDate = ko.observable("");
    senderAdress = ko.observable("");
    comment = ko.observable("");
    tag = ko.observable("");
    registernummer = ko.observable("");
    CurrentUser = ko.observable("");
    Referat = ko.observable("");
    Rubrum = ko.observable("");
    Wegen = ko.observable("");
    url: ko.Observable<string> = ko.observable("");
    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalHandleSelection = ko.observable();
    dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' } as const;
    removeAfterMove = ko.observable(true);
    submitText = ko.observable("Zuordnen");
    errorMessage = ko.observable("");

    async getAdressesForCase(Entry) {
        let caseID = ko.toJS(Entry).split(' ')[0];
        this.caseId(caseID);
        let res = (await RNSAPI.getPersonsForCaseId(caseID)).Payload.Addresses;
        let all = [];
        if (res[0].length > 0)
            all=res[0];
        if (res[1].length > 0)
            all=all.concat(res[1]);
        if (res[7].length > 0)
            all = all.concat(res[7]);
        let AllAdresses = [];
        all.map((element) => AllAdresses.push(element.Keyword));
        this.adressesForCase(AllAdresses);
    }

    async pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    };

    pickCase = async () => {
        let cases = (await RNSAPI.getCases()).Payload.Cases;
        this.pickGeneric("Akte", ["Registernummer", "Rubrum", "Wegen", "SachbearbeiterId"], ["Akte", "Rubrum", "Wegen", "SachbearbeiterId"], cases);
        this.modalHandleSelection((selectedObject) => {
            this.newCaseOption(false);
            this.submitText("Zuordnen");
            this.caseId(selectedObject()["Registernummer"]);
            this.clerkId(selectedObject()["SachbearbeiterId"]);
        });
        $('#modal').modal('show');
    };

    pickClerk = async () => {
        let clerks = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(clerk => clerk.Sachbearbeiter_ID !== "");
        this.pickGeneric("Sachbearbeiter", ["Sachbearbeiter"], ["Sachbearbeiter"], clerks);
        this.modalHandleSelection((selectedObject) => this.clerkId(selectedObject()["Sachbearbeiter_ID"]));
        $('#modal').modal('show');
    };

    async suggestRegisterNumber() {
        let xregisternummer = (await RNSAPI.suggestCaseId()).Payload.CaseId;
        if (this.newCaseOption()) {
            this.caseId(xregisternummer);
            this.clerkId(RNSAPI.User().username);
        } else {
        }

    }
    newCase() {
        this.newCaseOption(true);
        this.suggestRegisterNumber();
        this.submitText("Anlegen & Zuordnen");
        this.errorMessage("");
    }
 
    pickReferat = async () => {
        let referate = (await RNSAPI.getReferate()).Payload.Units;
        this.pickGeneric("Referat", ["Name", "Id"], ["Name", "Id"], referate);
        this.modalHandleSelection((selectedObject) => this.Referat(selectedObject()["Id"]));

        $('#modal').modal('show');
    };
    async assignNewCase() {
        let caseData = { Registernummer: "", Rubrum: "", Referat: "", SachbearbeiterId: "", Wegen: "" };
        caseData = {
            Registernummer: this.caseId(),
            Rubrum: this.Rubrum(),
            Referat: this.Referat(),
            SachbearbeiterId: this.clerkId(),
            Wegen: this.Wegen()
        };

        let addresses = [
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            []
        ];
        let extendedCase = {
            Key: this.caseId(),
            Infos: {
                Notiz_Mandantenbegehren: "",
                Notiz_Sachverhalt: "",
                Notizen: ""
            },
            Persons: [],
        }

        let data: any = {
            Akte: caseData,
            Addresses: addresses,
            ExtendedPersons: [],
            ExtendedCase: extendedCase
        };
        try {
            let result = await RNSAPI.createExtendedCase(data);
            if (result.Type === "CaseCreationError") {
                alert("Fehler: Die Akte konnte nicht angelegt werden.");

            } else {
                RNSAPI.createHistoryEntry(this.caseId(), "Aktenanlage");
                RNSAPI.updateCaseIdSuggestion(this.caseId());
                this.associateWithCase();
            }
        } catch (e) {
            console.log(e);
            console.log("error has occured")
        }
    }

    async loadBeAAccounts() {
        this.allAccounts = await RNSAPI.getAllInboxes();
        for (let i = 0; i < this.allAccounts.Payload.Accounts.length; i++) {
            if (this.allAccounts.Payload.Accounts[i].CertificatePin === "B") {
                this.beAAccounts.push(this.allAccounts.Payload.Accounts[i]);
            }
        }
    }
    async loadMailData() {
        this.beAMessages([{}]);
        this.allMessages(((await RNSAPI.getMailsByDateInterval(this.interval().startDate, this.interval().endDate)).Payload.Emails)
            .sort((m1, m2) => {
                let d1 = new Date(m1.EMailDate);
                let d2 = new Date(m2.EMailDate);
                if (d1 < d2) {
                    return 1;
                } else {
                    return -1;
                }
            })
            .map(mail => {
                mail.EMailDate = new Date(mail.EMailDate).toLocaleDateString('de-DE', this.dateOptions);
                if(!this.isViewerRole()) {
                    mail.actionHandlers = [
                        {
                            icon: "eye", selectable: true, name: "eye", action: async () => {
                                this.selectedObject(mail);
                                let IsBeaAccount = false;
                                for (let j = 0; j < this.beAAccounts().length; j++) {
                                    if (this.beAAccounts()[j].EMailAccount_ID === this.selectedObject().EMailAccountID) {
                                        IsBeaAccount = true;
                                        break;
                                    }
                                }
                                this.showEmail(mail.EMailAccountID, mail.RNSMail_ID, IsBeaAccount);
                            }
                        },
                        {
                            icon: "trash-alt", selectable: true, name: "trash-alt", action: async () => {
                                this.deleteMail(mail.EMailAccountID, mail.RNSMail_ID);
                                this.loadZPEData();
                            }
                        }
                    ];
                }
                return mail;
            }));
        if (this.beAAccounts().length == 0) {
            this.mails(ko.toJS(this.allMessages()));
        }
        for (let j = 0; j < this.beAAccounts().length; j++) {
            for (let i = 0; i < this.allMessages().length; i++) {
                if (this.allMessages()[i].EMailAccountID.toString() === this.beAAccounts()[j].EMailAccount_ID) {
                    this.allMessages()[i].To = this.beAAccounts()[j].CertificatePath;
                    this.beAMessages.push(this.allMessages()[i]);
                }
                else {
                    this.mails.push(this.allMessages()[i]);
                }
            }
        }
    }

    async loadBeaMessageById(accountId: string, mailId: string) {
        let mail = (await RNSAPI.getMailById(accountId, mailId)).Payload;

        this.BeAMessage(mail);
    }

    uploadToScanPool = (vm, evt) => {
        let files = evt.target.files;
        let count = files.length;
        let errors: Array<string> = [];
        let illegal = (Array.from(files) as Array<any>).filter(f => f.name.length > 30).map(f => f.name);

        if (illegal.length > 0) {
            alert(`Die Dateinamen der folgenden folgenden Dateien ist zu lang: [${illegal.join()}]`);
            return;
        }

        for (let file of files) {
            if(file.type !== "application/pdf")
            {
                alert(`Der Dokumenten-Upload im Scanpool unterstützt nur PDF Dokumente. Bitte laden Sie andere Dokumenten-Formate direkt in der Akte hoch.`);
                return;
            }
            let reader = new FileReader();
            reader.onload = ((f) => {
                return async (e) => {
                    let fileName = f.name;
                    let base64Content = e.target.result.split(',')[1];

                    let apiResult = await RNSAPI.uploadToScanPool(fileName, base64Content);
                    if (apiResult.Type !== "UploadSuccessful") {
                        errors.push(f.name);
                    }
                    count--;
                    if (count === 0 && errors.length !== 0) {
                        alert(`Die Dateien ${errors.join()} konnten nicht erfolgreich hochlgeladen werden.`);
                        this.loadScanPool();
                    } else if (count === 0) {
                        alert("Alle Dateien konnten erfolgreich hochgeladen werden.");
                        this.loadScanPool();
                    }
                };
            })(file);

            reader.readAsDataURL(file);
        }

    }

    async getSB(name) {
        let ExtractSB = (await RNSAPI.getSachbearbeiter()).Payload.Clerks;
        for (let i = 0; i < ExtractSB.length; i++) {
            if (name === ExtractSB[i].Sachbearbeiter)
                return ExtractSB[i].Sachbearbeiter_ID;
        }
        return "";
    }

    async loadScanPool() {
        this.scanPool((await RNSAPI.getScanPoolByDateInterval(this.interval().startDate, this.interval().endDate)).Payload.ScannedFiles
            .sort((a, b) => {
                let dateA = new Date(a.UploadDate);
                let dateB = new Date(b.UploadDate);
                if (dateA < dateB) return 1;
                else if (dateA > dateB) return -1;
                else return 0;
            })
            .map(file => {
                file.UploadDate = new Date(file.UploadDate).toLocaleDateString('de-DE', this.dateOptions);
                if(!this.isViewerRole()) {
                    file.actionHandlers = [{
                        icon: "eye", selectable: true, name: "eye", action: async () => {
                            this.selectedObject(file.Name);
                            this.showScanPoolEntry(file.Name);
                            $('#DocumentViewerModal').modal('show');
                        }
                    }];
                }
                return file;
            }));
    }

    async loadAlleData() {
        let alleEmail = (await RNSAPI.getMailsByDateInterval(this.interval().startDate, this.interval().endDate)).Payload.Emails;
        let transformedEmail = alleEmail.map(mail => {
            mail.EMailDate = new Date(mail.EMailDate).toLocaleDateString('de-DE', this.dateOptions);
            if(!this.isViewerRole()) {
                mail.actionHandlers = [
                    {
                        icon: "eye", selectable: true, name: "eye", action: async () => {
                            this.selectedObject(mail);
                            let IsBeaAccount = false;
                            for (let j = 0; j < this.beAAccounts().length; j++) {
                                if (this.beAAccounts()[j].EMailAccount_ID === this.selectedObject().EMailAccountID) {
                                    IsBeaAccount = true;
                                    break;
                                }
                            }
                            this.showEmail(mail.EMailAccountID, mail.RNSMail_ID, IsBeaAccount);
                            this.alleMail("alleMail");
                        }
                    },
                    {
                        icon: "trash-alt", selectable: true, name: "trash-alt", action: async () => {
                            this.deleteMail(mail.EMailAccountID, mail.RNSMail_ID);
                            this.loadAlleData();
                        }
                    }
                ];
            }
            return mail;
        });

        let scanPool = (await RNSAPI.getScanPoolByDateInterval(this.interval().startDate, this.interval().endDate)).Payload.ScannedFiles
        let transformedScanPool = scanPool.map(file => {
            file.EMailDate = new Date(file.UploadDate).toLocaleDateString('de-DE', this.dateOptions);
            file.Subject = file.Name;
            if(!this.isViewerRole()) {
                file.actionHandlers = [{
                    icon: "eye", selectable: true, name: "eye", action: async () => {
                        this.selectedObject(file.Name);
                        this.showScanPoolEntry(file.Name);
                        $('#DocumentViewerModal').modal('show');
                        this.alleScan("alleScan");
                    }
                }
                ];
            }
            return file;
        });

        let alleVar = (transformedScanPool.concat(transformedEmail));
        let sortedAlle = alleVar.sort((t1: IntermediateListItem, t2: IntermediateListItem) => {
        });

        this.alles(sortedAlle.reverse());
    }

    loadZPEData() {
        this.showAlle();
        this.loadBeAAccounts();
        this.loadMailData();
        this.loadScanPool();
    }

    RefreshPage() {
        window.location.reload();
    }

    async associateEmailWithCase(mail: any) {
        await RNSAPI.moveMailToCase({
            AccountID: mail.EMailAccountID,
            CaseID: this.caseId(),
            ClerkId: this.clerkId(),
            Notes: this.comment(),
            RnsMailID: mail.RNSMail_ID,
            Sender: this.senderAdress(),
            WorkflowID: this.tableWorkflow[ko.toJS(this.selectedWorkflow())],
            DeleteAfterMove: this.removeAfterMove()
        }).then((response) => {
            this.clearMoveFormData();
            $("#DocumentViewerModal").modal('hide');
            this.loadAlleData();
            this.loadMailData();
        }).catch((error) => {
            this.errorMessage("Fehler beim Zuordnen der Akte / des Dokumentes.");
        });
    }

    private clearMoveFormData() {
        this.caseId("");
        this.clerkId("");
        this.comment("");
        this.senderAdress("");
        this.removeAfterMove(true);
    }

    async associateScanPoolWithCase(fileName: string) {
        await RNSAPI.moveDocToCase({
            FileName: fileName,
            CaseId: this.caseId(),
            ClerkId: this.clerkId(),
            Notes: this.comment(),
            Sender: this.senderAdress(),
            WorkflowID: this.tableWorkflow[ko.toJS(this.selectedWorkflow())],
            DeleteAfterMove: this.removeAfterMove()
        }).then((response) => {
            this.clearMoveFormData();
            $("#DocumentViewerModal").modal('hide');
            this.loadScanPool();
            this.loadAlleData();
        }).catch((error) => {
            this.errorMessage("Fehler beim Zuordnen der Akte / des Dokumentes.");
        });
    }

    async moveDocToCase() {
        this.clerkId(await this.getSB((<HTMLInputElement>document.getElementById("SBSearchKanzleipostfachsearchdrop")).value));
        if (this.caseId() === "" || this.clerkId() === "") {
            this.errorMessage("Die Pflichtfelder wurden nicht ausgefüllt.");
        } else {
            if(this.newCaseOption()) {
                if(this.Rubrum() === "" || this.Wegen() === "" || this.Referat() === "") {
                    this.errorMessage("Die Pflichtfelder wurden nicht ausgefüllt.");
                } else {
                    await this.assignNewCase();
                }
            } else {
                await this.associateWithCase();
            }
        }
    }

    associateWithCase = async () => {
        switch (this.currentCategory()) {
            case ZPECategories.Emails:
                this.associateEmailWithCase(this.selectedObject());
                $('#DocumentViewerModal').modal('hide');
                break;
            case ZPECategories.BeA:
                this.associateEmailWithCase(this.selectedObject());
                $('#DocumentViewerModal').modal('hide');
                break;
            case ZPECategories.ScanPool:
                this.associateScanPoolWithCase(this.selectedObject());
                $('#DocumentViewerModal').modal('hide');
                break;
            case ZPECategories.Alle:
                if (ko.toJS(this.alleScan() == "alleScan")) {
                    this.associateScanPoolWithCase(this.selectedObject());
                }
                else {
                    this.associateEmailWithCase(this.selectedObject());
                }
                $('#DocumentViewerModal').modal('hide');
                break;
        }

    };

    private showEmail(accountId: string, mailId: string, IsBea: boolean) {
        this.currentViewer("empty-view");
        this.currentViewerParams({
            accountId: accountId,
            mailId: mailId,
            fullscreen: true
        });
        if (IsBea) {
            this.loadBeaMessageById(accountId, mailId);
            this.currentViewer("bea-view");

        }
        else {
            this.currentViewer("mail-view");
        }
        $('#DocumentViewerModal').modal('show');
    }

    newBeaMessage() {
        return ko.observable({
            Subject: ko.observable(''),
            From: ko.observable(''),
            To: ko.observable(''),
            BodyHtml: ko.observable(''),
            Attachments: ko.observableArray([]),
        })
    }

    async deleteMail(accountId: string, mailId: string) {
        let result = await RNSAPI.deleteMailbyId(accountId, mailId);
        if (result.Type === "DeleteSuccessful") {
            this.currentViewer("empty-view");
            this.loadMailData();
        } else {
            alert("Error deleteMail");
        }
    }

    private showScanPoolEntry(fileName: string) {
        this.currentViewer("empty-view");
        this.currentViewerParams({
            fileName: fileName
        });
        this.currentViewer("pdfjs-view");
        this.loadDocumentFromScanPool(fileName);
    }

    async loadDocumentFromScanPool(fileName: string) {
        let result = await RNSAPI.getScanPoolEntryByName(fileName);
        this.blob = Utils.base64ToBlob(result.Payload.Document.DocumentData, "application/pdf");
        let pdfData = Utils.base64ToUint8Array(result.Payload.Document.DocumentData);
        this.loadPdf(pdfData);
        this.print();
    }

    private loadPdf(pdfData) {
        let pdf = PDFJS.getDocument(pdfData);
        pdf.then((document) => this.loadPage(document, 1));
    }

    print = () => {
        let fileURL = URL.createObjectURL(this.blob);
        this.url(fileURL);
    }

    downloadAttachment = async (id, name) => {
        if (this.source === Source.ECase) {
            let blob = Utils.base64ToBlob(this.BeAMessage().Attachments.filter(attachment => attachment.AttachmentId === id)[0].AttachmentData);
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            document.body.appendChild(link);
            link.download = name;
            link.click();
            document.body.removeChild(link);
        } else {
            let accountId = this.BeAMessage().AccountID;
            let rnsMailId = this.BeAMessage().RnsMailID;
            let attachmentId = id;
            let req = new XMLHttpRequest();
            req.open("POST", `${RNSAPI.base}/ZPE/GetAttachment`, true);
            req.setRequestHeader("Content-Type", "application/json");
            req.setRequestHeader('access_token', RNSAPI.Token);
            req.responseType = "blob";

            req.onload = function (event) {
                let blob = req.response;
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                document.body.appendChild(link);
                link.download = name;
                link.click();
                document.body.removeChild(link);
            };

            let obj = {
                AccountID: accountId,
                RnsMailID: rnsMailId,
                AttachmentID: attachmentId
            }

            req.send(JSON.stringify(obj));

        }
    }

    private loadPage(pdfDocument, pageNumber: number) {
        let SCALE = 0.75;

        this.pdfDocument = pdfDocument;
        this.maximumPageNumber(pdfDocument.numPages);
        $("#pdfContainer").empty();
        var container = document.getElementById('pdfContainer');
        return pdfDocument.getPage(pageNumber).then(function (pdfPage) {
            var pdfPageView = new (PDFJS as any).PDFPageView({
                container: container,
                id: pageNumber,
                scale: SCALE,
                defaultViewport: pdfPage.getViewport(SCALE),
                textLayerFactory: new (PDFJS as any).DefaultTextLayerFactory(),
            });
            pdfPageView.setPdfPage(pdfPage);
            return pdfPageView.draw();
        });
    }
    maximumPageNumber = ko.observable(1);
    pdfDocument = null;
    showAlle = () => {
        this.currentCategory(ZPECategories.Alle);
    };
    showEmails = () => {
        this.currentCategory(ZPECategories.Emails);
    };

    showScanPool = () => {
        this.currentCategory(ZPECategories.ScanPool);
    };

    showBeA = () => {
        this.currentCategory(ZPECategories.BeA);
        this.beABool(true);
    }

    showEConsult = () => {
        this.currentCategory(ZPECategories.EConsult);
    };

    showDebris = () => {
        this.currentCategory(ZPECategories.Debris);
    };

    constructor() {
        this.isViewerRole(RolesAndRights.isViewerRole());
        this.loadZPEData();
        this.interval.subscribe(newInterval => this.loadZPEData());
        this.BeAMessage(this.newBeaMessage());
        this.loadAlleData();
        this.suggestRegisterNumber();
        Postbox.publisher().subscribe((Entry) => { this.getAdressesForCase(Entry) }, "adressesForCase");
    }
}

let html = fs.readFileSync(__dirname + '/zpe.html', 'utf8');

ko.components.register("zpe-view", {
    viewModel: ZPEViewModel,
    template: html
});