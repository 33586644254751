export let layoutFamilienrecht = JSON.parse(`{
  "Identifikationsdaten": [
    [
      {
        "Label": "Identifikationsdaten",
        "Type": "HEADLINE",
        "Size": 12,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Registernummer*",
        "Database": "",
        "DbText": "LRegnr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "Registernummer",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 13,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Referat*",
        "Database": "",
        "DbText": "LblReferat",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "Referatnummer",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 2,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Nein/Undefiniert",
        "DbText": "Zuo_refnr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Sachbearbeiter*",
        "Database": "",
        "DbText": "LblSachbearbeiter",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "SachbearbeiterId",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 2,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sachbearbeiter",
        "SelectionProperty": "Sachbearbeiter_ID"
      },
      {
        "Label": "",
        "Database": "Nein/Undefiniert",
        "DbText": "Zuo_sachnr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Sachbearbeiter",
        "SelectionProperty": "Sachbearbeiter"
      }
    ],
    [
      {
        "Label": "Anlagedatum",
        "Database": "",
        "DbText": "lblAnlagedatum",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "AnlageDatum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 10,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Rubrum*",
        "Database": "",
        "DbText": "LblRubrum",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "Rubrum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 64,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Wegen*",
        "Database": "",
        "DbText": "LblWegen",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "Wegen",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 64,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Kennz. Ausl Mandant",
        "Database": "",
        "DbText": "LKausmd",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "IsAuslaendischesMandat",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 1,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Kennz. Beitr. Eigene",
        "Database": "",
        "DbText": "Lkbeiei",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "IsEigeneBeitreibung",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 1,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "VorSt. Abzugsber. Mdt.",
        "Database": "",
        "DbText": "LKvstab",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": null,
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 1,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Mandant ist Kläger",
        "Database": "",
        "DbText": "LMdtKläger",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": null,
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 1,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "AZ Mandant",
        "Database": "",
        "DbText": "LAzmdt",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": null,
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Organisationsdaten": [
    [
      {
        "Label": "Organisationsdaten",
        "Type": "HEADLINE",
        "Size": 12,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "WV Grund",
        "Database": "",
        "DbText": "LblWVGrund",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "FristNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Nein/Undefiniert",
        "DbText": "Zuo_frinr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "WV Ablauf",
        "Database": "",
        "DbText": "LblWVAblauf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "FristDatum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 10,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Sachstand",
        "Database": "",
        "DbText": "LblSachbestand",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": null,
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Nein/Undefiniert",
        "DbText": "Zuo_Sachstand",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Mahnkennung",
        "Database": "",
        "DbText": "Lmahnnr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "MahnNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Nein/Undefiniert",
        "DbText": "Zuo_Mahnnr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Gegenstandswert ",
        "Database": "",
        "DbText": "LWert1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": null,
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 12,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "MEK",
        "Database": "",
        "DbText": "LMEK",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Akte",
        "DbText": "MEK",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 12,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Mandatsherkunft",
        "Database": "",
        "DbText": "LblMandat",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Values": [
          "",
          "Empfehlung",
          "Homepage/Internet",
          "Telefonbuch",
          "Regionale Anzeige",
          "Rechtsberater Kontaktformular",
          "Rechtsberater Hilfehotline",
          "Rechtsberater Anwaltshotline",
          "ADAC",
          "Sonstiges"
        ],
        "Label": "",
        "Database": "XML/Default",
        "DbText": "mandat",
        "Type": "COMBOBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Sachverhalt": [
    {
      "Name": "Allgemein",
      "Elements": [
        [
          {
            "Label": "Mandantenbegehren",
            "Database": "",
            "DbText": "LblMandantenbegehren",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Notiz_Mandantenbegehren",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Notiz",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sachverhalt",
            "Database": "",
            "DbText": "LblSachverhalt",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Notiz_Sachverhalt",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Notiz",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fallkonstellation",
            "Database": "",
            "DbText": "LblFallkonstellation",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Fallkonstellation",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Notiz",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Abrechnungsdaten",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zahlungsart",
            "Database": "",
            "DbText": "LblZahlungsart",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Selbstzahler",
              "Rechtsschutzversicherung",
              "Prozesskostenhilfe",
              "RECHT-nah Card"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Zahlungsart",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ertragsverteilung",
            "Database": "",
            "DbText": "LblErtragsverteilung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "100%",
              "50% : 50%",
              "30% : 70%"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Ertragsverteilung",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Nur bei Rechtsschutzversicherung:",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Versicherung",
            "Database": "",
            "DbText": "LblVersicherung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Versicherung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 20,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Versicherungsnummer",
            "Database": "",
            "DbText": "LblVersicherungsnr",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Versicherungsnr",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 30,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Selbstbeteiligung",
            "Database": "",
            "DbText": "LblSelbstbeteiligung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Selbstbeteiligung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 30,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schadensnummer",
            "Database": "",
            "DbText": "LblSchadensnummer",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Schadensnummer",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 30,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Deckungszusage erhalten",
            "Database": "",
            "DbText": "LblBereits_Deckungszusage",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja ": "Ja_Deckungszusage_erhalten",
              "Nein ": "Nein_Deckungszusage_erhalten"
            },
            "Default": "True",
            "Label": "Deckungszusage_erhalten",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Nur bei RECHT-nah Card",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kartennummer",
            "Database": "",
            "DbText": "LblRechtNahCard_Kartennummer",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "RechtNahCard_Kartennummer",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Belege / Dokumente:",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kopie Personalausweis",
            "Database": "XML/Default",
            "DbText": "ChkDok_Personalausweiskopie",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schreiben Übernahme VKH-Kosten",
            "Database": "XML/Default",
            "DbText": "ChkDok_SchreibenZuProzesskostenuebernahme",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Deckungszusage",
            "Database": "XML/Default",
            "DbText": "ChkDok_Deckungszusage",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Berechtigungsschein",
            "Database": "XML/Default",
            "DbText": "ChkDok_Berechtigungsschein",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Anzahl Kinder",
            "Database": "",
            "DbText": "LblAnzahl_Kinder",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "Fats/Familiensachen",
            "DbText": "kindanzahl",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Der erziehende Elternteil ist",
            "Database": "",
            "DbText": "LblDer_erzihende_Elternteil_ist",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Mutter",
              "Vater"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Der_erzihende_Elternteil_ist",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Aufenthalt Kinder bei:",
            "Database": "",
            "DbText": "LblAufenthalt_Kinder",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Mandant",
              "Gegner",
              "Sonstige"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Aufenthalt_Kinder",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wer erhält das Kindergeld ?",
            "Database": "",
            "DbText": "LblWer_erhaelt_das_Kindergeld",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "Fats/Familiensachen",
            "DbText": "werkindgeld",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Unterbringung Kinder",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kindergrippe",
            "Database": "",
            "DbText": "LblKindergrippe",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Kindergrippe",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kindergarten",
            "Database": "",
            "DbText": "LblKindergarten",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Kindergarten",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schule (Klasse)",
            "Database": "",
            "DbText": "LblSchule_Klasse",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Schule_Klasse",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Daten Kinder",
            "Database": "",
            "DbText": "LblDaten_Kinder_Ehe",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Headers": [
              "Name",
              "Vorname",
              "Geburtsdatum",
              "Geburtsort",
              "Eigenes Einkommen",
              "Eigenes Vermögen"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Daten_Kinder_Ehe",
            "Type": "TABLE",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Weitere Kinder Mandant",
            "Database": "",
            "DbText": "LblWeitere_Kinder_Mandant",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Headers": [
              "Name",
              "Vorname",
              "Anschrift",
              "Geburtsdatum",
              "Geburtsort"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Weitere_Kinder_Mandant",
            "Type": "TABLE",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Weitere Kinder Gegner",
            "Database": "",
            "DbText": "LblWeitere_Kinder_Gegner",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Headers": [
              "Name",
              "Vorname",
              "Anschrift",
              "Geburtsdatum",
              "Geburtsort"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Weitere_Kinder_Gegner",
            "Type": "TABLE",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Aufenthalt Kinder bei:",
            "Database": "",
            "DbText": "LblAufenthalt_weiterer_Kinder",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Mandant",
              "Gegner",
              "Sonstige"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Aufenthalt_weiterer_Kinder",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kindesmutter/-vater",
            "Database": "",
            "DbText": "LblKindesmutter_vater",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Kindesmutter_vater",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Anschrift Kindesmutter/-vater",
            "Database": "",
            "DbText": "LblAnschrift_Kindesmutter_vater",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Anschrift_Kindesmutter_vater",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Aufenthalt weiterer Kinder Gegner bei:",
            "Database": "",
            "DbText": "LblAufenthalt_weiterer_Kinder_Gegner",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Mandant",
              "Gegner",
              "Sonstige"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Aufenthalt_weiterer_Kinder_Gegner",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gibt es einen Ehevertrag ?",
            "Database": "",
            "DbText": "LblGibt_es_einen_Ehevertrag",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_Ehevertrag",
              "Nein": "Nein_Ehevertrag"
            },
            "Default": "True",
            "Label": "Ehevertrag",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum",
            "Database": "",
            "DbText": "LblDatum_Ehevertrag",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Ehevertrag",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Welcher Notar ?",
            "Database": "",
            "DbText": "LblEhevertrag_Notar",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Ehevertrag_Notar",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Aktenzeichen des Notars",
            "Database": "",
            "DbText": "LblAktenzeichen_des_Notars",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Aktenzeichen_des_Notars",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Was wurde geregelt:",
            "Database": "",
            "DbText": "LblRegelung_Ehevertrag",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Ehevertrag_Notiz",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Regelung zum Güterrecht ?",
            "Database": "",
            "DbText": "LblRegelung_Gueterrecht",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja ": "Ja_Gueterrecht",
              "Nein": "Nein_Gueterrecht"
            },
            "Default": "True",
            "Label": "Gueterrecht",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Notiz_Güterrecht",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Regelung zum Unterhalt ?",
            "Database": "",
            "DbText": "LblRegelung_zum_Unterhalt",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja ": "Ja_Unterhalt",
              "Nein": "Nein_Unterhalt"
            },
            "Default": "True",
            "Label": "Unterhalt",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Notiz_Unterhalt",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Regelung zum Versorgungsausgleich",
            "Database": "",
            "DbText": "LblRegelung_zum_Versorgungsausgleich",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja ": "Ja_Versorgungsausgleich_2",
              "Nein Versorgungsausgleich": "Nein_Versorgungsausgleich_2"
            },
            "Default": "True",
            "Label": "Versorgungsausgleich_2",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Notiz_Versorgungsausgleich",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sonstige Regelungen",
            "Database": "",
            "DbText": "LblSonstige_Regelungen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Sonstiges_Ehevertrag",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Dokumente Ehevertrag",
            "Database": "",
            "DbText": "LblDokumente_Ehevertrag",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Ehevertrag",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Ehevertrag",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Dokumente Einkommensnachweise ",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gehaltsabrechnung der letzten 12 Monate (alternativ Kontoauszüge)",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Gehaltsabrechnung_der_letzten_12_Monate_alternativ_Kontoauszüge",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Aktueller Einkommenssteuerbescheid bzw. Lohnsteuerausgleich",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Aktueller_Einkommenssteuerbescheid_bzw_Lohnsteuerausgleich",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Dazugehörige Einkommenssteuererklärung",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Dazugehoerige_Einkommenssteuererklaerung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Selbständige:",
            "Database": "",
            "DbText": "LblSelbstaendige",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Bilanzen",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Bilanzen_finanz_Reg",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Einnahmen-/Überschussrechnung",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Einnahmen_Überschussrechnung_finanz_Reg",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Einkommenssteuerbescheid der letzten drei Jahre",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Einkommenssteuerbescheid_der_letzten_drei_Jahre",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Dokumente Vermögensnachweise ",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Grundbuchauszug bei Grundeigentum",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Grundbuchauszug_bei_Grundeigentum",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "(Kapital- nicht Risiko-) Lebensversicherungen",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Kapital_nicht_Risiko_Lebensversicherungen",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sparvermögen",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Sparvermoegen",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Aktienvermögen (Depotkonten)",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Aktienvermoegen_Depotkonten",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Bausparverträge",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Bausparvertraege",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sonstige Einnahmen",
            "Database": "",
            "DbText": "LblSonstige_Einnahmen_finanz_Reg",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Sonstige_Einnahmen_finanz_Reg",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Dokumente Schulden ",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Entsprechende Nachweise",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Entsprechende_Nachweise_finanz_Reg",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Dokumente Einkommensnachweise ",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gehaltsabrechnung der letzten 12 Monate (alternativ Kontoauszüge)",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Gehaltsabrechnung_der_letzten_12_Monate_alternativ_Kontoauszüge_G",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Aktueller Einkommenssteuerbescheid bzw. Lohnsteuerausgleich",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Aktueller_Einkommenssteuerbescheid_bzw_Lohnsteuerausgleich_G",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Dazugehörige Einkommenssteuererklärung",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Dazugehoerige_Einkommenssteuererklaerung_G",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Selbständige:",
            "Database": "",
            "DbText": "LblSelbstaendige_G",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Bilanzen",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Bilanzen_finanz_Reg_G",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Einnahmen-/Überschussrechnung",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Einnahmen_Überschussrechnung_finanz_Reg_G",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Einkommenssteuerbescheid der letzten drei Jahre",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Einkommenssteuerbescheid_der_letzten_drei_Jahre_G",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Dokumente Vermögensnachweise ",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Grundbuchauszug bei Grundeigentum",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Grundbuchauszug_bei_Grundeigentum_G",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "(Kapital- nicht Risiko-) Lebensversicherungen",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Kapital_nicht_Risiko_Lebensversicherungen_G",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sparvermögen",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Sparvermoegen_G",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Aktienvermögen (Depotkonten)",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Aktienvermoegen_Depotkonten_G",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Bausparverträge",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Bausparvertraege_G",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sonstige Einnahmen",
            "Database": "",
            "DbText": "LblSonstige_Einnahmen_finanz_Reg_G",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Sonstige_Einnahmen_finanz_Reg_G",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Dokumente Schulden ",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Entsprechende Nachweise",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Entsprechende_Nachweise_finanz_Reg_G",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Trennung und Scheidung",
      "Elements": [
        [
          {
            "Label": "Heiratsdatum*",
            "Database": "",
            "DbText": "LblVorname",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "Fats/Familiensachen",
            "DbText": "heiratdat",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 30,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ehereg. - Nr.*",
            "Database": "",
            "DbText": "Lbl1",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "Fats/Familiensachen",
            "DbText": "heiratregnr",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 30,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Standesamt",
            "Database": "",
            "DbText": "Lblname2",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "Fats/Familiensachen",
            "DbText": "famgericht",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 30,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Heirat: PLZ/Ort",
            "Database": "",
            "DbText": "Lblname3",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "Fats/Familiensachen",
            "DbText": "heiratsort",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 30,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "Fats/Familiensachen",
            "DbText": "heiratsplz",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Dokumente Ehedaten",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Heiratsurkunde",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Heiratsurkunde",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Vom Ehepartner Getrenntlebend",
            "Database": "",
            "DbText": "LblGetrenntlebend",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Vom_Ehepartner_Getrenntlebend",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mit dem Ehepartner in einer gemeinsamen Wohnung lebend",
            "Database": "",
            "DbText": "LblMit_dem_Ehepartner_in_einer_gemeinsamen_Wohnung_lebend",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Mit_dem_Ehepartner_in_einer_gemeinsamen_Wohnung_lebend",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wenn ja: Bitte beschreiben Sie die Art und Weise der Trennung",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Sachverhalt_Trennung_innerhalb_Wohnung",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Trennungsvereinbarung geschlossen",
            "Database": "",
            "DbText": "LblTrennungsvereinbarung_geschlossen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Trennungsvereinbarung_geschlossen",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wenn ja: Datum",
            "Database": "",
            "DbText": "LblWenn_ja_Datum",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Wenn_ja_Datum_Trennungsvereinbarung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wenn eine gemeinsame Wohnung nicht vorliegt, wer ist ausgezogen?",
            "Database": "",
            "DbText": "LblWenn_eine_gemeinsame_Wohnung_nicht_vorliegt_wer_ist_ausgezogen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Mandant",
              "Gegner"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Wenn_eine_gemeinsame_Wohnung_nicht_vorliegt_wer_ist_ausgezogen",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Auszugsdatum",
            "Database": "",
            "DbText": "LblAuszugsdatum",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Auszugsdatum",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Adresse der letzten gemeinsamen Wohnung",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Straße*",
            "Database": "",
            "DbText": "Lbl3",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Gemeinsame_Adresse",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 50,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Postleitzahl* / Ort*",
            "Database": "",
            "DbText": "Lbl4",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "PLZ_Gemeinsamer_Ort",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 8,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Gemeinsamer_Ort",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 50,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zerüttung der Ehe. Bitte schildern Sie die Scheidungsgründe",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Notiz_Scheidungsgruende",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Bereits laufende Scheidungsverfahren",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Anhängiges Scheidungsverfahren",
            "Database": "",
            "DbText": "LblAnhaengiges_Scheidungsverfahren",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Anhaengiges_Scheidungsverfahren",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Aktenzeichen anhängiges Scheidungsverfahren:",
            "Database": "",
            "DbText": "LblAktenzeichen_Scheidungsverfahren",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Aktenzeichen_Scheidungsverfahren",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Einvernehmliche Scheidung",
            "Database": "",
            "DbText": "LblEinvernehmliche_Scheidung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Einvernehmliche_Scheidung",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Bereitschaft zur Wiederherstellung der ehel. Lebensgemeinschaft",
            "Database": "",
            "DbText": "LblBereitschaft_zur_Wiederherstellung_der_ehel_Lebensgemeinschaft",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Bereitschaft_zur_Wiederherstellung_der_ehel_Lebensgemeinschaft",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wenn ja, kurze Schilderung des Sachverhalts",
            "Database": "",
            "DbText": "LblSchilderung_Sachverhalts_LG",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Schilderung_Sachverhalt_LG",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Dokumente Trennung und Scheidung",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Trennungsvereinbarung",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Trennungsvereinbarung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Scheidungsantrag",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Scheidungsantrag",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Bestätigung Einwohnermeldeamt",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Bestaetigung_Einwohnermeldeamt",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sonstiger Schriftverkehr",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Sonstiger_Schriftverkehr",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Härtefallscheidung",
      "Elements": [
        [
          {
            "Label": "Gewalt in der Ehe",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gewalt oder ernstliche Drohungen seitens des Ehepartners",
            "Database": "",
            "DbText": "LblGehen_von_Ihrem_Ehepartner_Gewalt_oder_ernstliche_Drohungen_aus",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Notiz_Gewalt_in_der_Ehe",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Alkohol oder Drogenmissbrauch seitens des Ehepartners",
            "Database": "",
            "DbText": "LblAlkohol_oder_Drogenmissbrauch_seitens_des_Ehepartners",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Alkohol_oder_Drogenmissbrauch_seitens_des_Ehepartners",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Notiz_Drogenmissbrauch",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Anzeigen oder Gerichtsverfahren infolge Gewalt in der Ehe",
            "Database": "",
            "DbText": "LblAnzeigen_oder_Gerichtsverfahren_infolge_Gewalt_in_der_Ehe",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Strafanzeigen",
              "Gerichtsverfahren"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Kam_es_wegen_Gewalt_in_der_Ehe_zu_Anzeigen_oder_Gerichtsverfahren",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Strafanzeige Gerichtsverfahren",
            "Database": "",
            "DbText": "LblDatum_Strafanzeige_Gerichtsverfahren",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Strafanzeige_Gerichtsverfahren",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gewaltschutzantrag gestellt",
            "Database": "",
            "DbText": "LblGewaltschutzantrag_gestellt",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Gewaltschutzantrag_gestellt",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum ",
            "Database": "",
            "DbText": "LblDatum_Gewaltschutzantrag",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Gewaltschutzantrag",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Aktenzeichen",
            "Database": "",
            "DbText": "LblAktenzeichen_Gewaltschutzantrag",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Aktenzeichen_Gewaltschutzantrag",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gründe für Gewaltschutzantrag",
            "Database": "",
            "DbText": "LblGruende_fuer_Gewaltschutzantrag",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Notiz_Gruende_Gewaltschutzantrag",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Leiden die Kinder unter der Ehe ?",
            "Database": "",
            "DbText": "LblKinder_unter_Ehe",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja": "Ja_unter_Ehe",
              "Nein": "Nein_unter_Ehe"
            },
            "Default": "True",
            "Label": "unter_Ehe",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Notiz_Kinder_Ehe",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sonstige Härtefallgründe ?",
            "Database": "",
            "DbText": "LblJa_sonst_Haertefallgruende",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja ": "Ja_sonst_Haertefallgruende",
              "Nein": "Nein_sonst_Haertefallgruende"
            },
            "Default": "True",
            "Label": "sonst_Haertefallgruende",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Notiz_Haertefallgruende",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fallbezogene Dokumente",
            "Database": "",
            "DbText": "LblFallbezogene_Dokumente",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Strafanzeige",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Strafanzeige",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gewaltschutzantrag",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Gewaltschutzantrag",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gewaltprotokolle",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Gewaltprotokolle",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Vernehmungsprotokoll",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Vernehmungsprotokoll",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ärztliche Atteste",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Aerztliche_Atteste_2",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fotos ",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Fotos_2",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gerichtsentscheidungen",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Gerichtsentscheidungen_Haertefallscheidung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ermittlungsakten",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum",
            "Database": "",
            "DbText": "LblDatum_Erm_Akte",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ausstellende Behörde",
            "Database": "",
            "DbText": "LblAusstellende_Behoerde",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Ausstellende_Behörde",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Aktenzeichen",
            "Database": "",
            "DbText": "LblAktenzeichen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Aktenzeichen",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zeugen",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Vorname",
            "Database": "",
            "DbText": "Lbl2",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Vorname",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 30,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Name*",
            "Database": "",
            "DbText": "LblName1",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Name1",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 30,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": "Akte",
            "SelectionProperty": "Name1"
          }
        ],
        [
          {
            "Label": "Straße*",
            "Database": "",
            "DbText": "LblStrasse",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Address",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 50,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": "Akte",
            "SelectionProperty": "Address"
          }
        ],
        [
          {
            "Label": "Postleitzahl* / Ort*",
            "Database": "",
            "DbText": "LblPLZ_Ort",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "ZipCode",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 8,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": "Akte",
            "SelectionProperty": "ZipCode"
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "CityName",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 50,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": "Akte",
            "SelectionProperty": "CityName"
          }
        ],
        [
          {
            "Label": "Dokumente Gewaltschutz",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ärztliche Atteste",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Aerztliche_Atteste",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fotos",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Fotos",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Eidesstaatliche Versicherung",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Eidesstaatliche_Versicherung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gewaltschutzantrag",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Gewaltschutzantrag_2",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Zuweisung Ehewohnung",
      "Elements": [
        [
          {
            "Label": "Schilderung Gründe für Zuweisung Ehewohnung",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Schilderung_Gruende_fuer_Zuweisung_Ehewohnung",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Miets- oder Eigentumswohnung",
            "Database": "",
            "DbText": "LblMiets_oder_Eigentumswohnung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Eigentumswohnung",
              "Mietswohnung"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Miets_oder_Eigentumswohnung",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ehewohnung",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mietvertrag Ehewohnung",
            "Database": "",
            "DbText": "LblMietvertrag_Ehewohnung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Gemeinschaftlicher Mietvertrag",
              "Alleinmieter Mandant",
              "Alleinmieter Gegner"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Mietvertrag_Ehewohnung",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Eigentumswohnung ",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Art des Eigentums",
            "Database": "",
            "DbText": "LblArt_des_Eigentums",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Alleineigentum des Mandanten",
              "Alleineigentum des Gegners",
              "Gemeinschaftseigentum"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Art_des_Eigentums",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Größe der Wohnung in qm",
            "Database": "",
            "DbText": "LblGroesse_der_Wohnung_in_qm",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Groesse_der_Wohnung_in_qm",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Bei Trennung: In der Ehewohnung lebt",
            "Database": "",
            "DbText": "LblBei_Trennung_In_der_Ehewohnung_lebt",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Mandant ",
              "Gegner"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Bei_Trennung_In_der_Ehewohnung_lebt",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mit Kindern in der Ehewohnung lebend",
            "Database": "",
            "DbText": "LblMit_Kindern_in_der_Ehewohnung_lebend",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Mit_Kindern_in_der_Ehewohnung_lebend",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sonstige Wohnverhältnisse ",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Nutzungsregelung bis Ehescheidung ",
            "Database": "",
            "DbText": "LblNutzungsregelung_bis_Ehescheidung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Nutzungsregelung_bis_Ehescheidung",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wenn ja: Festlegung Nutzungsregelung",
            "Database": "",
            "DbText": "LblWenn_ja_Festlegung_Nutzungsregelung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Wenn_ja_Festlegung_Nutzungsregelung",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "In der Wohnung weiterleben soll",
            "Database": "",
            "DbText": "LblIn_der_Wohnung_weiterleben_soll",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Mandant",
              "Gegner"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "In_der_Wohnung_weiterleben_soll",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "In der Wohnung mit KIndern weiterlebend",
            "Database": "",
            "DbText": "LblIn_der_Wohnung_mit_KIndern_weiterlebend",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "In_der_Wohnung_mit_KIndern_weiterlebend",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Trennungsunterhalt",
      "Elements": [
        [
          {
            "Label": "Getrenntlebend vom Ehepartner ",
            "Database": "",
            "DbText": "LblGetrenntlebend_vom_Ehepartner_TU",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Getrenntlebend_vom_Ehepartner_TU",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zahlung Kindes oder sonstigen Unterhalt",
            "Database": "",
            "DbText": "LblZahlt_der_Ehegatte_bereits_Kindes_oder_sonst_Unterhalt_TU",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Zahlt_der_Ehegatte_bereits_Kindes_oder_sonst_Unterhalt_TU",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wenn ja: Höhe Unterhalt",
            "Database": "",
            "DbText": "LblWenn_ja_Hoehe_Unterhalt",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Wenn_ja_Hoehe_Unterhalt",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Geltendmachung Unterhaltsrückstand",
            "Database": "",
            "DbText": "LblGeltendmachung_Unterhaltsrueckstand_TU",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Geltendmachung_Unterhaltsrueckstand_TU",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wenn ja: Aufforderung Ehepartner über Einkünfte/Vermögen",
            "Database": "",
            "DbText": "LblWenn_ja_Aufforderung_Ehepartner_ueber_Einkuenfte_Vermoegen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Wenn_ja_Aufforderung_Ehepartner_ueber_Einkuenfte_Vermoegen",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Höhe Zahlungsrückstand Ehegatte",
            "Database": "",
            "DbText": "LblHoehe_Zahlungsrueckstand_Ehegatte",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Hoehe_Zahlungsrueckstand_Ehegatte",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum der Auskunftsaufforderung",
            "Database": "",
            "DbText": "LblDatum_Auskunftsaufforderung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Datum_Auskunftsaufforderung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Monatliches Gesamteinkommen d. Ehegatten während der Ehe",
            "Database": "",
            "DbText": "LblMonatliches_Gesamteinkommen_d_Ehegatten_Ehe",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Monatliches_Gesamteinkommen_d_Ehegatten_Ehe",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Geltendmachung von Vorsorgeunterhalt",
            "Database": "",
            "DbText": "LblGeltendmachung_von_Vorsorgeunterhalt",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Geltendmachung_von_Vorsorgeunterhalt",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Geltendmachung von Mehrbedarf",
            "Database": "",
            "DbText": "LblGeltendmachung_von_Mehrbedarf",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Geltendmachung_von_Mehrbedarf",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Erwerbstätigkeit möglich",
            "Database": "",
            "DbText": "LblErwerbstaetigkeit_moeglich",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Erwerbstaetigkeit_moeglich",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Dokumente",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Rechnungen ",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Rechnungen_TU",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Atteste",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Atteste_TU",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kontoauszuege",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Kontoauszuege_TU",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gehaltsabrechnung (12 Monate)",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Gehaltsabrechnung_12_Monate",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Vermögensnachweise",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Vermoegensnachweise",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Kindesunterhalt",
      "Elements": [
        [
          {
            "Label": "Kindesunterhalt",
            "Database": "",
            "DbText": "LblTabelle_Kindesunterhalt",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Headers": [
              "Name",
              "Vorname",
              "Geburtsdatum",
              "Ausbildung",
              "Elterliche Sorge bei",
              "Adresse",
              "Eigener Wonhsitz des Kindes",
              "Kindergeld erhält",
              "Höhe des Kindergeldes"
            ],
            "Label": "Kind",
            "Database": "XML/Default",
            "DbText": "Tabelle_Kindesunterhalt",
            "Type": "TABLE",
            "Size": 9,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Dokumente Kindesunterhalt",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Atteste ",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Atteste_KU",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Nachweise ",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Nachweise_KU",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Einkommensnachweise ",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Einkommensnachweise_KU",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Getrenntlebend vom Ehepartner",
            "Database": "",
            "DbText": "LblLGetrenntlebend_vom_Ehepartner",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Leben_Sie_von_Ihren_Ehepartner_getrennt_Kindesunterhalt",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Anhängige Ehesache",
            "Database": "",
            "DbText": "LblAnhaengige_Ehesache_KU",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Anhaengige_Ehesache_KU",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wenn ja: Unterhalt als Folgesache ",
            "Database": "",
            "DbText": "LblWenn_ja_Unterhalt_als_Folgesache",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Wenn_ja_Unterhalt_als_Folgesache",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Rechtskräftige Scheidung",
            "Database": "",
            "DbText": "LblRechtskraeftige_Scheidung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Rechtskraeftige_Scheidung",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Scheidungsdatum",
            "Database": "",
            "DbText": "LblScheidungsdatum_KU",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Scheidungsdatum_KU",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Unterhaltstitel ",
            "Database": "",
            "DbText": "LblUnterhaltstitel_KU",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Unterhaltstitel_KU",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wenn ja: Abaenderungsantrag stellen",
            "Database": "",
            "DbText": "LblWenn_ja_Abaenderungsantrag_stellen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Wenn_ja_Abaenderungsantrag_stellen",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wenn nein: Ehepartner zur Erstellung eines Titels aufgefordert",
            "Database": "",
            "DbText": "LblWenn_nein_Ehepartner_zur_Erstellung_eines_Titels_aufgefordert",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Wenn_nein_Ehepartner_zur_Erstellung_eines_Titels_aufgefordert",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Geltendmachung Unterhaltsrückstand",
            "Database": "",
            "DbText": "LblGeltendmachung_Unterhaltsrueckstand",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Geltendmachung_Unterhaltsrueckstand",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wenn ja: Ehepartner zur Auskunft über Einkünfte/Vermögen aufgefordert worden",
            "Database": "",
            "DbText": "LblWenn_ja_Ehepartner_zur_Auskunft_Einkuenfte_Vermoegen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Wenn_ja_Ehepartner_zur_Auskunft_Einkuenfte_Vermoegen",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Aufforderung",
            "Database": "",
            "DbText": "LblDatum_Aufforderung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Datum_Aufforderung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Sorgerecht",
      "Elements": [
        [
          {
            "Label": "Antrag auf Übertragung d. Sorgerechts",
            "Database": "",
            "DbText": "LblAntrag_auf_Uebertragung_d_Sorgerechts",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Antrag_auf_Uebertragung_d_Sorgerechts",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wenn nein: Bedarf einer Einzelregelung",
            "Database": "",
            "DbText": "LblWenn_nein_Bedarf_einer_Einzelregelung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Wenn_nein_Bedarf_einer_Einzelregelung",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wenn ja: Leben die Eltern getrennt",
            "Database": "",
            "DbText": "LblWenn_ja_Leben_die_Eltern_getrennt",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Wenn_ja_Leben_die_Eltern_getrennt",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kinder in Obhut von",
            "Database": "",
            "DbText": "LblKinder_in_Obhut_von",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Mandant",
              "Gegner",
              "Dritter"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Kinder_in_Obhut_von",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wenn in Obhut Dritter: Adresse",
            "Database": "",
            "DbText": "LblAdresse_Obhut_Dritter",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Adresse_Obhut_Dritter",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schilderung aktueller Sorgerechtsregelung",
            "Database": "",
            "DbText": "LblSchilderung_aktueller_Sorgerechtsregelung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Notiz_Sorgerechtsregelung",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gründe fuer die Übertragung des Sorgerechts",
            "Database": "",
            "DbText": "LblGruende_fuer_die_Uebertragung_des_Sorgerechts",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Notiz_Uebertragung_Sorgerecht",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schilderung Einzelfallregelung",
            "Database": "",
            "DbText": "LblSchilderung_Einzelfallregelung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Notiz_Einzelfallregelung",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sachverhaltsschilderung Gefährdung für das körperliche, geistige, seelische Wohl des Kindes ",
            "Database": "",
            "DbText": "LblGefaehrdung_Kindeswohl",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Kindeswohlgefaehrdung",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sorgerechtsregelung für folgende Kinder",
            "Database": "",
            "DbText": "LblSorgerechtsregelung_fuer_folgende_Kinder",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Headers": [
              "Name",
              "Vorname",
              "Geburtsdatum"
            ],
            "Label": "Kind",
            "Database": "XML/Default",
            "DbText": "Sorgerechtsregelung_fuer_folgende_Kinder",
            "Type": "TABLE",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Umgangsrecht",
      "Elements": [
        [
          {
            "Label": "Zustehen des Sorgerechts für",
            "Database": "",
            "DbText": "LblZustehen_des_Sorgerechts_fuer",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Mandant",
              "Gegner"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Zustehen_des_Sorgerechts_fuer",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Bestehende Umgangsregelung",
            "Database": "",
            "DbText": "LblBestehende_Umgangsregelung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Bestehende_Umgangsregelung",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wenn ja: Inhalt und Handhabung der Regelung",
            "Database": "",
            "DbText": "LblWenn_ja_Inhalt_und_Handhabung_der_Regelung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Wenn_ja_Inhalt_und_Handhabung_der_Regelung",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Titulierung der Regelung",
            "Database": "",
            "DbText": "LblTitulierung_der_Regelung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Titulierung_der_Regelung",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Regelung des Umgangs notwendig",
            "Database": "",
            "DbText": "LblRegelung_des_Umgangs",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Regelung_des_Umgangs",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Teilweise Verweigerung des Umgangs",
            "Database": "",
            "DbText": "LblTeilweise_Verweigerung_des_Umgangs",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Teilweise_Verweigerung_des_Umgangs",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Für welche Kinder (Name, Vorname) ?",
            "Database": "",
            "DbText": "LblRegelung_Umgangsrecht_Kinder",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Regelung_Umgangsrecht_Kinder",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ferienregelung Umgang",
            "Database": "",
            "DbText": "LblFerienregelung_Umgang",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Ferienregelung_Umgang",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wenn ja, welche:",
            "Database": "",
            "DbText": "LblArt_Feinregelung_Umgangsrecht",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Art_Feinregelung_Umgangsrecht",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Regelung des Umgangs für  folgende Kinder",
            "Database": "",
            "DbText": "LblRegelung_des_Umgangs_fuer_folgende_Kinder",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Headers": [
              "Name",
              "Vorname",
              "Geburtsdatum"
            ],
            "Label": "Kind",
            "Database": "XML/Default",
            "DbText": "Regelung_des_Umgangs_fuer_folgende_Kinder",
            "Type": "TABLE",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Dokumente ",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Umgangsregelung",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Umgangsregelung_UR",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gerichtliche Regelung ",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Gerichtliche_Regelung_UR",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schriftverkehr",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Schriftverkehr_UR",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Haushaltsgegenstände",
      "Elements": [
        [
          {
            "Label": "Anhängiges Scheidungsverfahren",
            "Database": "",
            "DbText": "LblAnhaengiges_Scheidungsverfahren_HG",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Anhaengiges_Scheidungsverfahren_HG",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Vorliegen d. Regelung zur Zuweisung der Haushaltsgegenstände ",
            "Database": "",
            "DbText": "LblVorliegen_d_Regelung_zur_Zuweisung_der_Haushaltsgegenstaende",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Vorliegen_d_Regelung_zur_Zuweisung_der_Haushaltsgegenstaende",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wenn ja: Art der Regelung",
            "Database": "",
            "DbText": "LblWenn_ja_Art_der_Regelung_HG",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Wenn_ja_Art_der_Regelung_HG",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zuweisung folgender Haushaltsgeräte",
            "Database": "",
            "DbText": "LblZuweisung_folgender_Haushaltsgeraete",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Zuweisung_folgender_Haushaltsgeraete",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gründe für Zuweisung",
            "Database": "",
            "DbText": "LblGruende_fuer_Zuweisung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Gruende_fuer_Zuweisung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Nachehelicher Unterhalt",
      "Elements": [
        [
          {
            "Label": "Anhängiges Scheidungsverfahren ",
            "Database": "",
            "DbText": "LblAnhaengiges_Scheidungsverfahren_NU",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Anhaengiges_Scheidungsverfahren_NU",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Bestehende Regelung nachehelicher Unterhalt",
            "Database": "",
            "DbText": "LblBestehende_Regelung_nachehel_Unterhalt",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Bestehende_Regelung_nachehel_Unterhalt",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wenn ja: Art der Regelung ",
            "Database": "",
            "DbText": "LblWenn_ja_Art_der_Regelung_NU",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Privatrechtlich",
              "Gerichtlich"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Wenn_ja_Art_der_Regelung_NU",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schilderung des Inhalts der Regelung",
            "Database": "",
            "DbText": "LblSchilderung_der_Regelung_Inhalt_NU",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Schilderung_der_Regelung_Inhalt_NU",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schilderung Lebensverhältnisse während der Elternzeit",
            "Database": "",
            "DbText": "LblSchilderung_Lebensverhaeltnisse_waehrend_der_Elternzeit",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Schilderung_Lebensverhaeltnisse_waehrend_der_Elternzeit",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schilderung aktueller Lebensverhältnisse",
            "Database": "",
            "DbText": "LblSchilderung_aktueller_Lebensverhaeltnisse",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Schilderung_aktueller_Lebensverhaeltnisse",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Erwerbstätigkeit",
            "Database": "",
            "DbText": "LblErwerbstaetigkeit",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Erwerbstaetigkeit",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Anzahl Stunden pro Woche",
            "Database": "",
            "DbText": "LblAnzahl_Stunden_pro_Woche",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Anzahl_Stunden_pro_Woche",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Beantragung nachehelischer Unterhalt",
            "Database": "",
            "DbText": "LblBeantragung_nachehelischer_Unterhalt",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Beantragung_nachehelischer_Unterhalt",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zahlungsrückstand des Ehegatten",
            "Database": "",
            "DbText": "LblZahlungsrueckstand_des_Ehegatten",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Zahlungsrueckstand_des_Ehegatten",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gründe für  nachehelichen Unterhalt:",
            "Database": "",
            "DbText": "LblGruende_nachehelicher_Unterhalt",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kinderbetreuung",
            "Database": "XML/Akte",
            "DbText": "Kinderbetreuung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Alter",
            "Database": "XML/Akte",
            "DbText": "Alter",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Krankheit",
            "Database": "XML/Akte",
            "DbText": "Krankheit",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Erwerbslosigkeit",
            "Database": "XML/Akte",
            "DbText": "Erwerbslosigkeit",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Aufstockungsunterhalt",
            "Database": "XML/Akte",
            "DbText": "Aufstockungsunterhalt",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ausbildungsunterhalt",
            "Database": "XML/Akte",
            "DbText": "Ausbildungsunterhalt",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sonstiges",
            "Database": "",
            "DbText": "Sonstiges_NU",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Zugewinnausgleich",
      "Elements": [
        [
          {
            "Label": "Vermögensverhältnisse d. Mandanten zu Beginn der Ehe",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Vermögen Mandant",
            "Database": "",
            "DbText": "LblVermoegen_Mandant",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Vermoegen_Mandant",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schulden Mandant",
            "Database": "",
            "DbText": "LblSchulden_Mandant",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Schulden_Mandant",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zuerwerb während der Ehezeit Mandant",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Immobilien Mandant",
            "Database": "",
            "DbText": "LblImmobilien_Mandant",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Immobilien_Mandant",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Geld, Wertpapiere etc. Mandant",
            "Database": "",
            "DbText": "LblGeld_Wertpapiere_etc_Mandant",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Geld_Wertpapiere_etc_Mandant",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sonstiges Vermögen Mandant",
            "Database": "",
            "DbText": "LblSonstiges_Vermoegen_Mandant",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Sonstiges_Vermoegen_Mandant",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Endvermögen Mandant",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Vermögen Mandant",
            "Database": "",
            "DbText": "LblVermoegen_Mandant_2",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Endvermoegen_Mandant",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schulden Mandant Mandant 2",
            "Database": "",
            "DbText": "LblSchulden_Mandant_Mandant_2",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Endschulden_Mandant",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Vermögensverhältnisse des Gegners zu Beginn der Ehe",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Vermögen Gegner",
            "Database": "",
            "DbText": "LblVermoegen_Gegner",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Vermoegen_Gegner",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schulden Gegner",
            "Database": "",
            "DbText": "LblSchulden_Gegner",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Schulden_Gegner",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zuerwerb während der Ehezeit Gegner",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Immobilien Gegner",
            "Database": "",
            "DbText": "LblImmobilien_Gegner",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Immobilien_Gegner",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Geld, Wertpapiere etc. Gegner",
            "Database": "",
            "DbText": "LblGeld_Wertpapiere_etc_Gegner",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Geld_Wertpapiere_etc_Gegner",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sonstiges Vermögen Gegner",
            "Database": "",
            "DbText": "LblSonstiges_Vermoegen_Gegner",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Sonstiges_Vermoegen_Gegner",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Endvermögen Gegner",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Vermögen Gegner ",
            "Database": "",
            "DbText": "LblVermoegen_Gegner_2",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Endvermoegen_Gegner",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schulden Gegner ",
            "Database": "",
            "DbText": "LblSchulden_Gegner_2",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Endschulden_Gegner",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Versorgungsausgleich",
      "Elements": [
        [
          {
            "Label": "Regelung Versorgungsausgleich",
            "Database": "",
            "DbText": "LblRegelung_Versorgungsausgleich",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Regelung_Versorgungsausgleich",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wenn ja: Schilderung d. Inhalts der Regelung",
            "Database": "",
            "DbText": "LblWenn_ja_Schilderung_d_Inhalts_der_Regelung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Wenn_ja_Schilderung_d_Inhalts_der_Regelung",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Rentenanwartschaften Mandant",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gesetzliche Rentenversicherung ",
            "Database": "",
            "DbText": "LblGesetzliche_Rentenversicherung_M",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Gesetzliche_Rentenversicherung_M",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Beamtenversorgung",
            "Database": "",
            "DbText": "LblBeamtenversorgung_M",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Beamtenversorgung_M",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zusatzversorgung ",
            "Database": "",
            "DbText": "LblZusatzversorgung_M",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Zusatzversorgung_M",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Betriebliche Altersversorgung",
            "Database": "",
            "DbText": "LblBetriebliche_Altersversorgung_M",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Betriebliche_Altersversorgung_M",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Private Lebensversicherung ",
            "Database": "",
            "DbText": "LblPrivate_Lebensversicherung_M",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Private_Lebensversicherung_M",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Rentenanwartschaften Gegner",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gesetzliche Rentenversicherung ",
            "Database": "",
            "DbText": "LblGesetzliche_Rentenversicherung_G",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Gesetzliche_Rentenversicherung_G",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Beamtenversorgung ",
            "Database": "",
            "DbText": "LblBeamtenversorgung_G",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Beamtenversorgung_G",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zusatzversorgung",
            "Database": "",
            "DbText": "LblZusatzversorgung_G",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Zusatzversorgung_G",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Betriebliche Altersversorgung",
            "Database": "",
            "DbText": "LblBetriebliche_Altersversorgung_G",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Betriebliche_Altersversorgung_G",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Private Lebensversicherung ",
            "Database": "",
            "DbText": "LblPrivate_Lebensversicherung_G",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Private_Lebensversicherung_G",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Erbrechtliche Regelungen",
      "Elements": [
        [
          {
            "Label": "Wurden erbrechtliche Regelungen getroffen ?",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Values": {
              "Ja ": "Ja_erbrechtliche_Regelung",
              "Nein ": "Nein_erbrechtliche_Regelung"
            },
            "Default": "True",
            "Label": "erbrechtliche_Regelung",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Erbrechtliche Regelungen im Ehevertrag",
            "Database": "",
            "DbText": "LblErbrechtliche_Regelungen_Ehevertrag",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja ": "Ja_Erbrechtliche_Regelungen_Ehevertrag",
              "Nein ": "Nein_Erbrechtliche_Regelungen_Ehevertrag"
            },
            "Default": "True",
            "Label": "Erbrechtliche_Regelungen_Ehevertrag",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Erbrechtliche Regelungen Erbvertrag",
            "Database": "",
            "DbText": "LblErbrechtliche_Regelungen_Erbvertrag",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja ": "Ja_Erbrechtliche_Regelungen_Erbvertrag",
              "Nein": "Nein_Erbrechtliche_Regelungen_Erbvertrag"
            },
            "Default": "True",
            "Label": "Erbrechtliche_Regelungen_Erbvertrag",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Erbrechtliche Regelungen Testament",
            "Database": "",
            "DbText": "LblErbrechtliche_Regelungen_Testament",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": {
              "Ja ": "Ja_Erbrechtliche_Regelungen_Testament",
              "Nein ": "Nein_Erbrechtliche_Regelungen_Testament"
            },
            "Default": "True",
            "Label": "Erbrechtliche_Regelungen_Testament",
            "Database": "XML/Default",
            "DbText": "",
            "Type": "RADIOGROUP",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schilderung erbrechtliche Regelungen",
            "Database": "",
            "DbText": "LblSchilderung_erbrechtliche_Regelungen_2",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Akte",
            "DbText": "Schilderung_erbrechtliche_Regelungen",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Dokumente",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ehevertrag",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Ehevertrag_erbrechtliche_Regelung_2",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Erbvertrag ",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Erbvertrag_erbrechtliche_Regelungen",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Testament",
            "Database": "XML/Akte",
            "DbText": "ChkDok_Testament_erbrechtliche_Regelungen",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    }
  ],
  "Mandant": [
    [
      {
        "Label": "Suchbegriff*",
        "Database": "",
        "DbText": "LblSuchbegriff",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Keyword",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Keyword"
      }
    ],
    [
      {
        "Label": "Vorname",
        "Database": "",
        "DbText": "LblVorname",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "FirstName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Name*",
        "Database": "",
        "DbText": "LblName1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Name1"
      }
    ],
    [
      {
        "Label": "Straße*",
        "Database": "",
        "DbText": "LblStrasse",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Address",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Address"
      }
    ],
    [
      {
        "Label": "Postleitzahl* / Ort*",
        "Database": "",
        "DbText": "LblPLZ_Ort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ZipCode",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "ZipCode"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "CityName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "CityName"
      }
    ],
    [
      {
        "Label": "Geburtsdatum",
        "Database": "",
        "DbText": "LblGeburtsdatum_Kopie1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "BirthDate",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Geburtsort",
        "Database": "",
        "DbText": "LblGeburtsort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Mandant",
        "DbText": "Geburtsort",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Wievielte Ehe",
        "Database": "",
        "DbText": "LblWievielte_Ehe",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Mandant",
        "DbText": "Wievielte_Ehe",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Staatsangehörigkeit",
        "Database": "",
        "DbText": "LblStaatsangehoerigkeit",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Familiensachen",
        "DbText": "staatmdt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Beruf",
        "Database": "",
        "DbText": "LblBeruf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Familiensachen",
        "DbText": "berufmdt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "monatl. Bruttoeinkommen",
        "Database": "",
        "DbText": "Lblmonatl_Bruttoeinkommen",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Familiensachen",
        "DbText": "bruttomdt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "monatl. Nettoeinkommen",
        "Database": "",
        "DbText": "Lblmonatl_Nettoeinkommen",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Familiensachen",
        "DbText": "nettomdt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Erwerbstätigkeit",
        "Database": "",
        "DbText": "LblErwerbstaetigkeit_Mandant",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Values": [
          "Arbeitnehmer Vollzeit",
          "Arbeitnehmer Teilzeit",
          "Selbständig",
          "Arbeitssuchend",
          "Rentner"
        ],
        "Label": "",
        "Database": "XML/Mandant",
        "DbText": "Erwerbstaetigkeit_Mandant",
        "Type": "COMBOBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Steuerklasse Mandant",
        "Database": "",
        "DbText": "LblSteuerklasse_Mandant",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Values": [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI"
        ],
        "Label": "",
        "Database": "XML/Mandant",
        "DbText": "Steuerklasse_Mandant",
        "Type": "COMBOBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Grundeigentum",
        "Database": "",
        "DbText": "LblGrundeigentum",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Mandant",
        "DbText": "Grundeigentum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Vermögen",
        "Database": "",
        "DbText": "LblVermoegen",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Mandant",
        "DbText": "mdtVermoegen",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Schulden Mandant",
        "Database": "",
        "DbText": "LblSchulden_Mandant",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Mandant",
        "DbText": "mdtSchulden",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Frühere Scheidungsverfahren/Aktenzeichen",
        "Database": "",
        "DbText": "LblFruehere_Scheidungsverfahren_Aktenzeichen",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Mandant",
        "DbText": "Scheidungsverfahren_Aktenzeichen",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Telefonnummer",
        "Database": "",
        "DbText": "LblTelefonnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Phone1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Phone1"
      }
    ],
    [
      {
        "Label": "Faxnummer",
        "Database": "",
        "DbText": "LblFaxnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Fax",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Fax"
      }
    ],
    [
      {
        "Label": "Handynummer",
        "Database": "",
        "DbText": "LblHandynummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "HandyNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "MobilePhone"
      }
    ],
    [
      {
        "Label": "E-Mail",
        "Database": "",
        "DbText": "Lbllongmail",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "EmailAddress",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "EmailAddress"
      }
    ],
    [
      {
        "Label": "Rechtsform*",
        "Database": "",
        "DbText": "LblLrechtsform",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Rechtsform",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Anrede Briefkopf",
        "Database": "",
        "DbText": "LblAnredeBriefkopf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeBriefkopf",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Anrede"
      }
    ],
    [
      {
        "Label": "Anrede direkt",
        "Database": "",
        "DbText": "LblAnrededirekt",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeDirekt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Briefanrede"
      }
    ],
    [
      {
        "Label": "Kontoinhaber",
        "Database": "",
        "DbText": "LblKontoinhaber",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Mandant",
        "DbText": "Kontoinhaber",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Bankverbindung",
        "Database": "",
        "DbText": "LblBankverbindung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "kurzbez",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 57,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "bank",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      }
    ],
    [
      {
        "Label": "BLZ/BIC",
        "Database": "",
        "DbText": "LblBLZ_BIC",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "blz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BLZ"
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "BIC",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 11,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Konto/IBAN",
        "Database": "",
        "DbText": "LblKonto_IBAN",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ktonr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "IBAN",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 34,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "SEPA Lastschr.",
        "Database": "",
        "DbText": "LblSEPALastschr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "MandatSEPA",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 35,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Datum SEPA",
        "Database": "",
        "DbText": "LblDatumSEPA",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "MandatSEPAdatum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 10,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Abrechnungsdaten",
        "Type": "HEADLINE",
        "Size": 12,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Zahlungsart",
        "Database": "",
        "DbText": "LblZahlungsart",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Values": [
          "",
          "Selbstzahler",
          "Rechtsschutzversicherung",
          "Prozesskostenhilfe"
        ],
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Zahlungsart",
        "Type": "COMBOBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Versicherung",
        "Database": "",
        "DbText": "LblVersicherung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Versicherung",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Versicherungsnummer",
        "Database": "",
        "DbText": "LblVersicherungsnr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Versicherungsnr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Selbstbeteiligung",
        "Database": "",
        "DbText": "LblSelbstbeteiligung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Selbstbeteiligung",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Schadensnummer",
        "Database": "",
        "DbText": "LblSchadensnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Schadensnummer",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Deckungszusage erhalten",
        "Database": "",
        "DbText": "LblBereits_Deckungszusage",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Values": {
          "Ja ": "Ja_Deckungszusage_erhalten",
          "Nein ": "Nein_Deckungszusage_erhalten"
        },
        "Default": "True",
        "Label": "Deckungszusage_erhalten",
        "Database": "XML/Default",
        "DbText": "",
        "Type": "RADIOGROUP",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Belege / Dokumente:",
        "Type": "HEADLINE",
        "Size": 12,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Berechtigungsschein FamR",
        "Database": "XML/Akte",
        "DbText": "ChkDok_Berechtigungsschein_FamR",
        "Type": "CHECKBOX",
        "Size": 3,
        "Skip": 3,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Deckungszusage FamR",
        "Database": "XML/Akte",
        "DbText": "ChkDok_Deckungszusage_FamR",
        "Type": "CHECKBOX",
        "Size": 3,
        "Skip": 3,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Schreiben Übernahme VKH-Kosten",
        "Database": "XML/Default",
        "DbText": "ChkDok_SchreibenZuProzesskostenuebernahme",
        "Type": "CHECKBOX",
        "Size": 3,
        "Skip": 3,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Kopie Personalausweis",
        "Database": "XML/Default",
        "DbText": "ChkDok_Personalausweiskopie",
        "Type": "CHECKBOX",
        "Size": 3,
        "Skip": 3,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Ja",
        "Database": "XML/Mandant",
        "DbText": "Ja_Scheidungsverfahren",
        "Type": "CHECKBOX",
        "Size": 3,
        "Skip": 3,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Gegner": [
    [
      {
        "Label": "Suchbegriff*",
        "Database": "",
        "DbText": "LblSuchbegriff",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Keyword",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Keyword"
      }
    ],
    [
      {
        "Label": "Vorname",
        "Database": "",
        "DbText": "LblVorname",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "FirstName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Name*",
        "Database": "",
        "DbText": "LblName1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Name1"
      }
    ],
    [
      {
        "Label": "Straße*",
        "Database": "",
        "DbText": "LblStrasse",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Address",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Address"
      }
    ],
    [
      {
        "Label": "Postleitzahl* / Ort*",
        "Database": "",
        "DbText": "LblPLZ_Ort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ZipCode",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "ZipCode"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "CityName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "CityName"
      }
    ],
    [
      {
        "Label": "Geburtsdatum",
        "Database": "",
        "DbText": "LblGeburtsdatum_Kopie1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "BirthDate",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Geburtsort",
        "Database": "",
        "DbText": "LblGeburtsort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Geburtsort",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Wievielte Ehe",
        "Database": "",
        "DbText": "LblWievielte_Ehe",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Wievielte_Ehe",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Staatsangehörigkeit",
        "Database": "",
        "DbText": "LblStaatsangehoerigkeit",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Familiensachen",
        "DbText": "staatgeg",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Beruf",
        "Database": "",
        "DbText": "LblBeruf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Familiensachen",
        "DbText": "berufgeg",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "monatl. Bruttoeinkommen",
        "Database": "",
        "DbText": "Lblmonatl_Bruttoeinkommen",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Familiensachen",
        "DbText": "bruttogeg",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "monatl. Nettoeinkommen",
        "Database": "",
        "DbText": "Lblmonatl_Nettoeinkommen",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Familiensachen",
        "DbText": "nettogeg",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Erwerbstätigkeit",
        "Database": "",
        "DbText": "LblErwerbstaetigkeit_Gegner",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Values": [
          "Arbeitnehmer Vollzeit",
          "Arbeitnehmer Teilzeit",
          "Selbständig",
          "Arbeitssuchend",
          "Rentner"
        ],
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Erwerbstaetigkeit_Gegner",
        "Type": "COMBOBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Steuerklasse Gegner",
        "Database": "",
        "DbText": "LblSteuerklasse_Gegner",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Values": [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI"
        ],
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Steuerklasse_Gegner",
        "Type": "COMBOBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Grundeigentum",
        "Database": "",
        "DbText": "LblGrundeigentum",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Grundeigentum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Vermögen",
        "Database": "",
        "DbText": "LblVermoegen",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Vermoegen",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Schulden Gegner",
        "Database": "",
        "DbText": "LblSchulden_Gegner",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Schulden_Gegner",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Frühere Scheidungsverfahren/Aktenzeichen",
        "Database": "",
        "DbText": "LblFruehere_Scheidungsverfahren_Aktenzeichen",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "Ja",
        "Database": "XML/Default",
        "DbText": "Ja_Scheidungsverfahren",
        "Type": "CHECKBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Scheidungsverfahren_Aktenzeichen",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Telefonnummer ",
        "Database": "",
        "DbText": "Lbltel2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Phone1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Phone1"
      }
    ],
    [
      {
        "Label": "Faxnummer",
        "Database": "",
        "DbText": "LblFaxnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Fax",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Fax"
      }
    ],
    [
      {
        "Label": "Handynummer",
        "Database": "",
        "DbText": "LblHandynummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "HandyNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "MobilePhone"
      }
    ],
    [
      {
        "Label": "E-Mail",
        "Database": "",
        "DbText": "Lbllongmail",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "EmailAddress",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "EmailAddress"
      }
    ],
    [
      {
        "Label": "Rechtsform*",
        "Database": "",
        "DbText": "Lblrechtsform",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Rechtsform",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Anrede Briefkopf",
        "Database": "",
        "DbText": "LblAnredeBriefkopf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeBriefkopf",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Anrede"
      }
    ],
    [
      {
        "Label": "Anrede direkt",
        "Database": "",
        "DbText": "LblAnrededirekt",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeDirekt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Briefanrede"
      }
    ],
    [
      {
        "Label": "Kontoinhaber",
        "Database": "",
        "DbText": "LblKontoinhaber",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Kontoinhaber",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Bankverbindung",
        "Database": "",
        "DbText": "LblBankverbindung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "kurzbez",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 57,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "bank",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      }
    ],
    [
      {
        "Label": "BLZ/BIC",
        "Database": "",
        "DbText": "LblBLZ_BIC",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "blz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BLZ"
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "BIC",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 11,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Konto/IBAN",
        "Database": "",
        "DbText": "LblKonto_IBAN",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ktonr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "IBAN",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 34,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "SEPA Lastschr.",
        "Database": "",
        "DbText": "LblSEPALastschr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "MandatSEPA",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 35,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Datum SEPA",
        "Database": "",
        "DbText": "LblDatumSEPA",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "MandatSEPAdatum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 10,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Behorde": [
    [
      {
        "Label": "Suchbegriff*",
        "Database": "",
        "DbText": "LblSuchbegriff",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Keyword",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Keyword"
      }
    ],
    [
      {
        "Label": "Name*",
        "Database": "",
        "DbText": "LblName1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Name1"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lblname2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Name2"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lblname3",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name3",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Name3"
      }
    ],
    [
      {
        "Label": "Straße",
        "Database": "",
        "DbText": "Lblstr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Address",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Address"
      }
    ],
    [
      {
        "Label": "Länderkennzeichen",
        "Database": "",
        "DbText": "Lbllkz",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "lkz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 3,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "CountryID"
      }
    ],
    [
      {
        "Label": "PLZ",
        "Database": "",
        "DbText": "Lblplz",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ZipCode",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "ZipCode"
      }
    ],
    [
      {
        "Label": "Wohnort",
        "Database": "",
        "DbText": "Lblort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "CityName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "CityName"
      }
    ],
    [
      {
        "Label": "Telefon1",
        "Database": "",
        "DbText": "Lbltel1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Phone1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Phone1"
      }
    ],
    [
      {
        "Label": "Telefon2",
        "Database": "",
        "DbText": "Lbltel2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "tel2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Phone2"
      }
    ],
    [
      {
        "Label": "Zentralrufnummer",
        "Database": "",
        "DbText": "Lblzentralrufnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "zentralrufnummer",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "CentralPhone"
      }
    ],
    [
      {
        "Label": "Fax",
        "Database": "",
        "DbText": "Lblfax",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Fax",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Fax"
      }
    ],
    [
      {
        "Label": "Telekommunikation",
        "Database": "",
        "DbText": "Lbltelkom",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "telkom",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "E-Mail",
        "Database": "",
        "DbText": "Lbllongmail",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "EmailAddress",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "EmailAddress"
      }
    ],
    [
      {
        "Label": "Homepage",
        "Database": "",
        "DbText": "Lblhomepage",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "homepage",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "URL"
      }
    ],
    [
      {
        "Headers": [
          "Medium",
          "Nummer"
        ],
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Kommunikationsdaten",
        "Type": "TABLE",
        "Size": 9,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Anrede Briefkopf",
        "Database": "",
        "DbText": "Lblan1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeBriefkopf",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Anrede"
      }
    ],
    [
      {
        "Label": "Anrede direkt",
        "Database": "",
        "DbText": "Lblan2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeDirekt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Briefanrede"
      }
    ],
    [
      {
        "Label": "Bankverbindung",
        "Database": "",
        "DbText": "LblBankverbindung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "kurzbez",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 57,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "bank",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      }
    ],
    [
      {
        "Label": "BLZ/BIC",
        "Database": "",
        "DbText": "LblBLZ_BIC",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "blz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BLZ"
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "bic",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 11,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BIC"
      }
    ],
    [
      {
        "Label": "Konto / IBAN",
        "Database": "",
        "DbText": "LblKonto_IBAN",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ktonr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "iban",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 34,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Gericht": [
    [
      {
        "Label": "Suchbegriff*",
        "Database": "",
        "DbText": "LblSuchbegriff",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Keyword",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Keyword"
      }
    ],
    [
      {
        "Label": "Name*",
        "Database": "",
        "DbText": "LblName1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Name1"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lblname2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Name2"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lblname3",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name3",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Name3"
      }
    ],
    [
      {
        "Label": "Straße",
        "Database": "",
        "DbText": "LblStrasse",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Address",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Address"
      }
    ],
    [
      {
        "Label": "Postleitzahl",
        "Database": "",
        "DbText": "LblPLZ_Ort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ZipCode",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "ZipCode"
      }
    ],
    [
      {
        "Label": "Gerichtsort",
        "Database": "",
        "DbText": "Lblort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "CityName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "CityName"
      }
    ],
    [
      {
        "Label": "Gerichtskennzahl",
        "Database": "",
        "DbText": "LblGerichtsKennzahl",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "GerichtsKennzahl",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Telefonnummer 1",
        "Database": "",
        "DbText": "LblTelefonnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Phone1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Phone1"
      }
    ],
    [
      {
        "Label": "Telefonnummer 2",
        "Database": "",
        "DbText": "Lbltel2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "tel2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Phone2"
      }
    ],
    [
      {
        "Label": "Faxnummer",
        "Database": "",
        "DbText": "LblFaxnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Fax",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Fax"
      }
    ],
    [
      {
        "Label": "Handynummer",
        "Database": "",
        "DbText": "LblHandynummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "HandyNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "MobilePhone"
      }
    ],
    [
      {
        "Label": "E-Mail",
        "Database": "",
        "DbText": "Lbllongmail",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "EmailAddress",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "EmailAddress"
      }
    ],
    [
      {
        "Label": "Homepage",
        "Database": "",
        "DbText": "LblHomepage",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Homepage",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Kommunikationsdaten",
        "Database": "",
        "DbText": "LblKommunikationsdaten",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Headers": [
          "Medium",
          "Nummer"
        ],
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Kommunikationsdaten",
        "Type": "TABLE",
        "Size": 9,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Anrede Briefkopf",
        "Database": "",
        "DbText": "Lblan1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeBriefkopf",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Anrede"
      }
    ],
    [
      {
        "Label": "Anrede direkt",
        "Database": "",
        "DbText": "Lblan2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeDirekt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Briefanrede"
      }
    ],
    [
      {
        "Label": "Bankverbindung",
        "Database": "",
        "DbText": "LblBankverbindung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "kurzbez",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 57,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "bank",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      }
    ],
    [
      {
        "Label": "BLZ/BIC",
        "Database": "",
        "DbText": "LblBLZ_BIC",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "blz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BLZ"
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "bic",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 11,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BIC"
      }
    ],
    [
      {
        "Label": "Konto / IBAN",
        "Database": "",
        "DbText": "LblKonto_IBAN",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ktonr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "iban",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 34,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Weitere": [
    [
      {
        "Label": "Suchbegriff*",
        "Database": "",
        "DbText": "LblSuchbegriff",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Keyword",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Keyword"
      }
    ],
    [
      {
        "Label": "Vorname",
        "Database": "",
        "DbText": "LblVorname",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "FirstName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Name*",
        "Database": "",
        "DbText": "LblName",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Name1"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "MyLabel1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Name2"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lblname3",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name3",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Name3"
      }
    ],
    [
      {
        "Label": "Straße",
        "Database": "",
        "DbText": "LblStrasse",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Address",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Address"
      }
    ],
    [
      {
        "Label": "Postleitzahl / Ort",
        "Database": "",
        "DbText": "LblPLZ_Ort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ZipCode",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "ZipCode"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "CityName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "CityName"
      }
    ],
    [
      {
        "Label": "Telefonnummer 1",
        "Database": "",
        "DbText": "LblTelefonnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Phone1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Phone1"
      }
    ],
    [
      {
        "Label": "Telefonnummer 2",
        "Database": "",
        "DbText": "Lbltel2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "tel2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Phone2"
      }
    ],
    [
      {
        "Label": "Faxnummer",
        "Database": "",
        "DbText": "LblFaxnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Fax",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Fax"
      }
    ],
    [
      {
        "Label": "Handynummer",
        "Database": "",
        "DbText": "LblHandynummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "HandyNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "MobilePhone"
      }
    ],
    [
      {
        "Label": "E-Mail",
        "Database": "",
        "DbText": "Lbllongmail",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "EmailAddress",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "EmailAddress"
      }
    ],
    [
      {
        "Label": "Homepage",
        "Database": "",
        "DbText": "LblHomepage",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Homepage",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Kommunikationsdaten",
        "Database": "",
        "DbText": "LblKommunikationsdaten",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Headers": [
          "Medium",
          "Nummer"
        ],
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Kommunikationsdaten",
        "Type": "TABLE",
        "Size": 9,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Rechtsform*",
        "Database": "",
        "DbText": "Lblrechtsform",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Rechtsform",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Anrede Briefkopf",
        "Database": "",
        "DbText": "LblAnredeBriefkopf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeBriefkopf",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Anrede"
      }
    ],
    [
      {
        "Label": "Anrede direkt",
        "Database": "",
        "DbText": "LblAnrededirekt",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeDirekt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Briefanrede"
      }
    ],
    [
      {
        "Label": "Beteiligungsart",
        "Database": "",
        "DbText": "Lblbetart",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/DefaultZuord",
        "DbText": "Beteiligungsart",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 3,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "",
        "DbText": "Zuo_Beteiligungsart",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Bankverbindung",
        "Database": "",
        "DbText": "LblBankverbindung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "kurzbez",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 57,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "bank",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      }
    ],
    [
      {
        "Label": "BLZ/BIC",
        "Database": "",
        "DbText": "LblBLZ_BIC",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "blz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BLZ"
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "BIC",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 11,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Konto/IBAN",
        "Database": "",
        "DbText": "LblKonto_IBAN",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ktonr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "IBAN",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 34,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "SEPA Lastschr.",
        "Database": "",
        "DbText": "LblSEPALastschr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "MandatSEPA",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 35,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Datum SEPA",
        "Database": "",
        "DbText": "LblDatumSEPA",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "MandatSEPAdatum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 10,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ]
}`);
