import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment"
import { RNSAPI } from "../../api";
import { Moment } from "moment";
import { MainViewModel } from "../../main";
import '../dialog/dialogYN';
import { DateTime } from 'luxon';
import { RolesAndRights } from "../../helpers/RolesAndRights";

class AppointmentDailyViewModel {
    appointments = ko.observableArray([]);
    hideoncreate = ko.observable();
    openAppointment = ko.observable(false);
    appointmentMode = ko.observable();
    appointmentId = ko.observable();
    appointmentData = ko.observable();
    lanes: ko.Computed<Array<Array<any>>>

    ShowMoreLines: ko.Observable<Boolean> = ko.observable(false);

    LaneStruc: ko.ObservableArray<Number> = ko.observableArray([48]);
    LaneVisib: ko.ObservableArray<Number> = ko.observableArray([48]);

    timeslots: ko.ObservableArray<String> = ko.observableArray([]);
    currentDate: ko.Observable<Moment>;
    parentVM: any;

    currentAppointment: any;

    showWVperDay = ko.observableArray([]);

    showDeadlineperDay = ko.observableArray([]);
    predeadlines = ko.observableArray([]);

    wholeDayAppointment = ko.observableArray([]);

    caseAndRubrum = ko.observableArray([]);

    ishalf: ko.Observable<Boolean> = ko.observable(false);

    startAZ = 7;
    endAZ = 19;
    start = 0;
    end = 24;

    MaxWidth = 1;

    isViewerRole = ko.observable<boolean>(false);

    constructDate = (date: string, time: string) => {
        let parsedDate = this.parseDate(date);
        let parsedTime = this.parseTime(time);
        parsedDate.add(parsedTime.hours(), 'hours');
        parsedDate.add(parsedTime.minutes(), 'minutes');
        return parsedDate.toISOString();
    }

    startDate = ko.observable(moment().format("YYYY-MM-DD"));
    endDate = ko.observable(moment().format("YYYY-MM-DD"));

    parseDate = (dateStr: string) => moment.utc(dateStr, "DD.MM.YYYY", true);

    parseTime = (timeStr: string) => moment(timeStr, "HH:mm");

    startTime = ko.observable("00:00");

    endTime = ko.observable("00:00");

    private static pad(n, width) {
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
    }

    private readonly marginInPx = 70;

    private getHalfHours(Time: Moment) {
        let shou = Time.hour();
        let smin = Time.minute();

        shou = shou * 2;
        if (smin >= 30)
            shou++;
        return shou;
    }

    public ishalfhour(data: any) {
        if (data.toString().includes(':30')) {
            return true;
        }
    }

    public left(Element: Object) {
        let start = this.getHalfHours(moment.utc(Element[0].StartDate));
        let end = this.getHalfHours(moment.utc(Element[0].EndDate));

        let MostElements = 0;
        let Width = 1;

        for (let i = start; i < end; i++) {
            if (this.LaneVisib[i] > MostElements)
                MostElements = this.LaneVisib[i];
            this.LaneVisib[i] = this.LaneVisib[i] + 1;
        }
        for (let i = start; i < end; i++) {
            if (this.LaneStruc[i] > Width)
                Width = this.LaneStruc[i];
        }

        return `calc(${(100 / this.MaxWidth) * MostElements}% - ${(this.marginInPx / this.MaxWidth) * MostElements}px)`;
    }
    public sameDay(startDate, endDate) {
        return startDate.isSame(endDate, 'hour')
    }
    public width(Element: Object) {
        const fullWidth = this.sameDay(moment.utc(Element[0].StartDate), moment.utc(Element[0].EndDate))
        let start = this.getHalfHours(moment.utc(Element[0].StartDate));
        let end = this.getHalfHours(moment.utc(Element[0].EndDate));
        let MostElements = 0;
        for (let i = start; i < end; i++) {
            if (this.LaneStruc[i] > MostElements)
                MostElements = this.LaneStruc[i];
        }

        return `calc(${100 / (fullWidth ? 1: MostElements)}% - ${this.marginInPx / (fullWidth ? 1: MostElements)}px)`;
    }

    public top(appointment: any) {
        let m = DateTime.fromISO(appointment.StartDate);
        let offset = m.hour - this.start;
        offset += m.minute / 60;
        return 100 * offset / (this.end - this.start) + "%";
    }

    public height(appointment: any) {
        let startDate = moment.utc(appointment.StartDate);
        let endDate = moment.utc(appointment.EndDate);
        let percentage = 100 * (endDate.diff(startDate, 'minutes') / 60 / (this.end - this.start));
        return `${percentage ? percentage: 2}%`;
    }

    public id(appointment: any) {
        return `${appointment.Id}`
    }

    startMom = ko.observable("");

    public createAppointment = (() => {
        let oldTimeStamp: number = null;
        let oldHour: String = null;
        return (hour: String, event: Event) => {
            if (oldTimeStamp && oldHour && ((event.timeStamp - oldTimeStamp) < 250 && oldHour === hour)) {
                let startMoment = this.currentDate().clone().add(moment.duration(hour as any));
                this.startMom(ko.toJS(this.currentDate().clone().add(moment.duration(hour as any)).toString()));
                let computedEnd = "";
                for (let i = 0; i < this.timeslots().length; i++) {
                    if (hour === ko.toJS(this.timeslots()[i])) {
                        computedEnd = ko.toJS(this.timeslots()[i + 1].toString());
                        this.endTime(ko.toJS(this.timeslots()[i + 1].toString()));
                        break;
                    }
                }
                (<HTMLSelectElement>document.getElementById('StarTimeSelect')).value = ko.toJS(hour.toString());
                (<HTMLSelectElement>document.getElementById('endTimeID')).value = ko.toJS(computedEnd);
                $('#AppointmentTestmodal').modal('show');
            }
            else {
                oldTimeStamp = event.timeStamp;
                oldHour = hour;
            }
        }
    })()

    public ShowAppointmentModal = async (type, data) => {
        if(!this.isViewerRole()) {
            this.openAppointment(false)
            setTimeout(() => {
                this.openAppointment(true)
            }, 10)
            this.appointmentMode(type)
            this.startDate();
            this.endDate(moment().format("YYYY-MM-DD"));
            this.appointmentData({
                startDate: moment(this.currentDate()).format("YYYY-MM-DD"),
                time: data
            })
            setTimeout(() => {
                $("#AppointmentTestmodal").modal("show");
            }, 10)
        }
    }

    public deleteAppointment = async (appointment: any) => {
        this.currentAppointment = appointment;
        $("#DeleteMail").modal('show');
    }

    public actuallydelete = async () => {
        await RNSAPI.deleteAppointment(this.currentAppointment.Id);
        this.parentVM.update();
        $("#DeleteMail").modal('hide');
    }

    public getWVs = async () => {
        let allWV = (await RNSAPI.getResubmissionsByRange({ StartDate: this.currentDate(), EndDate: this.currentDate() })).Payload.Resubmissions;
        this.showWVperDay([]);
        for (let i = 0; i < allWV.length; i++) {
            if (allWV[i].Sachbearbeiter === RNSAPI.User().username && !allWV[i].ErledigtVon) {
                if (allWV[i].Case_ID) {
                    const casedetail = this.caseAndRubrum().find((item) => item.CaseID === allWV[i].Case_ID)
                    if (casedetail)
                        allWV[i].Rubrum = casedetail.Rubrum || ""
                    else
                        allWV[i].Rubrum = "";
                } else {
                    allWV[i].Rubrum = "";
                }
                this.showWVperDay.push(allWV[i]);
            }
        }
    }

    public getFristen = async () => {
        let realTS = moment(this.currentDate(), "X").format('');
        let allDead = (await RNSAPI.getDeadlines(this.currentDate())).Payload.Deadlines;
        this.showDeadlineperDay([]);
        this.predeadlines([])
        for (let i = 0; i < allDead.length; i++) {
            if (this.isOwned(allDead[i].Clerk) && !allDead[i].CompletedBy) {
                if (realTS.includes(allDead[i].Deadline)) {
                    this.showDeadlineperDay.push(allDead[i]);
                }
                if (realTS.includes(allDead[i].PreDeadline)) {
                    this.predeadlines.push(allDead[i]);
                }
            }
        }
    }

    public isOwned =  (clerk) => {
        return (clerk === RNSAPI.User().username)
    }

    async getRubrums() {
        let response = (await RNSAPI.getCasesInfo()).Payload;
        this.caseAndRubrum(response.CaseInfo ? response.CaseInfo : [])
    }

    public setdailyAppointments = async (appointment) => {
        this.wholeDayAppointment.push(appointment);
    }

    constructor(params) {
        this.isViewerRole(RolesAndRights.isViewerRole());
        this.hideoncreate((params && params.hideoncreate) ? true : false);
        let labelArr = [];
        let count = (this.end - this.start) * 2;
        for (let i = 0; i < count; i++) {
            let hour = AppointmentDailyViewModel.pad(Math.floor(i / 2) + this.start, 2);
            if (i % 2 == 0) {
                labelArr.push(`${hour}:00`);
            } else {
                labelArr.push(`${hour}:30`);
            }
        }
        this.timeslots(labelArr);
        this.currentDate = params.date;
        this.appointments = params.appointments;
        this.parentVM = params.vm;
        this.lanes = ko.computed(() => {
            let appointments = this.appointments().sort((a, b) => {
                let startA = moment.utc(a.StartDate);
                let startB = moment.utc(b.StartDate);
                if (startA.isAfter(startB)) return -1;
                else if (startA.isBefore(startB)) return 1;
                else return 0;
            });
            for (let i = 0; i < 48; i++) {
                this.LaneStruc[i] = 0;
                this.LaneVisib[i] = 0;
            }
            let lanes: Array<Array<any>> = [[]];

            this.wholeDayAppointment([]);

            for (let i = 0; i < appointments.length; i++) {
                let appointment = appointments[i];

                if (appointment.StartDate.endsWith("00:00:00") && appointment.EndDate.endsWith("23:59:00")) {
                    this.setdailyAppointments(appointment);
                }
                else {
                    let start = this.getHalfHours(moment.utc(appointment.StartDate));
                    let end = this.getHalfHours(moment.utc(appointment.EndDate));

                    for (let j = start; j < end; j++) {
                        this.LaneStruc[j] = this.LaneStruc[j] + 1;
                    }

                    let inserted = false;
                    for (let lane of lanes) {
                        if (lane.length === 0 || moment(lane[lane.length - 1].EndDate).isBefore(start)) {
                            lane.push(appointment);
                            inserted = true;
                            break;
                        }
                    }
                    if (!inserted) {
                        lanes.push([appointment]);
                    }
                }
            }
            for (let i = 0; i < 48; i++) {
                if (this.MaxWidth < this.LaneStruc[i])
                    this.MaxWidth = this.LaneStruc[i];
            }

            this.getWVs();
            this.getFristen();

            return lanes;
        });

        this.getRubrums();

        $("div, scrollelement").animate({ scrollTop: 575 }, "fast");
    }
}

let html = fs.readFileSync(__dirname + '/daily.html', 'utf8');

ko.components.register("daily-view", {
    viewModel: AppointmentDailyViewModel,
    template: html
});
