import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI } from "../../api";
import "./headline";
import { HeadlineViewModel } from "./headline";
import { MainViewModel } from "../../main";
import { Utils } from "../../utils";
import { ObservableArray } from "knockout";

export enum TreeType {
    PROCESS = "PROCESS",
    CHECKLIST = "CHECKLIST"
}

export function treeTypeText(type: TreeType): string {
    switch (type) {
        case TreeType.CHECKLIST:
            return "Checkliste";
        case TreeType.PROCESS:
            return "Prozess";
    }
}

export class TreeDto {
    public Id: ko.Observable<string>
    public Type: ko.Observable<TreeType>
    public Title: ko.Observable<string>
    public SubTitle: ko.Observable<string>
    public Nodes: ko.ObservableArray<ko.Observable<Headline>>
}

export class Headline {
    public Title: ko.Observable<string>
    public Groups: ko.ObservableArray<ko.Observable<GroupNode>>
}

export class GroupNode {
    public Id: ko.Observable<string>
    public Checked: ko.Observable<boolean>
    public Title: ko.Observable<string>
    public SubTitle: ko.Observable<string>
    public Description: ko.Observable<string>
    public Links: ko.ObservableArray<ko.Observable<string>>
    public More: ko.ObservableArray<ko.Observable<Headline>>
}


class TreeViewModel {
    //Here we use the Edit Mode for BOTH creation and editing
    IsEditMode = ko.observable(false);
    types = ko.observableArray([TreeType.PROCESS, TreeType.CHECKLIST]);
    tree: ko.Observable<TreeDto> = ko.observable(this.createNewProcess());

    treeTypeText = treeTypeText;


    public switchMode() {
        this.IsEditMode(!this.IsEditMode());
    }

    public createNewProcess(): TreeDto {
        let obj: TreeDto = {
            Id: ko.observable(""),
            Type: ko.observable(TreeType.PROCESS),
            Title: ko.observable(""),
            SubTitle: ko.observable(""),
            Nodes: ko.observableArray([])
        }

        return obj;
    }

    public createHeadlineHandler() {
        this.tree().Nodes.push(ko.observable(HeadlineViewModel.createHeadline()));
    }

    public deleteHeadlineHandler(index: ko.Observable<number>) {
        return this.tree().Nodes.splice(index(), 1);
    }

    async postData() {
        console.log(ko.toJSON(this.tree));
        let result = await RNSAPI.addOrUpdateTree(this.tree());
        MainViewModel.RoutingTable.showTreeOverview();
    }

    public static parseTree(tree: any): TreeDto {
        return {
            Id: ko.observable(tree.Id),
            SubTitle: ko.observable(tree.SubTitle),
            Type: ko.observable(tree.Type),
            Title: ko.observable(tree.Title),
            Nodes: ko.observableArray(tree.Nodes.map(n => TreeViewModel.parseHeadline(n)))
        }
    }

    public static parseHeadline(headline: any): ko.Observable<Headline> {
        return ko.observable({
            Title: ko.observable(headline.Title),
            Groups: ko.observableArray(headline.Groups.map(g => TreeViewModel.parseGroup(g)))
        }) as ko.Observable<Headline>;
    }

    public static parseGroup(group: any): ko.Observable<GroupNode> {
        return ko.observable({
            Id: ko.observable(group.Id),
            Checked: ko.observable(group.Checked),
            Title: ko.observable(group.Title),
            SubTitle: ko.observable(group.SubTitle),
            Description: ko.observable(group.Description),
            Links: ko.observableArray(group.Links.map(l => ko.observable(l))),
            More: ko.observableArray(group.More.map(h => TreeViewModel.parseHeadline(h)))
        }) as ko.Observable<GroupNode>;
    }

    async loadTree(type: TreeType, id: string) {
        let result = await RNSAPI.getTreeByTypeAndId(type, id);
        if (result.Type === "TreeResult") {
            this.tree(TreeViewModel.parseTree(result.Payload.Tree));
        }
    }

    public generateComputable(index: ko.Computed<number>) {
        return ko.computed(() => {
            return `${(index() + 1)}.`;
        });
    }

    constructor(params: any) {
        if (params && params.id && params.type) {
            this.loadTree(params.type, params.id)
        }

        this.IsEditMode(true);
        HeadlineViewModel.loadTemplates();
    }
}

let html = fs.readFileSync(__dirname + '/tree.html', 'utf8');

ko.components.register("tree-view", {
    viewModel: TreeViewModel,
    template: html
});
