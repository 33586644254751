import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment"
import { RNSAPI } from "../../api";
import { Utils } from "../../utils";
import { MainViewModel } from "../../main";
import '../dialog/dialog';
import { DialogViewModel } from '../dialog/dialog';

class AppointmentModalViewModel {
    addressTypes = ko.observableArray(["Mandant", "Gegner", "Sonstige", "Gericht", "Versicherung", "Behörde", "Anwalt"]); //, "Gericht", "Versicherung", "Behörde", "Anwalt"
    type = ko.observable(this.addressTypes()[0]);
     types = ko.observableArray(["Termin"]);
     selectedType = ko.observable(this.types()[0]);

    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalHandleSelection = ko.observable();

    times = [];

    async pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    };

    async pickCase5() {
        let cases = (await RNSAPI.getCases()).Payload.Cases;
        this.pickGeneric("Akte", ["Registernummer", "Rubrum", "Wegen"], ["Akte", "Rubrum", "Wegen"], cases);
        this.modalHandleSelection((selectedObject) => this.basicAppointment().CaseId(selectedObject()["Registernummer"]));
        $('#modal').modal('show');
    };
    pickClerk5 = async () => {
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric("Sachbearbeiter", ["Sachbearbeiter"], ["Sachbearbeiter"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => {
            this.basicAppointment().SachbearbeiterId(selectedObject()["Sachbearbeiter_ID"]); //selectedObject()["Sachbearbeiter_ID"]
            this.basicAppointment().Sachbearbeiter(selectedObject()["Sachbearbeiter"]);
           
        });
        $('#modal').modal('show');
    };
    IsEditMode = ko.observable(false)

    startDate = ko.observable(moment().format("DD.MM.YYYY"));
    startTime = ko.observable("00:00");

    endDate = ko.observable(moment().format("DD.MM.YYYY"));
    endTime = ko.observable("00:00");

    parseDate = (dateStr: string) => moment(dateStr, "DD.MM.YYYY", true);
    parseTime = (timeStr: string) => moment(timeStr, "HH:mm");

    constructDate = (date: string, time: string) => {
        let parsedDate = this.parseDate(date);
        if (this.selectedType() === "Frist") {
            return parsedDate.toISOString();
        } else {
            let parsedTime = this.parseTime(time);
            let hour = parsedTime.hour();
            parsedDate.add(parsedTime.hours(), 'hours');
            parsedDate.add(parsedTime.minutes(), 'minutes');
            return parsedDate.toISOString();
        }
    }

    newBasicAppointment() {
        let obj = {
            AppointmentSubject: ko.observable(null),
            AppointmentDate: ko.computed(() => this.constructDate(this.startDate(), this.startTime())),
            StartDate: ko.computed(() => this.constructDate(this.startDate(), this.startTime())),
            EndDate: ko.computed(() => this.constructDate(this.endDate(), this.endTime())),
            AppointmentType: ko.computed(() => {
                let selectedType = this.selectedType();
                if (selectedType === "Termin") {
                    return "B";
                } else {
                    return "F";
                }
            }),
            Addressee: ko.observable(null),
            AddresseeId: ko.observable(""),
            AddresseeTypeId: ko.observable(""),
            AppointmentNote: ko.observable(""),
            SachbearbeiterId: ko.observable(RNSAPI.User().username),
            Sachbearbeiter: ko.observable(null),
            CaseId: ko.observable(""),
            CreationDate: ko.observable(null),
            CreatorId: ko.observable(""),
            EditedAtDate: ko.observable(null),
            EditorId: ko.observable(""),
            Gericht: ko.observable(null),
            GerichtsId: ko.observable(""),
            IsDeleted: ko.observable(false),
            Lawyer: ko.observable(null),
            LawyerId: ko.observable(RNSAPI.User() ? RNSAPI.User().username : "GR"),
            MeetingPlace: ko.observable(""),
            MeetingPlaceStreet: ko.observable(""),
            Moved: ko.observable(""),
            RecurringAppointmentDto: ko.observable(null),
            RoomID: ko.observable(""),
            IsRecurringAppointment: ko.observable(false)
        };
        obj["AppointmentName"] = ko.computed(() => obj.AppointmentSubject());

        return ko.observable(obj);
    };

    basicAppointment = this.newBasicAppointment();
    

    resetObject(obj) {
        for (let key in obj) {
            obj[key](null);
        }
    }


    checkDate = (str: string) => this.parseDate(str).isValid();
    checkTime = (str: string) => this.parseTime(str).isValid();

    async appointmentModalPostData1() {
        $(".form-group").each(function () { $(this).removeClass("has-error"); });
        if (!Utils.checkErrors(["AppointmentSubject"], this.basicAppointment(), "appointment", [Utils.checkString])) {alert("ungültiger Betreff"); return;}
        else if (!Utils.checkErrors(["startDate", "endDate"], this, "", [Utils.checkString, this.checkDate])) {alert("ungültiges Datum");return;}
        else if (this.selectedType() === "Termin" && !Utils.checkErrors(["startTime", "endTime"], this, "", [Utils.checkString, this.checkTime])) {alert("ungültige Zeit");return;}
        else if (new Date(this.basicAppointment().StartDate()) > new Date(this.basicAppointment().EndDate())) {
            alert("Das Enddatum liegt vor dem Startdatum");
            return;
        }

        console.log(this.startTime);
        console.log(this.endTime);

        if (this.IsEditMode()) {
            try {
                let result = await RNSAPI.updateAppointment(this.basicAppointment());
                if (result.Type === "UpdateSuccessful") {
                    $('#appointmentModal').modal('hide');
                    MainViewModel.RoutingTable.showDashboardView({ view: "appointments" });
                } else {
                    alert("Fehler beim Speichern.");
                }
            } catch (e) {
                console.log(e);
                alert("Fehler beim Speichern: " + e.responseText);
            }
        } else {
            try {
                let result = await RNSAPI.createAppointment(this.basicAppointment());
                if (result.Type === "CreationSuccessful") {
                    $('#appointmentModal').modal('hide');
                    MainViewModel.RoutingTable.showDashboardView({ view: "appointments" });
                } else {
                    alert("Fehler beim Anlegen des Termins.");
                }
            } catch (e) {
                console.log(e);
                alert("Fehler beim Anlegen: " + e.responseText);
            }
        }
    };

    async loadAppointment(id: string) {
        try {
            let result = await RNSAPI.getAppointment(id);

            if (result.Type === "AppointmentFound") {
                let appointment = result.Payload.Appointment;
                let newAppointment = this.newBasicAppointment();
                let obj = newAppointment();

                let excluding = ["AppointmentDate", "AppointmentName", "StartDate", "EndDate", "AppointmentType"];

                for (let key in appointment) {
                    if (obj[key] === undefined) {
                        obj[key] = appointment[key];
                    } else if (excluding.indexOf(key) === -1) {
                        obj[key](appointment[key]);
                    }
                }

                this.basicAppointment(obj);
                let momentStart = moment.utc(appointment.StartDate).local();
                let momentEnd = moment.utc(appointment.EndDate).local();
                this.startDate(momentStart.format("DD.MM.YYYY"));
                this.endDate(momentEnd.format("DD.MM.YYYY"));
                this.startTime(momentStart.format("HH:mm"));
                this.endTime(momentEnd.format("HH:mm"));
            } else {
                alert("Termin nicht gefunden.");
            }
        } catch (e) {
            alert("Verbindung zum Server abgebrochen.");
        }
    }

    adjustDigits(val: number, places: number){
        var str = String(val);
        for(var i = 0; i < places - String(val).length; i++){
            str = "0" + str;
        }
        return str;
    }

    constructor(params: any) {
        params = params || {};
        let id = params.id;
        this.IsEditMode(id !== undefined);

        if (params.startdate) {
            let startMoment = moment.utc(params.startdate);
            this.startDate(startMoment.format("DD.MM.YYYY"));
            this.startTime(startMoment.format("HH:mm"));
            this.endDate(startMoment.format("DD.MM.YYYY"));
            this.endTime(startMoment.format("HH:mm"));
        }

        for(var i = 0; i < 24; i++){
            this.times.push(this.adjustDigits(i, 2)+":"+"00");
            this.times.push(this.adjustDigits(i, 2)+":"+"30");
        }

        if (this.IsEditMode()) {
            this.loadAppointment(id);
        }
    }
}

let html = fs.readFileSync(__dirname + '/appointment_modal.html', 'utf8');

ko.components.register("appointment_modal-view", {
    viewModel: AppointmentModalViewModel,
    template: html
});
