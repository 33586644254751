import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { MainViewModel } from "../../main";
import "../dataTable/dataTable";
import "../extended/new";
import "../appointment/daily";
import "../appointment/weekly";
import "../appointment/weekly_whole";
import "../appointment/monthly";
import "../dialog/appointmentdialog";
import "../deadline/extend";
import "../deadline/delegate";
import "../calendar/calendar";
import "../note/notes";
import "../dialog/dialogYN";
import "../dialog/ConfirmFTWDialog";
import {
    DataTableViewModel,
    IntermediateListItem,
    Color,
} from "../dataTable/dataTable";
import { Utils } from "../../utils";
import * as moment from "moment";
import "../../../node_modules/moment/locale/de.js";
import * as fs from "fs";
import { Interval } from "../workflow/zpe";
import { Transform } from "stream";
import { Filter } from "../case/caseentryoverview";
import { Postbox } from './postbox'
import { RolesAndRights } from "../../helpers/RolesAndRights";

enum Status {
    Done = "E",
    New = "N",
    Running = "L",
    FurtherTerminating = "W",
}
export class DashboardViewModel {
    addressTypes = ko.observableArray([
        "Mandant",
        "Gegner",
        "Sonstige",
        "Gericht",
        "Versicherung",
        "Behörde",
        "Anwalt",
    ]);
    appointments = ko.observableArray([]);
    deadlines = ko.observableArray([]);
    resubmissions = ko.observableArray([]);
    resubmissionLapsetime = ko.observable(null);
    todos = ko.observableArray([]);
    lastWorkedOn = ko.observableArray([]);
    caseAndRubrum = ko.observableArray([]);
    isempty = ko.observable("-/-");
    isemptyDate = ko.observable("TT.MM.JJJJ");
    selectedAddress = ko.observable(this.addressTypes()[0]);
    defaultSachbearbeiter = ko.observable();
    Rubrum = ko.observable("Kein Rubrum vorhanden.");
    days = [];

    AllSB = ko.observableArray();
    newSB = ko.observable("");
    newSB1 = ko.observable("");
    openDashboardAppointment = ko.observable();
    oldDeadline = ko.observable(null);
    extendDeadlineDate = ko.observable();
    appointmentId = ko.observable();
    infoMessage = ko.observable('');
    infoTitle = ko.observable('');
    modialAutoHide = ko.observable(false);

    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    filteredTodos: ko.Computed<any>;
    modalHandleSelection = ko.observable();
    TermiCount = ko.observable(0);

    hasDeadlineManagementRights = ko.observable<boolean>(false);
    isViewerRole = ko.observable<boolean>(false);

    readonly DANGER_COLOR: Color = {
        color: "white",
        "background-color": "#e21b38", //be4259
    };

    wholeday = ko.observable(false);

    currentView: ko.Observable<string>;

    viewTypes = [
        { name: "Tagesansicht", view: "daily-view" },
        { name: "Wochenansicht", view: "weekly-view" },
        { name: "Ganze Woche", view: "weekly-whole-view" },
    ];
    selectedType = ko.observable(this.viewTypes[0].view);

    colHasChanged = ko.observable('daily-view');
    filterByDate = ko.observable('');

    changeCol(obj, event) {
        if (event.originalEvent) {
            this.colHasChanged(this.selectedType());
        }
    }
    pickedWeekDate = ko.observable(moment.utc().startOf("day"));

    today = () => this.pickedWeekDate(moment.utc().startOf("day"));

    nextInterval = () => {
        if (this.selectedType() === "weekly-view") {
            this.pickedWeekDate(this.startOfDay().clone().add(1, "week"));
        } else if (this.selectedType() === "weekly-whole-view") {
            this.pickedWeekDate(this.startOfDay().clone().add(1, "week"));
        }
        else {
            this.pickedWeekDate(this.startOfDay().clone().add(1, "day"));
        }
    };

    previousInterval = () => {
        if (this.selectedType() === "weekly-view") {
            this.pickedWeekDate(this.startOfDay().clone().subtract(1, "week"));
        } else if (this.selectedType() === "weekly-whole-view") {
            this.pickedWeekDate(this.startOfDay().clone().subtract(1, "week"));
        } else {
            this.pickedWeekDate(this.startOfDay().clone().subtract(1, "day"));
        }
    };

    startOfDay = ko.computed(() => {

        let weekDate = this.pickedWeekDate().clone();
        let selectedType = this.selectedType();

        if (selectedType === "weekly-view") {
            return weekDate.startOf("week");
        } else if (selectedType === "weekly-whole-view") {
            return weekDate.startOf("week");
        } else {
            return weekDate;
        }
    });

    endOfDay = ko.computed(() => {
        let weekDate = this.pickedWeekDate().clone();
        let selectedType = this.selectedType();

        if (selectedType === "weekly-view") {
            let startOfWeek = weekDate.startOf("week");
            return startOfWeek.clone().add(4, "days").endOf("day");
        } else if (selectedType === "weekly-whole-view") {
            let startOfWeek = weekDate.startOf("week");
            return startOfWeek.clone().add(6, "days").endOf("day");
        } else {
            return weekDate.endOf("day");
        }
    });

    newAppointment = () => {
        Postbox.publisher().publish({ EditAppointment: false }, "EditAppointmentData");
    };

    intervalText = ko.computed(() => {
        let startOfDay = this.startOfDay().clone();
        let endOfDay = this.endOfDay().clone();
        let selectedType = this.selectedType();

        if (selectedType === "weekly-view") {
            return `KW ${startOfDay.format("W")} \u2013 ${startOfDay.format(
                "D. MMMM"
            )} bis ${endOfDay.format("D. MMMM YYYY")}`;
        } else if (selectedType === "weekly-whole-view") {
            return `KW ${startOfDay.format("W")} \u2013 ${startOfDay.format(
                "D. MMMM"
            )} bis ${endOfDay.format("D. MMMM YYYY")}`;
        } else {
            return startOfDay.format("D. MMMM YYYY");
        }
    });

    startOfThisMonth: moment.Moment;
    startOfNextMonth: moment.Moment;

    deadlineToBeModified = ko.observable(null);

    totalInactive = ko.observable();
    resetFilter() {
        this.filterByDate('')
    }

    constructor(params: any) {
        this.hasDeadlineManagementRights(RolesAndRights.hasDeadlineManagementRights());
        this.isViewerRole(RolesAndRights.isViewerRole());

        moment.locale("de");
        this.currentView = ko.observable(params.view);
        this.currentView = ko.observable("todos");
        if (params.view && params.view === "termin") {
            $("#AppointmentDashboardmodal").modal("show");
        }
        if (params.view && params.view === "frist") {
            $("#NewDeadlineDialog").modal("show");
            this.update();
        }
        this.endOfDay.subscribe(() => this.update());

        this.startOfThisMonth = moment.utc().startOf("month");
        this.startOfNextMonth = moment.utc().startOf("month").add(1, "month");

        for (var i = 0; i < 24; i++) {
            this.times.push(this.adjustDigits(i, 2) + ":" + "00");
            this.times.push(this.adjustDigits(i, 2) + ":" + "30");
        }

        this.update();

        this.getDefaultSachbearbeiter();
        this.oldDeadline = params;
        $(document).ready(function () {
            $("[data-toggle='tooltip']").tooltip();
        });
        Postbox.publisher().subscribe((type) => {
            this.update();
        }, 'updateDashboard')

        Postbox.publisher().subscribe((type) => {
            this.hideAppointmentModal()
        }, 'updatedAppointment')

        Postbox.publisher().subscribe((value) => {
            this.filterByDate(value);
        }, 'changeCalendar')

        this.filteredTodos = ko.computed({
            owner: this,
            read: () => {
                let todos = this.todos()
                if (this.filterByDate() === 'clicked') {
                    todos = this.todos()
                    .filter(todo => moment(todo.date, 'DD.MM.YYYY').isSameOrBefore(this.pickedWeekDate(), 'day'))
                    .map(todo => {
                        if (moment(todo.date, 'DD.MM.YYYY').isSame(this.pickedWeekDate(), 'day')) {
                            return {
                                ...todo,
                                color: undefined
                            }
                        }
                        return todo
                    })
                }
                return todos
            }
        });
    }

    async getDefaultSachbearbeiter() {
        let sach = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(
            (d) => d.Sachbearbeiter_ID === RNSAPI.User().username
        );
        this.akteFrist().SachbearbeiterId(sach[0].Sachbearbeiter_ID);
        this.akteFrist().Sachbearbeiter(sach[0].Sachbearbeiter_ID);
    }

    public getVM = () => this;

    public showAppointments() {
        MainViewModel.RoutingTable.showDashboardView(
            { view: "appointments" },
            true
        );
        this.currentView("appointments");
    }

    public OpebJN() {
        $("#Testmodal").modal("show");
    }

    public showTodos() {
        MainViewModel.RoutingTable.showDashboardView({ view: "todos" }, true);
        this.currentView("todos");
    }

    public showResubmissions() {
        MainViewModel.RoutingTable.showDashboardView(
            { view: "resubmissions" },
            true
        );
        this.currentView("resubmissions");
    }

    public showLastWorkedOn() {
        MainViewModel.RoutingTable.showDashboardView(
            { view: "lastworkedon" },
            true
        );
        this.currentView("lastworkedon");
    }
    hideAppointmentModal() {
        this.openDashboardAppointment(false)
    }
    transformAppointmentData(obj: any, type: string) {
        let color =
            Utils.isToday(moment.utc(obj.StartDate)) && !obj.Completed
                ? this.DANGER_COLOR
                : undefined;
                let actionList = [];
        if(!this.isViewerRole()) {
            actionList.push( {
                icon: "pencil-alt",
                name: "Bearbeiten",
                action: () => {
                    this.IsEditMode(true);
                    this.appointmentId(obj.Id);
                    this.openDashboardAppointment(true);
                    console.log("call");
                    Postbox.publisher().publish({ EditAppointment: true, AppointmentId: obj.Id }, "EditAppointmentData");
                }
            },
            {
                icon: "trash-alt",
                name: "Löschen",
                action: async () => {
                    Postbox.publisher().publish({ ConfirmType: "APPOINTMENT", Reason: obj.AppointmentName, CaseId: "", Id: obj.Id, IsDeleteItem: true }, "ConfirmFTWModalData");
                },
            });
        }
        

        if (!obj.Completed) {
            if(!this.isViewerRole()) {
                actionList.push({
                    icon: "check",
                    name: "Abhaken",
                    action: async () => {
                        Postbox.publisher().publish({ ConfirmType: "APPOINTMENT", Reason: obj.AppointmentName, CaseId: "", Id: obj.Id, IsDeleteItem: false }, "ConfirmFTWModalData");
                    },
                });
            }
        }

        return new IntermediateListItem("", type, nameTable[type], obj.StartDate, obj.AppointmentSubject, obj.CaseId, obj, actionList, color);
    }


    deleteEntryid = ko.observable("");

    public actuallydeleteEntry = async () => {
        await RNSAPI.deleteRecord(ko.toJS(this.deleteEntryid));
        this.update();
        $("#DeleteEntry").modal('hide');
    }

    async changedStartTime() {
        let start = ko.toJS(this.startTime);
        for (let i = 0; i < this.times.length; i++) {
            if (start === this.times[i]) {
                document.getElementById("endEditTimeID")[i + 1].selected = true;
                this.endTime(this.times[i + 1]);
            }
        }
    }

    changedStartDate() {
      if (moment(this.startDate(), 'YYYY-MM-DD').isAfter(moment(this.endDate(), 'YYYY-MM-DD'))) {
        this.endDate(this.startDate());
        this.changedStartTime()
      }
    }

    transformDeadlineData(obj: any, type: string) {
        let check = {
            icon: "check",
            name: "Abschließen",
            action: async () => {
                obj.CompletionDate = "";
                obj.CompletionDate2 = "";
                // await RNSAPI.completeDeadline(obj);
                Postbox.publisher().publish({ ConfirmType: "DEADLINE", Reason: obj.TypeDescription, CaseId: obj.CaseId, Id: obj, IsDeleteItem: false }, "ConfirmFTWModalData");

                // this.update();
            },
        };

        let actionList = [];

        if(this.hasDeadlineManagementRights()) {
            actionList.push({
                icon: "pencil-alt",
                name: "Verlängern",
                action: async () => {
                    this.deadlineToBeModified(obj);
                    $("#extendDeadline").modal("show");
                    $("#extendDeadLineDate").val(
                        this.dateConverter(ko.toJS(obj.Deadline))
                    );
                },
            },
            {
                icon: "user-circle",
                name: "Delegieren",
                action: async () => {
                    this.deadlineToBeModified(obj);
                    $("#delegateDeadlineModal").modal("show");
                    $("#newSB").val(ko.toJS(obj.Deadline));
                },
            });
        }

        if (obj.CompletionDate === null && this.hasDeadlineManagementRights()) {
            if(!this.isViewerRole()) {
                actionList.push(check);
            }
        }

        var DeadlineX = Number(moment(obj.Deadline).format("YYYYMMDD"));
        var Heutee = Number(moment(moment()).format("YYYYMMDD")); 
        var OneDay = 86400;

        let color = Utils.isToday(moment.utc(obj.Deadline))
            ? this.DANGER_COLOR
            : undefined;
        return new IntermediateListItem(
            "",
            type,
            nameTable[type],
            obj.Deadline,
            obj.TypeDescription,
            obj.CaseId,
            obj,
            actionList,
            color
        );
    }

    transformResubmissionData(rObj: any, type: string) {
        let actionList = [];

        if(!this.isViewerRole()) {
            actionList.push({
                icon: "check",
                name: "Abschließen",
                action: async () => {
                    Postbox.publisher().publish({ ConfirmType: "RESUBMISSION", Reason: rObj.DeadLineReason, CaseId: rObj.Case_ID, Id: rObj.ID, IsDeleteItem: false }, "ConfirmFTWModalData");
                },
            },
            {
                icon: "pencil-alt",
                name: "Bearbeiten",
                action: async () => {
                    $("#extendResubmissionModal").modal("show");
                    this.akteExtendResubmission(ko.toJS(rObj));
                    rObj.DeadLineDate = moment(rObj.DeadLineDate).format("YYYY-MM-DD");
                    $("#wiedervorlage").val(rObj.DeadLineDate);
                },
            },
            {
                icon: "user-circle",
                name: "Delegieren",
                action: async () => {
                    this.akteExtendResubmission(ko.toJS(rObj));
                    $("#delegateResubmissionModal").modal("show");
                    $("#delegate").val(rObj.Sachbearbeiter);
                },
            });
        }

        let color = Utils.isToday(moment.utc(rObj.DeadLineDate))
            ? this.DANGER_COLOR
            : undefined;


        
        return new IntermediateListItem(
            "",
            type,
            nameTable[type],
            rObj.DeadLineDate,
            rObj.DeadLineReason,
            rObj.Case_ID || rObj.Sachbearbeiter,
            rObj,
            actionList,
            color
        );
    }

    transformECasesData(obj: any, type: string) {
        let actionList = [];
        if(!this.isViewerRole()) {
            actionList.push({
                icon: "pencil-alt",
                name: "Bearbeiten",
                action: async () =>
                    MainViewModel.RoutingTable.showCaseEntryView({
                        caseId: obj.CaseId,
                        eCaseRecordId: obj.Id,
                    }),
            },
            {
                icon: "trash-alt",
                name: "Löschen",
                action: async () => {
                    $("#DeleteEntry").modal('show');
                    this.deleteEntryid(obj.Id);
                },
            },
            {
                icon: "eye",
                name: "Betrachten",
                action: async () => {
                    MainViewModel.RoutingTable.showCaseEntriesView({
                        caseId: obj.CaseId,
                        eCaseRecordId: obj.Id,
                    });
                },
            });
        }
        return new IntermediateListItem(
            "",
            type,
            obj.WorkflowId,
            obj.LastChange || obj.DocumentDate || obj.RecordDate,
            obj.Subject,
            obj.CaseId,
            obj,
            actionList
        );
    }

    transformZPEData(obj: any, type: string) {
        let actionList = [];
        if(!this.isViewerRole()) {
            actionList.push( {
                icon: "eye",
                name: "Betrachten",
                action: async () => {
                    MainViewModel.RoutingTable.showMailView({
                        accountId: obj.EMailAccountID,
                        mailId: obj.RNSMail_ID,
                    });
                },
            });
        }
        return new IntermediateListItem(
            " ",
            type,
            obj.WorkflowId,
            obj.EMailDate,
            obj.Subject,
            obj.Sender,
            obj,
            actionList
        );
    }
    constructAppointmentDate(date: string, type: string) {
        const parsedDate = this.parseDate(date)
        let parsedTime = this.parseTime(type === 'start' ? '00:00': '23:59')
        parsedDate.add(parsedTime.hours(), "hours");
        parsedDate.add(parsedTime.minutes(), "minutes");
        return parsedDate.toISOString();
    }

    async updateAppointments(allAppointments: any) {
        try {
            let appointments = allAppointments.Payload.Appointments.map((a) => ({
                ...a,
            })).filter((a: any) => a.AppointmentType !== "F")
            let transformed = appointments.map((obj) => {
                if(!this.isViewerRole()) {
                    obj.actionHandlers = [
                        {
                            icon: "pencil-alt",
                            name: "Bearbeiten",
                            action: () => {
                                Postbox.publisher().publish({ EditAppointment: true, AppointmentId: obj.Id }, "EditAppointmentData");
                            },
                        },
                        {
                            icon: "trash-alt",
                            name: "Löschen",
                            action: async () => {
                                Postbox.publisher().publish({ ConfirmType: "APPOINTMENT", Reason: obj.AppointmentName, CaseId: "", Id: obj.Id, IsDeleteItem: true }, "ConfirmFTWModalData");
                            },
                        },
                    ];
                }
                return obj;
            });
            this.appointments(transformed);
        } catch (e) {
            console.log("Could not retrieve appointments.");
            console.log(e);
        }
    }

    async updateDeadlines(allDeadlines: any) {
        try {
            let deadlines = allDeadlines.Payload.Deadlines.map((d) => ({
                ...d,
            })).filter((d) => d.CompletionDate === null);

            let deadlinesSorted = deadlines.sort((d1, d2) => {
                if (moment.utc(d1.Deadline).isBefore(moment.utc(d2.Deadline))) {
                    return 1;
                } else {
                    return -1;
                }
            });

            let transformed = deadlinesSorted.map((obj) => {
                let copy = $.extend(true, {}, obj);
                if(!this.isViewerRole()) {
                    obj.columnActions = {
                        CaseId: MainViewModel.RoutingTable.generateLink(
                            `/new/${encodeURIComponent(obj.CaseId)}`
                        ),
                    };
                }


                obj.actionHandlers = [];

                if(this.hasDeadlineManagementRights() && !this.isViewerRole()) {
                    obj.actionHandlers.push({
                        icon: "pencil-alt",
                        name: "Verlängern",
                        action: async () => {
                            this.deadlineToBeModified(copy);
                            this.basicDeadline(obj);

                            $("#extendDeadlineModal").modal("show");
                            $("#extendDeadLineDate").val(
                                this.dateConverter(ko.toJS(obj.Deadline))
                            );
                        },
                    },
                    {
                        icon: "user-circle",
                        name: "Delegieren",
                        action: async () => {
                            $("#delegateDeadlineModal").modal("show");
                            $("#newSB").val(ko.toJS(obj.Clerk));
                            var obj1 = {};
                            for (let key in obj1) {
                                obj1[key] = ko.observable(obj[key]);
                            }
                            this.basicDeadline(obj);
                        },
                    },
                    {
                        icon: "check",
                        name: "Abschließen",
                        action: async () => {
                            if (obj.PreDeadline === "Keine Vorfrist") {
                                obj.PreDeadline = "";
                            }
                            obj.CompletionDate = "";
                            obj.CompletionDate2 = "";
                            Postbox.publisher().publish({ ConfirmType: "DEADLINE", Reason: obj.TypeDescription, CaseId: obj.CaseId, Id: obj, IsDeleteItem: false }, "ConfirmFTWModalData");
                        },
                    });
                }


                obj.Deadline = moment(obj.Deadline).format("DD.MM.YYYY");
                obj.PreDeadline = obj.PreDeadline ? moment(obj.PreDeadline).format("DD.MM.YYYY") : "Keine Vorfrist";
                return obj;
            });

            let UserData = await RNSAPI.User();
            let UserRole = UserData.role;

            if (UserRole.toString() === "0") {
                for (let i = 0; i < transformed.length; i++) {
                    if (transformed[i].Clerk !== UserData.username) {
                        transformed.splice(i, 1);
                    }
                }
            }

            let count = 0;

            for (let i = 0; i < transformed.length; i++) {
                if (transformed[i] !== 0) {
                    count++;
                }
            }
            this.TermiCount(count);
            this.deadlines(transformed);
        } catch (e) {
            console.log("Could not retrieve deadlines.");
            console.log(e);
        }
    }
    intervalLength = ko.observable(30);
    interval = ko.pureComputed(() => {
        let length = this.intervalLength();
        let startDate = moment.utc().startOf("day");
        let endDate = moment.utc().endOf("day").add(length, "days");
        return new Interval(startDate, endDate);
    });

    async updateResubmissions() {
        let range = {
            StartDate: this.interval().startDate.toISOString(),
            EndDate: this.interval().endDate.toISOString(),
        };
        let resub = (
            await RNSAPI.getResubmissionsByRange(range)
        ).Payload.Resubmissions.map((r) => ({ ...r })).filter(
            (r: any) => r.Status !== "E"
        );

        let UserData = RNSAPI.User();
        let UserRole = UserData.role;


        if (UserRole.toString() === "0") {
            for (let i = 0; i < resub.length; i++) {
                if (resub[i].Sachbearbeiter !== UserData.username) {
                    resub.splice(i, 1);
                }
            }
        }

        this.totalInactive = resub.lenght;
        let resubmissions = resub.sort((d1, d2) => {
            if (moment.utc(d1.DeadLineDate).isBefore(moment.utc(d2.DeadLineDate))) {
                return 1;
            } else {
                return -1;
            }
        });

        resubmissions = resubmissions.reverse();

        this.resubmissions(
            resubmissions.map((r) => {
                let copy = $.extend(true, {}, r);
                r.DeadLineDate = moment.utc(r.DeadLineDate).format("DD.MM.YYYY");
                r.Rubrum = ko.toJS(this.caseAndRubrum().find((item) => item.CaseID == r.Case_ID)).Rubrum;
                var Today = Number(moment().add(5, "days").format("YYYYMMDD"));
                var FristTimestamp = Number(
                    moment(r.DeadLineDate, "MM/DD/YYYY").format("YYYYMMDD")
                );

                r.color = FristTimestamp < Today ? this.DANGER_COLOR : undefined;

                if(!this.isViewerRole()) {
                    r.columnActions = {
                        Case_ID: MainViewModel.RoutingTable.generateLink(
                            `/new/${encodeURIComponent(r.Case_ID)}`
                        ),
                    };
                }

                if (r.Status !== Status.Done) {
                    if(!this.isViewerRole()) {
                        r.actionHandlers = [
                            {
                                icon: "pencil-alt",
                                name: "Bearbeiten",
                                action: () =>
                                {
                                    $("#extendResubmissionModal").modal("show");
                                    var obj = {};
                                    for (let key in r) {
                                        obj[key] = ko.observable(r[key]);
                                    }
                                    this.akteExtendResubmission(obj);
                                    $("#wiedervorlage").val(
                                        this.dateConverter(
                                            this.akteExtendResubmission().DeadLineDate()
                                        )
                                    );
                                 
                                    console.log("When column is selected:");
                                    console.log(ko.toJS(this.akteExtendResubmission));
                                },
                            },
                            {
                                icon: "user-circle",
                                name: "Delegieren",
                                action: async () => {
                                    $("#delegateResubmissionModal").modal("show");
    
                                    var obj = {};
                                    for (let key in r) {
                                        obj[key] = ko.observable(r[key]);
                                    }
                                    this.akteExtendResubmission(obj);
                                    $("#delegate").val(
                                        this.akteExtendResubmission().Sachbearbeiter()
                                    );
                                },
                            },
                        ];
                    }
                }
                let checkAction = {
                    icon: "check",
                    name: "Abschließen",
                    action: async () => {
                        Postbox.publisher().publish({ ConfirmType: "RESUBMISSION", Reason: r.DeadLineReason, CaseId: r.Case_ID, Id: r.ID, IsDeleteItem: false }, "ConfirmFTWModalData");
                    },
                };
                if (r.Status !== Status.Done) {
                    if(!this.isViewerRole()) {
                        r.actionHandlers.push(checkAction);
                    }
                }
                r.Status = (() => {
                    switch (r.Status) {
                        case Status.New:
                            return "N";
                        case Status.Running:
                        case Status.FurtherTerminating:
                            return "L";
                        case Status.Done:
                            return "E";
                        default:
                            return r.Status;
                    }
                })();

                return r;
            })
        );
    }

    dateConverter(date: string) {
        let d = date.split(".");

        return `${d[2]}-${d[1]}-${d[0]}`;
    }

    async updateTodos(
        start: Date,
        end: Date,
        allAppointments: any,
        allDeadlines: any
    ) {
        try {
 
            let appointments = allAppointments.Payload.Appointments.filter(
                (a: any) =>
                    a.AppointmentType !== "F" &&
                    !a.Completed &&
                    Number(moment(a.AppointmentDate).format("YYYYMMDD")) <=
                    Number(moment().format("YYYYMMDD"))
            );

            let deadlines = allDeadlines.Payload.Deadlines.filter(
                (d) =>
                    d.CompletionDate === null
            );

            let transformedAppointments = appointments.map((obj) =>
                this.transformAppointmentData(obj, "appointment")
            );

            let transformedDeadlines = deadlines.map((obj) =>
                this.transformDeadlineData(obj, "deadline")
            );

            // let PreANDdeadelines = JSON.parse(JSON.stringify(transformedDeadlines));

            // let AllPreDead = PreANDdeadelines.map((obj) => {
            //     // let check = {
            //     //     icon: "check",
            //     //     name: "Abschließen",
            //     //     action: async () => {
            //     //         obj.originalObj.CompletionDate = "";
            //     //         obj.originalObj.CompletionDate2 = "";
            //     //         await RNSAPI.completeDeadline(obj.originalObj);
            //     //         this.update();
            //     //     },
            //     // };

            //     let actionList = [];

            //     if(this.hasDeadlineManagementRights() && !this.isViewerRole()) {
            //         actionList.push({
            //             icon: "pencil-alt",
            //             name: "Verlängern",
            //             action: async () => {
            //                 this.deadlineToBeModified(obj);
            //                 $("#extendDeadline").modal("show");
            //                 $("#extendDeadLineDate").val(
            //                     this.dateConverter(ko.toJS(obj.Deadline))
            //                 );
            //             },
            //         },
            //         {
            //             icon: "user-circle",
            //             name: "Delegieren",
            //             action: async () => {
            //                 this.deadlineToBeModified(obj);
            //                 $("#delegateDeadlineModal").modal("show");
            //                 $("#newSB").val(ko.toJS(obj.Deadline));
            //             },
            //         });
            //     }
                
            //     obj.entryType = "predeadline";
            //     // if (obj.originalObj.CompletionDate === null && this.hasDeadlineManagementRights()) {
            //     //     if(!this.isViewerRole()) {
            //     //         actionList.push(check);
            //     //     }
            //     // }
            //     return new IntermediateListItem(
            //         "",
            //         obj.entryType,
            //         nameTable[obj.entryType],
            //         obj.dateObj,
            //         obj.originalObj.TypeDescription,
            //         obj.originalObj.CaseId,
            //         obj,
            //         actionList,
            //         obj.color
            //     );
            // });
            // console.log(AllPreDead);
            // transformedDeadlines = transformedDeadlines.concat(AllPreDead);
            let range = {
                StartDate: this.interval().startDate.toISOString(),
                EndDate: this.interval().endDate.toISOString(),
            };
            let resubmissions = (
                await RNSAPI.getResubmissionsByRange(range)
            ).Payload.Resubmissions.map((r) => ({ ...r })).filter(
                (r: any) =>
                    r.Status !== "E" &&
                    Number(moment(r.DeadLineDate).format("YYYYMMDD")) <=
                    Number(moment().format("YYYYMMDD"))
            );
            let transformedResubmissions = resubmissions.map((obj) =>
                this.transformResubmissionData(obj, "resubmission")
            );

            let UserData = await RNSAPI.User();
            let UserRole = UserData.role;

            let todos = transformedAppointments
                .concat(transformedDeadlines)
                .concat(transformedResubmissions);

            let sortedTodos = todos.sort(
                (t1: IntermediateListItem, t2: IntermediateListItem) => {
                    if (t1.dateObj.isBefore(t2.dateObj)) {
                        return 1;
                    } else {
                        return -1;
                    }
                }
            );

            let transformed = sortedTodos.map((obj) => {
                let copy = $.extend(true, {}, obj);

                var Today = Number(moment().format("YYYYMMDD"));

                for (var i = 0; i <= sortedTodos.length - 1; i++) {
                    var temp = moment(sortedTodos[i].date, "DD.MM.YYYY").format(
                        "YYYYMMDD"
                    );
                    var FristTimestamp = Number(temp);

                    sortedTodos[i].color =
                        FristTimestamp < Today ? this.DANGER_COLOR : undefined;

                    if (
                        sortedTodos[i].entryTypeName === "Frist" ||
                        sortedTodos[i].entryType === "ecase" ||
                        sortedTodos[i].entryTypeName === "Vorfrist" ||
                        sortedTodos[i].entryTypeName === "Wiedervorlage" ||
                        sortedTodos[i].entryTypeName === "Termin"
                    ) {
                        if(!this.isViewerRole()) {
                            sortedTodos[i].columnActions = {
                                number: MainViewModel.RoutingTable.generateLink(
                                    `/new/${encodeURIComponent(sortedTodos[i].number)}`
                                ),
                            };
                        }
                    }
                }

                return obj;
            });

            let FilterForUser = sortedTodos.reverse();
            if (UserRole.toString() === "0") {
                for (let i = 0; i < FilterForUser.length; i++) {
                    if (FilterForUser[i].entryType === "resubmission") {
                        if (
                            FilterForUser[i].originalObj.Sachbearbeiter !== UserData.username
                        ) {
                            FilterForUser.splice(i, 1);
                        }
                    } else if (FilterForUser[i].entryType === "predeadline") {
                        if (
                            FilterForUser[i].originalObj.originalObj.Clerk !==
                            UserData.username
                        ) {
                            FilterForUser.splice(i, 1);
                        }
                    } else if (FilterForUser[i].entryType === "deadline") {
                        if (FilterForUser[i].originalObj.Clerk !== UserData.username) {
                            FilterForUser.splice(i, 1);
                        }
                    } else if (FilterForUser[i].entryType === "ecase") {
                        if (FilterForUser[i].originalObj.LawyerId !== UserData.username) {
                            FilterForUser.splice(i, 1);
                        }
                    }
                }
            }

            for (let i = 0; i < FilterForUser.length; i++) {
                if (FilterForUser[i].rubrum === undefined) {
                    const casedetail = this.caseAndRubrum().find((item) => item.CaseID == FilterForUser[i].number)
                    FilterForUser[i].rubrum = casedetail && casedetail.Rubrum? casedetail.Rubrum: ""
                }
            }

            this.todos(FilterForUser);
        } catch (e) {
            console.log("Could not retrieve todos.");
            console.log(e);
        }
    }

    async updateLastWorkedOn() {
        let cases = (await RNSAPI.getLastCases(10)).Payload.Cases;
        let transformed = cases.map((obj) => {
            if (obj.Case !== "") {
                if(!this.isViewerRole()) {
                    obj.actionHandlers = [
                        {
                            icon: "folder-open",
                            name: "Akte öffnen",
                            action: () => {
                                MainViewModel.RoutingTable.showNewView({ caseId: obj.Case })
                            }
                        },
                    ];
                    obj.columnActions = {
                        number: MainViewModel.RoutingTable.generateLink(
                            `/new/${encodeURIComponent(obj.Case)}`
                        ),
                    };
                }
            }
            return obj;
        });
        this.lastWorkedOn(transformed);
    }

    async update() {
        let allAppointments = await RNSAPI.getAppointments(
            this.startOfDay().toDate(),
            this.endOfDay().toDate()
        );

        let response = (await RNSAPI.getCasesInfo()).Payload;
        this.caseAndRubrum(response.CaseInfo ? response.CaseInfo : [])

        for (let appointment of allAppointments.Payload.Appointments) {
            if (appointment["CaseId"]) {
                const casedetail = this.caseAndRubrum().find((item) => item.CaseID === appointment["CaseId"])
                appointment["Rubrum"] = casedetail.Rubrum || ""
            } else {
                appointment["Rubrum"] = "";
            }
            if (appointment["AppointmentType"] === "B")
                appointment["AppointmentType"] = "Besprechung";
            else if (appointment["AppointmentType"] === "G")
                appointment["AppointmentType"] = "Gerichtstermin";
            else if (appointment["AppointmentType"] === "A")
                appointment["AppointmentType"] = "Ausser Haus";
            else if (appointment["AppointmentType"] === "V")
                appointment["AppointmentType"] = "Verkündungstermin";
            else if (appointment["AppointmentType"] === "P")
                appointment["AppointmentType"] = "Privat";
            else if (appointment["AppointmentType"] === "D")
                appointment["AppointmentType"] = "Dauertermin";
            else if (appointment["AppointmentType"] === "N")
                appointment["AppointmentType"] = "Notar";
        }
        let allDeadlines = await RNSAPI.getDeadlinesByRange(this.startOfDay().clone().startOf("week"), moment(this.startOfDay()).clone().startOf("week").add(4, "days"));
        this.updateAppointments(allAppointments);
        this.updateDeadlines(allDeadlines);
        this.updateTodos(
            this.startOfDay().toDate(),
            this.endOfDay().toDate(),
            allAppointments,
            allDeadlines
        );
        this.updateLastWorkedOn();
        this.updateResubmissions();
    }
    workDayOffset(startDate, endDate) {
        let startWeekDay = startDate.clone();
        if(startDate.day() != 0){ //Not Sunday
            startWeekDay = startWeekDay.subtract(startDate.day(), 'day');
        }
        else{
            startWeekDay = startWeekDay.subtract(1, 'w');
        }
        let endWeekDay = endDate.clone();
        if(endDate.day() != 0){
            endWeekDay = endWeekDay.add(7 - endDate.day(), 'day');
        }
        let diff = endWeekDay.diff(startWeekDay, 'days');
        let weekends = 2 * (Math.floor(diff/7)-1);
        return weekends;

    };

    newAkteFrist() {
        let obj = {
            SachbearbeiterId: ko.observable(RNSAPI.User().username),
            Sachbearbeiter: ko.observable(null),
            Clerk: ko.observable(null),
            CaseId: ko.observable(null),
            Rubrum: ko.observable("Kein Rubrum vorhanden."),
            DeadlineNumber: ko.observable("-/-"),
            DeadlineText: ko.observable(null),
            CompletedBy: ko.observable(""),
            CompletionDate: ko.observable(""),
            CreatedBy: ko.observable(""),
            StartDate: ko.observable(moment().format("YYYY-MM-DD")),
            PreDeadline: ko.computed(() => {
                if (this.predeadlineNr().startsWith("w")) {
                    let weeks = this.predeadlineNr()[1] + this.predeadlineNr()[2];
                    let today = moment().format("YYYY-MM-DD");
                    let FristDay = moment(this.akteFrist().StartDate(), "YYYY-MM-DD").add(
                        weeks,
                        "w"
                    );
                    return FristDay.format("YYYY-MM-DD");
                } else if (this.predeadlineNr().startsWith("m")) {
                    let months = this.predeadlineNr()[1] + this.predeadlineNr()[2];
                    let today = moment().format("YYYY-MM-DD");
                    console.log('akte: ', ko.toJS(this.akteFrist()))
                    let FristDay = moment(this.akteFrist().StartDate(), "YYYY-MM-DD").add(
                        months,
                        "M"
                    );
                    return FristDay.format("YYYY-MM-DD");
                } else if (this.predeadlineNr().startsWith("j")) {
                    let year = this.predeadlineNr()[1] + this.predeadlineNr()[2];
                    let today = moment().format("YYYY-MM-DD");
                    let FristDay = moment(this.akteFrist().StartDate(), "YYYY-MM-DD").add(
                        year,
                        "y"
                    );
                    return FristDay.format("YYYY-MM-DD");
                } else {
                    if (this.deadlineNr().startsWith("w")) {
                        let weeks = this.deadlineNr()[1] + this.deadlineNr()[2];
                        let today = moment().format("YYYY-MM-DD");
                        let FristDay = moment(
                            this.akteFrist().StartDate(),
                            "YYYY-MM-DD"
                        ).add(weeks, "w");
                        if (FristDay.day() == 0) {
                            FristDay.add(1, "day");
                        } else if (FristDay.day() == 6) {
                            FristDay.add(2, "day");
                        } else {
                            FristDay.add(1, "day");
                        }
                        return FristDay.format("YYYY-MM-DD");
                    } else if (this.deadlineNr().startsWith("m")) {
                        let months = this.deadlineNr()[1] + this.deadlineNr()[2];
                        let today = moment().format("YYYY-MM-DD");
                        let FristDay = moment(
                            this.akteFrist().StartDate(),
                            "YYYY-MM-DD"
                        ).add(months, "M");
                        if (FristDay.day() == 0) {
                            FristDay.add(1, "day");
                        } else if (FristDay.day() == 6) {
                            FristDay.add(2, "day");
                        }
                        return FristDay.format("YYYY-MM-DD");
                    } else if (this.deadlineNr().startsWith("j")) {
                        let year = this.deadlineNr()[1] + this.deadlineNr()[2];
                        let today = moment().format("YYYY-MM-DD");
                        let FristDay = moment(
                            this.akteFrist().StartDate(),
                            "YYYY-MM-DD"
                        ).add(year, "y");
                        if (FristDay.day() == 0) {
                            FristDay.add(1, "day");
                        } else if (FristDay.day() == 6) {
                            FristDay.add(2, "day");
                        }
                        return FristDay.format("YYYY-MM-DD");
                    } else {
                    }

                    return moment().format("YYYY-MM-DD");
                }
            }),
            TheDeadline: ko.computed(() => {
                if (this.deadlineNr().startsWith("w")) {
                    let weeks = this.deadlineNr()[1] + this.deadlineNr()[2];
                    let today = moment().format("YYYY-MM-DD");
                    let FristDay = moment(this.akteFrist().StartDate(), "YYYY-MM-DD").add(
                        weeks,
                        "w"
                    );
                    if (FristDay.day() == 0) {
                        FristDay.add(1, "day");
                    } else if (FristDay.day() == 6) {
                        FristDay.add(2, "day");
                    }
                    return FristDay.format("YYYY-MM-DD");
                } else if (this.deadlineNr().startsWith("m")) {
                    let months = this.deadlineNr()[1] + this.deadlineNr()[2];
                    let today = moment().format("YYYY-MM-DD");
                    let FristDay = moment(this.akteFrist().StartDate(), "YYYY-MM-DD").add(
                        months,
                        "M"
                    );
                    if (FristDay.day() == 0) {
                        FristDay.add(1, "day");
                    } else if (FristDay.day() == 6) {
                        FristDay.add(2, "day");
                    }
                    return FristDay.format("YYYY-MM-DD");
                } else if (this.deadlineNr().startsWith("j")) {
                    let year = this.deadlineNr()[1] + this.deadlineNr()[2];
                    let today = moment().format("YYYY-MM-DD");
                    let FristDay = moment(this.akteFrist().StartDate(), "YYYY-MM-DD").add(
                        year,
                        "y"
                    );
                    if (FristDay.day() == 0) {
                        FristDay.add(1, "day");
                    } else if (FristDay.day() == 6) {
                        FristDay.add(2, "day");
                    }
                    return FristDay.format("YYYY-MM-DD");
                } else {
                }
            }),
        };
        return ko.observable(obj);
    }

    deadlineNr = ko.observable("");
    predeadlineNr = ko.observable("");
    IsEditMode = ko.observable(false);

    akteFrist = this.newAkteFrist();
    basicDeadline = this.akteFrist;
    IsDeadlineNecessary = ko.observable(true);

    DeadLineNr = ko.observable("");

    deadlineCreationDate = ko.observable(moment().format("YYYY-MM-DD"));
    wiedervorlage = ko.observable(moment().format("YYYY-MM-DD"));
    deadlineDeadlineDate = ko.computed(() => {
        if (this.deadlineNr().startsWith("w")) {
            let weeks = this.deadlineNr()[1] + this.deadlineNr()[2];
            let today = moment().format("YYYY-MM-DD");
            let FristDay = moment(this.deadlineCreationDate(), "YYYY-MM-DD").add(
                weeks,
                "w"
            );
            let offset = this.workDayOffset(FristDay.clone(), moment(this.deadlineCreationDate(), "YYYY-MM-DD"));
            if (FristDay.day() == 0) {
                FristDay.add(1, "day");
            } else if (FristDay.day() == 6) {
                FristDay.add(2, "day");
            }
            return FristDay.format("YYYY-MM-DD");
        } else if (this.deadlineNr().startsWith("m")) {
            let months = this.deadlineNr()[1] + this.deadlineNr()[2];
            let today = moment().format("YYYY-MM-DD");
            let FristDay = moment(this.deadlineCreationDate(), "YYYY-MM-DD").add(
                months,
                "M"
            );
            if (FristDay.day() == 0) {
                FristDay.add(1, "day");
            } else if (FristDay.day() == 6) {
                FristDay.add(2, "day");
            }
            return FristDay.format("YYYY-MM-DD");
        } else if (this.deadlineNr().startsWith("j")) {
            let year = this.deadlineNr()[1] + this.deadlineNr()[2];
            let today = moment().format("YYYY-MM-DD");
            let FristDay = moment(this.deadlineCreationDate(), "YYYY-MM-DD").add(
                year,
                "y"
            );
            if (FristDay.day() == 0) {
                FristDay.add(1, "day");
            } else if (FristDay.day() == 6) {
                FristDay.add(2, "day");
            }
            return FristDay.format("YYYY-MM-DD");
        } else if(this.deadlineNr().startsWith("t")){
            let days = this.deadlineNr()[1] + this.deadlineNr()[2];
            let today = moment().format("YYYY-MM-DD");
            let FristDay = moment(this.deadlineCreationDate(), "YYYY-MM-DD").add(
                days,
                "day"
            );
            let offset = this.workDayOffset(FristDay, moment(this.deadlineCreationDate(), "YYYY-MM-DD"));
            FristDay.add(offset, "day");
            if (FristDay.day() == 0) {
                FristDay.add(1, "day");
            } else if (FristDay.day() == 6) {
                FristDay.add(2, "day");
            }
            return FristDay.format("YYYY-MM-DD");
        }
    });
    deadlinePrelineDate = ko.computed(() => {
        if (this.predeadlineNr().startsWith("w")) {
            let weeks = this.predeadlineNr()[1] + this.predeadlineNr()[2];
            let FristDay = moment(this.deadlineCreationDate(), "YYYY-MM-DD").add(
                weeks,
                "w"
            );
            return FristDay.format("YYYY-MM-DD");
        } else if (this.predeadlineNr().startsWith("m")) {
            let months = this.predeadlineNr()[1] + this.predeadlineNr()[2];
            let FristDay = moment(this.deadlineCreationDate(), "YYYY-MM-DD").add(
                months,
                "M"
            );
            return FristDay.format("YYYY-MM-DD");
        } else if (this.predeadlineNr().startsWith("j")) {
            let year = this.predeadlineNr()[1] + this.predeadlineNr()[2];
            let FristDay = moment(this.deadlineCreationDate(), "YYYY-MM-DD").add(
                year,
                "y"
            );
            return FristDay.format("YYYY-MM-DD");
        } else {
            if (this.deadlineNr().startsWith("w")) {
                let weeks = this.deadlineNr()[1] + this.deadlineNr()[2];
                let x = parseInt(this.deadlineNr()[2]) - 1;
                weeks = this.deadlineNr()[1] + x;
                let today = moment().format("YYYY-MM-DD");
                let FristDay = moment(this.deadlineCreationDate(), "YYYY-MM-DD").add(
                    weeks,
                    "w"
                );
                if (FristDay.day() == 0) {
                    FristDay.add(1, "day");
                } else if (FristDay.day() == 6) {
                    FristDay.add(2, "day");
                }

                return FristDay.format("YYYY-MM-DD");
            } else if (this.deadlineNr().startsWith("m")) {
                let months = this.deadlineNr()[1] + this.deadlineNr()[2];
                let today = moment().format("YYYY-MM-DD");
                console.log("here is the months", months);
                let FristDay = moment(this.deadlineCreationDate(), "YYYY-MM-DD").add(
                    months,
                    "M"
                );
                console.log("the firstDay is ", FristDay.format("YYYY-MM-DD"));
                if (FristDay.day() == 0) {
                    FristDay.add(1, "day");
                } else if (FristDay.day() == 6) {
                    FristDay.add(2, "day");
                }
                FristDay.add(-7, "day");
                return FristDay.format("YYYY-MM-DD");
            } else if (this.deadlineNr().startsWith("j")) {
                let year = this.deadlineNr()[1] + this.deadlineNr()[2];
                let today = moment().format("YYYY-MM-DD");
                let FristDay = moment(this.deadlineCreationDate(), "YYYY-MM-DD").add(
                    year,
                    "y"
                );
                if (FristDay.day() == 0) {
                    FristDay.add(1, "day");
                } else if (FristDay.day() == 6) {
                    FristDay.add(2, "day");
                } else {
                    FristDay.add(-7, "day");
                }
                return FristDay.format("YYYY-MM-DD");
            }  else if(this.deadlineNr().startsWith("t")){
                let days = this.deadlineNr()[1] + this.deadlineNr()[2];
                let today = moment().format("YYYY-MM-DD");
                let FristDay = moment(this.deadlineCreationDate(), "YYYY-MM-DD").add(
                    days,
                    "d"
                );
                if (FristDay.day() == 0) {
                    FristDay.add(1, "day");
                } else if (FristDay.day() == 6) {
                    FristDay.add(2, "day");
                }
                return FristDay.format("YYYY-MM-DD");
            }
        }
    });
    creationDate = ko.observable(moment().format("YYYY-MM-DD"));
    deadlineDate = ko.computed(() => {
        if (ko.toJS(this.DeadLineNr()) == "") {
            return moment().format("YYYY-MM-DD");
        } else {
            let total_days = Number(ko.toJS(this.DeadLineNr())) * 7;
            if (ko.toJS(this.akteResubmission().DeadLineNr) == "m1") {
                total_days = 30;
            }
            if (!isNaN(total_days)) {
                let potential_date = moment(
                    ko.toJS(this.creationDate()),
                    "YYYY-MM-DD"
                ).add(total_days, "days");
                if (potential_date.day() == 0) {
                    potential_date.subtract(2, "day");
                } else if (potential_date.day() == 6) {
                    potential_date.subtract(1, "day");
                }
                return potential_date.format("YYYY-MM-DD");
            }
        }
    });

    parseDate = (dateStr: string) => moment.utc(dateStr, "DD.MM.YYYY", true);

    newAkteResubmission() {
        let rObj = {
            AdressTyp: ko.observable(""),
            AngelegtVon: ko.observable(""),
            AnlageDatum: ko.computed(() =>
                this.parseDate(
                    moment(this.creationDate(), "YYYY-MM-DD").format("DD.MM.YYYY")
                ).toISOString()
            ),
            Case_ID: ko.observable(""),
            Comment: ko.observable(""),
            Copies: ko.observable(""),
            DeadLineDate: ko.computed(() =>
                this.parseDate(
                    moment(this.deadlineDate(), "YYYY-MM-DD").format("DD.MM.YYYY")
                ).toISOString()
            ),
            DeadLineNr: ko.observable(""),
            DeadLineReason: ko.observable(""),
            EDAVerfahren: ko.observable(false),
            ErledigtDatum: ko.observable(null),
            ErledigtVon: ko.observable(""),
            ForderungskontoDrucken: ko.observable(false),
            Formulardruk: ko.observable(false),
            HonorarForderung: ko.observable(false),
            Keyword: ko.observable(""),
            PapierArtID: ko.observable(""),
            Quotelung: ko.observable(0),
            Referat: ko.observable(""),
            Sachbearbeiter: ko.observable(
                RNSAPI.User() ? RNSAPI.User().username : "GR"
            ),
            Standardtext: ko.observable(""),
            Status: ko.observable("N"),
        };
        return ko.observable(rObj);
    }
    akteResubmission = this.newAkteResubmission();
    akteExtendResubmission: ko.Observable<any> = ko.observable();

    async postFrist() {
        this.postFristData();
    }
    async postResubmission() {
        this.postResubmissionData();
    }
    async postFristData() {
        $(".form-group").each(function () {
            $(this).removeClass("has-error");
        });
        if (!Utils.checkErrors(["CaseId", "Sachbearbeiter", 'DeadlineText'], this.akteFrist(), "deadline", [Utils.checkString])) return;

        let deadlineObj = ko.toJS(this.akteFrist());

        deadlineObj.StartDate = moment(deadlineObj.StartDate, "YYYY-MM-DD").format(
            "YYYY-MM-DD"
        );
        deadlineObj.TheDeadline = moment(
            $("#deadlineDeadlineDate").val(),
            "YYYY-MM-DD"
        ).format("YYYY-MM-DD");
        deadlineObj.PreDeadline = moment(
            $("#fristDeadlinePrelineDate").val(),
            "YYYY-MM-DD"
        ).format("YYYY-MM-DD");
        
        try {
            let result = await RNSAPI.createDeadline(deadlineObj);
            if (result.Type === "CreationSuccessful") {
                this.akteFrist().CaseId("");
                this.akteFrist().Sachbearbeiter("");
                this.akteFrist().DeadlineText("");
                let allDeadlines = await RNSAPI.getDeadlines(this.startOfDay());
                RNSAPI.createHistoryEntry(deadlineObj.CaseId, "Frist angelegt");
                this.updateDeadlines(allDeadlines);
                this.updateLastWorkedOn();
                $("#NewDeadlineDialog").modal("hide");
                $('#messageModal').modal('show');

                this.infoTitle('Erledigt')
                this.infoMessage('Daten erfolgreich erfasst.')

                setTimeout(() => {
                    $('#messageModal').modal('hide');
                }, 3000);

            } else {
                this.infoTitle('')
                this.infoTitle('Fehler')
                this.infoMessage('');
                this.infoMessage('Fehler beim Anlegen here.');
                $('#messageModal').modal('show');
            }
        } catch (e) {
            this.infoTitle('')
            this.infoTitle('Fehler')
            this.infoMessage('');
            this.infoMessage('Fehler beim Anlegen here.');
            $('#messageModal').modal('show');
        }
    }


    async addNewTodos() {
        this.postToDosData();
    }

    newTodos() {
        let obj = {
            Clerk: ko.observable(null),
            CaseId: ko.observable(null),
            Rubrum: ko.observable("Kein Rubrum vorhanden."),
            DeadlineText: ko.observable(null),
            TheDeadline: ko.computed(() => {
                if (this.deadlineNr().startsWith("w")) {
                    let weeks = this.deadlineNr()[1] + this.deadlineNr()[2];
                    let today = moment().format("YYYY-MM-DD");
                    let FristDay = moment(this.akteFrist().StartDate(), "YYYY-MM-DD").add(
                        weeks,
                        "w"
                    );
                    if (FristDay.day() == 0) {
                        FristDay.add(1, "day");
                    } else if (FristDay.day() == 6) {
                        FristDay.add(2, "day");
                    }
                    console.log("Date is: ");
                    console.log(FristDay.format("YYYY-MM-DD"));
                    return FristDay.format("YYYY-MM-DD");
                } else if (this.deadlineNr().startsWith("m")) {
                    let months = this.deadlineNr()[1] + this.deadlineNr()[2];
                    let today = moment().format("YYYY-MM-DD");
                    let FristDay = moment(this.akteFrist().StartDate(), "YYYY-MM-DD").add(
                        months,
                        "M"
                    );
                    if (FristDay.day() == 0) {
                        FristDay.add(1, "day");
                    } else if (FristDay.day() == 6) {
                        FristDay.add(2, "day");
                    }
                    console.log("Date is: ");
                    console.log(FristDay.format("YYYY-MM-DD"));
                    return FristDay.format("YYYY-MM-DD");
                } else if (this.deadlineNr().startsWith("j")) {
                    let year = this.deadlineNr()[1] + this.deadlineNr()[2];
                    let today = moment().format("YYYY-MM-DD");
                    let FristDay = moment(this.akteFrist().StartDate(), "YYYY-MM-DD").add(
                        year,
                        "y"
                    );
                    if (FristDay.day() == 0) {
                        FristDay.add(1, "day");
                    } else if (FristDay.day() == 6) {
                        FristDay.add(2, "day");
                    }
                    console.log("Date is: ");
                    console.log(FristDay.format("YYYY-MM-DD"));
                    return FristDay.format("YYYY-MM-DD");
                } else {
                    console.log("DeadlineNr is wrong");
                    console.log(this.deadlineNr());
                    //Kein Präfix = Wochen
                }
            }),
        };
        return ko.observable(obj);
    }

    async postToDosData() {
        $(".form-group").each(function () {
            $(this).removeClass("has-error");
        });
        let akteFrist = ko.toJS(this.akteFrist());
        alert(akteFrist.CaseId);
    }

    checkDate = (str: string) => {return {validation: moment.utc(str).isValid() , message: ' ist Pflichtfeld'}};

    async postResubmissionData() {
        $(".form-group").each(function () {
            $(this).removeClass("has-error");
        });
        let resubmissionObj = ko.toJS(this.akteResubmission());

        console.log("Submitted object:");
        console.log(resubmissionObj);
        resubmissionObj["DeadLineDate"] = moment(
            $("#deadlineDate").val(),
            "YYYY-MM-DD"
        ).toISOString();
        try {
            let result = await RNSAPI.createResubmission(resubmissionObj);
            if (result.Type === "CreateSuccess") {
                this.akteResubmission().Case_ID("");
                this.akteResubmission().Sachbearbeiter("");
                this.akteResubmission().DeadLineNr("");
                this.creationDate(moment().format("YYYY-MM-DD"));
                MainViewModel.RoutingTable.showDashboardView({ view: "resubmissions" });
                let allResubmissions = await RNSAPI.getResubmissionReasons();
                RNSAPI.createHistoryEntry(
                    resubmissionObj.Case_ID,
                    "Wiedervorlage angelegt"
                );
                this.updateResubmissions();
                this.updateLastWorkedOn();
                $("#NewResubmissionDialog").modal("hide");
            } else {
                alert("Fehler beim Anlegen der Wiedervorlage.");
            }
        } catch (e) {
            console.log(e);
            alert("Fehler beim Anlegen : " + e.responseText);
        }
    }

    async extendResubmission() {
        $(".form-group").each(function () {
            $(this).removeClass("has-error");
        });
        this.akteExtendResubmission()["DeadLineDate"] = ko.observable(
            $("#wiedervorlage").val()
        );
        this.akteExtendResubmission()["Status"] = ko.observable("L");
        console.log("When submitting value:");
        console.log(ko.toJS(this.akteExtendResubmission));
        let ecases = (await RNSAPI.getCaseEntriesForWorkFlow("PE")).Payload.Records;
        console.log("e akte:", ecases);
        let resubmissionObj = ko.toJS(this.akteExtendResubmission());
        try {
            console.log(ko.toJS(this.akteExtendResubmission));
            let result = await RNSAPI.updateResubmission(
                ko.toJS(this.akteExtendResubmission())
            );
            if (result.Type === "EditSuccess") {
                $("#extendResubmissionModal").modal("hide");
                MainViewModel.RoutingTable.showDashboardView(
                    { view: "resubmissions" },
                    true
                );
                this.updateResubmissions();
            } else {
                alert("Fehler beim Speichern.");
            }
        } catch (e) {
            console.log(e);
            alert("Fehler beim Speichern: " + e.responseText);
        }
    }

    async extendDeadline() {
        if(this.hasDeadlineManagementRights()) {
            let deadlineObj = ko.toJS(this.akteFrist());
            try {
                let result = await RNSAPI.extendDeadline(
                    deadlineObj,
                    moment(ko.toJS(this.extendDeadlineDate()), "DD.MM.YYYY").format(
                        "YYYY-MM-DD"
                    )
                );
                if (result.Type === "ExtendedSuccessful") {
                    let allDeadlines = await RNSAPI.getDeadlines(this.startOfDay());
                    RNSAPI.createHistoryEntry(deadlineObj.CaseId, "Frist angelegt");
                    this.updateDeadlines(allDeadlines);
                } else {
                    alert("Fehler beim Speichern..");
                }
            } catch (e) {
                alert("Fehler beim ..");
                console.log(e);
            }
            $("#extendDeadlineModal").modal("hide");
        }
    }
    //added new delegate
    async delegateResubmission() {
        $(".form-group").each(function () {
            $(this).removeClass("has-error");
        });
        this.akteExtendResubmission()["Sachbearbeiter"] = ko.observable(
            $("#delegate").val()
        );
        console.log("When submitting value:");
        try {
            const transform = ko.toJS(this.akteExtendResubmission())
            const akteDTO = {}

            for (const key in transform) {
                if (key === 'Case_ID') {
                    akteDTO['CaseID'] = transform[key]
                } else {
                    akteDTO[key] = transform[key]
                }
            }

            let result = await RNSAPI.updateResubmission(akteDTO);
            console.log(result.Type);
            if (result.Type == "EditSuccess") {
                $("#delegateResubmissionModal").modal("hide");
                MainViewModel.RoutingTable.showDashboardView(
                    { view: "resubmissions" },
                    true
                );
                this.currentView("resubmissions");
                this.updateResubmissions();
            } else {
                alert("Fehler beim Speichern.");
            }
        } catch (e) {
            $("#delegateResubmissionModal").modal("hide");
            MainViewModel.RoutingTable.showDashboardView(
                { view: "resubmissions" },
                true
            );
            this.updateResubmissions();
            console.log(e);
        }
    }
    async delegateDeadline() {
        $(".form-group").each(function () {
            $(this).removeClass("has-error");
        });
        let delegateobj = ko.toJS(this.akteFrist());
        console.log("1: ", delegateobj);
        console.log("2: ", this.akteFrist());
        console.log($("#delegateapp").val().toString());
        let keys = [
            "AddressTypeId",
            "CaseId",
            "Clerk",
            "CompletedBy",
            "CompletionDate",
            "CompletionDate2",
            "CreatedBy",
            "CreationDate",
            "Deadline",
            "EditedAt",
            "EditedFromId",
            "ExportToOutlook",
            "IMEX",
            "IsDoubleCheck",
            "IsPasswordProtected",
            "NotaryOrLawyer",
            "Note",
            "Note1",
            "Note2",
            "Note3",
            "Note4",
            "OutlookMessageId",
            "PreDeadline",
            "PrDeadline2",
            "ProcessTime",
            "RecordId",
            "ReplacedWith",
            "ReviewAt",
            "Rubrum",
            "StartDate",
            "TypeDescription",
            "TypeId",
        ];
        let obj = {};
        for (let key in keys) {
            obj[keys[key]] = delegateobj[keys[key]];
        }
        console.log("this is the newly assigned object ", ko.toJS(obj));
        try {
            let result = await RNSAPI.delegateDeadline(
                ko.toJS(obj),
                $("#delegateapp").val().toString()
            );
            this.updateDeadlines(this.today);
            console.log("Result is " + result.Type);
            if (result.Type == "DelegationSuccessful") {
                $("#delegateDeadlineModal").modal("hide");
                MainViewModel.RoutingTable.showDashboardView(
                    { view: "appointments" },
                    true
                );
                this.currentView("appointments");
            } else {
                alert("Fehler beim Speichern.");
            }
        } catch (e) {
            console.log(e);
            console.log("here is the problem: " + e.responseText);
        }
    }
    //desta

    times = [];

    startDate = ko.observable(moment().format("YYYY-MM-DD"));
    startTime = ko.observable("00:00");

    endDate = ko.observable(moment().format("YYYY-MM-DD"));
    endTime = ko.observable("00:00");
    parseTime = (timeStr: string) => moment(timeStr, "HH:mm");
    checkTime = (str: string) => this.parseTime(str).isValid();

    // function for showing new appointment form in modal

    showAppointmentModal() {
        this.basicAppointment().AppointmentSubject("");
        this.basicAppointment().CaseId("");
        this.basicAppointment().AddresseeId("");
        this.basicAppointment().AddresseeTypeId("");
        this.basicAppointment().Addressee("");
        this.basicAppointment().AppointmentNote("");
        this.startDate(moment().format("YYYY-MM-DD"));
        this.endDate(moment().format("YYYY-MM-DD"));
        this.startTime("00:00");
        this.endTime("00:00");
    }

    changeDateFormat = (date: string) => {
        var d = date.split("-");
        return `${d[2]}.${d[1]}.${d[0]}`;
    };

    async appointmentModalPostData() {
        this.startDate(this.changeDateFormat(this.startDate()));
        this.endDate(this.changeDateFormat(this.endDate()));


        $(".form-group").each(function () {
            $(this).removeClass("has-error");
        });

        if (
            !Utils.checkErrors(
                ["AppointmentSubject"],
                this.basicAppointment(),
                "appointment",
                [Utils.checkString]
            )
        ) {
            alert("ungültiger Betreff");
            return;
        } else if (
            !Utils.checkErrors(["startDate", "endDate"], this, "", [
                Utils.checkString,
                this.checkDate,
            ])
        ) {
            alert("ungültiges Datum");
            return;
        } else if (
            this.selectedType() === "Termin" &&
            !Utils.checkErrors(["startTime", "endTime"], this, "", [
                Utils.checkString,
                this.checkTime,
            ])
        ) {
            alert("ungültige Zeit");
            return;
        } else if (
            new Date(this.basicAppointment().StartDate()) >
            new Date(this.basicAppointment().EndDate())
        ) {
            alert("Das Enddatum liegt vor dem Startdatum");
            return;
        }

        this.basicAppointment().CaseId(
            (<HTMLSelectElement>(
                document.getElementById("EditAppointmentDialogsearchdrop")
            )).value.split(" ")[0]
        );

        if (this.IsEditMode()) {
            try {
                let result = await RNSAPI.updateAppointment(this.basicAppointment());
                if (result.Type === "UpdateSuccessful") {
                    RNSAPI.createHistoryEntry(
                        this.basicAppointment().CaseId(),
                        "Termin bearbeitet"
                    );
                    this.basicAppointment().AppointmentSubject("");
                    this.basicAppointment().CaseId("");
                    this.basicAppointment().AddresseeId("");
                    this.basicAppointment().AddresseeTypeId("");
                    this.basicAppointment().Addressee("");
                    this.basicAppointment().AppointmentNote("");
                    $("#appointmentModal").modal("hide");
                    this.update();
                } else {
                    alert("Fehler beim Speichern.");
                }
                this.IsEditMode(false);
            } catch (e) {
                console.log(e);
                alert("Fehler beim Speichern: " + e.responseText);
            }
        } else {
            try {
                let result = await RNSAPI.createAppointment(this.basicAppointment());
                if (result.Type === "CreationSuccessful") {
                    $("#appointmentModal").modal("hide");
                    this.update();
                    RNSAPI.createHistoryEntry(
                        this.basicAppointment().CaseId(),
                        "Termin angelegt"
                    );
                    this.basicAppointment().AppointmentSubject("");
                    this.basicAppointment().CaseId("");
                    this.basicAppointment().AddresseeId("");
                    this.basicAppointment().AddresseeTypeId("");
                    this.basicAppointment().Addressee("");
                    this.basicAppointment().AppointmentNote("");

                } else {
                    alert("Fehler beim Anlegen des Termins.");
                }
            } catch (e) {
                console.log(e);
                alert("Fehler beim Anlegen: " + e.responseText);
            }
        }
    }

    adjustDigits(val: number, places: number) {
        var str = String(val);
        for (var i = 0; i < places - String(val).length; i++) {
            str = "0" + str;
        }
        return str;
    }

    constructDate = (date: string, time: string) => {
        let parsedDate = this.parseDate(date);

        if (this.selectedType() === "Frist") {
            return parsedDate.toISOString();
        } else {
            let parsedTime = this.parseTime(time);
            let hour = parsedTime.hour();
            parsedDate.add(parsedTime.hours(), "hours");
            parsedDate.add(parsedTime.minutes(), "minutes");
            return parsedDate.toISOString();
        }
    };

    newBasicAppointment() {
        let obj = {
            AppointmentSubject: ko.observable(null),
            AppointmentDate: ko.computed(() =>
                this.constructDate(this.startDate().toString(), this.startTime())
            ),
            StartDate: ko.computed(() =>
                this.constructDate(this.startDate().toString(), this.startTime())
            ),
            EndDate: ko.computed(() =>
                this.constructDate(this.endDate().toString(), this.endTime())
            ),
            AppointmentType: ko.observable(""),
            Addressee: ko.observable(null),
            AddresseeId: ko.observable(""),
            AddresseeTypeId: ko.observable(""),
            AppointmentNote: ko.observable(""),
            SachbearbeiterId: ko.observable(RNSAPI.User().username),
            Sachbearbeiter: ko.observable(null),
            CaseId: ko.observable(""),
            Completed: ko.observable(false),
            CreationDate: ko.observable(null),
            CreatorId: ko.observable(""),
            EditedAtDate: ko.observable(null),
            EditorId: ko.observable(""),
            Gericht: ko.observable(null),
            GerichtsId: ko.observable(""),
            Id: ko.observable(""),
            IsDeleted: ko.observable(false),
            IsRecurringAppointment: ko.observable(false),
            Lawyer: ko.observable(null),
            LawyerId: ko.observable(RNSAPI.User() ? RNSAPI.User().username : "GR"),
            MeetingPlace: ko.observable(""),
            MeetingPlaceStreet: ko.observable(""),
            Moved: ko.observable(""),
            RecurringAppointmentDto: ko.observable(null),
            RoomID: ko.observable(""),
        };
        obj["AppointmentName"] = ko.computed(() => obj.AppointmentSubject());

        return ko.observable(obj);
    }

    basicAppointment = this.newBasicAppointment();

    fillNewAppointmentData(data) {
        this.startDate(moment(this.startOfDay().clone()).format("YYYY-MM-DD"));
        this.endDate(moment().format("YYYY-MM-DD"));
        this.startTime(data || "00:00");
        this.changedStartDate();
        Postbox.publisher().publish({ EditAppointment: false }, "EditAppointmentData");
    }

    async fillAppointmentData(id, slot) {
        if (!id) {
            this.fillNewAppointmentData(slot);
            return;
        }

        this.IsEditMode(true);
        try {
            //id Encoding wrong!

            let result = await RNSAPI.getAppointment(id);

            if (result.Type === "AppointmentFound") {
                let appointment = result.Payload.Appointment;
                let obj = this.basicAppointment();

                let excluding = [
                    "AppointmentDate",
                    "AppointmentName",
                    "StartDate",
                    "EndDate",
                    "isRecurringAppointment",
                ]; //"AppointmentType",
                for (let key in appointment) {
                    if (obj[key] === undefined) {
                        obj[key] = appointment[key];
                    } else if (excluding.indexOf(key) === -1) {
                        obj[key](appointment[key]);
                    }
                }

                this.basicAppointment(obj);
                (<HTMLSelectElement>(
                    document.getElementById("terminar")
                )).value = ko.toJS(this.basicAppointment().AppointmentType);
                (<HTMLSelectElement>(
                    document.getElementById("EditAppointmentDialogsearchdrop")
                )).value = ko.toJS(this.basicAppointment().CaseId);

                let momentStart = moment
                    .utc(appointment.AppointmentDate)
                    .subtract(1, "hours")
                    .local();
                let momentEnd = moment
                    .utc(appointment.AppointmentDate)
                    .subtract(1, "hours")
                    .local();

                let momentStartTime = moment
                    .utc(appointment.StartDate)
                    .subtract(1, "hours")
                    .local();
                let momentEndTime = moment
                    .utc(appointment.EndDate)
                    .subtract(1, "hours")
                    .local();
                this.startDate(momentStart.format("YYYY-MM-DD"));
                this.endDate(momentEndTime.format("YYYY-MM-DD"));
                this.startTime(momentStartTime.format("HH:mm"));
                this.endTime(momentEndTime.format("HH:mm"));

                $("#EditAppointment").modal("show");
            } else {
                alert("Termin nicht gefunden.");
            }
        } catch (e) {
            alert("Verbindung zum Server abgebrochen.");
            console.log(e);
        }
        this.IsEditMode(true);
    }

    async changedwholeday() {
        console.log(this.wholeday);
        if (this.wholeday()) {
            this.wholeday(false);
        } else {
            this.wholeday(true);
        }
    }

    async pickCase5() {
        let cases = (await RNSAPI.getCases()).Payload.Cases;
        this.pickGeneric(
            "Akte",
            ["Registernummer", "Rubrum", "Wegen"],
            ["Akte", "Rubrum", "Wegen"],
            cases
        );
        this.modalHandleSelection((selectedObject) =>
            this.basicAppointment().CaseId(selectedObject()["Registernummer"])
        );
        $("#modal").modal("show");
    }
    pickClerk5 = async () => {
        let sachbearbeiter = (
            await RNSAPI.getSachbearbeiter()
        ).Payload.Clerks.filter((s) => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric(
            "Sachbearbeiter",
            ["Sachbearbeiter"],
            ["Sachbearbeiter"],
            sachbearbeiter
        );
        this.modalHandleSelection((selectedObject) => {
            this.basicAppointment().SachbearbeiterId(
                selectedObject()["Sachbearbeiter_ID"]
            ); 
            this.basicAppointment().Sachbearbeiter(
                selectedObject()["Sachbearbeiter"]
            );
        });
        $("#modal").modal("show");
    };

    pickSachbearbeiter1 = async () => {
        let sachbearbeiter = (
            await RNSAPI.getSachbearbeiter()
        ).Payload.Clerks.filter((s) => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric(
            "Sachbearbeiter",
            ["Sachbearbeiter"],
            ["Sachbearbeiter"],
            sachbearbeiter
        );
        console.log(sachbearbeiter);
        this.modalHandleSelection((selectedObject) =>
            this.newSB1(selectedObject()["Sachbearbeiter_ID"])
        );
        $("#modal").modal("show");
    };
    pickclerk = async () => {
        let sachbearbeiter = (
            await RNSAPI.getSachbearbeiter()
        ).Payload.Clerks.filter((s) => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric(
            "Sachbearbeiter",
            ["Sachbearbeiter"],
            ["Sachbearbeiter"],
            sachbearbeiter
        );
        this.modalHandleSelection((selectedObject) =>
            this.newSB(selectedObject()["Sachbearbeiter_ID"])
        );
        $("#modal").modal("show");
    };
    pickSachbearbeiter = async () => {
        let sachbearbeiter = (
            await RNSAPI.getSachbearbeiter()
        ).Payload.Clerks.filter((s) => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric(
            "Sachbearbeiter",
            ["Sachbearbeiter"],
            ["Sachbearbeiter"],
            sachbearbeiter
        );
        this.modalHandleSelection((selectedObject) => {
            this.akteFrist().SachbearbeiterId(selectedObject()["Sachbearbeiter_ID"]); 
            this.akteFrist().Sachbearbeiter(selectedObject()["Sachbearbeiter_ID"]);
            this.akteResubmission().Sachbearbeiter(
                selectedObject()["Sachbearbeiter_ID"]
            );
        });
        $("#modal").modal("show");
    };

    async pickCase() {
        let cases = (await RNSAPI.getCases()).Payload.Cases;
        console.log(cases);
        this.pickGeneric(
            "Akte",
            ["Registernummer", "Rubrum", "Wegen"],
            ["Akte", "Rubrum", "Wegen"],
            cases
        );
        this.modalHandleSelection((selectedObject) => {
            this.akteFrist().CaseId(selectedObject()["Registernummer"]);
            this.akteFrist().Rubrum(selectedObject()["Rubrum"]);
            this.akteResubmission().Case_ID(selectedObject()["Registernummer"]);
            this.Rubrum(selectedObject()["Rubrum"]);
        });
        $("#modal").modal("show");
    }
    async pickAkte() {
        let cases = (await RNSAPI.getCases()).Payload.Cases;
        console.log(cases);
        this.pickGeneric(
            "Akte",
            ["Registernummer", "Rubrum", "Wegen"],
            ["Akte", "Rubrum", "Wegen"],
            cases
        );
        this.modalHandleSelection((selectedObject) => {
            this.basicAppointment().CaseId(selectedObject()["Registernummer"]);
        });
        $("#modal").modal("show");
    }

    async pickAddress() {
        let address = (await RNSAPI.getPersonsForScope(this.selectedAddress()))
            .Payload.Addresses;
        this.pickGeneric(
            "Gesetzlicher Vertreter",
            ["FirstName", "Name1", "CityName"],
            ["Vorname", "Nachname", "Ort"],
            address
        );
        this.modalHandleSelection((selectedObject) => {
            console.log("Selected object");
            console.log(ko.toJS(selectedObject));
            this.basicAppointment().AddresseeId(selectedObject()["Keyword"]); //selectedObject()["Sachbearbeiter_ID"]
            // this.basicAppointment().Addressee(selectedObject()["AddresseeId"]);
        });
        $("#modal").modal("show");
    }

    async pickDeadlineNumberResubmission() {
        let reasons = (await RNSAPI.getResubmissionReasons()).Payload.Reasons;
        console.log("Reasons");
        console.log(reasons);
        this.pickGeneric("Grund", ["DeadLineName"], ["Beschreibung"], reasons);
        this.modalHandleSelection((selectedObject) => {
            const durations = ['D', 'W', 'M', 'Y']
            for(let d of durations) {
                if (selectedObject().DeadLineElapseTime.includes(d)) {
                    this.akteResubmission().DeadLineNr(selectedObject().DeadLineNr);
                    this.resubmissionLapsetime(selectedObject().DeadLineElapseTime);
                }
            }
            if (selectedObject().DeadLineNr) {
                this.DeadLineNr(selectedObject().DeadLineElapseTime);
                this.akteResubmission().DeadLineNr(selectedObject().DeadLineElapseTime);
                this.akteResubmission().DeadLineReason(selectedObject().DeadLineName);
            }
        });
        $("#modal").modal("show");
    }

    async pickDeadlineNumber() {
        let reasons = (await RNSAPI.getDeadlineReasons()).Payload.Reasons;
        console.log("the reasons list is ", reasons);
        this.pickGeneric(
            "Grund",
            ["DeadlineDescription"],
            ["Beschreibung"],
            reasons
        );
        this.modalHandleSelection((selectedObject) => {
            this.akteFrist().DeadlineNumber(selectedObject()["DeadlineNumber"]);
            this.deadlineNr(selectedObject()["DeadlineDuration"]);
            this.predeadlineNr(selectedObject()["PreDeadlineDuration"]);
            this.akteFrist().DeadlineText(selectedObject()["DeadlineDescription"]);
            this.akteResubmission().DeadLineReason(
                selectedObject()["DeadlineDescription"]
            );
        });
        $("#modal").modal("show");
    }

    async pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    }

    akte = ko.observable({
        Registernummer: ko.observable(null),
        Referat: ko.observable(null),
        SachbearbeiterId: ko.observable(null),
        Rubrum: ko.observable(null),
        Wegen: ko.observable(null),
        Sachstand: ko.observable(null),
        AnlageDatum: ko.observable(moment().format("DD.MM.YYYY")),
        Note1: ko.observable(null),
        Wiedervorlage: ko.observable(""),
        WertGericht1: ko.observable(0),
        IsSelected: ko.observable(true),
        delegate: ko.observable(""),
        delegateapp: ko.observable(""),
    });
}
//end of class
let nameTable: { [key: string]: string } = {
    appointment: "Termin",
    deadline: "Frist",
    predeadline: "Vorfrist",
    inbox: "Posteingang",
    resubmission: "Wiedervorlage",
    ecase: "EAkte",
};

var html = fs.readFileSync(__dirname + "/dashboard.html", "utf8");

ko.components.register("dashboard-view", {
    viewModel: DashboardViewModel,
    template: html,
});
