import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { Utils } from "../../utils";
import { MainViewModel } from "../../main";
import '../dialog/dialog';
import '../dialog/message';
import { DialogViewModel } from '../dialog/dialog';

class AddressViewModel {
    types = ko.observableArray(["Mandant", "Gegner", "Sonstige", "Gericht", "Versicherung", "Behörde", "Anwalt"]); //, "Gericht", "Versicherung", "Behörde", "Anwalt"
    selectedType = ko.observable('');

    infoMessage = ko.observable('');
    infoTitle = ko.observable('');
    modialAutoHide = ko.observable(false)

    //DisplayGVertreter1 = ko.observable(null);
    mandantData = ko.observable(true);
    manGegSonsData = ko.observable(true);
    mandantFirstName = ko.observable(true);
    gerchitNameTwo = ko.observable(true);
    gerchitNameThree = ko.observable(true);
    mandantrechtsform = ko.observable(true);
    showRF = ko.observable(false);
    rachts = ko.observableArray([]);
    rachtsPerson = ko.observableArray([]);
    briefkopf = ko.observableArray([]);
    //condition extended to make a field visibel on only the three fields
    typeChanged(obj, event) {
        if (event.originalEvent) {
            if (this.selectedType() === "Mandant") {
                this.mandantData(true);
                this.manGegSonsData(true);
                this.mandantFirstName(true);
                this.mandantrechtsform(true);
            } else if (this.selectedType() == "Gegner") {
                this.manGegSonsData(true);
                this.mandantData(false);
                this.mandantFirstName(true);
                this.mandantrechtsform(true);
            } else if (this.selectedType() == "Sonstige") {
                this.manGegSonsData(true);
                this.mandantData(false);
                this.mandantFirstName(true);
                this.mandantrechtsform(true);
            }
            else {
                this.mandantData(false);
                this.manGegSonsData(false);
                this.mandantFirstName(true);
                this.mandantrechtsform(false);
            }
        }
    }

    IsEditMode = ko.observable(false);
    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalHandleSelection = ko.observable();

    pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    };

    async pickRechtsform2(person) {
        let rechtsformen = (await RNSAPI.getRechtsformen()).Payload.LegalForms;
        this.pickGeneric("Rechtsform", ["NameID", "Briefanrede"], ["Name", "Anrede"], rechtsformen);
        this.modalHandleSelection((selectedObject) => {
          person.Rechtsform(selectedObject()["NameID"]);
          person.AnredeBriefkopf(selectedObject()["Briefanrede"]);
          person.AnredeDirekt(selectedObject()["Anrede"]);
        })
        $('#modal').modal('show');
    };

    async pickGVertreter(person) {
        let addresses = (await RNSAPI.getPersonsForScope(this.selectedType())).Payload.Addresses;
        this.pickGeneric("Gesetzlicher Vertreter", ["FirstName", "Name1", "CityName"], ["Vorname", "Nachname", "Ort"], addresses);
        this.modalHandleSelection((selectedObject) => {
            this.basicPerson().GesetzlicherVertreter1Id(selectedObject()["Keyword"]);
        });
        $('#modal').modal('show');
    };

    async getCity() {
        let result=await RNSAPI.getPostcode(this.basicPerson().ZipCode());
        if (result.Type=="GetPostcodeResult" && result.Payload.Cities.length>0) {
            this.basicPerson().CityName(result.Payload.Cities[0])
        }
        else {
            this.basicPerson().CityName('')
        }


    }

    newBasicPerson() {
        let obj = {
            FirstName: ko.observable(""),
            Name1: ko.observable(""),
            Name2: ko.observable(""),
            Name3: ko.observable(""),
            Rechtsform: ko.observable(null),
            Address: ko.observable(null),
            ZipCode: ko.observable(null),
            CityName: ko.observable(null),
            EmailAddress: ko.observable(null),
            Phone1: ko.observable(null),
            AnredeBriefkopf: ko.observable(null),
            AnredeDirekt: ko.observable(null),
            Note1: ko.observable(null),
            GesetzlicherVertreter1Id: ko.observable(null),
            Fax: ko.observable(null),
            RSVersNr: ko.observable(null)

        };

        obj["Keyword"] = ko.computed(function () {
            return (obj.Name1() + obj.FirstName()).substring(0, 20);
        });

        return ko.observable(obj);
    };

    basicPerson = this.newBasicPerson();

    changeExtra() {
    };

    resetObject(obj) {
        for (let key in obj) {
            obj[key](null);
        }
    }

    currentPanelData = ko.observable({
        panel: 'mandant-template',
        data: self
    });

    mapping = {
        "mandant": {
            "panel": "mandant-template",
            "data": self
        },
        "gegner": {
            "panel": "gegner-template",
            "data": self
        },
        "sonstige": {
            "panel": "sonstige-template",
            "data": self
        },
        "gericht": {         //added templates
            "panel": "gericht-template",
            "data": self
        },
        "versicherung": {
            "panel": "versicherung-template",
            "data": self
        },
        "behörde": {
            "panel": "behörde-template",
            "data": self
        },
        "anwalt": {
            "panel": "anwalt-template",
            "data": self
        }
    };


    goto(name) {
        this.currentPanelData(this.mapping[name]);
        this.currentPanelData.valueHasMutated();
    }

    pickRechtsform = async () => {
        let rechtsformen = (await RNSAPI.getRechtsformen()).Payload.LegalForms;
        let x = [];
        let y = [];
        let z = [];
        let a = [];
        let b = [];
        let c = [];
        for (let i = 0; i < rechtsformen.length; i++) {
            if (rechtsformen[i].NameID != "") {
                if (rechtsformen[i].NameID === "Herr" || rechtsformen[i].NameID === "Frau" || rechtsformen[i].NameID === "Divers") {
                    //console.log(rechtsformen[i].NameID);
                    a.push(rechtsformen[i].NameID);
                    b.push(rechtsformen[i].Briefanrede);
                    c.push(rechtsformen[i].Anrede);
                }
                else {
                    x.push(rechtsformen[i].NameID);
                    y.push(rechtsformen[i].Briefanrede)
                    z.push(rechtsformen[i].Anrede)
                }
            }

        }
        this.rachts(x);
        this.rachtsPerson(a);
        this.briefkopf(rechtsformen);
    }

    selectOption = (data) => {
        let a = ko.toJS(this.briefkopf);
        for (let i = 0; i < a.length; i++) {
            if (data === a[i].NameID) {
                this.basicPerson().Rechtsform(a[i].NameID);
                // this.currentAnredeGegner(a[i].NameID);
                // this.currentAnredeSonsti(a[i].NameID);
                this.basicPerson().AnredeBriefkopf(a[i].Briefanrede);
                this.basicPerson().AnredeDirekt(a[i].Anrede);
                this.showRF(false);
                break;
            }
        }
    }

    showSuggestionsRF = async () => {
        this.showRF(true);
        this.pickRechtsform();
    }

    async postData() {
        Utils.removeHints();
        $(".form-group").each(function () { $(this).removeClass("has-error"); });
        if (!Utils.checkErrors(["Name1"], this.basicPerson(), "mandant", [Utils.checkString])) return;

        let person = ko.toJSON(this.basicPerson());
        if (this.IsEditMode()) {
            try {
                // let sampleError = {response: {status: 401, data: {error: 'nothing'}}};
                // throw sampleError;
                let result = await RNSAPI.updatePerson(this.basicPerson(), this.selectedType());
                if (result.Type === "UpdateSuccessful") {
                    MainViewModel.RoutingTable.showAddressOverview();
                } else {
                    alert("Fehler beim Speichern.");
                }
            } catch (e) {
                let error = Utils.handleError(e);
                this.infoMessage('');
                this.infoMessage(error['code'] + ': ' + error['message']);
                console.log(e);
                // alert("Fehler beim Speichern: " + e.responseText);
            }
        } else {
            try {
                let result = await RNSAPI.createPerson(this.basicPerson(), this.selectedType());
                if (result.Type === "AdditionSuccessful") {
                    RNSAPI.createHistoryEntry("NULL", "Stammdaten / " + this.selectedType() + " " + this.basicPerson().Name1() + " angelegt");
                    this.infoMessage('');
                    this.infoTitle('')
                    this.infoTitle('Erledigt')
                    this.infoMessage('Daten erfolgreich erfasst.')
                    this.modialAutoHide(true)
                    setTimeout(() => {
                        this.infoMessage('');
                        MainViewModel.RoutingTable.showAddressOverview();
                        $("html, body").animate({ scrollTop: 0 }, "fast");
                    }, 4000)
                } else {
                    this.infoTitle('')
                    this.infoTitle('Fehler')
                    this.infoMessage('');
                    this.infoMessage('Fehler beim Anlegen: Person mit diesem Keyword existiert bereits.');
                    // alert("Fehler beim Anlegen: Person mit diesem Keyword existiert bereits.");
                }
            } catch (e) {
                // let sampleError = {response: {status: 401, data: {error: 'nothing'}}};
                // throw sampleError;
                let error = Utils.handleError(e);
                console.log(error)
                this.infoMessage('');
                this.infoMessage(error['code'] + ': ' + error['message']);
                // $('#MessageModal').modal('show')
                // console.log(e);
                // alert("Fehler beim Anlegen: " + e.responseText);
            }
        }
    }

    async loadPerson(keyword: string, type: string) {
        let result = (await RNSAPI.getPersonForKeyword(keyword, type));
        if (result.Type === "ExactMatchNotFound") {
            alert("Person wurde in der Zwischenzeit gelöscht!")
            return;
        }
        let person = result.Payload.Address;
        let newPerson = this.newBasicPerson();
        let obj = newPerson();
        for (let key in person) {
            if (obj[key] === undefined) {
                obj[key] = person[key];
            } else if (key !== "Keyword") {
                obj[key](person[key]);
            } else {
                obj["Keyword"] = ko.observable(person["Keyword"]);
            }
        }
        this.basicPerson(obj);
    }

    constructor(params: any) {
        this.selectedType.subscribe((newValue) => this.goto(newValue.toLowerCase()));

        params = params || {};
        let keyword = params.keyword;
        let type = params.type;
        this.IsEditMode(keyword !== undefined && type !== undefined);

        if (this.IsEditMode()) {
            this.loadPerson(keyword, type);
            this.selectedType(type);
            if (this.selectedType() == "Mandant") {//Condition added to hide fields from new and edit forms
                this.mandantData(true);
                this.manGegSonsData(true);
                this.mandantFirstName(true);
                this.mandantrechtsform(true);
            } else if (this.selectedType() == "Gegner") {
                this.manGegSonsData(true);
                this.mandantData(false);
                this.mandantFirstName(true);
                this.mandantrechtsform(true);
            } else if (this.selectedType() == "Sonstige") {
                this.manGegSonsData(true);
                this.mandantData(false);
                this.mandantFirstName(true);
                this.mandantrechtsform(true);
            }
            else {
                this.mandantData(false);
                this.manGegSonsData(false);
                this.mandantFirstName(true);
                this.mandantrechtsform(false);
            }
        }
        const selectedValue = localStorage.getItem('updateSelectedAddress')
        if (selectedValue) {
            this.selectedType(selectedValue || this.types()[0])
            localStorage.setItem('updateSelectedAddress', '')
        }
    }
}

let html = fs.readFileSync(__dirname + '/address.html', 'utf8');

ko.components.register("address-view", {
    viewModel: AddressViewModel,
    template: html
});
