import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI, Routing } from "../../api";
import { MainViewModel } from "../../main";
import * as moment from "moment";
import '../dataTable/dataTable';
import '../deadline/extend';

export class DeadlineOverviewViewModel {
    deadlines = ko.observableArray([]);
    deadlineToBeExtended = ko.observable(null);

    hasDeadlineManagementRights = ko.observable<boolean>(false);

    async loadDeadlines() {
        let addresses = (await RNSAPI.getDeadlines(moment())).Payload.Deadlines;
        this.deadlines(addresses.map((d) => {
            let obj = { ...d };

            d.actionHandlers = [];

            if(this.hasDeadlineManagementRights()) {
                d.actionHandlers.push({
                    icon: "pencil", name: "Verlängern", action: async () => {
                        this.deadlineToBeExtended(obj);
                        $('#extendDeadline').modal('show');
                    }
                });
            }

            if (!d.CompletionDate && this.hasDeadlineManagementRights()) {
                d.actionHandlers.push({
                    icon: "check", name: "Abhaken", action: async () => {
                        let result = await RNSAPI.completeDeadline(obj);
                        this.loadDeadlines();
                    }
                });
            }

            d.Deadline = (new Date(d.Deadline)).toLocaleDateString();
            d.PreDeadline = d.PreDeadline ? (new Date(d.PreDeadline)).toLocaleDateString() : "";
            d.Reason = d.TypeId + d.TypeDescription;
            d.CompletionDate = d.CompletionDate ? (new Date(d.CompletionDate)).toLocaleDateString() : "";

            return d;
        }));
    }

    public getVM = () => this;

    constructor() {
        this.loadDeadlines();
    }
}

let html = fs.readFileSync(__dirname + '/overview.html', 'utf8');

ko.components.register("deadlineoverview-view", {
    viewModel: DeadlineOverviewViewModel,
    template: html
});