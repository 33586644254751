import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment";
import { Utils } from "../../utils";
import { RNSAPI } from "../../api";
import { Postbox } from '../dashboard/postbox'
import "../dropdownsearch/dropdownsearch";
import { MainViewModel } from "../../main";
import './dialog';
import { DialogViewModel } from './dialog';
import './message';
// import '../dashboard/dashboard';
import { DashboardViewModel } from "../dashboard/dashboard";
import { join } from "path";

export class resubmissionDialogViewModel {

    parseDate = (dateStr: string) => moment.utc(dateStr, "DD.MM.YYYY", true);
    creationDate = ko.observable(moment().format("YYYY-MM-DD"));
    DeadLineNr = ko.observable("");
    DeadLineElapseTime = ko.observable("");
    Rubrum = ko.observable("Kein Rubrum vorhanden.");
    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalHandleSelection = ko.observable();
    fixCase = ko.observable(false);
    givenId = "NewResubmissionDialogExt";
    sundayNotification = ko.observable(false);

    akteResubmission = this.newAkteResubmission();

    deadLineDateValue = ko.observable("");


    private formatStandardDeadlineDate(): string {
        if(!this.DeadLineElapseTime()) {
            return moment().format("YYYY-MM-DD");
        }
        let potentialDate = moment(ko.toJS(this.creationDate()), "YYYY-MM-DD"); 
        potentialDate.add(Number(this.DeadLineElapseTime()), 'w');
        return potentialDate.format("YYYY-MM-DD");
    }


    newAkteResubmission() {
        let rObj = {
            AdressTyp: ko.observable(""),
            AngelegtVon: ko.observable(""),
            AnlageDatum: ko.computed(() =>
                this.parseDate(
                    moment(this.creationDate(), "YYYY-MM-DD").format("DD.MM.YYYY")
                ).toISOString()
            ),
            Case_ID: ko.observable(""),
            Comment: ko.observable(""),
            Copies: ko.observable(""),
            DeadLineDate: ko.computed(() => this.parseDate(moment(ko.toJS(this.deadLineDateValue), "YYYY-MM-DD").format("DD.MM.YYYY")).toISOString()),
            DeadLineNr: ko.observable(""),
            DeadLineReason: ko.observable(""),
            EDAVerfahren: ko.observable(false),
            ErledigtDatum: ko.observable(null),
            ErledigtVon: ko.observable(""),
            ForderungskontoDrucken: ko.observable(false),
            Formulardruk: ko.observable(false),
            HonorarForderung: ko.observable(false),
            Keyword: ko.observable(""),
            PapierArtID: ko.observable(""),
            Quotelung: ko.observable(0),
            Referat: ko.observable(""),
            Sachbearbeiter: ko.observable(
                RNSAPI.User() ? RNSAPI.User().username : "GR"
            ),
            Standardtext: ko.observable(""),
            Status: ko.observable("N"),
        };

        return ko.observable(rObj);
    }

    async pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    }

    resubmissionErrorMessage = ko.observable<string>('');
    allClerks = ko.observableArray([]);
    allCases = ko.observableArray([]);
    allResubmissionReasons = ko.observableArray([]);

    async postResubmissionData() {
        if (ko.toJS(this.akteResubmission().Case_ID) === '') {
            $("#caseInput_div").addClass("has-error");
            return;
        }
        else {
            $("#caseInput_div").removeClass("has-error");
        }
        if (ko.toJS(this.akteResubmission().DeadLineNr) === "") {
            $("#reasonInput_div").addClass("has-error");
            return;
        }
        else {
            $("#reasonInput_div").removeClass("has-error");
        }

        if(!ko.toJS(this.allCases()).find(x => x.caseid === this.akteResubmission().Case_ID())) {
            this.resubmissionErrorMessage("Die eingegebene Registernummer existiert nicht!");
            return;
        }

        if(!ko.toJS(this.allClerks()).find(x => x.Sachbearbeiter_ID === this.akteResubmission().Sachbearbeiter())) {
            this.resubmissionErrorMessage("Der eingegebene Sachbearbeiter existiert nicht!");
            return;
        }

        if(!ko.toJS(this.allResubmissionReasons()).find(x => x.DeadLineName === this.akteResubmission().DeadLineReason())) {
            this.resubmissionErrorMessage("Der eingegebene Wiedervorlage Grund existiert nicht!");
            return;
        }

        let resubmissionObj = ko.toJS(this.akteResubmission());
        resubmissionObj["DeadLineDate"] = moment(ko.toJS(this.deadLineDateValue)).format("YYYY-MM-DD");
        try {
            let result = await RNSAPI.createResubmission(resubmissionObj);
            if (result.Type === "CreateSuccess") {
                this.akteResubmission().Case_ID("");
                this.akteResubmission().Sachbearbeiter("");
                this.akteResubmission().DeadLineNr("");
                this.creationDate(moment().format("YYYY-MM-DD"));
                RNSAPI.createHistoryEntry(resubmissionObj.Case_ID, "Wiedervorlage angelegt");
                $("#NewResubmissionDialogExt").modal("hide");
                $("#CaseNewResubmissionDialogExt").modal("hide");
            } else {
                alert("Fehler beim Anlegen der Wiedervorlage.");
            }
        } catch (e) {
            console.log(e);
            alert("Fehler beim Anlegen : " + e.responseText);
        }

        if (document.location.href.includes('dashboard'))
            Postbox.publisher().publish("", "updateDashboard")
        else if (document.location.href.includes('tfw'))
            Postbox.publisher().publish("", "updateTFW")

    }

    async postResubmission() {
        this.postResubmissionData();
    }

    async pickCase() {
        this.resubmissionErrorMessage('');
        await this.loadCases();
        let cases = ko.toJS(this.allCases());

        this.pickGeneric(
            "Akte",
            ["caseid", "rubrum"],
            ["Akte", "Rubrum"],
            cases
        );
        this.modalHandleSelection((selectedObject) => {
            this.akteResubmission().Case_ID(selectedObject()["caseid"]);
            this.Rubrum(selectedObject()["rubrum"]);
        });
        $("#Resubmodal").modal("show");
    }

    pickSachbearbeiter = async () => {
        this.resubmissionErrorMessage('');
        await this.loadClerks();
        let sachbearbeiter = ko.toJS(this.allClerks());

        this.pickGeneric(
            "Sachbearbeiter",
            ["Sachbearbeiter"],
            ["Sachbearbeiter"],
            sachbearbeiter
        );
        this.modalHandleSelection((selectedObject) => {
            this.akteResubmission().Sachbearbeiter(
                selectedObject()["Sachbearbeiter_ID"]
            );
        });
        $("#Resubmodal").modal("show");
    };

    private calculateResubmissionDate(elapseTime: string): string {
        const timeIndication = elapseTime[0];
        const timeDuration = elapseTime[1];

        let potentialDate = moment(ko.toJS(this.creationDate()), "YYYY-MM-DD"); 
        switch (timeIndication) {
            case 'D':
                potentialDate.add(Number(timeDuration), 'd');
                break;
            case 'W':
                potentialDate.add(Number(timeDuration), 'w');
                break;
            case 'M':
                potentialDate.add(Number(timeDuration), 'M');
                break;
            case 'Y':
                potentialDate.add(Number(timeDuration), 'y');
                break;
        }
        return potentialDate.format("YYYY-MM-DD");
    }

    async pickDeadlineNumberResubmission() {
        this.resubmissionErrorMessage('');
        await this.loadResubmissionReasons();
        let reasons = ko.toJS(this.allResubmissionReasons());
        this.pickGeneric("Grund", ["DeadLineName"], ["Beschreibung"], reasons);
        let isInDuration: boolean = false;
        this.modalHandleSelection((selectedObject) => {
            const durations = ['D', 'W', 'M', 'Y'];
            this.akteResubmission().DeadLineReason(selectedObject().DeadLineName);
            for (let d of durations) {
                if (selectedObject().DeadLineElapseTime.includes(d)) {
                    isInDuration = true;
                    const calculatedDate = this.calculateResubmissionDate(selectedObject().DeadLineElapseTime);
                    //this.deadLineDateValue(calculatedDate);
                    this.akteResubmission().DeadLineNr(selectedObject().DeadLineNr);
                }
            }

            if (selectedObject().DeadLineNr && !isInDuration) {
                this.DeadLineNr(selectedObject().DeadLineNr);
                this.akteResubmission().DeadLineNr(selectedObject().DeadLineNr);
                this.DeadLineElapseTime(selectedObject().DeadLineElapseTime);
                //this.deadLineDateValue(this.formatStandardDeadlineDate());
                this.calcResubmission();
            }
        });
        $("#Resubmodal").modal("show");
    }

    async getRubrumFromCase() {
        this.Rubrum((await RNSAPI.getCaseByCaseId(ko.toJS(this.akteResubmission().Case_ID))).Payload.Case.Rubrum);
    }

   private clearResubmissionForm(): void {
       this.Rubrum("");
       this.akteResubmission().DeadLineReason("");
       this.deadLineDateValue("");
       this.akteResubmission().Case_ID("");
       this.akteResubmission().Sachbearbeiter(RNSAPI.User() ? RNSAPI.User().username : "GR");
       this.akteResubmission().DeadLineNr("");
    }

    async calcResubmission() {
        if (ko.toJS(this.akteResubmission().DeadLineNr) !== "") {
            let res = (await RNSAPI.calculateResubmission({
                deadlineNumber: ko.toJS(this.akteResubmission().DeadLineNr),
                StartDate: moment(ko.toJS(this.creationDate))
            })).Payload;
            if (res.Deadline !== null)
                this.deadLineDateValue(moment(res.Deadline).format("YYYY-MM-DD"));
        }
    }

    isSunday() {
        if (ko.toJS(moment(this.deadLineDateValue()).weekday()) === 6)
            this.sundayNotification(true);
        else
            this.sundayNotification(false);

    }

    
    private async loadCases() {
        let cases = [];
        await RNSAPI.getCasesOverview(0, ko.toJS(20)).then((data) => {
            cases = data as any;
        }).catch((error) => {
            cases = [];
        });

        this.allCases(cases); //(await RNSAPI.getCases()).Payload.Cases
    }


    private async loadClerks() {
        this.allClerks((
            await RNSAPI.getSachbearbeiter()
        ).Payload.Clerks.filter((s) => s.Sachbearbeiter_ID.trim() !== ""));
    }


    private async loadResubmissionReasons() {
        this.allResubmissionReasons((await RNSAPI.getResubmissionReasons()).Payload.Reasons);
    }
    
    constructor(params: any) {
        this.loadCases();
        this.loadClerks();
        this.loadResubmissionReasons();

        if (params.Case) {
            this.akteResubmission().Case_ID(params.Case);
            this.getRubrumFromCase();
        } 

        if (params.prefix) {
            this.givenId = params.prefix + this.givenId;
        }

        $(window).on('shown.bs.modal', function() { 
            if($("#NewResubmissionDialogExt").attr("caseid")) {
                $("#CaseIDResubmissionInput").val($("#NewResubmissionDialogExt").attr("caseid")).trigger('change');
            } else {
                $("#CaseIDResubmissionInput").trigger("change");
            }
        });

        this.creationDate.subscribe(() => this.calcResubmission())

        document.getElementById("CaseIDResubmissionInput").onchange = (event) => {
            let inputCase = (<HTMLInputElement>event.target).value;
            if(this.akteResubmission().Case_ID() !== inputCase) {
                this.akteResubmission().Case_ID(inputCase);
                this.getRubrumFromCase();
            } else {
                if(inputCase === "") {
                    this.clearResubmissionForm();
                }
            }
        };

        this.deadLineDateValue.subscribe(() => this.isSunday());
    }
}

let html = fs.readFileSync(__dirname + "/resubmissionDialog.html", "utf8");

ko.components.register("resubmission-dialog-view", {
  viewModel: resubmissionDialogViewModel,
  template: html,
});