import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI, Routing } from "../../api";
import { Utils } from "../../utils";

class PlayViewModel {
    async downloadOggFile(id: string) {
        let result = await RNSAPI.getDocumentData(id);

        let player = ($("audio")[0] as HTMLMediaElement)
        //yes 'probably' is actually part of the WEB standard
        //unfortunately it is returned as a string
        //'probably', 'maybe', '' this should be a JOKE, but it is not 
        if (player.canPlayType('audio/ogg; codecs="opus"') === 'probably') {
            let blob = Utils.base64ToBlob(result.Payload.Document.DocumentData, "audio/ogg; codecs=opus");
            this.blobUrl(URL.createObjectURL(blob));
        } else {
            //Fallback for old browsers, decode to PCM and then encode to wave
            this.decodeOgg(Utils.base64ToUint8Array(result.Payload.Document.DocumentData));
        }
    }

    private readonly sampleRate = 12000;
    private readonly bitDepth = 16;

    private decodeOgg(typedArray: Uint8Array) {
        let decoderCode = fs.readFileSync(__dirname + '/../../../node_modules/opus-recorder/dist/decoderWorker.min.js', 'utf8');
        let decoderBlob = new Blob([decoderCode], { type: "text/javascript" });
        let waveCode = fs.readFileSync(__dirname + '/../../../node_modules/opus-recorder/dist/waveWorker.min.js', 'utf8');
        let waveCodeBlob = new Blob([waveCode], { type: "text/javascript" });
        let decoderWorker = new Worker(window.URL.createObjectURL(decoderBlob));
        let wavWorker = new Worker(window.URL.createObjectURL(waveCodeBlob));
        decoderWorker.postMessage({
            command: 'init',
            decoderSampleRate: this.sampleRate,
            outputBufferSampleRate: this.sampleRate
        });
        wavWorker.postMessage({
            command: 'init',
            wavBitDepth: this.bitDepth,
            wavSampleRate: this.sampleRate
        });
        //setTimeout(() => wavWorker.postMessage({ command: 'done' }), 1000);
        decoderWorker.onmessage = (e) => {
            // null means decoder is finished
            if (e.data === null) {
                wavWorker.postMessage({ command: 'done' });
            }
            // e.data contains decoded buffers as float32 values
            else {
                wavWorker.postMessage({
                    command: 'encode',
                    buffers: e.data
                }, e.data.map((typedArray) => {
                    return typedArray.buffer;
                }));
            }
        };
        wavWorker.onmessage = (e) => {
            console.log("encoding")
            // null signifies that no more data is expected and worker is closed. 
            if (e.data !== null) {
                let dataBlob = new Blob([e.data], { type: "audio/wav" });
                this.blobUrl(URL.createObjectURL(dataBlob));
            }
        };
        decoderWorker.postMessage({
            command: 'decode',
            pages: typedArray
        }, [typedArray.buffer]);

        decoderWorker.postMessage({
            command: 'done',
        });
    };

    subscriptions = [];
    blobUrl = ko.observable("");

    constructor(params) {
        this.subscriptions.push(params.id.subscribe((newId) => {
            this.downloadOggFile(newId);
        }));
        this.downloadOggFile(params.id());
    }

    dispose() {
        for (var s of this.subscriptions) s.dispose();
    }
}

let html = fs.readFileSync(__dirname + '/play.html', 'utf8');

ko.components.register("play-view", {
    viewModel: PlayViewModel,
    template: html
});