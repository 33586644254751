import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment";
import { RNSAPI, Routing } from "../../api";
import { Utils } from "../../utils";
import '../dialog/dialog';
import { Postbox } from "../dashboard/postbox";
import { RolesAndRights } from "../../helpers/RolesAndRights";

class CalculatorViewModel {
    filteredItems: ko.Computed<any>;

    currentView = ko.observable('abrechnung');
    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalHandleSelection: any = ko.observable();
    allItems = ko.observableArray([]);
    selectedMandant = ko.observable();
    modalLocalTitle = ko.observable('');
    modalSelectedObject = ko.observable(null);
    filter = ko.observable('');
    today = ko.observable(moment().format("DD.MM.YYYY"));
    selectedCase = ko.observable(null);
    reminder: ko.Observable<any> = ko.observable();
    AdressatSuchbegriff = ko.observable('');
    Sachbearbeiter = ko.observable('');
    rechNr = ko.observable("-/-");

  
    reminderLevel = ko.observable('1');
    reminderRadionBtn = ko.observable('1');
    allMonitorings = ko.observableArray();
    currentCaseID = ko.observable('');
    selectedMonitorings = ko.observableArray();
    monCaseID = ko.observable('');
    monSB = ko.observable('');
    monAmount = ko.observable('');
    monCaseHourly = ko.observable('');
    monSingle = ko.observable(true);

    isAmountZero = ko.observable(false);

    startDate = ko.observable('');
    startTime = ko.observable('');
    endDate = ko.observable('');
    endTime = ko.observable('');
    hourly = ko.observable('');

    allMandantenForCase = ko.observableArray();
    // selectedMandantForCase = ko.observable('');

    postGenerateTMBill = ko.observable(false);

    isViewerRole = ko.observable<boolean>(false);

    TimeMonitoringType = ko.observable({
        ID: ko.observable(0),
        CaseID: ko.observable(''),
        StartTime: ko.observable(''),
        EndTime: ko.observable(''),
        Sent: ko.observable(true),
        Duration: ko.observable(''),
        Type: ko.observable(''),
        UserID: ko.observable(''),
        Task: ko.observable('')
    });

    BillTimeMonitoringType = ko.observable({
        ID: ko.observable(0),
        UserID: ko.observable(''),
        CaseID: ko.observable(''),
        Sent: ko.observable(true),
        Type: ko.observable(''),
        Task: ko.observable(''),
        Recipient: ko.observable(''),
        MWST: ko.observable(19.00)
    });

    TimeMonitoringEntry = ko.observable({
        Datum: ko.observable(''),
        Start: ko.observable(''),
        Ende: ko.observable(''),
        Dauer: ko.observable(''),
        Tatigkeit: ko.observable(''),
        SB: ko.observable(''),
        Stundensatz: ko.observable('')
    });


    async pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    };

   

    private formatCurrencyInput(input: number): string {
        if (isNaN(input)) return "0,00 €";
        const options = { style: 'currency', currency: 'EUR' };
        const numberFormat = new Intl.NumberFormat('de-DE', options);
        return numberFormat.format(input);
    }

 

    modalPick() {
        if (this.modalHandleSelection !== null) this.modalHandleSelection()(this.modalSelectedObject);
    };

    async abgleich() {
        // this.loadRVGEntries();
        alert("Abgleich");
    }

    async GetDLK() {
        let tmdata = (await RNSAPI.getTimeMonitorings(1)).Payload.TimeMonitorings;
        tmdata = tmdata.reverse();
        for (let i = 0; i < tmdata.length; i++) {
            let durationMin = tmdata[i].Duration
            tmdata[i].Duration = this.getHoursFromMinutes(tmdata[i].Duration);
            tmdata[i].AmountNr = tmdata[i].Amount;
            tmdata[i].Amount = this.formatCurrencyInput(tmdata[i].Amount);
            tmdata[i].TransferToBill = false;
            let actionList = [
                {
                    icon: "file-invoice", name: "Rechnung erstellen", action: async () => {
                        this.selectedMonitorings([]);
                        await this.suggestBillNumber();
                        $('#DLK_modal').modal("show");
                        this.setDLKModal(tmdata[i]);
                        if (tmdata[i].Type === "Akte") {
                            this.getHourlyForCase(durationMin);
                        }
                        else {
                            this.monAmount(tmdata[i].Amount);
                            this.monCaseHourly('');
                        }
                        if (ko.toJS(this.monAmount).startsWith("0,00"))
                            this.isAmountZero(true);
                        else
                            this.isAmountZero(false);
                        this.monSingle(true);
                        this.getReciepiant(tmdata[i].CaseID);
                    }
                },
                {
                    icon: "trash-alt", name: "Löschen", action: async () => {
                        await RNSAPI.DeleteTimeMonitoring(tmdata[i].ID);
                        this.allMonitorings([]);
                        this.GetDLK();
                    }
                },
            ];
            //tmdata[i].checkBoxAction = actionList;
            if(!this.isViewerRole()) {
                tmdata[i].actionHandlers = actionList;
            }
            this.allMonitorings.push(tmdata[i]);

        }
    }

    async getHourlyForCase(span) {
        let res = (await RNSAPI.getExtendedCase(ko.toJS(this.monCaseID))).Payload;
        //this.monAmount(res.Akte.Stundensatz);
        this.monCaseHourly(this.formatAmount(res.Akte.Stundensatz));
        let caseHourlyPrice = Number(res.Akte.Stundensatz) / 60 * Number(span);
        this.monAmount(this.formatCurrencyInput(caseHourlyPrice));
        if (ko.toJS(this.monAmount).startsWith("0,00"))
            this.isAmountZero(true);
        else
            this.isAmountZero(false);
    }

    private async suggestBillNumber(): Promise<void> {
        let billNumber = (await RNSAPI.rvgSuggestId()).Payload.Rechnungsnummer;
        this.rechNr(billNumber);
    }

    async newMassDLK() {
        await this.suggestBillNumber();
        this.selectedMonitorings([]);
        $('#DLK_modal').modal("show");
        let AmountVal = 0;
        let CaseID = "";
        for (let entry of ko.toJS(this.allMonitorings())) {
            if (entry.TransferToBill) {
                this.setDLKModal(entry);
                AmountVal = AmountVal + entry.AmountNr;
                CaseID = entry.CaseID;
            }
        }
        this.monAmount(this.formatAmount(AmountVal));
        if (CaseID !== "")
            this.getReciepiant(CaseID);

    }

    manualDLK() {
        Postbox.publisher().publish(true, "isManualDLK");
        $("#timemonitoringModal").modal('show');
    }

    async setDLKModal(TMEntry) {
        this.monCaseID(TMEntry.CaseID);
        this.monSB(TMEntry.UserID);
        const calculatedStart = TMEntry.StartTime.split('T')[1].substring(0, ko.toJS(TMEntry.StartTime.split('T')[1]).length - 3);
        const calculatedEnd = TMEntry.EndTime.split('T')[1].substring(0, ko.toJS(TMEntry.EndTime.split('T')[1]).length - 3);
        this.selectedMonitorings.push({
            ID: TMEntry.ID,
            Datum: moment(ko.toJS(TMEntry.StartTime.split('T')[0])).format('DD.MM.YYYY'),
            Start: calculatedStart,
            Ende: calculatedEnd,
            Dauer: TMEntry.Duration,
            Tatigkeit: TMEntry.Task,
            SB: TMEntry.UserID,
            Type: TMEntry.Type,
            Sent: TMEntry.Sent
        });
    }

    async generateTMBill() {
        if(!this.postGenerateTMBill()) {
            this.postGenerateTMBill(true);
            let tmArray = [];
            let Reciepient = ko.toJS($("#selectedMandantModal").val());
            let MWST = $("#selectedMWSTDLK").val() === "19" ? 19 : 16;
            let caseID = ko.toJS(this.monCaseID);
            for (let calculation of ko.toJS(this.selectedMonitorings())) {
                tmArray.push({
                    Recipient: Reciepient,
                    ID: calculation.ID,
                    CaseID: caseID,
                    Sent: calculation.Sent,
                    UserID: calculation.SB,
                    Task: calculation.Tatigkeit,
                    MWST: MWST,
                    Type: ""
                });
            }
    
            let res = (await RNSAPI.BillTimeMonitoring(ko.toJS(tmArray)));
            if (res.Type === "BillTimeMonitoringSuccessful") {
                $("#DLK_modal").modal("hide");
                this.allMonitorings([]);
                this.GetDLK();
                await this.download('Dienstleistungskosten', res.Payload.DokumentenID);
                Postbox.publisher().publish("", "ResetFilter");
            }
            this.postGenerateTMBill(false);
        }
    }

    async getReciepiant(ca) {
        if (ca !== "") {
            let address = (await RNSAPI.getPersonsForCaseId(ca.toString())).Payload.Addresses[0];
            if (address.length > 0) {
                this.allMandantenForCase(address);

            }
            else {
                //Kein Mandant in Akte
                //Od keine echte Akte
            }
        }
    }

    formatAmount(input: number) {
        let decimals = 0;
        let decimalstring = "00";
        let output = "";
        decimals = input % 1;
        input = input - decimals;
        if (decimals !== 0) {
            decimalstring = ko.toJS((Math.round((decimals + Number.EPSILON) * 100) / 100).toString());
            decimalstring = decimalstring.toString().substring(2, decimalstring.length);
        }
        output = input + "," + decimalstring + " €";
        return output;
    }

    getHoursFromMinutes(input: number) {
        var hours = 0;
        var minutes = 0;
        minutes = input % 60;
        hours = (input - minutes) / 60;
        var minutestring = "";
        if (minutes < 10)
            minutestring = "0" + minutes;
        else
            minutestring = "" + minutes;
        let output = ko.toJS(hours) + ':' + minutestring;
        return output;
    }

    formatDate(input: Date) {
        var hoursString = "";
        var minutesString = "";
        if (input.getHours() < 10)
            hoursString = "0" + input.getHours();
        else
            hoursString = "" + input.getHours();

        if (input.getMinutes() < 10)
            minutesString = "0" + input.getMinutes();
        else
            minutesString = "" + input.getMinutes();

        return hoursString + ":" + minutesString;
    }

   
 
    formatValue(input: number) {
        let decimals = 0;
        let decimalstring = "00";
        let output = "";
        decimals = input % 1;
        input = input - decimals;
        if (decimals !== 0) {
            decimalstring = ko.toJS((Math.round((decimals + Number.EPSILON) * 100) / 100).toString());
            decimalstring = decimalstring.toString().substring(2, decimalstring.length);
        }
        output = input + "," + decimalstring;
        return output;
    }


  
    isEditMode = ko.observable(false);
    currentUpdate = {};


    InRVGRange(value) {
        let num = parseFloat(value.split('.')[0]);
        let test = (num < 7009 || num >= 7000) ? true : false;
        return test;
    }

 
    async setLastCase() {
        let data = (await RNSAPI.receiveUserData()).lastVisitedCaseId;
        $("#pnr").val(data);
        // this.loadRVGEntries();
    }

    resetObject(obj) {
        for (let key in obj) {
            obj[key](null);
        }
    }


    round(value, decimals) {
        let val: any = value + 'e' + decimals;
        return Number(Math.round(val) + 'e-' + decimals);
    }

   

    initializeReminder(data) {
        this.reminder({
            AdressatSuchbegriff: ko.observable(data ? data.AdressatSuchbegriff : ''), //Address search term
            AdressatTyp: ko.observable(data ? data.AdressatTyp : ''), //Address type
            Aktion: ko.observable(data ? data.Aktion : null),
            DatUhr: ko.observable(data ? data.DatUhr : ''),
            Geldbetrag: ko.observable(data ? data.Geldbetrag : ''), //Amount of money
            GezahltAm: ko.observable(data ? data.GezahltAm : null),
            MahnDatum: ko.observable(data ? moment(data.MahnDatum).format('YYYY-MM-DD') : this.nextMonth(null)), //Reminder Date = +4weeks from today.
            MahnNr: ko.observable(data ? data.MahnNr : 'HV'), //Reminder
            Mahnung1: ko.observable(data ? data.Mahnung1 : null),
            Mahnung2: ko.observable(data ? data.Mahnung2 : null),
            Mahnung3: ko.observable(data ? data.Mahnung3 : null),
            MwSt: ko.observable(data ? data.MwSt : '19,00'), //VAT
            Rechnungsnummer: ko.observable(data ? data.Rechnungsnummer : ''), //Bill number
            Registernummer: ko.observable(data ? data.Registernummer : $("#pnr").val()), //CaseID/Register number
            Restbetrag: ko.observable(data ? data.Restbetrag : ''), //Balance
            Sachbearbeiter: ko.observable(data ? data.Sachbearbeiter : ''), //Clerk
            SchreibenVom: ko.observable(data ? data.SchreibenVom : moment.utc().format()), //Writing From
            Status: ko.observable(data ? data.Status : 'O'), //TODO: Please check me out If I am 'O' or '0'
            Wegen: ko.observable(data ? data.Wegen : 'Gebührenrefassung RVG') //Reason: 'Gebührenrefassung RVG'
        });
    }

    nextMonth(date) {
        let currentDate = date ? moment(date) : moment();
        let futureMonth = moment(currentDate).add(1, 'M');
        let futureMonthEnd = moment(futureMonth).endOf('month');

        if (currentDate.date() != futureMonth.date() && futureMonth.isSame(futureMonthEnd.format('YYYY-MM-DD'))) {
            futureMonth = futureMonth.add(1, 'd');
        }

        return moment.parseZone(futureMonth).utc().format();
    }

    //MARK: - Add new remainder
    async addReminder(reminder) {

        if (RNSAPI.DEBUG_Mode) console.log(reminder);
        try {

            let resultType = (await RNSAPI.addReminder(reminder)).Type;
            if (resultType !== 'AddSuccessful') {
                throw new Error('Unable to add invoice!');
            }
            if (RNSAPI.DEBUG_Mode) console.log('successfully added invoice');
            this.getAllReminders();

        } catch (e) {
            throw new Error(e);
        }

    }

    reminders = ko.observableArray([]);
    dlcosts = ko.observableArray([]);

    async getAllReminders() {

        try {

            let entries = (await RNSAPI.getRemindersByCaseId('')).Payload.Reminders
                .sort((a, b) => {
                    let dateA = new Date(a.MahnDatum);
                    let dateB = new Date(b.MahnDatum);
                    if (dateA < dateB) return 1;
                    else if (dateA > dateB) return -1;
                    else return 0
                })
                .map((entry) => {
                    // entry.MahnDatum = this.dateFormat(entry.MahnDatum);
                    entry.MahnDatum1 = this.dateFormat(entry.MahnDatum);
                    entry.WegenText = entry.Wegen.length > 30 ? entry.Wegen.substring(0, 30) + '...' : entry.Wegen;
                    entry.GeldbetragText = entry.Geldbetrag + ' €';
                    if(!this.isViewerRole()) {
                        entry.actionHandlers = [
                            {
                                'icon': 'pencil-alt',
                                'name': 'Pencil',
                                'action': () => {
                                    this.editReminder(entry);
                                }
                            },
                            {
                                'icon': 'paperclip',
                                'name': 'Paperclip',
                                'action': () => {
                                    this.openWorkflowModal();
                                }
                            }, {
                                'icon': 'trash-alt',
                                'name': 'Delete',
                                'action': () => {
                                    this.deleteReminder(entry.DatUhr);
                                }
                            }
                        ];
                    }
                    return entry;
                });

            this.reminders(entries);

        } catch (e) {
            console.log(e)
        }
    }

    async getRemindersByStichtag() {
        if ($('#searchByStichtag').val()) {
            let stichTag = $('#searchByStichtag').val();
            this.reminders((await RNSAPI.getRemindersByStichtag('2019-05-19')).Payload.Reminders);
        }
    }

    async getRemindersByAdressat() {
        if ($('#searchByAddress').val()) {
            let address = $('#searchByAddress').val();
            this.reminders((await RNSAPI.getRemindersByAdressat(address.toString())).Payload.Reminders);
        }
    }

    async getRemindersByCaseId() {
        if ($('#searchByCaseId').val()) {
            let caseId = $('#searchByCaseId').val();
            this.reminders((await RNSAPI.getRemindersByCaseId(caseId.toString())).Payload.Reminders);
        }
    }

    async deleteReminder(datUhr) {

        try {

            let response = (await RNSAPI.deleteReminder(datUhr)).Type;
            if (response !== 'DeleteSuccessful') {
                throw new Error('Deletion Unsuccessful');
            }

            this.getAllReminders();

        } catch (e) {
            console.log(e);
        }
    }

    editReminder(row) {
        this.initializeReminder(row)
        $('#mahnlisteModal').modal('show');
    }

    async updateReminder() {

        if (this.reminderRadionBtn() === '2') {
            this.reminder().MahnDatum(this.reminder().MahnDatum());
            this.addReminder(ko.toJS(this.reminder()));
        }

        try {

            if (this.reminderLevel() === '1') {
                this.reminder().MahnDatum(this.nextMonth(null));
            }
            if (this.reminderLevel() === '2') {
                this.reminder().MahnDatum(this.nextMonth(this.reminder().MahnDatum()));
            }
            if (this.reminderLevel() === '3') {
                this.reminder().MahnDatum(this.nextMonth(this.reminder().MahnDatum()));
            }

            let response = (await RNSAPI.updateReminder(ko.toJS(this.reminder()))).Type;
            if (response !== 'UpdateSuccessful') {
                throw new Error('Update Unsuccessful');
            }

            this.getAllReminders();
            $('#mahnlisteModal').modal('hide');

        } catch (e) {
            $('#mahnlisteModal').modal('hide');
            console.log(e);
        }
    }

    accounting = ko.observable({
        AdressatSuchbegriff: ko.observable(''), //Address search term
        AdressatTyp: ko.observable('1'), //Address type
        Betrag: ko.observable(''), //Amount
        Currency: ko.observable('€'), //Currency
        Endsumme: ko.observable(''), //Summe Amount
        IsLawOrNotaryOffice: ko.observable('A'),
        Rechnungsdatum: moment().format(), //Invoice date.
        Rechnungsnummer: ko.observable(''), //Bill number
        Registernummer: ko.observable(''), //CaseID/Register number
        Status: ko.observable('R'),
    });

   

    accountings = ko.observableArray([]);


    async saveToWord(caseId, keyword, rechnungsnummer) {

        let name = 'Gebührenerfassung RVG';
        let result = await RNSAPI.generateCaseEntryText(caseId, name, 'mandant', keyword, 'Standardtext', rechnungsnummer);

        if (result.Type === "GenerationSuccessful") {
            //TODO typo on the server fix later!! should of course be record id
            let documentId = result.Payload.CaseId;
            let caseEntries = (await RNSAPI.getCaseEntriesForId(caseId)).Payload.Records;
            let recordId = caseEntries.filter(entry => {
                return entry.DocDBIds.indexOf(documentId) !== -1;
            })[0].Id;

            RNSAPI.createHistoryEntry(caseId, "Texterzeugung / " + name);

            await this.download(name, recordId);
        }
    }

    async download(name: string, id: string) {

        let element = document.createElement('a');
        let doc = (await RNSAPI.getDocumentData(id)).Payload.Document;

        //server side bug in the ActiveX Component => OleType is doc instead of rtf
        let IsRTF = atob(doc.DocumentData.substr(0, 50)).indexOf("rtf") !== -1;
        let type = IsRTF ? "rtf" : doc.OLE2Type.trim().toLowerCase();

        let blob = Utils.base64ToBlob(doc.DocumentData, 'application/octet-stream');

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob);
        } else {
            element.setAttribute('href', 'data:application/octet-stream;charset=utf-16le;base64,' + doc.DocumentData);
            element.setAttribute('download', `${name.trim()}.${type}`);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }
    }

    switchTab() {
        this.currentView('abrechnung');
    }

    async getAllRechnungsausgangsbuch() {

        try {

            let entries = (await RNSAPI.getRechnungsausgangsbuchByCaseId('')).Payload.CalculationOutputBooks
                .sort((a, b) => {
                    let dateA = new Date(a.Rechnungsdatum);
                    let dateB = new Date(b.Rechnungsdatum);
                    if (dateA < dateB) return 1;
                    else if (dateA > dateB) return -1;
                    else return 0
                })
                .map((entry) => {
                    entry.Rechnungsdatum = this.dateFormat(entry.Rechnungsdatum);
                    entry.BetragText = entry.Betrag + ' €';
                    entry.EndsummeText = entry.Endsumme + ' €';
                    if(!this.isViewerRole()) {
                        entry.actionHandlers = [
                            {
                                'icon': 'paperclip',
                                'name': 'Paperclip',
                                'action': () => {
                                    this.openWorkflowModal();
                                }
                            }
                        ];
                    }
                    return entry;
                });

            this.accountings(entries);

        } catch (e) {
            console.log(e);
        }
    }

    async getRechnungsausgangsbuchByCaseId() {
        if ($('#searchByCaseId').val()) {
            let caseID = $('#searchByCaseId').val();
            this.accountings((await RNSAPI.getRechnungsausgangsbuchByCaseId(caseID.toString())).Payload.CalculationOutputBooks);
        }
    }

    async getRechnungsausgangsbuchByRange() {

        if ($('#dateFrom').val() && $('#dateTo').val()) {

            try {

                let from = $('#dateFrom').val();
                let to = $('#dateTo').val();

                let entries = (await RNSAPI.getRechnungsausgangsbuchByRange(from.toString(), to.toString())).Payload.CalculationOutputBooks
                    .sort((a, b) => {
                        let dateA = new Date(a.Rechnungsdatum);
                        let dateB = new Date(b.Rechnungsdatum);
                        if (dateA < dateB) return 1;
                        else if (dateA > dateB) return -1;
                        else return 0
                    })
                    .map((entry) => {
                        entry.Rechnungsdatum = this.dateFormat(entry.Rechnungsdatum);
                        entry.Betrag = entry.Betrag + ' €';
                        entry.Endsumme = entry.Endsumme + ' €';
                        if(!this.isViewerRole()) {
                            entry.actionHandlers = [
                                {
                                    'icon': 'paperclip',
                                    'name': 'Paperclip',
                                    'action': () => {
                                        this.openWorkflowModal();
                                    }
                                }
                            ];
                        }
                        return entry;
                    });

                this.accountings(entries);

            } catch (e) {
                console.log(e);
            }
        }
    }

    async getRechnungsausgangsbuchByRechnungsnummer() {

        if ($('#searchByRechnungnummer').val()) {

            try {

                let rechnungnummer = $('#searchByRechnungnummer').val();
                let entries = (await RNSAPI.getRechnungsausgangsbuchByRechnungsnummer(rechnungnummer.toString())).Payload.CalculationOutputBooks
                    .sort((a, b) => {
                        let dateA = new Date(a.Rechnungsdatum);
                        let dateB = new Date(b.Rechnungsdatum);
                        if (dateA < dateB) return 1;
                        else if (dateA > dateB) return -1;
                        else return 0
                    })
                    .map((entry) => {
                        entry.Rechnungsdatum = this.dateFormat(entry.Rechnungsdatum);
                        entry.Betrag = entry.Betrag + ' €';
                        entry.Endsumme = entry.Endsumme + ' €';
                        if(!this.isViewerRole()) {
                            entry.actionHandlers = [
                                {
                                    'icon': 'paperclip',
                                    'name': 'Paperclip',
                                    'action': () => {
                                        this.openWorkflowModal();
                                    }
                                }
                            ];
                        }
                        return entry;
                    });

                this.accountings(entries);

            } catch (e) {
                console.log(e);
            }
        }
    }

    openWorkflowModal() {
        this.modalKeys(["WFK"]);
        this.modalColumns(["WFK"]);
        this.modalData(Object.keys(this.wfkOutMap).map(wfk => { return { WFK: wfk }; }));
        this.allItems(Object.keys(this.wfkOutMap).map(wfk => { return { WFK: wfk }; }));
        this.modalTitle("Workflow Mark");
        $('#workflowModal').modal('show');
    }

    markEntry = (data) => {
        let recordId = '';
        this.modalSelectedObject(data)
        if (this.modalData().length === 1) {
            this.setMark(recordId, this.modalData()[0].WFK);
        } else {
            this.setMark(recordId, this.modalSelectedObject().WFK);
            $('#workflowModal').modal('hide');
        }
    }

    async setMark(entryId: string, mark: string) {
        await RNSAPI.setCaseEntryWorkflowMark(entryId, mark);
    }

    private readonly wfkOutMap = {
        "RA": ["B", "V"],
        "B": ["ZU", "RA"],
        "V": ["PA"]
    }

    dateFormat(date) {
        return moment(date).format('DD.MM.YYYY');
    }


    entries: ko.Computed<Array<any>>;

    constructor() {
        this.isViewerRole(RolesAndRights.isViewerRole());
        this.setLastCase();

        Postbox.publisher().subscribe((response) => {
            this.allMonitorings([]);
            this.GetDLK();
        }, "UpdateDLK");

        Postbox.publisher().subscribe((response) => {
            if(response) {
                this.getAllRechnungsausgangsbuch();
            }
        }, "refreshReminders");

        $(document).ready(function () {

            $.ajaxSetup({
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true
            });

        });

        this.GetDLK();

        this.initializeReminder(null);

        


        this.allItems = ko.observableArray([]);

        this.filteredItems = ko.computed({
            owner: this,
            read: () => {
                let filter = this.filter().toLowerCase();
                let items = this.allItems();
                return items.filter(item => {
                    for (let value of ko.toJS(this.modalKeys).map(column => item[column]) as Array<string>) {
                        if (value && value.toLowerCase().indexOf(filter) !== -1) return true;
                    }
                    return false;
                })

            }
        })

        this.entries = ko.computed({
            owner: this,
            read: () => {
                return this.filteredItems();
            }
        })

        this.entries = ko.computed({
            owner: this,
            read: () => {
                return this.filteredItems();
            }
        })

        this.getAllRechnungsausgangsbuch();
        this.getAllReminders();
    }
}


let html = fs.readFileSync(__dirname + '/calculator.html', 'utf8');

ko.components.register("calculator-view", {
    viewModel: CalculatorViewModel,
    template: html
});
