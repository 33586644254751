export let layoutVerkehrsrecht = JSON.parse(`{
  "Identifikationsdaten": [
    [
      {
        "Label": "Identifikationsdaten",
        "Type": "HEADLINE",
        "Size": 12,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Registernummer*",
        "Database": "",
        "DbText": "LRegnr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "Registernummer",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 13,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Referat*",
        "Database": "",
        "DbText": "LblReferat",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "Referatnummer",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 2,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Nein/Undefiniert",
        "DbText": "Zuo_refnr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Sachbearbeiter*",
        "Database": "",
        "DbText": "LblSachbearbeiter",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "SachbearbeiterId",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 2,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sachbearbeiter",
        "SelectionProperty": "Sachbearbeiter_ID"
      },
      {
        "Label": "",
        "Database": "Nein/Undefiniert",
        "DbText": "Zuo_sachnr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Sachbearbeiter",
        "SelectionProperty": "Sachbearbeiter"
      }
    ],
    [
      {
        "Label": "Anlagedatum",
        "Database": "",
        "DbText": "lblAnlagedatum",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "AnlageDatum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 10,
        "Alignment": null,
        "WebInfo": "Datum",
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Rubrum*",
        "Database": "",
        "DbText": "LblRubrum",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "Rubrum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 64,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Wegen*",
        "Database": "",
        "DbText": "LblWegen",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "Wegen",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 64,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Kennz. Ausl Mandant",
        "Database": "",
        "DbText": "LKausmd",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "IsAuslaendischesMandat",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 1,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Kennz. Beitr. Eigene",
        "Database": "",
        "DbText": "Lkbeiei",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "IsEigeneBeitreibung",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 1,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "VorSt. Abzugsber. Mdt.",
        "Database": "",
        "DbText": "LKvstab",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": null,
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 1,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Mandant ist Kläger",
        "Database": "",
        "DbText": "LMdtKläger",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": null,
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 1,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "AZ Mandant",
        "Database": "",
        "DbText": "LAzmdt",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": null,
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Organisationsdaten": [
    [
      {
        "Label": "Organisationsdaten",
        "Type": "HEADLINE",
        "Size": 12,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "WV Grund",
        "Database": "",
        "DbText": "LblWVGrund",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "FristNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Nein/Undefiniert",
        "DbText": "Zuo_frinr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "WV Ablauf",
        "Database": "",
        "DbText": "LblWVAblauf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "FristDatum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 10,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Sachstand",
        "Database": "",
        "DbText": "LblSachbestand",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": null,
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Nein/Undefiniert",
        "DbText": "Zuo_Sachstand",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Mahnkennung",
        "Database": "",
        "DbText": "Lmahnnr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "MahnNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Nein/Undefiniert",
        "DbText": "Zuo_Mahnnr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Gegenstandswert ",
        "Database": "",
        "DbText": "LWert1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": null,
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 12,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "MEK",
        "Database": "",
        "DbText": "LMEK",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "MEK",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 12,
        "Alignment": null,
        "WebInfo": "Datum",
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Mandatsherkunft",
        "Database": "",
        "DbText": "LblMandat",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Values": [
          "",
          "Empfehlung",
          "Homepage/Internet",
          "Telefonbuch",
          "Regionale Anzeige",
          "Rechtsberater Kontaktformular",
          "Rechtsberater Hilfehotline",
          "Rechtsberater Anwaltshotline",
          "ADAC",
          "Sonstiges"
        ],
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Mandat",
        "Type": "COMBOBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Sachverhalt": [
    {
      "Name": "Allgemein",
      "Elements": [
        [
          {
            "Label": "Mandantenbegehren",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Notiz_Mandantenbegehren",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Notiz",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sachverhalt",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Notiz_Sachverhalt",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Notiz",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fallkonstellation",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Fallkonstellation",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Notiz",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kfz Daten",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Halter des Fahrzeugs",
            "Database": "",
            "DbText": "LblHalter_des_Fahrzeugs",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "",
            "DbText": "Fahrzeughalter",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "aktueller KM-Stand",
            "Database": "",
            "DbText": "LblKfz_KM_Stand",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "",
            "DbText": "Kfz_KM_Stand",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Nummer",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Marke",
            "Database": "",
            "DbText": "LblHersteller",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Kfz_Hersteller",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Anzahl der Halter",
            "Database": "",
            "DbText": "LblAnzahlhalter",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Anzahlhalter",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Nummer",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Modell",
            "Database": "",
            "DbText": "LblTyp",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Kfz_Typ",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Leistung KW / PS",
            "Database": "",
            "DbText": "LblKfz_Leistung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Kfz_Leistung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Tag der Erstzulassung",
            "Database": "",
            "DbText": "LblKfz_Zulassungsdatum",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Kfz_Zulassungsdatum",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Kaufpreis",
            "Database": "",
            "DbText": "LblKfz_Kaufpreis",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Kfz_Kaufpreis",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Amtliches Kennzeichen",
            "Database": "",
            "DbText": "LblAmtliches_Kennzeichen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Kfz_Kennzeichen",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Art der Finanzierung",
            "Database": "",
            "DbText": "LblKfz_Finanzierung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "keine",
              "Leasing",
              "Darlehen"
            ],
            "Label": "Leasing",
            "Database": "XML/Default",
            "DbText": "Kfz_Finanzierung",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fahrgestellnummer",
            "Database": "",
            "DbText": "LblFahrgestellnummer",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Kfz_Fahrgestellnummer",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Tag des Erwerbes",
            "Database": "",
            "DbText": "LblKfz_Erwerbungsdatum",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Kfz_Erwerbungsdatum",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Baujahr",
            "Database": "",
            "DbText": "LblKfz_Baujahr",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Kfz_Baujahr",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Nummer",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Darlehen bei",
            "Database": "",
            "DbText": "LblDarlehen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "",
            "DbText": "Darlehengeber",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Darlehensnummer",
            "Database": "",
            "DbText": "LblDarlehensnummer",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Darlehensnummer",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Leasing bei",
            "Database": "",
            "DbText": "LblLeasing",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "",
            "DbText": "Leasinggeber",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Leasingsnummer",
            "Database": "",
            "DbText": "LblLeasingsnummer",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Leasingsnummer",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Daten Fahrerlaubnis",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fahrer",
            "Database": "",
            "DbText": "LblMandantfahrer",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "",
            "DbText": "Fahrer",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Ausgestellt am:",
            "Database": "",
            "DbText": "LblAusgestellt_am",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Fuehrerschein_Ausstellungsdatum",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Führerscheinklasse",
            "Database": "",
            "DbText": "LblFührerscheinklasse",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Fuehrerschein_Klassen",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Ausstellende Behörde",
            "Database": "",
            "DbText": "LblAusstellende_Behörde",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Fuehrerschein_AusstellendeBehoerde",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Persönliche Daten Mandant",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Einkommen",
            "Database": "",
            "DbText": "LblEinkommen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Einkommen",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Familienstand",
            "Database": "",
            "DbText": "LblFamilienstand",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Familienstand",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Anzahl Kinder",
            "Database": "",
            "DbText": "LblAnzahlKinder",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "AnzahlKinder",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Nummer",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Arbeitgeber",
            "Database": "",
            "DbText": "LblArbeitgeber",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "",
            "DbText": "Arbeitgeber",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Personenbeförderung",
            "Database": "",
            "DbText": "LblArbeitgeber_Personenbefoerderung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Personenbefoerderung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zugehörige Dokumente",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fahrzeugschein",
            "Database": "",
            "DbText": "ChkDok_Fahrzeugschein",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Führerschein",
            "Database": "",
            "DbText": "ChkDok_Fuehrerschein",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gegnerdaten",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Amtliches Kennzeichen",
            "Database": "",
            "DbText": "LblGegnerKfz_Kennzeichen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "GegnerKfz_Kennzeichen",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Modell",
            "Database": "",
            "DbText": "LblGegnerKfz_Typ",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "GegnerKfz_Typ",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Marke",
            "Database": "",
            "DbText": "LblGegnerKfz_Hersteller",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "GegnerKfz_Hersteller",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fahrzeugtyp",
            "Database": "",
            "DbText": "LblGegnerFahrzeugtyp",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Pkw",
              "Lkw",
              "Motorrad",
              "motorisierte Zweiräder",
              "Anhänger",
              "Zugmaschinen"
            ],
            "Label": "",
            "Database": "",
            "DbText": "GegnerFahrzeugtyp",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Baujahr",
            "Database": "",
            "DbText": "LblGegnerKfz_Baujahr",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "GegnerKfz_Baujahr",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fahrgestellnummer",
            "Database": "",
            "DbText": "LblGegnerKfz_Fahrgestellnummer",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "GegnerKfz_Fahrgestellnummer",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fahrzeughalter",
            "Database": "",
            "DbText": "LblFahrzeughalterGegner",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "",
            "DbText": "Gegnerfahrzeughalter",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fahrer",
            "Database": "",
            "DbText": "LblGegnerfahrer",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "",
            "DbText": "Gegnerfahrer",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Bei Abweichung zum Fahrzeughalter bitte angeben",
            "Database": "",
            "DbText": "LblBei_Abweichung_zum_Fahrzeughalter_bitte_angebenGegner",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Haftpflichtversicherung",
            "Database": "",
            "DbText": "LblGegnerhaftpflichtversicherung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "",
            "DbText": "Gegnerhaftpflichtversicherung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schein-/Schadennummer",
            "Database": "",
            "DbText": "Lblvzrallggegner_Verz_Schadennummer",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "GegnerHaftplicht_Schadennummer",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Inanspruchnahme Kaskoversicherung",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wert Mehraufwand Kaskoprämie",
            "Database": "",
            "DbText": "LblKaskopraemie",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Kaskopraemie",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wert Selbstbeteiligungsanteil",
            "Database": "",
            "DbText": "LblKaskoSelbstbeteiligung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "KaskoSelbstbeteiligung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schreiben Prämienmehraufwand des Vollkaskoversichers",
            "Database": "",
            "DbText": "ChkDok_InanspruchSchreiben",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sind sonstige Schäden entstanden?",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "Nein",
            "Database": "XML/Default",
            "DbText": "SonstigeSchadenYN",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kleiderschaden",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Genaue Bezeichnung",
            "Database": "",
            "DbText": "LblKleiderschadenBezeichnung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "KleiderschadenBezeichnung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Anschaffungsdatum",
            "Database": "",
            "DbText": "LblKleiderschadenAnschaffungDatum",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "KleiderschadenAnschaffungDatum",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Preis",
            "Database": "",
            "DbText": "LblKleiderschadenAnschaffungPreis",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "KleiderschadenAnschaffungPreis",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gepäckschaden",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Bezeichnung",
            "Database": "",
            "DbText": "LblGepaeckschadenBezeichnung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "GepaeckschadenBezeichnung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Anschaffungsdatum",
            "Database": "",
            "DbText": "LblGepaeckschadenAnschaffungDatum",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "GepaeckschadenAnschaffungDatum",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Preis",
            "Database": "",
            "DbText": "LblGepaeckschadenAnschaffungPreis",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "GepaeckschadenAnschaffungPreis",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sonstige Schaden",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Bezeichnung",
            "Database": "",
            "DbText": "LblSonstigeschadenBezeichnung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "SonstigeschadenBezeichnung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Anschaffungsdatum",
            "Database": "",
            "DbText": "LblSonstigeschadenAnschaffungDatum",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "SonstigeschadenAnschaffungDatum",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Preis",
            "Database": "",
            "DbText": "LblSonstigeschadenAnschaffungPreis",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "SonstigeschadenAnschaffungPreis",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sind die Unfallersatzansprüche abgetreten?",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "UnfallersatzYN",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Abtretungsempfänger",
            "Database": "",
            "DbText": "LblAbtretungsempfaenger",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "",
            "DbText": "Abtretungsempfänger",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum der Abtretung",
            "Database": "",
            "DbText": "LblUnfallersatz_Abtretung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Unfallersatz_Abtretung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Abtretungserklärung",
            "Database": "",
            "DbText": "ChkDok_Abtretungserklärung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Verkehrsunfall",
      "Elements": [
        [
          {
            "Label": "Unfallumstände",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Unfall",
            "Database": "",
            "DbText": "LblDatum_Unfall_Unfallsachen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Unfallumstaende_Datum",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Unfallort ",
            "Database": "",
            "DbText": "LblUnfallort_Unfallsachen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Unfallumstaende_Ort",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Uhrzeit",
            "Database": "",
            "DbText": "LblUnfallumstaende_Zeit",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Unfallumstaende_Zeit",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Unfallhergang",
            "Database": "",
            "DbText": "Lblnotiz_Unfallhergang",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "notiz_Unfallhergang",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Straßenverhältnisse",
            "Database": "",
            "DbText": "Lblnotiz_Strassenverhaeltnisse",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "notiz_Strassenverhaeltnisse",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sichtverhältnisse",
            "Database": "",
            "DbText": "Lblnotiz_Sichtverhaeltnisse",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "notiz_Sichtverhaeltnisse",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Polizeiliche Unfallaufnahme",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Dienststelle",
            "Database": "",
            "DbText": "LblUnfallaufnahme_Dienststelle",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Unfallaufnahme_Dienststelle",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Straße",
            "Database": "",
            "DbText": "LblUnfallaufnahme_Strasse",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Unfallaufnahme_Strasse",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "PLZ / Ort",
            "Database": "",
            "DbText": "LblUnfallaufnahme_plzort",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Unfallaufnahme_Ort",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Telefon",
            "Database": "",
            "DbText": "LblUnfallaufnahme_Telefon",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Unfallaufnahme_Telefon",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Tagebuchnr. / Aktenzeichen",
            "Database": "",
            "DbText": "LblUnfallaufnahme_Tagebuchnr",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Unfallaufnahme_Tagebuchnr",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mandant Gebührenpflichtige",
            "Database": "",
            "DbText": "LblUnfallaufnahme_GebuehrenMandant",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Verwarnung",
              "Strafanzeige",
              "Bußgeld-Verfahren"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Unfallaufnahme_GebuehrenMandant",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gegner Gebührenpflichtige",
            "Database": "",
            "DbText": "LblUnfallaufnahme_GebuehrenGegner",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Verwarnung",
              "Strafanzeige",
              "Bußgeld-Verfahren"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Unfallaufnahme_GebuehrenGegner",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Benötigte Dokumente",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Personalienaustausch",
            "Database": "",
            "DbText": "ChkDok_Personalienaustausch",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fahrzeugschaden",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kfz-Haftpflichtversicherung",
            "Database": "",
            "DbText": "LblKfz_Haftpflichtversicherung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "",
            "DbText": "Haftpflichtversicherung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "zus. Versicherungsumfang",
            "Database": "",
            "DbText": "LblVersicherungsumfang",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "keine",
              "Teilkasko",
              "Vollkasko"
            ],
            "Label": "keine",
            "Database": "XML/Default",
            "DbText": "Versicherungsumfang",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Selbstbeteiligung: Teilkasko",
            "Database": "",
            "DbText": "LblTeilkasko_Selbstbeteiligung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Teilkasko_Selbstbeteiligung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Vollkasko",
            "Database": "",
            "DbText": "LblVollkasko_Selbstbeteiligung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Vollkasko_Selbstbeteiligung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Verkehrsservicevers.",
            "Database": "",
            "DbText": "LblVerkehrsserviceversicherung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "",
            "DbText": "Verkehrsserviceversicherung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Beschreibung Schadensumfang",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "notiz_Schadenumfang",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Reparaturkosten gemäß Kostenrechnung",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Reparaturbetrieb",
            "Database": "",
            "DbText": "LblReparaturbetrieb",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "",
            "DbText": "Reparaturbetrieb",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Reparaturkosten",
            "Database": "",
            "DbText": "LblReparaturkosten",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Reperaturkosten",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Reparaturkosten gemäß Kostenvoranschlag",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Reparaturbetrieb",
            "Database": "",
            "DbText": "LblReparaturbetriebAngebot",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "",
            "DbText": "ReparaturbetriebAngebot",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kostenvoranschlag",
            "Database": "",
            "DbText": "LblAngebotkosten",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Reparaturangebot",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Reparaturkosten gemäß Sachverständigengutachten",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sachverständiger",
            "Database": "",
            "DbText": "LblSachverstaendiger",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "",
            "DbText": "Sachverständiger",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Totalschaden",
            "Database": "",
            "DbText": "LblTotalschaden",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Totalschaden",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wertminderung",
            "Database": "",
            "DbText": "LblWertminderung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Wertminderung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Benötigte Dokumente",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sachverständigenrechnung",
            "Database": "",
            "DbText": "ChkDok_Sachverstaendigenrechnung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gutachten",
            "Database": "",
            "DbText": "ChkDok_Gutachten",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wurde das Fahrzeug abgeschleppt?",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "AbschleppYN",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Abschleppunternehmen",
            "Database": "",
            "DbText": "LblAbschleppunternehmen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "",
            "DbText": "Abschleppunternehmen",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Höhe Abschleppkosten",
            "Database": "",
            "DbText": "LblUnfallersatz_Appschleppkosten",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Abschleppkosten",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Rechnung Abschleppkosten",
            "Database": "",
            "DbText": "ChkDok_Abschlepprechnung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wird ein Mietvertrag in Anspruch genommen?",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "MietvertragYN",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Rechnungsdatum",
            "Database": "",
            "DbText": "Lbl2",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "MietvertragRechnungsdatum",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "tägliche Mietkosten",
            "Database": "",
            "DbText": "LblUnfallersatz_Mietkosten",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Unfallersatz_Mietkosten",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "gesamte Mietwagenkosten",
            "Database": "",
            "DbText": "LblMietwagenkostenGesamt",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "MietwagenkostenGesamt",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Übernahme Mietwagen",
            "Database": "",
            "DbText": "LblMietwagenUebernameVon",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "MietwagenUebernameVon",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Rückgabe Mietwagen",
            "Database": "",
            "DbText": "LblMietwagenUebernameBis",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "MietwagenUebernameBis",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mietwagenrechnung",
            "Database": "",
            "DbText": "ChkDok_Mietwagenrechnung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wird Nutzungsausfall in Anspruch genommen?",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "NutzungsausfallYN",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Anfang Nutzungsausfall",
            "Database": "",
            "DbText": "LblNutzungsausfallVon",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "NutzungsausfallVon",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Ende Nutzungsausfall",
            "Database": "",
            "DbText": "LblNutzungsausfallBis",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "NutzungsausfallBis",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wert Nutzungsausfall pro Tag",
            "Database": "",
            "DbText": "LblNutzungsausfallTag",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "NutzungsausfallTag",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Summe Nutzungsausfall",
            "Database": "",
            "DbText": "Lbl5",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "NutzungsausfallSumme",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gruppierung gemäß Schwackeliste",
            "Database": "",
            "DbText": "LblNutzungsausfallGruppierung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "NutzungsausfallGruppierung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Abmeldekosten Altfahrzeug",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Abmeldung",
            "Database": "",
            "DbText": "LblAbmeldungDatum",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "AbmeldungDatum",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Summe Abmeldung",
            "Database": "",
            "DbText": "LblAbmeldungSumme",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "AbmeldungSumme",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kostenbeleg Zulassungsstelle",
            "Database": "",
            "DbText": "ChkDok_AbmeldungKostenbeleg",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Anmeldekosten Ersatzfahrzeug",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Neuzulassung",
            "Database": "",
            "DbText": "LblNeuzulassungDatum",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "NeuzulassungDatum",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Summe Neuzulassung",
            "Database": "",
            "DbText": "LblNeuzulassungSumme",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "NeuzulassungSumme",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kostenbeleg Zulassungsstelle",
            "Database": "",
            "DbText": "ChkDok_AnmeldungKostenbeleg",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kostenbeleg Kfz-Kennzeichen",
            "Database": "",
            "DbText": "ChkDok_Kostenbeleg_KfzKennzeichen",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Zulassungsrecht",
      "Elements": [
        [
          {
            "Label": "Benötigte Dokumente",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Erstzulassung",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fahrzeugdaten",
            "Database": "",
            "DbText": "LblZul_Fahrzeugdaten",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ersthalterdaten",
            "Database": "",
            "DbText": "LblZul_Ersthalterdaten",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zulassung verweigert",
            "Database": "",
            "DbText": "LblZul_Zulassungverweigerung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Falsche Zulassung",
            "Database": "",
            "DbText": "LblZul_FalscheZulassung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zulassung eines gebrauchten Fahrzeugs",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fahrzeugbrief",
            "Database": "",
            "DbText": "ChkDok_ZulGebrauchtFahrzeugbrief",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "ggfs. EWG- Übereinstimmungs- Bescheinigung",
            "Database": "",
            "DbText": "ChkDok_ZulGebrauchtEWG",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Versicherung-Doppelkarte",
            "Database": "",
            "DbText": "ChkDok_ZulGebrauchtVersicherung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Personalausweis des zukünftigen Fahrzeughalters/Reisepass",
            "Database": "",
            "DbText": "ChkDok_ZulGebrauchtAusweis",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fahrzeugschein",
            "Database": "",
            "DbText": "ChkDok_ZulGebrauchtSchein",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Bei Firmen: Gewerbemeldung oder Handelsregisterauszug",
            "Database": "",
            "DbText": "ChkDok_ZulGebrauchtGewerbe",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Abmelde- / Stillegungsbescheinigung",
            "Database": "",
            "DbText": "ChkDok_ZulGebrauchtAbmelden",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Nachweis Haupt-/Abgasuntersuchung, wenn Fahrzeug älter als 3 Jahren",
            "Database": "",
            "DbText": "ChkDok_ZulGebrauchtAbgas",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zulassung für Fahrzeuge aus dem Ausland",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Benötigte Dokumente mit Fahrzeugsbrief",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fahrzeugbrief",
            "Database": "",
            "DbText": "ChkDok_ZulAuslandFahrzeugbrief",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Versicherung-Doppelkarte",
            "Database": "",
            "DbText": "ChkDok_ZulAuslandVersicherung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Auszug aus dem Vereinsregister",
            "Database": "",
            "DbText": "ChkDok_ZulAuslandVerein",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "ggfs. EWG- Übereinstimmungs- Bescheinigung",
            "Database": "",
            "DbText": "ChkDok_ZulAuslandEWG",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Personalausweis des zukünfitgen Fahrzeughalters/Reisepass",
            "Database": "",
            "DbText": "ChkDok_ZulAuslandAusweis",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gewerbeanmeldung oder Handelsregisterauszug",
            "Database": "",
            "DbText": "ChkDok_ZulAuslandGewerbe",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Bescheinigung über die Abgasuntersuchung für AU- pflichtige Fahrzeuge",
            "Database": "",
            "DbText": "ChkDok_ZulAuslandAbgas",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fahrzeugbrief und COC- Papiere/ Datenbestätigung/ TÜV Gutachten",
            "Database": "",
            "DbText": "ChkDok_ZulAuslandCOC",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Benötigte Dokumente ohne Fahrzeugsbrief",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Unbedenklichkeitsbescheinigung",
            "Database": "",
            "DbText": "ChkDok_ZulAuslandFahrzeugOhneUnbedenk",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zollunbedenklichkeitsbescheinigung bei Einfuhr außerhalb der EU",
            "Database": "",
            "DbText": "ChkDok_ZulAuslandFahrzeugOhneZoll",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Einfuhrumsatzsteuererklärung (nur bei Neuwagen) bei EU- Import",
            "Database": "",
            "DbText": "ChkDok_ZulAuslandFahrzeugOhneEinfuhr",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "ausländische Fahrzeugpapiere bzw. Kaufvertrag bzw. Importbescheinigung",
            "Database": "",
            "DbText": "ChkDok_ZulAuslandFahrzeugOhneImport",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Vollgutachten einer technischen Prüfstelle mit technischem Datenblatt",
            "Database": "",
            "DbText": "ChkDok_ZulAuslandFahrzeugOhneGutachten",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Bescheinigung über die Abgasuntersuchung für AU- pflichtige Fahrzeuge",
            "Database": "",
            "DbText": "ChkDok_ZulAuslandFahrzeugOhneAbgas",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zulassung verweigert",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Falsche Zulassung",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Saisonzulassung",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Händlerkennzeichen",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Roller, Moped und Mofa Kennzeichen",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wiederzulassung eines Fahrzeugs",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Benötigte Dokumente",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fahrzeugbrief",
            "Database": "",
            "DbText": "ChkDok_ZulWiederFahrzeugbrief",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kennzeichenschilder",
            "Database": "",
            "DbText": "ChkDok_ZulWiederKennzeichen",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "länger als 7 Jahre stillgelegt: Vollabnahme beim TÜV erforderlich",
            "Database": "",
            "DbText": "ChkDok_ZulWiederTUV",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Abmeldebestätigung oder Fahrzeugschein mit Stillegungsvermerk",
            "Database": "",
            "DbText": "ChkDok_ZulWiederAbmelde",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Vollmacht und Personalausweis/Reisepass (Meldebestätigung)",
            "Database": "",
            "DbText": "ChkDok_ZulWiederVollmacht",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Nachweis Abmeldebescheinigung oder Bericht Hauptuntersuchung",
            "Database": "",
            "DbText": "ChkDok_ZulWiederNachweis",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "ausgefüllte Versicherungsbestätigung",
            "Database": "",
            "DbText": "ChkDok_ZulWiederVersicherung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Nachweis der letzten Abgasuntersuchung",
            "Database": "",
            "DbText": "ChkDok_ZulWiederAbgas",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Handelsregisterauszug oder Gewerbeanmeldung",
            "Database": "",
            "DbText": "ChkDok_ZulWiederFirmenwagen",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zulassung eines Oldtimers",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Benötigte Dokumente",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gutachten eines Sachverständigen(der das Fahrzeug als historisch anerkennt)",
            "Database": "",
            "DbText": "ChkDok_ZulOldtimerGutachten",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schriftlicher Antrag mit Angaben zu dem betreffenden Kfz und dem Halter",
            "Database": "",
            "DbText": "ChkDok_ZulOldtimerAntrag",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Führungszeugnis nach der Belegeart 0 vom Bundeszentralregister in Bonn",
            "Database": "",
            "DbText": "ChkDok_ZulFuehrungszeugnis",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Auszug aus dem Verkehrszentralregister des Kraftfahrt- Bundesamtes",
            "Database": "",
            "DbText": "ChkDok_ZulAuszug",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Versicherungsbestätigung",
            "Database": "",
            "DbText": "ChkDok_ZulVersicherung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Nachweis, dass das Fahrzeug an Veranstaltungen zur Förderung des kraftfahrzeugtechnischen Kulturgutes teilgenommen hat (Teilnahme- oder Clubbescheinigung, Nennungsbestätigung usw)",
            "Database": "",
            "DbText": "ChkDok_ZulNachweis",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Betriebserlaubnis und Bauartgenehmigung",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Erlöschen der Betriebserlaubnis",
            "Database": "",
            "DbText": "Lblnotiz_BetriebserlaubnisErloeschen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "notiz_BetriebserlaubnisErloeschen",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Einholen einer Betriebserlaubnis",
            "Database": "",
            "DbText": "LblBetriebserlaubnisEinholen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "BetriebserlaubnisEinholen",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "ABE Teile und Papiere",
            "Database": "",
            "DbText": "LblBetriebserlaubnisABE",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "BetriebserlaubnisABE",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Abnahmepflicht",
            "Database": "",
            "DbText": "LblBetriebserlaubnisAbnahmepflicht",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Bau- und Betriebsvorschriften",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "BetriebserlaubnisAbnahmepflicht",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kraftfahrzeuge und ihre Anhänger",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Personalausweis/Reisepass",
            "Database": "",
            "DbText": "LblAnhaengerZulassungAusweis",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "AnhaengerZulassungAusweis",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fahrzeugbrief des Anhängers",
            "Database": "",
            "DbText": "LblAnhaengerFahrzeugBrief",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "AnhaengerFahrzeugBrief",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Herstellerbescheinigung oder Bestätigung einer Prüforganisation",
            "Database": "",
            "DbText": "LblAnhaengerZulassungHersteller",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "AnhaengerZulassungHersteller",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Versicherungsdoppelkarte oder Versicherungscode",
            "Database": "",
            "DbText": "LblAnhaengerZulassungVersicherung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "AnhaengerZulassungVersicherung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "EWG-Übereinstimmungsbescheinigung",
            "Database": "",
            "DbText": "LblAnhaengerZulassungEWG",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "AnhaengerZulassungEWG",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "andere Straßenfahrzeuge",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Verkehrsstrafrecht",
      "Elements": [
        [
          {
            "Label": "Schilderung des Tatvorwurfes",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "notiz_Tatvorwurf",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schilderung_des_Tatherganges",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "notiz_Tathergang",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Tatzeitpunkt",
            "Database": "",
            "DbText": "LblTatzeitpunkt",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Tatzeitpunkt",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Angabe Tatort",
            "Database": "",
            "DbText": "LblTatort",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Tatort",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Angabe Ermittlungsbehörde",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Name",
            "Database": "",
            "DbText": "LblErmittlungsbehoerde_Name",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Ermittlungsbehoerde_Name",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Aktenz./Tagebuchnummer",
            "Database": "",
            "DbText": "LblErmittlungsbehoerde_Aktenzeichen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Ermittlungsbehoerde_Aktenzeichen",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wurde eine Blutprobe entnommen?",
            "Database": "",
            "DbText": "LblBlutprobeYN",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "BlutprobeYN",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Angabe Ergebnis",
            "Database": "",
            "DbText": "Lblnotiz_BlutprobeErgebnis",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "notiz_BlutprobeErgebnis",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wurde die Fahrerlaubnis beschlagnahmt/vorläufig entzogen?",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Grund Beschluss/Beschlagnahme",
            "Database": "",
            "DbText": "LblFahrerlaubnis_Beschlagnahme",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Fahrerlaubnis_Beschlagnahme",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum des Beschlusses/der Beschlagnahme",
            "Database": "",
            "DbText": "LblFahrerlaubnis_Beschlagnahme_Kopie1",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Fahrerlaubnis_BeschlagnahmeDatum",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Gerichtsbeschluss",
            "Database": "",
            "DbText": "ChkDok_Gerichtsbeschluss",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Beschlagnahmebestätigung",
            "Database": "",
            "DbText": "ChkDok_Beschlagnahmebestaetigung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wurde bereits eine Anklage erhoben?",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zustellung der Anklageschrift",
            "Database": "",
            "DbText": "LblVsr_Zustellunganklageschrift",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Zustellunganklageschrift",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Anklageschrift",
            "Database": "",
            "DbText": "ChkDok_Vsr_Anklageschrift",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wurde bereits ein Strafbefehl erlassen?",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zustellung des Strafbefehles",
            "Database": "",
            "DbText": "LblVsr_ZustellungStrafbefehl",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "ZustellungStrafbefehl",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Soll Einspruch eingelegt werden?",
            "Database": "",
            "DbText": "LblVsr_StrafbefehlEinspruch",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "StrafbefehlEinspruch",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Strafbefehl",
            "Database": "",
            "DbText": "ChkDok_Vsr_Strafbefehl",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "U-Haft",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "U-Haft angeordnet?",
            "Database": "",
            "DbText": "LblVsr_UHaftYN",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "UHaftYN",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Benötigte Dokumente",
            "Database": "",
            "DbText": "LblBenoetigte_DokumenteFahrerlaubnis",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Verkehrsverwaltungsrecht",
      "Elements": [
        [
          {
            "Label": "Freiheitsstrafe, § 38 ff. StGB",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Grund der Freiheitsstrafe",
            "Database": "",
            "DbText": "Lblnotiz_Freiheitsstrafegrund",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "notiz_Freiheitsstrafegrund",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum der Entscheidung",
            "Database": "",
            "DbText": "LblFreiheitsstrafe_Entscheidung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Freiheitsstrafe_Entscheidung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Zustellung",
            "Database": "",
            "DbText": "LblFreiheitsstrafe_Zustellung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Freiheitsstrafe_Zustellung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Urteil",
            "Database": "",
            "DbText": "ChkDok_FreiheitsstrafeUrteil",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Zuvor ergangene Urteile",
            "Database": "",
            "DbText": "ChkDok_FreiheitsstrafeZuvorUrteile",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Geldstrafe, § 40 ff. StGB",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Grund der Geldstrafe",
            "Database": "",
            "DbText": "LblGeldstrafe_Grund",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "notiz_Geldstrafegrund",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum der Entscheidung",
            "Database": "",
            "DbText": "LblGeldstrafe_Antragstellung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Geldstrafe_Entscheidung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Zustellung",
            "Database": "",
            "DbText": "LblGeldstrafe_Zustellung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Geldstrafe_Zustellung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Benötigte Dokumente",
            "Database": "",
            "DbText": "LblBenoetigte_Dokumente_Kopie2_Kopie1",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Urteil",
            "Database": "",
            "DbText": "ChkDok_GeldstrafeUrteil",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Zuvor ergangene Urteile",
            "Database": "",
            "DbText": "ChkDok_GeldstrafeZuvorUrteile",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fahrverbot, § 44 StGB",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Grund des Fahrverbotes",
            "Database": "",
            "DbText": "LblGrund_des_Fahrverbotes",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "notiz_Fahrverbotgrund",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum der Entscheidung",
            "Database": "",
            "DbText": "LblFahrverbot_Entscheidung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Fahrverbot_Entscheidung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Zustellung",
            "Database": "",
            "DbText": "LblFahrverbot_Zustellung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Fahrverbot_Zustellung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Benötigte Dokumente",
            "Database": "",
            "DbText": "LblBenötigte_Dokumente_Kopie3",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Urteil",
            "Database": "",
            "DbText": "ChkDok_FahrverbotUrteil",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Zuvor ergangene Urteile",
            "Database": "",
            "DbText": "ChkDok_FahrverbotZuvorUrteile",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Entziehung der Fahrerlaubnis, § 69 StGB",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Grund der Entziehung",
            "Database": "",
            "DbText": "Lblnotiz_Entziehung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "notiz_Entziehungsgrund",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum der Entscheidung",
            "Database": "",
            "DbText": "LblEntziehung_Entscheidung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Entziehung_Entscheidung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Zustellung",
            "Database": "",
            "DbText": "LblEntziehung_Zustellung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Entziehung_Zustellung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Benötigte Dokumente",
            "Database": "",
            "DbText": "LblBenoetigte_Dokumente_Kopie2",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Urteil",
            "Database": "",
            "DbText": "ChkDok_EntziehungUrteil",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Zuvor ergangene Urteile",
            "Database": "",
            "DbText": "ChkDok_EntziehungZuvorUrteile",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sperre/Wiedererteilung Fahrerlaubnis, § 69a StGB",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Welche Klassen sollen wiedererteilt werden?",
            "Database": "",
            "DbText": "LblWiedererteilung_Klassen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Wiedererteilung_Klassen",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Grund der Entziehung?",
            "Database": "",
            "DbText": "LblWiederteilung_Entziehungsgrund",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "notiz_Wiedererteilungsgrund",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ende Sperrfrist bis zur Wiedererteilung?",
            "Database": "",
            "DbText": "LblWiedererteilung_Sperrfrist",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Wiedererteilung_Sperrfrist",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wurde bereits ein Antrag gestellt?",
            "Database": "",
            "DbText": "LblWiedererteilung_AntragYN",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Wiedererteilung_AntragYN",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Antragstellung",
            "Database": "",
            "DbText": "LblWiedererteilung_Antrag",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Wiedererteilung_Antrag",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum der ablehnenden Entscheidung",
            "Database": "",
            "DbText": "LblWiedererteilung_Ablehnung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Wiedererteilung_Ablehnung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Benötigte Dokumente",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Aktueller Auszug aus dem Fahrerlaubnisregister",
            "Database": "",
            "DbText": "ChkDok_AuszugFahrerlaubnisregister",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schreiben Ablehnungsentscheidung",
            "Database": "",
            "DbText": "ChkDok_SchreibenAblehnungsentscheidung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wiedereinteilung Antrag",
            "Database": "",
            "DbText": "ChkDok_WiedereinteilungAntrag",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Anordnung einer MPU",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Grund der Anordnung",
            "Database": "",
            "DbText": "LblMPU_Grund",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "MPU_Grund",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Anordnungsdatum",
            "Database": "",
            "DbText": "LblMPU_Anordung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "MPU_Anordung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zugangsdatum",
            "Database": "",
            "DbText": "LblMPU_Zugang",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "MPU_Zugang",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Frist zur Vorlage des Gutachtens",
            "Database": "",
            "DbText": "LblMPU_GutachtenFrist",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "MPU_GutachtenFrist",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Anordnung zur Führung eines Fahrtenbuches",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Grund der Anordnung",
            "Database": "",
            "DbText": "LblFahrtenbuch_Grund",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Fahrtenbuch_Grund",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Anordnungsdatum",
            "Database": "",
            "DbText": "LblFahrtenbuch_Anordnung",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Fahrtenbuch_Anordnung",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zugangsdatum",
            "Database": "",
            "DbText": "LblFahrtenbuch_Zugang",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Fahrtenbuch_Zugang",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Dauer des Fahrverbots",
            "Database": "",
            "DbText": "LblFahrtenbuch_Dauerverbot",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Fahrtenbuch_Dauerverbot",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Anzahl der betroffenen Fahrzeuge",
            "Database": "",
            "DbText": "LblFahrtenbuch_AnzahlBetroffeneFahrzeug",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Fahrtenbuch_AnzahlBetroffeneFahrzeug",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Nummer",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Anordnungsschreiben bzgl. MPU",
            "Database": "",
            "DbText": "ChkDok_MPU_Anordnungsschreiben",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Anordnungsschreiben bzgl. des Fahrtenbuches",
            "Database": "",
            "DbText": "ChkDok_Fahrtenbuch_Anordnungsschreiben",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Benötigte Dokumente",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    }
  ],
  "Mandant": [
    [
      {
        "Label": "Suchbegriff*",
        "Database": "",
        "DbText": "LSuchbegriff",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Keyword",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Keyword"
      }
    ],
    [
      {
        "Label": "Vorname",
        "Database": "",
        "DbText": "LVorname",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "FirstName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Name*",
        "Database": "",
        "DbText": "LName1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Name1"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "LblName2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Name2"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "LblName3",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name3",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Name3"
      }
    ],
    [
      {
        "Label": "Straße*",
        "Database": "",
        "DbText": "LStrasse",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Address",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Address"
      }
    ],
    [
      {
        "Label": "Postleitzahl* / Ort*",
        "Database": "",
        "DbText": "LPLZ_Ort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ZipCode",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "ZipCode"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "CityName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "CityName"
      }
    ],
    [
      {
        "Label": "Telefonnummer 1",
        "Database": "",
        "DbText": "LTelefonnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Phone1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Phone1"
      }
    ],
    [
      {
        "Label": "Telefonnummer 2",
        "Database": "",
        "DbText": "Lbltel2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "tel2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Phone2"
      }
    ],
    [
      {
        "Label": "Faxnummer",
        "Database": "",
        "DbText": "LFaxnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/DefaultZuord",
        "DbText": "Fax",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Fax"
      }
    ],
    [
      {
        "Label": "Handynummer",
        "Database": "",
        "DbText": "LHandynummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "HandyNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "MobilePhone"
      }
    ],
    [
      {
        "Label": "E-Mail",
        "Database": "",
        "DbText": "Llongmail",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "EmailAddress",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": "Email",
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "EmailAddress"
      }
    ],
    [
      {
        "Label": "Homepage",
        "Database": "",
        "DbText": "LblHomepage",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "homepage",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": "Browser",
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "URL"
      }
    ],
    [
      {
        "Label": "Geburtsdatum",
        "Database": "",
        "DbText": "Lblgebdat",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "BirthDate",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": "Datum",
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Beruf",
        "Database": "",
        "DbText": "LblBeruf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Beruf",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Vorsteuerberechtigt",
        "Database": "",
        "DbText": "LblVorsteuerberechtigt",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Values": {
          "Ja": "Ja_vorsteuerberechtigt",
          "Nein": "Nein_vorsteuerberechtigt"
        },
        "Default": "True",
        "Label": "vorsteuerberechtigt",
        "Database": "XML/Default",
        "DbText": "",
        "Type": "RADIOGROUP",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Rechtsform*",
        "Database": "",
        "DbText": "LRechtsform",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Rechtsform",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Anrede Briefkopf",
        "Database": "",
        "DbText": "LAnredeBriefkopf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeBriefkopf",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Anrede"
      }
    ],
    [
      {
        "Label": "Anrede direkt",
        "Database": "",
        "DbText": "LAnrededirekt",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeDirekt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Briefanrede"
      }
    ],
    [
      {
        "Label": "Kontoinhaber",
        "Database": "",
        "DbText": "LblKontoinhaber",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Kontoinhaber",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Bankverbindung",
        "Database": "",
        "DbText": "LBankverbindung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "kurzbez",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 57,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "bank",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      }
    ],
    [
      {
        "Label": "BLZ / BIC",
        "Database": "",
        "DbText": "LBLZ_BIC",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "blz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BLZ"
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "BIC",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Konto / IBAN",
        "Database": "",
        "DbText": "LKonto_IBAN",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ktonr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "IBAN",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "SEPA Lastschr.",
        "Database": "",
        "DbText": "LSEPALastschr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "MandatSEPA",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 35,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Datum SEPA",
        "Database": "",
        "DbText": "LDatumSEPA",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "MandatSEPAdatum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 10,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Abrechnungsdaten",
        "Type": "HEADLINE",
        "Size": 12,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Zahlungsart",
        "Database": "",
        "DbText": "LblZahlungsart",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Values": [
          "",
          "Selbstzahler",
          "Rechtsschutzversicherung",
          "Prozesskostenhilfe",
          "RECHT-nah Card"
        ],
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Zahlungsart",
        "Type": "COMBOBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Ertragsverteilung",
        "Database": "",
        "DbText": "LblErtragsverteilung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Values": [
          "100%",
          "50% : 50%",
          "30% : 70%"
        ],
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Ertragsverteilung",
        "Type": "COMBOBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Nur bei Rechtsschutzversicherung:",
        "Type": "HEADLINE",
        "Size": 12,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Versicherung",
        "Database": "",
        "DbText": "LblVersicherung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "RsVers",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Versicherungsnummer",
        "Database": "",
        "DbText": "LblVersicherungsnr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "RsverNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Selbstbeteiligung",
        "Database": "",
        "DbText": "LblSelbstbeteiligung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "RsSelbst",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": "Geld",
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Schadensnummer",
        "Database": "",
        "DbText": "LblSchadensnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "SchadNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Deckungszusage erhalten",
        "Database": "",
        "DbText": "LblBereits_Deckungszusage",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Values": {
          "Ja ": "Ja_Deckungszusage_erhalten",
          "Nein ": "Nein_Deckungszusage_erhalten"
        },
        "Default": "True",
        "Label": "Deckungszusage_erhalten",
        "Database": "XML/Default",
        "DbText": "",
        "Type": "RADIOGROUP",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Nur bei RECHT-nah Card",
        "Type": "HEADLINE",
        "Size": 12,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Kartennummer",
        "Database": "",
        "DbText": "LblRechtNahCard_Kartennummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "RechtNahCard_Kartennummer",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Belege / Dokumente:",
        "Type": "HEADLINE",
        "Size": 12,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Kopie Personalausweis",
        "Database": "XML/Default",
        "DbText": "ChkDok_Personalausweiskopie",
        "Type": "CHECKBOX",
        "Size": 3,
        "Skip": 3,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Schreiben Übernahme VKH-Kosten",
        "Database": "XML/Default",
        "DbText": "ChkDok_SchreibenZuProzesskostenuebernahme",
        "Type": "CHECKBOX",
        "Size": 3,
        "Skip": 3,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Deckungszusage",
        "Database": "XML/Default",
        "DbText": "ChkDok_Deckungszusage",
        "Type": "CHECKBOX",
        "Size": 3,
        "Skip": 3,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Berechtigungsschein",
        "Database": "XML/Default",
        "DbText": "ChkDok_Berechtigungsschein",
        "Type": "CHECKBOX",
        "Size": 3,
        "Skip": 3,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Gegner": [
    [
      {
        "Label": "Suchbegriff*",
        "Database": "",
        "DbText": "LSuchbegriff",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Keyword",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Keyword"
      }
    ],
    [
      {
        "Label": "Vorname",
        "Database": "",
        "DbText": "LVorname",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "FirstName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Name*",
        "Database": "",
        "DbText": "LName",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Name1"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lname2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Name2"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lblname3",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name3",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Name3"
      }
    ],
    [
      {
        "Label": "Straße*",
        "Database": "",
        "DbText": "LStrasse",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Address",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Address"
      }
    ],
    [
      {
        "Label": "Postleitzahl* / Ort*",
        "Database": "",
        "DbText": "LPLZ_Ort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ZipCode",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "ZipCode"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "CityName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "CityName"
      }
    ],
    [
      {
        "Label": "Telefonnummer 1",
        "Database": "",
        "DbText": "LTelefonnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Phone1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Phone1"
      }
    ],
    [
      {
        "Label": "Telefonnummer 2",
        "Database": "",
        "DbText": "Lbltel2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "tel2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Phone2"
      }
    ],
    [
      {
        "Label": "Faxnummer",
        "Database": "",
        "DbText": "LFaxnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Fax",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Fax"
      }
    ],
    [
      {
        "Label": "Handynummer",
        "Database": "",
        "DbText": "LHandynummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "HandyNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "MobilePhone"
      }
    ],
    [
      {
        "Label": "E-Mail",
        "Database": "",
        "DbText": "Llongmail",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "EmailAddress",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": "Email",
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "EmailAddress"
      }
    ],
    [
      {
        "Label": "Homepage",
        "Database": "",
        "DbText": "LblHomepage",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Homepage",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": "Browser",
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Geburtsdatum",
        "Database": "",
        "DbText": "Lblgebgeg",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "BirthDate",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": "Datum",
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Beruf",
        "Database": "",
        "DbText": "LblBeruf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Beruf",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Rechtsform*",
        "Database": "",
        "DbText": "LRechtsform",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Rechtsform",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Anrede Briefkopf",
        "Database": "",
        "DbText": "LAnredeBriefkopf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeBriefkopf",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Anrede"
      }
    ],
    [
      {
        "Label": "Anrede direkt",
        "Database": "",
        "DbText": "LAnrededirekt",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeDirekt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Briefanrede"
      }
    ],
    [
      {
        "Label": "Gegn. Anwalt",
        "Database": "",
        "DbText": "Lgeganwalt",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/DefaultZuord",
        "DbText": "geganwalt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "AZ gegn. Anwalt",
        "Database": "",
        "DbText": "Lazgeganw",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/DefaultZuord",
        "DbText": "azgeganw",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Bankverbindung",
        "Database": "",
        "DbText": "LBankverbindung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "kurzbez",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 57,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "bank",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      }
    ],
    [
      {
        "Label": "BLZ/BIC",
        "Database": "",
        "DbText": "LBLZ_BIC",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "blz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BLZ"
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "BIC",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 11,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Konto / IBAN",
        "Database": "",
        "DbText": "LKonto_IBAN",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ktonr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "IBAN",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 34,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "SEPA Lastschr.",
        "Database": "",
        "DbText": "LSEPALastschr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "MandatSEPA",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 35,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Datum SEPA",
        "Database": "",
        "DbText": "LDatumSEPA",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "MandatSEPAdatum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 10,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Behorde": [
    [
      {
        "Label": "Suchbegriff*",
        "Database": "",
        "DbText": "LSuchbegriff",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Keyword",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Keyword"
      }
    ],
    [
      {
        "Label": "Name*",
        "Database": "",
        "DbText": "LName1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Name1"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lname2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Name2"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lname3",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name3",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Name3"
      }
    ],
    [
      {
        "Label": "Straße",
        "Database": "",
        "DbText": "lstr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Address",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Address"
      }
    ],
    [
      {
        "Label": "Länderkennzeichen",
        "Database": "",
        "DbText": "llkz",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "lkz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 3,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "CountryID"
      }
    ],
    [
      {
        "Label": "PLZ",
        "Database": "",
        "DbText": "lplz",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ZipCode",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "ZipCode"
      }
    ],
    [
      {
        "Label": "Wohnort",
        "Database": "",
        "DbText": "lort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "CityName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "CityName"
      }
    ],
    [
      {
        "Label": "Telefon1",
        "Database": "",
        "DbText": "ltel1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Phone1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Phone1"
      }
    ],
    [
      {
        "Label": "Telefon2",
        "Database": "",
        "DbText": "ltel2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "tel2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Phone2"
      }
    ],
    [
      {
        "Label": "Zentralrufnummer",
        "Database": "",
        "DbText": "lzentralrufnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Zentralrufnummer",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Fax",
        "Database": "",
        "DbText": "lfax",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Fax",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Fax"
      }
    ],
    [
      {
        "Label": "E-Mail",
        "Database": "",
        "DbText": "llongmail",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "EmailAddress",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "EmailAddress"
      }
    ],
    [
      {
        "Label": "Telekommunikation",
        "Database": "",
        "DbText": "ltelkom",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "telkom",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Homepage",
        "Database": "",
        "DbText": "lhomepage",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Homepage",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Anrede Briefkopf",
        "Database": "",
        "DbText": "lan1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeBriefkopf",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Anrede"
      }
    ],
    [
      {
        "Label": "Anrede direkt",
        "Database": "",
        "DbText": "lan2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeDirekt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Briefanrede"
      }
    ],
    [
      {
        "Label": "Bankverbindung",
        "Database": "",
        "DbText": "LBankverbindung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "kurzbez",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 57,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "bank",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      }
    ],
    [
      {
        "Label": "BLZ/BIC",
        "Database": "",
        "DbText": "LBLZ_BIC",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "blz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BLZ"
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "BIC",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 11,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Konto / IBAN",
        "Database": "",
        "DbText": "LKonto_IBAN",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ktonr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "IBAN",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 34,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Amtsbezeichnung",
        "Database": "",
        "DbText": "LblAmtsbezeichnung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Amtsbezeichnung",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Aktenzeichen",
        "Database": "",
        "DbText": "LblAktenzeichen",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Aktenzeichen",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Gericht": [
    [
      {
        "Label": "Suchbegriff*",
        "Database": "",
        "DbText": "LSuchbegriff",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Keyword",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Keyword"
      }
    ],
    [
      {
        "Label": "Name*",
        "Database": "",
        "DbText": "LName1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Name1"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lname2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Name2"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lname3",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name3",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Name3"
      }
    ],
    [
      {
        "Label": "Straße",
        "Database": "",
        "DbText": "LStrasse",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Address",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Address"
      }
    ],
    [
      {
        "Label": "Postleitzahl",
        "Database": "",
        "DbText": "LPLZ_Ort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ZipCode",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "ZipCode"
      }
    ],
    [
      {
        "Label": "Gerichtsort",
        "Database": "",
        "DbText": "Lort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "CityName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "CityName"
      }
    ],
    [
      {
        "Label": "Gerichtskennzahl",
        "Database": "",
        "DbText": "LRechtsform",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "GerichtsKennzahl",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Telefonnummer 1",
        "Database": "",
        "DbText": "LTelefonnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Phone1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Phone1"
      }
    ],
    [
      {
        "Label": "Telefonnummer 2",
        "Database": "",
        "DbText": "Lbltel2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "tel2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Phone2"
      }
    ],
    [
      {
        "Label": "Faxnummer",
        "Database": "",
        "DbText": "LFaxnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Fax",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Fax"
      }
    ],
    [
      {
        "Label": "Handynummer",
        "Database": "",
        "DbText": "LHandynummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "HandyNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "MobilePhone"
      }
    ],
    [
      {
        "Label": "E-Mail",
        "Database": "",
        "DbText": "Llongmail",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "EmailAddress",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "EmailAddress"
      }
    ],
    [
      {
        "Label": "Homepage",
        "Database": "",
        "DbText": "LblHomepage",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Homepage",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Anrede Briefkopf",
        "Database": "",
        "DbText": "lan1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeBriefkopf",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Anrede"
      }
    ],
    [
      {
        "Label": "Anrede direkt",
        "Database": "",
        "DbText": "lan2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeDirekt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Briefanrede"
      }
    ],
    [
      {
        "Label": "Bankverbindung",
        "Database": "",
        "DbText": "LBankverbindung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "kurzbez",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 57,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "bank",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      }
    ],
    [
      {
        "Label": "BLZ/BIC",
        "Database": "",
        "DbText": "LBLZ_BIC",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "blz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BLZ"
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "BIC",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 11,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Konto / IBAN",
        "Database": "",
        "DbText": "LKonto_IBAN",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ktonr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "IBAN",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 34,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Weitere": [
    [
      {
        "Label": "Suchbegriff*",
        "Database": "",
        "DbText": "LSuchbegriff",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "subeg",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Keyword"
      }
    ],
    [
      {
        "Label": "Vorname",
        "Database": "",
        "DbText": "LVorname",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "vname",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Name*",
        "Database": "",
        "DbText": "LName",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "name1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Name1"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "MyLabel1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "name2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Name2"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lblname3",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "name3",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Name3"
      }
    ],
    [
      {
        "Label": "Straße",
        "Database": "",
        "DbText": "LStrasse",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "str",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Address"
      }
    ],
    [
      {
        "Label": "Postleitzahl / Ort",
        "Database": "",
        "DbText": "LPLZ_Ort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "plz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "ZipCode"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ort",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "CityName"
      }
    ],
    [
      {
        "Label": "Telefonnummer 1",
        "Database": "",
        "DbText": "LTelefonnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "tel1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Phone1"
      }
    ],
    [
      {
        "Label": "Telefonnummer 2",
        "Database": "",
        "DbText": "Lbltel2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "tel2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Phone2"
      }
    ],
    [
      {
        "Label": "Faxnummer",
        "Database": "",
        "DbText": "LFaxnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "fax",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Fax"
      }
    ],
    [
      {
        "Label": "Handynummer",
        "Database": "",
        "DbText": "LHandynummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "HandyNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "MobilePhone"
      }
    ],
    [
      {
        "Label": "E-Mail",
        "Database": "",
        "DbText": "Llongmail",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "longmail",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "EmailAddress"
      }
    ],
    [
      {
        "Label": "Homepage",
        "Database": "",
        "DbText": "LblHomepage",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Homepage",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Kommunikationsdaten",
        "Database": "",
        "DbText": "LblKommunikationsdaten",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Headers": [
          "Medium",
          "Nummer"
        ],
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Kommunikationsdaten",
        "Type": "TABLE",
        "Size": 9,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Rechtsform*",
        "Database": "",
        "DbText": "LRechtsform",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Rechtsform",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "NameID"
      }
    ],
    [
      {
        "Label": "Anrede Briefkopf",
        "Database": "",
        "DbText": "LAnredeBriefkopf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "an1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Anrede"
      }
    ],
    [
      {
        "Label": "Anrede direkt",
        "Database": "",
        "DbText": "LAnrededirekt",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "an2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Briefanrede"
      }
    ],
    [
      {
        "Label": "Beteiligungsart",
        "Database": "",
        "DbText": "Llbetart",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/DefaultZuord",
        "DbText": "Beteiligungsart",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 3,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "",
        "DbText": "Zuo_Beteiligungsart",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Bankverbindung",
        "Database": "",
        "DbText": "LBankverbindung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "kurzbez",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 57,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "bank",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      }
    ],
    [
      {
        "Label": "BLZ/BIC",
        "Database": "",
        "DbText": "LBLZ_BIC",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "blz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BLZ"
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "bic",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 11,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BIC"
      }
    ],
    [
      {
        "Label": "Konto / IBAN",
        "Database": "",
        "DbText": "LKonto_IBAN",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ktonr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "iban",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 34,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "SEPA Lastschr.",
        "Database": "",
        "DbText": "LSEPALastschr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "MandatSEPA",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 35,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Datum SEPA",
        "Database": "",
        "DbText": "LDatumSEPA",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "MandatSEPAdatum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 10,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ]
}`);
