import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI, Routing } from "../../api";
import { MainViewModel } from "../../main";
import '../dataTable/dataTable';
import { Utils } from "../../utils";
import { Postbox } from '../dashboard/postbox'
import '../dialog/dialog';
import { DialogViewModel } from '../dialog/dialog';
import moment = require("moment");
import '../address/address_form';
import { RolesAndRights } from "../../helpers/RolesAndRights";

enum AddressCategories {
    Alle = "Alle",
    Mandant = "Mandant",
    Gegner = "Gegner",
    Sonstige = "Sonstige",
    Gericht = "Gericht",
    Versicherung = "Versicherung",
    Behörde = "Behörde",
    Anwalt = "Anwalt"
}

export class AddressOverviewViewModel {
    infoMessage = ko.observable('');
    infoTitle = ko.observable('');
    modialAutoHide = ko.observable(false)

    addresses = ko.observableArray([]);

    mandanten = ko.observableArray([]);
    gegner = ko.observableArray([]);
    sonstige = ko.observableArray([]);

    isViewerRole = ko.observable<boolean>(false);

    addressCases = ko.observableArray([]);

    DropDownText = ko.observable("Alle Beteiligten");

    selectedType = ko.observable("Alle");
    types = ko.observableArray(["Mandant", "Gegner", "Sonstige", "Gericht", "Versicherung", "Behörde", "Anwalt"]);
    requiredVertreter = ko.observableArray(["Mandant", "Gegner", "Sonstige"]);
    columns = ko.observableArray(['FirstName', 'Name1', 'CityName', 'Actions']);
    columnHeaders = ko.observableArray(['Vorname', 'Nachname', 'Ort']);

    currentCategory = ko.observable(AddressCategories.Mandant);
    categories = AddressCategories;
    currentAddress = ko.observable({
        Keyword: "",
        Sonstige1: "",
        Update: ""
    })
    IsEditMode = ko.observable(false);
    showRF = ko.observable(false);
    wantToDelete = ko.observable(true);
    notAssigned = ko.observable(false);
    rachts = ko.observableArray([]);
    rachtsPerson = ko.observableArray([]);
    briefkopf = ko.observableArray([]);

    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalHandleSelection = ko.observable();

    newPerson() {
        let obj = {
            FirstName: ko.observable(""),
            Name1: ko.observable(""),
            Name2: ko.observable(""),
            Name3: ko.observable(""),
            Rechtsform: ko.observable(null),
            Address: ko.observable(null),
            ZipCode: ko.observable(null),
            CityName: ko.observable(null),
            EmailAddress: ko.observable(null),
            Phone1: ko.observable(null),
            AnredeBriefkopf: ko.observable(null),
            AnredeDirekt: ko.observable(null),
            Note1: ko.observable(null),
            GesetzlicherVertreter1Id: ko.observable(null),
            Fax: ko.observable(null),
            RSVersNr: ko.observable(null)

        };

        obj["Keyword"] = ko.computed(function () {
            return (obj.Name1() + obj.FirstName()).substring(0, 20);
        });

        return ko.observable(obj);
    };

    person = this.newPerson();

    typeChanged(obj, event) {
        if (event.originalEvent) {
            if (this.selectedType() === "Gericht" || this.selectedType() === "Versicherung" || this.selectedType() === "Behörde") {
                this.columns(['Name1', 'CityName']);
                this.columnHeaders(['Name', 'Ort']);
            }
            else {
                this.columns(['FirstName', 'Name1', 'CityName']);
                this.columnHeaders(['Vorname', 'Name', 'Ort']);
            }
        }
    }

    async loadAssignedCases(keyword, type) {
        let res = (await RNSAPI.checkAssignment(keyword, type))
        if (res.Type === "AddressAssignedToPerson") {
            this.wantToDelete(false);
            this.notAssigned(false);
            this.addressCases(res.Payload.AssignedCases);
            $("#addressAssigned").modal("show");
        }
        else {
            //Adresse hier nicht zugeordnet
            this.notAssigned(true);
            $("#addressAssigned").modal("show");
        }
    }

    initTooltip() {
        try {
            $("[data-toggle='tooltip']").tooltip();    
        } catch {}
    }

    async loadAddresses() {
        if (this.selectedType() === AddressCategories.Mandant) {
            this.DropDownText("Mandanten");
        }
        else if (this.selectedType() === AddressCategories.Gegner) {
            this.DropDownText("Gegner");
        }
        else if (this.selectedType() === AddressCategories.Sonstige) {
            this.DropDownText("Weitere");
        } else if (["Gericht", "Versicherung", "Behörde", "Anwalt"].indexOf(this.selectedType()) !== -1) {
            this.currentCategory(AddressCategories[this.selectedType()])
        }
        else {
            this.DropDownText("Beteiligte");
        }


        let addresses = (await RNSAPI.getPersonsForScope(this.selectedType())).Payload.Addresses;
        this.addresses(addresses.map((a) => {
            a['Name1'] = a['Name1'] + ' ' + a['Name2'] + ' ' + a['Name3'];
            if(a['Phone1'] === '+49') a['Phone1'] = '';
         
            a['CreatedAt'] = moment(a['CreatedAt']).format("DD.MM.YYYY");
            if (a['CreatedAt'] === 'Invalid date')
                a['CreatedAt'] = "";
            if (this.selectedType() === "Gericht" || this.selectedType() === "Versicherung" || this.selectedType() === "Behörde") {
                if(!this.isViewerRole()) {
                    a.actionHandlers = [{
                        icon: "eye",
                        name: "Ansehen",
                        action: () => this.loadPerson(a.Keyword, this.selectedType())
                    }]
                }
            }
            else {
                if(!this.isViewerRole()) {
                    a.actionHandlers = [{
                        icon: "pencil-alt",
                        name: "Bearbeiten",
                        action: () => this.loadPerson(a.Keyword, this.selectedType())
                    },
                    {
                        icon: "info-circle",
                        name: "Aktenzuordnung",
                        action: async () => { this.loadAssignedCases(a.Keyword, this.selectedType()) }
                    },
                    {
                        icon: "trash-alt",
                        name: "Löschen",
                        action: async () => {
                            this.setCurrentAddress({
                                Keyword: a.Keyword,
                                Sonstige1: this.selectedType(),
                                Update: 'addresses'
                            })
                        }
                    }
                    ];
                }
            }

            return a;
        }));
    }

    async deleteAddress() {
        let res = await RNSAPI.deletePerson(this.currentAddress().Keyword, this.currentAddress().Sonstige1)
        $("#deleteConfirm").modal("hide")
        if (res.Type === "AddressAssigned") {
            this.wantToDelete(true);
            this.notAssigned(false);
            this.addressCases(res.Payload.AssignedCases);
            $("#addressAssigned").modal("show");
        }
        else {
            this.currentAddress().Update === 'addresses' ? this.loadAddresses() : this.showAll()
        }
    }

    setCurrentAddress(address) {
        this.currentAddress(address);
        $("#deleteConfirm").modal("show");
    }

    async navigateToCase(data) {
        $('#addressAssigned').modal("hide");
        var mb = document.getElementsByClassName("modal-backdrop")
        mb[0].remove();
        MainViewModel.RoutingTable.showNewView({ caseId: data });
    }

    showAll = async () => {
        this.currentCategory(AddressCategories.Alle);
        this.selectedType(AddressCategories.Alle);

        this.DropDownText("Alle Beteiligten");

        let Mandanten = (await RNSAPI.getPersonsForScope(AddressCategories.Mandant)).Payload.Addresses;
        let Gegner = (await RNSAPI.getPersonsForScope(AddressCategories.Gegner)).Payload.Addresses;
        let Sonstige = (await RNSAPI.getPersonsForScope(AddressCategories.Sonstige)).Payload.Addresses;

        this.mandanten(Mandanten.map((a) => {
            a['Name1'] = a['Name1'] + ' ' + a['Name2'] + ' ' + a['Name3'];
            if(a['Phone1'] === '+49') a['Phone1'] = '';
            a['Sonstige1'] = AddressCategories.Mandant;
            if(!this.isViewerRole()) {
                a.actionHandlers = [{
                    icon: "pencil-alt",
                    name: "Bearbeiten",
                    action: async () => {
                        this.loadPerson(a.Keyword, a['Sonstige1'])
                    }
                },
                {
                    icon: "trash-alt",
                    name: "Löschen",
                    action: async () => {
                        this.setCurrentAddress(a)
                    }
                }
                ];
            }

            return a;
        }));

        this.gegner(Gegner.map((a) => {
            a['Name1'] = a['Name1'] + ' ' + a['Name2'] + ' ' + a['Name3'];
            if(a['Phone1'] === '+49') a['Phone1'] = '';
            a['Sonstige1'] = AddressCategories.Gegner;
            if(!this.isViewerRole()) {
                a.actionHandlers = [{
                    icon: "pencil-alt",
                    name: "Bearbeiten",
                    // action: () => MainViewModel.RoutingTable.showAddressView({ keyword: a.Keyword, type: a['Sonstige1'] })
                    action: () => this.loadPerson(a.Keyword, a['Sonstige1'])
                },
                {
                    icon: "trash-alt",
                    name: "Löschen",
                    action: async () => {
                        this.setCurrentAddress(a)
                    }
                }
                ];
            }
            return a;
        }));

        this.sonstige(Sonstige.map((a) => {
            a['Name1'] = a['Name1'] + ' ' + a['Name2'] + ' ' + a['Name3'];
            if(a['Phone1'] === '+49') a['Phone1'] = '';
            a['Sonstige1'] = "Weitere";
            if(!this.isViewerRole()) {
                a.actionHandlers = [{
                    icon: "pencil-alt",
                    name: "Bearbeiten",
                    // action: () => MainViewModel.RoutingTable.showAddressView({ keyword: a.Keyword, type: a['Sonstige1'] })
                    action: () => this.loadPerson(a.Keyword, a['Sonstige1'])
                },
                {
                    icon: "trash-alt",
                    name: "Löschen",
                    action: async () => {
                        this.setCurrentAddress(a)
                    }
                }
                ];
            }
            return a;
        }));

        let addresses = this.mandanten().concat(this.gegner().concat(this.sonstige()));
        this.addresses(addresses);
    };

    showMandant = () => {
        this.currentCategory(AddressCategories.Mandant);
        this.selectedType(AddressCategories.Mandant);
        localStorage.setItem('updateSelectedAddress', 'Mandant')
        this.loadAddresses();
    };

    showGegner = () => {
        this.currentCategory(AddressCategories.Gegner);
        this.selectedType(AddressCategories.Gegner);
        localStorage.setItem('updateSelectedAddress', 'Gegner')
        this.loadAddresses();
    };
    showSonstige = () => {
        this.currentCategory(AddressCategories.Sonstige);
        this.selectedType(AddressCategories.Sonstige);
        localStorage.setItem('updateSelectedAddress', 'Sonstige')
        this.loadAddresses();
    };
    showGericht = () => {
        this.currentCategory(AddressCategories.Gericht);
        this.selectedType(AddressCategories.Gericht);
        this.loadAddresses();
    };
    showVersicherung = () => {
        this.currentCategory(AddressCategories.Versicherung);
        this.selectedType(AddressCategories.Versicherung);
        this.loadAddresses();
    };
    showBehörde = () => {
        this.currentCategory(AddressCategories.Behörde);
        this.selectedType(AddressCategories.Behörde);
        this.loadAddresses();
    };
    showAnwalt = () => {
        this.currentCategory(AddressCategories.Anwalt);
        this.selectedType(AddressCategories.Anwalt);
        this.loadAddresses();
    }

    async loadPerson(keyword, type) {
        let result = (await RNSAPI.getPersonForKeyword(keyword, type));
        if (result.Type === "ExactMatchNotFound") {
            alert("Person wurde in der Zwischenzeit gelöscht!")
            return;
        }
        let person = result.Payload.Address;
        let newPerson = this.newPerson();
        let obj = newPerson();
        for (let key in person) {
            if (obj[key] === undefined) {
                obj[key] = person[key];
            } else if (key !== "Keyword") {
                obj[key](person[key]);
            } else {
                obj["Keyword"] = ko.observable(person["Keyword"]);
            }
        }
        obj["Sonstige1"] = type;

        this.person(obj);
        Postbox.publisher().publish(this.person, "updateAddressModal");
        $('#allAddressModal').modal('show');
    }

    async getCity() {

        const { Type, Payload } = (await RNSAPI.getPostcode(this.person().ZipCode()))

        if (Type === "GetPostcodeResult" && Payload.Cities.length > 0) {
            this.person().CityName(Payload.Cities[0])
        } else {
            this.person().CityName('')
        }
    }

    showSuggestionsRF = async () => {
        this.showRF(true);
        this.pickRechtsform();
    }

    selectOption = (data) => {
        let a = ko.toJS(this.briefkopf);
        for (let i = 0; i < a.length; i++) {
            if (data === a[i].NameID) {
                this.person().Rechtsform(a[i].NameID);
                this.person().AnredeBriefkopf(a[i].Briefanrede);
                this.person().AnredeDirekt(a[i].Anrede);
                this.showRF(false);
                break;
            }
        }
    }

    pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    }

    async pickGVertreter(type: string) {
        let addresses = (await RNSAPI.getPersonsForScope(type)).Payload.Addresses;
        this.pickGeneric("Gesetzlicher Vertreter", ["FirstName", "Name1", "CityName"], ["Vorname", "Nachname", "Ort"], addresses);
        this.modalHandleSelection((selectedObject) => {
            Postbox.publisher().publish(selectedObject()["Keyword"], "ChooseGVertreter");
        });
        $('#modal').modal('show');
    }

    async pickVersicherung(instance) {
        let addresses = (await RNSAPI.getPersonsForScope("versicherung")).Payload.Addresses;
        this.pickGeneric("Versicherung wählen", ["Name1", "CityName"], ["Nachname", "Ort"], addresses);
        this.modalHandleSelection((selectedObject) => {
            let obj = { Keyword: selectedObject()["Keyword"], Instance: instance }
            Postbox.publisher().publish(obj, "ChooseVersicherung");
        });
        $('#modal').modal('show');
    }

    pickRechtsform = async () => {
        let rechtsformen = (await RNSAPI.getRechtsformen()).Payload.LegalForms;
        let x = [];
        let y = [];
        let z = [];
        let a = [];
        let b = [];
        let c = [];
        for (let i = 0; i < rechtsformen.length; i++) {
            if (rechtsformen[i].NameID != "") {
                if (rechtsformen[i].NameID === "Herr" || rechtsformen[i].NameID === "Frau" || rechtsformen[i].NameID === "Divers") {
                    //console.log(rechtsformen[i].NameID);
                    a.push(rechtsformen[i].NameID);
                    b.push(rechtsformen[i].Briefanrede);
                    c.push(rechtsformen[i].Anrede);
                }
                else {
                    x.push(rechtsformen[i].NameID);
                    y.push(rechtsformen[i].Briefanrede)
                    z.push(rechtsformen[i].Anrede)
                }
            }

        }
        this.rachts(x);
        this.rachtsPerson(a);
        this.briefkopf(rechtsformen);
    }

    resetObject() {
        let newPerson = this.newPerson();
        let obj = newPerson();
        return obj
    }

    showInfoMessage() {
        setTimeout(() => {
            this.infoMessage('');
            this.modialAutoHide(true);
            $("html, body").animate({ scrollTop: 0 }, "fast");
        }, 4000)
    }

    typeToView(type) {
        if (type === "Mandant")
            this.showMandant();
        else if (type === "Gegner")
            this.showGegner();
        else if (type === "Sonstige")
            this.showSonstige();
        else if (type === "Gericht")
            this.showGericht();
        else if (type === "Versicherung")
            this.showVersicherung();
        else if (type === "Behörde")
            this.showBehörde();
        else if (type === "Anwalt")
            this.showAnwalt();
        else
            this.showAll();
    }

    constructor() {
        this.isViewerRole(RolesAndRights.isViewerRole());
        this.showAll();
        this.initTooltip();
        Postbox.publisher().subscribe((type: string) => {
            this.currentCategory(AddressCategories[type])
            this.selectedType(type)
        }, 'updateAddress')

        Postbox.publisher().subscribe((inf) => {
            this.infoMessage(inf.infMessage);
            this.infoTitle(inf.infTitle);
            this.modialAutoHide(inf.infshow);
            this.typeToView(ko.toJS(inf.type));
            this.showInfoMessage();
        }, 'showInfo')

        Postbox.publisher().subscribe((type: string) => {
            this.pickGVertreter(type);
        }, 'showGV')

        Postbox.publisher().subscribe((instance) => {
            this.pickVersicherung(instance);
        }, 'showVersicherung')
    }
}

let html = fs.readFileSync(__dirname + '/overview.html', 'utf8');

ko.components.register("addressoverview-view", {
    viewModel: AddressOverviewViewModel,
    template: html
});