import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment"
import { RNSAPI } from "../../api";
import { Utils } from "../../utils";
import '../dialog/dialog';

class DelegateDeadlineViewModel {
    oldDeadline = ko.observable(null);
    newSB = ko.observable("");
    parentVM: any;
    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalHandleSelection = ko.observable();
    async pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    };
    
    newAkteFrist() {
        let obj = {
            SachbearbeiterId: ko.observable(RNSAPI.User().username),
            Sachbearbeiter: ko.observable(null),
            Clerk: ko.observable(null),
            CaseId: ko.observable(null),
            Rubrum: ko.observable("Kein Rubrum vorhanden."),
            DeadlineNumber: ko.observable("-/-"),
            DeadlineText: ko.observable(null),
            PreDeadline: ko.observable(),
            StartDate: ko.observable(moment().format("YYYY-MM-DD")),
            TheDeadline: ko.observable()
        };
        //console.log(RNSAPI.getSachbearbeiter());
        return ko.observable(obj);
    };
    IsEditMode = ko.observable(false)

    akteFrist = this.newAkteFrist();
    pickClerk = async () => {
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric("Sachbearbeiter", ["Sachbearbeiter"], ["Sachbearbeiter"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => this.newSB(selectedObject()["Sachbearbeiter_ID"]));
        $('#delegatemodal').modal('show');
    };


   /*  pickSachbearbeiter = async () => {
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric("Sachbearbeiter", ["Sachbearbeiter"], ["Sachbearbeiter"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => this.newSB1(selectedObject()["Sachbearbeiter_ID"]));
       $('#delegatemodal').modal('show');
    };
 */
delegateDeadline = async () => {
        $(".form-group").each(function () { $(this).removeClass("has-error"); });
        if (!Utils.checkErrors(["newSB"], this, "", [Utils.checkString])) return;
        this.akteFrist()['Clerk'] = ko.observable($('#newSB').val());
        let deadlineObj = ko.toJS(this.akteFrist());
        console.log(ko.toJS(deadlineObj.Clerk));
        console.log("i am here "+this.newSB())
        try {
            let result = await RNSAPI.delegateDeadline(this.akteFrist(), this.newSB());
            if (result.Type === "DelegationSuccessful") {
                alert(" success.");
                $('#delegateDeadlineModal').modal('hide');
                this.parentVM.update();
            } else {
                alert("Fehler beim Delegieren der Frist.");
                //alert("there is the error occured.");
            }
        } catch (e) {
           // console.log(e);
            alert("catch error: " + e.responseText);
        }
    };
    constructor(params: any) {
        if (params && params.deadline) {
            this.oldDeadline = params.deadline;
            this.parentVM = params.vm;
        }
    }
}

let html = fs.readFileSync(__dirname + '/delegate.html', 'utf8');

ko.components.register("delegate-deadline-view", {
    viewModel: DelegateDeadlineViewModel,
    template: html
});