import { RNSAPI } from "../../api";
import { Utils } from '../../utils';
import * as fs from 'fs';
import * as ko from "knockout";
import * as model from './model';
import * as moment from 'moment';
import { CaseOverviewViewModel } from "../case/overview";
import './fastcaseperson1';
import '../dashboard/dashboard';
import '../dialog/message';
import '../dialog/resubmissionDialog';
import '../dialog/deadlineDialog';
import '../address/address_form'
import { MainViewModel } from "../../main";
import {
    layoutMietrecht, layoutAllgemein, layoutAllgemeinesZivilRecht, layoutArbeitsrecht,
    layoutAsylrecht, layoutErbrecht, layoutFamilienrecht, layoutSozialrecht, layoutSteuerrecht,
    layoutStrafrecht, layoutVerkehrsOWI, layoutVerkehrsrecht, layoutVerwaltungsrecht,
    layoutZivilrecht
} from './layouts';
import { UserData } from '../../UserData';
import Navigo = require("navigo");
import { print } from "util";
import { Postbox } from "../dashboard/postbox";
import { RolesAndRights } from "../../helpers/RolesAndRights";
import '../receivablesAccount/forcase';




export class Stamp {
    Id: string | null;
    Text: string;
    Type: string;

    constructor(Text: string, Type: string, Id?: string) {
        this.Text = Text;
        this.Type = Type;
        if (Id) this.Id = Id;
    }
}

class PredefinedStamp {
    AnnoTyp: string
    Bezeichnung: string;
    Text: string
    Workflow: string
    Farbe: string
}

export class NewViewModel {
    isLoadingData = ko.observable<boolean>(false);
    hasDeadlineManagementRights = ko.observable<boolean>(false);
    infoMessage = ko.observable('');
    infoTitle = ko.observable('');
    modialAutoHide = ko.observable(false)
    actionType = ko.observable("");
    action = ko.observable("");
    rechtgebiete: ko.ObservableArray<string> = ko.observableArray(["Allgemein", "VerkehrsOWiR"]);
    aktenkonto: ko.Observable<any> = ko.observable();
    userType = ko.observable("");
    compare = ko.observable(0);
    toggleButton = ko.observable(true);
    bereiche: ko.ObservableArray<string> = ko.observableArray(["Mandantenbegehren"]);
    caseData: ko.ObservableArray<any> = ko.observableArray();
    caseAddresses = ko.observableArray();
    caseAddresses3 = ko.observableArray();
    akte: ko.Observable<any> = ko.observable();
    addUser: ko.Observable<any> = ko.observable();
    booking: ko.Observable<any> = ko.observable();
    dateValue = "";
    url: ko.Observable<string> = ko.observable("");
    openCreateTextModal = ko.observable(false)
    blob: Blob
    currentView = ko.observable('beleiligte');
    TheCurrentKeyword = ko.observable("");
    currentAnredeGegner = ko.observable("");
    currentAnredeSonsti = ko.observable("");
    currentAnrede = ko.observable("");
    client;
    courtOptions = ko.observable(false);
    documentToEmail = ko.observable(null);
    grund: ko.Observable<any> = ko.observable();
    Mahnstufe: ko.Observable<any> = ko.observable();
    kurzel: ko.Observable<any> = ko.observable();
    kurzel1: ko.Observable<any> = ko.observable();
    DeadLineNr = ko.observable("");
    MahnNr = ko.observable("");
    creationDate = ko.observable(moment().format("DD.MM.YYYY"));
    public router = new Navigo(null, true);
    courtsAddress = ko.observableArray([]);
    rachts = ko.observableArray([]);
    rachtsPerson = ko.observableArray([]);
    showRF = ko.observable(false);
    IsNew = ko.observable(false);
    briefkopf = ko.observableArray([]);
    direkt = ko.observableArray([]);
    courtsAddress2 = ko.observableArray([]);
    selectedCourtAddress = ko.observable();
    selectedCourtAddress2 = ko.observable();
    popupOpen = ko.observable(false);
    anreden = ko.observableArray(['Herr', 'Frau', 'Herr Dr.', 'Frau Dr.']);
    wopiToken = {
        "AccessToken": "",
        "AccessTokenTtl": 0
    }
    defendantsInCase = ko.observableArray([]);

    newDeadline = async () => {
        $('#CaseNewDeadlineDialogExt').modal('show');
    }

    newResubmission = async () => {
        $('#CaseNewResubmissionDialogExt').modal('show');
    }
    newAppointment = async () => {
        $('#appointmentModal').modal('show');
    }
    caseAddresses2 = ko.observable({
        m: ko.observableArray([]),
        g: ko.observableArray([])
    })
    deadlineDate = ko.computed(() => {

        if (ko.toJS(this.DeadLineNr()) == "") {
            return moment().format("YYYY-MM-DD");
        }
        else if (ko.toJS(this.DeadLineNr()) == "m123") {

            return moment(ko.toJS(this.akte()).FristDatum).format("YYYY-MM-DD");
        }
        else {
            let total_days = Number(ko.toJS(this.DeadLineNr())) * 7;
            if (ko.toJS(this.DeadLineNr()) == 'm1') {
                total_days = 30;
            }
            if (!isNaN(total_days)) {

                let potential_date = moment(moment().add(total_days, 'days'), ("DD.MM.YYYY"));

                if (potential_date.day() == 0) {
                    potential_date.subtract(2, 'day');
                }
                else if (potential_date.day() == 6) {
                    potential_date.subtract(1, 'day');
                }
                return (potential_date.format("YYYY-MM-DD"));
            }

        }
    });

    deadlineDate1 = ko.computed(() => {

        if (ko.toJS(this.MahnNr()) == "") {
            return moment().format("YYYY-MM-DD");
        }
        else if (ko.toJS(this.MahnNr()) == "m123") {

            return moment(ko.toJS(this.akte()).MahnDatum).format("YYYY-MM-DD");
        }
        else {
            let total_days = Number(ko.toJS(this.MahnNr())) * 7;
            if (ko.toJS(this.MahnNr()) == 'm1') {
                total_days = 30;
            }

            if (!isNaN(total_days)) {

                // alert(total_days);gul
                let potential_date = moment(moment().add(total_days, 'days'), ("DD.MM.YYYY"));

                if (potential_date.day() == 0) {
                    potential_date.subtract(2, 'day');
                }
                else if (potential_date.day() == 6) {
                    potential_date.subtract(1, 'day');
                }
                return (potential_date.format("YYYY-MM-DD"));
            }

        }
    });



    caseEntries: ko.ObservableArray<any>;
    caseId: string;
    currentId = ko.observable(null);
    currentViewer = ko.observable("pdf");
    currentMimeType = ko.observable(null);
    buttonText = ko.observable('Add');
    mandantIndex = ko.observable(0);
    // mandantType = ko.observable('mandante');
    myObservableArray2 = ko.observableArray(["", ""]);
    caseItem = ko.observable();
    firstName = ko.observable();
    Comment = ko.observable();
    myObservableArray3 = ko.observableArray(["", ""]);
    myObservableArray4 = ko.observableArray(["", ""]);
    myObservableArray5 = ko.observableArray(["", ""]);
    myObservableArray6 = ko.observableArray(["", ""]);
    adData: ko.Observable<any> = ko.observable();
    info: ko.Observable<any> = ko.observable();
    extendedInfo: ko.Observable<any> = ko.observable();
    emptyPersonType = ko.observable(true);

    mandantIndice = ko.observableArray([0, 0]);

    selectFirstCaseData() {
        this.userType("Mandant");
        if (this.caseAddresses[0].length === 0) {
            this.emptyPersonType(true);
        }
        else {
            this.emptyPersonType(false);
            this.changePerson(this.caseAddresses[0][0], 0, 'mandant', 0);
        }
    }

    selectFirstCaseDataGegner() {
        this.userType("Gegner");
        if (this.caseAddresses[1].length === 0) {
            this.emptyPersonType(true);
        }
        else {
            this.emptyPersonType(false);
            this.changePerson(this.caseAddresses[1][0], 0, 'gegner', 0);
        }
    }

    selectFirstCaseDataWeit() {
        this.userType("Sonstige");
        if (this.caseAddresses[7].length === 0) {
            this.emptyPersonType(true);
        }
        else {
            this.emptyPersonType(false);
            this.changePerson(this.caseAddresses[7][0], 0, 'sonstige', 0);
        }
    }

    selectExtend() {
        this.fillExtendedInfo(this.extendedInfo);
    }
    selectInfo() {
        this.fillInfo();
    }
    mandantIndexObj = ko.observable({
        index: 0,
        type: '',
        rootIndex: 0
    });

    showInfoMessage() {
        setTimeout(() => {
            this.infoMessage('');
            this.modialAutoHide(true);
            $("html, body").animate({ scrollTop: 0 }, "fast");
        }, 4000)
    }

    openEditAddressModal(component, type, data) {
        this.changePerson(data, null, null, null);
        this.ad(data);

        this.ad().Sonstige1 = ko.toJS(component);
        Postbox.publisher().publish(this.ad(), "updateAddressModal");
        $('#allAddressModal').modal('show');
    }

    changePerson(data, index, type, rootIndex) {
        if (!data) {
            return;
        }

        this.ad(data);

        Postbox.publisher().publish(this.ad(), "updateAddressModal");
    }


    changeButtonText(data, index, type, rootIndex) {
        if (!data) {
            return;
        }

        //this.showRF(false);
        //this.IsNew(false);

        //if (type === 'gegner') {
        //    this.mandantIndexObj().index = index;
        //    this.mandantIndexObj().type = type;
        //    this.mandantIndexObj().rootIndex = rootIndex;
        //    this.toggleButton(false);
        //    this.userType("Gegner hinzufügen");
        //}
        //else if (type === 'weitere') {
        //    this.mandantIndexObj().index = index;
        //    this.mandantIndexObj().type = 'sonstige';
        //    this.mandantIndexObj().rootIndex = rootIndex;
        //    this.toggleButton(false);
        //}
        //else {
        //    this.mandantIndexObj().index = index;
        //    this.mandantIndexObj().type = type;
        //    this.mandantIndexObj().rootIndex = rootIndex;
        //    this.toggleButton(true);
        //}

        //this.userType(type);

        //this.mandantIndex(index);

        //let address = ko.toJS(data);

        //this.TheCurrentKeyword(address.Keyword);
        //this.ad().FirstName(address.FirstName);
        //this.ad().DisplayName(address.DisplayName);
        //this.ad().Adress2(address.Address);
        //this.ad().ZipCode(address.ZipCode);
        //this.ad().CityName(address.CityName);
        //this.ad().Phone1(address.Phone1);
        //this.ad().CountryName(address.CountryName);
        //this.ad().Fax(address.Fax);
        //this.ad().URL(address.URL);
        //this.ad().URL(address.URL);
        ////if (this.mandantIndexObj().type===0)
        ////if (this.mandantIndexObj().type === "mandant")
        ////    $("#mandantRechtsform1").val(address.Rechtsform);
        //if (this.mandantIndexObj().type === "gegner")
        //    $("#gegnerRechtsform").val(address.Rechtsform);
        //else if (this.mandantIndexObj().type === "sonstige")
        //    $("#sonstigeRechtsform").val(address.Rechtsform);
        ////console.log(this.mandantIndexObj().rootIndex + " " + address.Rechtsform);
        //this.ad().Adresszusatz(address.Adresszusatz);
        //this.ad().AnredeBriefkopf(address.AnredeBriefkopf);
        //this.ad().AnredeDirekt(address.AnredeDirekt);
        //this.ad().RSVersId(address.RSVersId);
        //this.ad().RSVersNr(address.RSVersNr);
        //this.ad().RSSelbstbehalt(address.RSSelbstbehalt);
        //this.ad().EmailAddress(address.EmailAddress);
        //this.ad().MobilePhone(address.MobilePhone);

        //this.currentAnrede(address.Rechtsform);
        //this.currentAnredeGegner(address.Rechtsform);
        //this.currentAnredeSonsti(address.Rechtsform);

        //if (!address.BirthDate) {
        //    this.ad().BirthDate('')
        //} else {
        //    this.ad().BirthDate(address.BirthDate);
        //}
        //this.ad().BirthName(address.BirthName);
        //this.ad().Adress2(address.Address);

        //if (type === "mandant")
        //    type="Mandant"


        //console.log(this.ad());

    }

    newPersonObservable() {

        let obj = {
            FirstName: ko.observable(''),
            Address: ko.observable(''),
            Adress2: ko.observable(''),
            Adress2CityName: ko.observable(''),
            Adress2CountryID: ko.observable(''),
            Adress2PLZ: ko.observable(''),
            Adresszusatz: ko.observable(''),
            AnredeBriefkopf: ko.observable(''),
            AnredeDirekt: ko.observable(''),
            Arbeitgeber1: ko.observable(''),
            Arbeitgeber2: ko.observable(''),
            Arbeitgeber3: ko.observable(''),
            Bank: ko.observable(''),
            BankId: ko.observable(''),
            BirthName: ko.observable(''),
            // BirthDate: ko.observable(moment().format("YYYY-MM-DD")), //CHANGE: - Change occured in here
            BirthDate: ko.observable(''), //CHANGE: - Change occured in here
            CentralPhone: ko.observable(''),
            CityName: ko.observable(''),
            CountryID: ko.observable(''),
            CountryName: ko.observable(''),
            DebitorenKontoNummer: ko.observable(''),
            DisplayName: ko.observable(''),
            Ehepartner: ko.observable(''),
            EmailAddress: ko.observable(''),
            Fax: ko.observable(''),
            GesetzlicherVertreter1: ko.observable(''),
            GesetzlicherVertreter1Id: ko.observable(''),
            GesetzlicherVertreter2: ko.observable(''),
            GesetzlicherVertreter2Id: ko.observable(''),
            GesetzlicherVertreter3: ko.observable(''),
            GesetzlicherVertreter3Id: ko.observable(''),
            GesetzlicherVertreter4: ko.observable(''),
            GesetzlicherVertreter4Id: ko.observable(''),
            IBAN: ko.observable(''),
            Imex: ko.observable(''),
            KennzeichenMB: ko.observable(''),
            KontoNummer: ko.observable(''),
            KreditorenKontoNummer: ko.observable(''),
            MBType: ko.observable(''),
            MobilePhone: ko.observable('+49'),
            Name1: ko.observable(''),
            Name2: ko.observable(''),
            Name3: ko.observable(''),
            Note1: ko.observable(''),
            Note2: ko.observable(''),
            Note3: ko.observable(''),
            Note4: ko.observable(''),
            Note5: ko.observable(''),
            PLZPostfach: ko.observable(''),
            PersonalausweisNr: ko.observable(''),
            Phone1: ko.observable('+49'),
            Phone2: ko.observable(''),
            Phone3: ko.observable(''),
            Postfach: ko.observable(''),
            Rechtsform: ko.observable(''),
            Sonstige1: ko.observable(''),
            Sonstige2: ko.observable(''),
            SpezielleAnrede2: ko.observable(''),
            URL: ko.observable(''),
            ZipCode: ko.observable(''),
            Keyword: ko.computed<string>(String)
        };

        //CHANGE: - Change occured in here



        obj["Keyword"] = ko.computed(function () {
            return obj.DisplayName().replace(/\s/g, "") + obj.FirstName().replace(/\s/g, "");
        });
        //console.log("The obj:");
        //console.log(obj);
        return ko.observable(obj);
    };

    //CHANGE: - Change occured in here
    newUser = this.newPersonObservable();
    //CHANGE: - Change occured in here



    updateKeyword() {
        this.ad().Keyword(ko.toJS(this.ad().FirstName).replace(/\s/g, '') + ko.toJS(this.ad().DisplayName).replace(/\s/g, ''));
        this.TheCurrentKeyword(this.ad().Keyword());
    }

    /* from case */
    /* from case */

    vorsteuer = ko.observable<boolean>(false);

    async loadData(id) {
        const caseData1: any = (await RNSAPI.getExtendedCase(id)).Payload;
        this.vorsteuer(caseData1.Akte.IsMandantVorzugsteuerabzugberechtigt);
        this.caseAddresses = caseData1.Addresses;
        setTimeout(() => {
            this.changeButtonText(this.caseAddresses[0][0], 0, 'mandant', 0);
        }, 500);
        //console.log("reached here ", caseData1);
        location.reload();
        /* const rechtsgebiet: KnockoutObservable<string> = ko.observable("Allgemein" || "Allgemein");
        rechtsgebiet.subscribe(s => {
               this.initialize(id, getLayout(s), s);
           }); */
        //console.log(this.akte);
    }
    async addNewUser() {
        let cView = ''
        this.ad().Keyword(ko.toJS(this.ad().DisplayName) + ko.toJS(this.ad().FirstName));
        let TypeInt = 0;

        if (this.userType().toLowerCase() === "mandant") {
            this.userType("Mandant");
            TypeInt = 0;
            cView = 'beleiligte'
        }
        else if (this.userType().toLowerCase() === "gegner") {
            this.userType("Gegner");
            TypeInt = 1;
            cView = 'gegner'
        }
        else {
            this.userType("Sonstige");
            TypeInt = 7;
            cView = 'weitereI'
        }

        let Extcasedata = (await RNSAPI.getExtendedCase(this.caseId)).Payload;

        this.ad().Name1 = this.ad().DisplayName;
        this.ad().Address = this.ad().Adress2;

        if (TypeInt === 0) {
            this.ad().Rechtsform(ko.toJS(this.currentAnrede));
        }
        else if (TypeInt === 1) {
            this.ad().Rechtsform(ko.toJS(this.currentAnredeGegner));
        }
        else {
            this.ad().Rechtsform(ko.toJS(this.currentAnredeSonsti));
        }

        let MyKeyword = ko.toJS(this.ad().DisplayName) + ko.toJS(this.ad().FirstName);

        Extcasedata.Addresses[TypeInt].push(ko.toJS(this.ad()));
        if (!Utils.checkErrors(["Keyword", "FirstName", "DisplayName", "Rechtsform", "Adress2", "ZipCode", "CityName", "Rechtsform"], this.ad(), this.userType().toLowerCase(), [Utils.checkString])) return;

        RNSAPI.saveExtendedCase(Extcasedata).then(res => {
            if (res.Type !== "CaseUpdateSuccessful") {
                //console.log(" Not successed");
                this.infoTitle('')
                this.infoTitle('Fehler')
                this.infoMessage('')
                this.infoMessage('Daten wurden nicht aufgezeichnet.')
            }
            else {
                let result = RNSAPI.createPerson(this.ad(), ko.toJS(this.userType()));
                RNSAPI.joinPerson(MyKeyword, this.caseId, ko.toJS(this.userType()));
                this.infoTitle('')
                this.infoTitle('Erledigt')
                this.infoMessage('Daten erfolgreich erfasst.')
                this.modialAutoHide(true)
                setTimeout(() => {
                    this.IsNew(false);
                    this.loadData(this.caseId);
                    sessionStorage.setItem('currentView', cView)
                }, 4000)
            }
        }).catch((e) => {
            let error = Utils.handleError(e);
            this.infoMessage('');
            this.infoMessage(error['code'] + ': ' + error['message']);
        });
    }

    async addNewUser1(user) {
        //console.log("User:");
        //console.log(ko.toJS(user));

        let TypeInt = 0;

        if (this.userType() === "mandant") {
            this.userType("Mandant");
            TypeInt = 0;
        }
        else if (this.userType() === "gegner") {
            this.userType("Gegner");
            TypeInt = 1;
        }
        else {
            this.userType("Sonstige");
            TypeInt = 7;
        }

        //console.log("to save ",ko.toJS(this.userType()));

        //user.caseData.Addresses[0].push(ko.toJS(this.newUser()));

        let Extcasedata = (await RNSAPI.getExtendedCase(this.caseId)).Payload;



        console.log(this.caseId);
        console.log(Extcasedata);

        //user.caseData.Addresses[0].push(ko.toJS(this.newUser()));
        //this.caseAddresses[0].push(ko.toJS(user.newUser));

        //let displayName = user.newUser.DisplayName;
        //let firstName = user.newUser.FirstName;
        let keyW = ko.toJS(this.newUser().Keyword).toString();
        //console.log("to save ", ko.toJS(user.caseData));

        this.newUser().Name1 = this.newUser().DisplayName;

        //console.log(ko.toJS(this.newUser()));

        RNSAPI.saveExtendedCase(user.caseData).then(res => {
            if (res.Type !== "CaseUpdateSuccessful") {
                console.log(" Not successed");
            }
            else {
                //console.log("successed");
                let result = RNSAPI.createPerson(this.newUser, ko.toJS(this.userType()));
                RNSAPI.joinPerson(keyW, user.caseData.Akte.Registernummer, ko.toJS(this.userType()));
                this.loadData(user.caseData.Akte.Registernummer);
                this.IsNew(false);
            }
        })
            .catch(() => {
                console.log(" Not successed some error occured");
            });
        //TODO: - Get all case datas
        //let cases = await RNSAPI.getCases();
        /*         RNSAPI.saveExtendedCase(user.caseData).then(res => {

                        if (res.Type !== "CaseUpdateSuccessful") {

                             console.log(" Not successed");
                        }
                        else{
                            console.log("successed");
                        }
                    })
                    .catch(() => {
                         console.log(" Not successed some error occured");

                    }); */

        //TODO: Reset newUser Object
        // let newUser =
    }

    saveNewUser() {
        this.ad().Keyword(ko.toJS(this.ad().DisplayName) + ko.toJS(this.ad().FirstName));
        this.addNewUser();
    }

    createNewPerson() {
        Postbox.publisher().publish(this.userType(), "clearAddressModal");
        $('#allAddressModal').modal('show');
    }

    toggleAddNewUser = ko.observable(false);
    billInfo = ko.observable({});
    billInfoItems = ['Bitte wählen Sie', 'Selbstzahler', 'Rechtschutzversicherung', 'Prozesskostenhilfe', 'Beratungshilfe'];

    openAddNewUserModal() {
        this.toggleAddNewUser() ? this.toggleAddNewUser(false) : this.toggleAddNewUser(true);;
        // $('#addclient').modal('show');
    }

    async postDokumentData() {
        let selectedFile = (document.getElementById('ownFile') as any).files[0];

        if (selectedFile === undefined) {
            alert("Bitte geben Sie eine Datei an.");
            return;
        } else {

            this.basicEntry().MimeType(selectedFile.type);
            let reader = new FileReader();
            reader.onload = async (evt) => await this.createDokumenEntry(evt.target, selectedFile.name.split('.').pop());
            reader.readAsBinaryString(selectedFile);

        }

    }

    fillInfo() {

        let obj = this.infData();
        obj.Notizen(ko.toJS(this.info().Notizen));
        obj.Notiz_Sachverhalt(ko.toJS(this.info().Notiz_Sachverhalt));
        obj.Notiz_Mandantenbegehren(ko.toJS(this.info().Notiz_Mandantenbegehren))
    }

    fillExtendedInfo(data) {
        this.extendedInfo = data;
    }
    async addNewCase() {

        let newCase = {
            unrede: $("#unrede").val(),
            vorname: $("#DisplayName-vorname").val(),
            nachname: $("#fname-nachname").val(),
            address: $("#Address-stobe").val(),
            zipCode: $("#ZipCode-plz").val(),
            cityName: $("#CityName-ort").val(),
            phone: $("#Phone1-telefon").val(),
            emailAddress: $("#EmailAddress-1").val(),
            mobilePhone: $("#MobilePhone-telefon").val(),
            fax: $("#Fax-nr").val(),
            homepage: $("#home-page").val(),
            Rechtsform: $("#Rechtsform2").val(),
            Adresszusatz: $("#Adresszusatz").val(),
            AnredeBriefkopf: $("#AnredeBriefkopf2").val(),
            Anrededirekt: $("#Anrededirekt").val(),
            BirthDate: $("#BirthDate2").val(),
            Geburtsort: $("#Geburtsort").val(),
            Saatsangehaigkeit: $("#Saatsangehaigkeit").val(),

        }

        //TODO: Save data to server and after success, append into the UI
        // await RNSAPI.saveExtendedCase(newCase);
    }

    //TODO: - Moved into dokumente component
    basicEntry = this.newBasicEntry();
    workflows = ko.observableArray([])
    selectedWorkflow = ko.observableArray();
    selectedWorkflowItem = ko.observable('');

    newBasicEntry() {
        let obj = {
            Id: ko.observable(null),
            CaseId: ko.observable(""),
            CurrentCRTVersion: ko.observable(" "),
            DocType: ko.observable(0),
            Documents: ko.observableArray([]),
            LawyerId: ko.observable(RNSAPI.User() ? RNSAPI.User().username : "GR"),
            MimeType: ko.observable("application/pdf"),
            Note1: ko.observable(""),
            Recipient: ko.observable(""),
            Subject: ko.observable(""),
            imex: ko.observable("A"),
            isScanned: ko.observable("0"),
            isVerschluss: ko.observable(false),
            WorkflowId: ko.observable("")
        };

        return ko.observable(obj);
    };

    async pickPerson(type) {
        let addresses = (await RNSAPI.getPersonsForScope(type)).Payload.Addresses;

        let headline = "Mandanten auswählen";
        if (type === "gegner") {
            headline = "Gegner auswählen";
        }
        else if (type === "sonstige")
            headline = "Sonstige auswählen"

        this.pickGeneric(headline, ["FirstName", "Name1", "CityName"], ["Vorname", "Nachname", "Ort"], addresses);
        this.modalHandleSelection((selectedObject) => {
            let personFromDb = selectedObject();
            let obj = this.newPersonObservable();
            for (let key in personFromDb) {
                if (obj[key] === undefined) {
                    obj[key] = personFromDb[key];
                } else if (key !== "Keyword") {
                    obj[key](personFromDb[key]);
                } else {
                    obj["Keyword"] = ko.observable(personFromDb["Keyword"]);
                }
            }
            this.joinPerson(type, obj["Keyword"]);
        });
        $('#ExtCasemodal').modal('show');
    };

    unJoinPerson(component, data, type) {
        this.rmvPerson(data.Keyword);
        this.rmvType(component);
        $('#RemovePerson').modal('show');
    }

    async actuallyUnjoin() {
        let result = await RNSAPI.unjoinPerson(ko.toJS(this.rmvPerson), this.caseId, ko.toJS(this.rmvType))
        $('#RemovePerson').modal('hide');
        this.messageModalWithReload();
    }

    rmvPerson = ko.observable('');
    rmvType = ko.observable('');

    async joinPerson(type, personKeyWord) {
        let result = await RNSAPI.joinPerson(personKeyWord, this.caseId, type)
        this.messageModalWithReload();
    }

    messageModalWithReload() {
        this.infoMessage('Daten erfolgreich erfasst.');
        this.infoTitle('Erledigt');
        this.modialAutoHide(true);
        this.showInfoMessage();
        setTimeout(() => {
            this.loadData(this.caseId);
        }, 3000)
    }

    pickPersonAWH = async (type) => {
        if (!type) return
        let addresses = (await RNSAPI.getPersonsForScope(type)).Payload.Addresses;
        this.pickGeneric(type === 'mandant' ? "Mandantenauswahl" : "Gegnerauswahl", ["FirstName", "Name1", "CityName"], ["Vorname", "Nachname", "Ort"], addresses);
        this.modalHandleSelection((selectedObject) => {
            let o = selectedObject();
            for (let key in o) {
                $("#" + type + key).val(o[key])
            }
        });
        $('#ExtCasemodal').modal('show');
    }

    createEntry() {
        $('#uploadDocument').modal('show');
        this.workflows([
            {
                name: 'Ak',
                text: "Autorenkorrektur"
            },
            {
                name: 'B',
                text: "Zur Bearbeitung"
            },
            {
                name: 'BDS',
                text: 'Löschung wg. BDSG'
            },
            {
                name: 'Di',
                text: 'Diktat'
            },
            {
                name: 'E',
                text: 'Erledigt'
            },
            {
                name: 'EGP',
                text: 'an EGVP übergeben'
            },
            {
                name: 'EIN',
                text: 'Empfangene Nachrichten'
            },
            {
                name: 'ERV',
                text: 'Elektronischer Rechtsverkehr'
            },
            {
                name: 'F',
                text: 'Fertigstellung'
            },
            {
                name: 'PA',
                text: 'Postausgang'
            },
            {
                name: 'PE',
                text: 'Posteingang'
            },
            {
                name: 'PR',
                text: 'Prüfung'
            },
            {
                name: 'SG',
                text: 'Signatur geprüft'
            },
            {
                name: 'SI',
                text: 'Signiert'
            },
            {
                name: 'St',
                text: 'Standardtext'
            },
            {
                name: 'U',
                text: 'Zur Unterschrift'
            },
            {
                name: 'V',
                text: 'Versand'
            },
            {
                name: 'VF',
                text: 'Versandfertig machen'
            },
            {
                name: 'VPA',
                text: 'Archiv VP'
            },
            {
                name: 'ZS',
                text: 'Zur Signatur'
            },
            {
                name: 'ZU',
                text: 'zur Unterschrift'
            },
            {
                name: 'b',
                text: 'Bearbeitung Büro'
            },
            {
                name: 'eCD',
                text: 'Download eConsult'
            },
            {
                name: 'eCU',
                text: 'Upload eConsult'
            },
            {
                name: 'eDD',
                text: 'Download Drebis'
            },
            {
                name: 'eDU',
                text: 'Upload Drebis'
            },
            {
                name: 'ra',
                text: 'Bearbeitung Anwalt'
            }])
        // MainViewModel.RoutingTable.showCaseEntryView({ caseId: this.caseId });
    }

    createText() {
        $('#createTextDocument').modal('show');
        // MainViewModel.RoutingTable.showTextTemplateView({ caseId: this.caseId });
    }

    //TODO: - Moved into dokumente component
    async createDokumenEntry(document: any, ending: string) {
        let documentObj = {
            CRTVersion: " ",
            DocumentData: btoa(document.result),
            OleType: ending.toUpperCase(),
            OLE2Type: ending.toUpperCase(),
            useOCR: false
        };

        this.basicEntry().Documents([documentObj]);

        try {
            let result = await RNSAPI.createCaseEntry(this.basicEntry());
            if (result.Type === "InsertionSuccessful") {
                //console.log('dokument inserted successfully: ', result);
                alert('dokument inserted successfully');
                setTimeout(() => {
                    $('#uploadDocument').modal('hide');
                }, 200);
                // MainViewModel.RoutingTable.showCaseEntriesView({ caseId: this.basicEntry().CaseId() });
            } else {
                alert("Fehler beim Anlegen.");
            }
        } catch (e) {
            console.log(e);
            alert("Fehler beim Anlegen: " + e.responseText);
        }
    }
    //TODO: - Moved into dokumente component
    workflowChanged;

    /*   async getAddresses() {
          let address1 = (await RNSAPI.getPersonsForScope("Gericht")).Payload.Addresses;
          console.log(address1);
          //TODO: Please remove slice from me
          this.courtsAddress(address1.slice(1, 30));
          this.selectedCourtAddress(address1[0]);
      } */
    //CHANGE: - Change occured in here
    async getAddresses(type) {
        //console.log("adfasf:" + await RNSAPI.getPersonsForScope(type));
        let address1 = (await RNSAPI.getPersonsForScope(type)).Payload.Addresses;
        //console.log(address1);
        //console.log("the address is ",address1);
        //TODO: Please remove slice from me
        this.courtsAddress(address1.slice(1, 2000));
        //console.log("the address is ",this.courtsAddress());
        //TODO: If there is no active court, use default court
        //let c1=address1[0]['keyword'];
        let c2 = address1[1]['keyword'];
        //let d = {...c1, ...c2};
        this.selectedCourtAddress(c2);
        //console.log(this.selectedCourtAddress);
        //this.selectedCourtAddress(address1[0]['keyword']);
    }

    //TODO: - Delete me
    async pickAddress1(Instance) {
        this.filter("");
        $('#gerichtsmodal').modal('show');
        if (this.GerAdressen.length === 0) {
            this.isLoadingData(true);
            await RNSAPI.getPersonsForScope("Gericht").then((response) => {
                this.GerAdressen(response.Payload.Addresses);
                this.isLoadingData(false);
            }, (error) => {
                this.isLoadingData(false);
            });
            let AllAddr = this.GerAdressen();
            Postbox.publisher().subscribe((Filter) => {
                this.GerAdressen(Filter);
            }, "ApplyFilter");
            document.getElementById('inputGer-filter').oninput = function (f) {
                let theFilter = (<HTMLInputElement>document.getElementById('inputGer-filter')).value;
                let Filtered = [];
                if (theFilter.length > 2) {
                    for (let i = 0; i < AllAddr.length; i++) {
                        if ((AllAddr[i].Name1 + AllAddr[i].Name2 + AllAddr[i].Name3).toString().toLowerCase().includes(theFilter.toString().toLowerCase())) {
                            Filtered.push(AllAddr[i])
                        }
                    }
                    Postbox.publisher().publish(Filtered, "ApplyFilter");
                }
                else {
                    Postbox.publisher().publish(AllAddr, "ApplyFilter");
                }

            }
        }
        if (Instance === 1) {
            this.modalHandleSelection((selectedObject) => this.akte().Gericht1Id(selectedObject()["Keyword"]));
        }
        else if (Instance === 2) {
            this.modalHandleSelection((selectedObject) => this.akte().Gericht2Id(selectedObject()["Keyword"]));
        }
        else if (Instance === 3) {
            this.modalHandleSelection((selectedObject) => this.akte().Gericht3Id(selectedObject()["Keyword"]));
        }
    }

    async pickAddressVers(Instance) {
        this.filter("");
        $('#versicherungsmodal').modal('show');
        if (this.VerAdressen.length === 0) {
            this.isLoadingData(true);
            await RNSAPI.getPersonsForScope("versicherung").then((response) => {
                this.VerAdressen(response.Payload.Addresses);
                this.isLoadingData(false);
            }, (error) => {
                this.isLoadingData(false);
            });
            let AllAddr = this.VerAdressen();
            Postbox.publisher().subscribe((Filter) => {
                this.VerAdressen(Filter);
            }, "ApplyFilterVers");
            document.getElementById('inputVers-filter').oninput = function (f) {
                let theFilter = (<HTMLInputElement>document.getElementById('inputVers-filter')).value;
                let Filtered = [];
                if (theFilter.length > 2) {
                    for (let i = 0; i < AllAddr.length; i++) {
                        if ((AllAddr[i].Name1 + AllAddr[i].Name2 + AllAddr[i].Name3).toString().toLowerCase().includes(theFilter.toString().toLowerCase())) {
                            Filtered.push(AllAddr[i])
                        }
                    }
                    Postbox.publisher().publish(Filtered, "ApplyFilterVers");
                }
                else {
                    Postbox.publisher().publish(AllAddr, "ApplyFilterVers");
                }

            }
        }
        if (Instance === 1) {
            this.modalHandleSelection((selectedObject) => this.akte().MandantHaftpflichtversicherungId(selectedObject()["Keyword"]));
        }
        else if (Instance === 2) {
            this.modalHandleSelection((selectedObject) => this.akte().GegnerHaftpflichtversicherungId(selectedObject()["Keyword"]));
        }
        else if (Instance === 3) {
            this.modalHandleSelection((selectedObject) => this.akte().AuthorityId(selectedObject()["Keyword"]));
        }
    }

    async pickAddressBeh(Instance) {
        this.filter("");
        $('#behoerdenmodal').modal('show');
        if (this.BehAdressen.length === 0) {
            this.isLoadingData(true);
            await RNSAPI.getPersonsForScope("behörde").then((response) => {
                this.BehAdressen(response.Payload.Addresses);
                this.isLoadingData(false);
            }, (error) => {
                this.isLoadingData(false);
            });
            let AllAddr = this.BehAdressen();
            Postbox.publisher().subscribe((Filter) => {
                this.BehAdressen(Filter);
            }, "ApplyFilterBeh");
            document.getElementById('inputBeh-filter').oninput = function (f) {
                let theFilter = (<HTMLInputElement>document.getElementById('inputBeh-filter')).value;
                let Filtered = [];
                if (theFilter.length > 2) {
                    for (let i = 0; i < AllAddr.length; i++) {
                        if ((AllAddr[i].Name1 + AllAddr[i].Name2 + AllAddr[i].Name3).toString().toLowerCase().includes(theFilter.toLowerCase())) {
                            Filtered.push(AllAddr[i])
                        }
                    }
                    Postbox.publisher().publish(Filtered, "ApplyFilterBeh");
                }
                else {
                    Postbox.publisher().publish(AllAddr, "ApplyFilterBeh");
                }

            }
        }
        if (Instance === 1) {
            this.modalHandleSelection((selectedObject) => this.akte().AuthorityId(selectedObject()["Keyword"]));
        }
    }

    async pickAddressAnw(Instance) {
        this.filter("");
        $('#anwaltmodal').modal('show');
        if (this.AnwAdressen.length === 0) {
            this.isLoadingData(true);
            await RNSAPI.getPersonsForScope("anwalt").then((response) => {
                this.AnwAdressen(response.Payload.Addresses);
                this.isLoadingData(false);
            }, (error) => {
                this.isLoadingData(false);
            });
            let AllAddr = this.AnwAdressen();
            Postbox.publisher().subscribe((Filter) => {
                this.AnwAdressen(Filter);
            }, "ApplyFilterAnw");
            document.getElementById('inputAnw-filter').oninput = function (f) {
                let theFilter = (<HTMLInputElement>document.getElementById('inputAnw-filter')).value;
                let Filtered = [];
                if (theFilter.length > 2) {
                    for (let i = 0; i < AllAddr.length; i++) {
                        if ((AllAddr[i].Name1 + AllAddr[i].Name2 + AllAddr[i].Name3).toString().toLowerCase().includes(theFilter.toLowerCase())) {
                            Filtered.push(AllAddr[i])
                        }
                    }
                    Postbox.publisher().publish(Filtered, "ApplyFilterAnw");
                }
                else {
                    Postbox.publisher().publish(AllAddr, "ApplyFilterAnw");
                }

            }
        }
        if (Instance === 1) {
            this.modalHandleSelection((selectedObject) => this.akte().GegenAnwaltId(selectedObject()["Keyword"]));
        }
        else if (Instance === 2)
            this.modalHandleSelection((selectedObject) => this.akte().KorrAnwaltID(selectedObject()["Keyword"]));
    }

    focusinput() {
        if (this.initialfocusinput) {
            document.getElementById("inputGer-filter").focus();
            this.initialfocusinput = false;
        }
    }

    focusinputVers() {
        if (this.initialfocusinput) {
            document.getElementById("inputVers-filter").focus();
            this.initialfocusinput = false;
        }
    }

    focusinputBeh() {
        if (this.initialfocusinput) {
            document.getElementById("inputBeh-filter").focus();
            this.initialfocusinput = false;
        }
    }

    focusinputAnw() {
        if (this.initialfocusinput) {
            document.getElementById("inputAnw-filter").focus();
            this.initialfocusinput = false;
        }
    }

    modalPick() {
        if (this.modalHandleSelection !== null) this.modalHandleSelection()(this.modalSelectedObject);
        $('#gerichtsmodal').modal('hide');
    };

    modalPickVers() {
        if (this.modalHandleSelection !== null) this.modalHandleSelection()(this.modalSelectedObject);
        $('#versicherungsmodal').modal('hide');
    };

    modalPickBeh() {
        if (this.modalHandleSelection !== null) this.modalHandleSelection()(this.modalSelectedObject);
        $('#behoerdenmodal').modal('hide');
    };

    modalPickAnw() {
        if (this.modalHandleSelection !== null) this.modalHandleSelection()(this.modalSelectedObject);
        $('#anwaltmodal').modal('hide');
    };

    modalSelect = (obj, event) => {

        if ($(event.target.parentElement).hasClass("active")) {
            this.modalPick();
        }
        else {
            $("#modalTable tr").removeClass("active");
            $(event.target.parentElement).addClass("active");
            this.modalSelectedObject(obj);
        }
    }

    modalSelectVers = (obj, event) => {

        if ($(event.target.parentElement).hasClass("active")) {
            this.modalPickVers();
        }
        else {
            $("#modalTable tr").removeClass("active");
            $(event.target.parentElement).addClass("active");
            this.modalSelectedObject(obj);
        }
    }

    modalSelectBeh = (obj, event) => {

        if ($(event.target.parentElement).hasClass("active")) {
            this.modalPickBeh();
        }
        else {
            $("#modalTable tr").removeClass("active");
            $(event.target.parentElement).addClass("active");
            this.modalSelectedObject(obj);
        }
    }

    modalSelectAnw = (obj, event) => {

        if ($(event.target.parentElement).hasClass("active")) {
            this.modalPickAnw();
        }
        else {
            $("#modalTable tr").removeClass("active");
            $(event.target.parentElement).addClass("active");
            this.modalSelectedObject(obj);
        }
    }

    GerAdressen = ko.observableArray([]);
    VerAdressen = ko.observableArray([]);
    BehAdressen = ko.observableArray([]);
    AnwAdressen = ko.observableArray([]);
    allItems = ko.observableArray([]);
    filter = ko.observable('');
    modalSelectedObject = ko.observable(null);
    initialfocusinput = true;

    //CHANGE: - Change occured in here
    async pickCase(type) {
        this.userType(type);
        //console.log(type);
        if (!type) return;

        let addresses = (await RNSAPI.getPersonsForScope(type)).Payload.Addresses;

        // let cases = (await RNSAPI.getCases()).Payload.Cases;
        this.pickGeneric(type.toString() + "-Auswahl", ["DisplayName", "FirstName", "CityName"], ["Vorname", "Nachname", "Ort"], addresses);
        this.modalHandleSelection((selectedObject) => {
            let address = ko.toJS(selectedObject);
            //console.log("before",ko.toJS(this.newUser()));
            this.newUser().Keyword(address.Keyword);
            this.newUser().FirstName(address.FirstName);
            this.newUser().DisplayName(address.DisplayName);
            this.newUser().Adress2(address.Adress2);
            this.newUser().ZipCode(address.ZipCode);
            this.newUser().CityName(address.CityName);
            this.newUser().Phone1(address.Phone1);
            this.newUser().Fax(address.Fax);
            this.newUser().URL(address.URL);
            this.newUser().Rechtsform(address.Rechtsform);
            this.newUser().Adresszusatz(address.Adresszusatz);
            this.newUser().AnredeBriefkopf(address.AnredeBriefkopf);
            this.newUser().AnredeDirekt(address.AnredeDirekt);
            if (!address.BirthDate) {
                this.newUser().BirthDate(moment().format("YYYY-MM-DD"));
            } else {
                this.newUser().BirthDate(address.BirthDate);
            }
            this.newUser().BirthName(address.BirthName);
            //console.log("After",ko.toJS(this.newUser()));
        });

        $('#ExtCasemodal').modal('show');

    }

    //CHANGE: - Change occured in here
    typeChanged(obj, event) {
        if (event.originalEvent) {
            //console.log(obj, event);
        }
    }

    /*
        typeChanged(obj, event) {
            if (event.originalEvent) {
                console.log(obj, event);
            }
        } */

    async sendMail(entry) {

        // this.currentViewer = ko.observable('mail');
        this.currentViewer("compose");
        $('#DocumentViewerModal').modal('show');
        this.documentToEmail({
            entry: entry,
            email: this.ad().EmailAddress
        });

        //console.log('mail to send: ');
        //console.group('entry: ', entry)
        //console.log('Betreff', entry.CaseId)
        //console.log('Akte', entry.Subject)
        //console.log('currentViewer', this.currentViewer())
        //console.groupEnd();
    }

    async remove(id: string) {
        await RNSAPI.deleteRecord(id);
        await this.loadEntries();
    }

    async download(name: string, id: string) {
        let element = document.createElement('a');
        let doc = (await RNSAPI.getDocumentData(id)).Payload.Document;
        //server side bug in the ActiveX Component => OleType is doc instead of rtf
        let IsRTF = atob(doc.DocumentData.substr(0, 50)).indexOf("rtf") !== -1;
        let type = IsRTF ? "rtf" : doc.OLE2Type.trim().toLowerCase();

        let blob = Utils.base64ToBlob(doc.DocumentData, 'application/octet-stream');

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob);
        } else {
            element.setAttribute('href', 'data:application/octet-stream;charset=utf-16le;base64,' + doc.DocumentData);
            element.setAttribute('download', `${name.trim()}.${type}`);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }
    }

    documentColumns = ['RecordDate', 'DocumentDate', 'Subject', 'Recipient', '', 'LawyerId'];
    documentColumnHeaders = ['Geändert', 'Dok-Dat', 'Textinhalt', 'Empfänger/Absender', 'DK', 'SB'];
    selectedElement = ko.observable(null);
    isDocumentsEmpty: ko.Computed<boolean>;

    //TODO: - Moved into dokumente component
    executeAction = (elem: any, handler: any) => {
        if (handler.selectable) {
            this.selectedElement(elem);
        }

        handler.action();
        //this.showYNDia();
    }

    async loadEntries() {

        try {

            let entries = (await RNSAPI.getCaseEntriesForId(this.caseId)).Payload.Records
                .filter((entry) => {
                    return entry.MimeType.indexOf("application/vnd.renostar.xmp") === -1;
                })
                .sort((a, b) => {
                    let dateA = new Date(a.LastChange || a.DocumentDate || a.RecordDate);
                    let dateB = new Date(b.LastChange || b.DocumentDate || b.RecordDate);
                    if (dateA < dateB) return 1;
                    else if (dateA > dateB) return -1;
                    else return 0
                })
                .map((entry) => {
                    entry.DocumentDate = moment(entry.LastChange || entry.DocumentDate || entry.RecordDate).format('DD.MM.YYYY');
                    entry.RecordDate = moment(entry.RecordDate).format('DD.MM.YYYY');
                    entry.moreActionHandlers = [
                        {
                            'icon': 'envelope',
                            'name': 'Versenden',
                            'action': () => {
                                this.currentId(entry.Id);
                                this.currentViewer('')
                                this.sendMail(entry);
                            }
                        },
                        {
                            'icon': 'trash-alt',
                            'name': 'Löschen',
                            'action': () => {
                                this.remove(entry.Id);
                            }
                        }
                    ]

                    entry.actionHandlers = [
                        {
                            'icon': 'eye',
                            'name': 'Bearbeiten',
                            'action': () => {
                                this.currentViewer("");
                                this.currentMimeType(entry.MimeType.toLowerCase());
                                //console.log(this.currentMimeType);
                                this.currentId(entry.Id);
                                if (entry.MimeType.toLowerCase().indexOf("pdf") !== -1) {
                                    this.currentViewer("pdf");
                                } else if (entry.MimeType.toLowerCase().indexOf("rfc822") !== -1) {
                                    this.currentViewer("mail");
                                } else if (entry.MimeType.toLowerCase().indexOf("audio/ogg") !== -1) {
                                    this.currentViewer("play");
                                } else {
                                    this.currentViewer("wopi");
                                }
                                $('#DocumentViewerModal').modal('show');
                            }
                        },
                        {
                            'icon': 'pencil-alt',
                            'name': 'Edit',
                            'action': () => {
                                MainViewModel.RoutingTable.showCaseEntryView({ caseId: this.caseId, eCaseRecordId: entry.Id });
                            }
                        },
                        {
                            'icon': 'tags',
                            'name': 'Tags',
                            'action': () => {
                            }
                        },
                        {
                            'icon': 'cloud-download-alt',
                            'name': 'Download',
                            'action': () => {
                                this.download(entry.Subject, entry.Id);
                            }
                        }
                    ];

                    return entry;
                });

            this.caseEntries(entries);
        } catch (e) {
            console.log(e);
        }

    }

    openStampDialog = () => {
        $("#createStamp").modal();
    };

    public getVM = () => this;

    predefinedStamps = ko.observableArray([]);
    currentStamps: ko.ObservableArray<Stamp> = ko.observableArray([])


    private async loadPredefinedStamps() {
        let templates = (await RNSAPI.getStampTemplates()).Payload.Templates;
        this.predefinedStamps(templates);
    }

    private async loadStamps(recordId: string) {
        if (recordId) {
            let stamps = (await RNSAPI.getStampsByECaseId(recordId)).Payload.Stamps;
            let stampsWithEditedDate = stamps.map(stamp => {
                stamp.CreationDate = (new Date(stamp.CreationDate)).toLocaleDateString();
                return stamp;
            });
            this.currentStamps(stampsWithEditedDate);
        }
    }

    deleteStamp = async (stampId: string) => {
        let result = await RNSAPI.deleteStampById(stampId);
        if (result.Type === "DeleteStampSuccessful") {
            this.updateStamps();
        } else {
            alert("Fehler beim Löschen des Stempels.");
        }
    }

    public async updateStamps() {
        this.loadStamps(this.currentId());
    }

    createStampFromTemplate = async (stamp: PredefinedStamp) => {
        let result = await RNSAPI.addStamp(stamp.Text, "Regular", this.currentId());
        if (result.Type === "AddSuccessful") {
            this.updateStamps();
        } else {
            alert("Fehler beim Erstellen des Stempels.");
        }
    }

    async loadDocumentFromECase(id: string) {
        let result = await RNSAPI.getDocumentData(id);
        this.blob = Utils.base64ToBlob(result.Payload.Document.DocumentData, "application/pdf");
        let pdfData = Utils.base64ToUint8Array(result.Payload.Document.DocumentData);
        this.loadPdf(pdfData);
        this.print();
    }

    private loadPdf(pdfData) {
        let pdf = PDFJS.getDocument(pdfData);
        pdf.then((document) => this.loadPage(document, 1));
    }

    print = () => {
        let fileURL = URL.createObjectURL(this.blob);
        this.url(fileURL);
    }

    private loadPage(pdfDocument, pageNumber: number) {
        let SCALE = 0.75;

        this.pdfDocument = pdfDocument;
        this.maximumPageNumber(pdfDocument.numPages);
        $("#pdfContainer").empty();
        var container = document.getElementById('pdfContainer');
        return pdfDocument.getPage(pageNumber).then(function (pdfPage) {
            var pdfPageView = new (PDFJS as any).PDFPageView({
                container: container,
                id: pageNumber,
                scale: SCALE,
                defaultViewport: pdfPage.getViewport(SCALE),
                textLayerFactory: new (PDFJS as any).DefaultTextLayerFactory(),
            });
            pdfPageView.setPdfPage(pdfPage);
            return pdfPageView.draw();
        });
    }

    maximumPageNumber = ko.observable(1);
    pdfDocument = null;

    addMoreAction(entry) {
        entry.actionHandlers.push(
            {
                'icon': 'trash-alt',
                'name': 'Löschen',
                'action': () => {
                    this.remove(entry.Id);
                }
            },
        );
    }

    showDocuments() {

        this.currentView('dokumente');
        this.updateUserData();

    }

    private async updateUserData() {
        let data = await RNSAPI.receiveUserData();
        RNSAPI.writeUserData(new UserData(this.caseId));
    }

    async cityFilter() {
        const { Type, Payload } = await RNSAPI.getPostcode(this.ad().ZipCode());
        if (Type == "GetPostcodeResult" && Payload.Cities.length > 0) {
            this.ad().CityName(Payload.Cities[0])
        }
    }

    xml = ko.observable('')
    title = ko.observable('')
    subtitle = ko.observable('')

    handleNaming(word) {
        return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase()
    }

    changeCheckbox(event, name, parent) {
        if (event && event.target && event.target.checked && name && parent) {
            $(`.newcase [showOn='${name}']`).each(function () {
                // console.log($(this).css('display', 'block'))
                $(`.newcase [id='${parent + $(this).attr('name')}']`)
                    .closest("[class*='col-m']").css('display', 'block')
            })
        } else if (event && event.target && !event.target.checked && name && parent) {
            $(`.newcase [showOn='${name}']`).each(function () {
                // console.log($(this).css('display', 'block'))
                $(`.newcase [id='${parent + $(this).attr('name')}']`)
                    .closest("[class*='col-m']").css('display', 'none')
            })
        }
    }

    inputFormField(element) {
        return `<div class="form-group">
            <label for="exampleInput1" class="${$(element).attr('required') ? $(element).attr('required') : ''}">${$(element).attr('label')}</label>
            ${$(element).attr('tooltip') ? this.setTooltip($(element).attr('tooltip')) : ""}
            <input type="text" id="` + $(element).parent().get(0).nodeName.toLowerCase() + $(element).attr('name') + `" class="form-control">
        </div>`
    }

    selectFormField(element, id, domOptions) {
        return `<div class="form-group">
        <label for="" class="${$(element).attr('required') ? $(element).attr('required') : ''}">${$(element).attr('label')}</label>
        ${$(element).attr('tooltip') ? this.setTooltip($(element).attr('tooltip')) : ""}
        <select class="form-control" id="${id}"
        data-bind="event:{change: changeOptions('${$(element).attr("ref")}', event, '${$(element).parent().get(0).nodeName.toLowerCase()}') }"
        style="background-image: linear-gradient(45deg, transparent 50%, #D2D2D2 50%), linear-gradient(135deg, #D2D2D2 50%, transparent 50%), linear-gradient(to right, #FFF, #FFF) !important;
        background-position: calc(100% - 20px) calc(1em + 1.5px), calc(100% - 15px) calc(1em + 1.5px), calc(100% - 2.5em) 0.5em !important;
        background-size: 5px 5px, 5px 5px, 1px 1.5em !important;
        background-repeat: no-repeat !important;
        margin: 0 !important;
        -webkit-box-sizing: border-box !important;
        -moz-box-sizing: border-box !important;
        box-sizing: border-box !important;
        -webkit-appearance: none !important;
        -moz-appearance: none !important;
        cursor: pointer !important;">
        <option></option>` +
            domOptions +
            `</select>
    </div>`
    }

    textareaFormField(element) {
        return `<div class="form-group">
                <label for="exampleInput1" class="${$(element).attr('required') ? $(element).attr('required') : ''}">${$(element).attr('label')}</label>
                ${$(element).attr('tooltip') ? this.setTooltip($(element).attr('tooltip')) : ""}
                <textarea id="${$(element).parent().get(0).nodeName.toLowerCase()}${$(element).attr('name')}" class="form-control" id="" rows="3"></textarea>
        </div>`
    }

    checkboxFormField(element) {
        if ($(element).attr('toggle')) {
            return `<div class="form-group form-check mb-1">
            <input type="checkbox" class="form-check-input" value="" id="` + $(element).parent().get(0).nodeName.toLowerCase() + $(element).attr('name') + `"
            data-bind="event:{change: changeCheckbox(event, '${$(element).attr('name')}', '${$(element).parent().get(0).nodeName.toLowerCase()}') }" 
            >
            ${$(element).attr('tooltip') ? this.setTooltip($(element).attr('tooltip')) : ""}
            <label class="form-check-label" for="" style="color: #3cb7d6; font-size: initial;">${$(element).attr('label')}</label>
        </div>`
        } else {
            return `<div class="form-group form-check mb-1">
            <input type="checkbox" class="form-check-input" value="" id="` + $(element).parent().get(0).nodeName.toLowerCase() + $(element).attr('name') + `"
            data-bind="event:{change: changeCheckbox(event, '${$(element).attr('name')}', '${$(element).parent().get(0).nodeName.toLowerCase()}') }" 
            >
            ${$(element).attr('tooltip') ? this.setTooltip($(element).attr('tooltip')) : ""}
            <label class="form-check-label" for="">${$(element).attr('label')}</label>
        </div>`
        }
    }

    setTooltip(title) {
        return `<span class="d-inline-block" tabindex="0" data-toggle="tooltip" title="" data-original-title="${title}">
            <small>
                <i class="fas fa-question-circle text-default"></i>
            </small>
        </span>`
    }

    parseTemplate(template) {
        var container = $('<div/>');
        if ($(template).attr('type')) {
            this.title(`${$(template).attr('title')}`)
            this.subtitle(`${$(template).attr('subtitle')}`)
        }

        $(template).find('*').each((index, item) => {
            if ($(item).attr('type') == 'header' && ["MANDANT", "GEGNER"].indexOf(item.nodeName) === -1) {
                let selection = ''
                if (Number($(item).attr('collapsable')) > 0) {
                    if (Number($(item).attr('hasSelection')) > 0) {
                        selection = `<div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                            <span class="form-inline">	
                            <h5 class="mr-3 mt-0">` + this.handleNaming(item.nodeName) + `</h5>
                            <button data-bind="click: function() {pickPerson('${item.nodeName.toLowerCase()}')}" class="btn btn-secondary btn-sm mt-1 mb-2"><i class="fas fa-search mr-1"></i> Aus Stammdaten hinzufügen</button>
                            </span>
                            </div>
                        </div>`
                    }
                    $(item).append(`
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 mt-3">
                            <a class="btn btn-default text-white w-100 text-left" href="#collapseExample${this.handleNaming(item.nodeName)}" data-toggle="collapse"  role="button" aria-expanded="false" aria-controls="collapseExampleThree"><i class="fas fa-angle-down mr-1"></i>${$(item).attr('title')}</a>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="bg-white p-3 rounded-bottom show ${!Number($(item).attr('collapsed')) ? '' : 'collapse'}" id="collapseExample${this.handleNaming(item.nodeName)}">`
                        + selection +
                        `<div class="row target"></div></div>
                        </div>
                    </div>
                    `)
                }
                else if (Number($(item).attr('collapsable')) == 0 && $(item).attr('title')) {
                    selection = `<div class="bg-white pb-2 px-3 mt-4 rounded">
                    <div class="row">	
                        <div class="col-md-12 col-sm-12 col-lg-12 mt-3 mb-2">
                        <h5 class="">${$(item).attr('title')}</h5>
                        </div>
                    </div>
                    <div class="row target"></div>`

                    $(item).append(selection)
                }
                else if (Number($(item).attr('collapsable')) == 0 && !$(item).attr('title')) {
                    selection = `<div class="bg-white py-2 px-3 mt-4 rounded">
                    <div class="row target"></div>`
                    $(item).append(selection)
                }

            }
            else if ($(item).attr('name') && $(item).attr('name').includes('Rechtsform')) {
                if ($(item).parent().attr('type') == 'header') {
                    const id = $(item).parent().get(0).nodeName.toLowerCase() + $(item).attr('name')
                    $(item).parent().find('.target').append(`<div style="${$(item).attr('hidden') ? 'display: none;"' : '"'} class="col-sm-${$(item).attr('col')} col-md-${$(item).attr('col')} col-lg-${$(item).attr('col')}">
                    <div class="form-group">
                        <label for="mandantrechtsform" class="${$(item).attr('required') ? $(item).attr('required') : ''}">${$(item).attr('label')}</label>
                        ${$(item).attr('tooltip') ? this.setTooltip($(item).attr('tooltip')) : ""}
                        <div class="input-group" style="position: relative;">
                            <input type="text" name="rechtsform" data-bind="event:{focus: showSuggestionsRFAWH('${id}')}" autocomplete="off" class="form-control" id="${id}">
                            
                            <ul class="dropdown-menu" id="suggestionsMandantRFInp" style="top: 34px;
                            width: 100%;position: absolute !important; left:unset!important; border-top-left-radius:0px; border-top-right-radius:0px; padding-top:unset; height:250px; overflow:hidden; overflow-y:scroll">
                                <li style="padding-left:0.75rem; cursor:default; background-color: #e9ecef;opacity: 1;">Geschlecht</li>
                                <!--ko foreach: rachtsPerson()-->
                                <li class="dropdown-item" style="color: #3cb7d6 !important; padding-left:0.75rem; cursor:pointer" data-bind="text: $data;"></li>
                                <!--/ko-->
                                <li style="padding-left:0.75rem; cursor:default; background-color: #e9ecef;opacity: 1;">Rechtsform</li>
                                <!--ko foreach: rachts()-->
                                <li class="dropdown-item" style="color: #3cb7d6 !important; padding-left:0.75rem; cursor:pointer" data-bind="text: $data;"></li>
                                <!--/ko-->
                            </ul>
                            
                        </div>
                    </div>
                    </div>
                    `
                    )
                }
            }
            else if ($(item).attr('type') == 'text') {
                if ($(item).parent().attr('type') == 'header') {
                    $(item).parent().find('.target').append(`<div style="${$(item).attr('hidden') ? 'display: none;"' : '"'} class="col-sm-${$(item).attr('col')} col-md-${$(item).attr('col')} col-lg-${$(item).attr('col')}">
                        ${this.inputFormField(item)}
                    </div>`
                    )
                }
            }
            else if ($(item).attr('type') == 'checkbox') {
                if ($(item).parent().attr('type') == 'header') {
                    $(item).parent().find('.target').append(`<div class="col-sm-${$(item).attr('col')} col-md-${$(item).attr('col')} col-lg-${$(item).attr('col')}">
                        ${this.checkboxFormField(item)}
                    </div>`
                    )
                }
            }
            else if ($(item).attr('type') == 'textarea') {
                if ($(item).parent().attr('type') == 'header') {
                    $(item).parent().find('.target').append(`<div class="col-sm-${$(item).attr('col')} col-md-${$(item).attr('col')} col-lg-${$(item).attr('col')}">
                        ${this.textareaFormField(item)}
                        </div>`
                    )
                }
            }
            else if ($(item).attr('type') == 'select') {
                const options = JSON.parse($(item).attr('options'))
                const id = $(item).parent().get(0).nodeName.toLowerCase() + $(item).attr('name')
                let domOptions = ''
                if ($(item).attr('name'))
                    for (let i = 0; i < options.length; i++) {
                        if (typeof options[i] === 'object') {
                            domOptions += `<option value="${options[i].label}" ${$(item).attr('value') == options[i].label ? ' selected=\"' + $(item).attr('value') + '\" ' : ''} >${options[i].label}</option>`
                        } else {
                            domOptions += `<option value="${options[i]}" ${$(item).attr('value') == options[i] ? ' selected=\"' + $(item).attr('value') + '\" ' : ''} >${options[i]}</option>`
                        }
                    }
                if ($(item).parent().attr('type') == 'header') {
                    $(item).parent().find('.target').append(`<div style="${$(item).attr('hidden') ? 'display: none;"' : '"'} class="col-sm-${$(item).attr('col')} col-md-${$(item).attr('col')} col-lg-${$(item).attr('col')}">
                        ${this.selectFormField(item, id, domOptions)}
                    </div>`)
                }
            }
            container.append($(item).get(0))
        })

        const dom = container.get(0)
        ko.applyBindings(this, dom);

        $('.newcase').append(dom)
        $(".dropdown-menu").each(function () {
            $(this).hide()
        });
    }

    selectOptionAWH = (type, data) => {
        let a = ko.toJS(this.briefkopf);
        for (let i = 0; i < a.length; i++) {
            if (data === a[i].NameID) {
                $("#" + type).val(data)
                $("#" + type).next().hide()
                $("#" + type).parents('.target').find('[id*=AnredeBriefkopf]').val(a[i].Briefanrede)
                this.showRF(false);
                break;
            }
        }
    }

    showRForm = ko.observable('')

    isEmpty(value) {
        return (value == 'undefined' || value == "" || value == null || value == false)
    }

    changeOptions(ref, event, parent) {
        if (!this.isEmpty(ref) && (event && event.target && !this.isEmpty(event.target.value))) {
            let domOptions = '<option></option>'
            let allOptions = JSON.parse($("[name=" + ref + "]").attr('options'))
            let options = allOptions[0][event.target.value]

            for (let i = 0; i < options.length; i++) {
                if (typeof options[i] === 'object') {
                    domOptions += `<option value="${options[i].label}">${options[i].label}</option>`
                } else {
                    domOptions += `<option value="${options[i]}">${options[i]}</option>`
                }
            }
            $("#" + parent + ref).find("option").remove()
            $("#" + parent + ref).append(domOptions)
        }
        else if ((event && event.target && !this.isEmpty(event.target.value))) {
            if ($(`.newcase [on='${event.target.value}']`).length) {
                const partialId = $(`.newcase [on='${event.target.value}']`).attr("name")
                $(`.newcase [id*='${partialId}']`).closest("[class*='col-m']").css('display', 'block')
            } else if ($(`.newcase option[value='${event.target.value}']`).parent().find('option').length) {
                const options = $(`.newcase option[value='${event.target.value}']`).parent().find('option')
                options.each(function () {
                    const value = $(this).val()
                    if ($(`.newcase [on='${value}']`).length) {
                        const partialId = $(`.newcase [on='${value}']`).attr("name")
                        $(`.newcase [id*='${partialId}']`).closest("[class*='col-m']").css('display', 'none')
                    }
                })
            }
        }
    }

    initialSetValue() {
        const refs = $('.newcase [ref]')
        refs.each((index, item) => {
            let domOptions = '<option></option>'
            let ref = $(item).attr('ref')
            let allOptions = JSON.parse($("[name=" + ref + "]").attr('options'))
            let nodeName = $(item).get(0).nodeName
            const referee = $('.newcase').find(`[id*=${this.handleNaming(nodeName)}]`)
            let options = allOptions[0][$(referee).val().toString()] || []
            let value = ''
            const d: Data = this.data()
            for (let key in d.caseData.ExtendedCase.Infos) {
                if (key.includes(ref)) {
                    value = d.caseData.ExtendedCase.Infos[key]
                }
            }

            for (let i = 0; i < options.length; i++) {
                if (typeof options[i] === 'object') {
                    domOptions += `<option value="${options[i].label}" ${value == options[i].label ? ' selected=\"' + options[i].label + '\" ' : ''}>${options[i].label}</option>`
                } else {
                    domOptions += `<option value="${options[i]}" ${value == options[i] ? ' selected=\"' + options[i] + '\" ' : ''}>${options[i]}</option>`
                }
            }
            $(`[id*=${ref}]`).find("option").remove()
            $(`[id*=${ref}]`).append(domOptions)
        })
    }

    showSuggestionsRFAWH = async (type) => {
        this.showRForm(type)
        $("#" + type).next().show()
        const self = this;
        $(".dropdown-item").each(function () {
            $(this).click(function () {
                self.selectOptionAWH(type, $(this).text())
            })
        })
        this.pickRechtsform();
    }

    async getTemplate(type) {
        const { Template } = (await RNSAPI.GetTemplate(type)).Payload

        if (Template) {
            this.parseTemplate(Template)
            this.fillAWHData()
            $("#Notiz_Sachverhalt").closest(".row").hide()
            $("#Notizen").closest(".row").hide()
        }
    }

    fillAWHData() {

        setTimeout(() => {
            const d: Data = this.data()
            for (let key in d.caseData.ExtendedCase.Infos) {
                $(".newcase input, .newcase textarea, .newcase select, .newcase select").each((index, item) => {
                    if ($(item).attr('id') == key) {
                        if ($(item).attr('type') == 'checkbox' && d.caseData.ExtendedCase.Infos[key]) {
                            $(item).prop('checked', d.caseData.ExtendedCase.Infos[key] == "false" ? false : true)
                        } else {
                            $(item).val(d.caseData.ExtendedCase.Infos[key])
                            if (d.caseData.ExtendedCase.Infos[key] && $(item).closest("[class*='col-m']").css('display') == 'none') {
                                $(item).closest("[class*='col-m']").css('display', 'block')
                            }
                        }
                    }
                })
            }
            this.initialSetValue()
        }, 5000)

    }



    async pickGVertreter(type: string) {
        let addresses = (await RNSAPI.getPersonsForScope(type)).Payload.Addresses;
        this.pickGeneric("Gesetzlicher Vertreter", ["FirstName", "Name1", "CityName"], ["Vorname", "Nachname", "Ort"], addresses);
        this.modalHandleSelection((selectedObject) => {
            Postbox.publisher().publish(selectedObject()["Keyword"], "ChooseGVertreter");
        });
        $('#ExtCasemodal').modal('show');
    }

    constructor(params) {
        this.loadReferate();
        this.loadClerks();
        this.hasDeadlineManagementRights(RolesAndRights.hasDeadlineManagementRights());

        $("html, body").animate({ scrollTop: 0 }, "fast");
        this.initialize(params.caseId, layoutAllgemein)

        this.caseEntries = ko.observableArray([]);
        this.isDocumentsEmpty = ko.computed({
            owner: this,
            read: () => {
                return this.caseEntries().length === 0;
            }
        });
        this.caseId = params.caseId;
        this.pickRechtsform();
        this.initializeAktenkonto(null);

        if (params.eCaseRecordId) this.currentId(params.eCaseRecordId);
        //this.loadEntries();

        //TODO: - Moved into dokumente component
        this.workflowChanged = (obj, event) => {

            if (event.originalEvent) { //user changed
                // console.log('original event: ', this.selectedWorkflowItem());
                if (this.selectedWorkflow().indexOf(this.selectedWorkflowItem()) === -1) {
                    this.selectedWorkflow.push(this.selectedWorkflowItem());
                }
                //console.log(this.selectedWorkflow());
            } else { // program changed
                console.log('original event 2: ', event);
            }
        }

        this.getAktenkontoByCaseID();
        this.loadPredefinedStamps();

        ko.bindingHandlers.bindHTML = {
            init: function () {
                return { controlsDescendantBindings: true };
            },
            update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
                var value = ko.utils.unwrapObservable(valueAccessor());
                ko.applyBindingsToNode(element, { html: value }, viewModel);
                ko.applyBindingsToDescendants(bindingContext, element);
            }
        }
        const cView = sessionStorage.getItem('currentView')
        if (cView) {
            this.currentView(cView);
        }


        Postbox.publisher().subscribe((inf) => {
            this.infoMessage(inf.infMessage);
            this.infoTitle(inf.infTitle);
            this.modialAutoHide(inf.infshow);
            this.showInfoMessage();
            setTimeout(() => {
                this.loadData(this.caseId);
            }, 3000)

        }, 'showInfoCase');

        Postbox.publisher().subscribe((type: string) => {
            this.pickGVertreter(type);
        }, 'showGV');
    }

    parseDate = (dateStr: string) => moment.utc(dateStr, "DD.MM.YYYY", true);

    newAkteFrist() {
        let obj = {
            SachbearbeiterId: ko.observable(RNSAPI.User().username),
            Sachbearbeiter: ko.observable(null),
            CaseId: ko.observable(null),
            Rubrum: ko.observable("Kein Rubrum vorhanden."),
            DeadlineNumber: ko.observable("-/-"),
            DeadlineText: ko.observable(null),
            PreDeadline: ko.observable(),
            StartDate: ko.observable(moment().format("YYYY-MM-DD")),
            TheDeadline: ko.observable()
        };
        //console.log(RNSAPI.getSachbearbeiter());
        return ko.observable(obj);
    };

    async chooseCourts() {
        this.popupOpen(true);
        this.courtsAddress2(ko.toJS(this.courtsAddress()));

        setTimeout(() => {
            $('#court').modal('show');
        }, 100)
    }

    akteFrist = this.newAkteFrist();

    createPersonObservable() {
        let obj = {

            FirstName: ko.observable(''),
            Address: ko.observable(''),
            Adress2: ko.observable(''),
            Adress2CityName: ko.observable(''),
            Adress2CountryID: ko.observable(''),
            Adress2PLZ: ko.observable(''),
            Adresszusatz: ko.observable(''),
            AnredeBriefkopf: ko.observable(''),
            AnredeDirekt: ko.observable(''),
            Arbeitgeber1: ko.observable(''),
            Arbeitgeber2: ko.observable(''),
            Arbeitgeber3: ko.observable(''),
            Bank: ko.observable(''),
            BankId: ko.observable(''),
            BirthDate: ko.observable(''),
            BirthName: ko.observable(''),
            CentralPhone: ko.observable(''),
            CityName: ko.observable(''),
            CountryID: ko.observable(''),
            CountryName: ko.observable(''),
            DebitorenKontoNummer: ko.observable(''),
            DisplayName: ko.observable(''),
            Ehepartner: ko.observable(''),
            EmailAddress: ko.observable(''),
            Fax: ko.observable(''),
            GesetzlicherVertreter1: ko.observable(''),
            GesetzlicherVertreter1Id: ko.observable(''),
            GesetzlicherVertreter2: ko.observable(''),
            GesetzlicherVertreter2Id: ko.observable(''),
            GesetzlicherVertreter3: ko.observable(''),
            GesetzlicherVertreter3Id: ko.observable(''),
            GesetzlicherVertreter4: ko.observable(''),
            GesetzlicherVertreter4Id: ko.observable(''),
            IBAN: ko.observable(''),
            Imex: ko.observable(''),
            KennzeichenMB: ko.observable(''),
            KontoNummer: ko.observable(''),
            KreditorenKontoNummer: ko.observable(''),
            MBType: ko.observable(''),
            MobilePhone: ko.observable('+49'),
            Name1: ko.observable(''),
            Name2: ko.observable(''),
            Name3: ko.observable(''),
            Note1: ko.observable(''),
            Note2: ko.observable(''),
            Note3: ko.observable(''),
            Note4: ko.observable(''),
            Note5: ko.observable(''),
            PLZPostfach: ko.observable(''),
            PersonalausweisNr: ko.observable(''),
            Phone1: ko.observable('+49'),
            Phone2: ko.observable(''),
            Phone3: ko.observable(''),
            Postfach: ko.observable(''),
            Rechtsform: ko.observable(''),
            Sonstige1: ko.observable(''),
            Sonstige2: ko.observable(''),
            SpezielleAnrede2: ko.observable(''),
            URL: ko.observable(''),
            ZipCode: ko.observable(''),
            Keyword: ko.observable(''),
            RSVersId: ko.observable(''),
            RSVersNr: ko.observable(''),
            RSSelbstbehalt: ko.observable(''),
            //edit: ko.observable(true),
            // IsSelected: ko.observable(false)
        };
        //let x=this.createPersonObservable();
        /*         obj["Keyword"] = ko.computed(() =>
                    (obj.Name1() || '') + (obj.FirstName() || '')); */
        //obj.Keyword = ko.computed(() => obj.DisplayName().replace(/\s/g, "") + obj.FirstName().replace(/\s/g, ""));

        return ko.observable(obj);
    };

    ad = this.createPersonObservable();

    extendedObj() {
        let rObj = {
            AktenZeichenGericht1: ko.observable(""),
            AktenZeichenGericht2: ko.observable(""),
            AktenZeichenGericht3: ko.observable(""),
            IsAuslaendischesMandat: ko.observable(""),
            IsEigeneBeitreibung: ko.observable(""),
            IsMandantVorzugsteuerabzugberechtigt: ko.observable(""),
            //MahnNr: ko.observable(""),
            AblageDatum: ko.observable(""),
            AblageNr: ko.observable(""),
            Ablagekennzeichen: ko.observable(""),
            Druckkennung: ko.observable(""),
            Kostentraeger: ko.observable(""),
            AdressTyp: ko.observable(""),
            AngelegtVon: ko.observable(""),
            Case_ID: ko.observable(""),
            Comment: ko.observable(""),
            Copies: ko.observable(""),
            MahnDatum: ko.computed(() => {
                return this.deadlineDate1();
            }),
            MahnNr: ko.computed(() => {
                return this.Mahnstufe();
            }),
            EDAVerfahren: ko.observable(false),
            ErledigtDatum: ko.observable(null),
            ErledigtVon: ko.observable(""),
            ForderungskontoDrucken: ko.observable(false),
            Formulardruk: ko.observable(false),
            HonorarForderung: ko.observable(false),
            Keyword: ko.observable(""),
            PapierArtID: ko.observable(""),
            Quotelung: ko.observable(0),
            Standardtext: ko.observable(""),
            //SachbearbeiterId: ko.observable(RNSAPI.User().username),
        };
        return ko.observable(rObj);
    };

    aExtended = this.extendedObj();

    infoModel() {
        let obj = {
            E_PostJa: ko.observable("false"),
            E_PostNein: ko.observable("false"),
            Fallkonstellation: ko.observable(""),
            MEK: ko.observable(""),
            MandatSonstiges: ko.observable(""),
            Notiz_Mandantenbegehren: ko.observable(""),
            Notiz_Sachverhalt: ko.observable(""),
            Notizen: ko.observable(""),
            mandat: ko.observable("")
        }
        return ko.observable(obj);
    };

    infData = this.infoModel();

    newAkteResubmission() {
        let rObj = {
            AdressTyp: ko.observable(""),
            AngelegtVon: ko.observable(""),
            //AnlageDatum: ko.computed(() => this.parseDate(this.creationDate()).toISOString()),
            AnlageDatum: ko.observable(""),
            Case_ID: ko.observable(""),
            Comment: ko.observable(""),
            Copies: ko.observable(""),
            DeadLineDate: ko.computed(() => {
                return this.deadlineDate();
            }),
            DeadLineNr: ko.computed(() => {
                return this.kurzel();
            }),
            DeadLineReason: ko.computed(() => {
                return this.grund();
            }),
            EDAVerfahren: ko.observable(false),
            ErledigtDatum: ko.observable(null),
            ErledigtVon: ko.observable(""),
            ForderungskontoDrucken: ko.observable(false),
            Formulardruk: ko.observable(false),
            HonorarForderung: ko.observable(false),
            Keyword: ko.observable(""),
            PapierArtID: ko.observable(""),
            Quotelung: ko.observable(0),
            Referat: ko.observable(""),
            Sachbearbeiter: ko.observable(RNSAPI.User() ? RNSAPI.User().username : "GR"),
            Standardtext: ko.observable(""),
            Status: ko.observable("N"),
            Sachstand: ko.observable("")
            //SachbearbeiterId: ko.observable(RNSAPI.User().username),
        };
        return ko.observable(rObj);
    };

    akteResubmission = this.newAkteResubmission();

    async postNewResumbission() {
        this.akteResubmission().Case_ID(this.data().caseId);
        let resubmissionObj = ko.toJS(this.akteResubmission());
        let obj = ko.toJS(this.akte);
        //resubmissionObj.AnlageDatum = moment(obj.AnlageDatum).format("DD.MM.YYYY");
        resubmissionObj.AnlageDatum = moment(moment.now()).format("DD.MM.YYYY");
        resubmissionObj.DeadLineDate = moment($('#deadlineDate').val(), "YYYY-MM-DD").toISOString();
        resubmissionObj.Sachbearbeiter = $('#SachbearbeiterId').val().toString();
        // TODO: Check me please
        // resubmissionObj.Referat = await this.getReferateCode(obj.Referat, 'Id') || obj.Referat;
        resubmissionObj.Referat = '';
        resubmissionObj.Sachstand = obj["Sachstand"];
        resubmissionObj.DeadLineNr = obj["FristNr"];


        try {
            let result = await RNSAPI.createResubmission(resubmissionObj);

            if (result.Type === "CreateSuccess") {
                RNSAPI.createHistoryEntry(resubmissionObj.Case_ID, "Wiedervorlage angelegt");
            } else {
                alert("Fehler beim Anlegen der Wiedervorlage.");
            }
        } catch (e) {
            console.log(e);
        }
    }

    async showAssignModal(PersonTypeNr: Number) {
        let PersonTypeStr = "";
        if (PersonTypeNr === 1)
            PersonTypeStr = "mandant";
        else if (PersonTypeNr === 2)
            PersonTypeStr = "gegner";
        else if (PersonTypeNr === 3)
            PersonTypeStr = "sonstige";

        RNSAPI.unjoinPerson(ko.toJS(this.TheCurrentKeyword), this.data().caseId, PersonTypeStr);
        this.loadData(this.data().caseId);
    }

    data: ko.Observable<Data | undefined> = ko.observable<Data | undefined>(undefined);

    async initialize(CaseId: string, layout: any, layoutName?: string) {
        await this.getData(CaseId, layout, layoutName).then(data => {
            //console.log(data)
            this.data(data)
            if (this.data().caseData.ExtendedCase.Infos.CaseType) {
                this.getTemplate(this.data().caseData.ExtendedCase.Infos.CaseType)
            }
        });
    }

    switchTab(s: string): void {
        this.data().currentTab(s);
    }

    redirectTab() {
        //console.log(this.data().caseId);
        let x = this.data().caseId;
        MainViewModel.RoutingTable.showCaseEntriesView({ caseId: x });
    }

    saveExtendedModal() {
        let d: Data = this.data();
        let obj = ko.toJS(this.aExtended());

        updateDataModel(d.extended, d.caseData, d.caseId);
        //d.caseData.Akte= {...ko.toJS(obj) , ...ko.toJS(this.akte)};
        d.caseData.Akte = ko.toJS(this.akte);
        d.caseData.Akte.IsAuslaendischesMandat = Number(ko.toJS(this.aExtended().IsAuslaendischesMandat)) ? true : false;
        d.caseData.Akte.IsEigeneBeitreibung = Number(ko.toJS(this.aExtended().IsEigeneBeitreibung)) ? true : false;
        d.caseData.Akte.IsMandantVorzugsteuerabzugberechtigt = Number(ko.toJS(this.aExtended().IsMandantVorzugsteuerabzugberechtigt)) ? true : false;
        d.caseData.Akte.AnlageDatum = moment(d.caseData.Akte.AnlageDatum).format("DD.MM.YYYY");
        d.caseData.Akte.FristDatum = moment($("#deadlineDate").val()).format("DD.MM.YYYY");
        d.caseData.Akte.MahnDatum = moment($("#deadlineDate1").val()).format("DD.MM.YYYY");
        d.caseData.Akte.Gericht1Id = $("#courtId").val();
        //TODO: Save selected court here saveExtendedMod


        //delete copy.Akte.DeadLineNr1;
        //delete copy.Akte.FristNr1;
        //delete copy.Akte.DeadLineNr1;


        RNSAPI.saveExtendedCase(d.caseData)
            .then(res => {
                if (res.Type !== "CaseUpdateSuccessful") {
                    window.alert("An error has occured while saving the case");
                }
                else {
                    window.alert("saved");
                    $('#detailsModal').modal('hide');
                    //MainViewModel.RoutingTable.showNewView({ caseId: d.caseId });
                    //this.postNewResumbission();
                    //RNSAPI.createResubmission(d.caseData);
                }
            })
            .catch(() => { window.alert("An error has occured while saving the case"); });

    }

    async UpdateAdress(data) {

        let d = ko.toJS(data.data);
        data.ad().Keyword = this.TheCurrentKeyword;
        data.ad().Rechtsform = this.ad().Address;
        data.ad().FirstName = this.ad().FirstName;
        data.ad().Name1 = this.ad().DisplayName;
        data.ad().Address = this.ad().Adress2;
        data.ad().ZipCode = this.ad().ZipCode;
        data.ad().CityName = this.ad().CityName;
        data.ad().Phone1 = this.ad().Phone1;
        data.ad().EmailAddress = this.ad().EmailAddress;
        data.ad().CountryName = this.ad().CountryName;
        data.ad().MobilePhone = this.ad().MobilePhone;
        data.ad().Fax = this.ad().Fax;
        data.ad().URL = this.ad().URL;
        //data.ad().Rechtsform = ko.toJS(this.ad().Rechtsform);
        console.log(data.ad())
        if (this.mandantIndexObj().type === "mandant") {
            data.ad().Rechtsform($("#mandantRechtsform1").val().toString());
        }
        else if (this.mandantIndexObj().type === "gegner") {
            data.ad().Rechtsform($("#gegnerRechtsform").val().toString());
        }
        else if (this.mandantIndexObj().type === "sonstige" || this.mandantIndexObj().type === "weitere") {
            data.ad().Rechtsform($("#sonstigeRechtsform").val().toString());
        }
        console.log(data.ad())
        //console.log(this.mandantIndexObj().rootIndex + " " + this.mandantIndexObj().type);

        //data.ad().Rechtsform = $("#Rechtsform").val().toString();
        data.ad().Adresszusatz = this.ad().Adresszusatz;
        data.ad().AnredeBriefkopf = this.ad().AnredeBriefkopf;
        data.ad().AnredeDirekt = this.ad().AnredeDirekt;
        data.ad().BirthDate = this.ad().BirthDate;
        data.ad().BirthName = this.ad().BirthName;

        d.caseData.Addresses[this.mandantIndexObj().rootIndex][this.mandantIndexObj().index] = ko.toJS(data.ad);

        //if (this.mandantIndexObj().type == "mandant") {
        //    //data.ad().RSSelbstbehlalt = ko.toJS($("#excess").val());
        //}
        if (!Utils.checkErrors(["Keyword", "FirstName", "DisplayName", "Rechtsform", "Adress2", "ZipCode", "CityName"], data.ad(), this.mandantIndexObj().type, [Utils.checkString])) return;

        this.save(this.data)


        let result = await RNSAPI.updatePerson(ko.toJS(d.caseData.Addresses[this.mandantIndexObj().rootIndex][this.mandantIndexObj().index]), this.mandantIndexObj().type);

        //window.location.reload();
    }

    async saveMandate(data) {
        //console.log("HIer:");
        let d = ko.toJS(data.data);
        //console.log(d);
        if (this.mandantIndexObj().type === 'gegner') {
            d.caseData.Addresses[this.mandantIndexObj().rootIndex][this.mandantIndexObj().index] = ko.toJS(data.ad);
            d.caseData.Addresses[this.mandantIndexObj().rootIndex][this.mandantIndexObj().index].BirthDate = moment(d.caseData.Addresses[this.mandantIndexObj().rootIndex][this.mandantIndexObj().index].BirthDate).format("DD.MM.YYYY");
        } else {
            d.caseData.Addresses[this.mandantIndexObj().rootIndex][this.mandantIndexObj().index] = ko.toJS(data.ad);
            d.caseData.Addresses[this.mandantIndexObj().rootIndex][this.mandantIndexObj().index].BirthDate = moment(d.caseData.Addresses[this.mandantIndexObj().rootIndex][this.mandantIndexObj().index].BirthDate).format("DD.MM.YYYY");
        }

        updateDataModel(this.data().extended, this.data().caseData, this.data().caseId);

        try {
            if (this.mandantIndexObj().type == "mandant") {
                let result = await RNSAPI.updatePerson(ko.toJS(data.ad), "Mandant");
            }
            else {
                let result = await RNSAPI.updatePerson(ko.toJS(data.ad), "Gegner");
            }
        }
        catch (e) {
            console.log(" Not successed some error occured");
        }

        RNSAPI.saveExtendedCase(d.caseData)
            .then(res => {
                if (res.Type !== "CaseUpdateSuccessful") {
                    window.alert("An error has occured while saving the case");
                }
                else {
                    //MainViewModel.RoutingTable.showNewView({ caseId: d.caseId });
                    // this.postNewResumbission();
                    // RNSAPI.createResubmission(d.caseData);
                }
            })
            .catch(() => { window.alert("An error has occured while saving the case"); });

    }

    saveInfo() {
        let d: Data = this.data();
        updateDataModel(d.extended, d.caseData, d.caseId);
        let obj = ko.toJS(this.info());
        d.caseData.ExtendedCase.Infos = ko.toJS(this.info());

        RNSAPI.saveExtendedCase(d.caseData)
            .then(res => {
                if (res.Type !== "CaseUpdateSuccessful") {
                    window.alert("An error has occured while saving the case");
                }
                else {
                    alert("value updated successfully");
                    // this.postNewResumbission();
                    // RNSAPI.createResubmission(d.caseData);
                }
            })
            .catch(() => { window.alert("An error has occured while saving the case"); });

    }

    async getReferateCode(value, type) {
        const key = type === 'Name' ? 'Id' : 'Name'
        let referate = (await RNSAPI.getReferate()).Payload.Units;
        const found = referate.filter(item => item[key] === value)[0]
        if (found !== undefined)
            return found[type]
        else
            return "";
    }

    caseErrorMessage = ko.observable<string>('');

    async save(data) {
        //console.log('from save')
        if (!Utils.checkMultipleErrors(["Rubrum", "Referat", "SachbearbeiterId"], this.akte(), "", [Utils.checkString])) return;
        if (this.akte().Sachstand() && this.akte().Sachstand().length > 8) {
            $('#sachstand').parent().addClass("has-error");
            return;
        }

        if(!ko.toJS(this.allReferate()).find(a => a.Name === this.akte().Referat())) {
            this.caseErrorMessage('Das eingegebene Referat existiert nicht!');
            return;
        }

        if(!ko.toJS(this.allClerks()).find(x => x.Sachbearbeiter_ID === this.akte().SachbearbeiterId())) {
            this.caseErrorMessage("Der eingegebene Sachbearbeiter existiert nicht!");
            return;
        }
        


        let d: Data = this.data();
        updateDataModel(d.extended, d.caseData, d.caseId);

        let obj = this.akte();
        d.caseData.Akte.Rubrum = obj["Rubrum"];
        d.caseData.Akte.Wegen = obj["Wegen"];
        d.caseData.Akte.Referat = await this.getReferateCode(ko.toJS(obj["Referat"]), 'Id') || obj["Referat"];
        d.caseData.Akte.SachbearbeiterId = $("#SachbearbeiterId").val().toString();
        d.caseData.Akte.AnlageDatum = moment(ko.toJS(obj["AnlageDatum"])).format("DD.MM.YYYY");
        if (ko.toJS(d.caseData.Akte.AnlageDatum) === "Invalid date") {
            d.caseData.Akte.AnlageDatum = null;
        }
        d.caseData.Akte.Ortskennung = $("#aufbewahrungsort").val() ? $("#aufbewahrungsort").val().toString() : '';

        d.caseData.Akte.FristNr = obj["FristNr"];
        if ($("#deadlineDate").val() != "") {
            d.caseData.Akte.FristDatum = moment($("#deadlineDate").val()).format("DD.MM.YYYY");
            console.log(d.caseData.Akte.FristDatum)
        }
        d.caseData.Akte.Sachstand = obj["Sachstand"];

        d.caseData.Akte.MandantRechtschutzSchadennummer = obj["MandantRechtschutzSchadennummer"];

        d.caseData.Akte.Gericht1Id = this.akte().Gericht1Id;
        d.caseData.Akte.Gericht2Id = this.akte().Gericht2Id;
        d.caseData.Akte.Gericht3Id = this.akte().Gericht3Id;

        d.caseData.Akte.AktenZeichenGericht1 = $("#idAZGericht1").val();
        d.caseData.Akte.AktenZeichenGericht2 = $("#idAZGericht2").val();
        d.caseData.Akte.AktenZeichenGericht3 = $("#idAZGericht3").val();

        d.caseData.Akte.WertGericht1 = $("#idGSW1").val().toString().replace(/\./g, "").replace(/,/g, ".")
        d.caseData.Akte.WertGericht2 = $("#idGSW2").val().toString().replace(/\./g, "").replace(/,/g, ".")
        d.caseData.Akte.WertGericht3 = $("#idGSW3").val().toString().replace(/\./g, "").replace(/,/g, ".")

        d.caseData.Akte.MandantCaseID = obj["MandantCaseID"];
        d.caseData.Akte.GegnerCaseID = obj["GegnerCaseID"];
        d.caseData.Akte.Stundensatz = obj["Stundensatz"];

        d.caseData.Akte.MandantHaftpflichtversicherungId = obj["MandantHaftpflichtversicherungId"];
        d.caseData.Akte.MandantHaftplichtVersicherungsnummer = obj["MandantHaftplichtVersicherungsnummer"];
        d.caseData.Akte.MandantHaftpflichtSchadennummer = obj["MandantHaftpflichtSchadennummer"];
        d.caseData.Akte.GegnerHaftpflichtversicherungId = obj["GegnerHaftpflichtversicherungId"];
        d.caseData.Akte.GegnerHaftpflichtVersicherungsnummer = obj["GegnerHaftpflichtVersicherungsnummer"];
        d.caseData.Akte.GegnerHaftpflichtSchadensnummer = obj["GegnerHaftpflichtSchadensnummer"];

        d.caseData.Akte.AuthorityId = obj["AuthorityId"];
        d.caseData.Akte.AdministrativeAuthorityCaseID = obj["AdministrativeAuthorityCaseID"];
        d.caseData.Akte.StaatsanwaltCaseID = obj["StaatsanwaltCaseID"];
        d.caseData.Akte.PolizeiCaseID = obj["PolizeiCaseID"];

        d.caseData.Akte.GegenAnwaltId = obj["GegenAnwaltId"];
        d.caseData.Akte.GegenAnwaltCaseID = obj["GegenAnwaltCaseID"];
        d.caseData.Akte.KorrAnwaltID = obj["KorrAnwaltID"];
        d.caseData.Akte.KorrAnwaltCaseID = obj["KorrAnwaltCaseID"];

        d.caseData.Akte.IsAuslaendischesMandat = Number(ko.toJS(obj["IsAuslaendischesMandat"])) ? true : false;
        d.caseData.Akte.IsEigeneBeitreibung = Number(ko.toJS(obj["IsEigeneBeitreibung"])) ? true : false;
        d.caseData.Akte.IsMandantVorzugsteuerabzugberechtigt = Number(ko.toJS(obj["IsMandantVorzugsteuerabzugberechtigt"])) ? true : false;

        d.caseData.Akte.MahnNr = obj["MahnNr"];
        d.caseData.Akte.MahnDatum = $("#deadlineDate1").val();

        if (ko.toJS(obj["AblageDatum"]) === "Invalid date") {
            obj["AblageDatum"] = null;
        }
        d.caseData.Akte.AblageDatum = obj["AblageDatum"];

        d.caseData.Akte.AblageNr = obj["AblageNr"];
        d.caseData.Akte.Ablagekennzeichen = obj["Ablagekennzeichen"];

        d.caseData.ExtendedCase.Infos = ko.toJS(this.info());

        $(".newcase input, .newcase select, .newcase textarea").each(function () {
            d.caseData.ExtendedCase.Infos[$(this).attr('id')] = $(this).attr('type') === 'checkbox' ? $(this).is(':checked') : $(this).val()
        })

        try {
            const res = await RNSAPI.saveExtendedCase(d.caseData);

            if (res.Type !== "CaseUpdateSuccessful") {
                console.log('extended case updated succ')
                this.infoTitle('Fehler')
                this.infoMessage('');
                this.infoMessage('Datensatz konnte nicht erfolgreich gespeichert werden.');
            } else {
                this.infoTitle('');
                this.infoTitle('Erledigt')
                this.infoMessage('');
                this.infoMessage('Daten erfolgreich erfasst.');
                this.modialAutoHide(true)
                this.loadData(this.caseId);
            }

        } catch (e) {
            let error = Utils.handleError(e);
            this.infoMessage('');
            this.infoMessage(error['code'] + ': ' + error['message']);
            // window.alert("An error has occured while saving the case");
        }

    }

    async saveAndRedirect(data) {
        this.save(data);
        // this.router.navigate('/cases');
    }

    async getData(caseId: string, layout: any, layoutName?: string): Promise<Data> {
        this.akteResubmission().Case_ID(caseId);

        try {
            let type = localStorage.getItem('caseEditView')
            if (type === 'TextModalOpen') {
                this.openCreateTextModal(true);
                this.showDocuments();
            }
            if (type === 'FolderOpen') {
                this.openCreateTextModal(false);
                this.showDocuments();
            }
            localStorage.removeItem('caseEditView') //MARK: - For brevity.
        } catch (e) {
            //Silence is golden
        }

        const caseData: any = (await RNSAPI.getExtendedCase(caseId)).Payload;
        const resubmissionData: any = (await RNSAPI.getResubmissionByCaseId(caseId)).Payload.Resubmissions;

        let infd = {};
        for (let key in caseData.ExtendedCase.Infos) {
            infd[key] = ko.observable(caseData.ExtendedCase.Infos[key]);
        }

        this.info(infd);

        // const cases: any = (await RNSAPI.getCaseByCaseId(caseId)).Payload.Cases;

        var x = ko.toJS(this.mandantIndex);

        this.caseData = caseData;
        this.caseAddresses = caseData.Addresses;
        this.caseAddresses3(caseData.Addresses[0]);

        this.defendantsInCase(caseData.Addresses[1]);

        let books = [{
            AddressTyp: "Mandant",
            AuslagenSteuerfrei: "",
            AuslagenSteuerpflichtig: "",
            Belegdatum: "24.03.2019",
            Beteiligte: "",
            BezahlterBetrag: "40",
            Buchungstext: "Hello world",
            Buchungstype: "Sonstige buchung aus buchhaltung",
            Eingang: "Ausgang",
            Fremdgeld: "",
            Gegenkonto: "",
            Honorar: "1000",
            Nettoforderung: "20",
            RechnungsNr: "10002",
            Restverteilung: "20",
            Steueranteil: "10",
            Umsatz: "1000",
            Umsatzsteuer: 19
        }, {
            AddressTyp: "Mandant",
            AuslagenSteuerfrei: "",
            AuslagenSteuerpflichtig: "",
            Belegdatum: "24.03.2019",
            Beteiligte: "",
            BezahlterBetrag: "40",
            Buchungstext: "Hello world 2",
            Buchungstype: "Sonstige buchung aus buchhaltung",
            Eingang: "Eingang",
            Fremdgeld: "",
            Gegenkonto: "",
            Honorar: "1000",
            Nettoforderung: "20",
            RechnungsNr: "10002",
            Restverteilung: "20",
            Steueranteil: "10",
            Umsatz: "1000",
            Umsatzsteuer: 19
        }]
        this.showTable(
            ['Eingang', 'Belegdatum', 'RechnungsNr', 'BezahlterBetrag', 'Buchungstext', 'Honorar', 'AuslagenSteuerpflichtig', 'AuslagenSteuerfrei', 'Fremdgeld', 'Umsatzsteuer'],
            ['status', 'Datum', 'RechnungsNr', 'Betrag', 'BUCHUNGSTEXT', 'KTO', 'Steuerpel', 'Steuerer', 'Fremdgeld', 'MWST', 'Aktionen'], books);

        // this.info(caseData.ExtendedCase.Infos);
        this.extendedInfo = caseData;
        setTimeout(() => {
            //this.changeButtonText(this.caseAddresses[0][0], 0, 'mandant', 0)
            this.changePerson(this.caseAddresses[0][0], 0, 'mandant', 0)
        }, 1000);
        var obj = {};

        for (let key in caseData.Akte) {
            obj[key] = ko.observable(caseData.Akte[key]);
        }

        if (obj['Referat']()) {
            const value = await this.getReferateCode(obj['Referat'](), 'Name')
            if (value) {
                obj['Referat'](value)
            }
        }
        this.akte(obj);
        this.akte().WertGericht1 = Utils.formatCurrency(caseData.Akte.WertGericht1 || '0,00');
        this.akte().WertGericht2 = Utils.formatCurrency(caseData.Akte.WertGericht2 || '0,00');
        this.akte().WertGericht3 = Utils.formatCurrency(caseData.Akte.WertGericht3 || '0,00');

        var ad = {};
        for (let key in caseData.Addresses[0]) {
            // this.myObservableArray2()[x]= ko.observable(caseData.Addresses[0][0]).toISOString();
            ad[key] = ko.observable(caseData.Addresses[key]);
        }

        this.selectFirstCaseData();

        let reasons = (await RNSAPI.getResubmissionReasons()).Payload.Reasons;
        for (let reason in reasons) {
            if (reasons[reason]['DeadLineNr'] == this.akte().FristNr()) {
                this.grund(reasons[reason]['DeadLineName']);
            }
        }
        for (let reason in reasons) {
            if (reasons[reason]['DeadLineNr'] == this.akte().MahnNr()) {
                this.Mahnstufe(reasons[reason]['DeadLineName']);
            }
        }

        this.kurzel(caseData.Akte.FristNr);
        this.kurzel1(caseData.Akte.MahnNr);
        //TODO: Please check me out ok. Important!
        this.DeadLineNr('m123');
        this.MahnNr('m123');

        this.akte().AnlageDatum = ko.observable(moment(caseData.Akte.AnlageDatum).format("YYYY-MM-DD"));
        this.akte().AblageDatum = ko.observable(moment(caseData.Akte.AblageDatum).format("YYYY-MM-DD"));
        this.akte().deadlineDate = ko.observable(moment(caseData.Akte.FristDatum).format("YYYY-MM-DD"));
        this.akte().deadlineDate1 = ko.observable(moment(caseData.Akte.MahnDatum).format("YYYY-MM-DD"));

        const extended: model.Extended = extendedFromLayout(layout, caseData);
        const rechtsgebiet: ko.Observable<string> = ko.observable(layoutName || "Allgemein");
        rechtsgebiet.subscribe(s => {
            this.initialize(caseId, getLayout(s), s);
            this.bereiche(getLayout(s).Sachverhalt.map(sa => sa.Name));
        });
        const bereich: ko.Observable<string> = ko.observable(this.bereiche()[0]);

        fillData(extended, caseData);

        return {
            caseId: caseId,
            layout: layout,
            caseData: caseData,
            extended: extended,
            selectedRechtsGebiet: rechtsgebiet,
            selectedBereich: bereich,
            currentTab: ko.observable("IdentifikationsTab"),
        };
    }

    async pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    };

    pickFrom = async () => {
        let inboxes = (await RNSAPI.getAllInboxes()).Payload.Accounts;
        this.pickGeneric("E-Mail-Adressen", ['EMail_Address'], ['E-Mail'], inboxes);
        this.modalHandleSelection((selectedObject) => {
            //console.log('selectedObject() ', selectedObject())
            this.from(selectedObject())
        });
        $('#ExtCasemodal').modal('show');
    };

    from = ko.observable({ EMail_Address: "" } as any);

    allReferate = ko.observableArray([]);

    private async loadReferate() {
        this.allReferate((await RNSAPI.getReferate()).Payload.Units);
    }

    pickReferat = async () => {
        this.caseErrorMessage('');
        await this.loadReferate();
        const referate = ko.toJS(this.allReferate());
        this.pickGeneric("Referat", ["Name", "Id"], ["Name", "Id"], referate);
        this.modalHandleSelection((selectedObject) => this.akte().Referat(selectedObject()["Name"]));
        $('#ExtCasemodal').modal('show');
    };

    closeDocView() {
        console.log("Test of Collapsing modal")
        $('#DocumentViewerModal').modal();
    };

    async pickDeadlineNumber() {
        let reasons = (await RNSAPI.getDeadlineReasons()).Payload.Reasons;
        this.pickGeneric("Grund", ["DeadlineDescription"], ["Beschreibung"], reasons);
        this.modalHandleSelection((selectedObject) => {
            this.grund(selectedObject()["DeadlineDescription"]);

        });
        $('#ExtCasemodal').modal('show');
    };

    async pickDeadlineNumberResubmission() {
        let reasons = (await RNSAPI.getResubmissionReasons()).Payload.Reasons;
        // console.log(reasons);
        // console.log('Reasons');
        // console.log(reasons);
        this.pickGeneric("Grund", ["DeadLineName"], ["Beschreibung"], reasons);

        this.modalHandleSelection((selectedObject) => {

            // this.akteFrist().DeadlineNumber(selectedObject().DeadLineElapseTime);
            this.akte().FristNr(selectedObject().DeadLineNr);
            this.grund(selectedObject().DeadLineName);
            this.DeadLineNr(selectedObject().DeadLineElapseTime);
            // this.updateDeadlineDate(ko.toJS(selectedObject().DeadLineNr));
        });
        $('#ExtCasemodal').modal('show');
    };

    async pickDeadlineNumberResubmission1() {
        let reasons = (await RNSAPI.getResubmissionReasons()).Payload.Reasons;

        this.pickGeneric("Grund", ["DeadLineName"], ["Beschreibung"], reasons);

        this.modalHandleSelection((selectedObject) => {
            // console.log('Now selected');
            //this.akteFrist().DeadlineNumber(selectedObject().DeadLineElapseTime);

            this.akte().MahnNr(selectedObject().DeadLineNr);
            this.Mahnstufe(selectedObject().DeadLineName);
            this.MahnNr(selectedObject().DeadLineElapseTime);

            //this.updateDeadlineDate(ko.toJS(selectedObject().DeadLineNr));
        });
        $('#ExtCasemodal').modal('show');
    };

    selectOption = (data) => {
        let a = ko.toJS(this.briefkopf);
        for (let i = 0; i < a.length; i++) {
            //if (ko.toJS(this.ad().Adresszusatz) == a[i].NameID) {
            //    this.ad().AnredeBriefkopf(a[i].Briefanrede);
            //    this.ad().AnredeDirekt(a[i].Anrede);
            //}
            console.log(a[i].NameID)
            if (data === a[i].NameID) {
                let test = ko.observable(a[i].NameID);
                //this.ad().Rechtsform = test; //(a[i].NameID)
                this.currentAnrede(a[i].NameID);
                this.currentAnredeGegner(a[i].NameID);
                this.currentAnredeSonsti(a[i].NameID);
                this.ad().AnredeBriefkopf(a[i].Briefanrede);
                this.ad().AnredeDirekt(a[i].Anrede);
                this.showRF(false);
                break;
            }
        }
    }

    pickRechtsform = async () => {
        let rechtsformen = (await RNSAPI.getRechtsformen()).Payload.LegalForms;
        let x = [];
        let y = [];
        let z = [];
        let a = [];
        let b = [];
        let c = [];
        for (let i = 0; i < rechtsformen.length; i++) {
            if (rechtsformen[i].NameID != "") {
                if (rechtsformen[i].NameID === "Herr" || rechtsformen[i].NameID === "Frau" || rechtsformen[i].NameID === "Divers") {
                    //console.log(rechtsformen[i].NameID);
                    a.push(rechtsformen[i].NameID);
                    b.push(rechtsformen[i].Briefanrede);
                    c.push(rechtsformen[i].Anrede);
                }
                else {
                    x.push(rechtsformen[i].NameID);
                    y.push(rechtsformen[i].Briefanrede)
                    z.push(rechtsformen[i].Anrede)
                }
            }

        }
        this.rachts(x);
        this.rachtsPerson(a);
        this.briefkopf(rechtsformen);
    };

    showSuggestionsRF = async () => {
        this.showRF(true);
    }

    pickRechtsformG = async () => {
        let rechtsformen = (await RNSAPI.getRechtsformen()).Payload.LegalForms;
        this.pickGeneric("Rechtsform", ["NameID", "Briefanrede"], ["Name", "Anrede"], rechtsformen);
        this.modalHandleSelection((selectedObject) => {
            this.ad().Rechtsform(ko.toJS(selectedObject()["NameID"].toString()));
            //console.log(ko.toJS(this.ad().Rechtsform));
            $("#RechtsformG").val(selectedObject()["NameID"]);
            //$("#AnredeBriefkopf").val(selectedObject()["Briefanrede"]);
            //$("#AnredeDirekt").val(selectedObject()["Anrede"]);
            this.ad().AnredeBriefkopf(selectedObject()["Briefanrede"]);
            this.ad().AnredeDirekt(selectedObject()["Anrede"]);
        });

        $('#ExtCasemodal').modal('show');
    };

    pickRechtsformS = async () => {
        let rechtsformen = (await RNSAPI.getRechtsformen()).Payload.LegalForms;
        this.pickGeneric("Rechtsform", ["NameID", "Briefanrede"], ["Name", "Anrede"], rechtsformen);
        this.modalHandleSelection((selectedObject) => {
            this.ad().Rechtsform(ko.toJS(selectedObject()["NameID"].toString()));
            //console.log(ko.toJS(this.ad().Rechtsform));
            $("#RechtsformS").val(selectedObject()["NameID"]);
            //$("#AnredeBriefkopf").val(selectedObject()["Briefanrede"]);
            //$("#AnredeDirekt").val(selectedObject()["Anrede"]);
            this.ad().AnredeBriefkopf(selectedObject()["Briefanrede"]);
            this.ad().AnredeDirekt(selectedObject()["Anrede"]);
        });

        $('#ExtCasemodal').modal('show');
    };

    allClerks = ko.observableArray([]);

    private async loadClerks() {
        this.allClerks((
            await RNSAPI.getSachbearbeiter()
        ).Payload.Clerks.filter((s) => s.Sachbearbeiter_ID.trim() !== ""));
    }

    pickSachbearbeiter = async () => {
        this.caseErrorMessage('');
        await this.loadClerks();
        let sachbearbeiter = ko.toJS(this.allClerks());
        this.pickGeneric("Sachbearbeiter", ["Sachbearbeiter", ""], ["Sachbearbeiter", ""], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => this.akte().SachbearbeiterId(selectedObject()["Sachbearbeiter_ID"]));
        $('#ExtCasemodal').modal('show');
    };

    pickCourt = async () => {
        var selt = this;

        this.getAddresses('Gericht');

        //selt.Name1 = ko.observable(null);
        //selt.CityName = ko.observable(null);
        //selt.Keyword=ko.observable(null);

        $('#court').modal('show');
    };

    async pickResubmissionReason() {
        let reasons = (await RNSAPI.getResubmissionReasons()).Payload.Reasons;

        this.pickGeneric("Grund", ["DeadLineName"], ["Beschreibung"], reasons);
        this.modalHandleSelection((selectedObject) => {
            // console.log('Now selected');
            // console.log(ko.toJS(selectedObject));
            // this.akteFrist().DeadlineNumber(selectedObject().DeadLineElapseTime);
            //this.updateDeadlineDate(ko.toJS(selectedObject().DeadLineNr));

        });
        $('#ExtCasemodal').modal('show');
    };

    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalHandleSelection = ko.observable();

    isEditMode = ko.observable(false);

    openAktenkontoForEdit = (data) => {
        //console.log(data);
        this.isEditMode(true);
        this.initializeAktenkonto(data);
        $('#aktenkonto').modal('show');
    }

    openBookingModal() {
        this.isEditMode(false);
        this.initializeAktenkonto(null);
        this.aktenkonto().Referat(this.akte().Referat());
        this.aktenkonto().Registernummer(this.akte().Registernummer());
        this.aktenkonto().Sachbearbeiter(this.akte().SachbearbeiterId());
        this.aktenkonto().Betreff(this.akte().Rubrum());
        this.aktenkonto().AdresseSuchbegriff(this.caseAddresses[0][0]['Keyword']);
        $('#aktenkonto').modal('show');
    }

    openDashboardModal(value) {

        const params: any = {};
        params.view = 'todos';
        if (value === 'termin') {
            params.viewType = 'termin';
            MainViewModel.RoutingTable.showDashboardView(params);
        }
        if (value === 'frist') {
            params.viewType = 'frist';
            MainViewModel.RoutingTable.showDashboardView(params);
        }
    }

    bookTypes() {
        return ko.observableArray([
            { k: 'A', v: 'Steuerfreie Auslagenbuchung', t: '0,00' },
            { k: 'B', v: 'Sonstige Buchung aus Buchhaltung', t: '19,00' },
            { k: 'F', v: 'Forderungsbuchung aus Vollstreckung', t: '0,00' }, // Need automatic calculations
            { k: 'H', v: 'Hebegebühr aus Buchhaltung', t: '19,00' },
            { k: 'O', v: 'Vorschussforderung steuerfreie Auslagen', t: '0,00' },
            { k: 'P', v: 'Steuerpflichtige Auslagenbuchung', t: '19,00' },
            { k: 'Q', v: 'Vorschussforderung steuerpflichtige Auslagen', t: '19,00' },
            { k: 'R', v: 'Forderung aus Abrechnungsschreiben', t: '19,00' }, // Need automatic calculations
            // { k: 'U', v: 'Umsatzsteuer auf steuerfreie Auslagen', t: '19,00' },
            { k: 'V', v: 'Vorschuss', t: '19,00' }
        ])
    }

    cashAccounts() {
        return ko.observableArray([
            { t: 'Kasse', v: '1000' },
            { t: 'Postbank', v: '1100' },
            { t: 'Bank 1', v: '1200' },
            { t: 'Bank 2', v: '1210' },
            { t: 'Fremdgeld', v: '1610' },
            { t: 'Auslagen steuerfrei', v: '1700' },
            { t: 'Auslagen steuerpflichtig', v: '1710' },
            { t: 'Erlöse aus anwaltlicher Tätigkeit', v: '8000' },
            { t: 'Erlöse aus notarieller Tätigkeit', v: '8010' }
        ])
    }

    mandantTypes() { return ['Mandant', 'Gegner', 'Sonstige', 'Gericht']; }

    valueAddedTaxes() { return ['19,00', '0,00', '7,00', '14,00', '15,00', '16,00']; }

    selectBookType(data, event) {
        if (event.target.value) {
            let book = ko.toJS(this.bookTypes()).find(item => item.k === event.target.value);
            this.aktenkonto().Mwst(book.t);
        }
    }

    salesSplits() {
        return ko.observable({
            Honorar: ko.observable(''), // Fee
            Fremdgeld: ko.observable(''), // Foreign/Alien Money
            AuslagenSteuerfrei: ko.observable(''), // Expenses tax-free
            AuslagenSteuerpflichtig: ko.observable(''), // Taxable expenses
        })
    }

    bookKeys = ko.observableArray([]);
    bookColumns = ko.observableArray([]);
    bookData = ko.observableArray([]);

    async showTable(keys, columns, data) {
        this.bookKeys(keys);
        this.bookColumns(columns);
        this.bookData(data);
    };

    refreshAccount() {
        //TODO: - Get all accounts from API
    }

    initializeAktenkonto(data) {
        this.aktenkonto({
            Abgerechnet: ko.observable(data ? data['Abgerechnet'] : ""),
            AdresseSuchbegriff: ko.observable(data ? data['AdresseSuchbegriff'] : ""),
            AdresseTyp: ko.observable(data ? data['AdresseTyp'] : "1"),
            Belegdatum: ko.observable(data ? moment(data['Belegdatum']).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')),
            Betreff: ko.observable(data ? data['Betreff'] : ""),
            Bezahlt: ko.observable(data ? data['Bezahlt'] : "0,00"),
            Buchungsdatum: ko.observable(data ? data['Buchungsdatum'] : moment.utc().format()),
            Buchungstext1: ko.observable(data ? data['Buchungstext1'] : ""),
            Buchungstext2: ko.observable(data ? data['Buchungstext2'] : ""),
            Buchungstyp: ko.observable(data ? data['Buchungstyp'] : "B"),
            DatUhr: ko.observable(data ? data['DatUhr'] : ""),
            EinOderAusgang: ko.observable(data ? data['EinOderAusgang'] : "E"), // E or A
            Fremdgeld: ko.observable(data ? data['Fremdgeld'] : "0,00"),
            Gebuehrenerhoehung: ko.observable(data ? data['Gebuehrenerhoehung'] : ""),
            Gesamtumsatz: ko.observable(data ? data['Gesamtumsatz'] : "0,00"),
            Honorar: ko.observable(data ? data['Honorar'] : "0,00"),
            ImportOrExport: ko.observable(data ? data['ImportOrExport'] : ""),
            Jahrgang: ko.observable(data ? data['Jahrgang'] : moment.utc().startOf('year').format()),
            Konto: ko.observable(data ? data['Konto'] : ''), //"0300",
            KorrespondierendeDatUhr: ko.observable(data ? data['KorrespondierendeDatUhr'] : ""),
            Menupunkt: ko.observable(data ? data['Menupunkt'] : ''),
            MindestGebuehr: ko.observable(data ? data['MindestGebuehr'] : ''),
            Mwst: ko.observable(data ? data['Mwst'] : '19,00'),
            NettoauslagenNachP26: ko.observable(data ? data['NettoauslagenNachP26'] : ""),
            NettoauslagenNachP27: ko.observable(data ? data['NettoauslagenNachP27'] : ""),
            NettoauslagenNachP28: ko.observable(data ? data['NettoauslagenNachP28'] : ""),
            Nettogebuehr: ko.observable(data ? data['Nettogebuehr'] : "0,00"),
            Paragraph: ko.observable(data ? data['Paragraph'] : ""),
            Rechnungsnummer: ko.observable(data ? data['Rechnungsnummer'] : ''),
            Referat: ko.observable(data ? data['Referat'] : ''),
            Registernummer: ko.observable(data ? data['Registernummer'] : ''),
            Root: ko.observable(data ? data['Root'] : ''),
            Sachbearbeiter: ko.observable(data ? data['Sachbearbeiter'] : ''),
            SteuerfreieAuslagen: ko.observable(data ? data['SteuerfreieAuslagen'] : "0,00"),
            SteuerpflichtigeAuslagen: ko.observable(data ? data['SteuerpflichtigeAuslagen'] : "0,00"),
            Streitwert: ko.observable(data ? data['Streitwert'] : ''),
            Umsatz: ko.observable(data ? data['Umsatz'] : "0,00"),
            Unterkonto: ko.observable(data ? data['Unterkonto'] : ''),
            Zehntel: ko.observable(data ? data['Zehntel'] : '')
        });

    }

    statusModalText = ko.observable("");
    statusModalTitle = ko.observable("");
    aktenkontoIsSaving = ko.observable(false);

    async addAktenkonto() {
        let conditions = [
            {
                name: this.aktenkonto().AdresseSuchbegriff(),
                id: 'akteAdresseSuchbegriff'
            }, {
                name: this.aktenkonto().AdresseTyp(),
                id: 'akteAdresseTyp'
            },
            // {
            //     name: this.aktenkonto().Rechnungsnummer(),
            //     id: 'akteRechnungsnummer'
            // },
            {
                name: this.aktenkonto().Buchungstyp(),
                id: 'akteBuchungstyp'
            }, {
                name: this.aktenkonto().Belegdatum(),
                id: 'akteBelegdatum'
            }, {
                name: this.aktenkonto().Buchungstext1(),
                id: 'akteBuchungstext1'
            }, {
                name: this.aktenkonto().Gesamtumsatz(),
                id: 'akteGesamtumsatz'
            }, {
                name: this.aktenkonto().Mwst(),
                id: 'akteMwst'
            }
        ]

        for (let i = 0; i < conditions.length; i++) {
            if (conditions[i].name === '' || (conditions[i].id === 'akteGesamtumsatz' && conditions[i].name === '0,00')) {
                $('#' + conditions[i].id).css('border', '1px solid red');
                return;
            }
        }

        let h = parseFloat(this.removeDotsFromNumber(this.aktenkonto().Honorar()).replace(',', '.'));
        let sa = parseFloat(this.removeDotsFromNumber(this.aktenkonto().SteuerfreieAuslagen()).replace(',', '.'));
        let sg = parseFloat(this.removeDotsFromNumber(this.aktenkonto().SteuerpflichtigeAuslagen()).replace(',', '.'));
        let fg = parseFloat(this.removeDotsFromNumber(this.aktenkonto().Fremdgeld()).replace(',', '.'));
        let total = parseFloat(this.removeDotsFromNumber(this.aktenkonto().Gesamtumsatz()).replace(',', '.'));
        //NARK: - Mark all red, if amount and shared amount was not equal.
        //console.log(h, sa, sg, fg, total);
        if (total !== h + sa + sg + fg) {
            $('#akteGesamtumsatz').css('border-color', 'red !important');
            $('#akteHonorar').css('border', '1px solid red');
            $('#akteSteuerfreieAuslagen').css('border', '1px solid red');
            $('#akteSteuerpflichtigeAuslagen').css('border', '1px solid red');
            $('#akteFremdgeld').css('border', '1px solid red');
            return;
        }

        //if (this.aktenkonto().Buchungstyp() === 'B' && this.aktenkonto().Mwst() !== '0,00') {
        //    let totalNetSum: any = 0;
        //    if (this.aktenkonto().Honorar() !== '0,00') {
        //        totalNetSum += Number(this.calculateNetAmount(this.aktenkonto().Honorar(), this.aktenkonto().Mwst()).replace(',', '.'));
        //    }
        //    if (this.aktenkonto().SteuerfreieAuslagen() !== '0,00') {
        //        totalNetSum += Number(sa);
        //    }
        //    if (this.aktenkonto().SteuerpflichtigeAuslagen() !== '0,00') {
        //        totalNetSum += Number(this.calculateNetAmount(this.aktenkonto().SteuerpflichtigeAuslagen(), this.aktenkonto().Mwst()).replace(',', '.'))
        //    }
        //    if (this.aktenkonto().Fremdgeld() !== '0,00') {
        //        totalNetSum += Number(this.calculateNetAmount(this.aktenkonto().Fremdgeld(), this.aktenkonto().Mwst()).replace(',', '.'))
        //    }

        //    totalNetSum = Math.round(totalNetSum * 100) / 100;
        //    totalNetSum = totalNetSum.toFixed(2);
        //    totalNetSum = totalNetSum.toString();
        //    totalNetSum = totalNetSum.replace('.', ',');
        //    this.aktenkonto().Nettogebuehr(totalNetSum);
        //}

        this.aktenkontoIsSaving(true);
        try {

            this.aktenkonto().Belegdatum(moment.utc(this.aktenkonto().Belegdatum()).format());
            this.aktenkonto().DatUhr(moment.utc().format('YYYYMMDD') + moment.utc().format('hhmmss') + Utils.uuidv4().substring(0, 6).toUpperCase())
            this.aktenkonto().Umsatz(this.aktenkonto().Gesamtumsatz());
            this.aktenkonto().Buchungstext1(this.aktenkonto().Buchungstext1().substring(0, 30));
            //console.log(ko.toJS(this.aktenkonto()));

            let responseType = (await RNSAPI.addAktenkonto(this.aktenkonto())).Type;

            if (responseType !== 'AddSuccessful') {
                throw new Error('Unable to add');
            }

            $('#aktenkonto').modal('hide');
            this.initializeAktenkonto(null);
            this.getAktenkontoByCaseID();
            this.resetErrorBorder();
            this.aktenkontoIsSaving(false);

        } catch (e) {
            this.statusModalTitle('<i class="far fa-times-circle  mr-2"></i> Fehler');
            this.statusModalText('Die Konto konnte nicht erstellt werden.<br />Bitte versuchen Sie es erneut.<br />');
            $('#aktenkonto').modal('hide');
            $("#statusModal").modal('show');
            this.resetErrorBorder();
            this.aktenkontoIsSaving(false);
            console.log(e);
        }
        //console.log(this.aktenkonto());
    }

    deleteAktenkonto = async (data) => {
        try {
            let responseType = (await RNSAPI.deleteAktenkonto(data.DatUhr)).Type;
            if (responseType !== 'DeleteSuccessful') {
                throw new Error('Unable to delete aktenkonto');
            }
            //console.log('Aktenkonto deleted successfully');
            this.getAktenkontoByCaseID();
        } catch (e) {
            console.log(e);
        }
    }

    reloadBrowser() {
        location.reload();
    }

    resetErrorBorder() {
        $('#akteGesamtumsatz').css('border-color', '#d4d8d8 !important');
        $('#akteHonorar').css('border', '1px solid #d4d8d8');
        $('#akteSteuerfreieAuslagen').css('border', '1px solid #d4d8d8');
        $('#akteSteuerpflichtigeAuslagen').css('border', '1px solid #d4d8d8');
        $('#akteFremdgeld').css('border', '1px solid #d4d8d8');
    }

    async editAktenkonto() {

        let conditions = [
            this.aktenkonto().AdresseSuchbegriff(),
            this.aktenkonto().AdresseTyp(),
            // this.aktenkonto().Rechnungsnummer(),
            this.aktenkonto().Gesamtumsatz(),
            this.aktenkonto().Buchungstyp(),
            this.aktenkonto().Belegdatum(),
            this.aktenkonto().Mwst(),
        ]

        for (let i = 0; i < conditions.length; i++) {
            if (conditions[i].name === '' || (conditions[i].id === 'akteGesamtumsatz' && conditions[i].name === '0,00')) {
                $('#' + conditions[i].id).css('border', '1px solid red');
                return;
            }
        }

        let h = parseFloat(this.removeDotsFromNumber(this.aktenkonto().Honorar()).replace(',', '.'));
        let sa = parseFloat(this.removeDotsFromNumber(this.aktenkonto().SteuerfreieAuslagen()).replace(',', '.'));
        let sg = parseFloat(this.removeDotsFromNumber(this.aktenkonto().SteuerpflichtigeAuslagen()).replace(',', '.'));
        let fg = parseFloat(this.removeDotsFromNumber(this.aktenkonto().Fremdgeld()).replace(',', '.'));
        let total = parseFloat(this.removeDotsFromNumber(this.aktenkonto().Gesamtumsatz()).replace(',', '.'));
        //NARK: - Mark all red, if amount and shared amount was not equal.
        //console.log(h, sa, sg, fg, total);
        if (h + sa + sg + fg !== total) {
            $('#akteGesamtumsatz').css('border-color', 'red !important');
            $('#akteHonorar').css('border', '1px solid red');
            $('#akteSteuerfreieAuslagen').css('border', '1px solid red');
            $('#akteSteuerpflichtigeAuslagen').css('border', '1px solid red');
            $('#akteFremdgeld').css('border', '1px solid red');
            return;
        }

        if (this.aktenkonto().Buchungstyp() === 'B' && this.aktenkonto().Mwst() !== '0,00') {
            let totalNetSum: any = 0;
            if (this.aktenkonto().Honorar() !== '0,00') {
                totalNetSum += Number(this.calculateNetAmount(this.aktenkonto().Honorar(), this.aktenkonto().Mwst()).replace(',', '.'));
            }
            if (this.aktenkonto().SteuerfreieAuslagen() !== '0,00') {
                totalNetSum += Number(sa);
            }
            if (this.aktenkonto().SteuerpflichtigeAuslagen() !== '0,00') {
                totalNetSum += Number(this.calculateNetAmount(this.aktenkonto().SteuerpflichtigeAuslagen(), this.aktenkonto().Mwst()).replace(',', '.'))
            }
            if (this.aktenkonto().Fremdgeld() !== '0,00') {
                totalNetSum += Number(this.calculateNetAmount(this.aktenkonto().Fremdgeld(), this.aktenkonto().Mwst()).replace(',', '.'))
            }

            totalNetSum = Math.round(totalNetSum * 100) / 100;
            totalNetSum = totalNetSum.toFixed(2);
            totalNetSum = totalNetSum.toString();
            totalNetSum = totalNetSum.replace('.', ',');
            this.aktenkonto().Nettogebuehr(totalNetSum);
        }

        //if (this.aktenkonto().EinOderAusgang() !== 'E') {
        //    this.aktenkonto().Gesamtumsatz('-' + this.aktenkonto().Gesamtumsatz());
        //    this.aktenkonto().Honorar('-' + this.aktenkonto().Honorar());
        //    this.aktenkonto().SteuerpflichtigeAuslagen('-' + this.aktenkonto().SteuerpflichtigeAuslagen());
        //    this.aktenkonto().SteuerfreieAuslagen('-' + this.aktenkonto().SteuerfreieAuslagen());
        //    this.aktenkonto().Fremdgeld('-' + this.aktenkonto().Fremdgeld());
        //}

        try {

            this.aktenkonto().Belegdatum(moment.utc(this.aktenkonto().Belegdatum()).format());
            this.aktenkonto().Umsatz(this.aktenkonto().Gesamtumsatz());
            this.aktenkonto().Buchungstext1(this.aktenkonto().Buchungstext1().substring(0, 30));

            //console.log('edit aktenkonto called.', ko.toJS(this.aktenkonto()));
            let response = (await RNSAPI.updateAktenkonto(this.aktenkonto())).Type;

            if (response !== 'UpdateSuccessful') {
                throw new Error('');
            }
            this.getAktenkontoByCaseID();
            $('#aktenkonto').modal('hide');

        } catch (e) {
            console.log(e);
        }
    }

    summe = ko.observable({
        Gesamtumsatz: ko.observable('0,00'),
        Honorar: ko.observable('0,00'),
        FHonorar: ko.observable('0,00'),
        SteuerpflichtigeAuslagen: ko.observable('0,00'),
        FSteuerpflichtigeAuslagen: ko.observable('0,00'),
        SteuerfreieAuslagen: ko.observable('0,00'),
        FSteuerfreieAuslagen: ko.observable('0,00'),
        Fremdgeld: ko.observable('0,00'),
    });

    summeAccounting() {

    }

    validateNumber(evt) {
        var theEvent = evt || window.event;

        // Handle paste
        if (theEvent.type === 'paste') {
            key = evt.clipboardData.getData('text/plain');
        } else {
            // Handle key press
            var key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }
        var regex = /[0-9]|\./;

        if (!regex.test(key)) {
            return false;
        } else {
            return true;
        }
    }

    removeDotsFromNumber(str: string) {
        return str.replace(/\./g, '');
    }

    converToDeCurrency(value) {
        let val: any = Number(this.removeDotsFromNumber(value).replace(',', '.'));
        this.aktenkonto().Gesamtumsatz(new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(val));
    }

    calculateAmount() {

        this.converToDeCurrency(this.aktenkonto().Gesamtumsatz());

        if (this.aktenkonto().Buchungstyp() === 'A' && this.aktenkonto().Mwst() === '0,00') {
            //0%
            this.aktenkonto().Honorar('0,00');
            this.aktenkonto().SteuerfreieAuslagen(this.aktenkonto().Gesamtumsatz());
            this.aktenkonto().SteuerpflichtigeAuslagen('0,00');
            this.aktenkonto().Fremdgeld('0,00');
            this.aktenkonto().Nettogebuehr(this.removeDotsFromNumber(this.aktenkonto().Gesamtumsatz()));
        }
        if (this.aktenkonto().Buchungstyp() === 'B' && this.aktenkonto().Mwst() !== '0,00') {
            //19% - Might Change
            this.aktenkonto().Honorar(this.aktenkonto().Gesamtumsatz());
            this.aktenkonto().SteuerfreieAuslagen('0,00');
            this.aktenkonto().SteuerpflichtigeAuslagen('0,00');
            this.aktenkonto().Fremdgeld('0,00');
        }
        if (this.aktenkonto().Buchungstyp() === 'F' && this.aktenkonto().Mwst() === '0,00') {
            //0%
            this.aktenkonto().Honorar('0,00');
            this.aktenkonto().SteuerfreieAuslagen('0,00');
            this.aktenkonto().SteuerpflichtigeAuslagen('0,00');
            this.aktenkonto().Fremdgeld(this.aktenkonto().Gesamtumsatz());
            this.aktenkonto().Nettogebuehr(this.aktenkonto().Gesamtumsatz());
        }
        //Forwarding fee - 19% Fixed VAT
        if (this.aktenkonto().Buchungstyp() === 'H' && this.aktenkonto().Mwst() === '19,00') {
            //19%
            this.aktenkonto().Honorar(this.aktenkonto().Gesamtumsatz());
            this.aktenkonto().SteuerfreieAuslagen('0,00');
            this.aktenkonto().SteuerpflichtigeAuslagen('0,00');
            this.aktenkonto().Fremdgeld('0,00');
            this.aktenkonto().Nettogebuehr(this.calculateNetAmount(this.aktenkonto().Gesamtumsatz(), '19,00'));
        }
        if (this.aktenkonto().Buchungstyp() === 'O' && this.aktenkonto().Mwst() === '0,00') {
            //0%
            this.aktenkonto().Honorar('0,00');
            this.aktenkonto().SteuerfreieAuslagen(this.aktenkonto().Gesamtumsatz());
            this.aktenkonto().SteuerpflichtigeAuslagen('0,00');
            this.aktenkonto().Fremdgeld('0,00');
            this.aktenkonto().Nettogebuehr(this.aktenkonto().Gesamtumsatz());
        }
        if (this.aktenkonto().Buchungstyp() === 'P' && this.aktenkonto().Mwst() !== '0,00') {
            //19% - Can change
            this.aktenkonto().Honorar('0,00');
            this.aktenkonto().SteuerfreieAuslagen('0,00');
            this.aktenkonto().SteuerpflichtigeAuslagen(this.aktenkonto().Gesamtumsatz());
            this.aktenkonto().Fremdgeld('0,00');
            this.aktenkonto().Nettogebuehr(this.calculateNetAmount(this.aktenkonto().Gesamtumsatz(), this.aktenkonto().Mwst()));
        }
        if (this.aktenkonto().Buchungstyp() === 'Q' && this.aktenkonto().Mwst() !== '0,00') {
            //19% - Can change
            this.aktenkonto().Honorar('0,00');
            this.aktenkonto().SteuerfreieAuslagen('0,00');
            this.aktenkonto().SteuerpflichtigeAuslagen(this.aktenkonto().Gesamtumsatz());
            this.aktenkonto().Fremdgeld('0,00');
            this.aktenkonto().Nettogebuehr(this.calculateNetAmount(this.aktenkonto().Gesamtumsatz(), this.aktenkonto().Mwst()));
        }
        if (this.aktenkonto().Buchungstyp() === 'R') {
            //19% - Can change
            this.aktenkonto().Honorar(this.aktenkonto().Gesamtumsatz());
            this.aktenkonto().SteuerfreieAuslagen('0,00');
            this.aktenkonto().SteuerpflichtigeAuslagen('0,00');
            this.aktenkonto().Fremdgeld('0,00');
            this.aktenkonto().Nettogebuehr(this.calculateNetAmount(this.aktenkonto().Gesamtumsatz(), this.aktenkonto().Mwst()));
        }
        if (this.aktenkonto().Buchungstyp() === 'U' && this.aktenkonto().Mwst() !== '0,00') {
            //19% - Can change
            this.aktenkonto().Honorar('0,00');
            this.aktenkonto().SteuerfreieAuslagen('0,00');
            this.aktenkonto().SteuerpflichtigeAuslagen(this.aktenkonto().Gesamtumsatz());
            this.aktenkonto().Fremdgeld('0,00');
            this.aktenkonto().Nettogebuehr(this.calculateNetAmount(this.aktenkonto().Gesamtumsatz(), this.aktenkonto().Mwst()));
        }
        if (this.aktenkonto().Buchungstyp() === 'V' && this.aktenkonto().Mwst() === '19,00') {
            //19%
            this.aktenkonto().Honorar(this.aktenkonto().Gesamtumsatz());
            this.aktenkonto().SteuerfreieAuslagen('0,00');
            this.aktenkonto().SteuerpflichtigeAuslagen('0,00');
            this.aktenkonto().Fremdgeld('0,00');
            this.aktenkonto().Nettogebuehr(this.calculateNetAmount(this.aktenkonto().Gesamtumsatz(), '19,00'));
        }

        let h = parseFloat(this.aktenkonto().Honorar().replace(',', '.'));
        let sa = parseFloat(this.aktenkonto().SteuerfreieAuslagen().replace(',', '.'));
        let sg = parseFloat(this.aktenkonto().SteuerpflichtigeAuslagen().replace(',', '.'));
        let fg = parseFloat(this.aktenkonto().Fremdgeld().replace(',', '.'));

        //console.log(h + sa + sg + fg);
        //console.log(parseFloat(this.aktenkonto().Gesamtumsatz().replace(',', '.')));
        //console.log(this.aktenkonto().Nettogebuehr());

        if (h + sa + sg + fg !== parseFloat(this.aktenkonto().Gesamtumsatz().replace(',', '.'))) {
            console.log('Not equal');
            // return;
        } else {
            console.log('Equal')
        }
    }

    calculateNetAmount(amount, rate) {
        let netamount: any = 0;
        netamount = this.removeDotsFromNumber(amount); //Clear all dots
        netamount = parseFloat(netamount.replace(',', '.'));
        netamount = Math.round(netamount * 100) / 100;
        netamount = (netamount) / ((Number(rate.replace(',', '.') / 100)) + 1);
        netamount = netamount.toFixed(2);
        netamount = netamount.toString();
        netamount = netamount.replace('.', ',');
        //console.log(netamount);
        return netamount;
    }

    async suggestRechnungNumber() {
        let rechnungsnummer = (await RNSAPI.rvgSuggestId()).Payload.Rechnungsnummer;
        this.aktenkonto().Rechnungsnummer(rechnungsnummer);
    }

    aktenkontos = ko.observableArray([]);

    showDirection(einOderAusgang, x) {
        if (einOderAusgang === 'A') {
            return '- ' + x.toString();
        }

        if (einOderAusgang === 'E') {
            return '+ ' + x.toString();
        }

        return x;
    }

    dateFormat(date) {
        return moment(date).format('DD.MM.YYYY');
    }

    trimMwst(value) {
        if (value.indexOf(',') !== -1) {
            return value.slice(0, -3);
        }
        return value;
    }

    async getAktenkontoByCaseID() {

        this.summe().Honorar('0,00');
        this.summe().FHonorar('0,00');
        this.summe().SteuerpflichtigeAuslagen('0,00');
        this.summe().FSteuerpflichtigeAuslagen('0,00');
        this.summe().FSteuerfreieAuslagen('0,00');
        this.summe().SteuerfreieAuslagen('0,00');
        this.summe().Fremdgeld('0,00');

        let entries = (await RNSAPI.getAktenkontoByCaseID(this.caseId)).Payload.Aktenkonto
            .filter(d => moment(d.Belegdatum).isValid())
            .map(entry => {

                if (entry.Root === 'H') {
                    this.summe().FHonorar(this.transformSumme(parseFloat(this.removeDotsFromNumber(this.summe().FHonorar()).replace(',', '.')) + parseFloat(this.removeDotsFromNumber(entry.Honorar).replace(',', '.'))));
                    this.summe().FSteuerpflichtigeAuslagen(this.transformSumme(parseFloat(this.removeDotsFromNumber(this.summe().FSteuerpflichtigeAuslagen()).replace(',', '.')) + parseFloat(this.removeDotsFromNumber(entry.SteuerpflichtigeAuslagen).replace(',', '.'))));
                    this.summe().FSteuerfreieAuslagen(this.transformSumme(parseFloat(this.removeDotsFromNumber(this.summe().FSteuerfreieAuslagen()).replace(',', '.')) + parseFloat(this.removeDotsFromNumber(entry.SteuerfreieAuslagen).replace(',', '.'))));
                    this.summe().Fremdgeld(this.transformSumme(parseFloat(this.removeDotsFromNumber(this.summe().Fremdgeld()).replace(',', '.')) + parseFloat(this.removeDotsFromNumber(entry.Fremdgeld).replace(',', '.'))));
                } else {
                    this.summe().Honorar(this.transformSumme(parseFloat(this.removeDotsFromNumber(this.summe().Honorar()).replace(',', '.')) + parseFloat(this.removeDotsFromNumber(entry.Honorar).replace(',', '.'))));
                    this.summe().SteuerpflichtigeAuslagen(this.transformSumme(parseFloat(this.removeDotsFromNumber(this.summe().SteuerpflichtigeAuslagen()).replace(',', '.')) + parseFloat(this.removeDotsFromNumber(entry.SteuerpflichtigeAuslagen).replace(',', '.'))));
                    this.summe().SteuerfreieAuslagen(this.transformSumme(parseFloat(this.removeDotsFromNumber(this.summe().SteuerfreieAuslagen()).replace(',', '.')) + parseFloat(this.removeDotsFromNumber(entry.SteuerfreieAuslagen).replace(',', '.'))));
                    this.summe().Fremdgeld(this.transformSumme(parseFloat(this.removeDotsFromNumber(this.summe().Fremdgeld()).replace(',', '.')) + parseFloat(this.removeDotsFromNumber(entry.Fremdgeld).replace(',', '.'))));
                }
                return entry;
            })

        let hs = parseFloat(this.removeDotsFromNumber(this.summe().FHonorar()).replace(',', '.')) - parseFloat(this.removeDotsFromNumber(this.summe().Honorar()).replace(',', '.')) <= 0 ? 0 : parseFloat(this.removeDotsFromNumber(this.summe().FHonorar()).replace(',', '.')) - parseFloat(this.removeDotsFromNumber(this.summe().Honorar()).replace(',', '.'));
        this.summe().FHonorar(this.transformSumme(hs))
        let fls = parseFloat(this.removeDotsFromNumber(this.summe().FSteuerpflichtigeAuslagen()).replace(',', '.')) - parseFloat(this.removeDotsFromNumber(this.summe().SteuerpflichtigeAuslagen()).replace(',', '.')) <= 0 ? 0 : parseFloat(this.removeDotsFromNumber(this.summe().FSteuerpflichtigeAuslagen()).replace(',', '.')) - parseFloat(this.removeDotsFromNumber(this.summe().SteuerpflichtigeAuslagen()).replace(',', '.'));
        this.summe().FSteuerpflichtigeAuslagen(this.transformSumme(fls))
        let frs = parseFloat(this.removeDotsFromNumber(this.summe().FSteuerfreieAuslagen()).replace(',', '.')) - parseFloat(this.removeDotsFromNumber(this.summe().SteuerfreieAuslagen()).replace(',', '.')) <= 0 ? 0 : parseFloat(this.removeDotsFromNumber(this.summe().FSteuerfreieAuslagen()).replace(',', '.')) - parseFloat(this.removeDotsFromNumber(this.summe().SteuerfreieAuslagen()).replace(',', '.'));
        this.summe().FSteuerfreieAuslagen(this.transformSumme(frs))

        this.aktenkontos(entries);
    }

    transformSumme(sum) {
        sum = Math.round(sum * 100) / 100;
        sum = sum.toFixed(2);
        sum = sum.toString();
        sum = sum.replace('.', ',');
        return sum;
    }

    gotoCalculator() { this.router.navigate('/calculator'); }
    //gotoCases() { this.router.navigate('/cases'); }

}

function extendedFromLayout(layout: any, caseData: any): model.Extended {
    const identifikationsdaten = transformElements(layout.Identifikationsdaten);

    const organisationsdaten: model.Tab = {
        Name: "Beteiligte",
        Forms: ko.observableArray([transformElements(layout.Organisationsdaten)])
    };

    const mandanten: model.Tab = {
        Name: "Fallinformationen",
        Forms: ko.observableArray(Utils.replicate(caseData.Addresses[0].length, () => transformElements(layout.Mandant)))
    }

    const gegner: model.Tab = {
        Name: "Dokumente",
        Forms: ko.observableArray(Utils.replicate(caseData.Addresses[1].length, () => transformElements(layout.Gegner)))
    }

    const gerichte: model.Tab = {
        Name: "Checkliste",
        Forms: ko.observableArray(Utils.replicate(caseData.Addresses[6].length, () => transformElements(layout.Gericht)))
    }

    const weitere: model.Tab = {
        Name: "Aktenkonto",
        Forms: ko.observableArray(Utils.replicate(caseData.Addresses[7].length, () => transformElements(layout.Weitere)))
    }

    return {
        Identifikationsdaten: transformElements(layout.Identifikationsdaten),
        Organisationsdaten: organisationsdaten,
        Mandanten: mandanten,
        Gerichte: gerichte,
        Gegner: gegner,
        Weitere: weitere,
        Sachverhalt: layout.Sachverhalt.map(transformSachverhaltTab),
    };
}

function transformElements(elements: any): model.FormElement[][] {
    return elements.map(row => row.map(transformLayoutFormElement));
}

function transformSachverhaltTab(oldTab: any): model.Tab {
    return {
        Name: oldTab.Name,
        Forms: ko.observableArray([transformElements(oldTab.Elements)])
    };
}

function getLayout(selectedName: string): any {
    switch (selectedName) {
        case "Allgemein": return layoutAllgemein;
        case "Allgemeines Zivilrecht": return layoutAllgemeinesZivilRecht;
        case "Arbeitsrecht": return layoutArbeitsrecht;
        case "Erbrecht": return layoutErbrecht;
        case "Familienrecht": return layoutFamilienrecht;
        case "Mietrecht": return layoutMietrecht;
        case "Sozialrecht": return layoutSozialrecht;
        case "Steuerrecht": return layoutSteuerrecht;
        case "Strafrecht": return layoutStrafrecht;
        case "VerkehrsOWiR": return layoutVerkehrsOWI;
        case "Verkehrsrecht": return layoutVerkehrsrecht;
        case "Verwaltungsrecht": return layoutVerwaltungsrecht;
        case "Zivilrecht": return layoutZivilrecht;
        case "Asylrecht": return layoutAsylrecht;
    }
}

interface Mapper {
    toRnsApi(a: any): any
    toLayout(a: any): any
}

function getMapper(field) {
    let mapper: { [name: string]: Mapper } = {
        "string": new class {
            toRnsApi(a) { return a; }
            toLayout(a) { return a; }
        }, "DateTime": new class {
            toRnsApi(a) {
                if (!a || a === "") return null;
                if (typeof (a) === 'function') return a;
                return new Date(a)
            }
            toLayout(a) { return "" + a.toString; }
        }, "int": new class {
            toRnsApi(a) {
                if (!a || a === "") return 0;
                if (typeof (a) === 'string') return parseInt(a);
                return a;
            }
            toLayout(a) { return a; }
        }, "double": new class {
            toRnsApi(a) {
                if (!a || a === "") return 0.0;
                if (typeof (a) === 'string') return parseFloat(a);
                return a;
            }
            toLayout(a) { return a; }
        }, "bool": new class {
            toRnsApi(a) {
                if (a) return "true"; else return "false";
            }
            toLayout(a) {
                if (a === "true") return true; else return false;
            }
        }
    };

    switch (field) {
        case "Registernummer": return mapper["string"];
        case "AblageDatum": return mapper["DateTime"];
        case "Ablagekennzeichen": return mapper["string"];
        case "AblageNr": return mapper["string"];
        case "AnlageDatum": return mapper["string"];
        case "Druckkennung": return mapper["string"];
        case "FristDatum": return mapper["DateTime"];
        case "FristNr": return mapper["string"];
        case "GlauebigerID": return mapper["string"];
        case "Kostenstelle": return mapper["string"];
        case "Kostentraeger": return mapper["string"];
        case "MahnDatum": return mapper["DateTime"];
        case "MahnNr": return mapper["string"];
        case "Referat": return mapper["string"];
        case "Rubrum": return mapper["string"];
        case "Wegen": return mapper["string"];
        case "SachbearbeiterId": return mapper["string"];
        case "AdvoportUpload": return mapper["bool"];
        case "AdvoportUploadDate": return mapper["DateTime"];
        case "AktenZeichenGericht1": return mapper["string"];
        case "AktenZeichenGericht2": return mapper["string"];
        case "AktenZeichenGericht3": return mapper["string"];
        case "AktenZeichenMahnGericht": return mapper["string"];
        case "AntragsGerManBeId": return mapper["string"];
        case "AnzahlGegner": return mapper["int"];
        case "AnzahlMandanten": return mapper["int"];
        case "DatumErlassMahnBescheid": return mapper["DateTime"];
        case "eConsultRoomID": return mapper["string"];
        case "eConsultRootNodeID": return mapper["string"];
        case "GegenAnwaltCaseID": return mapper["string"];
        case "GSchadenssparte": return mapper["string"];
        case "IsAuslaendischesMandat": return mapper["bool"];
        case "IsEigeneBeitreibung": return mapper["bool"];
        case "IsMandantKlaeger": return mapper["bool"];
        case "IsMandantVorzugsteuerabzugberechtigt": return mapper["bool"];
        case "JurionUpload": return mapper["bool"];
        case "JurionUploadDate": return mapper["DateTime"];
        case "KorrAnwaltCaseID": return mapper["string"];
        case "MSchadenssparte": return mapper["string"];
        case "Note1": return mapper["string"];
        case "Note2": return mapper["string"];
        case "Note3": return mapper["string"];
        case "Sachstand": return mapper["string"];
        case "WertGericht1": return mapper["double"];
        case "WertGericht2": return mapper["double"];
        case "WertGericht3": return mapper["double"];
        case "Gericht1Id": return mapper["string"];
        case "Gericht2Id": return mapper["string"];
        case "Gericht3Id": return mapper["string"];
        case "GegenAnwaltId": return mapper["string"];
        case "KorrAnwaltID": return mapper["string"];
    }

    return new class {
        toRnsApi(a) { return a; }
        toLayout(a) { return a; }
    };
}

function germanDateOrEmpty(s: string): string {
    let m = moment(s);
    if (m.isValid()) {
        return m.format("MM.DD.YYYY");
    } else {
        return "";
    }
}

function updateDataModel(e: model.Extended, data: any, caseId: string): void {
    type Setter = (key: string, value: string) => void

    function getSetterForCaseData(e: model.FormElement): Setter {
        function doSet(o, k, v): void {
            if (o === data.Akte) {
                if (k === "AnlageDatum") {
                    v = germanDateOrEmpty(v);
                } else {
                    let mapper = getMapper(k);
                    v = mapper.toRnsApi(v);
                }
            }

            if (o === data.ExtendedCase.Infos && e.WebInfo === "Datum") {
                v = germanDateOrEmpty(v);
            }
            if (o && k) o[k] = v;
        }
        if (e.Database === "Fats/Akte") return (key, value) => doSet(data.Akte, key, value);
        else if (e.Database === "XML/Default" || e.Database === "XML/Akte") return (key, value) => doSet(data.ExtendedCase.Infos, key, value);
        else return (key, value) => { };
    }

    function getSetterForPersonData(e: model.FormElement, addressIndex: number, personIndex: number): Setter {
        function doSet(o, k, v): void {
            if (o && k) o[k] = v;
            else if (o) o[k] = "";
        }

        if (e.Database === "Fats/Akte" || e.Database === "Fats/DefaultZuord")
            return (key, value) => doSet(data.Addresses[addressIndex][personIndex], key, value);
        else if (e.Database === "XML/Default") {
            let keyword = data.Addresses[addressIndex][personIndex]["Keyword"]
            let personData = data.ExtendedCase.Persons.find(data => data.subeg === keyword);
            if (!personData) {
                let addressType = addressIndex + 2;
                personData = {
                    "adresstyp": "" + addressType,
                    "dsid": "" + (data.ExtendedCase.Persons.size + 1),
                    "infos": {
                        "dskeyvater": "0014016",
                        "adresstypvater": "1",
                        "dsidvater": "-1"
                    },
                    "regnr": caseId,
                    "rolle": "",
                    "subeg": keyword,
                    "zunum": "1"
                }
                data.ExtendedCase.Persons.push(personData);
                return (key, value) => doSet(personData, key, value);
            }
            return (key, value) => doSet(personData, key, value);
        }
        else return (key, value) => { };
    }

    function copyBack(e: model.FormElement, setter: Setter): void {
        switch (e.Type) {
            case "HEADLINE": break;
            case "LABEL": break;

            case "COMBOBOX":
            case "TEXTAREA":
            case "TEXTBOX":
                setter(e.DbText, e.Data());
                break;
            case "CHECKBOX":
                setter(e.DbText, "" + !!e.Data());
                break;
            case "RADIOGROUP":
                let values = Object.keys(e.Values).map((k) => e.Values[k]);
                for (let value of values) {
                    setter(value, "" + (e.Data() === value))
                }
                break;
            case "TABLE":
                if (data.ExtendedCase.Tables[e.DbText]) {
                    data.ExtendedCase.Tables[e.DbText].Rows = e.Data().map(row => row.map(element => element()));
                } else {
                    data.ExtendedCase.Tables[e.DbText] = {
                        Rows: e.Data().map(row => row.map(element => element())),
                        Text: "",
                        ColumnVertical: true,
                        Headers: e.Headers
                    }
                }
                break;
            default: throw "Don't know element: " + JSON.stringify(e);
        }
    }

    let normalForms: model.FormElement[][][] = [
        e.Identifikationsdaten,
        ...e.Organisationsdaten.Forms()
    ];
    // ...e.Organisationsdaten.Forms(), //TODO: check me out
    for (let svt of e.Sachverhalt) {
        normalForms = normalForms.concat(svt.Forms());
    }

    for (let form of normalForms) {
        for (let row of form) {
            for (let entry of row) {
                copyBack(entry, getSetterForCaseData(entry));
            }
        }
    }

    e.Mandanten.Forms().forEach((form, personIndex) => {
        for (let row of form) {
            for (let entry of row) {
                copyBack(entry, getSetterForPersonData(entry, 0, personIndex));
            }
        }
    })

    e.Gegner.Forms().forEach((form, personIndex) => {
        for (let row of form) {
            for (let entry of row) {
                copyBack(entry, getSetterForPersonData(entry, 1, personIndex));
            }
        }
    })

    e.Gerichte.Forms().forEach((form, personIndex) => {
        for (let row of form) {
            for (let entry of row) {
                copyBack(entry, getSetterForPersonData(entry, 6, personIndex));
            }
        }
    })

    e.Weitere.Forms().forEach((form, personIndex) => {
        for (let row of form) {
            for (let entry of row) {
                copyBack(entry, getSetterForPersonData(entry, 7, personIndex));
            }
        }
    })
}

function transformLayoutFormElement(layoutElement: any): model.FormElement {
    const newElement: model.FormElement = {
        Type: layoutElement.Type,
        Size: layoutElement.Size,
        Skip: layoutElement.Skip,
        ReadOnly: layoutElement.ReadOnly,
        MaxLength: layoutElement.MaxLength,
        Label: layoutElement.Label,
        SelectionShow: layoutElement.SelectionShow,
    }

    setIfNotNull(newElement, "Database", layoutElement.Database);
    setIfNotNull(newElement, "DbText", layoutElement.DbText);
    setIfNotNull(newElement, "Alignment", layoutElement.Alignment);
    setIfNotNull(newElement, "WebInfo", layoutElement.WebInfo);
    setIfNotNull(newElement, "Values", layoutElement.Values);
    setIfNotNull(newElement, "Headers", layoutElement.Headers);

    if (layoutElement.SelectionFrom && layoutElement.SelectionProperty) {
        newElement.Selection = {
            SelectionFrom: layoutElement.SelectionFrom,
            SelectionProperty: layoutElement.SelectionProperty,
        };
    }

    switch (newElement.Type) {
        case "HEADLINE": break;
        case "LABEL": break;

        case "TEXTBOX": newElement.Data = ko.observable(""); break;
        case "CHECKBOX": newElement.Data = ko.observable(false); break;
        case "COMBOBOX": newElement.Data = ko.observable(""); break;
        case "RADIOGROUP": newElement.Data = ko.observable(""); break;
        case "TEXTAREA": newElement.Data = ko.observable(""); break;
        case "TABLE": newElement.Data = ko.observableArray([]); break;

        default: Utils.assertNever(newElement.Type); break;
    }

    return newElement;
}

function fillData(extended: model.Extended, caseData: any): void {
    type Resolver = (source: string | undefined, name: string) => string | undefined;

    // this.grund(resubmissionData[0]['DeadLineReason']);
    const resolveCaseData: Resolver = (source, name) => {
        if (source === "XML/Default" || source === "XML/Akte") {
            return caseData.ExtendedCase.Infos[name];
        } else if (source === "Fats/Akte") {
            return caseData.Akte[name];
        }
    }

    function resolvePersonData(addressData): Resolver {
        const resolveAdditionalDataByKeyword: (keyword: string) => any = keyword => {
            const personData = caseData.ExtendedCase.Persons.find(data => data.subeg === keyword);
            if (personData) {
                return personData[name as any];
            } else {
                return undefined;
            }
        };

        return (source: string | undefined, name: string) => {
            if (source && source.startsWith("XML")) {
                if (addressData.Keyword) {
                    return resolveAdditionalDataByKeyword(addressData.Keyword);
                } else {
                    return undefined;
                }
            } else if (source === "Fats/Default") {
                return addressData[name]
            } else {
                return undefined;
            }
        }
    }

    function parseDate(input: string, format?: string): moment.Moment {
        let m = format ? moment(input, format, true) : moment(input);
        if (m.isValid()) {
            return m;
        } else {
            throw new Error(`input ${input} was not ${format}`);
        }
    }

    function fillEntry(e: model.FormElement, resolver: Resolver): void {
        let resolved = resolver(e.Database, e.DbText);
        switch (e.Type) {
            case "HEADLINE": break;
            case "LABEL": break;

            case "COMBOBOX":
            case "TEXTAREA":
            case "TEXTBOX":
                if (resolved) {
                    if (e.WebInfo === "Datum") {
                        try {
                            let date: any = undefined;
                            if (e.Database === "Fats/Akte" && e.DbText === "AnlageDatum") {
                                date = parseDate(resolved, "DD.MM.YYYY")
                            } else if (e.Database === "Fats/Akte") {
                                date = parseDate(resolved);
                            } else {
                                date = parseDate(resolved, "DD.MM.YYYY");
                            }
                            e.Data(date.format("YYYY-MM-DD"));
                        } catch {
                            e.Data("");
                        }
                    } else {
                        e.Data(resolved);
                    }
                } else {
                    e.Data("");
                }
                break;
            case "CHECKBOX":
                if (!resolved || typeof (resolved) === "string" && resolved.toLowerCase() === "false") {
                    e.Data(false);
                } else {
                    e.Data(true);
                }
                break;
            case "RADIOGROUP":
                let res = Object.keys(e.Values).map((k) => e.Values[k]).find(dbEntry => {
                    let r = resolver(e.Database, dbEntry);
                    return r && typeof (r) === 'string' && r.toLowerCase() === "true";
                });
                if (resolved) {
                    e.Data(res);
                } else {
                    e.Data("");
                }
                break;
            case "TABLE":
                const tableDescription = caseData.ExtendedCase.Tables[e.DbText];
                if (tableDescription) {

                    // Error TS2339: Property 'push' does not exist on type 'KnockoutObservable<any>'.
                    // tableDescription.Rows.forEach(row => e.Data.push(makeObservables(row)));
                    tableDescription.Rows.forEach((row, index) => e.Data[index] = makeObservables(row));

                }
                break;
            default: throw "Don't know element: " + JSON.stringify(e);
        }
    }

    function makeObservables(row: string[]): ko.Observable<string>[] {
        return row.map(element => ko.observable(element));
    }

    function fillForm(form: model.FormElement[][], r: Resolver): void {
        for (let row of form) {
            for (let element of row) {
                fillEntry(element, r);
            }
        }
    }

    function fillCaseTab(t: model.Tab): void {
        t.Forms().forEach(form => fillForm(form, resolveCaseData));
    }

    fillForm(extended.Identifikationsdaten, resolveCaseData);
    fillCaseTab(extended.Organisationsdaten);
    extended.Sachverhalt.forEach(fillCaseTab);

    const personTypes: PersonLookupDescription[] = [
        { name: "Mandanten", addressesIndex: 0 },
        { name: "Gegner", addressesIndex: 1 },
        { name: "Gerichte", addressesIndex: 6 },
        { name: "Weitere", addressesIndex: 7 },
    ];

    personTypes.forEach(description => {
        extended[description.name].Forms().forEach((form, index) => {
            const resolver: Resolver = resolvePersonData(caseData.Addresses[description.addressesIndex][index])
            fillForm(form, resolver);
        });
    });
}

interface PersonLookupDescription {
    name: string,
    addressesIndex: number,
}

function setIfNotNull(o: any, attribute: string, value: any): void {
    if (value !== null) o[attribute] = value;
}

interface Data {
    caseId: string,
    layout: any,
    caseData: any,
    selectedRechtsGebiet: ko.Observable<string>,
    selectedBereich: ko.Observable<string>,
    extended: model.Extended,
    currentTab: ko.Observable<string>,
}

var html = fs.readFileSync(__dirname + '/new.html', 'utf8');

ko.components.register("new-view", {
    viewModel: NewViewModel,
    template: html
});

