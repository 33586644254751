import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { Postbox } from "../dashboard/postbox";
import { DialogViewModel } from './dialog';

export class VerifyPasswordDialogViewModel {

    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalHandleSelection = ko.observable();
    givenId = "VerifyPasswordDialogExt";

    
    userPassword = ko.observable("");
    passwordError = ko.observable("");
    checkPassword = ko.observable(false);
    passwordChanged = ko.observable(false);


    async verifyPassword() {
        if(this.userPassword() === '' || !this.userPassword()) return this.passwordError("Bitte geben Sie ihr Anmeldepasswort ein.");
        this.checkPassword(true);
        const response = await this.isUserPasswordValid();
        if(response) {
            Postbox.publisher().publish(true, "VerifiedPassword");
            Postbox.publisher().publish("", "setLoggedInUserLayout");
            this.userPassword("");
            this.passwordError("");
            this.checkPassword(false);
            $("#VerifyPasswordDialogExt").modal('hide');
        } else{
            Postbox.publisher().publish(false, "VerifiedPassword");
            this.checkPassword(false);
            this.passwordError("Das eingegebene Passwort war ungültig.")
        }
    }

    async isUserPasswordValid(): Promise<boolean> {
        let isValid = false;
        const verifyData = { Password: this.userPassword()};
        await RNSAPI.verifyUserPassword(verifyData).then(data => {
            isValid = true;
        }).catch(error => {
            isValid = false;
        });
        return isValid;
    }


   
    constructor() {
        Postbox.publisher().subscribe((isPasswordChanged) => {
            this.passwordChanged(isPasswordChanged);
        }, "DoesUserChangedThePassword");
    }
}


let html = fs.readFileSync(__dirname + "/VerifyPasswordDialog.html", "utf8");

ko.components.register("verify-password-dialog-view", {
  viewModel: VerifyPasswordDialogViewModel,
  template: html,
});