import * as fs from "fs";
import * as ko from "knockout";
import Navigo = require("navigo");
import { RNSAPI } from "../../api";
import { User } from "../../UserData";
import { Utils } from "../../utils";
import { MainViewModel } from "../../main";
import { LawFirmRegistration } from "../../types/LawFirmRegistration";


export class RegisterViewModel {
    getRecaptchaCode = function () {
        return $('.g-recaptcha-response').val();
    };
    router = new Navigo(null, true);
    step = ko.observable('');
    currentStep = ko.observable(1);
    kdNumber = ko.observable('');
    usNummer = ko.observable('');
    regEmail = ko.observable('');
    submitedRegister = ko.observable(false);
    registerErrorMail = ko.observable(false);
    registerErrorFail = ko.observable(false);
    agreed = ko.observable(false);
    anredeTitle = ko.observable('');
    kenzil = ko.observable({
        kanzleiname: ko.observable(''),
        kanzleistrasse: ko.observable(''),
        kanzleiaddition: ko.observable(''),
        kanzleiplz: ko.observable(''),
        kanzleiort: ko.observable(''),
        rain_telefon: ko.observable('+49'),
        kanzleiurl: ko.observable('www.'),
        rain_kinstitut: ko.observable(''),
        rain_kontoinhaber: ko.observable(''),
        rain_bic: ko.observable(''),
        rain_iban: ko.observable(''),
        rain_produkt: ko.observable('Testversion')
    });

    rainUser = ko.observable({
        rain_anrede: ko.observable(''),
        rain_vorname: ko.observable(''),
        rain_title: ko.observable(''),
        rain_name: ko.observable(''),
        rain_email: ko.observable(''),
        rain_username: ko.observable(''),
        rain_password: ko.observable(''),
        rain_password_c: ko.observable(''),
        rain_telefon: ko.observable(''),
        rain_mobile: ko.observable('+49')
    });
    recaptchResponse = ko.observable('');
    recaptchaLoading: boolean = true;
    hasRecaptchaLoaded: boolean = false;
    hasRecapthaExpired = ko.observable(false);
    defaultLocale: string = 'de';
    validCaptcha = ko.computed({
        owner: this,
        read: () => this.recaptchResponse() && !this.hasRecapthaExpired()
    });

    registerSuccessful = ko.observable(false);

    nextRegisterStep() {
        if (this.currentStep() === 1) {
            if (!this.registerValidation(1)) return;
            $("#kanzlei_content").hide();
            $("#kanzlei_step div").removeClass("visible");
            $("#person_content").show();
            $("#person_step .register-step-status").addClass("active");
            $("#person_step div").addClass("visible");
            $("#backtologin").hide();
            $("#previousStepButton").show();
            this.currentStep(2)
        } else if (this.currentStep() === 2) {
            if (!this.registerValidation(2)) return;
            $("#person_content").hide();
            $("#person_step div").removeClass("visible");
            $("#_content").show();
            $("#pay_step .register-step-status").addClass("active");
            $("#produkt_content2").show();
            $("#produkt_content2 div").addClass("visible");

            this.currentStep(3)
        }
        else if (this.currentStep() === 3) {
            if (!this.registerValidation(3)) return;
            this.anredeTitle(ko.toJS(this.rainUser().rain_anrede));
            if (ko.toJS(this.rainUser().rain_title) !== "Bitte wählen Sie") {
                let x = ko.toJS(this.rainUser().rain_anrede) + " " + ko.toJS(this.rainUser().rain_title);
                this.anredeTitle(x);
            }
            $("#payment_content2").hide();
            $("#payment_content2 div").removeClass("visible");
            $("#produkt_content2").hide();
            $("#produkt_content2 div").removeClass("visible");
            $("#summery_content").show();
            $("#summery_step .register-step-status").addClass("active");
            $("#summery_step div").addClass("visible");
            $("#nextStepButton").hide();
            $("#registersubmit").show();
            this.currentStep(4)
        }
    }

    previousRegisterStep() {
        $("#nextStepButton").show();
        if (this.currentStep() === 4) {
            $("#registersubmit").hide();
            $("#summery_content").hide();
            $("#summery_step div").removeClass("visible");
            $("#summery_step .register-step-status").removeClass("active");
            $("#produkt_content2").show();
            $("#produkt_content2 div").addClass("visible");

            this.currentStep(3)
        }
        else if (this.currentStep() === 3) {
            $("#produkt_content2").hide();
            $("#produkt_content2 div").removeClass("visible");
            $("#payment_content2").hide();
            $("#payment_content2 div").removeClass("visible");
            $("#_content").show();
            $("#pay_step .register-step-status").removeClass("active");
            $("#person_content").show();
            $("#person_step div").addClass("visible");

            this.currentStep(2)
        }
        else if (this.currentStep() === 2) {
            $("#person_content").hide();
            $("#person_step div").removeClass("visible");
            $("#kanzlei_content").show();
            $("#person_step .register-step-status").removeClass("active");
            $("#kanzlei_step div").addClass("visible");

            $("#previousStepButton").hide();
            $("#backtologin").show();
            this.currentStep(1)
        }
    }

    checkPassword() {
        $("#pc_validation_message").hide();
        if (!(/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/.test(ko.toJS(this.rainUser().rain_password).toString()))) {
            $("#pc_validation_message").show();
            $('#pc_message').html('Bitte mindestens 8 Zeichen und eine Kombination aus Kleinbuchstaben, Großbuchstaben, Ziffern und Sonderzeichen verwenden.')
        }
    }

    checkPasswordMatch() {
        var passwort = $("#rain_password").val();
        var passwort_wdh = $("#rain_password_c").val();
        $("#pc_validation_message").hide();
        if (passwort !== passwort_wdh) {
            $("#pc_validation_message").show();
            $("#pc_message").html("Die Passwörter stimmen nicht überein.")
        }
    }

    registerValidation(step) {
        var passwort = $("#rain_password").val();
        var passwort_wdh = $("#rain_password_c").val();
        var produkt = $("#produkt").val();
        var kreditinstitut = $("#kreditinstitut").val();
        var kontoinhaber = $("#kontoinhaber").val();
        var bic = $("#bic").val();
        var iban = $("#iban").val();


        if (step == 1) {
            if (!Utils.checkMultipleErrors(["kanzleiname", "kanzleistrasse", "kanzleiplz", "kanzleiort", "rain_telefon"], this.kenzil(), "", [Utils.checkString, Utils.checkPhone])) {
                $("#kc_validation_message").show();
                return false;
            }
            else {
                $("#kc_validation_message").hide();
                return true;
            }
        } else if (step == 2) {
            if (!Utils.checkMultipleErrors(["rain_anrede", "rain_vorname", "rain_name", "rain_email", "rain_username", "rain_password", "rain_password_c", "rain_mobile"], this.rainUser(), "", [Utils.checkString, Utils.checkPhone])) {
                $("#pc_validation_message").show();
                $("#pc_message").html("Es wurden nicht alle erforderlichen Eingabefelder ausgefüllt.")
                return false;
            }
            else {
                if (ko.toJS(this.rainUser().rain_password) === ko.toJS(this.rainUser().rain_password_c) && /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/.test(ko.toJS(this.rainUser().rain_password).toString())) {
                    $("#pc_validation_message").hide();
                    return true;
                }
                else {
                    if (passwort !== passwort_wdh) {
                        $("#pc_message").html("Die Passwörter stimmen nicht überein.")

                    } else {
                        $('#pc_message').html('Bitte mindestens 8 Zeichen und eine Kombination aus Kleinbuchstaben, Großbuchstaben, Ziffern und Sonderzeichen verwenden.')
                    }
                    $("#pc_validation_message").show();
                    return false;
                }
            }
        } else if (step == 3) {
            if (produkt === "Standard") {
                if (kreditinstitut === "" || kontoinhaber === "" || bic === "" || iban === "") {
                    $("#payment_validation_message").show();
                    return false;
                } else {
                    $("#payment_validation_message").hide();
                    return true;
                }
            }
            else
                return true;
        }
    }

    gotoLogin() {
        this.router.navigate('/login');
        localStorage.setItem('path', "");
    }

    gotoPerson() {
        this.currentStep(2)
        $("#summery_content").hide();
        $("#summery_step div").removeClass("visible");
        $("#summery_step .register-step-status").removeClass("active");

        $("#person_content").show();
        $("#person_step div").addClass("visible");

        $("#previousStepButton").show(); //Previous Step Button
        $("#nextStepButton").show();
        $("#registersubmit").hide();
    }

    gotoKenzil() {
        this.currentStep(1)
        $("#person_content").hide();
        $("#person_step div").removeClass("visible");
        $("#kanzlei_content").show();
        $("#person_step .register-step-status").removeClass("active");
        $("#kanzlei_step div").addClass("visible");
        $("#summery_content").hide();
        $("#summery_step div").removeClass("visible");
        $("#summery_step .register-step-status").removeClass("active");
        $("#previousStepButton").hide(); //Previous Step Button
        $("#backtologin").show();
        $("#registersubmit").hide();
        $("#nextStepButton").show();
    }

    checkKanzleiurl(value): string {
        return ['www.', '', null, undefined].indexOf(value) === -1 ? value: ''
    }

    async registerCourt() {
        if(!this.submitedRegister()) {
            if (!this.agreed() || !this.validCaptcha()) {
                $("#final_validation_message").show();
                return;
            }

            this.submitedRegister(true);

            const data: LawFirmRegistration = {
                LawFirmName: this.kenzil().kanzleiname(),
                Website: this.checkKanzleiurl(this.kenzil().kanzleiurl()),
                Address: this.kenzil().kanzleistrasse(),
                AdditionalAddress: this.kenzil().kanzleiaddition(),
                PostCode: this.kenzil().kanzleiplz(),
                Place: this.kenzil().kanzleiort(),
                Phone: this.kenzil().rain_telefon(),
                FormOfAddress: this.rainUser().rain_anrede(),
                Title: this.rainUser().rain_title(),
                Lastname: this.rainUser().rain_name(),
                Firstname: this.rainUser().rain_vorname(),
                Mobile: this.rainUser().rain_mobile(),
                Mail: this.rainUser().rain_email(),
                Username: this.rainUser().rain_username(),
                Password: this.rainUser().rain_password(),
                Admin: 1,
                Product: this.kenzil().rain_produkt(),
                AccountOwner: this.kenzil().rain_kontoinhaber(),
                CreditInstitution: this.kenzil().rain_kinstitut(),
                IBAN: this.kenzil().rain_iban(),
                BIC: this.kenzil().rain_bic()
            };

            RNSAPI.registerLawFirm(data).then(data => {
                this.registerSuccessful(true);
                this.kdNumber(data["LawFirmId"]);
                this.usNummer(data["Username"]);
                this.regEmail(data["Mail"]);
                localStorage.setItem('LawFirmInfo', JSON.stringify({
                    ShorthandSymbol: this.usNummer(),
                    LawFirmId: this.kdNumber()
                }));
            }).catch(error => {
                this.submitedRegister(false);
                $("#summery_content").show();
                if(error["responseJSON"].ErrorMessage === "Mail already registered") {
                    this.registerErrorMail(true);
                } else {
                    this.registerErrorFail(true);
                }
            });
        }
    }

    async cityFilter() {
        let result=await RNSAPI.getPostcode(this.kenzil().kanzleiplz());
        if (result.Type=="GetPostcodeResult" && result.Payload.Cities.length > 0) {
            this.kenzil().kanzleiort(result.Payload.Cities[0]);
        }
    }

    async generateShorthand() {
        if (ko.toJS(this.rainUser().rain_vorname) !== '' && ko.toJS(this.rainUser().rain_name) !== '') {
            this.rainUser().rain_username(ko.toJS(this.rainUser().rain_vorname)[0] + ko.toJS(this.rainUser().rain_name)[0]);
        }
    }

    agreement() {
        this.agreed(!this.agreed())
    }

    microtime(getAsFloat) {
        var s,
            now = (Date.now ? Date.now() : new Date().getTime()) / 1000;

        if (getAsFloat) {
            return now
        }

        s = now | 0

        return (Math.round((now - s) * 1000) / 1000) + ' ' + s
    }

    verifyCallback(response) {
        this.recaptchResponse(response);
        this.hasRecapthaExpired(false);
    }

    recaptchaExpiredCallback() {
        this.hasRecapthaExpired(true);
    }

    onloadCallback() {
        this.recaptchaLoading = false;
        this.hasRecaptchaLoaded = true;
        const gRecaptchaRef = $('.g-recaptcha').get(0);
        (<any>window).grecaptcha.render(gRecaptchaRef, {
            'sitekey': "6Lft7N0UAAAAALcGZXBlulSleV6iUrpIGesLHMWl",
            'callback': <any>((response: any) => this.verifyCallback(response)),
            'expired-callback': <any>(() => this.recaptchaExpiredCallback())
        });
    }

    displayRecaptcha() {
        var doc = <HTMLDivElement>document.getElementById('form');
        var script = document.createElement('script');
        script.innerHTML = '';
        script.src = `https://www.google.com/recaptcha/api.js?onload=reCaptchaOnloadCallback&render=explicit&hl=${this.defaultLocale}`; 
        script.async = true;
        script.defer = true;
        doc.appendChild(script);
    }

    changeTarif() {
        let selectedEl = (<HTMLSelectElement>document.getElementById("produkt")).value;
        if (selectedEl === "Testversion") {
            //TestversionInfo
            $("#TestversionInfo").show();
            $("#TestversionInfo div").addClass("visible");
            $("#payment_content2").hide();
            $("#payment_content2 div").removeClass("visible");
        }
        else {
            $("#TestversionInfo").hide();
            $("#TestversionInfo div").removeClass("visible");
            $("#payment_content2").show();
            $("#payment_content2 div").addClass("visible");
        }
    }

    constructor() {
        $(document).ready(function () {
            $("[data-toggle='tooltip']").tooltip();
        })
        window[<any>"reCaptchaOnloadCallback"] = <any>(() => this.onloadCallback());
        this.displayRecaptcha();
    }
}

var html = fs.readFileSync(__dirname + '/register.html', 'utf8');

ko.components.register("register-view", {
    viewModel: RegisterViewModel,
    template: html
});