import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment"
import { RNSAPI } from "../../api";
import { Utils } from "../../utils";

class AssignTagsViewModel {
    parentVM: any;
    currentId: ko.Observable<string>

    allTags = ko.observable({});
    allTagsKeys = ko.computed(() => Object.keys(this.allTags()));
    originalTagsInDocument: Array<any>;

    assignTags = async () => {
        let tags = Object.keys(this.allTags()).filter(k => this.allTags()[k]()).concat(this.originalTagsInDocument.filter(t => t.OnlyWFK).map(t => t.TagName));
        let id = this.currentId();

        let result = await RNSAPI.setTagsForECaseRecordId(tags, id);
        if (result.Type === 'AssignUnSuccessful') {
            alert("Fehler beim Zuweisen der Tags.");
        } else {
            $('#assignTags').modal('hide');
        }
    };

    async loadTags() {
        let tags = (await RNSAPI.getAllTagTypes()).Payload.Tags;
        let tagsObj = {};
        tags.filter(t => !t.OnlyWFK).forEach(t => {
            tagsObj[t.TagName] = ko.observable(false);
        });

        this.allTags(tagsObj);
    }

    async loadTagsForId(id: string) {
        await this.loadTags();

        this.originalTagsInDocument = (await RNSAPI.getTagsForECaseRecordId(id)).Payload.Tags;

        for (let tag of this.originalTagsInDocument.filter(t => !t.OnlyWFK)) {
            this.allTags()[tag.TagName](true);
        }
    }

    constructor(params: any) {
        if (params && params.id) {
            this.parentVM = params.vm;
            this.currentId = params.id;
            if (this.currentId()) this.loadTagsForId(this.currentId());

            this.currentId.subscribe(newId => {
                if (newId) this.loadTagsForId(newId);
            });
        }
    }
}

let html = fs.readFileSync(__dirname + '/assign.html', 'utf8');

ko.components.register("assigntags-view", {
    viewModel: AssignTagsViewModel,
    template: html
});