import '../node_modules/jquery/dist/jquery.js';
import '../node_modules/jwt-decode/build/jwt-decode.min.js';
import * as ko from 'knockout';
import Navigo = require("navigo");
import './components/dashboard/dashboard';
import './components/login/login';
import './components/reset/reset';
import './components/login/loginToken';
import './components/appointment/appointment';
import './components/appointment/appointment_modal';
import './components/deadline/overview';
import './components/deadline/deadline';
import './components/deadline/delegate';
import './components/timemonitoring/overview'
import './components/resubmission/overview';
import './components/case/case';
import './components/extended/new';
import './components/extended/dokumente';
import './components/case/fastcase';
import './components/case/easycase';
import './components/case/newcase';
import './components/case/overview';
import './components/case/caseentries';
import './components/case/caseentryoverview';
import './components/extended/extendedForm';
import './components/extended/extendedTabs';
import './components/extended/extendedMain';
import './components/tag/overview';
import './components/tag/tag';
import './components/view/overview';
import './components/view/view';
import './components/textTree/generation';
import './components/address/address';
import './components/address/overview';
import './components/address/address_form';
import './components/debug/debug';
import './components/note/note';
import './components/fee/calculator';
import './components/dictation/record';
import './components/zpe/mail';
import './components/zpe/compose';
import './components/zpe/inbox';
import './components/zpe/inboxoverview';
import './components/workflow/zpe';
import './components/tfw/tfw';
import './components/kalendar/kalendar';
import './components/kalendar/kalendaruser';
import './components/tree/tree';
import './components/tree/overview';
import './components/strategy/overview';
import './components/strategy/strategy';
import './components/taxonomie/taxonomie';
import './components/taxonomie/overview';
import './components/unit/addunit';
import './components/datamanagement/datamanagement';
import './components/settings/overview';
import './components/recherche/fachsuche';
import './components/dunning/overview';
import './components/register/register';
import './components/layout/layout';
import './components/layout/header';
import './components/unlock/unlock';
import './components/workflows/overview';
import './components/document/editor';
import './components/fee/rvg';
import { RNSAPI, Routing } from './api';
import { User } from './UserData';
import { Utils } from './utils';
import { V4MAPPED } from 'dns';
import { RolesAndRights } from './helpers/RolesAndRights';

export class MainViewModel {
    public currentRoute = ko.observable("login-view")
    public showSideNav = ko.observable(false)
    public currentParams = ko.observable(undefined)
    public showLogout = ko.observable(false);

    public logout() {
        MainViewModel.RoutingTable.showLoginView();
        vm.showLogout(false);
    }

    public router = new Navigo(null, true);
    public static paused = false;
    cookieExist = Utils.readCookie("access_token");

    constructor() {

        //MARK: - Public pages
        this.router.on(
            '/login', () => {
                this.currentRoute("login-view")
                this.showSideNav(false);
            },
            {
                before: (done, params) => { MainViewModel.disallowView(done) }
            }
        )
        this.router.on(
            '/reset', () => {
                this.currentRoute("reset-view")
                this.showSideNav(false);
            },
            {
                before: (done, params) => { MainViewModel.disallowView(done) }
            }
        )
        this.router.on(
            '/unlock', () => {
                this.currentRoute("unlock-view")
                this.showSideNav(false);
            },
            {
                before: (done, params) => { MainViewModel.disallowView(done) }
            }
        )
        this.router.on('/register', () => {
            this.currentRoute("register-view")
            this.showSideNav(false);
        },
            {
                before: (done, params) => { MainViewModel.disallowView(done) }
            }
        )

        //MARK: - Private pages

        this.router.on(
            '/', () => this.router.navigate('/todo'), {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )
        this.router.on(
            '/', () => this.router.navigate('/dashboard'), {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )
        this.router.on(
            '/cases', () => this.currentRoute("caseoverview-view"), {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )
        this.router.on(
            '/cases/:all', (params) => {
                this.currentRoute("caseoverview-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/logintoken', () => this.currentRoute("loginToken-view"), {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/new/:caseId', (params) => {
                this.currentRoute('new-view');
                this.currentParams(params);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/new_modal', () => (params) => {
                this.currentRoute("new_modal-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
            }
        }
        )

        this.router.on(
            '/extended-main/:caseId', () => (params) => {
                this.currentRoute("extended-main-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/fastcase', () => this.currentRoute("fastcase-view"), {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/easycase', () => this.currentRoute("easycase-view"), {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/newcase', () => this.currentRoute("newcase-view"), {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/document/editor', () => { 
                this.currentRoute("document-editor-view");
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        // this.router.on(
        //     '/document/editor/:docId', (params) => {
        //         this.currentRoute("document-editor-view");
        //         this.currentParams(params);
        //     }, {
        //     before: (done, params) => {
        //         MainViewModel.allowView(done)
        //         this.showSideNav(true);
        //     }
        // }
        // )

        this.router.on(
            '/document/editor/:caseId/:docId', (params) => {
                this.currentRoute("document-editor-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/document/editor/:caseId', (params) => {
                this.currentRoute("document-editor-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/case', () => (params) => {
                this.currentRoute("case-view");
                this.currentParams(undefined);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/case/:caseId', (params) => {
                this.currentRoute("case-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/unit/add', () => { this.currentRoute("addunit-view") }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/caseentry/:caseId', (params) => {
                this.currentRoute("caseentry-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/caseentry/:caseId/:eCaseRecordId', (params) => {
                this.currentRoute("caseentry-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/caseentry/:caseId/:eCaseRecordId', (params) => {
                this.currentRoute("caseentryoverview-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/caseentries', (params) => {
                this.currentRoute("caseentryoverview-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/caseentries/:caseId', (params) => {
                this.currentRoute("caseentries-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/caseentries/:caseId/:eCaseRecordId', (params) => {
                this.currentRoute("caseentries-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/tags', (params) => this.currentRoute("tagoverview-view"), {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/tag/:name', (params) => {
                this.currentRoute("tag-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/tag', (params) => {
                this.currentRoute("tag-view");
                this.currentParams(undefined);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/views', (params) => this.currentRoute("viewoverview-view"), {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/view/:name', (params) => {
                this.currentRoute("view-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/view', (params) => {
                this.currentRoute("view-view");
                this.currentParams(undefined);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/addresses', () => this.currentRoute("addressoverview-view"), {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/addresses', () => this.currentRoute("addressoverview-view"), {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/address', () => {
                this.currentRoute("address-view");
                this.currentParams(undefined);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/address/:type/:keyword', (params) => {
                this.currentRoute("address-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/address_form/:id', (params) => {
                this.currentRoute("address_form-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/appointment', () => {
                this.currentRoute("appointment-view");
                this.currentParams({ startdate: Utils.getParameterByName("startdate", window.location.href) });
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/appointment/:id', (params) => {
                this.currentRoute("appointment-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/appointment_modal', () => {
                this.currentRoute("appointment_modal-view");
                this.currentParams({ startdate: Utils.getParameterByName("startdate", window.location.href) });
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/appointment_modal/:id', (params) => {
                this.currentRoute("appointment_modal-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/deadline', (params) => {
                this.currentRoute("deadline-view");
                this.currentParams(undefined);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/resubmissions', () => {
                this.currentRoute("resubmissionoverview-view");
                this.currentParams(undefined);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/resubmissions',
            () => {
                this.currentRoute("resubmissionoverview-view");
                this.currentParams(undefined);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/deadlines',
            () => {
                this.currentRoute("deadlineoverview-view")
                this.currentParams(undefined);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/dashboard', () => {
                this.currentRoute("dashboard-view")
                this.currentParams({ view: "todos" });
                this.showSideNav(true)
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/dashboard/:view', (params) => {
                if (!MainViewModel.paused) {
                    this.currentRoute("dashboard-view");
                    this.currentParams(params);
                }
                MainViewModel.paused = false;
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )
        this.router.on(
            '/generation/:caseId',
            async (params) => {
                this.currentRoute("generation-view");
                this.currentParams({ caseId: params.caseId });
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/generation',
            async () => {
                this.currentRoute("generation-view");
                this.currentParams();
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/calculator',
            async () => {
                this.currentRoute("calculator-view");
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/timemonitoring',
            async () => {
                this.currentRoute("timemonitoringov-view");
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/note',
            async () => {
                this.currentRoute("note-view");
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/note/:id',
            async (params) => {
                this.currentRoute("note-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/dictation',
            async () => {
                this.currentRoute("record-view");
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/dictation/:id',
            async (params) => {
                this.currentRoute("record-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/compose',
            async (params) => {
                this.currentRoute("compose-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/compose/:caseId',
            async (params) => {
                this.currentRoute("compose-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/compose/:caseId/:eCaseRecordId',
            async (params) => {
                this.currentRoute("compose-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/mail/:accountId/:mailId',
            async (params) => {
                this.currentRoute("mail-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/inbox',
            async () => this.currentRoute("inbox-view"), {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/inbox/:id',
            async (params) => {
                this.currentRoute("inbox-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/inboxes',
            async () => {
                this.currentRoute("inboxoverview-view");
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/zpe',
            async () => {
                this.currentRoute("zpe-view");
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/workflows',
            async () => {
                this.currentRoute("workflows-view");
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/workflows/PE', async () => {
                this.currentRoute("workflows-view");
                this.currentParams({ workflow: "PE" });
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/workflows/PA', async () => {
                this.currentRoute("workflows-view");
                this.currentParams({ workflow: "PA" });
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/workflows/V', async () => {
                this.currentRoute("workflows-view");
                this.currentParams({ workflow: "V" });
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/tree/:type/:id',
            async (params) => {
                this.currentRoute("tree-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/tree',
            async () => {
                this.currentRoute("tree-view");
                this.currentParams(undefined);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/trees',
            async () => {
                this.currentRoute("treeoverview-view");
                this.currentParams(undefined);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/strategy',
            async () => {
                this.currentRoute("strategy-view");
                this.currentParams(undefined);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/strategies',
            async () => {
                this.currentRoute("strategyoverview-view");
                this.currentParams(undefined);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/taxonomie',
            async () => {
                this.currentRoute("taxonomie-view");
                this.currentParams(undefined);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/taxonomie/:id',
            async (params) => {
                this.currentRoute("taxonomie-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/taxonomies',
            async () => {
                this.currentRoute("taxonomieoverview-view");
                this.currentParams(undefined);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/letterhead/upload',
            async () => {
                this.currentRoute("letterhead-upload-view");
                this.currentParams(undefined);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/datamanagement',
            () => {
                this.currentRoute("datamanagement-view");
                this.currentParams({ view: "units" });
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/datamanagement/:view',
            (params) => {
                if (!MainViewModel.paused) {
                    this.currentRoute("datamanagement-view");
                    this.currentParams(params);
                }
                MainViewModel.paused = false;
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/settings',
            () => {
                this.currentRoute("settings-view");
                this.currentParams({ view: "showUsers" });
            }, {
            before: (done, params) => {
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/settings/:view', (params) => {
                if (!MainViewModel.paused) {
                    this.currentRoute("settings-view");
                    this.currentParams(params);
                }
                MainViewModel.paused = false;
            }, {
            before: (done, params) => {
                MainViewModel.allowView(done, params)
                this.showSideNav(true);
            }
        }
        )
        this.router.on(
            '/tfw',
            () => {
                this.currentRoute("tfw-view");
                this.currentParams({ view: "showTfw" });
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/tfw/:view', (params) => {
                if (!MainViewModel.paused) {
                    this.currentRoute("tfw-view");
                    this.currentParams(params);
                }
                MainViewModel.paused = false;
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done, params)
                this.showSideNav(true);
            }
        }
        )
        this.router.on(
            '/kalendar',
            () => {
                this.currentRoute("kalendar-view");
                this.currentParams({ view: "showKalendar" });
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/kalendar/:view', (params) => {
                if (!MainViewModel.paused) {
                    this.currentRoute("kalendar-view");
                    this.currentParams(params);
                }
                MainViewModel.paused = false;
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done, params)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/kalendaruser',
            () => {
                this.currentRoute("kalendaruser-view");
                this.currentParams({ view: "showKalendar" });
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/recherche',
            () => {
                this.currentRoute("fachsuche-view");
                this.currentParams(undefined);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/dunning',
            () => {
                this.currentRoute("dunning-view");
                this.currentParams(undefined);
            }, {
            before: (done, params) => {
                if(RolesAndRights.isLicenseExpired()) {
                    MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                    return;
                }
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )
        this.router.notFound((query) => {
            console.log('Show 404 page', query)
            this.router.navigate('/dashboard')
        });

        this.router.resolve()

        let cookie = Utils.readCookie("access_token");
        if (cookie === null) {
            MainViewModel.RoutingTable.showLoginView();
        } else {
            RNSAPI.Token = cookie;
            const decodedUser = RNSAPI.getCurrentLoggedInUser();
            if(decodedUser) {
                RNSAPI.User(new User(decodedUser.ShortHandSymbol, decodedUser.Role));
            } else {
                Utils.eraseCookie('access_token');
            }
        }

    }//end of constructor

    public static allowView(cb, params?) {
        let cookie = Utils.readCookie("access_token");
        if (cookie === null) {
            MainViewModel.RoutingTable.showLoginView();
            cb(false)
        } else {
            cb(true)
        }
    }

    public static disallowView(cb, params?) {

        let cookie = Utils.readCookie("access_token");
        if (cookie === null) {
            cb(true)
        } else {
            // this.router.navigate('/dashboard')
            MainViewModel.RoutingTable.showDashboardView()
            cb(false)
        }
    }

    public static RoutingTable = {
        generateLink: function (route: string) {
            return vm.router.link('').indexOf('#') !== -1 ? vm.router.link(route) : vm.router.link('#' + route)
            // return vm.router.link(route);
        },
        showCaseView: function (params?: any) {
            if (params) vm.router.navigate(`/fastcase/${encodeURIComponent(params.caseId)}`);
            else vm.router.navigate("/fastcase");
        },
        showNewCaseView: function (params?: any) {
            if (params) vm.router.navigate(`/newcase/${encodeURIComponent(params.caseId)}`);
            else vm.router.navigate("/newcase");
        },
        showCaseOverview: function () { vm.router.navigate("/cases") },
        showCaseEntriesView: function (params: any) {
            if (params.eCaseRecordId) vm.router.navigate(`/caseentries/${encodeURIComponent(params.caseId)}/${encodeURIComponent(params.eCaseRecordId)}`)
            else vm.router.navigate(`/caseentries/${encodeURIComponent(params.caseId)}`)
        },
        showCaseEntryView: function (params: any) {
            if (params.eCaseRecordId) vm.router.navigate(`/caseentry/${encodeURIComponent(params.caseId)}/${encodeURIComponent(params.eCaseRecordId)}`)
            else vm.router.navigate(`/caseentry/${encodeURIComponent(params.caseId)}`)
        },
        showTagOverview: function () { vm.router.navigate("/tags"); },
        showTagView: function (params?: any) {
            if (params && params.name) vm.router.navigate(`/tag/${encodeURIComponent(params.name)}`);
            else vm.router.navigate("/tag");
        },
        showViewOverview: function () { vm.router.navigate("/views"); },
        showViewView: function (params?: any) {
            if (params && params.name) vm.router.navigate(`/view/${encodeURIComponent(params.name)}`);
            else vm.router.navigate("/view");
        },
        showTreeOverview: function () { vm.router.navigate("/trees"); },
        showTreeView: function (params?: any) {
            if (params && params.id && params.type) vm.router.navigate(`/tree/${encodeURIComponent(params.type)}/${encodeURIComponent(params.id)}`);
            else vm.router.navigate("/tree");
        },
        showStrategyOverview: function () { vm.router.navigate("/strategies"); },
        showStrategyView: function () { vm.router.navigate("/strategy"); },
        showTaxonomieOverview: function () { vm.router.navigate("/taxonomies"); },
        showTaxonomieView: function (params?: any) {
            if (params && params.id) vm.router.navigate(`/taxonomie/${encodeURIComponent(params.id)}`);
            else vm.router.navigate("/taxonomie");
        },
        showDatamanagementView: function (params?: any, ignore = false) {
            if (ignore) MainViewModel.paused = true;
            if (params) {
                vm.router.navigate(`/datamanagement/${params.view}`);
            }
            else vm.router.navigate("/datamanagement");
        },
        showDocumentEditorView: function (params?: any, ignore = false) {
            if (ignore) MainViewModel.paused = true;
            if (params) {
                if(params.docId && params.caseId) {
                    vm.router.navigate(`/document/editor/${encodeURIComponent(params.caseId)}/${params.docId}`);
                } else if(params.caseId) {
                    vm.router.navigate(`/document/editor/${encodeURIComponent(params.caseId)}`);
                } 
            }
            else vm.router.navigate("/document/editor");
        },
        showSettingsView: function (params?: any, ignore = false) {
            if (ignore) MainViewModel.paused = true;
            if (params) {
                vm.router.navigate(`/settings/${params.view}`);
            }
            else vm.router.navigate("/settings");
        },
        showTfwView: function (params?: any, ignore = false) {
            if (ignore) MainViewModel.paused = true;
            if (params) {
                vm.router.navigate(`/tfw/${params.view}`);
            }
            else vm.router.navigate("/tfw");
        },
        showKalendarView: function (params?: any, ignore = false) {
            if (ignore) MainViewModel.paused = true;
            if (params) {
                vm.router.navigate(`/kalendar/${params.view}`);
            }
            else vm.router.navigate("/kalendar");
        },
        showDashboardView: function (params?: any, ignore = false) {
            if (ignore) MainViewModel.paused = true;
            if (params && params.viewType) {
                vm.router.navigate(`/dashboard/${params.viewType}`);
            }
            if (params) {
                vm.router.navigate(`/dashboard/${params.view}`);
            }
            else vm.router.navigate("/dashboard");
        },
        showAppointmentView: function (params?: any) {
            if (params) {
                // if (params.id) {
                //     vm.router.navigate(`/appointment/${params.id}`);
                // } else if (params.startdate) {
                //     vm.router.navigate(`/appointment?startdate=${encodeURIComponent(params.startdate)}`);
                // }
                if (params.startdate) {
                    vm.router.navigate(`/appointment?startdate=${encodeURIComponent(params.startdate)}`);
                }
            }
            else vm.router.navigate("/appointment");
        },
        showAppointmentModalView: function (params?: any) {
            if (params) {
                if (params.id) {
                    vm.router.navigate(`/appointment_modal/${params.id}`);
                } else if (params.startdate) {
                    vm.router.navigate(`/appointment_modal?startdate=${encodeURIComponent(params.startdate)}`);
                }
            }
            else vm.router.navigate("/appointment");
        },
        showDeadlineView: function (params?: any) {
            if (params) vm.router.navigate(`/deadline/${params.id}`);
            else vm.router.navigate("/deadline");
        },
        showDeadlineOverview: function () { vm.router.navigate("/deadlines") },
        showResubmissionOverview: function () { vm.router.navigate("/resubmissions"); },
        showResubmissionView: function (params?: any) {
            if (params && params.caseId) vm.router.navigate(`/resubmission?caseId=${encodeURIComponent(params.caseId)}`);
            else if (params) vm.router.navigate(`/resubmission/${params.id}`);
            else vm.router.navigate("/resubmission");
        },
        showAddressView: function (params?: any) {
            if (params) vm.router.navigate(`/address/${params.type}/${encodeURIComponent(params.keyword)}`);
            else vm.router.navigate("/address");
        },
        showAddressOverview: function () { vm.router.navigate("/addresses"); },
        showTextTemplateView: function (params?: any) { vm.router.navigate(`/generation/${encodeURIComponent(params.caseId)}`); },
        showNoteView: function (params?: any) {
            if (params) vm.router.navigate(`/note/${encodeURIComponent(params.id)}`);
            else vm.router.navigate("/note");
        },
        showMailView: function (params: any) {
            vm.router.navigate(`/mail/${encodeURIComponent(params.accountId)}/${encodeURIComponent(params.mailId)}`);
        },
        showInboxView: function (params: any) {
            if (params) vm.router.navigate(`/inbox/${encodeURIComponent(params.id)}`);
            else vm.router.navigate(`/inbox/`);
        },
        showComposeView: function (params: any) {
            if (params && params.caseId && params.eCaseRecordId) vm.router.navigate(`/compose/${encodeURIComponent(params.caseId)}/${encodeURIComponent(params.eCaseRecordId)}`);
            else if (params && params.caseId) vm.router.navigate(`/compose/${encodeURIComponent(params.caseId)}`);
            else vm.router.navigate(`/compose`);
        },
        showInboxOverview: () => vm.router.navigate(`/inboxes/`),
        showZPE: () => vm.router.navigate(`/zpe`),
        showDictation: function (params?: any) {
            if (params && params.id) vm.router.navigate(`/dictation/${encodeURIComponent(params.id)}`);
            else vm.router.navigate(`/dictation/`);
        },
        showCalculatorView: function () { vm.router.navigate("/calculator"); },
        showLoginView: () => {

            //if (window.location.hostname === 'localhost' || window.location.hostname === 'dev.olyro.de' || window.location.hostname === 'dev.intelli4pro.de') {
            window.location.href = "./#/login";
            //} else {
            //    window.location.href = "./login";
            //}
            // console.log(this.showSideNav())
            // this.showSideNav(false);
        },
        showRegisterView: function () {
            vm.router.navigate(`/register`)
            // this.showSideNav(false);
        },
        showResetView: function () {
            vm.router.navigate(`/reset`)
            // this.showSideNav(false);
        },
        showWorkflowView: (wfk: string) => vm.router.navigate(`/wfk/${wfk}`),
        showDebugView: function () { vm.currentRoute("debug-view"); },
        showExtendedMainView: function (params?: any) {
            if (params) {
                vm.router.navigate(`/extended-main/${encodeURIComponent(params.caseId)}`);
            }
            else vm.router.navigate("/dashboard");
        },
        showNewView: function (params?: any) {
            if (params) {
                vm.router.navigate(`/new/${encodeURIComponent(params.caseId)}`);
            }
            else vm.router.navigate("/dashboard");
        },
        showUnitAdd: () => vm.router.navigate(`/unit/add`),
        showDatamanagementOverview: function () { vm.router.navigate("/datamanagement"); },
        setOrigin: function (origin: string) { RNSAPI.origin = origin; },
        showSettings: () => vm.router.navigate(`/settings`)
    }
}

let vm = new MainViewModel();

ko.applyBindings(vm);

module.exports = MainViewModel.RoutingTable;
