import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment"
import { RNSAPI } from "../../api";
import { MainViewModel } from "../../main";
import { DateTime } from 'luxon';
import { RolesAndRights } from "../../helpers/RolesAndRights";

class AppointmentWeeklyViewModel {
    appointments = ko.observableArray([]);
    deadlines = ko.observableArray([]);
    resubmissions = ko.observableArray([]);
    timeslots = ko.observableArray([]);
    lanes: ko.Computed<Array<Array<Array<any>>>>;
    parentVM: any;
    date: ko.Observable<moment.Moment>;
    weekdays: ko.Computed<Array<any>>;
    hideoncreate = ko.observable();
    startMom = ko.observable("");
    weeklyAppointment = ko.observable(false);
    appointmentMode = ko.observable();
    appointmentId = ko.observable();
    appointmentData = ko.observable();
    currentDate = ko.observable();
    start = 0;
    end = 24;
    wholeDayAppointment = ko.observableArray([]);
    showDeadlineperDay = ko.observableArray([]);
    predeadlines = ko.observableArray([]);
    currentAppointment: any;
    readonly margin = "70";
    hoverId = ko.observable(null);
    dayCount = ko.observable(5);

    isViewerRole = ko.observable<boolean>(false);

    toggle = (index) => {
        this.hoverId(index)
        setTimeout(() => { this.hoverId(-1); }, 5000);

    }

    private static pad(n, width) {
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
    }

    public left(appointment: any, lane: number, nrOfLanes: number) {
        let startDate = moment.utc(appointment.AppointmentDate);
        let day = startDate.day() !== 0 ? startDate.day() : 7;
        return `calc((100% - ${this.margin}px) / ${this.dayCount()} * ${(day - 1) + lane / nrOfLanes} + ${this.margin}px)`;
    }

    public width(length: number) {
        return `calc(((100% - ${this.margin}px) / ${this.dayCount()}) / ${length})`;
    }

    public topWidth() {
        let genwidth = document.getElementById("weekdays_div").scrollWidth;
        let elwidth = (genwidth - 10 * this.dayCount()) / this.dayCount();
        return `${elwidth}px`;
    }

    public sepLeft(i: number): string {
        return `calc((100% - ${this.margin}px) / ${this.dayCount()} * ${i} + ${this.margin}px)`;
    }

    public top(appointment: any) {
        let m = DateTime.fromISO(appointment.StartDate);
        let offset = m.hour - this.start;
        offset += m.minute / 60;
        return `${100 * offset / (this.end - this.start)}%`;
    }

    public ishalfhour(data: any) {
        if (data.toString().includes(':30')) {
            return true;
        }
    }

    public height(appointment: any) {
        let startDate = moment.utc(appointment.StartDate);
        let endDate = moment.utc(appointment.EndDate);
        let percentage = 100 * (endDate.diff(startDate, 'minutes') / 60 / (this.end - this.start));
        return `${percentage}%`;
    }

    public id(appointment: any) {
        return `${appointment.Id}`
    }

    public deleteAppointment = async (appointment: any) => {
        this.currentAppointment = appointment;
        $("#DeleteMail").modal('show');
    }

    public actuallydelete = async () => {
        await RNSAPI.deleteAppointment(this.currentAppointment.Id);
        this.parentVM.update();
        $("#DeleteMail").modal('hide');
    }

    public createAppointment() {
        let oldTimeStamp: number = null;
        let oldHour: String = null;
        return (hour: String, event: MouseEvent) => {
            let x = event.pageX;
            let date = this.date().clone();
            for (let sep of $("div.weekday-sep").toArray()) {
                if (x < sep.getBoundingClientRect().left) break;
                date.add(1, 'days');
            }

            if (oldTimeStamp && oldHour && ((event.timeStamp - oldTimeStamp) < 250 && oldHour === hour)) {
                let startMoment = date.add(moment.duration(hour as any));
                this.currentDate(startMoment)
            } else {
                oldTimeStamp = event.timeStamp;
                oldHour = hour;
            }
        }
    }

    setCurrentDate(data, event) {
        let x = event.pageX;
        let date = this.date().clone();
        for (let sep of $("div.weekday-sep").toArray()) {
            if (x < sep.getBoundingClientRect().left) break;
            date.add(1, 'days');
        }
        let startMoment = date.add(moment.duration(data as any));
        this.currentDate(startMoment)
    }

    public ShowAppointmentModal = async (type, data) => {
        if(!this.isViewerRole()) {
            this.weeklyAppointment(false);
            setTimeout(() => {
                this.weeklyAppointment(true)
            }, 10);
            this.appointmentMode(type);
            this.appointmentData({
                startDate: this.currentDate(),
                time: data
            });
            setTimeout(() => {
                $("#AppointmentWeeklymodal").modal("show");
            }, 10);
        }
    }

    public isOwned = (clerk) => {
        return (clerk === RNSAPI.User().username)
    }

    private constructDay(appointments: Array<any>) {
        let lanes: Array<Array<any>> = [[]];
        this.wholeDayAppointment([]);
        for (let appointment of appointments) {
            let start = moment.utc(appointment.AppointmentDate);
            let inserted = false;
            for (let lane of lanes) {
                if (lane.length === 0 || moment(lane[lane.length - 1].AppointmentDate).isBefore(start)) {
                    if (appointment.StartDate.endsWith("00:00:00") && appointment.EndDate.endsWith("23:59:00")) {
                        this.wholeDayAppointment.push(appointment);
                    }
                    else
                        lane.push(appointment);
                    inserted = true;
                    break;
                }
            }

            if (!inserted) {
                lanes.push([appointment]);
            }
        }

        return lanes;
    }

    constructor(params) {
        this.isViewerRole(RolesAndRights.isViewerRole());

        let arr = [];
        this.hideoncreate((params && params.hideoncreate) ? true : false);
        let count = (this.end - this.start) * 2

        for (let i = 0; i < count; i++) {
            let hour = AppointmentWeeklyViewModel.pad(Math.floor(i / 2) + this.start, 2);
            if (i % 2 == 0) {
                arr.push(`${hour}:00`);
            } else {
                arr.push(`${hour}:30`);
            }
        }

        this.timeslots(arr);

        if (params.wholeWeek) {
            this.dayCount(7);
        }

        this.date = params.date;

        this.appointments = params.appointments;
        this.deadlines = params.deadlines;
        this.resubmissions(params.resubmissions);
        this.parentVM = params.vm;

        this.lanes = ko.computed(() => {
            let weekStart = this.date().clone();
            let appointments = this.appointments().sort((a, b) => {
                let startA = moment.utc(a.StartDate);
                let startB = moment.utc(b.StartDate);
                if (startA.isBefore(startB)) return -1;
                else if (startA.isAfter(startB)) return 1;
                else return 0;
            });

            return [1, 2, 3, 4, 5, 6].map(i => {                
                return this.constructDay(appointments);
            });
        });

        this.weekdays = ko.computed({
            owner: this,
            read: () => {
                let start = this.date();
                let weekdays = [];
                let appointments = ko.toJS(this.wholeDayAppointment);
                let deadlines = ko.toJS(this.deadlines);
                let resubmissions = ko.toJS(this.resubmissions);

                for (let i = 0; i < this.dayCount(); i++) {
                    let text = start.clone().add(i, "days").format("dd, DD.MM");
                    let date = start.clone().add(i, "days").format("DD.MM.YYYY");
                    let wholeOn = [];
                    let wholeDe = [];
                    let wholeRe = [];

                    for (let whole of appointments) {
                        if (date === moment(whole.AppointmentDate).format("DD.MM.YYYY"))
                            wholeOn.push(whole);
                    }

                    for (let whole of deadlines) {
                        if (date === whole.Deadline)
                            wholeDe.push(whole)
                    }

                    for (let whole of resubmissions) {
                        if (date === whole.DeadLineDate)
                            wholeRe.push(whole)
                    }

                    let ap = {
                        day: text,
                        appointments: wholeOn,
                        deadlines: wholeDe,
                        resubmissions: wholeRe
                    }

                    weekdays.push(ap);
                }

                return weekdays;
            }
        });

        $("div, scrollelement").animate({ scrollTop: 575 }, "fast");
        setTimeout(() => { this.toggle(-1); }, 1000);
    }
}

let html = fs.readFileSync(__dirname + '/weekly.html', 'utf8');

ko.components.register("weekly-view", {
    viewModel: AppointmentWeeklyViewModel,
    template: html
});
