import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment"
import { RNSAPI } from "../../api";
import { Stamp } from "./stamps";

class StampViewModel {
    parentVM: any;
    currentId = ko.observable("");
    stampTypes = ko.observableArray(["Regular", "Error", "Warning"]);
    stampType = ko.observable("Regular");
    stampText = ko.observable("");

    createStamp = async () => {
        let result = await RNSAPI.addStamp(this.stampText(), this.stampType(), this.currentId());
        if (result.Type === "AddSuccessful") {
            $('#createStamp').modal('hide');
            this.parentVM.updateStamps();
        } else {
            alert("Fehler beim Erstellen des Stempels.")
        }
    };

    constructor(params: any) {
        this.parentVM = params.vm;
        this.currentId = params.currentId;
    }
}

let html = fs.readFileSync(__dirname + '/stamp.html', 'utf8');

ko.components.register("stamp-view", {
    viewModel: StampViewModel,
    template: html
});