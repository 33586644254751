import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI, Routing } from "../../api";

import { DataTableViewModel, IntermediateListItem, Color } from '../dataTable/dataTable';

import { MainViewModel } from "../../main";
import { Utils } from "../../utils";


export class DunningViewModel {

    reminders = ko.observableArray([]);


    async updateReminders() {

        let result = await RNSAPI.getReminders();
        if (result.Type === "GetReminderSuccessful") {

            this.reminders(result.Payload.Reminders.map((c) => {
                c.actionHandlers = [
                    {
                        icon: "pencil-alt", name: "Bearbeiten", action: async () => {
                            //Edit an entry
                        }
                    },
                    {
                        icon: "trash-alt", name: "L�schen", action: async () => {
                            //Delete an entry
                        }
                    }
                ];
                return c;
            }));

        }

    }

    constructor() {

        this.updateReminders();

    }

}


let html = fs.readFileSync(__dirname + '/overview.html', 'utf8');

ko.components.register("dunning-view", {
    viewModel: DunningViewModel,
    template: html
});
