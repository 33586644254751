import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI, Routing } from "../../api";

class DebugViewModel {
    selectedTable = ko.observable(Object.keys(DebugViewModel.getTables())[0]);
    availableTables = ko.observable(Object.keys(DebugViewModel.getTables()));
    tableKeys = ko.observableArray([]);
    parsed = ko.observableArray([]);

    async loadData(dbNumber: number) {
        try {
            let result = await RNSAPI.getTableDump(dbNumber);
            let parsed = JSON.parse(result.Payload.JsonDump);
            this.parsed(parsed);
            if (parsed.length > 0) {
                this.tableKeys(Object.keys(parsed[0]));
            }
        } catch (e) {
            alert("Verbindung zum Server abgebrochen: " + JSON.stringify(e));
        }
    }

    constructor(params: any) {
        let firstTable = Object.keys(DebugViewModel.getTables())[0];
        this.selectedTable.subscribe(table => {
            console.log("test");
            let id = DebugViewModel.getTables()[table];
            this.loadData(id);
        });
        this.selectedTable(firstTable);
    }

    public static getTables(): any {
        return {
            "AKTENHISTORIE"                 : 0,
            "AKTE"                          : 1,
            "TEXTE_BENUTZER"                : 2,
            "TEXTE_EXPERTEN"                : 3,
            "EBENE_BENUTZER"                : 4,
            "EBENE_EXPERTEN"                : 5,
            "MAILS"                         : 6,
            "SACHBEARBEITER"                : 7,
            "AKTE_SACHBEARBEITER"           : 8,
            "MANDANT_OR_BETILIGTE"          : 9,
            "AKTE_MANDA_BET"                : 10,
            "BANKEN"                        : 11,
            "DICTATE_NEW"                   : 12,
            "TYPIST"                        : 13,
            "SCANS"                         : 14,
            "AKTE_GEGNER"                   : 15,
            "JOURNAL"                       : 16,
            "AKTE_SONSTIGE"                 : 17,
            "MAIL_INBOX"                    : 18,
            "SACHBEARBEITER_NO_RNS"         : 19,
            "ECONSULT_ACC"                  : 20,
            "GERICHTE"                      : 21,
            "MANDANT"                       : 22,
            "GEGNER"                        : 23,
            "TERMINE"                       : 24,
            "SONSTIGE_BETEILIGTE"           : 109,
            "HEAD1"                         : 66,
            "REFERATE"                      : 71,
            "RECHTSFORMEN"                  : 90,
            "ANREDEN"                       : 77,
            "VERSICHERUNGEN"                : 50,
            "BEHOERDEN"                     : 70,
            "NOTARTBETEILIGTE"              : 43,
            "ANWALT"                        : 60,
            "DEADLINE"                      : 25,
            "DEADLINE_REASONS"              : 75,
            "WORKFLOWMARK"                  : 266,
            "EPOST_ACCOUNT"                 : 700,
            "USER2EPOSTACCOUNT"             : 701,
            "MAILDB"                        : 400,
            "ZEITGTYP"                      : 83,
            "RNSTAGTYPE"                    : 30,
            "RNSTAGTONISTOYPE"              : 31,
            "RNSTAGTOHISTOYPE"              : 32,
            "PAPER"                         : 602,
            "ANWALTSWIEDERVORLAGE"          : 27,
            "NOTARWIEDERVORLAGE"            : 39,
            "FRISTEN"                       : 73,
            "DIENSTLEISTUNGSKOSTEN"         : 33,
            "POSTAUSGANGSBUCH"              : 29,
            "NOTARAKTE_TO_GERICHT"          : 55,
            "NEW_ECONSULTTYPE"              : 502,
            "NEW_EMAILACCOUNTTYPE"          : 503,
            "NEW_ECONSULTTOEMAILTYPE"       : 504,
            "NEW_USERYPE"                   : 505,
            "NEW_USERTOEMAILYPE"            : 506,
            "NEW_USERTOECONSULT"            : 507,
            "NEW_BEAACCOUNT"                : 508,
            "NEW_USERTOBEAACCOUNT"          : 509,
            "NEW_USERROLES"                 : 510,
            "NEW_DREBISACCOUNTTYPE"         : 511,
            "NEW_USER2DREBISACCOUNT"        : 512,
            "DEPARTMENT"                    : 600,
            "USER2DEPARTMENT"               : 601,
            "FOLDERS"                       : 520,
            "USER2SCANPOOL"                 : 521,
            "NEW_PROVENEXPERTACCOUNTTYPE"   : 522,
            "NEW_USERTOPROVENEXPERTACCOUNT" : 523,
            "NEW_UMFRAGETOSTAMMDATEN"       : 524,
            "KOSTENSTELLEN"                 : 182
        };
    }
}

let html = fs.readFileSync(__dirname + '/debug.html', 'utf8');

ko.components.register("debug-view", {
    viewModel: DebugViewModel,
    template: html
});
