import { Utils } from "../../utils";
import * as moment from "moment";
import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { Postbox } from '../dashboard/postbox'

class Day {
    day: number;
    date: moment.Moment;
    old: boolean;
    today: boolean;
    selected = ko.observable();

    constructor(day: number, date: moment.Moment) {
        this.day = day;
        this.date = date;
        this.selected(false);
        if (date) {
            let today = moment.utc().startOf("day");
            this.old = today.isAfter(date);
            this.today = today.isSame(date);
        } else {
            this.old = false;
            this.today = false;
        }
    }
}

class CalendarViewModel {
    weeks: ko.ObservableArray<Array<Day>> = ko.observableArray([]);
    parentVM: any;
    startOfMonth: moment.Moment;
    monthText = ko.observable("");
    days: any;
    cardinality: any;

    public update = (date: moment.Moment) => {
        this.parentVM.days.forEach(element => {
            if(element.selected){
                element.selected(false);
            }
        });
        this.days.forEach(element => {
            if(element.date == date && !element.today){
                element.selected(true);
            }
        });
        Postbox.publisher().publish('clicked', 'changeCalendar')
        this.parentVM.pickedWeekDate(date);
    }

    nextMonth = () => {
        this.startOfMonth.add(1, "month");
        this.setupCalendar();
    }

    previousMonth = () => {
        this.startOfMonth.subtract(1, "month");
        this.setupCalendar();
    }

    setupCalendar() {
        this.monthText(this.startOfMonth.format("MMMM YYYY"));
        let endOfMonth = this.startOfMonth.clone().endOf("month");
        let offset = (this.startOfMonth.day() + 6) % 7;
        let daysInMonth = this.startOfMonth.daysInMonth();

        let days = Array(offset).fill(new Day(null, null));

        for (let i = 1; i <= daysInMonth; i++) {
            days.push(new Day(i, this.startOfMonth.clone().add(i - 1, "day")));
            if(days.slice(-1)[0].date.isSame(this.parentVM.pickedWeekDate()) && !days.slice(-1)[0].today){
                days.slice(-1)[0].selected(true);
            }
        }

        //pad to a multiple of 7
        let length = days.length;
        days = days.concat(Array(length % 7 === 0 ? 0 : (7 - length % 7)).fill(new Day(null, null)));
        this.days = days;
        if(this.cardinality == 0){
            let temp = [];
            temp = temp.concat(this.days);
            temp = temp.concat(this.parentVM.days);
            this.parentVM.days = temp;
        }
        else{
            this.parentVM.days = this.parentVM.days.concat(this.days);
        }

        this.weeks(Utils.partition(days, 7));
    }

    constructor(params) {
        this.parentVM = params.vm;
        this.startOfMonth = params.startOfMonth;
        this.cardinality = params.cardinality;

        this.setupCalendar();
    }
}

let html = fs.readFileSync(__dirname + '/calendar.html', 'utf8');

ko.components.register("calendar-view", {
    viewModel: CalendarViewModel,
    template: html
});