import { RNSAPI } from '../../api';
import { UserData } from '../../UserData';
import { Utils } from '../../utils';
import { MainViewModel } from "../../main";
import * as fs from "fs";
import * as ko from "knockout";
import '../document/pdfjs';
import '../document/wopi';
import '../document/play';
import '../zpe/mail';
import './caseentry';

class CaseEntriesViewModel {
    caseId: string
    wopiToken = {
        "AccessToken": "",
        "AccessTokenTtl": 0
    }
    caseEntries: ko.ObservableArray<any>
    currentId = ko.observable(null);
    currentViewer = ko.observable("pdf");
    currentMimeType = ko.observable(null);

    createEntry() {
        MainViewModel.RoutingTable.showCaseEntryView({ caseId: this.caseId });
    }

    createResubmission() {
        MainViewModel.RoutingTable.showResubmissionView({ caseId: this.caseId });
    }

    async remove(id: string) {
        await RNSAPI.deleteRecord(id);
        await this.loadEntries();
    }

    async download(name: string, id: string) {
        let element = document.createElement('a');
        let doc= (await RNSAPI.getDocumentData(id)).Payload.Document;
        //server side bug in the ActiveX Component => OleType is doc instead of rtf
        let IsRTF = atob(doc.DocumentData.substr(0, 50)).indexOf("rtf") !== -1;
        let type = IsRTF ? "rtf" : doc.OLE2Type.trim().toLowerCase();

        let blob = Utils.base64ToBlob(doc.DocumentData, 'application/octet-stream');

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob);
        } else {
            element.setAttribute('href', 'data:application/octet-stream;charset=utf-16le;base64,' + doc.DocumentData);
            element.setAttribute('download', `${name.trim()}.${type}`);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }
    }

    async loadEntries() {
        try {
            let entries = (await RNSAPI.getCaseEntriesForId(this.caseId)).Payload.Records
                .filter((entry) => {
                    return entry.MimeType.indexOf("application/vnd.renostar.xmp") === -1;
                })
                .sort((a, b) => {
                    let dateA = new Date(a.LastChange || a.DocumentDate || a.RecordDate);
                    let dateB = new Date(b.LastChange || b.DocumentDate || b.RecordDate);
                    if (dateA < dateB) return 1;
                    else if (dateA > dateB) return -1;
                    else return 0
                })
                .map((entry) => {
                    if (entry.Subject === "")
                        entry.Subject = entry.DocDBIds[0];
                    entry.DocumentDate = (new Date(entry.LastChange || entry.DocumentDate || entry.RecordDate)).toLocaleDateString();
                    entry.actionHandlers = [{
                        'icon': 'pencil-alt',
                        'name': 'Edit',
                        'action': () => {
                            MainViewModel.RoutingTable.showCaseEntryView({ caseId: this.caseId, eCaseRecordId: entry.Id });
                        }
                    }, {
                        'icon': 'download',
                        'name': 'Download',
                            'action': () => {
                                if (entry.Id === '') {
                                    entry.Id = entry.DocDBIds[0];
                                }
                                this.download(entry.Subject, entry.Id);
                        }
                    }, {
                        'icon': 'trash',
                        'name': 'Löschen',
                        'action': () => {
                            this.remove(entry.Id);
                        }
                    }];

                    if (Utils.matchAny(entry.MimeType.toLowerCase(), Utils.AcceptedMimeTypes)) {
                        entry.actionHandlers.unshift({
                            'icon': 'eye',
                            'name': 'Vorschau',
                            'selectable': true,
                            'action': () => {
                                //set an non existent viewer to trigger the dispose methods
                                //before the id is set, otherwise, the old subscriptions will be called again
                                this.currentViewer("");
                                this.currentMimeType(entry.MimeType.toLowerCase());
                                this.currentId(entry.Id);
                                if (entry.MimeType.toLowerCase().indexOf("pdf") !== -1) {
                                    this.currentViewer("pdf");
                                } else if (entry.MimeType.toLowerCase().indexOf("rfc822") !== -1) {
                                    this.currentViewer("mail");
                                } else if (entry.MimeType.toLowerCase().indexOf("audio/ogg") !== -1) {
                                    this.currentViewer("play");
                                } else {
                                    this.currentViewer("wopi");
                                }
                            }
                        });
                    }

                    //add a "mark dictation as done button" when necessary
                    if (entry.MimeType.toLowerCase() === "audio/ogg" && entry.WorkflowId !== 'E' && !entry.TypistId) {
                        entry.actionHandlers.push({
                            'icon': 'check',
                            'name': 'Erledigen',
                            'action': async () => {
                                await RNSAPI.markDictationAsDone(entry.Id);
                                this.loadEntries();
                            }
                        });
                    }

                    return entry;
                });

            this.caseEntries(entries);
        } catch (e) {
            console.log(e);
        }
    }

    private async updateUserData() {
        let data = await RNSAPI.receiveUserData();
        RNSAPI.writeUserData(new UserData(this.caseId));
    }

    constructor(params: any) {
        this.caseEntries = ko.observableArray([]);
        this.caseId = params.caseId;

        if (params.eCaseRecordId) this.currentId(params.eCaseRecordId);

        this.loadEntries();

        this.updateUserData();
    }
}

let html = fs.readFileSync(__dirname + '/caseentries.html', 'utf8');

ko.components.register("caseentries-view", {
    viewModel: CaseEntriesViewModel,
    template: html
});
