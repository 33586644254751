import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment";
import { RNSAPI, Routing } from "../../api";
import { MainViewModel } from "../../main";
import '../dataTable/dataTable';

export class TagOverviewViewModel {
    tags = ko.observableArray([]);

    public createTag = () => {
        MainViewModel.RoutingTable.showTagView();
    }

    async loadTags() {
        let tags = (await RNSAPI.getAllTagTypes()).Payload.Tags;
        let mappedTags = tags.map(t => {
            t.OnlyWFK = (t.OnlyWFK as boolean) ? 'Ja' : 'Nein';
            t.actionHandlers = [{
                icon: "pencil",
                name: "Edit",
                action: () => MainViewModel.RoutingTable.showTagView({ name: t.TagName })
            },
            {
                icon: "trash-o",
                name: "Löschen",
                action: async () => {
                    let result = (await RNSAPI.deleteTagType(t.TagName));
                    if (result.Type === 'DeleteSuccessful') {
                        this.loadTags();
                    } else {
                        alert("Fehler beim Löschen eines Tags");
                    }
                }
            }
            ];

            return t;
        })
        this.tags(mappedTags);
    }

    constructor() {
        this.loadTags();
    }
}

let html = fs.readFileSync(__dirname + '/overview.html', 'utf8');

ko.components.register("tagoverview-view", {
    viewModel: TagOverviewViewModel,
    template: html
});