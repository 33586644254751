import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment";
import { RNSAPI, Routing } from "../../api";
import { MainViewModel } from "../../main";
import { Interval } from '../workflow/zpe';
import '../dataTable/dataTable';

enum Status {
    Done = 'E',
    New = 'N',
    Running = 'L',
    FurtherTerminating = 'W',
}

export class ResubmissionOverviewViewModel {
    resubmissions = ko.observableArray([]);
    intervalLength = ko.observable(30);
    interval = ko.pureComputed(() => {
        let length = this.intervalLength();
        let startDate = moment.utc().startOf('day');
        let endDate = moment.utc().endOf('day').add(length, 'days');
        return new Interval(startDate, endDate);
    })

    public createResubmission = () => {
        MainViewModel.RoutingTable.showResubmissionView();
    }

    async loadResubmissions() {
        let range = {
            StartDate: this.interval().startDate.toISOString(),
            EndDate: this.interval().endDate.toISOString()
        };
        let resubmissions = (await RNSAPI.getResubmissionsByRange(range)).Payload.Resubmissions;
        this.resubmissions(resubmissions.map((r) => {
            console.log(r);
            r.DeadLineDate = (new Date(r.DeadLineDate)).toLocaleDateString();
            r.columnActions = {
                "Case_ID": MainViewModel.RoutingTable.generateLink(`/case/${encodeURIComponent(r.Case_ID)}`)
            };
            let checkAction = { icon: "check", name: "Abschließen", action: async () => { await RNSAPI.completeResubmission(r.ID); this.loadResubmissions(); } };
            r.actionHandlers = [{
                icon: "pencil",
                name: "Edit",
                action: () => {
                   // MainViewModel.RoutingTable.showResubmissionView({ id: r.ID })
                   $('#extendResubmissionModal').modal('show');
                }
            },
            {
                icon: "trash-o",
                name: "Löschen",
                action: async () => {
                    await RNSAPI.deleteResubmission(r.ID);
                    this.loadResubmissions();
                }
            }
            ];

            if (r.Status !== Status.Done) r.actionHandlers.push(checkAction);

            r.Status = (() => {
                switch (r.Status) {
                    case Status.New:
                    case Status.Running:
                    case Status.FurtherTerminating:
                        return 'nicht erledigt';
                    case Status.Done:
                        return 'erledigt';
                    default:
                        return r.Status;
                }
            })();

            return r;
        }));
    }

    constructor() {
        this.loadResubmissions();
        this.interval.subscribe(newInterval => this.loadResubmissions());
    }
}

let html = fs.readFileSync(__dirname + '/overview.html', 'utf8');

ko.components.register("resubmissionoverview-view", {
    viewModel: ResubmissionOverviewViewModel,
    template: html
});