import * as fs from 'fs';
import * as ko from "knockout";
import './extendedDataTable';
import "../dialog/dialog"
import { RNSAPI } from "../../api";
import * as model from './model';

class ExtenedFormViewModel {
    constructor(params) {
        if (params) {
            this.data = ko.observableArray([params.data]);
        } else {
            this.data = ko.observableArray([]);
        }
    }

    data: ko.ObservableArray<model.FormElement[]>;

    toTemplate(data: model.FormElement): string {
        let base = "tab-" + data.Type.toLowerCase();
        if (data.Type === "TEXTBOX") {
            if (data.WebInfo === "Datum") return base + "-date";
            if (data.WebInfo === "Nummer") return base// + "-number";
        }
        return base;
    }

    getAdditionalAttributes(element: any): any {
        switch (element.Type) {
            case "HEADLINE": return {};
            case "LABEL": return {};
            case "CHECKBOX": return {};
            case "COMBOBOX": return {};
            case "RADIOGROUP": return {};

            case "TEXTBOX":
            case "TEXTAREA":
                let attr: any = {};
                if (element.MaxLength) {
                    attr.maxlength = element.MaxLength;
                }
                return attr;
            case "TABLE": return {};
            default: throw "Don't know element: " + JSON.stringify(element);
        }
    }

    async pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    };

    pickSomething = async (element: model.FormElement) => {
      switch(element.Selection.SelectionFrom) {
        case "Referat":        return this.pickReferat();
        case "Sachbearbeiter": return this.pickSachbearbeiter();
        case "Rechtsform":     return this.pickRechtsform();
        case "Mandant":        return this.pickPerson(element.Selection.SelectionFrom);
        case "Gegner":         return this.pickPerson(element.Selection.SelectionFrom);
        case "Sonstige":       return this.pickPerson(element.Selection.SelectionFrom);
        case "Versicherung":   return this.pickPerson(element.Selection.SelectionFrom);
        case "Bank":           return this.pickPerson(element.Selection.SelectionFrom);
        case "Gericht":        return this.pickPerson(element.Selection.SelectionFrom);
        case "Behörde":        return this.pickPerson(element.Selection.SelectionFrom);
        case "Anwalt":         return this.pickPerson(element.Selection.SelectionFrom);
      }
    }

    generateSelectionHandler = (selection: string) => {
      return (selectedObject) => {
        for (let row of this.data()) {
          for (let element of row) {
            if (element.Selection && element.Selection.SelectionFrom === selection) {
              element.Data(selectedObject()[element.Selection.SelectionProperty])
            }
          }
        }
      };
    }

    pickSachbearbeiter = async () => {
          let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
          this.pickGeneric("Sachbearbeiter", ["Sachbearbeiter"], ["Sachbearbeiter"], sachbearbeiter);
          this.modalHandleSelection(this.generateSelectionHandler("Sachbearbeiter"));
            $('#modal').modal('show');
        };

    pickReferat = async () => {
        let referate = (await RNSAPI.getReferate()).Payload.Units;
        this.pickGeneric("Referat", ["Name", "Id"], ["Name", "Id"], referate);
        this.modalHandleSelection(this.generateSelectionHandler("Referat"));
          $('#modal').modal('show');
    };

    pickRechtsform = async () => {
        let rechtsformen = (await RNSAPI.getRechtsformen()).Payload.LegalForms;
        this.pickGeneric("Rechtsform", ["NameID", "Briefanrede"], ["Name", "Anrede"], rechtsformen);
        this.modalHandleSelection(this.generateSelectionHandler("Rechtsform"));
          $('#modal').modal('show');
    };

    pickPerson = async (addressType) => {
        let addresses = (await RNSAPI.getPersonsForScope(addressType)).Payload.Addresses;
        this.pickGeneric( addressType + " auswahl", ["FirstName", "Name1", "CityName"], ["Vorname", "Nachname", "Ort"], addresses);
        this.modalHandleSelection(this.generateSelectionHandler(addressType));
          $('#modal').modal('show');
    };

    modalTitle   = ko.observable("");
    modalKeys    = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData    = ko.observableArray([]);
    modalHandleSelection = ko.observable();
}

let html = fs.readFileSync(__dirname + '/extendedForm.html', 'utf8');

ko.components.register("extended-form-view", {
    viewModel: ExtenedFormViewModel,
    template: html
});
