import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment";
import { RNSAPI, Routing } from "../../api";
import { MainViewModel } from "../../main";
import '../dataTable/dataTable';

export class ViewOverviewViewModel {
    views = ko.observableArray([]);

    public createView = () => {
        MainViewModel.RoutingTable.showViewView();
    }

    async loadViews() {
        let views = (await RNSAPI.getAllViews()).Payload.Views;
        let mappedViews = views.map(v => {
            v.Aktenansicht = v.Aktenansicht ? "Ja" : "Nein";
            v.SBSpezifisch = v.SBSpezifisch ? "Ja" : "Nein";
            v.actionHandlers = [{
                icon: "pencil",
                name: "Edit",
                action: () => MainViewModel.RoutingTable.showViewView({ name: v.Bezeichnung })
            },
            {
                icon: "trash-o",
                name: "Löschen",
                action: async () => {
                    let result = await RNSAPI.deleteView(v.Bezeichnung);
                    if (result.Type === 'DeleteSuccessful') {
                        this.loadViews();
                    } else {
                        alert("Fehler beim Löschen einer Ansicht.");
                    }
                }
            }
            ];

            return v;
        })
        this.views(mappedViews);
    }

    constructor() {
        this.loadViews();
    }
}

let html = fs.readFileSync(__dirname + '/overview.html', 'utf8');

//the naming is unfortunate, but that is what it actually is: A view for the overview of views
ko.components.register("viewoverview-view", {
    viewModel: ViewOverviewViewModel,
    template: html
});