import * as fs from "fs";
import * as ko from "knockout";
import '../../../node_modules/trumbowyg/dist/trumbowyg.min.js';
import '../../../node_modules/trumbowyg/dist/plugins/base64/trumbowyg.base64.min.js';
import '../../../node_modules/trumbowyg/dist/langs/de.min.js';
import './editorIcons';
import { RNSAPI } from "../../api";
import { Postbox } from "../dashboard/postbox";
declare var $:any;


enum AddressCategories {
    Alle = "Alle",
    Mandant = "Mandant",
    Gegner = "Gegner",
    Sonstige = "Sonstige",
    Gericht = "Gericht",
    Versicherung = "Versicherung",
    Behörde = "Behörde",
    Anwalt = "Anwalt"
}
class ComposeViewModel {
   
    subject = ko.observable("");
    caseId = ko.observable("");
    records = ko.observableArray([]);
    disableButton = ko.observable(false);
    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalHandleSelection = ko.observable();
    categories = AddressCategories;

    
    mailToAddresses = ko.observableArray([]);
    selectedMailTo = ko.observableArray([]);
    selectedMailCC = ko.observableArray([]);
    senderMails = ko.observableArray([]);
    selectedCase = ko.observable<string>();
    selectedSender = ko.observable<string>();

    isSendDocument = ko.observable(false);

    attachmentEntries = ko.observableArray<Map<string, string>>();

    async pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    };

    allCases = ko.observableArray([]);

    async loadCases(): Promise<void> {
        let cases = [];
        await RNSAPI.getCasesOverview(0, ko.toJS(20)).then((data) => {
            cases = data as any;
        }).catch((error) => {
            cases = [];
        });

        this.allCases(cases); //(await RNSAPI.getCases()).Payload.Cases
        $(".casepicker").selectpicker("refresh");
    }


    pickRecord = async () => {
        let records = (await RNSAPI.getCaseEntriesForId(this.caseId())).Payload.Records;
        let sortedRecords = records.sort((a, b) => {
            let dateA = new Date(a.LastChange || a.DocumentDate || a.RecordDate);
            let dateB = new Date(b.LastChange || b.DocumentDate || b.RecordDate);
            if (dateA < dateB) return 1;
            else if (dateA > dateB) return -1;
            else return 0
        });
        let mappedRecords = sortedRecords.map(record => {
            record.DocumentDate = (new Date(record.LastChange || record.DocumentDate || record.RecordDate)).toLocaleDateString();
            return record;
        });
        this.pickGeneric("EAkten", ['DocumentDate', 'Subject'], ['Datum', 'Betreff'], mappedRecords);
        this.modalHandleSelection((selectedObject) => {
            this.records.push(selectedObject())
        });
        $("#modal").modal("show");
    };

    deleteRecord = (id: string) => {
        this.records(this.records().filter(record => record.Id !== id));
    };

    async getMailToAddresses(): Promise<void> {
        await RNSAPI.getAllMailAddresses().then((response) => {
            for(const data of response["AddressMails"]) {
                if(data.Mail === "") continue;
                this.mailToAddresses.push({ Mail: data.Mail, Name: data.Name });
            }            
            $(".selectpicker").selectpicker('refresh');
        }).catch((error) => {});
    }

    changeMailTo(data, event): void { }
    changeMailCC(data, event): void { }
    changeCase(data, event): void {
        this.caseId(event.target.value);
    }

  
    async sendMail() {
        const html = ($('#trumbowyg') as any).trumbowyg('html');
        const text = $(`<div>${html}</div>`).text();

        const getMailDataFromId = ko.toJS(this.senderMails()).find(x => x.AccountId === ko.toJS(this.selectedSender()));
        if(!getMailDataFromId) {
            return;
        }

        const mailData = {
            AccountID: getMailDataFromId.AccountId,
            To: this.selectedMailTo(),
            Ccc: this.selectedMailCC(),
            Bcc: "",
            Subject: this.subject(),
            From: getMailDataFromId.EMailAddress,
            BodyHtml: html,
            BodyText: text,
            ECaseRecordIDs: this.records().map(record => record.Id)
        };

        await RNSAPI.sendMail(mailData).then(async (response) => {
            if(response.Type === "SendSuccessful") {
                if (ko.toJS(this.records).length > 0) {
                    let markAsSent = (<HTMLInputElement>document.getElementById("customChangeWorkflowOfDocuments")).checked;
                    if (markAsSent) {
                        for (var dok of ko.toJS(this.records)) {
                            if (dok.WorkflowId !== "PA")
                                await RNSAPI.setCaseEntryWorkflowMark(dok.Id, "PA");
                        }
                    }
                }
                alert("Mail erfolgreich versendet.");
                window.location.reload();
            } else {
                alert("Die E-Mail konnte nicht versendet werden.");    
            }
        }).catch((error) => {
            alert("Die E-Mail konnte nicht versendet werden!");
        });
    }

    async loadECase(id: string) {
        let result = (await RNSAPI.getCaseEntry(id));
        if (result.Type === 'IdFound') {
            this.records.push(result.Payload.Record);
        }
    }


    changeSenderMail(data, event): void {
        this.selectedSender(event.target.value);
    }

    async getMailFromAccounts(): Promise<void> {
        await RNSAPI.getMailSenderAccounts().then((response) => {
            this.senderMails(response["Accounts"]);
            if(ko.toJS(this.senderMails()).length > 0) {
                this.selectedSender(this.senderMails()[0]);
            }
        }, (error) => {});
    }

    async getRubrumFromCase(caseId: string) {
        this.subject(caseId + " " + (await RNSAPI.getCaseByCaseId(ko.toJS(caseId))).Payload.Case.Rubrum);
    }

    constructor(params: any) {

        this.getMailToAddresses(); 
        this.getMailFromAccounts(); 

        this.loadCases();


        $(document).ready(function() {
            $(".selectpicker").selectpicker({
                noneSelectedText: "Auswahl",
                noneResultsText: "Keine Einträge gefunden",
                iconBase: "fal",
                tickIcon: "fa-check",
                actionsBox: true,
                deselectAllText: "Auswahl aufheben",
                selectAllText: "Alle auswählen"
            });
            $(".selectpicker").selectpicker("render");

            $(".casepicker").selectpicker({
                noneSelectedText: "Auswahl",
                noneResultsText: "Keine Einträge gefunden",
                deselectAllText: "Auswahl aufheben",
                selectAllText: "Alle auswählen"
            });
            $(".casepicker").selectpicker("render");
        });



        if (params && params.caseId) {
            this.caseId(params.caseId);
        }

        if (params && params.eCaseRecordId) {
            this.loadECase(params.eCaseRecordId);
        }

        if (params && params.type == 'sendDocument') {
            this.isSendDocument(true);
            this.caseId(params.entry().entry.CaseId);

            this.selectedCase(ko.toJS(params.entry().entry.CaseId));

            this.getRubrumFromCase(ko.toJS(params.entry().entry.CaseId));
          

            this.loadECase(params.entry().entry.Id);
        }

        Postbox.publisher().subscribe((response) => {
            if(response) {
                this.records.push({ Id: response.Key, Subject: response.value });
            }
        }, "DropDownSearchEntry");

        ($('#trumbowyg') as any).trumbowyg({
            lang: "de",
            resetCss: true,
            btnsDef: {
                image: {
                    dropdown: ['insertImage', 'base64'],
                    ico: 'insertImage'
                }
            },
            btns: [
                ['viewHTML'],
                ['formatting'],
                ['strong', 'em', 'del'],
                ['superscript', 'subscript'],
                ['link'],
                ['image'],
                ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                ['unorderedList', 'orderedList'],
                ['horizontalRule'],
                ['removeformat'],
                ['fullscreen']
            ]
        });
    }
}

let html = fs.readFileSync(__dirname + '/compose.html', 'utf8');

ko.components.register("compose-view", {
    viewModel: ComposeViewModel,
    template: html
});