import * as fs from 'fs';
import * as ko from "knockout";
import {MainViewModel} from "../../main";
import {RNSAPI} from "../../api";
import { Utils } from '../../utils';
import './easycaseperson';
import '../dialog/message';
import { Postbox } from '../dashboard/postbox';

// Fragen:
//  - was sind die erweiterten Daten
//  - wie entferne ich einen Mandaten wieder
//  - Anrede und Rechtsform wählbar, aber Anrede hängt von Rechtsform ab
//  - Die Buttons zur Addition von weiteren Gegner und die zum Ausklappen sehen alle gleich aus
//  - Warum sind Gegner optional, wenn sie für die Kollisionsprüfung wichtig sind
//
//  Notiz_Mandantenbegehren
//  Notiz_Sachverhalt
//  Notizen

class EasyCaseViewModel {
    clients: ko.ObservableArray<Person> = ko.observableArray([
        {
            personType: ko.observable("mandant"),
            isFirst: ko.observable(true),
            isShown: ko.observable(true),
            isMoreShown: ko.observable(false),
            isError: ko.observable(false),
            isExtended: ko.observable(false),
            data: createPersonObservable()
        }
    ]);

    defendants: ko.ObservableArray<Person> = ko.observableArray([
        {
            personType: ko.observable("gegner"),
            isFirst: ko.observable(true),
            isShown: ko.observable(true),
            isMoreShown: ko.observable(false),
            isError: ko.observable(false),
            isExtended: ko.observable(false),
            data: createPersonObservable()
        }
    ]);


    mandantenbegehren = ko.observable("");
    notizen = ko.observable("");
    sachverhalt = ko.observable("");
    registernummer = ko.observable("");
    currentDate = ko.observable(null);
    CurrentUser = ko.observable("");
    SelectedUser = ko.observable("");
    referat = ko.observable("");
    collisionErrored = ko.observable(false);

    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    infoMessage = ko.observable('');
    infoTitle = ko.observable('');
    modialAutoHide = ko.observable(false)
    modalHandleSelection = ko.observable();

    collision: ko.Observable<Collision> = ko.observable(CollisionOff);

    closeCollision(): void {
        this.collision(CollisionOff);
    }

    async myCheckCollision() {
        let clients = this.clients();
        let defendants = this.defendants();
        let clientsPeople: string[] = [];
        let defendandtsPeople: string[] = [];
        let clientsCollisions = [];
        let defendantsCollisions = [];
        let RequiredFieldsLoaded = true;
        
        if (defendants.length === 1 && !defendants[0].data.Name1()) {
            this.defendants.remove(this.defendants()[0]);
        }

        if (ko.toJS(this.registernummer) === '') {
            this.setErrors('regnr', 'regnr', 0);
            RequiredFieldsLoaded = false;
        }


        //if (ko.toJS(this.referat) === '') {
        //    this.setErrors('referat', 'referat', 0);
        //    RequiredFieldsLoaded = false;
        //}

        for (let i = 0; i < defendants.length; i++) {
            if (!defendants[i].data.Name1()) {

                //console.log("Defendants" + defendants.length);
                defendants[i].isShown(true);
                defendants[i].isMoreShown(true);
                defendants[i].isError(true);
                document.getElementById('defendant-last-name-' + i).scrollIntoView()
                $('#defendant-last-name-' + i).parent().addClass('has-error');
                setTimeout(() => $('#defendant-last-name-' + i).parent().removeClass('has-error'), 1500);
                RequiredFieldsLoaded = false;
                return;
            } else {
                defendants[i].isError(false);
                defendandtsPeople.push(ko.toJS(defendants[i].data.Name1));
            }
        }

        for (let i = 0; i < clients.length; i++) {
            if (!clients[i].data.Name1()) {
                this.setErrors('client', 'last-name', i)
                RequiredFieldsLoaded = false;
                return;
            } else {
                clients[i].isError(false);
                clientsPeople.push(ko.toJS(clients[i].data.Name1));
            }
        }

        if (RequiredFieldsLoaded) {

            this.collision(CollisionLoading);

            for (let lastName of defendandtsPeople) {
                let collisions = await RNSAPI.checkCollision(lastName)
                defendantsCollisions = defendantsCollisions.concat(collisions.Payload.Addresses[0]);
            }

            for (let lastName of clientsPeople) {
                let collisions = await RNSAPI.checkCollision(lastName)
                clientsCollisions = clientsCollisions.concat(collisions.Payload.Addresses[1]);
            }

            for (let client of clients) {
                for (let defen of defendants) {
                    if (client.data.Name1() === defen.data.Name1()) {
                        defendantsCollisions.push({
                            FirstName: defen.data.FirstName(),
                            Name1: defen.data.Name1(),
                            Address: "",
                            ZipCode: "",
                            CityName: "",
                        });
                    }
                }
            }

            this.collision({
                kind: "CollisionLoaded",
                clients: ko.observableArray(clientsCollisions),
                defendants: ko.observableArray(defendantsCollisions)
            });
        }
    }

    setErrors(prefix, type, i) {
        this.clients()[i].isShown(true);
        this.clients()[i].isMoreShown(true);
        this.clients()[i].isError(true);
        document.getElementById(`${prefix}-${type}-` + i).scrollIntoView()
        $(`#${prefix}-${type}-` + i).parent().addClass('has-error');
        this.collisionErrored(true)
        setTimeout(() => {
            this.collisionErrored(false)
        }, 10000);
    }

    async pickGeneric(title, keys, columns, data, callback) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
        this.modalHandleSelection(callback);
    };

    addPerson(personType) {
        let array = personType === 'mandant' ? this.clients : this.defendants;
        array.push({
            personType: ko.observable(personType),
            isFirst: ko.observable(false),
            isShown: ko.observable(true),
            isMoreShown: ko.observable(false),
            isError: ko.observable(false),
            isExtended: ko.observable(false),
            data: createPersonObservable()
        });
    }

    deleteDefendant(index: number): void {
        if (window.confirm('Sind Sie sicher, dass Sie die Gegnerdaten löschen möchten?')) {
            this.defendants.remove(this.defendants()[index]);
        }
    }

    deleteClient(index: number): void {
        if (window.confirm('Wollen Sie den Mandanten entfernen.?')) {
            this.clients.remove(this.clients()[index]);
        }
    }

    pickSachbearbeiter = async () => {
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric2("Sachbearbeiter", ["Sachbearbeiter"], ["Sachbearbeiter"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => this.SelectedUser(selectedObject()["Sachbearbeiter_ID"]));
        $('#modal').modal('show');
    };

    async pickGeneric2(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    };

    async pickReferat() {
        const referate = (await RNSAPI.getReferate()).Payload.Units;
        this.pickGeneric2("Referat", ["Name", "Id"], ["Name", "Id"], referate);
        this.modalHandleSelection((selectedObject) => this.referat(selectedObject()["Name"]));
        $('#modal').modal('show');
    }

    async getLastCase() {
        const cases = (await RNSAPI.getCases()).Payload.Cases;
        if (cases.length) {
            this.referat(await this.getReferateCode(cases[cases.length - 1].Referat, 'Name'))
        }
    }

    async getReferateCode(value, type) {
        const key = type === 'Name' ? 'Id': 'Name'
        const referate = (await RNSAPI.getReferate()).Payload.Units;
        const found = referate.filter(item => item[key] === value)[0]
        return found[type]
    }

    GerAdressen = ko.observableArray([]);
    FirstCourt = ko.observable('');
    CourtCaseID = ko.observable('');
    MandaCaseID = ko.observable('');
    filter = ko.observable('');
    initialfocusinput = true;
    modalSelectedObject = ko.observable(null);
    RegNr = ko.observable('');
    setFrist = ko.observable(false);

    modalPick() {
        if (this.modalHandleSelection !== null) this.modalHandleSelection()(this.modalSelectedObject);
        $('#gerichtsmodal').modal('hide');
    };

    modalSelect = (obj, event) => {

        if ($(event.target.parentElement).hasClass("active")) {
            this.modalPick();
        }
        else {
            $("#modalTable tr").removeClass("active");
            $(event.target.parentElement).addClass("active");
            this.modalSelectedObject(obj);
        }
    }

    focusinput() {
        if (ko.toJS(this.initialfocusinput.toString()) === "true") {
            document.getElementById("inputGer-filter").focus();
            this.initialfocusinput = false;
        }
    }

    async pickAddress1(Instance) {
        $('#gerichtsmodal').modal('show');
        if (this.GerAdressen.length === 0) {
            this.GerAdressen((await RNSAPI.getPersonsForScope("Gericht")).Payload.Addresses);
            let AllAddr = this.GerAdressen();
            Postbox.publisher().subscribe((Filter) => {
                this.GerAdressen(Filter);
            }, "ApplyFilterGer");
            this.focusinput();
            document.getElementById('inputGer-filter').oninput = function (f) {
                let theFilter = (<HTMLInputElement>document.getElementById('inputGer-filter')).value;
                let Filtered = [];
                if (theFilter.length > 2) {
                    for (let i = 0; i < AllAddr.length; i++) {
                        if ((AllAddr[i].Name1 + AllAddr[i].Name2 + AllAddr[i].Name3).toString().includes(theFilter)) {
                            Filtered.push(AllAddr[i])
                        }
                    }
                    Postbox.publisher().publish(Filtered, "ApplyFilterGer");
                }
                else {
                    Postbox.publisher().publish(AllAddr, "ApplyFilterGer");
                }

            }
        }
        if (Instance === 1) {
            //this.modalHandleSelection((selectedObject) => this.akte().Gericht1Id(selectedObject()["Keyword"]));
            this.modalHandleSelection((selectedObject) => this.FirstCourt(selectedObject()["Keyword"]));
        }
    }

    async postData() {

        let caseData={Registernummer:"",Rubrum:""};

        if (this.defendants().length == 0) {

            caseData = {
                Registernummer: this.registernummer(),
                Rubrum: createRubrum(this.clients()[0].data.Name1(),null)
            };
        } else {
            caseData = {
                Registernummer: this.registernummer(),
                Rubrum: createRubrum(this.clients()[0].data.Name1(), this.defendants()[0].data.Name1())
            };
        }
        let addresses = [
            ko.toJS(this.clients as any).map(p => p.data),
            ko.toJS(this.defendants as any).map(p => p.data),
            [],
            [],
            [],
            [],
            [],
            [],
            []
        ];
        let extendedCase = {
            Key: this.registernummer(),
            Infos: {
                Notiz_Mandantenbegehren: this.mandantenbegehren(),
                Notiz_Sachverhalt: this.sachverhalt(),
                Notizen: this.notizen()
            },
            Persons: [],
        }

        let data: any = {
            Akte: caseData,
            Addresses:addresses,
            ExtendedPersons: [],
            ExtendedCase: extendedCase
        };

        try {
            data.Akte.SachbearbeiterId = this.SelectedUser();
            //data.Akte.Referat = await this.getReferateCode(this.referat(), 'Id') || this.referat();
            data.Akte.Gericht1Id = ko.toJS(this.FirstCourt);
            data.Akte.AktenZeichenGericht1 = ko.toJS(this.CourtCaseID);
            data.Akte.MandantCaseID = ko.toJS(this.MandaCaseID);
            //console.log(ko.toJS(data.Akte.AktenZeichenGericht1()));
            let result = await RNSAPI.createExtendedCase(data);

            if (result.Type === "CaseCreationError") {
                this.suggestRegisterNumber();
                this.infoTitle('')
                this.infoTitle('Fehler')
                this.infoMessage('');
                this.infoMessage('Fehler: Die Akte konnte nicht angelegt werden.');
                return
            }
            else {
                //MainViewModel.RoutingTable.showNewView({ caseId: caseData.Registernummer });
                this.RegNr(ko.toJS(this.registernummer));

                Postbox.publisher().publish(ko.toJS(this.registernummer), "SetCaseID");

                setTimeout(() => {
                    $('#EasyCaseNewDeadlineDialogExt').modal('show');
                }, 300);

            }
        } catch (e) {
            let error = Utils.handleError(e);
            this.infoMessage('');
            this.infoMessage(error['code'] + ': ' + error['message']);
        }
    }

    constructor(params) {
        params = params || {};

        var mb = document.getElementsByClassName("modal-backdrop")
        for (let i = 0; i < mb.length; i++)
            mb[i].remove();

        this.suggestRegisterNumber();
        this.setCurrentData();
        this.SelectedUser(ko.toJS(this.CurrentUser()));
        this.getLastCase()
        $(function () {
            $('[data-toggle="tooltip"]').tooltip()
        })
    }
    async setCurrentData() {
        this.CurrentUser(RNSAPI.User().username);
        var Heute = new Date();
        var TheDay = Heute.getDate();
        var TheMonth = Heute.getMonth() + 1;
        var TheYear = Heute.getFullYear();
        var Heutee = "";

        if (TheDay < 10) {
            Heutee += "0" + TheDay;
        } else {
            Heutee += TheDay;
        }

        if (TheMonth < 10) {
            Heutee += '.0' + TheMonth;
        } else {
            Heutee += '.' + TheMonth
        }

        Heutee += '.' + TheYear;

        this.currentDate(Heutee);
    }

    async suggestRegisterNumber() {

        let xregisternummer = (await RNSAPI.suggestCaseId()).Payload.CaseId;
        this.registernummer(xregisternummer);
    }

    removeClient(index) {
        if (window.confirm('Sind Sie sicher, dass die Gegnerdaten löschen möchten?')) {
            this.deleteClient(index)
        }
    }
}

function createRubrum(clientName: string, defendantName: string): string {
    if (clientName && defendantName)
        return (clientName + " ./. " + defendantName).substring(0, 64);
    else
        return clientName.substring(0, 64)
}

function isSamePerson(localPerson: any, serverPerson: any): boolean {
    return (localPerson.data.Name1() + localPerson.data.FirstName()).substring(0, 20) === serverPerson.Keyword;
}


function createPersonObservable(): any {
    let obj = {
        FirstName: ko.observable(null),
        Name1: ko.observable(null),
        Rechtsform: ko.observable(null),
        Address: ko.observable(null),
        ZipCode: ko.observable(null),
        CityName: ko.observable(null),
        EmailAddress: ko.observable(null),
        Phone1: ko.observable('+49'),
        // homepage, geburtsort, staatsangehörigkeit ???
        Fax: ko.observable(null),
        MobilePhone: ko.observable(null),
        AnredeBriefkopf: ko.observable(null),
        AnredeDirekt: ko.observable(null),
        BirthDate: ko.observable(null),
        Adresszusatz: ko.observable(null),
        edit: ko.observable(true),
        IsSelected: ko.observable(false)
    };
    //let x=this.createPersonObservable();
    obj["Keyword"] = ko.computed(() =>
        (obj.Name1() || '') + (obj.FirstName() || ''));

    return obj;
}

export interface Person {
    personType: any,
    isFirst: any,
    isShown: any,
    isMoreShown: any,
    isError: any,
    isExtended: any,
    data: any,
}

type Collision = CollisionOff | CollisionLoading | CollisionLoaded;

interface CollisionOff {
    kind: "CollisionOff";
}

let CollisionOff: CollisionOff = {kind: "CollisionOff"};

interface CollisionLoading {
    kind: "CollisionLoading";
}

let CollisionLoading: CollisionLoading = {kind: "CollisionLoading"};

interface CollisionLoaded {
    kind: "CollisionLoaded";
    clients: any;
    defendants: any;
}

let html = fs.readFileSync(__dirname + '/easycase.html', 'utf8');

ko.components.register("easycase-view", {
    viewModel: EasyCaseViewModel,
    template: html
});
