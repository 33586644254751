import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment"
import { RNSAPI } from "../../api";
import { MainViewModel } from "../../main";

class AppointmentPersonViewModel {
    appointments = ko.observableArray([]);
    timeslots = ko.observableArray([]);
    lanes: ko.Computed<Array<Array<Array<any>>>>;
    sachbearbeiters: ko.Computed<Array<any>>;
    parentVM: any;
    date: ko.Observable<moment.Moment>;
    weekdays: ko.Computed<Array<any>>;
    count: ko.Computed<any>;
    sepCount: ko.Computed<any>;

    start = 0;
    end = 24;
    //start = 0;
    //end = 24;

    currentAppointment: any;

    readonly margin = "70";
    hoverId = ko.observable(null);
    
    //TODO: Delete this
    hovered = ko.observable(false);

    toggle = (index) => {
        console.log(index, this.hoverId())
        // index === this.hoverId() ? this.hoverId(-1): this.hoverId(index)
        this.hoverId(index)
        setTimeout(() => { this.hoverId(-1); }, 5000);

    }

    //TODO: Delete this
    enableDetails = (index) => {
        console.log(index)
        this.hoverId(index);
    }
    //TODO: Delete this
    disableDetails = (event) => {
        console.log('null', event)
        // this.hoverId(null);
    }
    //no extra module for leftpad ;)
    private static pad(n, width) {
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
    }

    public left(appointment: any, lane: number, nrOfLanes: number) {
        let index = this.sachbearbeiters().findIndex(x => x.Sachbearbeiter_ID == appointment.LawyerId);
        // let startDate = moment.utc(appointment.AppointmentDate);
        return `calc((100% - ${this.margin}px) / ${this.count()} * ${(index) + lane / nrOfLanes} + ${this.margin}px)`;
    }

    public nameWidth(length: number) {
        return `calc((100%) / ${this.count()})`;
    }

    public width(length: number) {
        return `calc(((100% - ${this.margin}px) / ${this.count()}) / ${length})`;
    }

    public sepLeft(i: number): string {
        return `calc((100% - ${this.margin}px) / ${this.count()} * ${i} + ${this.margin}px)`;
    }

    public top(appointment: any) {
        let m = moment.utc(appointment.StartDate).local();
        let offset = m.hour() - this.start - 1;
        offset += m.minute() / 60;
        return `${100 * offset / (this.end - this.start)}%`;
    }
    public ishalfhour(data: any) {
        if (data.toString().includes(':30')) {
            return true;
        }
    }
    public height(appointment: any) {
        let startDate = moment.utc(appointment.StartDate);
        let endDate = moment.utc(appointment.EndDate);
        //let percentage = 100 * (endDate.diff(startDate, 'minutes') / 60 / (this.end - this.start));
        let percentage = 100 * (endDate.diff(startDate, 'minutes') / 60 / (this.end - this.start));
        //return `calc(${percentage}% - 1px)`;
         return `${percentage}%`;
    }

    public id(appointment: any) {
        return encodeURIComponent(appointment.Id);
    }

    public deleteAppointment = async (appointment: any) => {
        this.currentAppointment = appointment;
        $("#DeleteMail").modal('show');
        //await RNSAPI.deleteAppointment(appointment.Id);
        //this.parentVM.update();
    }

    public actuallydelete = async () => {
        await RNSAPI.deleteAppointment(this.currentAppointment.Id);
        this.parentVM.update();
        $("#DeleteMail").modal('hide');
    }

    //NOTE: Double Click was requested here, note there is **NO** native way of telling a double click
    //this is a crude timing solution (delta < 250ms), that does not take Position into account, but only the selected hour.
    public createAppointment = (() => {
        let oldTimeStamp: number = null;
        let oldHour: String = null;
        return (hour: String, event: MouseEvent) => {
            let x = event.pageX;
            let date = this.date().clone();
            //we don't know which weekday was clicked as there is no element for every weekday
            //only the separators position and mouse position help us do that
            for (let sep of $("div.weekday-sep").toArray()) {
                if (x < sep.getBoundingClientRect().left) break;
                date.add(1, 'days');
            }

            if (oldTimeStamp && oldHour && ((event.timeStamp - oldTimeStamp) < 250 && oldHour === hour)) {
                let startMoment = date.add(moment.duration(hour as any));
                MainViewModel.RoutingTable.showAppointmentView({ startdate: startMoment.toISOString() });
            } else {
                oldTimeStamp = event.timeStamp;
                oldHour = hour;
            }
        }
    })()

    constructor(params) {
        let arr = [];

        let count = (this.end - this.start) * 2

        for (let i = 0; i < count; i++) {
            let hour = AppointmentPersonViewModel.pad(Math.floor(i / 2) + this.start, 2); 
            if (i % 2 == 0) {
                arr.push(`${hour}:00`);
            } else {
                arr.push(`${hour}:30`);
            }
        }

        this.sachbearbeiters = params.sachbearbeiters;
        this.sepCount = ko.computed({
            owner: this,
            read: () => {
                if(this.sachbearbeiters())
                    return Array.apply(null, Array(this.sachbearbeiters().length)).map(function (_, i) {return i;});
                return [];
            }
        });
        this.timeslots(arr);

        this.date = params.date;
        this.count = ko.computed({
            owner: this,
            read: () => {
                let sachbearbeiters = this.sachbearbeiters();
                if(this.sachbearbeiters())
                    return this.sachbearbeiters().length;
                return 0;
            }
        });
        this.weekdays = ko.computed({
            owner: this,
            read: () => {
                let start = this.date();
                let weekdays = [];

                for (let i = 0; i < 5; i++) {
                    let text = start.clone().add(i, "days").format("dd, DD.MM");
                    weekdays.push(text);
                }

                return weekdays;
            }
        });
        this.appointments = params.appointments;
        this.parentVM = params.vm;

        this.lanes = ko.computed(() => {
            let weekStart = this.date().clone();
            let appointments = this.appointments().sort((a, b) => {
                let startA = moment.utc(a.StartDate);
                let startB = moment.utc(b.StartDate);

                if (startA.isBefore(startB)) return -1;
                else if (startA.isAfter(startB)) return 1;
                else return 0;
            });

            let lanes = this.sachbearbeiters().map(i => {
                let dayAfter = weekStart.clone().add(1, "day");
                let dayBefore = weekStart.clone().add(0, "day");

                let filtered = appointments.filter(appointment => {
                    let start = moment.utc(appointment.StartDate);
                    return start.isBefore(dayAfter) && start.isSameOrAfter(dayBefore) && appointment.LawyerId == i.Sachbearbeiter_ID;
                });

                return this.constructDay(filtered);
            });
            return lanes;
        });

        setTimeout(() => { this.toggle(-1); }, 1000);
        $("div, scrollelement").animate({ scrollTop: 575 }, "fast");

    }

    private constructDay(appointments: Array<any>) {
        let lanes: Array<Array<any>> = [[]];

        for (let appointment of appointments) {
            let start = moment.utc(appointment.AppointmentDate);
            let inserted = false;

            for (let lane of lanes) {
                if (lane.length === 0 || moment(lane[lane.length - 1].AppointmentDate).isBefore(start)) {
                    lane.push(appointment);
                    inserted = true;
                    break;
                }
            }

            if (!inserted) {
                lanes.push([appointment]);
            }
        }

        return lanes;
    }
}

let html = fs.readFileSync(__dirname + '/person.html', 'utf8');

ko.components.register("person-view", {
    viewModel: AppointmentPersonViewModel,
    template: html
});
