import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment"
import { RNSAPI } from "../../api";
import { MainViewModel } from "../../main";
import "../appointment/weekly";
import { DateTime } from 'luxon';

class AppointmentWeeklyViewModel {
    appointments = ko.observableArray([]);
    deadlines = ko.observableArray([]);
    resubmissions = ko.observableArray([]);
    parentVM: any;
    startOfDay = ko.observable();
    wholeWeek = ko.observable(true);

    constructor(params) {
        this.appointments = params.appointments;
        this.deadlines = params.deadlines;
        this.resubmissions(params.resubmissions);
        this.parentVM = params.vm;
        this.startOfDay = params.date;
    }
}

let html = fs.readFileSync(__dirname + '/weekly-whole.html', 'utf8');

ko.components.register("weekly-whole-view", {
    viewModel: AppointmentWeeklyViewModel,
    template: html
});
