import * as fs from 'fs';
import * as ko from "knockout";
import {MainViewModel} from "../../main";
import {RNSAPI} from "../../api";
import { Utils } from '../../utils';
import './fastcaseperson';
import '../dialog/message';

// Fragen:
//  - was sind die erweiterten Daten
//  - wie entferne ich einen Mandaten wieder
//  - Anrede und Rechtsform wählbar, aber Anrede hängt von Rechtsform ab
//  - Die Buttons zur Addition von weiteren Gegner und die zum Ausklappen sehen alle gleich aus
//  - Warum sind Gegner optional, wenn sie für die Kollisionsprüfung wichtig sind
//
//  Notiz_Mandantenbegehren
//  Notiz_Sachverhalt
//  Notizen

class FastCaseViewModel {
    clients: ko.ObservableArray<Person> = ko.observableArray([
        {
            personType: ko.observable("mandant"),
            isFirst: ko.observable(true),
            isShown: ko.observable(true),
            isMoreShown: ko.observable(false),
            isError: ko.observable(false),
            isExtended: ko.observable(false),
            data: createPersonObservable()
        }
    ]);

    defendants: ko.ObservableArray<Person> = ko.observableArray([
        {
            personType: ko.observable("gegner"),
            isFirst: ko.observable(true),
            isShown: ko.observable(true),
            isMoreShown: ko.observable(false),
            isError: ko.observable(false),
            isExtended: ko.observable(false),
            data: createPersonObservable()
        }
    ]);


    mandantenbegehren = ko.observable("");
    notizen = ko.observable("");
    sachverhalt = ko.observable("");
    registernummer = ko.observable("");
    currentDate = ko.observable(null);
    CurrentUser = ko.observable("");
    SelectedUser = ko.observable("");
    referat = ko.observable("");
    collisionErrored = ko.observable(false);

    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    infoMessage = ko.observable('');
    infoTitle = ko.observable('');
    modialAutoHide = ko.observable(false)
    modalHandleSelection = ko.observable();

    collision: ko.Observable<Collision> = ko.observable(CollisionOff);

    fastcaseErrorMessage = ko.observable<string>('');

    closeCollision(): void {
        this.collision(CollisionOff);
    }    

    collapseGegner = () => {
        let el = document.getElementById("collapseGegnerField");
        if (el.classList.contains("collapse"))
            el.classList.remove("collapse");
        else
            el.classList.add("collapse");
    }

    async myCheckCollision() {
        if(!ko.toJS(this.allClerks()).find(a => a.Sachbearbeiter_ID === this.SelectedUser())) {
            this.fastcaseErrorMessage('Der eingegebene Sachbearbeiter existiert nicht!');
            return;
        }

        if(!ko.toJS(this.allReferate()).find(a => a.Name === this.referat())) {
            this.fastcaseErrorMessage('Das eingegebene Referat existiert nicht!');
            return;
        }

        this.fastcaseErrorMessage('');


        let clients = this.clients();
        let defendants = this.defendants();
        let clientsPeople: string[] = [];
        let defendandtsPeople: string[] = [];
        let clientsCollisions = [];
        let defendantsCollisions = [];
        let RequiredFieldsLoaded = true;

        let hasErrors = false;
        
        if (defendants.length === 1 && !defendants[0].data.Name1()) {
            this.defendants.remove(this.defendants()[0]);
        }

        if (ko.toJS(this.registernummer) === '') {
            this.setErrors('regnr', 'regnr', 0);
            RequiredFieldsLoaded = false;
            hasErrors = true;
        }


        if (ko.toJS(this.referat) === '') {
            this.setErrors('referat', 'referat', 0);
            RequiredFieldsLoaded = false;
            hasErrors = true;
        }

        for (let i = 0; i < defendants.length; i++) {
            $(`#defendant-rechtsform-` + i).parent().removeClass('has-error');
            $(`#defendant-last-name-` + i).parent().removeClass('has-error');

            if (!defendants[i].data.Rechtsform()) {
                this.setErrors('defendant', 'rechtsform', i);
                RequiredFieldsLoaded = false;
                hasErrors = true;
            } else {
                defendants[i].isError(false);
                defendandtsPeople.push(ko.toJS(defendants[i].data.Rechtsform));
            }


            if (!defendants[i].data.Name1()) {
                hasErrors = true;

                defendants[i].isShown(true);
                defendants[i].isMoreShown(true);
                defendants[i].isError(true);
                document.getElementById('defendant-last-name-' + i).scrollIntoView()
                $('#defendant-last-name-' + i).parent().addClass('has-error');
                setTimeout(() => $('#defendant-last-name-' + i).parent().removeClass('has-error'), 1500);
                RequiredFieldsLoaded = false;
            } else {
                defendants[i].isError(false);
                defendandtsPeople.push(ko.toJS(defendants[i].data.Name1));
            }
        }

        for (let i = 0; i < clients.length; i++) {
            $(`#client-rechtsform-` + i).parent().removeClass('has-error');
            $(`#client-first-name-` + i).parent().removeClass('has-error');
            $(`#client-last-name-` + i).parent().removeClass('has-error');
            $(`#client-phone-` + i).parent().removeClass('has-error');

            if(!clients[i].data.Rechtsform()) {
                this.setErrors('client', 'rechtsform', i);
                RequiredFieldsLoaded = false;
                hasErrors = true;
            } else {
                clients[i].isError(false);
                clientsPeople.push(ko.toJS(clients[i].data.Rechtsform));
            }


            if(!clients[i].data.Rechtsform()) {

                if(!clients[i].data.Name1()) {
                    this.setErrors('client', 'last-name', i);
                    RequiredFieldsLoaded = false;
                    hasErrors = true;
                } else {
                    clients[i].isError(false);
                    clientsPeople.push(ko.toJS(clients[i].data.Name1));
                }
            }

            if (!clients[i].data.Name1() && (clients[i].data.Rechtsform() === "Herr" || clients[i].data.Rechtsform() === "Frau" || clients[i].data.Rechtsform() === "Divers")) { 
                this.setErrors('client', 'last-name', i);
                RequiredFieldsLoaded = false;
                hasErrors = true;
            } else if(!clients[i].data.Name1()) {
                this.setErrors('client', 'last-name', i);
                    RequiredFieldsLoaded = false;
                    hasErrors = true;
            } else {
                clients[i].isError(false);
                clientsPeople.push(ko.toJS(clients[i].data.Name1));
            }

            // if (!clients[i].data.FirstName() && (clients[i].data.Rechtsform() === "Herr" || clients[i].data.Rechtsform() === "Frau" || clients[i].data.Rechtsform() === "Divers")) {
            //     this.setErrors('client', 'first-name', i);
            //     RequiredFieldsLoaded = false;
            //     hasErrors = true;
            // } else {
            //     clients[i].isError(false);
            //     clientsPeople.push(ko.toJS(clients[i].data.FirstName));
            // }
        }

        if(hasErrors) return;

        if (RequiredFieldsLoaded) {

            this.collision(CollisionLoading);

            for (let lastName of defendandtsPeople) {
                let collisions = await RNSAPI.checkCollision(lastName)
                defendantsCollisions = defendantsCollisions.concat(collisions.Payload.Addresses[0]);
            }

            for (let lastName of clientsPeople) {
                let collisions = await RNSAPI.checkCollision(lastName)
                clientsCollisions = clientsCollisions.concat(collisions.Payload.Addresses[1]);
            }

            for (let client of clients) {
                for (let defen of defendants) {
                    if (client.data.Name1() === defen.data.Name1()) {
                        defendantsCollisions.push({
                            FirstName: defen.data.FirstName(),
                            Name1: defen.data.Name1(),
                            Address: "",
                            ZipCode: "",
                            CityName: "",
                        });
                    }
                }
            }

            this.collision({
                kind: "CollisionLoaded",
                clients: ko.observableArray(clientsCollisions),
                defendants: ko.observableArray(defendantsCollisions)
            });
        }
    }

    setErrors(prefix, type, i) {
        this.clients()[i].isShown(true);
        this.clients()[i].isMoreShown(true);
        this.clients()[i].isError(true);
        document.getElementById(`${prefix}-${type}-` + i).scrollIntoView()
        $(`#${prefix}-${type}-` + i).parent().addClass('has-error');
        this.collisionErrored(true)
        setTimeout(() => {
            this.collisionErrored(false)
        }, 10000);
    }

    async pickGeneric(title, keys, columns, data, callback) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
        this.modalHandleSelection(callback);
    };

    addPerson(personType) {
        let array = personType === 'mandant' ? this.clients : this.defendants;
        array.push({
            personType: ko.observable(personType),
            isFirst: ko.observable(false),
            isShown: ko.observable(true),
            isMoreShown: ko.observable(false),
            isError: ko.observable(false),
            isExtended: ko.observable(false),
            data: createPersonObservable()
        });
    }

    deleteDefendant(index: number): void {
        if (window.confirm('Sind Sie sicher, dass Sie die Gegnerdaten löschen möchten?')) {
            this.defendants.remove(this.defendants()[index]);
        }
    }

    deleteClient(index: number): void {
        if (window.confirm('Wollen Sie den Mandanten entfernen.?')) {
            this.clients.remove(this.clients()[index]);
        }
    }

    allClerks = ko.observableArray([]);
    allReferate = ko.observableArray([]);

    private async loadClerks() {
        this.allClerks((
            await RNSAPI.getSachbearbeiter()
        ).Payload.Clerks.filter((s) => s.Sachbearbeiter_ID.trim() !== ""));
    }

    private async loadReferate() {
        this.allReferate((await RNSAPI.getReferate()).Payload.Units);
    }

    pickSachbearbeiter = async () => {
        this.fastcaseErrorMessage('');
        await this.loadClerks();
        let sachbearbeiter = ko.toJS(this.allClerks());

        this.pickGeneric2("Sachbearbeiter", ["Sachbearbeiter"], ["Sachbearbeiter"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => this.SelectedUser(selectedObject()["Sachbearbeiter_ID"]));
        $('#modal').modal('show');
    };

    async pickGeneric2(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    };

    async pickReferat() {
        await this.loadReferate();
        const referate = ko.toJS(this.allReferate());

        this.pickGeneric2("Referat", ["Name", "Id"], ["Name", "Id"], referate);
        this.modalHandleSelection((selectedObject) => this.referat(selectedObject()["Name"]));
        $('#modal').modal('show');
    }

    async getLastCase() {
        const cases = (await RNSAPI.getCases()).Payload.Cases;
        if (cases.length) {
            this.referat(await this.getReferateCode(cases[cases.length - 1].Referat, 'Name'))
        }
    }

    async getReferateCode(value, type) {
        const key = type === 'Name' ? 'Id': 'Name'
        const referate = (await RNSAPI.getReferate()).Payload.Units;
        const found = referate.filter(item => item[key] === value)[0]
        return found[type]
    }

    private removeEmptyDefendantsData() {
        for(const entry of this.defendants()) {
            if(!entry.data.Name1()) {
                this.defendants.remove(entry);
            }
        }
    }

    async postData() {
        let caseData={Registernummer:"",Rubrum:""};

        if (this.defendants().length == 0) {

            caseData = {
                Registernummer: this.registernummer(),
                Rubrum: createRubrum(this.clients()[0].data.Name1(),null)
            };
        } else {
            caseData = {
                Registernummer: this.registernummer(),
                Rubrum: createRubrum(this.clients()[0].data.Name1(), this.defendants()[0].data.Name1())
            };
        }

        this.removeEmptyDefendantsData();

        let addresses = [
            ko.toJS(this.clients as any).map(p => p.data),
            ko.toJS(this.defendants as any).map(p => p.data),
            [],
            [],
            [],
            [],
            [],
            [],
            []
        ];

        for(let i =0; i<addresses[0].length; i++)
        {
            let mand  = addresses[0][i].Name1; 
            if(mand.length>30)
            {
                addresses[0][i].Name2 =  mand.substring(30, mand.length);
            }
            if(mand.length>60)
            {
                addresses[0][i].Name3 =  mand.substring(60, mand.length);
            }
        }

        for(let i =0; i<addresses[1].length; i++)
        {
            let mand  = addresses[1][i].Name1; 
            if(mand.length>30)
            {
                addresses[1][i].Name2 =  mand.substring(30, mand.length);
            }
            if(mand.length>60)
            {
                addresses[1][i].Name3 =  mand.substring(60, mand.length);
            }
        }
        let extendedCase = {
            Key: this.registernummer(),
            Infos: {
                Notiz_Mandantenbegehren: this.mandantenbegehren(),
                Notiz_Sachverhalt: this.sachverhalt(),
                Notizen: this.notizen()
            },
            Persons: [],
        }

        let data: any = {
            Akte: caseData,
            Addresses:addresses,
            ExtendedPersons: [],
            ExtendedCase: extendedCase
        };

       
        try {
            data.Akte.SachbearbeiterId = this.SelectedUser();
            data.Akte.Referat = await this.getReferateCode(this.referat(), 'Id') || this.referat();
            let result = await RNSAPI.createExtendedCase(data);

            if (result.Type === "CaseCreationError") {
                this.suggestRegisterNumber();
                this.infoTitle('')
                this.infoTitle('Fehler')
                this.infoMessage('');
                this.infoMessage('Fehler: Die Akte konnte nicht angelegt werden.');
                return
            }
            else {
                MainViewModel.RoutingTable.showNewView({ caseId: caseData.Registernummer });
            }
        } catch (e) {
            let error = Utils.handleError(e);
            this.infoMessage('');
            this.infoMessage(error['code'] + ': ' + error['message']);
        }
    }

    constructor(params) {
        this.loadClerks();
        this.loadReferate();

        params = params || {};

        this.suggestRegisterNumber();
        this.setCurrentData();
        this.SelectedUser(ko.toJS(this.CurrentUser()));
        this.getLastCase()
        $(function () {
            $('[data-toggle="tooltip"]').tooltip()
        })
    }
    async setCurrentData() {
        this.CurrentUser(RNSAPI.User().username);
        var Heute = new Date();
        var TheDay = Heute.getDate();
        var TheMonth = Heute.getMonth() + 1;
        var TheYear = Heute.getFullYear();
        var Heutee = "";

        if (TheDay < 10) {
            Heutee += "0" + TheDay;
        } else {
            Heutee += TheDay;
        }

        if (TheMonth < 10) {
            Heutee += '.0' + TheMonth;
        } else {
            Heutee += '.' + TheMonth
        }

        Heutee += '.' + TheYear;

        this.currentDate(Heutee);
    }

    async suggestRegisterNumber() {

        let xregisternummer = (await RNSAPI.suggestCaseId()).Payload.CaseId;
        this.registernummer(xregisternummer);
    }

    removeClient(index) {
        if (window.confirm('Sind Sie sicher, dass die Gegnerdaten löschen möchten?')) {
            this.deleteClient(index)
        }
    }
}

function createRubrum(clientName: string, defendantName: string): string {
    if (clientName && defendantName)
        return (clientName + " ./. " + defendantName).substring(0, 64);
    else
        return clientName.substring(0, 64)
}

function isSamePerson(localPerson: any, serverPerson: any): boolean {
    return (localPerson.data.Name1() + localPerson.data.FirstName()).substring(0, 20) === serverPerson.Keyword;
}


function createPersonObservable(): any {
    let obj = {
        FirstName: ko.observable(null),
        Name1: ko.observable(null),
        Rechtsform: ko.observable(null),
        Address: ko.observable(null),
        ZipCode: ko.observable(null),
        CityName: ko.observable(null),
        EmailAddress: ko.observable(null),
        Phone1: ko.observable('+49'),
        // homepage, geburtsort, staatsangehörigkeit ???
        Fax: ko.observable(null),
        MobilePhone: ko.observable(null),
        AnredeBriefkopf: ko.observable(null),
        AnredeDirekt: ko.observable(null),
        BirthDate: ko.observable(null),
        Adresszusatz: ko.observable(null),
        edit: ko.observable(true),
        IsSelected: ko.observable(false)
    };

    obj["Keyword"] = ko.computed(() => {
        const keywordString = (obj.Name1() || '') + (obj.FirstName() || '');
        return keywordString.substring(0,20);
    });
    return obj;
}

export interface Person {
    personType: any,
    isFirst: any,
    isShown: any,
    isMoreShown: any,
    isError: any,
    isExtended: any,
    data: any,
}

type Collision = CollisionOff | CollisionLoading | CollisionLoaded;

interface CollisionOff {
    kind: "CollisionOff";
}

let CollisionOff: CollisionOff = {kind: "CollisionOff"};

interface CollisionLoading {
    kind: "CollisionLoading";
}

let CollisionLoading: CollisionLoading = {kind: "CollisionLoading"};

interface CollisionLoaded {
    kind: "CollisionLoaded";
    clients: any;
    defendants: any;
}

let html = fs.readFileSync(__dirname + '/fastcase.html', 'utf8');

ko.components.register("fastcase-view", {
    viewModel: FastCaseViewModel,
    template: html
});
