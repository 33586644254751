import * as fs from "fs";
import * as ko from "knockout";
import Navigo = require("navigo");
import { RNSAPI } from "../../api";
import { User } from "../../UserData";
import { MainViewModel } from "../../main";
import { Utils } from "../../utils";
import { Postbox } from "../dashboard/postbox";

export class LoginViewModel {
    router = new Navigo(null, true);
    token = ko.observable("");
    password = ko.observable('');
    username = ko.observable('')
    courtId = ko.observable('')
    logins = ko.observable({
        username: ko.observable(''),
        courtId: ko.observable(''),
        password: ko.observable('')
    });


    checkLogin = ko.observable(false);
    resetPasswordForm = ko.observable(false);
    resetPasswordFormSite = ko.observable(0);
    resetPasswordData = ko.observable({
        LawFirmId: ko.observable(''),
        Username: ko.observable(''),
        Password: ko.observable(''),
        PasswordRetry: ko.observable(''),
        Mail: ko.observable(''),
        Code: ko.observable('')
    });
    resetPasswordErrorMessage = ko.observable("");
    processResetPasswordForm = ko.observable(false);
    


    gotoRegister() {
        this.router.navigate('/register');
        localStorage.setItem('path', "register");
    }
    reset() {
        if (!this.username() || !this.courtId()) {
            $("#pc_validation_message").show();
            $("#pc_message").html("Bitte geben Sie die korrekten Zugangsdaten Ihres Rainmaker-Kontos an.");
            //$("#loginError").html("Es wurden nicht alle erforderlichen Eingabefelder ausgefüllt.")
            return;
        }
        localStorage.setItem('username', ko.toJS(this.username()));
        localStorage.setItem('courtId', ko.toJS(this.courtId()));
        this.router.navigate('/reset');
    }
    //MARK: - Change happened.
    navigateToResetPassword() {

        if (!Utils.checkMultipleErrors(["username", "courtId"], this.logins(), "", [Utils.checkString])) {
            return;
        }
        localStorage.setItem('LawFirmInfo', JSON.stringify({
            ShorthandSymbol: this.logins().username(),
            LawFirmId: this.logins().courtId()
        }))
        this.router.navigate('/reset');
    }

    delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    resetPassword() {
        this.resetPasswordFormSite(0);
        this.resetPasswordForm(true);
        this.resetPasswordData().Code("");
        this.resetPasswordData().LawFirmId("");
        this.resetPasswordData().Username("");
        this.resetPasswordData().Mail("");
    }

    gotoLogin() {
        this.resetPasswordForm(false);
    }

    async nextStepResetPasswordForm() {
        this.processResetPasswordForm(true);
        this.resetPasswordErrorMessage("");
        switch(this.resetPasswordFormSite()) {
            case 0: 
                if(this.resetPasswordData().LawFirmId() !== "" && this.resetPasswordData().Username() !== "") {
                    this.resetPasswordFormSite(this.resetPasswordFormSite() + 1);
                } else {
                    this.resetPasswordErrorMessage('Bitte geben Sie Ihre Kundennummer und Ihr Benutzerkürzel ein.');
                }
                break;
            case 1:
                const passwordMail = await this.checkResetPasswordMail();
                if(!passwordMail) { 
                    this.resetPasswordErrorMessage('Die eingegebene E-Mail Adresse konnte nicht bestätigt werden.');
                } else {
                    this.resetPasswordFormSite(this.resetPasswordFormSite() + 1);
                }
                break;
            case 2:
                const mailCode = await this.checkMailCode();
                if(!mailCode) { 
                    this.resetPasswordErrorMessage('Der eingegebene Bestätigungscode ist falsch.');
                } else {
                    this.resetPasswordFormSite(this.resetPasswordFormSite() + 1);
                }
               break;
            case 3:
                if(this.resetPasswordData().Password() !== this.resetPasswordData().PasswordRetry()) {
                    this.resetPasswordErrorMessage("Die eingegebenen Passwörter stimmen nicht überein.");
                } else {
                    if(/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/.test(ko.toJS(this.resetPasswordData().Password).toString())) {
                        const changePassword = await this.changePassword();
                        if(!changePassword) { // !
                            this.resetPasswordErrorMessage('Das Passwort konnte nicht zurückgesetzt werden');
                        } else {
                            this.resetPasswordFormSite(this.resetPasswordFormSite() + 1);
                        }
                    } else {
                        this.resetPasswordErrorMessage("Bitte mindestens 8 Zeichen und eine Kombination aus Kleinbuchstaben, Großbuchstaben, Ziffern und Sonderzeichen verwenden.");
                    }
                }
                break;
        }
        this.processResetPasswordForm(false);
    }

    async changePassword(): Promise<boolean> {
        let passwordChanged = false;
        await RNSAPI.resetAccountPassword({ LawFirmId: this.resetPasswordData().LawFirmId(), Username: this.resetPasswordData().Username(), Password: this.resetPasswordData().Password() }).then(data => {
            passwordChanged = true;
        }).catch(error => {
            passwordChanged = false;
        });
        return passwordChanged;
    }
   
    async checkResetPasswordMail(): Promise<boolean> {
        let verifiedMail = false;
        await RNSAPI.verifyAccountMail({ LawFirmId: this.resetPasswordData().LawFirmId(), Username: this.resetPasswordData().Username(), Mail: this.resetPasswordData().Mail() }).then(data => {
            verifiedMail = true;
        }).catch(error => {
            verifiedMail = false;
        });
        return verifiedMail;
    }

    async checkMailCode(): Promise<boolean> {
        let verifiedCode = false;
        await RNSAPI.verifyAccountMailCode({ LawFirmId: this.resetPasswordData().LawFirmId(), Username: this.resetPasswordData().Username(), Code: this.resetPasswordData().Code() }).then(data => {
            verifiedCode = true;
        }).catch(error => {
            verifiedCode = false;
        });
        return verifiedCode;
    }

    login() {
        if (!this.logins().password() || !this.logins().username() || !this.logins().courtId()) {
            $("#pc_validation_message").show();
            $("#pc_message").html("Es wurden nicht alle Pflichtfelder ausgefüllt.");
            return;
        }

        this.checkLogin(true);

        RNSAPI.authUser({ LawFirmId: this.logins().courtId(), Username: this.logins().username(), Password: this.logins().password()}).then(data => {
            RNSAPI.login(data["AccessToken"]);
            Postbox.publisher().publish("", "setLoggedInUserHeader");
            Postbox.publisher().publish("", "setLoggedInUserLayout");
            const userInfo = RNSAPI.getCurrentLoggedInUser();
            if(userInfo) {
                if(userInfo.LicenseExpired === '1') {
                    if(userInfo.AdminRight === '1') {
                        MainViewModel.RoutingTable.showSettingsView({ view: "konto" });
                        return;
                    } else {
                        Utils.eraseCookie('access_token');
                        RNSAPI.sessionLogout();
                        this.checkLogin(false);
                        document.getElementById('loginloader').setAttribute("style", "display: none;");
                        $("#pc_validation_message").show();
                        $("#pc_message").html("Der rainmaker Zugang wurde deaktiviert. Bitte wenden Sie sich an Ihren Kanzlei-Administrator.");
                    }
                    return;
                }
            }
            MainViewModel.RoutingTable.showDashboardView();
        }).catch(error => {
            if(error.status === 400) {
                this.checkLogin(false);
                document.getElementById('loginloader').setAttribute("style", "display: none;");
                $("#pc_validation_message").show();
                $("#pc_message").html("Bitte geben Sie die korrekten Zugangsdaten Ihres Rainmaker-Kontos an.");
            }
        });
    }

    constructor() {
        let x = localStorage.getItem('path');
        if (x == "register") {
            this.router.navigate('/register');
        }
        if (typeof localStorage !== 'undefined') {
            try {
                if (localStorage.getItem('LawFirmInfo')) {
                    let lawFirmInfo = JSON.parse(localStorage.getItem('LawFirmInfo'))
                    this.logins().username(lawFirmInfo['ShorthandSymbol'])
                    this.logins().courtId(lawFirmInfo['LawFirmId'])
                }
            } catch (e) { }
        }

        RNSAPI.AuthenticationFailed = 0;
    }
}

var html = fs.readFileSync(__dirname + '/login.html', 'utf8');

ko.components.register("login-view", {
    viewModel: LoginViewModel,
    template: html
});