import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI, Routing } from "../../api";
import Navigo = require("navigo");
import '../dialog/dialog';
import { DialogViewModel } from '../dialog/dialog';
import { DataTableViewModel, IntermediateListItem, Color } from '../dataTable/dataTable';

import { MainViewModel } from "../../main";
import { Utils } from "../../utils";

export class FachsucheViewModel {

    router = new Navigo(null, true);

    async getData() {
        let data = await RNSAPI.getRecherche(RNSAPI.User().username);
        if (data.Type === "GetRechercheSuccessful") {
            var theForm, newInput1, newInput2;
            theForm = document.createElement('form');
            theForm.action = 'https://www.soldan.de/fachsuche/loginrm';
            theForm.method = 'post';
            newInput1 = document.createElement('input');
            newInput1.type = 'hidden';
            newInput1.name = 'email';
            newInput1.value = data.Payload.RechercheEMail;
            newInput2 = document.createElement('input');
            newInput2.type = 'hidden';
            newInput2.name = 'password';
            newInput2.value = data.Payload.RecherchePassword;
            theForm.appendChild(newInput1);
            theForm.appendChild(newInput2);
            document.getElementById('hidden_form_container').appendChild(theForm);
            theForm.submit();
        }
        else {
            console.log("Errormeldung")
        }
    }

    constructor() {
        this.getData();
    }

}


let html = fs.readFileSync(__dirname + '/fachsuche.html', 'utf8');

ko.components.register("fachsuche-view", {
    viewModel: FachsucheViewModel,
    template: html
});
