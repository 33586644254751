import * as ko from "knockout";
import * as fs from "fs";

class IconViewModel { }

let svg = fs.readFileSync(__dirname + '/../../../node_modules/trumbowyg/dist/ui/icons.svg', 'utf8');

ko.components.register("editor-icons", {
    viewModel: IconViewModel,
    template: svg
});
