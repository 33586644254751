import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { MainViewModel } from "../../main";
import '../dataTable/dataTable';
import '../extended/new';
import '../appointment/daily';
import '../appointment/weekly';
import '../appointment/person';
import '../appointment/monthly';
import '../dialog/appointmentdialog'
import '../deadline/extend';
import '../deadline/delegate';
import '../calendar/calendar';
import '../note/notes';
import '../dialog/dialogYN';
import { DataTableViewModel, IntermediateListItem, Color } from '../dataTable/dataTable';
import { Utils } from '../../utils';
import * as moment from "moment"
import "moment/locale/de";
import * as fs from 'fs';
import { Interval } from '../workflow/zpe';
import { Transform } from "stream";
import { Filter } from "../case/caseentryoverview";
import { Postbox } from "../dashboard/postbox";
import { RolesAndRights } from "../../helpers/RolesAndRights";
import "../dialog/ConfirmFTWDialog";


enum Status {
    Done = 'E',
    New = 'N',
    Running = 'L',
    FurtherTerminating = 'W',
}
export class KalendarAllViewModel {
    addressTypes = ko.observableArray(["Mandant", "Gegner", "Sonstige", "Gericht", "Versicherung", "Behörde", "Anwalt"]); //, "Gericht", "Versicherung", "Behörde", "Anwalt"
    appointments = ko.observableArray([]);
    deadlines = ko.observableArray([]);
    resubmissions = ko.observableArray([]);
    todos = ko.observableArray([]);
    lastWorkedOn = ko.observableArray([]);
    isempty = ko.observable("-/-");
    isemptyDate = ko.observable("TT.MM.JJJJ")
    selectedAddress = ko.observable(this.addressTypes()[0]);
    defaultSachbearbeiter = ko.observable();
    // sachbearbeiters = ko.observableArray([{Sachbearbeiter: "Evelyn Meier", Sachbearbeiter_ID: "EM", Id: 3}, {Sachbearbeiter: "Hans Gruendlich", Sachbearbeiter_ID: "HG", Id: 1}, {Sachbearbeiter: "Franziska Obermeier", Sachbearbeiter_ID: "FO", Id: 4}, {Sachbearbeiter: "Gustav Gründlich", Sachbearbeiter_ID: "GR", Id: 2}, {Sachbearbeiter: "Marco Buhleier", Sachbearbeiter_ID: "MB", Id: 6}, {Sachbearbeiter: "Oliver Weiß", Sachbearbeiter_ID: "OW", Id: 5}]);
    sachbearbeiters = ko.observableArray([]);
    selectedSachbearbeiters = ko.observableArray([]);
    Rubrum = ko.observable("Kein Rubrum vorhanden.");
    days = [];

    isViewerRole = ko.observable<boolean>(false);

    AllSB = ko.observableArray();
    newSB = ko.observable("");
    newSB1 = ko.observable("");
    oldDeadline = ko.observable(null);
    extendDeadlineDate = ko.observable();

    readonly DANGER_COLOR: Color = {
        color: "white",
        "background-color": "#e21b38" //be4259
    }

    wholeday = ko.observable(false);

    currentView: ko.Observable<string>;

    viewTypes = [{ name: "Tagesansicht", view: "daily-view" }, { name: "Wochenansicht", view: "person-view" }];
    selectedType = ko.observable(this.viewTypes[1].view);
    selectedBtn = ko.observable();

    colHasChanged = ko.observable(false);

    changeCol = () => {
        this.colHasChanged(this.colHasChanged() ? false : true);
    }

    pickedWeekDate = ko.observable(moment.utc().startOf("day"));

    today = () => this.pickedWeekDate(moment.utc().startOf("day"));

    nextInterval = () => {
        this.pickedWeekDate(this.startOfDay().clone().add(1, "day"));
        this.update();
    }

    previousInterval = () => {
        this.pickedWeekDate(this.startOfDay().clone().subtract(1, "day"));
        this.update();
    }

    startOfDay = ko.computed(() => {
        let weekDate = this.pickedWeekDate().clone();
        let selectedType = this.selectedType();

        if (selectedType === "weekly-view") {
            return weekDate.startOf("week");
        } else {
            return weekDate;
        }
    });

    endOfDay = ko.computed(() => {
        let weekDate = this.pickedWeekDate().clone();
        let selectedType = this.selectedType();

        if (selectedType === "weekly-view") {
            let startOfWeek = weekDate.startOf("week");
            return startOfWeek.clone().add(4, "days").endOf("day");
        } else {
            return weekDate.endOf("day");
        }
    });
    newAppointment = async () => {
        console.log("Test Modal apps");
        $('#AppointmentTestmodal').modal('show');
    }
    intervalText = ko.computed(() => {
        let startOfDay = this.startOfDay().clone();
        let endOfDay = this.endOfDay().clone();
        let selectedType = this.selectedType();

        if (selectedType === "weekly-view") {
            return `KW ${startOfDay.format("W")} \u2013 ${startOfDay.format("D. MMMM")} bis ${endOfDay.format("D. MMMM YYYY")}`;
        } else {
            return startOfDay.format("D. MMMM YYYY");
        }
    });

    startOfThisMonth: moment.Moment;
    startOfNextMonth: moment.Moment;

    deadlineToBeModified = ko.observable(null);
    // added by aron
    totalInactive = ko.observable();

    constructor(params: any) {
        this.isViewerRole(RolesAndRights.isViewerRole());
        moment.locale("de");
        this.currentView = ko.observable('todos');
        if (params.view && params.view === 'termin') {
            //$("#NewAppointmentDialog").modal('show')
            $('#AppointmentTestmodal').modal('show');
            console.log("here again")
        }
        if (params.view && params.view === 'frist') {
            $("#NewDeadlineDialog").modal('show')
        }
        this.updateData('meinKalender');


        this.startOfThisMonth = moment.utc().startOf("month");
        this.startOfNextMonth = moment.utc().startOf("month").add(1, "month");

        for (var i = 0; i < 24; i++) {
            this.times.push(this.adjustDigits(i, 2) + ":" + "00");
            this.times.push(this.adjustDigits(i, 2) + ":" + "30");
        }
        this.oldDeadline = params;
        this.update();
    }

    public getVM = () => this;

    public OpebJN() {

        $('#Testmodal').modal('show');
    }
    async updateAppointments(allAppointments: any) {
        try {
            let appointments = allAppointments.Payload.Appointments.map(a => ({ ...a })).filter((a: any) => a.AppointmentType !== "F");
            let transformed = appointments.map(obj => {
                if(!this.isViewerRole()) {
                    obj.actionHandlers = [
                        { icon: "pencil-alt", name: "Edit", action: () => MainViewModel.RoutingTable.showAppointmentView({ id: obj.Id }) },
                        {
                            icon: "trash-alt", name: "Löschen", action: async () => {
                                Postbox.publisher().publish({ ConfirmType: "APPOINTMENT", Reason: obj.AppointmentName, CaseId: "", Id: obj.Id, IsDeleteItem: true }, "ConfirmFTWModalData");
                                // this.update();
                            }
                        },
                    ];
                }
                return obj;
            });
            this.appointments(transformed);
        } catch (e) {
            console.log("Could not retrieve appointments.");
            console.log(e);
        }
    }

    public async updateData(value) {
        this.selectedBtn(value);
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
         //let sachbearbeiter = [{Sachbearbeiter: "Evelyn Meier", Sachbearbeiter_ID: "EM", Id: 3}, {Sachbearbeiter: "Franziska Obermeier", Sachbearbeiter_ID: "FO", Id: 4}, {Sachbearbeiter: "Gustav Gründlich", Sachbearbeiter_ID: "GR", Id: 2}, {Sachbearbeiter: "Hans Gruendlich", Sachbearbeiter_ID: "HG", Id: 1}, {Sachbearbeiter: "Marco Buhleier", Sachbearbeiter_ID: "MB", Id: 6}, {Sachbearbeiter: "Oliver Weiß", Sachbearbeiter_ID: "OW", Id: 5}, {Sachbearbeiter: "Tesfay Gebremeskel Chekole", Sachbearbeiter_ID: "TC", Id: 7}];
         console.log(sachbearbeiter);
        if(this.selectedBtn() == 'alle'){
            this.sachbearbeiters(sachbearbeiter);
        }
        if(this.selectedBtn() == 'meinKalender'){
            let username = RNSAPI.User().username;
            // let username = 'HG';
            let filtered = sachbearbeiter.filter((a: any) => a.Sachbearbeiter_ID == username);
            this.sachbearbeiters(filtered);
        }
        if(this.selectedBtn() == 'benutzer'){
            this.pickSachbearbeiterPerson();
        }
        if(this.selectedBtn() == 'gruppe'){
            this.selectedSachbearbeiters([]);
            this.sachbearbeiters().forEach(value => {
                this.selectedSachbearbeiters.push(value);
            });
            $('#selectUsersDialog').modal('show');
            // this.pickSachbearbeiterPerson();
        }
    }

    pickSachbearbeiterPerson = async () => {
        let users=await RNSAPI.getLawFirmUsers();
        console.log(users);
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        //let sachbearbeiter = [{Sachbearbeiter: "Evelyn Meier", Sachbearbeiter_ID: "EM", Id: 3}, {Sachbearbeiter: "Hans Gruendlich", Sachbearbeiter_ID: "HG", Id: 1}, {Sachbearbeiter: "Franziska Obermeier", Sachbearbeiter_ID: "FO", Id: 4}, {Sachbearbeiter: "Gustav Gründlich", Sachbearbeiter_ID: "GR", Id: 2}, {Sachbearbeiter: "Marco Buhleier", Sachbearbeiter_ID: "MB", Id: 6}, {Sachbearbeiter: "Oliver Weiß", Sachbearbeiter_ID: "OW", Id: 5}];
        this.pickGeneric("Sachbearbeiter", ["Sachbearbeiter"], ["Sachbearbeiter"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => {
            if(this.selectedBtn() == 'benutzer'){
                this.sachbearbeiters([selectedObject()]);
            }
            else if(this.selectedBtn() == 'gruppe'){
                if(this.selectedSachbearbeiters().findIndex(x => x.Sachbearbeiter_ID == selectedObject()['Sachbearbeiter_ID']) == -1){
                    this.selectedSachbearbeiters.push(selectedObject());
                }
            }
        });
        $('#modal').modal('show');
    };
    removeuser(index: number) {
        return this.selectedSachbearbeiters.splice(index, 1);
    }
    transferSelection(){
        this.sachbearbeiters(this.selectedSachbearbeiters());
        this.sachbearbeiters([]);
            this.selectedSachbearbeiters().forEach(value => {
                this.sachbearbeiters.push(value);
            });
    }

    public async update() {
        let allAppointments = await RNSAPI.getAppointments(this.startOfDay().toDate(), this.endOfDay().toDate());
        for (let appointment of allAppointments.Payload.Appointments) {
            if (appointment['CaseId']) {
                let casedetails = await RNSAPI.getCaseByCaseId(appointment['CaseId']);
                appointment['Rubrum'] = casedetails.Payload.Case['Rubrum'];
            }
            else {
                appointment['Rubrum'] = "";
            }
            if (appointment["AppointmentType"] === "B")
                appointment["AppointmentType"] = "Besprechung";
            else if (appointment["AppointmentType"] === "G")
                appointment["AppointmentType"] = "Gerichtstermin";
            else if (appointment["AppointmentType"] === "A")
                appointment["AppointmentType"] = "Ausser Haus";
            else if (appointment["AppointmentType"] === "V")
                appointment["AppointmentType"] = "Verkündungstermin";
            else if (appointment["AppointmentType"] === "P")
                appointment["AppointmentType"] = "Privat";
            else if (appointment["AppointmentType"] === "D")
                appointment["AppointmentType"] = "Dauertermin";
            else if (appointment["AppointmentType"] === "N")
                appointment["AppointmentType"] = "Notar";
        }

        this.updateAppointments(allAppointments);
    }

    async changedStartDate() {
        let start = ko.toJS(this.startTime);
        for (let i = 0; i < this.times.length; i++) {

            if (start === this.times[i]) {
                document.getElementById('endEditTimeID')[i + 1].selected = true;
                this.endTime(this.times[i + 1]);
            }
        }
    }

    intervalLength = ko.observable(30);
    interval = ko.pureComputed(() => {
        let length = this.intervalLength();
        let startDate = moment.utc().startOf('day');
        let endDate = moment.utc().endOf('day').add(length, 'days');
        return new Interval(startDate, endDate);
    })

    dateConverter(date: string) {

        let d = date.split('.');

        return `${d[2]}-${d[1]}-${d[0]}`;
    }
    deadlineNr = ko.observable("");
    predeadlineNr = ko.observable("");
    IsEditMode = ko.observable(false)
    IsDeadlineNecessary = ko.observable(true);

    DeadLineNr = ko.observable("");
    deadlineCreationDate = ko.observable(moment().format("YYYY-MM-DD"));
    wiedervorlage = ko.observable(moment().format("YYYY-MM-DD"));

    creationDate = ko.observable(moment().format("YYYY-MM-DD"));

    parseDate = (dateStr: string) => moment.utc(dateStr, "DD.MM.YYYY", true);

    checkDate = (str: string) => this.parseDate(str).isValid();

    times = [];

    startDate = ko.observable(moment().format("YYYY-MM-DD"));
    startTime = ko.observable("00:00");

    endDate = ko.observable(moment().format("YYYY-MM-DD"));
    endTime = ko.observable("00:00");
    parseTime = (timeStr: string) => moment(timeStr, "HH:mm");
    checkTime = (str: string) => this.parseTime(str).isValid();

    changeDateFormat = (date: string) => {
        var d = date.split('-');
        return `${d[2]}.${d[1]}.${d[0]}`;
    }
    constructDate = (date: string, time: string) => {

        let parsedDate = this.parseDate(date);

        if (this.selectedType() === "Frist") {
            return parsedDate.toISOString();
        } else {
            let parsedTime = this.parseTime(time);
            let hour = parsedTime.hour();
            parsedDate.add(parsedTime.hours(), 'hours');
            parsedDate.add(parsedTime.minutes(), 'minutes');
            return parsedDate.toISOString();
        }
    }

    newBasicAppointment() {
        let obj = {
            AppointmentSubject: ko.observable(null),
            AppointmentDate: ko.computed(() => this.constructDate(this.startDate().toString(), this.startTime())),
            StartDate: ko.computed(() => this.constructDate(this.startDate().toString(), this.startTime())),
            EndDate: ko.computed(() => this.constructDate(this.endDate().toString(), this.endTime())),
            AppointmentType: ko.observable(""),
            Addressee: ko.observable(null),
            AddresseeId: ko.observable(""),
            AddresseeTypeId: ko.observable(""),
            AppointmentNote: ko.observable(""),
            SachbearbeiterId: ko.observable(RNSAPI.User().username),
            Sachbearbeiter: ko.observable(null),
            CaseId: ko.observable(""),
            Completed: ko.observable(false),
            CreationDate: ko.observable(null),
            CreatorId: ko.observable(""),
            EditedAtDate: ko.observable(null),
            EditorId: ko.observable(""),
            Gericht: ko.observable(null),
            GerichtsId: ko.observable(""),
            Id: ko.observable(""),
            IsDeleted: ko.observable(false),
            IsRecurringAppointment: ko.observable(false),
            Lawyer: ko.observable(null),
            LawyerId: ko.observable(RNSAPI.User() ? RNSAPI.User().username : "GR"),
            MeetingPlace: ko.observable(""),
            MeetingPlaceStreet: ko.observable(""),
            Moved: ko.observable(""),
            RecurringAppointmentDto: ko.observable(null),
            RoomID: ko.observable("")
        };
        obj["AppointmentName"] = ko.computed(() => obj.AppointmentSubject());

        return ko.observable(obj);
    };

    basicAppointment = this.newBasicAppointment();

    async fillAppointmentData(id) {
        console.log(" id= ", id)
        this.IsEditMode(true);
        //(<HTMLSelectElement>document.getElementById('terminar')).value = ko.toJS(this.basicAppointment().AppointmentType);
        //AppintmentDialogViewModel.prototype.basicAppointment(this.basicAppointment());

        try {

            //id Encoding wrong!

            let result = await RNSAPI.getAppointment(id);

            if (result.Type === "AppointmentFound") {
                let appointment = result.Payload.Appointment;
                let obj = this.basicAppointment();

                let excluding = ["AppointmentDate", "AppointmentName", "StartDate", "EndDate", "isRecurringAppointment"]; //"AppointmentType",
                for (let key in appointment) {
                    if (obj[key] === undefined) {
                        obj[key] = appointment[key];
                    } else if (excluding.indexOf(key) === -1) {
                        obj[key](appointment[key]);
                    }
                }

                this.basicAppointment(obj);
                //console.log(obj);
                (<HTMLSelectElement>document.getElementById('terminar')).value = ko.toJS(this.basicAppointment().AppointmentType);
                (<HTMLSelectElement>document.getElementById('EditAppointmentDialogsearchdrop')).value = ko.toJS(this.basicAppointment().CaseId);

                let momentStart = moment.utc(appointment.AppointmentDate).subtract(1, 'hours').local();
                let momentEnd = moment.utc(appointment.AppointmentDate).subtract(1, 'hours').local();

                let momentStartTime = moment.utc(appointment.StartDate).subtract(1, 'hours').local();
                let momentEndTime = moment.utc(appointment.EndDate).subtract(1, 'hours').local();
                //let momentStartTime = moment.utc(appointment.StartDate).subtract(2, 'hours').local();
                //let momentEndTime = moment.utc(appointment.EndDate).subtract(2, 'hours').local();
                this.startDate(momentStart.format("YYYY-MM-DD"));
                this.endDate(momentEnd.format("YYYY-MM-DD"));
                this.startTime(momentStartTime.format("HH:mm"));
                this.endTime(momentEndTime.format("HH:mm"));

                $('#EditAppointment').modal('show');
            } else {
                alert("Termin nicht gefunden.");
            }
        } catch (e) {
            alert("Verbindung zum Server abgebrochen.");
            console.log(e);
        }
        this.IsEditMode(true);
    }

    adjustDigits(val: number, places: number) {
        var str = String(val);
        for (var i = 0; i < places - String(val).length; i++) {
            str = "0" + str;
        }
        return str;
    }


    async changedwholeday() {
        console.log(this.wholeday);
        if (this.wholeday()) {
            this.wholeday(false);
        }
        else {
            this.wholeday(true);
        }
    }

    async appointmentModalPostData() {

        this.startDate(this.changeDateFormat(this.startDate()));
        this.endDate(this.changeDateFormat(this.endDate()));

        //console.log('startDate', this.startDate())
        //console.log('endDate', this.endDate())

        $(".form-group").each(function () { $(this).removeClass("has-error"); });

        if (!Utils.checkErrors(["AppointmentSubject"], this.basicAppointment(), "appointment", [Utils.checkString])) { alert("ungültiger Betreff"); return; }
        else if (!Utils.checkErrors(["startDate", "endDate"], this, "", [Utils.checkString, this.checkDate])) { alert("ungültiges Datum"); return; }
        else if (this.selectedType() === "Termin" && !Utils.checkErrors(["startTime", "endTime"], this, "", [Utils.checkString, this.checkTime])) { alert("ungültige Zeit"); return; }
        else if (new Date(this.basicAppointment().StartDate()) > new Date(this.basicAppointment().EndDate())) {
            alert("Das Enddatum liegt vor dem Startdatum");
            return;
        }

        this.basicAppointment().CaseId((<HTMLSelectElement>document.getElementById('EditAppointmentDialogsearchdrop')).value);

        if (this.IsEditMode()) {
            try {
                let result = await RNSAPI.updateAppointment(this.basicAppointment());
                if (result.Type === "UpdateSuccessful") {
                    RNSAPI.createHistoryEntry(this.basicAppointment().CaseId(), "Termin bearbeitet");
                    this.basicAppointment().AppointmentSubject("");
                    this.basicAppointment().CaseId("");
                    this.basicAppointment().AddresseeId("");
                    this.basicAppointment().AddresseeTypeId("");
                    this.basicAppointment().Addressee("");
                    this.basicAppointment().AppointmentNote("");
                    $('#appointmentModal').modal('hide');
                    this.update();
                    // MainViewModel.RoutingTable.showDashboardView({ view: "appointments" });
                } else {
                    alert("Fehler beim Speichern.");
                }
                this.IsEditMode(false);
            } catch (e) {
                console.log(e);
                alert("Fehler beim Speichern: " + e.responseText);
            }
        } else {
            try {
                let result = await RNSAPI.createAppointment(this.basicAppointment());
                if (result.Type === "CreationSuccessful") {
                    $('#appointmentModal').modal('hide');
                    RNSAPI.createHistoryEntry(this.basicAppointment().CaseId(), "Termin bearbeitet");
                    this.update();
                    this.basicAppointment().AppointmentSubject("");
                    this.basicAppointment().CaseId("");
                    this.basicAppointment().AddresseeId("");
                    this.basicAppointment().AddresseeTypeId("");
                    this.basicAppointment().Addressee("");
                    this.basicAppointment().AppointmentNote("");

                    // MainViewModel.RoutingTable.showDashboardView({ view: "appointments" });
                } else {
                    alert("Fehler beim Anlegen des Termins.");
                }

            } catch (e) {
                console.log(e);
                alert("Fehler beim Anlegen: " + e.responseText);
            }
        }
    };

    async pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    };

    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalHandleSelection = ko.observable();
    TermiCount = ko.observable(0);

}


let nameTable: { [key: string]: string; } = {
    "appointment": "Termin",
    "deadline": "Frist",
    "predeadline": "Vorfrist",
    "inbox": "Posteingang",
    "resubmission": "Wiedervorlage",
    "ecase": "EAkte"
};

var html = fs.readFileSync(__dirname + '/kalendaruser.html', 'utf8');

ko.components.register("kalendaruser-view", {
    viewModel: KalendarAllViewModel,
    template: html
});
