import * as fs from "fs";
import * as ko from 'knockout';
import AudioUtils = require('audio-buffer-utils');
import MediaStreamRecorder = require('msr');
import { RNSAPI } from "../../api";
import { Utils } from "../../utils";
import { MainViewModel } from "../../main";
import '../dropdownsearch/dropdownsearch';
import { Postbox } from "../dashboard/postbox";
import moment = require("moment");
//Safari does not fully support the standard yet

export class RecordViewModel {
    parentVM: any;
    opentimemonitoringModal= ko.observable();
    timerPaused = ko.observable();
    trigger = ko.observable();
    HasActiveElement = ko.observable(false);
    SelectedValue = ko.observable("case");
    StartTime = ko.observable(this.addZeroToTime(new Date().getHours().toString()) + ":" + this.addZeroToTime(new Date().getMinutes().toString()));
    EndTime = ko.observable("");
    Started = ko.observable(false);
    DateTime = ko.observable(moment().format("YYYY-MM-DD"));
    TimeMonitoringType = ko.observable("");
    submitedSaveManualTimeMonitoring = ko.observable(false);
    manualTimeMonitoringError = ko.observable("");
    Activity = ko.observable("");
    TabelVisible = ko.observable(false);
    PausedMonitoring = ko.observable(false);
    HeaderTitle = ko.observable('Zeiterfassung');

    Zeiterfassungscounter = 1;

    showStartNew = ko.observable(true);
    showPause = ko.observable(false);
    showContinue = ko.observable(false);

    Lasted = ko.observable('0:00');
    PreDuration = ko.observable('0:00');
    WholeDuration = ko.observable('0:00');

    allMonitorings = ko.observableArray();

    isManualDLK = ko.observable(false);

    caseHourly = ko.observable('');
    caseToUpdate = ko.observable();

    currenTimeMonitoring = ko.observable({
        ID: ko.observable(''),
        CaseID: ko.observable(''),
        StartTime: ko.observable(''),
        EndTime: ko.observable(''),
        Sent: ko.observable(''),
        Duration: ko.observable(''),
        Type: ko.observable(''),
        Task: ko.observable('')
    });

    private addZeroToTime(num: string): string {
        if(Number(num) < 10) {
            num = "0" + num;
        }
        return num;
    }

    constructor(params: any) {
        Postbox.publisher().subscribe((response) => {
            if(response) {
                this.caseHourly("");
                this.isManualDLK(true);
                $('#SelectedType').val("clerk");
                this.SelectedValue(ko.toJS("clerk") as string);
            }
        }, "isManualDLK");

        this.parentVM = params.vm;
        this.timerPaused = params.timerPaused;
        this.trigger = params.trigger;
        this.opentimemonitoringModal = params.opentimemonitoringModal;
        this.timerPaused.subscribe(async () => {
            // alert('working');
            if(this.trigger() == true && this.timerPaused() == true){
                this.parentVM.runningMonitoring(false);
                await this.stopMonitoringMod();
                await this.parentVM.getCurrentTimeMonitoring();
                this.parentVM.runningMonitoring(true);
            }
            else if(this.trigger() == true){
                this.parentVM.runningMonitoring(false);
                await this.startTimer();
                await this.parentVM.getCurrentTimeMonitoring();
                this.parentVM.runningMonitoring(true);
            }
            // this.RefreshPage();
        });
        this.opentimemonitoringModal.subscribe(() => {
            if(this.opentimemonitoringModal() == true){
                this.isManualDLK(false);
                $('#timemonitoringModal').modal('show');
                this.getTimeMon();
            }
        });
        $("#bar").addClass('canvasBack');
        if (params.opentimemonitoringModal() == true) {
            $('#timemonitoringModal').modal('show');
            this.getTimeMon();
        }
    }

    async getTimeMon() {
        let reuslt = (await RNSAPI.getTimeMonitorings(2)).Payload.TimeMonitorings;
        if (reuslt.length !== 0) {
            this.TabelVisible(false);
            this.Started(true);
            this.showStartNew(false);
            this.showPause(true);
            this.showContinue(false);
            $('#newTM_btn').removeClass('disabled');
            this.currenTimeMonitoring().StartTime(ko.toJS(reuslt[0].StartTime));
            this.currenTimeMonitoring().ID(ko.toJS(reuslt[0].ID));
            var today = new Date(reuslt[0].StartTime);
            this.StartTime(this.formatDate(today));
        }
        else {
            this.allMonitorings((await RNSAPI.getTimeMonitorings(0)).Payload.TimeMonitorings);
            if (this.allMonitorings().length > 0)
                this.TabelVisible(true);
        }
    }

    StartNew() {
        //this.HasActiveElement(true);
        $('#newTM_btn').addClass('disabled');
        this.startTimer();
        //this.HeaderTitle('<i class="far fa-clock mr-2"></i> Neue Zeiterfassung'); //Change Topping
    }

    ShowOverview() {
        this.TabelVisible(true);
        //this.Started(false);
    }

    async ContinueMonitoring() {
        this.PausedMonitoring(false);
        $('#statusbtn').removeClass('btn-success');
        $('#statusbtn').addClass('btn-danger');
        (document.getElementById('Beginn_in')).setAttribute('disabled', 'true');
        (document.getElementById('End_in')).setAttribute('disabled', 'true');
        this.HasActiveElement(false);
        this.showPause(true);
        this.showContinue(false);
        this.continueTimer();
    }
    async stopMonitoringMod() {
        await this.getTimeMon();
        await this.StopMonitoring();
    }

    async StopMonitoring() {
        var result = await RNSAPI.updateTimeMonitoring(this.currenTimeMonitoring().ID());
        var today = new Date(result.Payload.End);
        this.currenTimeMonitoring().Duration(result.Payload.Duration);
        var startedTime = new Date(this.currenTimeMonitoring().StartTime());

        this.PausedMonitoring(true);
        this.showContinue(true);
        this.showPause(false);

        $('#statusbtn').removeClass('btn-danger');
        $('#statusbtn').addClass('btn-success');
        $('#Beginn_in').removeAttr('disabled');
        $('#End_in').removeAttr('disabled');
        this.HasActiveElement(true);

        this.currenTimeMonitoring().EndTime(result.Payload.End);

        this.EndTime(this.formatDate(today));

        var SubtractedTime = (today.getHours() * 60 + today.getMinutes()) - (startedTime.getHours() * 60 + startedTime.getMinutes());
        this.Lasted(this.getHoursFromMinutes(SubtractedTime));
        this.PreDuration(this.getHoursFromMinutes(Number(this.currenTimeMonitoring().Duration())));
        this.WholeDuration(this.getHoursFromMinutes(SubtractedTime + Number(this.currenTimeMonitoring().Duration())));
    }

    async saveManualTimeMonitoring() {
        if(!this.submitedSaveManualTimeMonitoring()) {
            if(this.StartTime() !== "" && this.EndTime() !== "" && this.DateTime() !== "" && this.Activity() !== "") {
                const CaseNumber = (<HTMLInputElement>document.getElementById('SearchCaseMonsearchdrop')).value.split(' ')[0];
                if (CaseNumber !== "" && CaseNumber !== null) {
                    this.submitedSaveManualTimeMonitoring(true);

                    if(this.SelectedValue() === 'clerk') {
                        this.TimeMonitoringType('DEFAULT');
                    } else {
                        if (ko.toJS(this.caseHourly) === '') {
                            $('#CaseHourly_col').addClass("has-error");
                            return;
                        }
                        else {
                            this.TimeMonitoringType('INDIVID');
                            this.updatedHourlyOfCase();
                            $('#CaseHourly_col').removeClass("has-error");
                        }
                    }

                    await RNSAPI.createManualTimeMonitoring({
                        CaseId: CaseNumber,
                        Date: this.DateTime(),
                        Start: this.StartTime(),
                        End: this.EndTime(),
                        Activity: this.Activity(),
                        Type: this.TimeMonitoringType()
                    }).then((response) => {
                        this.StartTime(this.addZeroToTime(new Date().getHours().toString()) + ":" + this.addZeroToTime(new Date().getMinutes().toString()));
                        this.EndTime("--:--");
                        this.DateTime(moment().format("YYYY-MM-DD"));
                        this.Activity("");
                        $("#SearchCaseMonsearchdrop").val("");
                        this.manualTimeMonitoringError("");
                        $("#timemonitoringModal").modal('hide');
                        Postbox.publisher().publish("Update", "UpdateDLK");
                        this.submitedSaveManualTimeMonitoring(false);
                    }).catch((error) => {
                        this.submitedSaveManualTimeMonitoring(false);
                        this.manualTimeMonitoringError("Die Zeit konnte nicht erfasst werden.");
                    });
                }
            } else {
                this.submitedSaveManualTimeMonitoring(false);
                this.manualTimeMonitoringError("Die erforderlichen Pflichtfelder wurden nicht ausgefüllt.");
            }
        } else {
            this.submitedSaveManualTimeMonitoring(false);
        }
    }

    async SaveMonitoring() {
        var CaseNumber = (<HTMLInputElement>document.getElementById('SearchCaseMonsearchdrop')).value;
        CaseNumber = CaseNumber.split(' ')[0];
        if (CaseNumber !== "" && CaseNumber !== null) {
            this.currenTimeMonitoring().CaseID(CaseNumber);
            this.currenTimeMonitoring().Sent("1");
            if (this.SelectedValue() === 'clerk') {
                this.currenTimeMonitoring().Type('DEFAULT');
            }
            else if (this.SelectedValue() === 'case') {
                if (ko.toJS(this.caseHourly) === '') {
                    $('#CaseHourly_col').addClass("has-error");
                    return;
                }
                else {
                    this.currenTimeMonitoring().Type('INDIVID');
                    this.updatedHourlyOfCase();
                    $('#CaseHourly_col').removeClass("has-error");
                }
            }

            this.currenTimeMonitoring().Task((<HTMLInputElement>document.getElementById('Taskinput')).value);
            let result = await RNSAPI.SaveTimeMonitoring(ko.toJS(this.currenTimeMonitoring()));
            if (result.Type === "SaveTimeMonitoringSuccessful") {
                this.resetModal();
                $("#timemonitoring").addClass("dictate1");
                $('#timemonitoringModal').modal('hide');
                this.parentVM.runningMonitoring(false);
                Postbox.publisher().publish("Update", "UpdateDLK");
            }
            else {
                //Errormessag
            }
        }
        else {
            //Notify user to select case!
        }
    }

    resetModal() {
        this.StartTime("");
        this.EndTime("");
        this.WholeDuration('0:00');
        this.Lasted('0:00');
        this.PreDuration('0:00');
        $("#SearchCaseMonsearchdrop").val("");
        $("#Taskinput").val("");
        this.caseHourly("");
        this.Started(false);
        this.showPause(false);
        this.showContinue(false);
        this.HasActiveElement(false);
        this.showStartNew(true);
    }

    async updatedHourlyOfCase() {
        if(this.caseToUpdate()) {
            if (ko.toJS(this.caseHourly) !== ko.toJS(this.caseToUpdate().Akte.Stundensatz)) {
                this.caseToUpdate().Akte.Stundensatz=ko.toJS(this.caseHourly);
                let res = (await RNSAPI.saveExtendedCase(ko.toJS(this.caseToUpdate)));
            }
        }
    }

    ShowContinue(ID: number, InstantStart: boolean) {
        for (let i = 0; i < this.allMonitorings().length; i++) {
            if (ko.toJS(this.allMonitorings())[i].ID === ID) {
                this.currenTimeMonitoring().ID(ko.toJS(this.allMonitorings())[i].ID);
                this.currenTimeMonitoring().CaseID(ko.toJS(this.allMonitorings())[i].CaseId);
                this.currenTimeMonitoring().StartTime(ko.toJS(this.allMonitorings())[i].StartTime);
                this.currenTimeMonitoring().EndTime(ko.toJS(this.allMonitorings())[i].EndTime);
                this.currenTimeMonitoring().Sent(ko.toJS(this.allMonitorings())[i].Sent);
                this.currenTimeMonitoring().Duration(ko.toJS(this.allMonitorings())[i].Duration);
                this.currenTimeMonitoring().Type(ko.toJS(this.allMonitorings())[i].Type);
            }
        }

        this.PreDuration(this.getHoursFromMinutes(Number(this.currenTimeMonitoring().Duration())));
        this.WholeDuration(this.PreDuration());
        this.Lasted('0:00');

        if (InstantStart) {
            this.showStartNew(false);
            this.showPause(true);
            this.showContinue(false);
            this.continueTimer();
        }
        else {
            this.PausedMonitoring(true);
            this.showContinue(true);
            this.showPause(false);
            this.showStartNew(false);
            this.HasActiveElement(true);
            var st = new Date(this.currenTimeMonitoring().StartTime())
            this.StartTime(this.formatDate(st));
            st = new Date(this.currenTimeMonitoring().EndTime());
            this.EndTime(this.formatDate(st));
        }
        this.TabelVisible(false);
        this.Started(true);
    }

    getHoursFromMinutes(input: number) {
        var hours = 0;
        var minutes = 0;
        minutes = input % 60;
        hours = (input - minutes) / 60;
        var minutestring = "";
        if (minutes < 10)
            minutestring = "0" + minutes;
        else
            minutestring = "" + minutes;
        let output = ko.toJS(hours) + ':' + minutestring;
        return output;
    }

    formatDate(input: Date) {
        var hoursString = "";
        var minutesString = "";
        if (input.getHours() < 10)
            hoursString = "0" + input.getHours();
        else
            hoursString = "" + input.getHours();

        if (input.getMinutes() < 10)
            minutesString = "0" + input.getMinutes();
        else
            minutesString = "" + input.getMinutes();

        return hoursString + ":" + minutesString;
    }

    async startTimer() {
        let SetTime = await RNSAPI.startTimeMonitoring("");
        this.trigger(false);
        this.timerPaused(false);
        this.TabelVisible(false);
        this.Started(true);
        this.showStartNew(false);
        this.showPause(true);
        this.showContinue(false);
        $('#newTM_btn').removeClass('disabled');
        this.currenTimeMonitoring().StartTime(ko.toJS(SetTime.Payload.Start));
        this.currenTimeMonitoring().ID(ko.toJS(SetTime.Payload.ID));
        var today = new Date(SetTime.Payload.Start);
        this.StartTime(this.formatDate(today));
        this.parentVM.runningMonitoring(true);
    }

    async continueTimer() {
        ko.toJS(this.currenTimeMonitoring().ID)
        let SetTime = await RNSAPI.startTimeMonitoring(ko.toJS(this.currenTimeMonitoring().ID));
        this.currenTimeMonitoring().StartTime(ko.toJS(SetTime.Payload.Start));
        this.currenTimeMonitoring().ID(ko.toJS(SetTime.Payload.ID));
        var today = new Date(SetTime.Payload.Start);
        this.StartTime(this.formatDate(today));
        this.EndTime("");
    }

    selectchanged() {
        this.SelectedValue(ko.toJS($('#SelectedType').val()) as string);
        if (ko.toJS(this.SelectedValue) === "case") {
            if (ko.toJS($('#SearchCaseMonsearchdrop').val()) !== "") {
                $('#casedropdownForCaseAbrechnung').removeClass("has-error");
                this.getStundensatzforCase();
            }
            else {
                $('#casedropdownForCaseAbrechnung').addClass("has-error");
            }
        }
        else {
            $('#casedropdownForCaseAbrechnung').removeClass("has-error");
        }
    }

    async getStundensatzforCase() {
        let res = (await RNSAPI.getExtendedCase(ko.toJS($('#SearchCaseMonsearchdrop').val().toString()).split(' ')[0])).Payload;
        this.caseHourly(res.Akte.Stundensatz);
        this.caseToUpdate(res);
    }

    RefreshPage() {
        // window.location.reload();
        $('#timemonitoringModal').modal('hide');
        this.parentVM.getCurrentTimeMonitoring();
    }

    openaktenoverview = async () => {
        window.open("/#/cases")
    }

    checkTitle(param: string) {
        if (param === "" || param === null) {
            param = "Unbenannte Zeiterfassung #" + this.Zeiterfassungscounter;
            this.Zeiterfassungscounter++;
        }
        return param;
    }
}

let html = fs.readFileSync(__dirname + '/timemonitoring.html', 'utf8');

ko.components.register("timemonitoring-view", {
    viewModel: RecordViewModel,
    template: html
});
