import { RNSAPI } from "../../api";
import { Utils } from '../../utils';
import * as fs from 'fs';
import * as ko from "knockout";
import * as model from './model';
import * as moment from 'moment';
import { MainViewModel } from "../../main";
import { layoutMietrecht, layoutAllgemein, layoutAllgemeinesZivilRecht, layoutArbeitsrecht,
         layoutAsylrecht, layoutErbrecht, layoutFamilienrecht, layoutSozialrecht, layoutSteuerrecht,
         layoutStrafrecht, layoutVerkehrsOWI, layoutVerkehrsrecht, layoutVerwaltungsrecht,
         layoutZivilrecht} from './layouts';

export class ExtendedMainViewModel {

  rechtgebiete: ko.ObservableArray<string> = ko.observableArray(["Allgemein", "VerkehrsOWiR" ]);
  //  "Allgemeines Zivilrecht",
  //  "Arbeitsrecht", "Asylrecht", "Erbrecht", "Familienrecht", "Mietrecht",
  //  "Sozialrecht", "Steuerrecht", "Strafrecht", "Verkehrsrecht",
  //  "Verwaltungsrecht", "Zivilrecht"]);

  bereiche: ko.ObservableArray<string> = ko.observableArray(["Mandantenbegehren"]);

  constructor(params) {
    this.initialize(params.caseId, layoutAllgemein);
  }

  initialize(CaseId: string, layout: any, layoutName?: string) {
    this.getData(CaseId, layout, layoutName).then(data => {
      this.data(data);
    });
  }

  data: ko.Observable<Data | undefined> = ko.observable(undefined);

  switchTab(s: string): void {
    this.data().currentTab(s);
    }

    redirectTab() {
        //console.log(this.data().caseId);
        let x = this.data().caseId;
        MainViewModel.RoutingTable.showCaseEntriesView({ caseId: x });
    }
  save(): void {
    let d: Data = this.data();
    updateDataModel(d.extended, d.caseData, d.caseId);
    RNSAPI.saveExtendedCase(d.caseData)
      .then(res => {
       if (res.Type !== "CaseUpdateSuccessful") {
         window.alert("An error has occured while saving the case");
       }})
      .catch(() => { window.alert("An error has occured while saving the case"); });
  }

  async getData(caseId: string, layout: any, layoutName?: string): Promise<Data> {
    const caseData: any = (await RNSAPI.getExtendedCase(caseId)).Payload;
    const extended: model.Extended = extendedFromLayout(layout, caseData);
    const rechtsgebiet: ko.Observable<string> = ko.observable(layoutName || "Allgemein");
    rechtsgebiet.subscribe(
      s => {
        this.initialize(caseId, getLayout(s), s);
        this.bereiche(getLayout(s).Sachverhalt.map(sa => sa.Name));
    });
    const bereich: ko.Observable<string> = ko.observable( this.bereiche()[0] );

    fillData(extended, caseData);
    return {
      caseId:               caseId,
      layout:               layout,
      caseData:             caseData,
      extended:             extended,
      selectedRechtsGebiet: rechtsgebiet,
      selectedBereich:      bereich,
      currentTab:           ko.observable("IdentifikationsTab"),
    };
  }

}

function extendedFromLayout(layout: any, caseData: any): model.Extended {
  const identifikationsdaten = transformElements(layout.Identifikationsdaten);

  const organisationsdaten: model.Tab = {
    Name: "Organisationsdaten",
    Forms: ko.observableArray([transformElements(layout.Organisationsdaten)])
  };

  const mandanten: model.Tab = {
    Name: "Mandanten",
    Forms: ko.observableArray(Utils.replicate(caseData.Addresses[0].length, () => transformElements(layout.Mandant)))
  }

  const gegner: model.Tab = {
    Name: "Gegner",
    Forms: ko.observableArray(Utils.replicate(caseData.Addresses[1].length, () => transformElements(layout.Gegner)))
  }

  const gerichte: model.Tab = {
    Name: "Gerichte",
    Forms: ko.observableArray(Utils.replicate(caseData.Addresses[6].length, () => transformElements(layout.Gericht)))
  }

  const weitere: model.Tab = {
    Name: "Weitere",
    Forms: ko.observableArray(Utils.replicate(caseData.Addresses[7].length, () => transformElements(layout.Weitere)))
  }

  return {
    Identifikationsdaten: transformElements(layout.Identifikationsdaten),
    Organisationsdaten: organisationsdaten,
    Mandanten:          mandanten,
    Gerichte:           gerichte,
    Gegner:             gegner,
    Weitere:            weitere,
    Sachverhalt:        layout.Sachverhalt.map(transformSachverhaltTab),
  };
}

function transformElements(elements: any): model.FormElement[][] {
  return elements.map(row => row.map(transformLayoutFormElement));
}

function transformSachverhaltTab(oldTab: any): model.Tab {
  return {
    Name: oldTab.Name,
    Forms: ko.observableArray([transformElements(oldTab.Elements)])
  };
}

function getLayout(selectedName: string): any {
  switch (selectedName){
    case "Allgemein":              return layoutAllgemein;
    case "Allgemeines Zivilrecht": return layoutAllgemeinesZivilRecht;
    case "Arbeitsrecht":           return layoutArbeitsrecht;
    case "Erbrecht":               return layoutErbrecht;
    case "Familienrecht":          return layoutFamilienrecht;
    case "Mietrecht":              return layoutMietrecht;
    case "Sozialrecht":            return layoutSozialrecht;
    case "Steuerrecht":            return layoutSteuerrecht;
    case "Strafrecht":             return layoutStrafrecht;
    case "VerkehrsOWiR":           return layoutVerkehrsOWI;
    case "Verkehrsrecht":          return layoutVerkehrsrecht;
    case "Verwaltungsrecht":       return layoutVerwaltungsrecht;
    case "Zivilrecht":             return layoutZivilrecht;
    case "Asylrecht":              return layoutAsylrecht;
  }
}

interface Mapper {
    toRnsApi(a: any): any
    toLayout(a: any): any
}

function getMapper(field) {
    let mapper: {[name: string]: Mapper}  = {
        "string": new class {
            toRnsApi(a) { return a; }
            toLayout(a) { return a; }
        }, "DateTime": new class {
            toRnsApi(a) {
                if (!a || a === "") return null;
                if (typeof(a) === 'function') return a;
                return new Date(a)
            }
            toLayout(a) { return "" + a.toString; }
        }, "int": new class {
            toRnsApi(a) {
                if (!a || a === "") return 0;
                if (typeof(a) === 'string') return parseInt(a);
                return a;
            }
            toLayout(a) { return a; }
        }, "double": new class {
            toRnsApi(a) {
                if (!a || a === "") return 0.0;
                if (typeof(a) === 'string') return parseFloat(a);
                return a;
            }
            toLayout(a) { return a; }
        }, "bool": new class {
          toRnsApi(a) {
            if (a) return "true"; else return "false";
          }
          toLayout(a) {
            if (a === "true") return true; else return false;
          }
        }
    };

    switch (field) {
        case "Registernummer"                       :  return mapper["string"];
        case "AblageDatum"                          :  return mapper["DateTime"];
        case "Ablagekennzeichen"                    :  return mapper["string"];
        case "AblageNr"                             :  return mapper["string"];
        case "AnlageDatum"                          :  return mapper["string"];
        case "Druckkennung"                         :  return mapper["string"];
        case "FristDatum"                           :  return mapper["DateTime"];
        case "FristNr"                              :  return mapper["string"];
        case "GlauebigerID"                         :  return mapper["string"];
        case "Kostenstelle"                         :  return mapper["string"];
        case "Kostentraeger"                        :  return mapper["string"];
        case "MahnDatum"                            :  return mapper["DateTime"];
        case "MahnNr"                               :  return mapper["string"];
        case "Referat"                              :  return mapper["string"];
        case "Rubrum"                               :  return mapper["string"];
        case "Wegen"                                :  return mapper["string"];
        case "SachbearbeiterId"                     :  return mapper["string"];
        case "AdvoportUpload"                       :  return mapper["bool"];
        case "AdvoportUploadDate"                   :  return mapper["DateTime"];
        case "AktenZeichenGericht1"                 :  return mapper["string"];
        case "AktenZeichenGericht2"                 :  return mapper["string"];
        case "AktenZeichenGericht3"                 :  return mapper["string"];
        case "AktenZeichenMahnGericht"              :  return mapper["string"];
        case "AntragsGerManBeId"                    :  return mapper["string"];
        case "AnzahlGegner"                         :  return mapper["int"];
        case "AnzahlMandanten"                      :  return mapper["int"];
        case "DatumErlassMahnBescheid"              :  return mapper["DateTime"];
        case "eConsultRoomID"                       :  return mapper["string"];
        case "eConsultRootNodeID"                   :  return mapper["string"];
        case "GegenAnwaltCaseID"                    :  return mapper["string"];
        case "GSchadenssparte"                      :  return mapper["string"];
        case "IsAuslaendischesMandat"               :  return mapper["bool"];
        case "IsEigeneBeitreibung"                  :  return mapper["bool"];
        case "IsMandantKlaeger"                     :  return mapper["bool"];
        case "IsMandantVorzugsteuerabzugberechtigt" :  return mapper["bool"];
        case "JurionUpload"                         :  return mapper["bool"];
        case "JurionUploadDate"                     :  return mapper["DateTime"];
        case "KorrAnwaltCaseID"                     :  return mapper["string"];
        case "MSchadenssparte"                      :  return mapper["string"];
        case "Note1"                                :  return mapper["string"];
        case "Note2"                                :  return mapper["string"];
        case "Note3"                                :  return mapper["string"];
        case "Sachstand"                            :  return mapper["string"];
        case "WertGericht1"                         :  return mapper["double"];
        case "WertGericht2"                         :  return mapper["double"];
        case "WertGericht3"                         :  return mapper["double"];
        case "Gericht1Id"                           :  return mapper["string"];
        case "Gericht2Id"                           :  return mapper["string"];
        case "Gericht3Id"                           :  return mapper["string"];
        case "GegenAnwaltId"                        :  return mapper["string"];
        case "KorrAnwaltID"                         :  return mapper["string"];
    }

    return new class {
        toRnsApi(a) { return a; }
        toLayout(a) { return a; }
    };
}

function germanDateOrEmpty(s: string): string {
  let m = moment(s);
  if (m.isValid()) {
    return m.format("MM.DD.YYYY");
  } else {
    return "";
  }
}

function updateDataModel(e: model.Extended, data: any, caseId: string): void {
  type Setter = (key: string, value: string) => void

  function getSetterForCaseData(e: model.FormElement): Setter {
    function doSet(o, k, v): void {
      if (o === data.Akte) {
        if (k === "AnlageDatum") {
          v = germanDateOrEmpty(v);
        } else {
          let mapper = getMapper(k);
          v = mapper.toRnsApi(v);
        }
      }

      if (o === data.ExtendedCase.Infos && e.WebInfo === "Datum") {
        v = germanDateOrEmpty(v);
      }

      if (o && k) o[k] = v;
    }
    if (e.Database === "Fats/Akte") return (key, value) => doSet(data.Akte, key, value);
    else if (e.Database === "XML/Default" || e.Database === "XML/Akte") return (key, value) => doSet(data.ExtendedCase.Infos, key, value);
    else return (key, value) => {};
  }

  function getSetterForPersonData(e: model.FormElement, addressIndex: number, personIndex: number): Setter {
    function doSet(o, k, v): void {
      if (o && k) o[k] = v;
      else if (o) o[k] = "";
    }

    if (e.Database === "Fats/Akte" || e.Database === "Fats/DefaultZuord")
      return (key, value) => doSet(data.Addresses[addressIndex][personIndex], key, value);
    else if (e.Database === "XML/Default") {
      let keyword = data.Addresses[addressIndex][personIndex]["Keyword"]
      let personData = data.ExtendedCase.Persons.find(data => data.subeg === keyword);
      if (!personData) {
        let addressType = addressIndex + 2;
        personData = {
          "adresstyp": "" + addressType,
          "dsid": "" + (data.ExtendedCase.Persons.size + 1),
          "infos": {
            "dskeyvater": "0014016",
            "adresstypvater": "1",
            "dsidvater": "-1"
          },
          "regnr": caseId,
          "rolle": "",
          "subeg": keyword,
          "zunum": "1"
        }
        data.ExtendedCase.Persons.push(personData);
        return (key, value) => doSet(personData, key, value);
      }
      return (key, value) => doSet(personData, key, value);
    }
    else return (key, value) => {};
  }

  function copyBack(e: model.FormElement, setter: Setter): void {
    switch (e.Type) {
      case "HEADLINE": break;
      case "LABEL": break;

      case "COMBOBOX":
      case "TEXTAREA":
      case "TEXTBOX":
        setter(e.DbText, e.Data());
        break;
      case "CHECKBOX":
        setter(e.DbText, "" + !!e.Data());
        break;
      case "RADIOGROUP":
        let values = Object.keys(e.Values).map((k) => e.Values[k]);
        for (let value of values) {
          setter(value, "" + (e.Data() === value))
        }
        break;
      case "TABLE":
        if (data.ExtendedCase.Tables[e.DbText]) {
          data.ExtendedCase.Tables[e.DbText].Rows = e.Data().map(row => row.map(element => element()));
        } else {
        data.ExtendedCase.Tables[e.DbText] = {
          Rows: e.Data().map(row => row.map(element => element())),
          Text: "",
          ColumnVertical: true,
          Headers: e.Headers
         }
       }
        break;
      default: throw "Don't know element: " + JSON.stringify(e);
    }
  }

  let normalForms: model.FormElement[][][] = [
    e.Identifikationsdaten,
    ...e.Organisationsdaten.Forms()
  ];

  for (let svt of e.Sachverhalt) {
    normalForms = normalForms.concat(svt.Forms());
  }

  for (let form of normalForms) {
    for (let row of form) {
      for (let entry of row) {
        copyBack(entry, getSetterForCaseData(entry));
      }
    }
  }

  e.Mandanten.Forms().forEach((form, personIndex) => {
    for (let row of form) {
      for (let entry of row) {
        copyBack(entry, getSetterForPersonData(entry, 0, personIndex));
      }
    }
  })

  e.Gegner.Forms().forEach((form, personIndex) => {
    for (let row of form) {
      for (let entry of row) {
        copyBack(entry, getSetterForPersonData(entry, 1, personIndex));
      }
    }
  })

  e.Gerichte.Forms().forEach((form, personIndex) => {
    for (let row of form) {
      for (let entry of row) {
        copyBack(entry, getSetterForPersonData(entry, 6, personIndex));
      }
    }
  })

  e.Weitere.Forms().forEach((form, personIndex) => {
    for (let row of form) {
      for (let entry of row) {
        copyBack(entry, getSetterForPersonData(entry, 7, personIndex));
      }
    }
  })
}

function transformLayoutFormElement(layoutElement: any): model.FormElement {
  const newElement: model.FormElement = {
    Type:          layoutElement.Type,
    Size:          layoutElement.Size,
    Skip:          layoutElement.Skip,
    ReadOnly:      layoutElement.ReadOnly,
    MaxLength:     layoutElement.MaxLength,
    Label:         layoutElement.Label,
    SelectionShow: layoutElement.SelectionShow,
  }

  setIfNotNull(newElement, "Database" , layoutElement.Database);
  setIfNotNull(newElement, "DbText"   , layoutElement.DbText);
  setIfNotNull(newElement, "Alignment", layoutElement.Alignment);
  setIfNotNull(newElement, "WebInfo"  , layoutElement.WebInfo);
  setIfNotNull(newElement, "Values"   , layoutElement.Values);
  setIfNotNull(newElement, "Headers"  , layoutElement.Headers);

  if (layoutElement.SelectionFrom && layoutElement.SelectionProperty) {
    newElement.Selection= {
      SelectionFrom:     layoutElement.SelectionFrom,
      SelectionProperty: layoutElement.SelectionProperty,
    };
  }

  switch (newElement.Type) {
    case "HEADLINE": break;
    case "LABEL": break;

    case "TEXTBOX":    newElement.Data = ko.observable("");      break;
    case "CHECKBOX":   newElement.Data = ko.observable(false);   break;
    case "COMBOBOX":   newElement.Data = ko.observable("");      break;
    case "RADIOGROUP": newElement.Data = ko.observable("");      break;
    case "TEXTAREA":   newElement.Data = ko.observable("");      break;
    case "TABLE":      newElement.Data = ko.observableArray([]); break;

    default: Utils.assertNever(newElement.Type); break;
  }

  return newElement;
}

function fillData(extended: model.Extended, caseData: any): void {
  type Resolver = (source: string | undefined, name: string) => string | undefined;

  const resolveCaseData: Resolver = (source, name) => {
    if (source === "XML/Default" || source === "XML/Akte") {
      return caseData.ExtendedCase.Infos[name];
    } else if (source === "Fats/Akte") {
      return caseData.Akte[name];
    }
  }

  function resolvePersonData(addressData): Resolver {
    const resolveAdditionalDataByKeyword: (keyword: string) => any = keyword => {
      const personData = caseData.ExtendedCase.Persons.find(data => data.subeg === keyword);
      if (personData) {
        return personData[name as any];
      } else {
        return undefined;
      }
    };

    return (source: string | undefined, name: string) => {
      if (source && source.startsWith("XML")) {
        if (addressData.Keyword) {
          return resolveAdditionalDataByKeyword(addressData.Keyword);
        } else {
          return undefined;
        }
      } else if (source === "Fats/Default") {
        return addressData[name]
      } else {
        return undefined;
      }
    }
  }

  function parseDate(input: string, format?: string): moment.Moment {
    let m = format ? moment(input, format, true) : moment(input);
    if (m.isValid()) {
      return m;
    } else {
      throw new Error(`input ${input} was not ${format}`);
    }
  }

  function fillEntry(e: model.FormElement, resolver: Resolver): void {
    let resolved = resolver(e.Database, e.DbText);
    switch (e.Type) {
      case "HEADLINE": break;
      case "LABEL": break;

      case "COMBOBOX":
      case "TEXTAREA":
      case "TEXTBOX":
        if (resolved) {
          if (e.WebInfo === "Datum") {
            try {
              let date: any = undefined;
              if (e.Database === "Fats/Akte" && e.DbText === "AnlageDatum") {
                date = parseDate(resolved, "DD.MM.YYYY")
              } else if (e.Database === "Fats/Akte") {
                date = parseDate(resolved);
              } else {
                date = parseDate(resolved, "DD.MM.YYYY");
              }
              e.Data(date.format("YYYY-MM-DD"));
            } catch {
              e.Data("");
            }
          } else {
            e.Data(resolved);
          }
        } else {
          e.Data("");
        }
        break;
      case "CHECKBOX":
        if (!resolved || typeof(resolved) === "string" && resolved.toLowerCase() === "false") {
          e.Data(false);
        } else {
          e.Data(true);
        }
        break;
      case "RADIOGROUP":
        let res = Object.keys(e.Values).map((k) => e.Values[k]).find(dbEntry => {
          let r = resolver(e.Database, dbEntry);
          return r && typeof(r) === 'string' && r.toLowerCase() === "true";
        });
        if (resolved) {
          e.Data(res);
        } else {
          e.Data("");
        }
        break;
      case "TABLE":
        const tableDescription = caseData.ExtendedCase.Tables[e.DbText];
        if (tableDescription) {
          // tableDescription.Rows.forEach(row => e.Data.push(makeObservables(row)));
        }
        break;
      default: throw "Don't know element: " + JSON.stringify(e);
    }
  }

  function makeObservables(row: string[]): ko.Observable<string>[] {
    return row.map(element => ko.observable(element));
  }

  function fillForm(form: model.FormElement[][], r: Resolver): void {
    for (let row of form) {
      for (let element of row) {
        fillEntry(element, r);
      }
    }
  }

  function fillCaseTab(t: model.Tab): void {
    t.Forms().forEach(form => fillForm(form, resolveCaseData));
  }

  fillForm(extended.Identifikationsdaten, resolveCaseData);
  fillCaseTab(extended.Organisationsdaten);
  extended.Sachverhalt.forEach(fillCaseTab);

  const personTypes: PersonLookupDescription[] = [
    { name: "Mandanten", addressesIndex: 0 },
    { name: "Gegner",    addressesIndex: 1 },
    { name: "Gerichte",  addressesIndex: 6 },
    { name: "Weitere",   addressesIndex: 7 },
  ];

  personTypes.forEach(description => {
    extended[description.name].Forms().forEach((form, index) => {
      const resolver: Resolver = resolvePersonData(caseData.Addresses[description.addressesIndex][index])
      fillForm(form, resolver);
    });
  });
}

interface PersonLookupDescription {
  name:           string,
  addressesIndex: number,
}

function setIfNotNull(o: any, attribute: string, value: any): void {
  if (value !== null) o[attribute] = value;
}

interface Data {
  caseId:               string,
  layout:               any,
  caseData:             any,
  selectedRechtsGebiet: ko.Observable<string>,
  selectedBereich:      ko.Observable<string>,
  extended:             model.Extended,
  currentTab:           ko.Observable<string>,
}

var html = fs.readFileSync(__dirname + '/extendedMain.html', 'utf8');

ko.components.register("extended-main-view", {
    viewModel: ExtendedMainViewModel,
    template: html
});
