import * as fs from "fs";
import * as ko from "knockout";
import { Headline, GroupNode } from './tree';
import { RNSAPI } from "../../api";
import { MainViewModel } from "../../main";
import { Utils } from "../../utils";
import '../dialog/dialog';

export class HeadlineViewModel {
    headline: ko.Observable<Headline>;
    IsEditMode: ko.Observable<boolean>;
    prefix: ko.Computed<string>;
    public static templates: Array<string> = [];

    public static createHeadline(): Headline {
        return {
            Title: ko.observable(""),
            Groups: ko.observableArray([])
        };
    }

    uuid = Utils.uuidv4();
    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalHandleSelection = ko.observable();

    async pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    };

    private static extractFiles(node: any): Array<string> {
        if (node.type === "DIRECTORY") {
            return [].concat.apply([], node.subs.map(n => HeadlineViewModel.extractFiles(n)));
        } else {
            return [node.name];
        }
    }

    static async loadTemplates() {
        let tree = (await RNSAPI.getTemplates()).Payload.Tree;
        HeadlineViewModel.templates = [].concat.apply([], tree.map(node => HeadlineViewModel.extractFiles(node)));
    }

    async pickTemplate(link: ko.Observable<string>) {
        let temps = HeadlineViewModel.templates.map(t => { return { "Text": t }; });
        this.pickGeneric("Text", ["Text"], ["Text"], temps);
        this.modalHandleSelection(
            (selectedObject) => {
                link(selectedObject()["Text"]);
            }
        );
        $(`#${this.uuid}modal`).modal('show');
    };

    public createGroupHandler() {
        this.headline().Groups.push(ko.observable(HeadlineViewModel.createGroup()));
    }

    public deleteGroupHandler(index: ko.Observable<number>) {
        this.headline().Groups.splice(index(), 1);
    }

    public deleteHeadlineHandler(group: ko.Observable<GroupNode>, index: ko.Observable<number>) {
        group().More.splice(index(), 1);
    }

    public createHeadlineHandler(group: ko.Observable<GroupNode>) {
        group().More.push(ko.observable(HeadlineViewModel.createHeadline()));
    }

    public createLinkHandler(group: ko.Observable<GroupNode>) {
        group().Links.push(ko.observable(""));
    }

    public deleteLinkHandler(group: ko.Observable<GroupNode>, index: ko.Observable<number>) {
        group().Links.splice(index(), 1);
    }

    public generateComputable(index: ko.Computed<number>) {
        return ko.computed(() => {
            return `${this.prefix()}${(index() + 1)}.`;
        });
    }

    public static createGroup(): GroupNode {
        return {
            Checked: ko.observable(false),
            Id: ko.observable(""),
            Description: ko.observable(""),
            Title: ko.observable(""),
            SubTitle: ko.observable(""),
            Links: ko.observableArray([]),
            More: ko.observableArray([]),
        };
    }

    constructor(params: any) {
        this.headline = params.headline;
        this.IsEditMode = params.edit;
        this.prefix = params.prefix;
    }
}

let html = fs.readFileSync(__dirname + '/headline.html', 'utf8');

ko.components.register("headline-view", {
    viewModel: HeadlineViewModel,
    template: html
});
