import { Utils } from "../../utils";
import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment"
import { RNSAPI } from "../../api";

class Day {
    day: number;
    appointments: Array<any>

    constructor(day: number, appointments: Array<any>) {
        this.day = day;
        this.appointments = appointments;
    }
}

class AppointmentMonthlyViewModel {
    weeks: ko.ObservableArray<Array<Day>> = ko.observableArray([]);
    parentVM: any;

    public deleteAppointment = async (appointment: any) => {
        await RNSAPI.deleteAppointment(appointment.Id);
        this.parentVM.update();
    }

    public id(appointment: any) {
        return encodeURIComponent(appointment.Id);
    }

    async loadAppointments() {
        let startOfMonth = moment.utc().startOf("month");
        let endOfMonth = moment.utc().endOf("month");

        let appointments = (await RNSAPI.getAppointments(startOfMonth.toDate(), endOfMonth.toDate())).Payload.Appointments;

        let offset = startOfMonth.day() - 1;
        let daysInMonth = startOfMonth.daysInMonth();

        let days = Array(offset).fill(new Day(null, []));

        for (let i = 1; i <= daysInMonth; i++) {
            days.push(new Day(i, appointments.filter(appointment => {
                let m = moment.utc(appointment.StartDate);
                return m.date() === i;
            })));
        }

        //pad to a multiple of 7
        let length = days.length;
        days = days.concat(Array(length % 7 === 0 ? 0 : (7 - length % 7)).fill(new Day(null, [])));

        this.weeks(Utils.partition(days, 7));
    }

    constructor(params) {
        this.loadAppointments();

        this.parentVM = params.vm;
    }
}

let html = fs.readFileSync(__dirname + '/monthly.html', 'utf8');

ko.components.register("monthly-view", {
    viewModel: AppointmentMonthlyViewModel,
    template: html
});