import { Utils } from '../../utils';
import * as fs from 'fs';
import * as ko from "knockout";
import * as model from './model';

export class ExtendedTabsViewModel {
  constructor(params) {
    this.data = params.data;
    if (this.data.length > 0) {
      this.active(this.data[0].Name);
    }
  }

  active: ko.Observable<string | undefined> = ko.observable(undefined);
  data: model.Tab[];
}

var html = fs.readFileSync(__dirname + '/extendedTabs.html', 'utf8');

ko.components.register("extended-tabs-view", {
    viewModel: ExtendedTabsViewModel,
    template: html
});
