import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment"
import { RNSAPI } from "../../api";
import { Utils } from "../../utils";
import { MainViewModel } from "../../main";

enum Limit {
    NO = 1,
    Aktenansicht = 2,
    SBSpezifisch = 3
}

class ViewViewModel {
    IsEditMode = ko.observable(false);

    allTags = ko.observable({});
    allTagsKeys = ko.computed(() => Object.keys(this.allTags()));

    //export to ko view
    Limit = Limit
    limit = ko.observable(Limit.NO)

    newBasicView = () => {
        let obj = {
            AN: ko.observable("A"),
            Bezeichnung: ko.observable(""),
            Aktenansicht: ko.observable(false),
            SBSpezifisch: ko.observable(false),
            Tags: ko.computed(() => {
                return Object.keys(this.allTags()).filter(k => this.allTags()[k]());
            })
        };

        return ko.observable(obj);
    };

    basicView = this.newBasicView();

    resetObject(obj) {
        for (let key in obj) {
            obj[key](null);
        }
    }

    async postData() {
        $(".form-group").each(function () { $(this).removeClass("has-error"); });
        if (!Utils.checkErrors(["Bezeichnung"], this.basicView(), "view", [Utils.checkString])) return;

        if (this.IsEditMode()) {
            try {
                let result = await RNSAPI.updateView(this.basicView());
                if (result.Type === "UpdateSuccessful") {
                    MainViewModel.RoutingTable.showViewOverview();
                } else {
                    alert("Fehler beim Speichern.");
                }
            } catch (e) {
                console.log(e);
                alert("Fehler beim Speichern: " + e.responseText);
            }
        } else {
            try {
                let result = await RNSAPI.createView(this.basicView());
                if (result.Type === "AddSuccessful") {
                    MainViewModel.RoutingTable.showViewOverview();
                }
                else if (result.Type === "ViewAlreadyExist") {
                    alert("Eine Ansicht mit dem Namen gibt es bereits.");
                } else {
                    alert("Fehler beim Anlegen der Ansicht.");
                }
            } catch (e) {
                console.log(e);
                alert("Fehler beim Anlegen: " + e.responseText);
            }
        }
    };

    async loadTags() {
        let tags = (await RNSAPI.getAllTagTypes()).Payload.Tags;
        let tagsObj = {};
        tags.forEach(t => {
            tagsObj[t.TagName] = ko.observable(false);
        });

        this.allTags(tagsObj);
    }

    async loadView(name: string) {
        try {
            let result = await RNSAPI.getViewByName(name);

            if (result.Type !== "ViewNotFound") {
                let view = result.Payload.Views[0];
                let newView = this.newBasicView();
                let obj = newView();

                let excluding = ['Tags'];

                for (let key in view) {
                    if (obj[key] === undefined) {
                        obj[key] = view[key];
                    } else if (excluding.indexOf(key) === -1) {
                        obj[key](view[key]);
                    }
                }

                if (view.Aktenansicht) {
                    this.limit(Limit.Aktenansicht);
                } else if (view.SBSpezifisch) {
                    this.limit(Limit.SBSpezifisch);
                } else {
                    this.limit(Limit.NO);
                }

                view.Tags.forEach(t => this.allTags()[t](true));

                this.basicView(obj);
            } else {
                alert("Ansicht nicht gefunden.");
            }
        } catch (e) {
            alert("Verbindung zum Server abgebrochen.");
        }
    }

    async loadData(params: any) {
        params = params || {};
        let name = params.name;
        this.IsEditMode(name !== undefined);

        await this.loadTags();
        if (this.IsEditMode()) {
            await this.loadView(name);
        }

        this.limit.subscribe(limit => {
            switch (limit) {
                case Limit.NO:
                    this.basicView().Aktenansicht(false);
                    this.basicView().SBSpezifisch(false);
                    break;
                case Limit.Aktenansicht:
                    this.basicView().Aktenansicht(true);
                    this.basicView().SBSpezifisch(false);
                    break;
                case Limit.SBSpezifisch:
                    this.basicView().Aktenansicht(false);
                    this.basicView().SBSpezifisch(true);
                    break;
            }
        })
    }

    constructor(params: any) {
        this.loadData(params);
    }
}

let html = fs.readFileSync(__dirname + '/view.html', 'utf8');

ko.components.register("view-view", {
    viewModel: ViewViewModel,
    template: html
});
