import * as ko from "knockout";

class EmptyViewModel {
    constructor(params) {
    }
}

let html = "<div></div>";

ko.components.register("empty-view", {
    viewModel: EmptyViewModel,
    template: html
});