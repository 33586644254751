import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment";
import { RNSAPI } from "../../api";
import { MainViewModel } from "../../main";
import '../zpe/mail';
import '../dataTable/dataTable';
import '../empty/empty';
import { type } from "jquery";
import { Utils } from "../../utils";
import { RolesAndRights } from "../../helpers/RolesAndRights";

export class Filter {
    CaseId: string;
    StartDate: string;
    EndDate: string;
    ShorthandSymbol: string;
    Workflow: string;
    Dictation: string;
    View: string;
    Limit: number;

    constructor(caseId: string, startDate: string, endDate: string, shorthandSymbol: string, workflow: string, dictation: string, view: string, limit: number) {
        this.CaseId = caseId;
        this.StartDate = startDate;
        this.EndDate = endDate;
        this.ShorthandSymbol = shorthandSymbol;
        this.Workflow = workflow;
        this.Dictation = dictation;
        this.View = view;
        this.Limit = limit;
    }
}

export class Stamp {
    Id: string | null;
    Text: string;
    Type: string;

    constructor(Text: string, Type: string, Id?: string) {
        this.Text = Text;
        this.Type = Type;
        if (Id) this.Id = Id;
    }
}

class PredefinedStamp {
    AnnoTyp: string
    Bezeichnung: string;
    Text: string
    Workflow: string
    Farbe: string
}

export class WorkflowsViewModel {
    currentCategory = ko.observable('overview');
    currentFilter = ko.observable(new Filter(null, null, null, null, null, null, null, 100));
    entries = ko.observableArray([]);
    currentViewer = ko.observable("pdf");
    predefinedStamps = ko.observableArray([]);
    currentStamps: ko.ObservableArray<Stamp> = ko.observableArray([]);
    currentId = ko.observable(null);
    currentMimeType = ko.observable(null);
    deleteEntryid = ko.observable("");
    documentToEmail = ko.observable(null);
    emailAddress = ko.observable("");
    sachbearbeiter = ko.observable("");
    caseID = ko.observable("");
    startDate = ko.observable("");
    endDate = ko.observable("");
    currentUser = ko.observable("");
    hasParams = ko.observable(false);

    isViewerRole = ko.observable<boolean>(false);

    wopiToken = {
        "AccessToken": "",
        "AccessTokenTtl": 0
    }

    workflows = [
        {
            "Id": "PE",
            "Description": "Posteingang",
            "Followers": ["B", "RA"]
        }, {
            "Id": "RA",
            "Description": "Bearbeitung Rechtsanwalt",
            "Followers": ["B", "V"]
        }, {
            "Id": "B",
            "Description": "Bearbeitung Büro",
            "Followers": ["ZU", "RA"]
        }, {
            "Id": "ZU",
            "Description": "Unterschriftsmappe",
            "Followers": ["B", "V"]
        }, {
            "Id": "V",
            "Description": "Postausgang",
            "Followers": ["PA"]
        }, {
            "Id": "PA",
            "Description": "Gesendet",
            "Followers": ["E"]
        }
        , {
            "Id": "E",
            "Description": "Erledigt",
            "Followers": []
        }
    ]

    wfNames = {
        "PE": "Posteingang",
        "RA": "Bearbeitung Rechtsanwalt",
        "B": "Bearbeitung Büro",
        "ZU": "Unterschriftsmappe",
        "V": "Postausgang",
        "PA": "Gesendet",
        "E": "Erledigt",
    }

    async pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    };

    modalHandleSelection = ko.observable();
    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);

    openaktenoverview = async () => {
        window.open("/#/cases")
    }

    private async loadPredefinedStamps() {
        let templates = (await RNSAPI.getStampTemplates()).Payload.Templates;
        this.predefinedStamps(templates);
    }

    createStampFromTemplate = async (stamp: PredefinedStamp) => {
        let result = await RNSAPI.addStamp(stamp.Text, "Regular", this.currentId());
        if (result.Type === "AddSuccessful") {
            this.updateStamps();
        } else {
            alert("Fehler beim Erstellen des Stempels.");
        }
    }

    public async updateStamps() {
        this.loadStamps(this.currentId());
    }

    private async loadStamps(recordId: string) {
        if (recordId) {
            let stamps = (await RNSAPI.getStampsByECaseId(recordId)).Payload.Stamps;
            let stampsWithEditedDate = stamps.map(stamp => {
                stamp.CreationDate = (new Date(stamp.CreationDate)).toLocaleDateString();
                return stamp;
            });
            this.currentStamps(stampsWithEditedDate);
        }
    }

    deleteStamp = async (stampId: string) => {
        let result = await RNSAPI.deleteStampById(stampId);
        if (result.Type === "DeleteStampSuccessful") {
            this.updateStamps();
        } else {
            alert("Fehler beim Löschen des Stempels.");
        }
    }

    async showPDF(recordId) {
        let { DocumentData } = (await RNSAPI.getDocumentData(recordId)).Payload.Document
        let blob = Utils.base64ToBlob(DocumentData, "application/pdf");
        var file = window.URL.createObjectURL(blob);
        $('#pdf-view').attr('src', file)
    }

    async showWorkflowModal(entryId: string, caseId: string) {
        let workflows = ko.toJS(this.workflows);
        this.pickGeneric("Workflow festlegen", ["Description"], ["Workflow"], workflows);
        this.modalHandleSelection((selectedObject) => {
            //let selectedWF(selectedObject()["Id"]));
            this.setWorkflow(entryId, selectedObject()["Id"])
        });
        $('#modal').modal('show');
    }

    async setWorkflow(entryId: string, mark: string) {
        await RNSAPI.setCaseEntryWorkflowMark(entryId, mark);
        this.getData(ko.toJS(this.currentCategory));
    }

    SetSB() {
        if (ko.toJS(this.sachbearbeiter) === "")
            this.currentFilter().ShorthandSymbol = null;
        else
            this.currentFilter().ShorthandSymbol = ko.toJS(this.sachbearbeiter);
        if (!ko.toJS(this.hasParams()))
            this.getData(ko.toJS(this.currentCategory))
    }

    SetCase() {
        if (ko.toJS(this.caseID) === "")
            this.currentFilter().CaseId = null;
        else
            this.currentFilter().CaseId = ko.toJS(this.caseID);
        this.getData(ko.toJS(this.currentCategory))
    }

    async setSbEntry(entry, sb: string) {
        entry.LawyerId = sb;
        await RNSAPI.updateCaseEntry(entry)
        this.getData(ko.toJS(this.currentCategory));
    }

    async pickClerk() {
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric("Sachbearbeiter", ["Sachbearbeiter"], ["Sachbearbeiter"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => {
            this.sachbearbeiter((selectedObject()["Sachbearbeiter_ID"]));
        });
        $('#modal').modal('show');
    };

    async pickClerkDelegation(entry) {
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric("Dokument delegieren", ["Sachbearbeiter"], ["Sachbearbeiter"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => {
            this.setSbEntry(entry, (selectedObject()["Sachbearbeiter_ID"]));
        });
        $('#modal').modal('show');
    };

    async pickCase() {
        let cases = (await RNSAPI.getCases()).Payload.Cases;
        this.pickGeneric("Akte", ["Registernummer", "Rubrum", "Wegen"], ["Akte", "Rubrum", "Wegen"], cases);
        this.modalHandleSelection((selectedObject) => {
            this.caseID(selectedObject()["Registernummer"]);
        });
        $('#modal').modal('show');
    };

    async download(name: string, id: string) {
        let element = document.createElement('a');
        let doc = (await RNSAPI.getDocumentData(id)).Payload.Document;
        let IsRTF = atob(doc.DocumentData.substr(0, 50)).indexOf("rtf") !== -1;
        let type = IsRTF ? "rtf" : doc.OLE2Type.trim().toLowerCase();

        let blob = Utils.base64ToBlob(doc.DocumentData, 'application/octet-stream');

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob);
        } else {
            element.setAttribute('href', 'data:application/octet-stream;charset=utf-16le;base64,' + doc.DocumentData);
            element.setAttribute('download', `${name.trim()}.${type}`);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }
    }

    async remove(id: string) {
        $("#DeleteEntry").modal('show');
        this.deleteEntryid(id);
    }

    async actuallydelete() {
        await RNSAPI.deleteRecord(ko.toJS(this.deleteEntryid));
        await this.getData(ko.toJS(this.currentCategory));
        $("#DeleteEntry").modal('hide');
    }

    async sendMail(entry) {
        this.currentViewer("compose");
        this.documentToEmail({
            entry: entry,
            email: this.emailAddress()
        });
        $('#DocumentViewerModal').modal('show');
    }

    async getData(type) {
        this.currentCategory(type);

        switch (type) {
            case "myInbox":
                this.currentFilter().Workflow = "PE";
                break;
            case "processLawyer":
                this.currentFilter().Workflow = "RA";
                break;
            case "processOffice":
                this.currentFilter().Workflow = "B";
                break;
            case "sign":
                this.currentFilter().Workflow = "ZU";
                break;
            case "outbox":
                this.currentFilter().Workflow = "V";
                break;
            case "sent":
                this.currentFilter().Workflow = "PA";
                break;
            case "done":
                this.currentFilter().Workflow = "E";
                break;
            case "overview":
            default:
                this.currentFilter().Workflow = null;
        }
        this.currentFilter().StartDate = ko.toJS(this.startDate);
        if (ko.toJS(this.currentFilter().StartDate) === "")
            this.currentFilter().StartDate = null;
        this.currentFilter().EndDate = ko.toJS(this.endDate);
        if (ko.toJS(this.currentFilter().EndDate) === "")
            this.currentFilter().EndDate = null;

        let entries = (await RNSAPI.getCaseEntriesForFilter(ko.toJS(this.currentFilter))).Payload.Records;

        entries.sort((a, b) => {
            let dateA = new Date(a.LastChange || a.DocumentDate || a.RecordDate);
            let dateB = new Date(b.LastChange || b.DocumentDate || b.RecordDate);
            if (dateA < dateB) return 1;
            else if (dateA > dateB) return -1;
            else return 0
        }).map((entry) => {
            const options = { day: '2-digit', month: '2-digit', year: 'numeric' } as const;

            entry.DocumentDate = new Date(entry.LastChange || entry.DocumentDate || entry.RecordDate).toLocaleDateString('de-DE', options);

            if(!this.isViewerRole()) {
                entry.columnActions = {
                    "CaseId": MainViewModel.RoutingTable.generateLink(`/new/${encodeURIComponent(entry.CaseId)}`)
                };


                entry.actionHandlers = [
                    {
                        'icon': 'paperclip',
                        'name': 'Workflow',
                        'action': () => {
                            this.showWorkflowModal(entry.Id, entry.CaseId);
                        }
                    },
                    {
                        'icon': 'user-circle',
                        'name': 'Delegieren',
                        'action': () => {
                            this.pickClerkDelegation(entry);
                        }
                    },
                    {
                        'icon': 'cloud-download-alt',
                        'name': 'Download',
                        'action': () => {
                            this.download(entry.Subject, entry.Id);
                        }
                    },
                    {
                        'icon': 'trash',
                        'name': 'Löschen',
                        'action': () => {
                            this.remove(entry.Id);
                        }
                    }];

                entry.actionHandlers.unshift({
                    'icon': 'eye',
                    'name': 'Vorschau',
                    'action': () => {
                        this.currentStamps([]);
                        this.currentViewer("");
                        this.currentMimeType(entry.MimeType.toLowerCase());
                        this.currentId(entry.Id);
                        if (entry.MimeType.toLowerCase().indexOf("pdf") !== -1) {
                            this.currentViewer("pdf");
                            this.updateStamps();
                            this.showPDF(entry.Id);
                        } else if (entry.MimeType.toLowerCase().indexOf("rfc822") !== -1) {
                            this.currentViewer("mail");
                            this.updateStamps();
                        } else if (entry.MimeType.toLowerCase().indexOf("audio/ogg") !== -1) {
                            this.currentViewer("play");
                        } else {
                            this.currentViewer("wopi");
                            this.updateStamps();
                        }
                        $('#DocumentViewerModal').modal('show');
                    }
                });
        }

            if (entry.WorkflowId === "V") {
                entry.actionHandlers.push({
                    'icon': 'envelope',
                    'name': 'Versenden',
                    'action': () => {
                        this.currentId(entry.Id);
                        this.currentViewer('')
                        this.sendMail(entry);
                    }
                });
            }

            if (entry.MimeType.toLowerCase() === "audio/ogg" && entry.WorkflowId !== 'E' && !entry.TypistId) {
                entry.actionHandlers.push({
                    'icon': 'check',
                    'name': 'Erledigen',
                    'action': async () => {
                        await RNSAPI.markDictationAsDone(entry.Id);
                        this.getData(ko.toJS(this.currentCategory));
                    }
                });
            }

            entry.Workflow = this.wfNames[entry.WorkflowId];

            return entry;
        });
        this.entries(entries);
    }

    constructor(params: any) {
        this.isViewerRole(RolesAndRights.isViewerRole());
        if (params !== undefined) {
            this.hasParams(true);
        }
        this.startDate(ko.toJS(moment().subtract(2, 'months').format("YYYY-MM-DD")));
        this.endDate(ko.toJS(moment().add(1, 'months').format("YYYY-MM-DD")));
        this.currentFilter().StartDate = ko.toJS(this.startDate);
        this.currentFilter().EndDate = ko.toJS(this.endDate);
        this.loadPredefinedStamps();
        this.sachbearbeiter.subscribe(() => this.SetSB());

        if (params !== undefined) {
            if (params.workflow !== undefined) {
                let user = RNSAPI.getCurrentLoggedInUser();
                this.sachbearbeiter(user.ShortHandSymbol);
                switch (params.workflow) {
                    case "PE": this.currentCategory("myInbox");
                        break;
                    case "PA": this.currentCategory("outbox");
                        break;
                    case "V": this.currentCategory("sent");
                        break;
                }
            }
        }

        this.hasParams(false);
        this.getData(ko.toJS(this.currentCategory));
        this.startDate.subscribe(() => this.getData(ko.toJS(this.currentCategory)));
        this.endDate.subscribe(() => this.getData(ko.toJS(this.currentCategory)));
        this.caseID.subscribe(() => this.SetCase());
    }
}

let html = fs.readFileSync(__dirname + '/overview.html', 'utf8');

ko.components.register("workflows-view", {
    viewModel: WorkflowsViewModel,
    template: html
});