import { Utils } from '../../utils';
import * as moment from 'moment';
import * as ko from "knockout";
import * as fs from 'fs';
import { Postbox } from '../dashboard/postbox'
import { RolesAndRights } from '../../helpers/RolesAndRights';

export class DataTableViewModel {
    numberOfItems: ko.Observable<number>
    numberOfPages: ko.Computed<number>
    showDots: boolean
    stars = ko.observable(false);
    colLength = ko.observable();
    icons = ko.observable("");
    currentPage = ko.observable(0)
    columns: Array<string>
    columnHeaders: Array<string>
    entries: ko.Computed<Array<any>>
    allItems: ko.ObservableArray<any>
    filteredItems: ko.Computed<any>
    filter = ko.observable('');
    sortByHeader = ko.observable("");
    sortBy = ko.computed({
        owner: this,
        read: () => {
            if (this.sortByHeader())
                return ko.toJS(this.columns)[ko.toJS(this.columnHeaders).indexOf(this.sortByHeader())];
            else
                return "";
        }
    });
    sortAsc = ko.observable(true);
    emptyMessage: string
    hasActions: boolean
    hasDLK: boolean
    isEmpty: ko.Computed<boolean>
    hasButton = ko.observable(false);
    hasAddressButton = ko.observable(false);
    hasDropDown = ko.observable(false);
    hasDTLayout = ko.observable(true);
    hasTabs = ko.observable(true);
    tooltips = ko.observableArray([]);
    resetpadleft = ko.observable(false);
    pageCounter = ko.observable(0);
    isFTW = ko.observable(false);

    Page1 = ko.observable(1);
    Page2 = ko.observable(2);
    Page3 = ko.observable(3);
    Page4 = ko.observable(4);
    Page5 = ko.observable(5);

    hasDeadlineManagementRights = ko.observable<boolean>(false);
    isViewerRole = ko.observable<boolean>(false);

    currentPages = ko.observableArray([1, 2, 3, 4, 5]);

    showCaseViewButton = ko.observable(false);

    selectedElement = ko.observable(null);


    previous = () => {
        this.currentPage(Math.max(0, this.currentPage() - 1));
        this.updateNumbers();
    }

    first = () => {
        this.currentPage(0);
        this.updateNumbers();
    }

    next = () => {
        this.currentPage(Math.min(this.numberOfPages() - 1, this.currentPage() + 1));
        this.updateNumbers();
    }

    last = () => {
        this.currentPage(this.numberOfPages() - 1);
        this.updateNumbers();
    }


    direct(pagenr: number) {
        this.currentPage(pagenr - 1);
        this.updateNumbers();
    }

    sort = (name: string) => {
        this.sortByHeader(name);
        this.sortAsc(!this.sortAsc())
    }

    addCaret = (name: string) => {
        if (this.sortByHeader() == name && this.sortAsc())
            return '<span class="fa fa-fw fa-sort-asc"></span>' + name;
        else if (this.sortByHeader() == name && !this.sortAsc())
            return '<span class="fa fa-fw fa-sort-desc"></span>' + name;
        return '<span class="fa fa-fw fa-sort"></span>' + name;
    }

    updateNumbers = () => {
        if (ko.toJS(this.currentPage()) < 3 || ko.toJS(this.numberOfPages) <= 5) {
            this.Page1(1);
            this.Page2(2);
            this.Page3(3);
            this.Page4(4);
            this.Page5(5);

        }
        else if (ko.toJS(this.currentPage()) >= 3 && ko.toJS(this.currentPage) < ko.toJS(this.numberOfPages() - 2)) {
            this.Page1(this.currentPage() - 1);
            this.Page2(this.currentPage());
            this.Page3(this.currentPage() + 1);
            this.Page4(this.currentPage() + 2);
            this.Page5(this.currentPage() + 3);

        }
        else if (ko.toJS(this.currentPage()) >= ko.toJS(this.numberOfPages() - 2)) {
            this.Page1(this.numberOfPages() - 4);
            this.Page2(this.numberOfPages() - 3);
            this.Page3(this.numberOfPages() - 2);
            this.Page4(this.numberOfPages() - 1);
            this.Page5(this.numberOfPages());

        }
    }

    resetFilter = () => this.filter("");

    executeAction = (elem: any, handler: any) => {
        if (handler.selectable) {
            this.selectedElement(elem);
        }

        handler.action();
    }

    newDeadline = async () => {
        $('#NewDeadlineDialogExt').modal('show');
    }

    newDeadlineReport = async () => {
        $('#reportDeadlineModal').modal('show');
        let start = (<HTMLInputElement>document.getElementById("rStartDate")).value;
        let end = (<HTMLInputElement>document.getElementById("rEndDate")).value;
        (<HTMLInputElement>document.getElementById("startRepDeadline")).value = start;
        (<HTMLInputElement>document.getElementById("endRepDeadline")).value = end;
        //Postbox.publisher().publish({ start, end }, "UpdateRepDeadlines");
    }

    newResubmission = async () => {

        $('#NewResubmissionDialogExt').modal('show');
    }

    openAddressModal(type) {
        Postbox.publisher().publish(type, 'clearAddressModal')
        $('#allAddressModal').modal('show');
    }

    newAppointment = async () => {
        $('#AppointmentDatamodal').modal('show');
    }
    pickCase = async () => {
        $('#modal').modal('show');
    }

    hasColumnAction = (parent: any, data: any) => parent.columnActions && parent.columnActions[data];
    hasTooltip = (parent: any, data: any) => parent.columnTooltips && parent.columnTooltips[data];
    hasIncludes = (parent: any, data: any) => {
        const found = this.tooltips().find(item => parent.originalObj[item.name] && item.col === data)
        let result;
        if (found && parent.originalObj[found.name]) {
            if (data === "subject") {
                if (this.isNotfrist(parent.originalObj.TypeId))
                    result = 'Notfrist';
            }
            else
                result = 'Vorfrist: ' + moment(parent.originalObj[found.name]).format("DD.MM.YYYY")
        }
        return result
    }

    isNotfrist(TypeId: string) {
        switch (TypeId) {
            case "AV":
                return true;
            case "B":
                return true;
            case "ER":
                return true;
            case "ES":
                return true;
            case "NZ":
                return true;
            case "R":
                return true;
            case "SB":
                return true;
            case "RV":
                return true;
            case "We":
                return true;
            case "EP":
                return true;
            case "WK":
                return true;
            case "GE":
                return true;
            case "EA":
                return true;
            case "Er":
                return true;
            case "WA":
                return true;
            default:
                return false;
        }
    }

    postprocess = (elements: Array<HTMLElement>) => {
        for (let element of elements) {
            if (element.classList && element.classList.contains("tooltip-container")) {
                $(element).tooltip();
                break;
            }
        }
    };

    clearFilter() {
        this.filter("");
        if (this.hasDLK) {
            for (let i = 0; i < this.entries().length; i++) {
                this.entries()[i].TransferToBill = false;
                (<HTMLInputElement>document.getElementById(this.entries()[i].ID + "_checkbox")).checked = false;
            }
            $("#multipleDLK").hide();
        }
    }

    checkStars = (color: string) => {
        if (color == "red") {
            this.icons("fa-circle text-danger")
            return true;
        }
        if (color == "green") {
            this.icons("fa-circle text-success");
            return true;
        }
        if (color == "yellow") {
            this.icons("");
            return true;
        }
        if (color == "complete") {
            this.icons("");
            return true;
        }
        else {
            return false;
        }
    }

    setFilter = (caseID: string) => {
        let somethingMarked = 0;
        for (let i = 0; i < this.entries().length; i++) {
            if (this.entries()[i].TransferToBill)
                somethingMarked++;
        }
        if (somethingMarked == 0)
            this.filter("");
        else {
            if (somethingMarked > 1) {
                $("#multipleDLK").show();
            }
            else {
                $("#multipleDLK").hide();
            }
            this.filter(caseID);
        }
    }

    filterSelectionForCase(obj, event) {
        obj.TransferToBill = !obj.TransferToBill;
        Postbox.publisher().publish(obj.CaseID, "UpdateFilterCheckCBs");
    }

    dataTableEntriesLoaded = ko.observable<boolean>(false);
    isEmptyEntries = ko.observable<boolean>(false);

    constructor(params: any) {
        this.hasDeadlineManagementRights(RolesAndRights.hasDeadlineManagementRights());
        this.isViewerRole(RolesAndRights.isViewerRole());


        this.columns = params.columns;
        this.colLength(this.columns);
        this.columnHeaders = params.columnHeaders;
        if (this.columnHeaders[0] == "status") {
            this.stars(true);
        }
        this.emptyMessage = params.emptyMessage;
        this.hasActions = params.hasActions;
        this.hasDLK = params.hasDLK;
        this.allItems = params.entries;
        this.numberOfItems = ko.observable(params.numberOfItems || 10);
        if (params.hasButton)
            this.hasButton(ko.toJS(params.hasButton));
        else
            this.hasButton(false);
        this.hasAddressButton = params.hasAddressButton;
        this.hasDropDown = params.hasDropDown;
        this.hasDTLayout = params.hasDTLayout;
        this.showCaseViewButton = params.showCaseViewButton;
        this.hasTabs = params.hasTabs;
        this.resetpadleft = params.resetpadleft;
        if (params.tooltips) {
            this.tooltips = params.tooltips;
        }
        if (this.hasTabs === undefined) {
            this.hasTabs = ko.observable(true);
        }
        if (params.isFTW)
            this.isFTW(true);

        this.numberOfItems.subscribe(() => this.currentPage(0));

        this.filteredItems = ko.computed({
            owner: this,
            read: () => {
                this.isEmptyEntries(false);
                let filter = this.filter().toLowerCase();
                let items = this.allItems();
                const realyLoaded = items.filter(item => {
                    for (let value of ko.toJS(this.columns).map(column => item[column]) as Array<string>) {
                        if (value && value.toLowerCase().indexOf(filter) !== -1) {
                            this.dataTableEntriesLoaded(true);
                            return true;
                        }
                    }
                    return false;
                });
                if(!this.dataTableEntriesLoaded() && realyLoaded.length === 0) {
                    this.isEmptyEntries(true);
                    this.dataTableEntriesLoaded(true);
                }
                return realyLoaded;
            }
        });

        this.isEmpty = ko.computed({
            owner: this,
            read: () => {
                return this.filteredItems().length === 0;
            }
        });

        this.numberOfPages = ko.computed({
            owner: this,
            read: () => {
                return Math.ceil(this.filteredItems().length / +this.numberOfItems());
            }
        });

        this.numberOfPages.subscribe(newNumberOfPages => this.currentPage(Math.max(0, Math.min(newNumberOfPages - 1, this.currentPage()))));

        this.entries = ko.computed({
            owner: this,
            read: () => {
                let numberOfItems = +this.numberOfItems();
                let items = this.filteredItems();
                let sortBy = this.sortBy();
                let sortAsc = this.sortAsc();
                let isDate = null;
                if (items.length) {
                    items.forEach(element => {
                        isDate = !isDate ? /^\d{2}\.\d{2}\.\d{4}$/.test(element[sortBy]) : true;
                    });
                }
                items.forEach(element => {
                });
                if (sortBy && sortAsc) {
                    if (isDate) {
                        items.sort((a, b) => (moment.utc(a[sortBy], "DD.MM.YYYY", true) > moment.utc(b[sortBy], "DD.MM.YYYY", true)) ? 1 : -1);
                    }
                    else {
                        items.sort((a, b) => { return a[sortBy].trim().toLowerCase().localeCompare(b[sortBy].trim().toLowerCase()); });
                    }
                }
                else if (sortBy && !sortAsc) {
                    if (isDate) {
                        items.sort((a, b) => (moment.utc(a[sortBy], "DD.MM.YYYY", true) < moment.utc(b[sortBy], "DD.MM.YYYY", true)) ? 1 : -1);
                    }
                    else {
                        items.sort((a, b) => { return a[sortBy].trim().toLowerCase().localeCompare(b[sortBy].trim().toLowerCase()) * -1; });
                    }
                }
                else {

                }
                let currentPage = this.currentPage();

                return Utils.partition(items, numberOfItems)[currentPage];
            }
        });

        if (this.hasDLK) {
            Postbox.publisher().subscribe((CaseID) => { this.setFilter(CaseID) }, "UpdateFilterCheckCBs")
            Postbox.publisher().subscribe(() => { this.resetFilter() }, "ResetFilter")
        }
    }
}

export interface Color {
    color: string
    "background-color": string
}

export class IntermediateListItem {
    stars: any
    entryType: string
    entryTypeName: string
    date: string
    dateObj: moment.Moment
    subject: string
    number: string
    originalObj: any
    actionHandlers: any
    color: Color
    rubrum: string
    entryType1: string

    constructor(stars: any, entryType: string, entryTypeName: string, date: string, subject: string, number: string, originalObj: any, actionHandlers?: any, color?: Color, rubrum?: string) {
        this.stars = stars
        this.entryType = entryType
        this.entryTypeName = entryTypeName;
        this.dateObj = moment.utc(date);
        this.date = this.dateObj.format("DD.MM.YYYY");
        this.subject = subject;
        this.number = number;
        this.originalObj = originalObj;
        this.actionHandlers = actionHandlers || [{ name: "Vorschau", action: () => { alert("Aktion für Typ: " + this.entryType) } }];
        this.color = color;
        this.rubrum = rubrum;
    }
};

var html = fs.readFileSync(__dirname + '/dataTable.html', 'utf8');

ko.components.register("data-table", {
    viewModel: DataTableViewModel,
    template: html
});