export let layoutVerkehrsOWI = JSON.parse(`{
  "Identifikationsdaten": [
    [
      {
        "Label": "Identifikationsdaten",
        "Type": "HEADLINE",
        "Size": 12,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Registernummer*",
        "Database": "",
        "DbText": "LRegnr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "Registernummer",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 13,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Referat*",
        "Database": "",
        "DbText": "LblReferat",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "Referatnummer",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 2,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Referat",
        "SelectionProperty": "Name"
      },
      {
        "Label": "",
        "Database": "Nein/Undefiniert",
        "DbText": "Zuo_refnr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Referat",
        "SelectionProperty": "Id"
      }
    ],
    [
      {
        "Label": "Sachbearbeiter*",
        "Database": "",
        "DbText": "LblSachbearbeiter",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "SachbearbeiterId",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 2,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sachbearbeiter",
        "SelectionProperty": "Sachbearbeiter_ID"
      },
      {
        "Label": "",
        "Database": "Nein/Undefiniert",
        "DbText": "Zuo_sachnr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Sachbearbeiter",
        "SelectionProperty": "Sachbearbeiter"
      }
    ],
    [
      {
        "Label": "Anlagedatum",
        "Database": "",
        "DbText": "lblAnlagedatum",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "AnlageDatum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 10,
        "Alignment": null,
        "WebInfo": "Datum",
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Rubrum*",
        "Database": "",
        "DbText": "LblRubrum",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "Rubrum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 64,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Wegen*",
        "Database": "",
        "DbText": "LblWegen",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "Wegen",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 64,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Organisationsdaten": [
    [
      {
        "Label": "Organisationsdaten",
        "Type": "HEADLINE",
        "Size": 12,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Mandatsherkunft",
        "Database": "",
        "DbText": "LblMandat",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Values": [
          "",
          "Empfehlung",
          "Homepage/Internet",
          "Telefonbuch",
          "Regionale Anzeige",
          "Rechtsberater Kontaktformular",
          "Rechtsberater Hilfehotline",
          "Rechtsberater Anwaltshotline",
          "ADAC",
          "Sonstiges"
        ],
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Mandat",
        "Type": "COMBOBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "WV Grund",
        "Database": "",
        "DbText": "LblWVGrund",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "FristNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Nein/Undefiniert",
        "DbText": "Zuo_frinr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "WV Ablauf",
        "Database": "",
        "DbText": "LblWVAblauf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "FristDatum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 10,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Termin Art",
        "Database": "",
        "DbText": "LblTermin_Art",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Termin_Art",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Datum",
        "Database": "",
        "DbText": "LblTermin_Datum",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Termin_Datum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": "Datum",
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Uhrzeit",
        "Database": "",
        "DbText": "LblTermin_Uhrzeit",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Termin_Uhrzeit",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": "Datum",
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Frist Grund",
        "Database": "",
        "DbText": "LblFrist_Grund",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Frist_Grund",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Frist Ablauf",
        "Database": "",
        "DbText": "LblFrist_Ablauf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Frist_Ablauf",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 10,
        "Alignment": null,
        "WebInfo": "Datum",
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Sachstand",
        "Database": "",
        "DbText": "LblSachbestand",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": null,
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Nein/Undefiniert",
        "DbText": "Zuo_Sachstand",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Gegenstandswert ",
        "Database": "",
        "DbText": "LWert1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": null,
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 12,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Bereits abgerechnet:",
        "Database": "",
        "DbText": "LblBereitsabgerechnet",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Fallwert",
        "Database": "",
        "DbText": "LblBereitsabgerechnet_Fallwert",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 3,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Bereitsabgerechnet_Fallwert",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 10,
        "Alignment": null,
        "WebInfo": "Datum",
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "OPOS",
        "Database": "",
        "DbText": "LblBereitsabgerechnet_OPOS",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 3,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Bereitsabgerechnet_OPOS",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 10,
        "Alignment": null,
        "WebInfo": "Datum",
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Vorsteuerabzugsberechtigt?",
        "Database": "XML/Default",
        "DbText": "Vorsteuerabzugsberechtigt",
        "Type": "CHECKBOX",
        "Size": 3,
        "Skip": 3,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Mahnkennung",
        "Database": "",
        "DbText": "LblMahnnr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Akte",
        "DbText": "MahnNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Nein/Undefiniert",
        "DbText": "Zuo_Mahnnr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Sachverhalt": [
    {
      "Name": "Allgemein",
      "Elements": [
        [
          {
            "Label": "Sachverhalt",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sachverhalt",
            "Database": "",
            "DbText": "LblSachverhalt",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Notiz_Sachverhalt",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Notiz",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mandantenbegehren",
            "Database": "",
            "DbText": "LblMandantenbegehren",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Notiz_Mandantenbegehren",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Notiz",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Notizen",
            "Database": "",
            "DbText": "LblNotizen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Notizen",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Notiz",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Tatvorwurf und Mandantenziel",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mandatssituation",
            "Database": "",
            "DbText": "LblMandatssituation",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Mandant/In hat einen Bußgeldbescheid erhalten",
              "Mandant/In hat einen Anhörungsbogen erhalten",
              "Mandant/In hat einen Zeugenbefragungsbogen erhalten",
              "Mandant/In hat einen Verkehrsverstoß begangen, bisher aber keine behördliche Handlung eingeleitet"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Mandatssituation",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Tatvorwurf",
            "Database": "",
            "DbText": "LblTatvorwurf1",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Abstandverstöße",
              "Alkoholverstöße",
              "Allgemein",
              "Auslandsverstöße",
              "Drogenverstöße",
              "Geschwindigkeitsverstöße",
              "Handyverstöße",
              "Park- und Halteverstöße",
              "Rotlichtverstöße",
              "Überholverstöße"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Tatvorwurf",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Mandantenziel",
            "Database": "",
            "DbText": "LblMandantenziel",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Keine Geldbuße bezahlen",
              "Kein Fahrverbot",
              "Höhere Geldbuße, aber kein Fahrverbot",
              "Fahrverbot zu einem anderen Zeitpunkt"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Mandantenziel",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Bitte geben Sie die Sanktionen an",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Geldbuße",
            "Database": "",
            "DbText": "LblGeldbuße",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Geldbusse",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fahrverbot Monat",
            "Database": "",
            "DbText": "LblFahrverbotmonat",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Fahrverbotmonat",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Eintrag FAER",
            "Database": "",
            "DbText": "LblEintrag_FAER",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Eintrag_FAER",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "Punkte",
            "Database": "",
            "DbText": "LblPunkte",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Weiter Informationen zum Mandanten",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fahrerlaubnis",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Führerscheinklasse",
            "Database": "",
            "DbText": "LblFuehrerscheinklasse",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Fuehrerschein_Klassen",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ausstellende Behörde",
            "Database": "",
            "DbText": "LblAusstellende_Behörde",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Fuehrerschein_AusstellendeBehoerde",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ausgestellt am:",
            "Database": "",
            "DbText": "LblAusgestellt_am",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Fuehrerschein_Ausstellungsdatum",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Einträge nach FAER",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Punkte",
            "Database": "",
            "DbText": "LblEintragFAERPunkte",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "EintragFAERPunkte",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Persönliche Daten Mandant",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Einkommen",
            "Database": "",
            "DbText": "LblEinkommen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Einkommen",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Geld",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Arbeitgeber",
            "Database": "",
            "DbText": "LblArbeitgeber",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "",
            "DbText": "Arbeitgeber",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Familienstand",
            "Database": "",
            "DbText": "LblFamilienstand",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Familienstand",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Anzahl Kinder",
            "Database": "",
            "DbText": "LblAnzahlKinder",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "AnzahlKinder",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Nummer",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Verkehrsrecht",
      "Elements": [
        [
          {
            "Label": "Verkehrsrecht allgemein",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wurde der Verkehrsverstoß vom Mandanten begangen?",
            "Database": "",
            "DbText": "LblVerkehrsrecht_Verkehrsverstoss_vom_Mandanten",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Verkehrsrecht_Verkehrsverstoss_vom_Mandanten",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Können Angaben zu dem Fahrer gemacht werden?",
            "Database": "",
            "DbText": "LblVerkehrverstoss_Fahrerangabe",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Verkehrverstoss_Fahrerangabe",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Name",
            "Database": "",
            "DbText": "LblVerkehrverstoss_Fahrerangabe_Name",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Verkehrverstoss_Fahrerangabe_Name",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Vorname",
            "Database": "",
            "DbText": "LblVerkehrverstoss_Fahrerangabe_Vorname",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Verkehrverstoss_Fahrerangabe_Vorname",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Straße und Hausnummer",
            "Database": "",
            "DbText": "LblVerkehrverstoss_Fahrerangabe_Strasse",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Verkehrverstoss_Fahrerangabe_Strasse",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "PLZ",
            "Database": "",
            "DbText": "LblVerkehrverstoss_Fahrerangabe_PLZ",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Verkehrverstoss_Fahrerangabe_PLZ",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ort",
            "Database": "",
            "DbText": "LblVerkehrverstoss_Fahrerangabe_Ort",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Verkehrverstoss_Fahrerangabe_Ort",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Handelt es sich um einen nahen Familienangehörigen?",
            "Database": "",
            "DbText": "LblVerkehrverstoss_Fahrerangabe_Familienangehörigen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Verkehrverstoss_Fahrerangabe_Familienangehörigen",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Kann nachgewiesen werden, dass der Mandant zur Tatzeit nicht der Fahrer des Fahrzeugs sein kann?",
            "Database": "",
            "DbText": "LblVerkehrverstoss_Fahrerangabe_Tatzeit",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Verkehrverstoss_Fahrerangabe_Tatzeit",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zeugenbeweis",
            "Type": "HEADLINE",
            "Size": 12,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Name",
            "Database": "",
            "DbText": "LblVerkehrverstoss_Fahrerangabe_Tatzeit_Name",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Verkehrverstoss_Fahrerangabe_Tatzeit_Name",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Vorname",
            "Database": "",
            "DbText": "LblVerkehrverstoss_Fahrerangabe_Tatzeit_Vorname",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Verkehrverstoss_Fahrerangabe_Tatzeit_Vorname",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Straße und Hausnummer",
            "Database": "",
            "DbText": "LblVerkehrverstoss_Fahrerangabe_Tatzeit_Strasse",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Verkehrverstoss_Fahrerangabe_Tatzeit_Strasse",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "PLZ",
            "Database": "",
            "DbText": "LblVerkehrverstoss_Fahrerangabe_Tatzeit_PLZ",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Verkehrverstoss_Fahrerangabe_Tatzeit_PLZ",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ort",
            "Database": "",
            "DbText": "LblVerkehrverstoss_Fahrerangabe_Tatzeit_Ort",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Verkehrverstoss_Fahrerangabe_Tatzeit_Ort",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Urkundenbeweis",
            "Database": "XML/Default",
            "DbText": "Verkehrverstoss_Fahrerangabe_Tatzeit_Urkundenbeweis",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Verkehrsverstoß",
            "Database": "",
            "DbText": "LblDatum_Verkehrsverstoss",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Datum_Verkehrverstoss",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Zeugenbefragungsbogen",
            "Database": "",
            "DbText": "LblDatum_Zeugenbefragungsbogen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Datum_Zeugenbefragungsbogen",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Zustellung Zeugenbefragungsbogen",
            "Database": "",
            "DbText": "LblDatum_Zustellung_Zeugenbefragungsbogen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Datum_Zustellung_Zeugenbefragungsbogen",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Anhörungsbogen",
            "Database": "",
            "DbText": "LblDatum_Anhörungsbogen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Datum_Anhoerungsbogen",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Bußgeldbescheid",
            "Database": "",
            "DbText": "LblDatum_Bussgeldbescheid",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Datum_Bussgeldbescheid",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Zustellung Bußgeldbescheid",
            "Database": "",
            "DbText": "LblDatum_Zustellung_Bussgeldbescheid",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Datum_Zustellung_Bussgeldbescheid",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wo wurde der Verkehrsverstoß begangen?",
            "Database": "",
            "DbText": "LblVerkehrverstoss_begangen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Innerorts",
              "Außerorts"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Verkehrsverstoss_begangen",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "bei innerorts:",
            "Database": "",
            "DbText": "Lblbei_innerorts",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Straße",
            "Database": "",
            "DbText": "LblVerkehrverstoss_begangen_Innerorts_Strasse",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Verkehrverstoss_begangen_Innerorts_Strasse",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "PLZ",
            "Database": "",
            "DbText": "LblVerkehrverstoss_begangen_Innerorts_PLZ",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Verkehrverstoss_begangen_Innerorts_PLZ",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ort",
            "Database": "",
            "DbText": "LblVerkehrverstoss_begangen_Innerorts_Ort",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Verkehrverstoss_begangen_Innerorts_Ort",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "bei außerorts:",
            "Database": "",
            "DbText": "Lblbei_ausserorts",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Straße",
            "Database": "",
            "DbText": "LblVerkehrverstoss_begangen_Ausserorts_Strasse",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Verkehrverstoss_begangen_Ausserorts_Strasse",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "PLZ",
            "Database": "",
            "DbText": "LblVerkehrverstoss_begangen_Ausserorts_PLZ",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Verkehrverstoss_begangen_Ausserorts_PLZ",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ort",
            "Database": "",
            "DbText": "LblVerkehrverstoss_begangen_Ausserorts_Ort",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Verkehrverstoss_begangen_Ausserorts_Ort",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wurde Einspruch gegen den Bußgeldbescheid eingelegt?",
            "Database": "",
            "DbText": "LblEinspruch_Bussgeldbescheid",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Einspruch_Bussgeldbescheid",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Einspruch",
            "Database": "",
            "DbText": "LblDatum_Einspruch_Bussgeldbescheid",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Datum_Einspruch_Bussgeldbescheid",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wurde bereits ein Gerichtstermin festgesetzt?",
            "Database": "",
            "DbText": "LblEinspruch_Bussgeldbescheid_Gerichtstermin",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Einspruch_Bussgeldbescheid_Gerichtstermin",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Datum Gerichtstermin",
            "Database": "",
            "DbText": "LblDatum_Einspruch_Bussgeldbescheid_Gerichtstermin",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Datum_Einspruch_Bussgeldbescheid_Gerichtstermin",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Datum",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wurden andere Verkehrsteilnehmer und/oder Fußgänger gefährdet?",
            "Database": "",
            "DbText": "LblAndere_Verkehrsteilnehmer_gefaehrdet",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Andere_Verkehrsteilnehmer_gefaehrdet",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Bitte geben Sie die Gefahrensituation an",
            "Database": "",
            "DbText": "LblAndere_Verkehrsteilnehmer_gefaehrdet_Gefahrensituation",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "riskantes Überholmanöver",
              "Gefährung von Fußgängern",
              "dichtes Auffahren",
              "Gefährung des Gegenverkehrs"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Andere_Verkehrsteilnehmer_gefaehrdet_Gefahrensituation",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sonstige Gefahrensituationen:",
            "Database": "",
            "DbText": "LblNotiz_Sonstige_Gefahrensituationen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Notiz_Sonstige_Gefahrensituationen",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Notiz",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ist Fremdschaden entstanden?",
            "Database": "",
            "DbText": "LblFremdschaden_entstanden",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "Ja",
            "Database": "XML/Default",
            "DbText": "Fremdschaden_entstanden",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sachschaden:",
            "Database": "",
            "DbText": "LblNotiz_Sachschaden",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Notiz_Sachschaden",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Notiz",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Personenschaden:",
            "Database": "",
            "DbText": "LblPersonenschaden",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Name",
            "Database": "",
            "DbText": "LblPersonenschaden_Name",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Personenschaden_Name",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Vorname",
            "Database": "",
            "DbText": "LblPersonenschaden_Vorname",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Personenschaden_Vorname",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Straße und Hausnummer",
            "Database": "",
            "DbText": "LblPersonenschaden_Strasse",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Personenschaden_Strasse",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "PLZ",
            "Database": "",
            "DbText": "LblPersonenschaden_PLZ",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Personenschaden_PLZ",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ort",
            "Database": "",
            "DbText": "LblPersonenschaden_Ort",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Personenschaden_Ort",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Können wichtige Gründe für die Tat vorgetragen werden?",
            "Database": "",
            "DbText": "LblTat_vorgetragen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Tat_vorgetragen",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Bitte geben Sie die wichtigen Gründe an",
            "Database": "",
            "DbText": "LblTat_vorgetragen_Gruende",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "einer fremden Person erste Hilfeleisten (§ 16 OWiG)",
              "Angehöriger einer Freiwilligen Feuerwehr (Einsatz)",
              "Andere wichtige Gründe"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Tat_vorgetragen_Gruende",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "bei andere wichtige Gründe:",
            "Database": "",
            "DbText": "Lblbei_andere_wichtige_Gruende",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Bitte geben Sie die anderen wichtigen Gründe an:",
            "Database": "",
            "DbText": "LblBitte_geben_Sie_die_anderen_wichtigen_Gruende_an",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Notiz_Tat_vorgetragen_andere_Gruende",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Notiz",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ist der Mandant auf den Führerschein angewiesen?",
            "Database": "",
            "DbText": "LblMandant_FuehrerscheinAngewiesen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Mandant_FuehrerscheinAngewiesen",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Bitte geben Sie die Gründe an",
            "Database": "",
            "DbText": "LblMandant_Fuehrerschein_Gruende",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Berufliche Notwendigkeit und Existenzgrundlage",
              "Berufsfahrer/Kurier",
              "Außendienstmitarbeiter",
              "Sonstige Gründe"
            ],
            "Label": "Berufliche Notwendigkeit und Existenzgrundlage",
            "Database": "XML/Default",
            "DbText": "Mandant_Fuehrerschein_Gruende",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Bitte geben Sie die sonstigen Gründe an:",
            "Database": "",
            "DbText": "LblBitte_geben_Sie_die_sonstigen_Gruende_an",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Notiz_Mandant_Fuehrerschein_Gruende",
            "Type": "TEXTAREA",
            "Size": 9,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Notiz",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    },
    {
      "Name": "Geschwindigkeitsverstöße",
      "Elements": [
        [
          {
            "Label": "Feststellung des Geschwindigkeitsverstoßes erfolgte durch:",
            "Database": "",
            "DbText": "LblFeststellung_des_Geschwindigkeitsverstosses_erfolgte_durch",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Messgerät",
            "Database": "",
            "DbText": "LblMessgeraet",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Eso ES 3.0",
              "Laveg VL 101",
              "Leivtec XV3",
              "Multanova 6F",
              "Nachfahren",
              "PoliScan Speed",
              "ProViDa 2000 (Modular)",
              "Riegl LR90-235/P",
              "Riegl FG21-P",
              "Schätzung",
              "Traffipax SpeedoPhot",
              "Traffipax TraffiPhot S",
              "Traffipax TraffiStar S 330",
              "Traffipax TraffiStar S 350",
              "ViDistA",
              "VIDIT VKS",
              "Sonstige"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Messgeraet",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Bitte geben Sie das Messgerät an:",
            "Database": "",
            "DbText": "LblBitte_geben_Sie_das_Messgeraet_an",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Notiz_Messgeraet",
            "Type": "TEXTAREA",
            "Size": 6,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": "Notiz",
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Nachfahren",
            "Database": "XML/Default",
            "DbText": "Geschwindigkeitverstoess_Nachfahren",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Schätzung",
            "Database": "XML/Default",
            "DbText": "Geschwindigkeitverstoess_Schaetzung",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Fahrtenschreiber",
            "Database": "XML/Default",
            "DbText": "Geschwindigkeitverstoess_Fahrtenschreiber",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Geständnis",
            "Database": "XML/Default",
            "DbText": "Geschwindigkeitverstoess_Gestaendnis",
            "Type": "CHECKBOX",
            "Size": 3,
            "Skip": 3,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Verkehrsverstoß war",
            "Database": "",
            "DbText": "LblGeschwindigkeitverstoess_wo",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Innerorts",
              "Außerorts"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Geschwindigkeitverstoess_wo",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "bei innerorts:",
            "Database": "",
            "DbText": "LblGeschwindigkeitverstoess_innerorts",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Straße",
            "Database": "",
            "DbText": "LblGeschwindigkeitverstoess_innerorts_Strasse",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Strasse_I_VV",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "PLZ",
            "Database": "",
            "DbText": "LblGeschwindigkeitverstoess_innerorts_PLZ",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "PLZ_I_VV",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ort",
            "Database": "",
            "DbText": "LblGeschwindigkeitverstoess_innerorts_Ort",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Ort_I_VV",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "bei außerorts:",
            "Database": "",
            "DbText": "LblGeschwindigkeitverstoess_ausserorts",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Straße",
            "Database": "",
            "DbText": "LblGeschwindigkeitverstoess_ausserorts_Strasse",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Strasse_A_VV",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "PLZ",
            "Database": "",
            "DbText": "LblGeschwindigkeitverstoess_ausserorts_PLZ",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "PLZ_A_VV",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ort",
            "Database": "",
            "DbText": "LblGeschwindigkeitverstoess_ausserorts_Ort",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Ort_A_VV",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Zulässige Höchstgeschwindigkeit",
            "Database": "",
            "DbText": "LblZulaessige_Hoechstgeschwindigkeit",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Zulaessige_Hoechstgeschwindigkeit",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "km/h",
            "Database": "",
            "DbText": "Lblkm_h",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Geschätzte Geschwindigkeit",
            "Database": "",
            "DbText": "LblGeschaetzte_Geschwindigkeit",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Geschaetzte_Geschwindigkeit",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "km/h",
            "Database": "",
            "DbText": "Lbl2",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Festgestellte Geschwindigkeit",
            "Database": "",
            "DbText": "LblFestgestellte_Geschwindigkeit",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "",
            "DbText": "Festgestellte_Geschwindigkeit",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "km/h",
            "Database": "",
            "DbText": "Lbl4",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Überschrittene Geschwindigkeit",
            "Database": "",
            "DbText": "LblUeberschrittene_Geschwindigkeit",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Ueberschrittene_Geschwindigkeit",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "km/h",
            "Database": "",
            "DbText": "Lbl5",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Toleranzabzug",
            "Database": "",
            "DbText": "LblToleranzabzug",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Toleranzabzug",
            "Type": "TEXTBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Label": "km/h",
            "Database": "",
            "DbText": "Lbl6",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wurde Einsicht in die Akte beantragt?",
            "Database": "",
            "DbText": "LblWurde_Einsicht_in_die_Akte_beantragt",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Wurde_Einsicht_in_die_Akte_beantragt",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Liegt die Akte bereits vor?",
            "Database": "",
            "DbText": "LblLiegt_die_Akte_bereits_vor",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Liegt_die_Akte_bereits_vor",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Liegt der Eichschein vor?",
            "Database": "",
            "DbText": "LblLiegt_der_Eichschein_vor",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Liegt_der_Eichschein_vor",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ist die Eichfrist des Messgeräts abgelaufen?",
            "Database": "",
            "DbText": "LblISt_die_Eichfrist_des_Messgeräts_abgelaufen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "Ja",
            "Database": "XML/Default",
            "DbText": "ISt_die_Eichfrist_des_Messgeraets_abgelaufen",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Liegt der Schulungsnachweis des Messbeamten zu dem verwendeten Messgerät vor?",
            "Database": "",
            "DbText": "LblSchulungsnachweis_Messbeamte",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Schulungsnachweis_Messbeamte",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Liegt ein Messfoto vor?",
            "Database": "",
            "DbText": "LblMessfoto",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Messfoto",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Befinden sich weitere Fahrzeuge in dem Messbereich?",
            "Database": "",
            "DbText": "LblMessfoto_weitereFahrzeug_Messbereich",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "Ja",
            "Database": "XML/Default",
            "DbText": "Messfoto_weitereFahrzeug_Messbereich",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wurde Einsicht in die Lebensakte des verwendeten Messgeräts beantragt?",
            "Database": "",
            "DbText": "LblEinsicht_Lebensakte_Messgeraet",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "Ja",
            "Database": "XML/Default",
            "DbText": "Einsicht_Lebensakte_Messgeraet",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Sind nach dem Termin der Eichung Reparaturen vorgenommen worden?",
            "Database": "",
            "DbText": "LblTermin_Eichung_Reparaturen",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Termin_Eichung_Reparaturen",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "bei ja:",
            "Database": "",
            "DbText": "LblTermin_Eichung_Reparaturen_ja",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "bei ja:",
            "Database": "",
            "DbText": "LblTermin_Eichung_Reparaturen_ja2",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wurde der Messabstand aufgeführt?",
            "Database": "",
            "DbText": "LblMessabstand_aufgefuehrt",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Messabstand_aufgefuehrt",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wurde die Messtrecke dokumentiert?",
            "Database": "",
            "DbText": "LblMesstrecke_dokumentiert",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Messtrecke_dokumentiert",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wurde der Tatort angegeben?",
            "Database": "",
            "DbText": "LblTatort_angegeben",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Tatort_angegeben",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Ist die zulässige Höchstgeschwindigkeit vom Tatort mitangegeben?",
            "Database": "",
            "DbText": "LblHoechstgeschwindigkeit_Tatort_zulaessig",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Hoechstgeschwindigkeit_Tatort_zulaessig",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Haben Sie die nachträglich präsentierte Geschwindigkeit bestätigt?",
            "Database": "",
            "DbText": "LblNachtraeglich_Geschwindigkeit",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "",
            "Database": "XML/Default",
            "DbText": "Nachtraeglich_Geschwindigkeit",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Haben Sie eine bestimmte Mindestgeschwindigkeit selbst eingeräumt?",
            "Database": "",
            "DbText": "Lblbestimmte_Mindestgeschwindigkeit",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "Nein",
            "Database": "XML/Default",
            "DbText": "bestimmte_Mindestgeschwindigkeit",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ],
        [
          {
            "Label": "Wurde ein Sachverständiger beauftragt?",
            "Database": "",
            "DbText": "LblSachverstaendiger_beauftragt",
            "Type": "LABEL",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          },
          {
            "Values": [
              "",
              "Ja",
              "Nein"
            ],
            "Label": "Ja",
            "Database": "XML/Default",
            "DbText": "Sachverstaendiger_beauftragt",
            "Type": "COMBOBOX",
            "Size": 3,
            "Skip": 0,
            "ReadOnly": false,
            "MaxLength": 0,
            "Alignment": null,
            "WebInfo": null,
            "SelectionShow": false,
            "SelectionFrom": null,
            "SelectionProperty": null
          }
        ]
      ]
    }
  ],
  "Mandant": [
    [
      {
        "Label": "Suchbegriff*",
        "Database": "",
        "DbText": "LblSuchbegriff",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "subeg",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Keyword"
      }
    ],
    [
      {
        "Label": "Vorname",
        "Database": "",
        "DbText": "LblVorname",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "vname",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "FirstName"
      }
    ],
    [
      {
        "Label": "Name*",
        "Database": "",
        "DbText": "LblName1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "name1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Name1"
      }
    ],
    [
      {
        "Label": "Straße*",
        "Database": "",
        "DbText": "LblStrasse",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "str",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Address"
      }
    ],
    [
      {
        "Label": "Postleitzahl* / Ort*",
        "Database": "",
        "DbText": "LblPLZ_Ort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "plz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "ZipCode"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ort",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "CityName"
      }
    ],
    [
      {
        "Label": "Geburtsdatum",
        "Database": "",
        "DbText": "LblGeburtsdatum",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "gebdat",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "BirthDate"
      }
    ],
    [
      {
        "Label": "Geburtsort",
        "Database": "",
        "DbText": "LblGeburtsort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Mandant",
        "DbText": "Geburtsort",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Staatsangehörigkeit",
        "Database": "",
        "DbText": "LblStaatsangehoerigkeit",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Familiensachen",
        "DbText": "staatmdt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Telefonnummer",
        "Database": "",
        "DbText": "LblTelefonnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "tel1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Phone1"
      }
    ],
    [
      {
        "Label": "Faxnummer",
        "Database": "",
        "DbText": "LblFaxnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "fax",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "Fax"
      }
    ],
    [
      {
        "Label": "Handynummer",
        "Database": "",
        "DbText": "LblHandynummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "HandyNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "MobilePhone"
      }
    ],
    [
      {
        "Label": "E-Mail",
        "Database": "",
        "DbText": "Lbllongmail",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "longmail",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Mandant",
        "SelectionProperty": "EmailAddress"
      }
    ],
    [
      {
        "Label": "Rechtsform*",
        "Database": "",
        "DbText": "LblLrechtsform",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "rechtsform",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "RechtsformType"
      }
    ],
    [
      {
        "Label": "Anrede Briefkopf",
        "Database": "",
        "DbText": "LblAnredeBriefkopf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "an1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Anrede"
      }
    ],
    [
      {
        "Label": "Anrede direkt",
        "Database": "",
        "DbText": "LblAnrededirekt",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "an2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Briefanrede"
      }
    ],
    [
      {
        "Label": "Abrechnungsdaten",
        "Type": "HEADLINE",
        "Size": 12,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Zahlungsart",
        "Database": "",
        "DbText": "LblZahlungsart",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Values": [
          "",
          "Selbstzahler",
          "Rechtsschutzversicherung",
          "Prozesskostenhilfe",
          "Beratungshilfe"
        ],
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Zahlungsart",
        "Type": "COMBOBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Versicherung",
        "Database": "",
        "DbText": "LblVersicherung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/DefaultZuord",
        "DbText": "RsVers",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Versicherungsnummer",
        "Database": "",
        "DbText": "LblVersicherungsnr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/DefaultZuord",
        "DbText": "RsverNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Selbstbeteiligung",
        "Database": "",
        "DbText": "LblSelbstbeteiligung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/DefaultZuord",
        "DbText": "RsSelbst",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Schadensnummer",
        "Database": "",
        "DbText": "LblSchadensnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/DefaultZuord",
        "DbText": "SchadNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Deckungszusage erhalten",
        "Database": "",
        "DbText": "LblBereits_Deckungszusage",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Values": {
          "Ja ": "Ja_Deckungszusage_erhalten",
          "Nein ": "Nein_Deckungszusage_erhalten"
        },
        "Default": "True",
        "Label": "Deckungszusage_erhalten",
        "Database": "XML/Default",
        "DbText": "",
        "Type": "RADIOGROUP",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Belege / Dokumente:",
        "Type": "HEADLINE",
        "Size": 12,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Schreiben Übernahme VKH-Kosten",
        "Database": "XML/Default",
        "DbText": "ChkDok_SchreibenZuProzesskostenuebernahme",
        "Type": "CHECKBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Deckungszusage FamR",
        "Database": "XML/Akte",
        "DbText": "ChkDok_Deckungszusage_FamR",
        "Type": "CHECKBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Berechtigungsschein FamR",
        "Database": "XML/Akte",
        "DbText": "ChkDok_Berechtigungsschein_FamR",
        "Type": "CHECKBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Gegner": [
    [
      {
        "Label": "Suchbegriff*",
        "Database": "",
        "DbText": "LSuchbegriff",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Keyword",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Keyword"
      }
    ],
    [
      {
        "Label": "Vorname",
        "Database": "",
        "DbText": "LVorname",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "FirstName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Name*",
        "Database": "",
        "DbText": "LName",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Name1"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lname2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Name2"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lblname3",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name3",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Name3"
      }
    ],
    [
      {
        "Label": "Straße*",
        "Database": "",
        "DbText": "LStrasse",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Address",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Address"
      }
    ],
    [
      {
        "Label": "Postleitzahl* / Ort*",
        "Database": "",
        "DbText": "LPLZ_Ort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ZipCode",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "ZipCode"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "CityName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "CityName"
      }
    ],
    [
      {
        "Label": "Telefonnummer 1",
        "Database": "",
        "DbText": "LTelefonnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Phone1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Phone1"
      }
    ],
    [
      {
        "Label": "Telefonnummer 2",
        "Database": "",
        "DbText": "Lbltel2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "tel2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Phone2"
      }
    ],
    [
      {
        "Label": "Faxnummer",
        "Database": "",
        "DbText": "LFaxnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Fax",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "Fax"
      }
    ],
    [
      {
        "Label": "Handynummer",
        "Database": "",
        "DbText": "LHandynummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "HandyNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "MobilePhone"
      }
    ],
    [
      {
        "Label": "E-Mail",
        "Database": "",
        "DbText": "Llongmail",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "EmailAddress",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": "Email",
        "SelectionShow": false,
        "SelectionFrom": "Gegner",
        "SelectionProperty": "EmailAddress"
      }
    ],
    [
      {
        "Label": "Homepage",
        "Database": "",
        "DbText": "LblHomepage",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Homepage",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": "Browser",
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Geburtsdatum",
        "Database": "",
        "DbText": "Lblgebgeg",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "BirthDate",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": "Datum",
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Beruf",
        "Database": "",
        "DbText": "LblBeruf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Beruf",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Rechtsform*",
        "Database": "",
        "DbText": "LRechtsform",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Rechtsform",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Anrede Briefkopf",
        "Database": "",
        "DbText": "LAnredeBriefkopf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeBriefkopf",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Anrede"
      }
    ],
    [
      {
        "Label": "Anrede direkt",
        "Database": "",
        "DbText": "LAnrededirekt",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeDirekt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Briefanrede"
      }
    ],
    [
      {
        "Label": "Gegn. Anwalt",
        "Database": "",
        "DbText": "Lgeganwalt",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/DefaultZuord",
        "DbText": "geganwalt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "AZ gegn. Anwalt",
        "Database": "",
        "DbText": "Lazgeganw",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/DefaultZuord",
        "DbText": "azgeganw",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Bankverbindung",
        "Database": "",
        "DbText": "LBankverbindung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "kurzbez",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 57,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "bank",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      }
    ],
    [
      {
        "Label": "BLZ/BIC",
        "Database": "",
        "DbText": "LBLZ_BIC",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "blz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BLZ"
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "BIC",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 11,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Konto / IBAN",
        "Database": "",
        "DbText": "LKonto_IBAN",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ktonr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "IBAN",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 34,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "SEPA Lastschr.",
        "Database": "",
        "DbText": "LSEPALastschr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "MandatSEPA",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 35,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Datum SEPA",
        "Database": "",
        "DbText": "LDatumSEPA",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "MandatSEPAdatum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 10,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Behorde": [
    [
      {
        "Label": "Suchbegriff*",
        "Database": "",
        "DbText": "LSuchbegriff",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "subeg",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Keyword"
      }
    ],
    [
      {
        "Label": "Name*",
        "Database": "",
        "DbText": "LName1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "name1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Name1"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lname2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "name2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Name2"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lname3",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "name3",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Name3"
      }
    ],
    [
      {
        "Label": "Straße",
        "Database": "",
        "DbText": "lstr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "str",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Address"
      }
    ],
    [
      {
        "Label": "Länderkennzeichen",
        "Database": "",
        "DbText": "llkz",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "lkz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 3,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "CountryID"
      }
    ],
    [
      {
        "Label": "PLZ",
        "Database": "",
        "DbText": "lplz",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "plz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "ZipCode"
      }
    ],
    [
      {
        "Label": "Wohnort",
        "Database": "",
        "DbText": "lort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ort",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "CityName"
      }
    ],
    [
      {
        "Label": "Telefon1",
        "Database": "",
        "DbText": "ltel1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "tel1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Phone1"
      }
    ],
    [
      {
        "Label": "Telefon2",
        "Database": "",
        "DbText": "ltel2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "tel2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Phone2"
      }
    ],
    [
      {
        "Label": "Zentralrufnummer",
        "Database": "",
        "DbText": "lzentralrufnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "zentralrufnummer",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "CentralPhone"
      }
    ],
    [
      {
        "Label": "Fax",
        "Database": "",
        "DbText": "lfax",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "fax",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "Fax"
      }
    ],
    [
      {
        "Label": "E-Mail",
        "Database": "",
        "DbText": "llongmail",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "longmail",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "EmailAddress"
      }
    ],
    [
      {
        "Label": "Telekommunikation",
        "Database": "",
        "DbText": "ltelkom",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "telkom",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Homepage",
        "Database": "",
        "DbText": "lhomepage",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "homepage",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Behörde",
        "SelectionProperty": "URL"
      }
    ],
    [
      {
        "Label": "Kommunikationsdaten",
        "Database": "",
        "DbText": "LblKommunikationsdaten",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Headers": [
          "Medium",
          "Nummer"
        ],
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Kommunikationsdaten",
        "Type": "TABLE",
        "Size": 8,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Anrede Briefkopf",
        "Database": "",
        "DbText": "lan1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "an1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Anrede"
      }
    ],
    [
      {
        "Label": "Anrede direkt",
        "Database": "",
        "DbText": "lan2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "an2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Briefanrede"
      }
    ],
    [
      {
        "Label": "Bankverbindung",
        "Database": "",
        "DbText": "LBankverbindung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "kurzbez",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 57,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "bank",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      }
    ],
    [
      {
        "Label": "BLZ/BIC",
        "Database": "",
        "DbText": "LBLZ_BIC",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "blz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BLZ"
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "bic",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 11,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BIC"
      }
    ],
    [
      {
        "Label": "Konto / IBAN",
        "Database": "",
        "DbText": "LKonto_IBAN",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ktonr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "iban",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 34,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Gericht": [
    [
      {
        "Label": "Suchbegriff*",
        "Database": "",
        "DbText": "LSuchbegriff",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Keyword",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Keyword"
      }
    ],
    [
      {
        "Label": "Name*",
        "Database": "",
        "DbText": "LName1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Name1"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lname2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Name2"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lname3",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Name3",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Name3"
      }
    ],
    [
      {
        "Label": "Straße",
        "Database": "",
        "DbText": "LStrasse",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Address",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Address"
      }
    ],
    [
      {
        "Label": "Postleitzahl",
        "Database": "",
        "DbText": "LPLZ_Ort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ZipCode",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "ZipCode"
      }
    ],
    [
      {
        "Label": "Gerichtsort",
        "Database": "",
        "DbText": "Lort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "CityName",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "CityName"
      }
    ],
    [
      {
        "Label": "Gerichtskennzahl",
        "Database": "",
        "DbText": "LRechtsform",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "GerichtsKennzahl",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Telefonnummer 1",
        "Database": "",
        "DbText": "LTelefonnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Phone1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Phone1"
      }
    ],
    [
      {
        "Label": "Telefonnummer 2",
        "Database": "",
        "DbText": "Lbltel2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "tel2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Phone2"
      }
    ],
    [
      {
        "Label": "Faxnummer",
        "Database": "",
        "DbText": "LFaxnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Fax",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "Fax"
      }
    ],
    [
      {
        "Label": "Handynummer",
        "Database": "",
        "DbText": "LHandynummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "HandyNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "MobilePhone"
      }
    ],
    [
      {
        "Label": "E-Mail",
        "Database": "",
        "DbText": "Llongmail",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "EmailAddress",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Gericht",
        "SelectionProperty": "EmailAddress"
      }
    ],
    [
      {
        "Label": "Homepage",
        "Database": "",
        "DbText": "LblHomepage",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Homepage",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Anrede Briefkopf",
        "Database": "",
        "DbText": "lan1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeBriefkopf",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Anrede"
      }
    ],
    [
      {
        "Label": "Anrede direkt",
        "Database": "",
        "DbText": "lan2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "AnredeDirekt",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Briefanrede"
      }
    ],
    [
      {
        "Label": "Bankverbindung",
        "Database": "",
        "DbText": "LBankverbindung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "kurzbez",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 57,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "bank",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      }
    ],
    [
      {
        "Label": "BLZ/BIC",
        "Database": "",
        "DbText": "LBLZ_BIC",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "blz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BLZ"
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "BIC",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 11,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Konto / IBAN",
        "Database": "",
        "DbText": "LKonto_IBAN",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ktonr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "IBAN",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 34,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ],
  "Weitere": [
    [
      {
        "Label": "Suchbegriff*",
        "Database": "",
        "DbText": "LSuchbegriff",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "subeg",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Keyword"
      }
    ],
    [
      {
        "Label": "Vorname",
        "Database": "",
        "DbText": "LVorname",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "vname",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Name*",
        "Database": "",
        "DbText": "LName",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "name1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Name1"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "MyLabel1",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "name2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Name2"
      }
    ],
    [
      {
        "Label": ".",
        "Database": "",
        "DbText": "Lblname3",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "name3",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Name3"
      }
    ],
    [
      {
        "Label": "Straße",
        "Database": "",
        "DbText": "LStrasse",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "str",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Address"
      }
    ],
    [
      {
        "Label": "Postleitzahl / Ort",
        "Database": "",
        "DbText": "LPLZ_Ort",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "plz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "ZipCode"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ort",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 50,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "CityName"
      }
    ],
    [
      {
        "Label": "Telefonnummer 1",
        "Database": "",
        "DbText": "LTelefonnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "tel1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Phone1"
      }
    ],
    [
      {
        "Label": "Telefonnummer 2",
        "Database": "",
        "DbText": "Lbltel2",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "tel2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Phone2"
      }
    ],
    [
      {
        "Label": "Faxnummer",
        "Database": "",
        "DbText": "LFaxnummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "fax",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "Fax"
      }
    ],
    [
      {
        "Label": "Handynummer",
        "Database": "",
        "DbText": "LHandynummer",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "HandyNr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "MobilePhone"
      }
    ],
    [
      {
        "Label": "E-Mail",
        "Database": "",
        "DbText": "Llongmail",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "longmail",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Sonstige",
        "SelectionProperty": "EmailAddress"
      }
    ],
    [
      {
        "Label": "Homepage",
        "Database": "",
        "DbText": "LblHomepage",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Homepage",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 100,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Kommunikationsdaten",
        "Database": "",
        "DbText": "LblKommunikationsdaten",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Headers": [
          "Medium",
          "Nummer"
        ],
        "Label": "",
        "Database": "XML/Default",
        "DbText": "Kommunikationsdaten",
        "Type": "TABLE",
        "Size": 9,
        "Skip": 0,
        "ReadOnly": true,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Rechtsform*",
        "Database": "",
        "DbText": "LRechtsform",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "Rechtsform",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "NameID"
      }
    ],
    [
      {
        "Label": "Anrede Briefkopf",
        "Database": "",
        "DbText": "LAnredeBriefkopf",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "an1",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Anrede"
      }
    ],
    [
      {
        "Label": "Anrede direkt",
        "Database": "",
        "DbText": "LAnrededirekt",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "an2",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Rechtsform",
        "SelectionProperty": "Briefanrede"
      }
    ],
    [
      {
        "Label": "Beteiligungsart",
        "Database": "",
        "DbText": "Llbetart",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/DefaultZuord",
        "DbText": "Beteiligungsart",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 3,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "",
        "DbText": "Zuo_Beteiligungsart",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 30,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Bankverbindung",
        "Database": "",
        "DbText": "LBankverbindung",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "kurzbez",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 57,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "bank",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": true,
        "SelectionFrom": "Bank",
        "SelectionProperty": "DisplayName"
      }
    ],
    [
      {
        "Label": "BLZ/BIC",
        "Database": "",
        "DbText": "LBLZ_BIC",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "blz",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 8,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BLZ"
      },
      {
        "Label": "",
        "Database": "Fats/Bank",
        "DbText": "bic",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 11,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": "Bank",
        "SelectionProperty": "BIC"
      }
    ],
    [
      {
        "Label": "Konto / IBAN",
        "Database": "",
        "DbText": "LKonto_IBAN",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "ktonr",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 20,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "iban",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 34,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "SEPA Lastschr.",
        "Database": "",
        "DbText": "LSEPALastschr",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "MandatSEPA",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 35,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ],
    [
      {
        "Label": "Datum SEPA",
        "Database": "",
        "DbText": "LDatumSEPA",
        "Type": "LABEL",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 0,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      },
      {
        "Label": "",
        "Database": "Fats/Default",
        "DbText": "MandatSEPAdatum",
        "Type": "TEXTBOX",
        "Size": 3,
        "Skip": 0,
        "ReadOnly": false,
        "MaxLength": 10,
        "Alignment": null,
        "WebInfo": null,
        "SelectionShow": false,
        "SelectionFrom": null,
        "SelectionProperty": null
      }
    ]
  ]
}`);
