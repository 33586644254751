import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment"
import { RNSAPI } from "../../api";
import { Utils } from "../../utils";
import { Filter } from "../case/caseentryoverview";

class SelectViewViewModel {
    views = ko.observableArray([]);
    currentFilter: ko.Observable<Filter>;

    selectView = async (view) => {
        let f = this.currentFilter();
        this.currentFilter(new Filter(
            f.CaseId,
            f.StartDate,
            f.EndDate,
            f.ShorthandSymbol,
            f.Workflow,
            f.Dictation,
            view.Bezeichnung,
            f.Limit
        ));
    };

    async loadViews() {
        let views = (await RNSAPI.getAllViews()).Payload.Views;
        this.views(views);
    }

    constructor(params: any) {
        if (params && params.currentFilter) {
            this.currentFilter = params.currentFilter;
        }

        this.loadViews();
    }
}

let html = fs.readFileSync(__dirname + '/select.html', 'utf8');

ko.components.register("selectview-view", {
    viewModel: SelectViewViewModel,
    template: html
});