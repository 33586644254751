import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { Postbox } from "../dashboard/postbox";
import { DialogViewModel } from './dialog';

export class ConfirmFTWDialogViewModel {

    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalHandleSelection = ko.observable();
    givenId = "ConfirmFTWDialogExt";

    
    deadlineReason = ko.observable<string>("");
    confirmId = ko.observable<any>();
    confirmReason = ko.observable<string>("");
    confirmType = ko.observable<string>("");
    errorMessage = ko.observable<string>("");
    checkComplete = ko.observable<boolean>(false);
    isDeleteConfirm = ko.observable<boolean>(false);


    async confirm() {
        let confirmSuccess = false;
        this.checkComplete(true);
        switch(this.confirmType()) {
            case "DEADLINE":
                await RNSAPI.completeDeadline(ko.toJS(this.confirmId())).then((response) => {
                    confirmSuccess = true;
                }).catch((error) => {
                    confirmSuccess = false;
                });
            break;
            case "APPOINTMENT":
                if(this.isDeleteConfirm()) {
                    await RNSAPI.deleteAppointment(ko.toJS(this.confirmId())).then((response) => {
                        confirmSuccess = true;
                    }).catch((error) => {
                        confirmSuccess = false;
                    });
                } else {
                    await RNSAPI.completeAppointment(ko.toJS(this.confirmId())).then((response) => {
                        confirmSuccess = true;
                    }).catch((error) => {
                        confirmSuccess = false;
                    });
                }
            break;
            case "RESUBMISSION":
                await RNSAPI.completeResubmission(ko.toJS(this.confirmId())).then((response) => {
                    confirmSuccess = true;
                }).catch((error) => {
                    confirmSuccess = false;
                });
            case "FORECLOSURE_BOOKING":
                await RNSAPI.removeForeclosureBooking(ko.toJS(this.confirmId())).then((response) => {
                    confirmSuccess = true;
                }).catch((error) => {
                    confirmSuccess = false;
                });
                Postbox.publisher().publish(confirmSuccess, "updateForeclosurePositionTable")
            break;
        }

        Postbox.publisher().publish(true, "updateTFW");
        Postbox.publisher().publish(true, "updateDashboard");
        Postbox.publisher().publish(true, "UpdateNew");
        
        if(confirmSuccess) {
            this.clearModal();
        } else {
            this.errorMessage("Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.");
        }
    }

    clearModal(): void {
        $("#ConfirmFTWDialogExt").modal('hide');
        this.confirmId("");
        this.confirmType("");
        this.isDeleteConfirm(false);
        this.confirmId(null);
        this.checkComplete(false);
        this.errorMessage("");
    }

    

    constructor() {
        Postbox.publisher().subscribe((confirmData) => {
            $("#ConfirmFTWDialogExt").modal('show');
            this.confirmType(confirmData.ConfirmType);
            this.isDeleteConfirm(confirmData.IsDeleteItem);
            this.confirmId(confirmData.Id);

            switch(this.confirmType()) {
                case "DEADLINE":
                    this.confirmReason(`Möchten Sie die Frist <span class="text-default">${confirmData.Reason}</span> aus <span class="text-default">${confirmData.CaseId}</span> als erledigt markieren?`);
                break;
                case "APPOINTMENT":
                    if(this.isDeleteConfirm()) {
                        this.confirmReason(`Möchten Sie den Termin <span class="text-default">${confirmData.Reason}</span> löschen?`);
                    } else {
                        this.confirmReason(`Möchten Sie den Termin <span class="text-default">${confirmData.Reason}</span> als erledigt markieren?`);
                    }
                break;
                case "RESUBMISSION":
                    this.confirmReason(`Möchten Sie die Wiedervorlage <span class="text-default">${confirmData.Reason}</span> aus <span class="text-default">${confirmData.CaseId}</span> als erledigt markieren?`);
                break;
                case "FORECLOSURE_BOOKING":
                    this.confirmReason(`Möchten Sie die Buchung <span class="text-default">${confirmData.Reason}</span> löschen?`);
                break;
            }
        }, "ConfirmFTWModalData");
    }
}


let html = fs.readFileSync(__dirname + "/ConfirmFTWDialog.html", "utf8");

ko.components.register("confirm-ftw-dialog-view", {
  viewModel: ConfirmFTWDialogViewModel,
  template: html,
});