import * as fs from "fs";
import * as ko from "knockout";
import { Note } from "./notes";
import { RNSAPI } from "../../api";
import { MainViewModel } from "../../main";
import { Utils } from "../../utils";
import { DocumentEditor } from '@syncfusion/ej2-documenteditor';

class NoteViewModel {
    text = ko.observable("");
    id = "";

    IsEditMode = ko.observable(false);

    async postData() {
        $(".form-group").each(function () { $(this).removeClass("has-error"); });
        if (!Utils.checkErrors(["text"], this, "", [Utils.checkString])) return;

        if (this.IsEditMode()) {
            await RNSAPI.updateNote(new Note(this.id, this.text(), RNSAPI.User().username, null));
            MainViewModel.RoutingTable.showDashboardView();
        } else {
            await RNSAPI.createNote(new Note(this.id, this.text(), RNSAPI.User().username, null));
            MainViewModel.RoutingTable.showDashboardView();
        }
    };

    async loadNote(id: string) {
        let note = await RNSAPI.getNote(id);
        this.text(note.Payload.NoteText);
    }

    constructor(params: any) {
        params = params || {};
        let id = params.id;
        this.IsEditMode(id !== undefined);
        if (this.IsEditMode()) {
            this.id = id;
            this.loadNote(id);
        }        

        let documentEditor: DocumentEditor = new DocumentEditor({
            isReadOnly: false,
            serviceUrl: 'https://ej2services.syncfusion.com/production/web-services/api/documenteditor/'
        });

        documentEditor.enableAllModules();
        documentEditor.appendTo('#DocumentEditor');
    }
}

let html = fs.readFileSync(__dirname + '/note.html', 'utf8');

ko.components.register("note-view", {
    viewModel: NoteViewModel,
    template: html
});
