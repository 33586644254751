import * as fs from "fs";
import * as ko from 'knockout';
import AudioUtils = require('audio-buffer-utils');
import MediaStreamRecorder = require('msr');
import { RNSAPI } from "../../api";
import { Utils } from "../../utils";
import { MainViewModel } from "../../main";
//Safari does not fully support the standard yet
if (!(window as any).AudioContext) (window as any).AudioContext = (window as any).webkitAudioContext;

class MarkedArea {
    start: number
    end: number

    constructor(start: number, end: number) {
        this.start = start;
        this.end = end;
    }
}

export class RecordViewModel {
    //openDictationModal = ko.observable(true)
    totalTime = ko.observable(0);
    IsEdit = ko.observable(false);
    waveCanvas = <HTMLCanvasElement>document.getElementById("wave");
    waveCanvasCtx = this.waveCanvas.getContext("2d");
    markedAreaCanvas = <HTMLCanvasElement>document.getElementById("markedArea");
    markedAreaCanvasCtx = this.markedAreaCanvas.getContext("2d");
    barCanvas = <HTMLCanvasElement>document.getElementById("bar");
    barCanvasCtx = this.barCanvas.getContext("2d");

    recording = ko.observable(false);
    playing = ko.observable(false);
    suspended = ko.observable(false);
    marking = ko.observable(false);
    beginning = 0;
    marked = new MarkedArea(0, 0);
    offset = 0;
    context = new (window as any).AudioContext();
    source = null;
    completeBuffer = ko.observable<AudioBuffer>(null);

    totalArray = new Uint8Array(0);

    mediaRecorder: MediaStreamRecorder;
    CaseId: string;
    Timet = ko.observable(0);
    displayTime = ko.observable("");
    startTime = ko.observable('');
    start: number = 0;
    end: number = 0;
    recordingText = ko.observable("Aufnahme starten");
    loadDictate = async (id: string) => {
        let dictate = (await RNSAPI.getAudioById(id)).Payload;
        this.context.decodeAudioData(Utils.base64ToArrayBuffer(dictate.DocumentData), (buffer) => {
            this.completeBuffer(buffer);
            this.drawWaveform(this.completeBuffer().getChannelData(0));
        });
    };

    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalHandleSelection = ko.observable();

    caseId = ko.observable("");
    subject = ko.observable("");

    async pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    };
    async pickCase() {
        let cases = (await RNSAPI.getCases()).Payload.Cases;
        this.pickGeneric("Akte", ["Registernummer", "Rubrum", "Wegen"], ["Akte", "Rubrum", "Wegen"], cases);
        this.modalHandleSelection((selectedObject) => this.caseId(selectedObject()["Registernummer"]));
        $('#modal').modal('show');
    };

    constructor(params: any) {
        $("#bar").addClass('canvasBack');
        if (params.openDictationModal == true) {
            console.log(params.openDictationModal)
            let mediaConstraints = {
                audio: true
            };

            let containerWidth = document.getElementById("canvasContainer").offsetWidth;
            this.waveCanvas.width = containerWidth;
            this.barCanvas.width = containerWidth;
            this.markedAreaCanvas.width = containerWidth;

            let onMediaSuccess = (stream) => {
                this.mediaRecorder = new MediaStreamRecorder(stream);
                this.mediaRecorder.mimeType = 'audio/wav'; // check this line for audio/wav
                document.getElementById("marking").onchange = (event) => {
                    let markingMode = (<HTMLInputElement>event.target).checked;
                    this.marking(markingMode);
                    document.getElementById("bar").style.display = markingMode ? "none" : "block";
                    document.getElementById("markedArea").style.display = markingMode ? "block" : "none";
                };

                let drawBarWithInput = (event) => {
                    let rect = this.barCanvas.getBoundingClientRect();
                    let x = event.clientX - rect.left;
                    this.offset = x / this.barCanvas.width;

                    this.resetPlayingState();
                    this.repaintBar(x);
                };

                document.getElementById("bar").onmousedown = (event) => {
                    if (this.completeBuffer() !== null) drawBarWithInput(event);
                };

                document.getElementById("bar").onmousemove = (event) => {
                    if (this.completeBuffer() !== null && (event.buttons === 1 || event.which === 1)) {
                        drawBarWithInput(event);
                    }
                };

                document.getElementById("markedArea").onmousedown = (event) => {
                    let rect = this.markedAreaCanvas.getBoundingClientRect();
                    this.beginning = event.clientX - rect.left;
                };

                document.getElementById("markedArea").onmousemove = (event) => {

                    if (event.buttons === 1 || event.which === 1) {
                        let rect = this.markedAreaCanvas.getBoundingClientRect();
                        let pos = event.clientX - rect.left;
                        this.marked = new MarkedArea(Math.min(this.beginning, pos) / this.markedAreaCanvas.width,
                            Math.max(this.beginning, pos) / this.markedAreaCanvas.width);
                        this.drawBackground(Math.min(this.beginning, pos), Math.abs(this.beginning - pos));
                    }
                };
            }

            let onMediaError = (e) => {
                alert("Leider unterstützt ihr Browser die Aufnahme von Audiodaten nicht.");
            }

            var n = <any>navigator;
            n.getUserMedia = n.getUserMedia || n.webkitGetUserMedia || n.mozGetUserMedia || n.msGetUserMedia;
            // navigator.getUserMedia(mediaConstraints, onMediaSuccess, onMediaError);
            if (typeof n.mediaDevices.getUserMedia === 'undefined') {
                n.getUserMedia(mediaConstraints, onMediaSuccess, onMediaError);
            } else {
                n.mediaDevices.getUserMedia(mediaConstraints).then(onMediaSuccess).catch(onMediaError);
            }
            this.drawWaveform([1, 3000, 0, 3000, 2, 0, 1]);

            if (params && params.id) {
                this.IsEdit(true);
                this.loadDictate(params.id);
            }
            $('#dictationModal').modal('show');
            //$('#header div').attr('width','500px');
            //$('.modal-backdrop.show').attr('opacity','0.0');
        }
    }
    RefreshPage() {
        window.location.reload();
    }
    deleteMarkedArea = () => {
        let first = AudioUtils.slice(this.completeBuffer(), 0, this.completeBuffer().length * this.marked.start);
        let second = AudioUtils.slice(this.completeBuffer(), this.completeBuffer().length * this.marked.end, this.completeBuffer().length);
        this.completeBuffer(AudioUtils.concat(first, second));
        this.marked = {
            start: 0,
            end: 0
        };
    };

    repaintBar = (x) => {
        this.barCanvasCtx.fillStyle = "#f00";
        this.barCanvasCtx.clearRect(0, 0, this.barCanvas.width, this.barCanvas.height);
        this.barCanvasCtx.fillRect(x, 0, 2, this.barCanvas.height);
    }

    resetPlayingState = () => {
        this.playing(false);
        this.suspended(false);
        if (this.source) {
            this.source.stop();
            //onended will later be called when the old node is disposed
            //we don't need that
            this.source.onended = null;
        }
    }

    startRecordingAudio = (callback) => {
        this.resetPlayingState();
        var d = new Date();
        var timestamp = d.getTime();
        this.mediaRecorder.ondataavailable = (blob) => {
            //var x = this.start;
            let fr = new FileReader();
            fr.onload = () => {
                this.context.decodeAudioData(fr.result, (buffer) => {
                    if (this.completeBuffer() === null) {
                        this.completeBuffer(buffer);
                    }
                    else
                        this.completeBuffer(AudioUtils.concat(this.completeBuffer(), buffer))
                    callback();
                });
            };

            fr.readAsArrayBuffer(blob);
        };
        console.log("it is recording")
        this.mediaRecorder.start(300000);

    }

    stopRecording = () => {
        var d = new Date();
        var timestamp = d.getTime();
        this.end = timestamp;
        var a: number = this.end - this.start;
        this.totalTime(ko.toJS(this.totalTime()) + a);
        let y = this.formatTime(this.totalTime() / 1000);
        this.displayTime("" + y);
        this.startTime("00:00");
        this.recordingText("Aufnahme fortsetzen...");
        this.mediaRecorder.stop();
        this.recording(false);
    }

    deleteAreaButton = () => {
        this.resetPlayingState();
        this.deleteMarkedArea();
        this.drawWaveform(this.completeBuffer().getChannelData(0));
        this.drawBackground(0, 0);
    };

    insertRecordingButton = () => {
        if (!this.recording()) {
            let markedStart = this.offset * this.completeBuffer().length;
            let oldBuffer = AudioUtils.clone(this.completeBuffer());
            this.completeBuffer(null);
            this.startRecordingAudio(() => {
                let first = AudioUtils.slice(oldBuffer, 0, markedStart);
                let second = AudioUtils.slice(oldBuffer, markedStart, oldBuffer.length);
                this.completeBuffer(AudioUtils.concat(first, this.completeBuffer(), second));
                this.drawWaveform(this.completeBuffer().getChannelData(0));
            });
        }

        this.recording(true);
    };

    overwriteRecordingButton = () => {
        if (!this.recording()) {
            let markedStart = this.marked.start * this.completeBuffer().length;
            this.deleteMarkedArea();
            let oldBuffer = AudioUtils.clone(this.completeBuffer());
            this.completeBuffer(null);
            this.startRecordingAudio(() => {
                let first = AudioUtils.slice(oldBuffer, 0, markedStart);
                let second = AudioUtils.slice(oldBuffer, markedStart, oldBuffer.length);
                this.completeBuffer(AudioUtils.concat(first, this.completeBuffer(), second));
                this.drawBackground(0, 0);
                this.drawWaveform(this.completeBuffer().getChannelData(0));
            });
        }

        this.recording(true);
    };

    startRecordingButton = () => {
        $("#bar").removeClass('canvasBack');
        var d = new Date();
        const con = d.getTime();
        var timestamp = d.getTime();
        this.start = timestamp;
        //let y = this.formatTime(this.totalTime() / 1000);
        this.recordingText("Aufnahme gestartet...");
        if (!this.recording()) {

            this.startRecordingAudio(() => this.drawWaveform(this.completeBuffer().getChannelData(0)));
        }
        this.recording(true);
    };
    // below is added by agache for timing
    formatTime(seconds: any) {

        let minutes: any = Math.floor(seconds / 60);
        let secs: any = Math.floor(seconds % 60);

        if (minutes < 10) {
            minutes = '0' + minutes;
        }

        if (secs < 10) {
            secs = '0' + secs;
        }

        return minutes + ':' + secs;
    }

    // the above is added by agache for timing

    playButton = () => {

        if (!this.playing()) {
            this.source = this.context.createBufferSource();
            let all = (this.completeBuffer().length / this.completeBuffer().sampleRate);

            this.source.buffer = this.completeBuffer();
            this.source.connect(this.context.destination);
            this.source.onended = () => {
                this.playing(false);
                this.suspended(false);
                this.repaintBar(this.offset * this.barCanvas.width);
            }

            this.playing(true);
            this.context.resume();
            this.source.start(0, this.offset * all, all);
            let startTime = this.context.currentTime;
            let paintAnimation = () => {
                let elapsed = (this.context.currentTime - startTime) + this.offset * all;
                let x = this.formatTime(elapsed);
                this.startTime(x);
                if ((this.totalTime() / 1000) < elapsed + 0.2) {
                    this.startTime("00:00");
                }

                if (this.playing() && elapsed <= all) {
                    this.repaintBar(this.barCanvas.width * (elapsed / all));
                    window.requestAnimationFrame(paintAnimation);
                }
            }
            window.requestAnimationFrame(paintAnimation);
        } else if (!this.suspended()) {
            this.context.suspend();
            this.suspended(true);
        } else if (this.suspended()) {
            this.context.resume();
            this.suspended(false);
        }

    };

    drawBackground = (start, width) => {
        this.markedAreaCanvasCtx.fillStyle = "rgba(175, 175, 175, 0.5)";
        this.markedAreaCanvasCtx.clearRect(0, 0, this.barCanvas.width, this.barCanvas.height);
        this.markedAreaCanvasCtx.fillRect(start, 0, width, this.barCanvas.height);
    }

    openaktenoverview = async () => {
        window.open("/#/cases")
    }

    drawWaveform = (buffer) => {
        this.waveCanvasCtx.fillStyle = 'rgb(200, 200, 200)';
        this.waveCanvasCtx.fillRect(0, 0, this.waveCanvas.width, this.waveCanvas.height);

        this.waveCanvasCtx.lineWidth = 2;
        this.waveCanvasCtx.strokeStyle = 'rgb(0, 0, 0)';

        this.waveCanvasCtx.beginPath();

        let x = 0;

        let reducedBuffer = [];
        let max = 0;
        let ratio = Math.floor(buffer.length / this.waveCanvas.width);

        for (let i = 0; i < buffer.length; i++) {
            if (Math.abs(buffer[i]) > Math.abs(max)) max = buffer[i]
            if ((i % ratio) === 0) {
                reducedBuffer.push(max);
                max = 0;
            }
        }

        for (let i = 0; i < reducedBuffer.length; i++) {
            let y = this.waveCanvas.height - (reducedBuffer[i] * Math.sin(i * 2) + 0.5) * this.waveCanvas.height;

            if (i === 0) {
                this.waveCanvasCtx.moveTo(x, y);
            } else {
                this.waveCanvasCtx.lineTo(x, y);
            }

            x += this.waveCanvas.width / reducedBuffer.length;
        }

        this.waveCanvasCtx.lineTo(this.waveCanvas.width, this.waveCanvas.height / 2);
        this.waveCanvasCtx.stroke();
    };

    encodeToOpus = () => {
        if (!Utils.checkErrors(['subject', 'caseId'], this, '', [Utils.checkString])) return;

        let workerCode = fs.readFileSync(__dirname + '/../../../node_modules/opus-recorder/dist/encoderWorker.min.js', 'utf8');
        let blob = new Blob([workerCode], { type: "text/javascript" });

        let encodeWorker = new Worker(window.URL.createObjectURL(blob));
        let bufferLength = 4096;

        encodeWorker.postMessage({
            command: 'init',
            encoderSampleRate: 12000,
            bufferLength: bufferLength,
            originalSampleRate: 44100,
            maxBuffersPerPage: 40,
            encoderApplication: 2049,
            encoderFrameSize: 20,
            //encoderComplexity:  9,
            //resampleQuality:    7,
            encoderComplexity: 3,
            resampleQuality: 3,
            bitRate: 24000
        });

        encodeWorker.postMessage({
            command: 'encode',
            buffers: [this.completeBuffer().getChannelData(0)]
        });

        encodeWorker.postMessage({
            command: 'done'
        });

        encodeWorker.onmessage = async (e) => {
            if (e.data == null) {
                let obj = {
                    Id: null,
                    CaseId: this.caseId(),
                    CurrentCRTVersion: " ",
                    DocType: 0,
                    Documents: [{
                        CRTVersion: " ",
                        DocumentData: Utils.uint8ArrayToBase64(this.totalArray),
                        OleType: "ogg",
                        OLE2Type: "ogg",
                        useOCR: false
                    }],
                    LawyerId: RNSAPI.User() ? RNSAPI.User().username : "GR",
                    MimeType: "audio/ogg",
                    Note1: "",
                    Recipient: "",
                    Subject: this.subject(),
                    imex: "A",
                    isScanned: "0",
                    isVerschluss: false,
                    WorkflowId: "B"
                };

                let result = await RNSAPI.createCaseEntry(obj);
                if (result.Type === "InsertionSuccessful") {
                    alert("Das Diktat wurde erfolgreich in die Akte" + this.caseId() + " übertragen!");
                    location.reload();
                    //MainViewModel.RoutingTable.showNewView({ CaseId: this.caseId() });
                }
                else alert("Fehler beim Hochladen!");
            }
            else {
                let totalArray = concatTypedArrays(this.totalArray, e.data);
                this.totalArray = totalArray;
            }
        };
    }
}

function concatTypedArrays(a, b) { // a, b TypedArray of same type
    var c = new (a.constructor)(a.length + b.length);
    c.set(a, 0);
    c.set(b, a.length);
    return c;
}

let html = fs.readFileSync(__dirname + '/record.html', 'utf8');

ko.components.register("record-view", {
    viewModel: RecordViewModel,
    template: html
});
